import { getAsset } from '@core/building/assets';
import { getItem } from '@core/building/items';
import { Field, Form } from '@core/forms';
import Validates, { numericality, presence } from '@core/forms/validations';
import { FontAwesome, Spinner } from '@shared';
import { AssetDisplay, ItemDisplay } from '@shared/displays';
import { DateSelector, FileUploader } from '@shared/forms';
import CostAccountFinder from '@shared/forms/CostAccountFinder';
import { connect, deskSessionGenerators } from '@store';
import { get } from 'lodash';
import React from 'react';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Input, Label, Row } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withHandlers, withState } from 'recompose';
import { formValues, reduxForm, SubmissionError } from 'redux-form';

const excluded = [
  'reserve',
  'geofence-in',
  'geofence-out',
  'requires_maintenance',
  'sent_to_repair',
  'start_maintenance',
  'maintenance_return',
  'reception_from_maintenance',
];

const excludedConsumable = ['devolution', ...excluded];

const excludedNotConsumable = ['entry', ...excluded];

const availableMovements = function (type, target, movements) {
  const filteredObject = { '': 'Seleccionar' };

  Object.keys(movements).forEach((key) => {
    if (type === 'item') {
      if (excludedConsumable.includes(key)) return;
      if (!movements[key].behavior.consumable) return;
    } else {
      if (movements[key].allowed_states && !movements[key].allowed_states.includes(target.state)) return;

      if (excludedNotConsumable.includes(key)) return;
    }
    filteredObject[key] = movements[key].name;
  });

  return filteredObject;
};

const UploaderComponent = ({ onChange, value }) => (
  <FileUploader multiple={true} token={value} onChange={onChange} endpoint={(token) => `/attachments/${token}`} />
);

// prettier-ignore
const validate = Validates(
  presence({ of: 'movementType' }),
  numericality({ of: 'quantity', greaterThan: 0 })
)

const enhance = compose(
  connect(
    ({
      appData: { movements },
      deskSession: { currentClient, currentTarget, clientData },
      session: { user, currentMembership },
    }) => ({
      currentClient,
      currentTarget,
      currentUser: user,
      movements,
      clientData,
      membership: user?.memberships.find((membership) => membership.id === currentMembership),
    }),
    (dispatch) => ({
      cancelTarget: () => dispatch(deskSessionGenerators.clearTarget()),
      addMovement: (movement) => dispatch(deskSessionGenerators.addMovement(movement)),
    }),
  ),
  branch(
    ({ currentTarget }) => !currentTarget || !currentTarget.id,
    renderComponent(() => null),
  ),
  withState('targetData', 'setTargetData', undefined),
  withState('showAdditionalFields', 'setAdditionalFieldsDisplay', false),
  withState('authorization', 'setAuthorization', false),
  withState('costAccountId', 'setCostAccountId', undefined),
  withState('quantity', 'setQuantity', false),
  withState('expectedAt', 'setExpectedAt', new Date().setHours(23, 59, 59, 999)),
  lifecycle({
    async componentDidMount() {
      const {
        currentTarget: { id, type },
      } = this.props;
      const searchFunction = type === 'asset' ? getAsset : getItem;
      const { body, status } = await searchFunction(id);

      if (status === 200) {
        const { setTargetData } = this.props;
        setTargetData(body);
      } else if (status === 404) {
        const { cancelTarget } = this.props;
        cancelTarget();
      }
    },
  }),
  withHandlers({
    onAuthCheckChange: (props) => (event) => {
      props.setAuthorization(event.target.checked);
    },
    onSubmit: ({
      addMovement,
      cancelTarget,
      currentClient,
      currentTarget,
      currentUser,
      movements,
      onSubmit,
      targetData,
    }) => (data) => {
      const complimentary = {
        clientType: 'Core::User',
        client: currentClient,
        generatorType: 'Core::User',
        generator: currentUser,
      };

      // Previous validations
      // 1. Return date if applies
      const { movementType, expectedAt, quantity } = data;

      if (movements[movementType].expects_return_date && !expectedAt)
        throw new SubmissionError({ expectedAt: 'No puede estar en blanco.' });

      // 2. Quantity presence if applies
      if (currentTarget.type === 'item' && !quantity)
        throw new SubmissionError({ quantity: 'No puede estar en blanco.' });

      // Mount data structure to be passed
      let movement;

      if (currentTarget.type === 'asset') {
        movement = {
          ...data,
          asset: targetData,
          consumable: false,
          ...complimentary,
        };
      } else if (currentTarget.type === 'item') {
        // Código temporal, Eliminar al resolver la carga de consumibles
        if (!data['extendedAttributes']) data['extendedAttributes'] = {};
        data['extendedAttributes']['decimalQuantity'] = data.quantity;
        //
        movement = {
          ...data,
          item: targetData,
          consumable: true,
          ...complimentary,
        };
      }

      if (movement) {
        cancelTarget();
        addMovement(movement);
      }

      onSubmit && onSubmit(movement);
    },
  }),
  reduxForm({
    form: 'deskSessionTarget',
    validate,
  }),
  formValues({ movementType: 'movementType', quantity: 'quantity' }),
);

const TargetEditorPresentation = ({
  // Props
  cancelTarget,
  currentTarget,
  targetData,
  movements,
  currentUser,
  clientData,
  authorization,
  onAuthCheckChange,
  // Forms engine
  error,
  handleSubmit,
  invalid,
  // Form data
  movementType,
  // Handlers
  showAdditionalFields,
  setAdditionalFieldsDisplay,
  setAuthorization,
  quantity,
  setQuantity,
  expectedAt,
  setExpectedAt,
  setCostAccountId,
  // State
  membership: {
    building: { organization },
    role,
  },
}) => {
  const showReturnDate = movementType && movements[movementType].expects_return_date;
  const showQuantity = currentTarget.type === 'item';
  const showExtras = showReturnDate || showQuantity;

  const expectsClient = movementType && movements[movementType] && movements[movementType].expects_client;

  const showAlert = expectsClient && get(targetData, 'item.restricted', false);

  const showRestrictionAlert =
    expectsClient &&
    clientData &&
    (currentTarget.type === 'asset'
      ? get(clientData, 'restrictedItems')?.some(
          (restrictedItem) =>
            restrictedItem.item.id === targetData.item.id &&
            (restrictedItem.quantity <= restrictedItem.delivered ||
              restrictedItem.quantity < restrictedItem.delivered + 1),
        )
      : currentTarget.type === 'item'
      ? get(clientData, 'restrictedItems')?.some(
          (restrictedItem) =>
            restrictedItem.item.id === targetData.id &&
            (restrictedItem.quantity <= restrictedItem.delivered ||
              restrictedItem.quantity < restrictedItem.delivered + Number(quantity)),
        )
      : false);

  const showDischargeAlert = currentTarget.type === 'asset' && movementType === 'discharge';

  return (
    <Card className="mb-4">
      <Form error={error} onSubmit={handleSubmit}>
        <CardHeader>Ingreso actual</CardHeader>

        {!targetData && (
          <div className="mb-4 mt-4 text-center h2 text-info">
            <Spinner />
          </div>
        )}

        {targetData && (
          <CardBody>
            <Row>
              <Col>
                <div className="small text-muted mb-2">
                  {currentTarget.type === 'asset' && 'Activo'}
                  {currentTarget.type === 'item' && 'Artículo'}
                </div>
                {currentTarget.type === 'asset' && targetData && <AssetDisplay asset={targetData} />}

                {currentTarget.type === 'item' && targetData && <ItemDisplay item={targetData} />}
              </Col>
              <Col>
                <div className="small text-muted mb-2">Tipo de movimiento</div>
                <Field
                  name="movementType"
                  type="select"
                  onChange={() => setAuthorization(false)}
                  options={availableMovements(currentTarget.type, targetData, movements)}
                  size="lg"
                />
              </Col>
            </Row>

            {showRestrictionAlert && (
              <Alert color="danger" className="mt-3 pr-3 pt-3 pl-3 pb-3">
                <strong>
                  El artículo está Restringido para este cliente, si considera que hay un error, comunicarse con el
                  administrador
                </strong>
              </Alert>
            )}

            {showDischargeAlert && (
              <Alert color="danger" className="mt-3 pr-3 pt-3 pl-3 pb-3">
                <strong>¡Importante!</strong>
                <p>
                  <strong>
                    Al dar de baja un activo, este no podrá ser utilizado nuevamente. Considere esto antes proceder con
                    esta acción.
                  </strong>
                </p>
              </Alert>
            )}

            {showAlert && (
              <Alert color="danger" className="mt-3 pr-3 pt-3 pl-3 pb-3">
                <strong>¡{currentTarget.type === 'item' ? 'Artículo' : 'Activo'} con restricciones!</strong>
                <p className="mt-2">
                  Yo, <strong>{[currentUser.firstName, currentUser.lastName].join(' ')}</strong>, hice las validaciones
                  necesarias y confirmo que el cliente seleccionado cuenta con la autorización necesaria para realizar
                  este movimiento.
                </p>
                <Label check>
                  <Input
                    type="checkbox"
                    name="auth-validated"
                    checked={authorization}
                    onChange={onAuthCheckChange}
                    className="mr-2"
                  />
                  Autorización verificada
                </Label>
              </Alert>
            )}

            {showExtras && (
              <Card className="mt-3">
                <CardBody className="pr-3 pt-3 pl-3 pb-0">
                  {showReturnDate && (
                    <Field
                      name="expectedAt"
                      label="Fecha de retorno"
                      help="Este movimiento requiere que se especifique una fecha de retorno."
                      component={DateSelector}
                      minDate={new Date()}
                      selected={expectedAt}
                      onChange={(data) => {
                        console.log(data);
                        setExpectedAt(data.setHours(23, 59, 59, 999));
                      }}
                      required
                    />
                  )}
                  {showQuantity && (
                    <Field
                      name="quantity"
                      type="number"
                      label="Cantidad"
                      min="0"
                      onChange={(id, quantity) => {
                        console.log(quantity, currentTarget[1]?.inventory?.availableQuantity);
                        setQuantity(quantity);
                      }}
                      required
                      help={
                        Number(quantity) > targetData?.inventory?.availableQuantity && movementType !== 'entry'
                          ? 'La cantidad solicitada es mayor que la cantidad disponible'
                          : ''
                      }
                      invalid={Number(quantity) > targetData?.inventory?.availableQuantity && movementType !== 'entry'}
                    />
                  )}
                  {currentTarget.type === 'item' && organization?.hasCostAccounts && movementType === 'withdrawal' && (
                    <Field
                      name="extendedAttributes.costAccountId"
                      label="Centro de Costo"
                      component={CostAccountFinder}
                      building={true}
                      editable={true}
                      help="Para este tipo de movimiento puede especificar el centro de costos al que desea cargarlo."
                      componentOnChange={(id, costAccountId) => {
                        setCostAccountId(costAccountId);
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            )}

            <Card className="mt-3">
              <CardBody className="p-2">
                <div role="button" onClick={() => setAdditionalFieldsDisplay(!showAdditionalFields)}>
                  <FontAwesome name="caret-right" className="mr-2" />
                  Información adicional
                </div>

                <div className="mr-3 ml-3 mt-2" style={{ display: showAdditionalFields ? 'block' : 'none' }}>
                  <Field
                    name="comments"
                    type="textarea"
                    label="Comentarios"
                    help="Cualquier antecedente adicional respecto al movimiento."
                  />

                  <Field name="attachmentsTokens" label="Archivos" component={UploaderComponent} />
                </div>
              </CardBody>
            </Card>
          </CardBody>
        )}
        <CardFooter>
          <span className="float-right">
            <Button
              role="button"
              disabled={invalid || !targetData || (showAlert && !authorization) || showRestrictionAlert}
              type="submit"
              color="primary"
            >
              <FontAwesome name="caret-down" /> Ingresar
            </Button>
          </span>
          <Button role="button" color="danger" onClick={cancelTarget} disabled={!targetData}>
            <FontAwesome name="times" /> Cancelar
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
};

export const TargetEditor = enhance(TargetEditorPresentation);
