import { handleAuth, withToken, apiFetch, mountQuery } from '../http';

export const getOrganizations = (query = {}) => {
  return handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/organizations${mountQuery(query)}`,
      method: 'GET',
    }),
  );
};

export const getOrganization = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/organizations/${id}`,
      method: 'GET',
    }),
  );

export const createOrganization = ({
  name,
  avatarToken,
  contactName,
  contactEmail,
  contactPhone,
  active,
  podStatus,
  hasCostAccounts,
}) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: '/admin/organizations',
      method: 'POST',
      body: {
        organization: {
          name,
          avatarToken,
          contactName,
          contactEmail,
          contactPhone,
          active,
          podStatus,
          hasCostAccounts,
        },
      },
    }),
  );

export const editOrganization = ({
  id,
  name,
  avatarToken,
  contactName,
  contactEmail,
  contactPhone,
  active,
  podStatus,
  hasCostAccounts,
}) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/organizations/${id}`,
      method: 'PUT',
      body: {
        organization: {
          name,
          avatarToken,
          contactName,
          contactEmail,
          contactPhone,
          active,
          podStatus,
          hasCostAccounts,
        },
      },
    }),
  );

export const getBuildings = (organizationId, query) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/organizations/${organizationId}/buildings${mountQuery(query)}`,
      method: 'GET',
    }),
  );
