import { getPosts, updatePost } from '@core/admin/posts';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { PostsFilterModal } from './components/PostsFilterModal';
import { PostsResults } from './components/PostsResults';

export class PostsIndex extends SimpleFilter {
  searchFunction = getPosts;

  setPostStatus = async (id, active) => {
    this.setState({
      loading: true,
    });

    const { status } = await updatePost({ id, active });

    if (status === 200) {
      active ? this.props.activateNotification() : this.props.deactivateNotification();

      await this.loadPosts();
    }

    this.setState({
      loading: false,
    });
  };

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Row>
              <Col>
                <div className="content-actions float-right">
                  <Button size="sm" color="secondary" onClick={this.toggle}>
                    <FontAwesome name="filter" /> Filtros
                  </Button>
                  <Button tag={NavLink} size="sm" outline color="success" to="/admin/posts/new">
                    <FontAwesome name="plus" /> Crear Noticia
                  </Button>
                </div>
                <h4 className="mb-4">
                  <FontAwesome name="newspaper" className="text-primary mr-2" />
                  Gestión de Noticias
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!items && (
                      <div className="text-center h1 text-info">
                        <Spinner />
                      </div>
                    )}

                    {items && <PostsResults posts={items} pagination={pagination} setPostStatus={this.setPostStatus} />}

                    {(!items || items.length > 0) && paginationTemplate}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <PostsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </Container>
    );
  }
}
