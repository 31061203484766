import { FontAwesome } from '@shared';
import { fromIso8601 } from '@core/utils/dates';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';
import React, { useState } from 'react';
import { InventoryCheckerSetDetail } from './InventoryCheckerSetDetail';
import { getInventoryCheckerSet } from '@core/building/inventory-checker-sets';

export const InventoryCheckerSetRow = ({ inventoryCheckerSet, processing, activeAutomation }) => {
  const FREQUENCY = {
    daily: 'Diaria',
    weekly: 'Semanal',
    monthly: 'Mensual',
    yearly: 'Anual',
  };

  const [isOpen, setIsOpen] = useState(false);
  const [inventoryCheckerSetDetail, setInventoryCheckerSetDetail] = useState(undefined);

  const toggle = () => {
    if (!isOpen)
      (async () => {
        const { body } = await getInventoryCheckerSet(inventoryCheckerSet.id);
        setInventoryCheckerSetDetail(body);
      })();
    setIsOpen(!isOpen);
  };

  return (
    <tr>
      <td className="align-middle text-left small">{inventoryCheckerSet.requestNumber}</td>
      <td className="align-middle text-center">
        <Badge color={inventoryCheckerSet.consumable ? 'primary' : 'primary'}>
          {inventoryCheckerSet?.consumable ? 'Consumibles' : 'Activos'}
        </Badge>
      </td>
      <td className="align-middle text-center">
        <Badge color={inventoryCheckerSet.active ? 'success' : 'danger'}>
          {inventoryCheckerSet?.active ? 'Habilitado' : 'Inhabilitado'}
        </Badge>
      </td>
      <td className="align-middle text-center small">{fromIso8601(inventoryCheckerSet.startAt)}</td>
      <td className="align-middle text-center small">{fromIso8601(inventoryCheckerSet.endAt)}</td>
      <td className="align-middle text-center small">{FREQUENCY[inventoryCheckerSet.frequency]}</td>
      <td className="align-middle text-center small">{fromIso8601(inventoryCheckerSet.lastOrderAt)}</td>
      <td className="align-middle text-center">
        <Button
          id={`ActiveButton-${inventoryCheckerSet.id}`}
          role="button"
          className="mb-1 mr-1"
          color={inventoryCheckerSet.active ? 'danger' : 'success'}
          size="sm"
          onClick={() => activeAutomation(inventoryCheckerSet)}
          disabled={new Date(inventoryCheckerSet.endAt) < new Date()}
        >
          <FontAwesome name={inventoryCheckerSet?.active ? 'toggle-off' : 'toggle-on'} />
        </Button>
        <Button
          role="button"
          className="mb-1 mr-1"
          color="info"
          id={`MoreInfoButton-${inventoryCheckerSet.id}`}
          size="sm"
          onClick={toggle}
        >
          <FontAwesome name="search" />
        </Button>
        <UncontrolledTooltip placement="auto" target={`ActiveButton-${inventoryCheckerSet.id}`}>
          {inventoryCheckerSet?.active ? 'Cancelar' : 'Activar'}
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="auto" target={`MoreInfoButton-${inventoryCheckerSet.id}`}>
          Más Información
        </UncontrolledTooltip>
      </td>
      <InventoryCheckerSetDetail
        inventoryCheckerSet={inventoryCheckerSetDetail}
        isOpen={isOpen}
        toggle={toggle}
        activeAutomation={activeAutomation}
      />
    </tr>
  );
};
