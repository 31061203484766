import { getDeviceStocks, createDeviceStock, deleteDeviceStock, updateDeviceStock } from '@core/building/device-stocks';
import { buildingDownload } from '@core/http';
import { ExcelDownload, FontAwesome, ShowForRoles, Spinner } from '@shared';
import { DeviceStockRow } from '@shared/device-stocks';
import { ItemSelector } from '@shared/forms';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert, Button, Col, Input, Row, Table } from 'reactstrap';

class DeviceStocksPresentation extends Component {
  state = {
    notFound: false,
    loading: true,
    modal: false,
    secret: '',
    deviceStocks: [],
    displayConfirmBox: true,
    newStockRow: false,
    itemId: undefined,
    minimalStock: undefined,
    optimalStock: undefined,
    maxStock: undefined,
    duplicated: false,
  };

  async componentDidMount() {
    const { device } = this.props;
    const barcode = device.barcode1;

    if (!barcode) {
      this.notFound();
      return;
    }

    await this.getStocks();
  }

  notFound = () => {
    this.setState({ notFound: true });

    this.props.notFound();
  };

  getStocks = async () => {
    const { body: deviceStocks, status } = await getDeviceStocks(this.props.device?.barcode1);

    if (status === 404) {
      this.notFound();
    } else if (status === 200) {
      this.setState({
        loading: false,
        deviceStocks,
      });
    }
  };

  createStock = async () => {
    this.setState({ loading: true });
    const { itemId, minimalStock, optimalStock, maxStock } = this.state;
    const payload = {
      itemId,
      minimalStock,
      optimalStock,
      maxStock,
    };
    const { status } = await createDeviceStock(this.props.device?.barcode1, payload);

    if (status === 201) {
      await this.getStocks();
      this.setState({
        newStockRow: false,
        itemId: undefined,
        minimalStock: undefined,
        optimalStock: undefined,
        maxStock: undefined,
        loading: false,
      });
    }
  };

  updateStock = async (id, payload) => {
    // const { deviceStocks } = this.state;
    const { status } = await updateDeviceStock(this.props.device?.barcode1, id, payload);

    if (status === 200) {
      this.props.successDeleteNotification();
      await this.getStocks();
    }
  };

  deleteStock = async (id) => {
    // const deviceStock = this.state.deviceStocks[index];
    const { status } = await deleteDeviceStock(this.props.device?.barcode1, id);

    if (status === 204) {
      this.props.successDeleteNotification();
      await this.getStocks();
    }
  };

  // Verificar duplicados
  checkItemsDuplicate = (id) => {
    if (this.state.deviceStocks.some((deviceStock) => deviceStock.item.id === id)) {
      this.setState({ duplicated: true });
    } else {
      this.setState({ duplicated: false });
    }
  };

  render() {
    const {
      deviceStocks,
      notFound,
      newStockRow,
      loading,
      itemId,
      minimalStock,
      optimalStock,
      maxStock,
      duplicated,
    } = this.state;
    const { device } = this.props;

    if (notFound) return <Redirect to="/organization/items" />;

    return (
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Configuración de stock de dispositivos</h5>

          <div>
            {deviceStocks && deviceStocks?.length > 0 && (
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                <ExcelDownload
                  file={`devices/${device.barcode1}/device_expected_stocks`}
                  filters={{}}
                  csv={false}
                  downloadFile={buildingDownload}
                  disabled={!deviceStocks}
                />
              </ShowForRoles>
            )}
            <Button
              role="button"
              size="sm"
              color="success"
              className="ml-1"
              onClick={() => this.setState({ newStockRow: true })}
            >
              Agregar <FontAwesome name="plus" />
            </Button>
          </div>
        </div>

        <Row>
          <Col sm={12} md={12} className="mb-4">
            {!deviceStocks && (
              <div className="text-center h1 text-info">
                <Spinner />
              </div>
            )}

            {deviceStocks && (
              <Table>
                <colgroup>
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: 'auto' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className="text-left">Artículos</th>
                    <th className="text-right">Mínimo</th>
                    <th className="text-right">Óptimo</th>
                    <th className="text-right">Máximo</th>
                    <th className="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {newStockRow && (
                    <tr>
                      <td className="align-middle small">
                        <ItemSelector
                          name="itemId"
                          resourceKey="id"
                          filters={{ active: true, consumable: true, serializer: 'simple' }}
                          inputProps={{ size: 'md' }}
                          input={{
                            onChange: (id) => {
                              this.checkItemsDuplicate(id);
                              this.setState({ itemId: id });
                            },
                          }}
                          value={itemId || ''}
                        />
                      </td>
                      <td className="align-middle small text-right">
                        <Input
                          name="minimalStock"
                          type="text"
                          placeholder="Mínimo"
                          onChange={(e) => this.setState({ minimalStock: e.target.value })}
                          value={minimalStock || ''}
                          size="md"
                        />
                      </td>
                      <td className="align-middle small text-right">
                        <Input
                          name="optimalStock"
                          type="text"
                          placeholder="Óptimo"
                          onChange={(e) => this.setState({ optimalStock: e.target.value })}
                          value={optimalStock || ''}
                          size="md"
                        />
                      </td>
                      <td className="align-middle small text-right">
                        <Input
                          name="maxStock"
                          type="text"
                          placeholder="Máximo"
                          onChange={(e) => this.setState({ maxStock: e.target.value })}
                          value={maxStock || ''}
                          size="md"
                        />
                      </td>
                      {loading ? (
                        <td className="align-middle text-center">
                          <Spinner color={'primary'} />
                        </td>
                      ) : (
                        <td className="align-middle small text-center">
                          <ShowForRoles accepted={['organization_admin']}>
                            <Button
                              role="button"
                              size="sm"
                              color={'success'}
                              className="mr-1"
                              onClick={this.createStock}
                              disabled={!itemId || duplicated || !(minimalStock || optimalStock || maxStock)}
                            >
                              <FontAwesome name="check" />
                            </Button>
                            <Button
                              role="button"
                              size="sm"
                              color="danger"
                              onClick={() =>
                                this.setState({
                                  newStockRow: false,
                                  itemId: undefined,
                                  minimalStock: undefined,
                                  optimalStock: undefined,
                                  maxStock: undefined,
                                })
                              }
                            >
                              <FontAwesome name="times" />
                            </Button>
                          </ShowForRoles>
                        </td>
                      )}
                    </tr>
                  )}
                  {duplicated && (
                    <tr>
                      <Alert color="warning">
                        El artículo ya está en la lista, si desea modificarlo utilice el modo edición de la fila
                        correspondiente.
                      </Alert>
                    </tr>
                  )}
                  {deviceStocks.map((deviceStock, index) => (
                    <DeviceStockRow
                      key={index}
                      deviceStock={deviceStock}
                      deleteStock={this.deleteStock}
                      updateStock={this.updateStock}
                    />
                  ))}
                  {deviceStocks.length === 0 && (
                    <tr>
                      <td className="text-center mt-3 mb-3">
                        <em>No se encontraron configuraciones de stocks</em>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

export const DeviceStocks = connect(null, (dispatch) => ({
  successCreateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Artículo configurado',
        text: `Se crearon los parametros de stock del artículo para este dispositivo.`,
        color: 'success',
      }),
    ),
  successUpdateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Artículo configurado',
        text: `Se modificaron los parámetros de stock del artículo para este dispositivo.`,
        color: 'success',
      }),
    ),
  successDeleteNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Artículo configurado',
        text: `Se eliminaron los parametros de stock del artículo para este dispositivo.`,
        color: 'success',
      }),
    ),
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo no encontrado',
        text:
          'El dispositivo buscado no existe o no se encuentra disponible. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(DeviceStocksPresentation);
