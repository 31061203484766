import { apiFetch, handleAuth, withMembership } from '../http';

export const getKitResources = (kit_id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${kit_id}/kit_resources`,
      method: 'GET',
    }),
  );

export const getKitResource = (kit_id, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${kit_id}/kit_resources/${id}`,
      method: 'GET',
    }),
  );

export const createKitResource = (kit_id: integer, kit_resource: object = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${kit_id}/kit_resources`,
      method: 'POST',
      body: { kit_resource },
    }),
  );

export const updateKitResource = (kit_id: integer, kit_resource: object = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${kit_id}/kit_resources/${kit_resource.id}`,
      method: 'PUT',
      body: { kit_resource },
    }),
  );

export const destroyKitResource = (kit_id: integer, kit_resource: object = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${kit_id}/kit_resources/${kit_resource.id}`,
      method: 'DELETE',
    }),
  );
