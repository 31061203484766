import { editArea, getArea } from '@core/building/areas';
import { SubmissionError } from '@core/forms';
import { Loading } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { AreaForm } from './components/AreaForm';

class EditAreaPresentation extends Component {
  state = {
    loading: true,
    edited: false,
    area: undefined,
    notFound: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) this.notFound();

    const { body: area, status } = await getArea(id);

    this.setState({ loading: false });

    if (status === 404) {
      this.notFound();
    } else if (status === 200) {
      this.setState({ area });
    }
  }

  notFound = () => {
    this.props.notFound();

    this.setState({ notFound: true });
  };

  editArea = async (data) => {
    const { body, status } = await editArea(data);

    if (status === 200) {
      this.setState({
        edited: true,
      });

      this.props.successNotification(data.name);
    } else if (status === 422) throw new SubmissionError(body.errors);
  };

  render() {
    const { area, edited, loading, notFound } = this.state;

    if (notFound) return <Redirect to="/building/areas" />;

    if (loading) return <Loading />;

    if (edited) return <Redirect to="/building/areas" />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title={`Editar área: ${area && area.name}`}>
          {area && <AreaForm initialValues={area} onSubmit={this.editArea} />}
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const EditArea = connect(null, (dispatch) => ({
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No encontrado',
        text:
          'El área buscada no existe o no se encuentra disponible. Si los errores persisten, por favor contacta a un administrador.',
        color: 'danger',
      }),
    ),
  successNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Área creada',
        text: `El área ${name} ha sido editada exitosamente.`,
        color: 'success',
      }),
    ),
}))(EditAreaPresentation);
