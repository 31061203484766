import React from 'react';
import { Form, FormGroup, Input, Label } from 'reactstrap';

export const UploadForm = ({ file, getRef, processing, setFile }) => {
  let innerComponent;

  if (file) {
    innerComponent = (
      <div>
        <div className="mb-3">
          Archivo seleccionado:
          <br />
          <code>{file.name}</code>
        </div>
        <div className="small text-muted">Click aquí para cambiarlo</div>
      </div>
    );
  } else {
    innerComponent = (
      <div>
        <div className="mb-3">Click aquí para cargar archivo</div>
        <div className="small text-muted">
          Solo se permiten archivos <code>.xlsx</code>
        </div>
      </div>
    );
  }

  return (
    <Form noValidate disabled={processing}>
      <FormGroup>
        <Label for="file" className="file-label mb-4" role="button">
          {innerComponent}
        </Label>
        <Input
          name="file"
          id="file"
          type="file"
          className="d-none"
          accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          multiple={false}
          onChange={setFile}
          disabled={processing}
          getRef={getRef}
        />
      </FormGroup>
    </Form>
  );
};
