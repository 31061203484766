import { getDeviceInventoryByBuilding } from '@core/building/devices';
import { BuildingLayout } from '@shared/layouts';
import React from 'react';
import { DeviceInventoryIndex } from './components/DeviceInventoryIndex';

export const DevicesInventory = (props) => (
  <BuildingLayout>
    <DeviceInventoryIndex search={getDeviceInventoryByBuilding} switcher={true} {...props} />
  </BuildingLayout>
);
