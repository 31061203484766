import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormGroup, Label, Input } from 'reactstrap';
import { ProcessingButton } from '@shared';

export const TaskCancelModal = function ({ task, toggle, isOpen, cancelTask }) {
  const [cancelReason, setCancelReason] = useState(undefined);
  const cleanAndClose = () => {
    setCancelReason(undefined);
    toggle();
  };
  const saveAndClose = (task, cancelReason) => {
    cancelTask(task, cancelReason);
    cleanAndClose();
  };
  return (
    <Modal isOpen={isOpen} toggle={cleanAndClose}>
      <ModalHeader toggle={cleanAndClose}>Anular Tarea</ModalHeader>
      <ModalBody>
        <h5>Título: {task.title}</h5>
        <FormGroup className="mt-2">
          <Label for="reason">Motivo:</Label>
          <Input
            style={{ height: '10em' }}
            value={cancelReason}
            onChange={({ target }) => setCancelReason(target.value)}
            type="textarea"
            name="reason"
            id="reason"
            placeholder={`Motivo para ${task.canceledAt ? 'reanudar' : 'anular'} la tarea...`}
            required
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button role="button" onClick={cleanAndClose}>
          Cerrar
        </Button>
        <ProcessingButton
          type="submit"
          role="button"
          outline
          color="primary"
          onClick={() => saveAndClose(task, cancelReason)}
          disabled={!cancelReason}
        >
          {task.canceledAt ? 'Reanudar Tarea' : 'Anular Tarea'}
        </ProcessingButton>
      </ModalFooter>
    </Modal>
  );
};
