import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Table } from 'reactstrap';
import { ItemDisplay } from '@shared/displays';
import { getRestrictedItems } from '@core/building/restricted-items';
import { Loading } from '@shared';

export const RestrictedItems = ({ user, ...props }) => {
  const [restrictedItems, setRestrictedItems] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRestrictions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cargar los items restringidos
  const getRestrictions = async () => {
    setLoading(true);
    const { body, status } = await getRestrictedItems(user.id);

    if (status === 200) {
      setRestrictedItems(body);
      setLoading(false);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Table>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: 'auto' }} />
            </colgroup>
            <thead>
              <th className="text-left">Artículo</th>
              <th className="align-middle text-center">Organización</th>
              <th className="align-middle text-right">Cantidad</th>
              <th className="align-middle text-right">Frecuencia</th>
              <th className="align-middle text-right">Utilizados</th>
            </thead>
            <tbody>
              {restrictedItems && restrictedItems.length > 0 ? (
                restrictedItems.map((restrictedItem, index) => (
                  <tr key={`restricted-item-${index}`}>
                    <td>
                      {restrictedItem && restrictedItem.item && <ItemDisplay item={restrictedItem.item} sku={true} />}
                    </td>
                    <td className="align-middle text-center">
                      {restrictedItem && restrictedItem.item && restrictedItem.item.organization.name}
                    </td>
                    <td className="align-middle text-right">{restrictedItem && restrictedItem.quantity}</td>
                    <td className="align-middle text-right">{restrictedItem && restrictedItem.period}</td>
                    <td className="align-middle text-right">{restrictedItem && restrictedItem.delivered}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Alert color="info">El usuario no tiene artículos restringidos</Alert>
                  </td>
                </tr>
              )}
              {loading && <Loading />}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
