export { default as format } from './format';
export { default as length } from './length';
export { default as numericality } from './numericality';
export { default as presence } from './presence';
export { default as passwordsMatching } from './passwords-matching';
export { default as emailFormat } from './email-format';
export { default as oneOf } from './oneOf';

const Validates = function (...validations) {
  return function (data) {
    let errors = {};

    validations.forEach((validation) => {
      // Run custom validation
      if (!validation.prototype.__standardValidation__) {
        const result = validation(data);
        errors = { ...errors, ...result };
        return;
      }

      // Normal validation
      const result = validation(data);

      if (!result.valid) {
        errors[validation.prototype.__validationTarget__] = result.message;
      }
    });

    return errors;
  };
};

export default Validates;
