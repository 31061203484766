import React, { Component } from 'react';
import { Redirect } from 'react-router';

import { connect, notificationsGenerators } from '@store';
import { cancelConfirmation } from '@core/account';
import { Loading } from '@shared';

class ConfirmationCancel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    cancelConfirmation({
      token: this.props.match.params.token,
    }).then(({ status }) => {
      if (status === 204) this.props.successNotification();

      this.setState({
        loading: false,
      });
    });
  }

  render() {
    return <ConfirmationPage loading={this.state.loading} />;
  }
}

const ConfirmationPage = ({ loading }) => {
  if (loading) return <Loading />;

  return <Redirect to="/" />;
};

export default connect(null, (dispatch) => ({
  successNotification: () => {
    dispatch(
      notificationsGenerators.insert({
        title: 'Solicitud cancelada',
        text: 'La solicitud de cancelación de confirmación de correo ha sido cancelada exitosamente.',
        color: 'primary',
      }),
    );
  },
}))(ConfirmationCancel);
