import React from 'react';
import { NavLink } from 'react-router-dom';
import { fromIso8601 } from '@core/utils/dates';
import { Button } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';

import { ConfirmBox, FontAwesome, ShowForRoles, Spinner } from '@shared';

const enhance = compose(
  withState('loading', 'setLoading', false),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    remove: ({ setLoading, deleteKey, setIsOpen }) => async () => {
      setLoading('remove');

      await deleteKey();

      setLoading(false);
      setIsOpen(false);
    },
  }),
  withHandlers({
    openConfirmBox: ({ setIsOpen, displayConfirmBox, remove }) => () => {
      if (displayConfirmBox) {
        setIsOpen(true);
      } else {
        remove();
      }
    },
    closeConfirmBox: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
  }),
);

const DeviceKeyRow = ({
  deviceKey,
  isOpen,
  openConfirmBox,
  closeConfirmBox,
  toogleConfirmBox,
  remove,
  resetSecret,
  loading,
}) => (
  <tr>
    <th scope="row" className="align-middle small text-left">
      <div>
        <span>
          Alias: <code>{deviceKey.alias}</code>
        </span>
      </div>
      <div>
        <kbd>{deviceKey.apiKey}</kbd>
      </div>
    </th>
    <td className="align-middle small">
      <div>
        <div className="small text-muted mb-1">Creado por</div>
        {deviceKey.creatorType === 'Core::User' && (
          <NavLink to={`/building/users/${deviceKey.creator.id}`}>
            <FontAwesome name="user" className="mr-1" /> {deviceKey.creator.firstName} {deviceKey.creator.lastName}
          </NavLink>
        )}
      </div>
      <div className="text-info">{fromIso8601(deviceKey.createdAt)}</div>
    </td>
    <td className="align-middle text-right">
      <Button role="button" size="sm" color="primary" onClick={() => resetSecret(deviceKey)} className="mr-1">
        <FontAwesome name="retweet" /> Generar Clave
      </Button>
      <Button role="button" size="sm" color="danger" onClick={openConfirmBox} disabled={loading}>
        {loading === 'remove' ? <Spinner /> : <FontAwesome name="times" />}
      </Button>
      <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
        <ConfirmBox
          isOpen={isOpen}
          closeConfirmBox={closeConfirmBox}
          avoidConfirmBox={toogleConfirmBox}
          removeTag={remove}
          type="clave de acceso"
          message="Esta acción no es reversible y el dispositivo podría dejar de funcionar."
          resource={deviceKey.apiKey}
        />
      </ShowForRoles>
    </td>
  </tr>
);

export default enhance(DeviceKeyRow);
