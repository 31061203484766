import { FontAwesome, ShowForRoles } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

export const BuildingToolNav = ({ currentBuilding, showAdminDivider }) => (
  <div>
    <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}>
      <div>
        <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding']}>
          <DropdownItem tag={NavLink} to="/building/telemetries">
            <FontAwesome name="tachometer-alt" className="mr-2" /> Telemetría
          </DropdownItem>
        </ShowForRoles>
        <ShowForRoles accepted={['organization_admin', 'guest']}>
          <DropdownItem tag={NavLink} to="/building/devices">
            <FontAwesome name="mobile-alt" className="mr-2" /> Dispositivos
          </DropdownItem>
          <DropdownItem tag={NavLink} to="/building/tags/dashboard">
            <FontAwesome name="tags" className="mr-2" /> Tags
          </DropdownItem>
        </ShowForRoles>
        <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
          <DropdownItem tag={NavLink} to="/building/readouts">
            <FontAwesome name="history" className="mr-2" />
            Lecturas
          </DropdownItem>
        </ShowForRoles>
      </div>
    </ShowForRoles>
    <ShowForRoles accepted={['organization_admin', 'guest']}>
      <div>
        <DropdownItem tag={NavLink} to="/building/areas">
          <FontAwesome name="layer-group" className="mr-2" /> Áreas
        </DropdownItem>
      </div>
    </ShowForRoles>
    <ShowForRoles accepted={['organization_admin']}>
      <div>
        <DropdownItem tag={NavLink} to="/building/transfer-asset">
          <FontAwesome name="exchange-alt" className="mr-2" /> Transferir Activos
        </DropdownItem>
      </div>
    </ShowForRoles>
    <ShowForRoles accepted={['organization_admin']}>
      <DropdownItem tag={NavLink} to="/building/expected_stocks">
        <FontAwesome name="cubes" className="mr-2" /> Carga de Stocks
      </DropdownItem>
      <DropdownItem tag={NavLink} to="/building/device_expected_stocks">
        <FontAwesome name="cubes" className="mr-2" /> Carga de Stocks de Dispositivos
      </DropdownItem>
    </ShowForRoles>
    <ShowForRoles accepted={['organization_admin']}>
      <DropdownItem tag={NavLink} to="/organization/transfer_consumables">
        <FontAwesome name="exchange-alt" className="mr-2" /> Transferencia de consumibles
      </DropdownItem>
    </ShowForRoles>
    {currentBuilding?.organization?.hasCostAccounts && (
      <ShowForRoles accepted={['organization_admin']}>
        <DropdownItem tag={NavLink} to="/building/cost-accounts">
          <FontAwesome name="wallet" className="mr-2" /> Centro de costos
        </DropdownItem>
      </ShowForRoles>
    )}
  </div>
);
