import { getMovements } from '@core/building/movements';
import { FontAwesome, simpleFilterQuery, Spinner } from '@shared';
import { MovementsTable } from '@shared/movements';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

export const AssetMovements = ({ asset }) => {
  const [movements, setMovements] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMovements = async (asset) => {
      setLoading(true);
      const { body, status } = await getMovements({ assets: asset.id, paginate: 'no_count', pp: 6 });

      if (status === 200) {
        setMovements(body);
        setLoading(false);
      }
    };

    fetchMovements(asset);
  }, [asset]);

  if (loading) {
    return (
      <div className="mt-4 mb-4 text-info text-center h2">
        <Spinner />
      </div>
    );
  }

  return (
    <div>
      <MovementsTable movements={movements} showTarget={false} />

      {movements.length >= 5 && (
        <div className="mt-1 text-center">
          <Button
            role="button"
            color="primary"
            tag={NavLink}
            to={`/movements?q=${simpleFilterQuery({
              consumable: 'false',
              'assets[{}]': [{ label: asset.barcode1, value: asset.id }],
            })}`}
          >
            <FontAwesome name="search" /> Ver todos
          </Button>
        </div>
      )}
    </div>
  );
};
