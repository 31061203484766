import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { CostAccountRow } from './CostAccountRow';
import ShowForRoles from '@shared/ShowForRoles';

export const CostAccountsResults = ({ costAccounts = [], pagination, searchFunction }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'grupo encontrada', 'grupos encontradas')}.
      Mostrando <strong>{pagination.per}</strong> centros de costos por página.
    </div>

    <Table hover size="sm">
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <ShowForRoles accepted={['organization_admin']}>
          <col style={{ width: 'auto' }} />
        </ShowForRoles>
      </colgroup>
      <thead>
        <tr>
          <th>Código</th>
          <th>Nombre</th>
          <ShowForRoles accepted={['organization_admin']}>
            <th className="text-center">Acciones</th>
          </ShowForRoles>
        </tr>
      </thead>
      <tbody>
        {costAccounts.map((costAccount, index) => (
          <CostAccountRow key={`costAccount-${index}`} costAccount={costAccount} searchFunction={searchFunction} />
        ))}

        {costAccounts.length === 0 && (
          <tr>
            <td colSpan="5" className="text-center mt-3 mb-3">
              <em>No se encontraron centros de costos.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
