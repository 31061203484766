import unwrap from './unwrap';

function presence({ value, message }) {
  if (value === null || value === undefined || value === '')
    return {
      valid: false,
      message: message || 'No puede estar en blanco.',
    };
  else
    return {
      valid: true,
    };
}

export default unwrap(presence);
