import { fromIso8601 } from '@core/utils/dates';
import { Barcode, ConfirmBox, FontAwesome, PictureDisplay, Spinner } from '@shared';
import { connect } from '@store';
import { upperCase } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button, Media } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';

const enhance = compose(
  connect(({ appData: { device_types: deviceTypes } }) => {
    const opts = {};
    deviceTypes.forEach(({ id, name }) => {
      opts[id] = upperCase(name);
    });
    return {
      deviceTypes: opts,
    };
  }),
  withState('loading', 'setLoading', false),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    remove: ({ setLoading, softDelete, setIsOpen }) => async () => {
      setLoading('remove');

      await softDelete();

      setLoading(false);
      setIsOpen(false);
    },
  }),
  withHandlers({
    toggleStatus: ({ setLoading, toggleDevice }) => async () => {
      setLoading('toggle');

      await toggleDevice();

      setLoading(false);
    },
    openConfirmBox: ({ setIsOpen, displayConfirmBox, remove }) => () => {
      if (displayConfirmBox) {
        setIsOpen(true);
      } else {
        remove();
      }
    },
    closeConfirmBox: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
  }),
);

const DeviceRowPresentation = ({
  device,
  isOpen,
  openConfirmBox,
  closeConfirmBox,
  toogleConfirmBox,
  remove,
  toggleStatus,
  loading,
}) => (
  <tr>
    <th scope="row" className="align-middle small">
      <Media className="align-items-center">
        <Media left className="mr-2">
          <Barcode code={device.barcode1} style={{ height: '2.5rem' }} />{' '}
        </Media>
        <Media body>
          <NavLink to={`/admin/devices/${device.barcode1}`}>
            <kbd>{device.barcode1}</kbd>
            <FontAwesome name="search" className="ml-1" />
          </NavLink>
          <div className="mt-1">
            <div className="w-100">
              {device.active && (
                <Badge color="success">
                  <FontAwesome name="check" className="mr-1" /> Activo
                </Badge>
              )}
              {!device.active && (
                <Badge color="danger">
                  <FontAwesome name="times" className="mr-1" /> Inactivo
                </Badge>
              )}
            </div>
          </div>
        </Media>
      </Media>
    </th>
    <td className="align-middle small">
      <Media className="align-items-center">
        <Media left className="mr-1" tag="div">
          <PictureDisplay
            picture={device.defaultPicture}
            pictureSize="icon"
            alt={device.name}
            width="3rem"
            height="2rem"
          />
        </Media>
        <Media body tag="div">
          <NavLink to={`/admin/devices/${device.barcode1}`}>{device.name} </NavLink>
          <div className="w-100 text-overflow">
            <span className="text-muted mr-1">
              <FontAwesome name="caret-right" className="mr-1" />
              Modelo: {device.deviceModel}
            </span>
          </div>
        </Media>
      </Media>
    </td>
    <td className="align-middle text-center small">{device.deviceType && device.deviceType.description}</td>
    <td className="align-middle text-center small">
      {fromIso8601(
        device.detectedAt
          ? device.detectedAt
          : (device?.deviceType?.name === 'vending' || device?.deviceType?.name === 'lockers') && device.updatedAt,
      )}
    </td>
    <td className="align-middle text-right">
      <Button role="button" size="sm" color="primary" tag={NavLink} to={`/admin/devices/${device.barcode1}/edit`}>
        <FontAwesome name="pencil-alt" />
      </Button>{' '}
      <Button role="button" size="sm" color={device.active ? 'warning' : 'success'} onClick={toggleStatus}>
        <FontAwesome name="power-off" />
      </Button>{' '}
      <Button role="button" size="sm" color="danger" onClick={openConfirmBox}>
        {loading === 'remove' ? <Spinner /> : <FontAwesome name="times" />}
      </Button>{' '}
      <ConfirmBox
        isOpen={isOpen}
        avoidConfirmBox={toogleConfirmBox}
        closeConfirmBox={closeConfirmBox}
        removeTag={remove}
        type="dispositivo"
        resource={device.name}
      />
    </td>
  </tr>
);

export const DeviceRow = enhance(DeviceRowPresentation);
