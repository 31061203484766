import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { FontAwesome, FileIcon } from '@shared';
import { fromIso8601 } from '@core/utils/dates';
import { fileInfoFor } from '@core/utils/mime-types';
import { fileSize } from '@core/utils/number-formatting';

import './AttachmentDisplay.scss';

export const AttachmentDisplay = ({ attachment }) => {
  const info = fileInfoFor(attachment.contentType);

  return (
    <Card className="attachment-display" tag="a" href={attachment.file.url} target="_blank">
      <CardBody>
        <div className="attachment-type">
          <FileIcon type={info.icon} width="2rem" />
        </div>
        <div className="attachment-info">
          <span className="unrevel text-muted">{attachment.title}</span>
          <span className="unrevel text-muted">
            <strong>Fecha:&nbsp;</strong> {fromIso8601(attachment.createdAt)}
          </span>

          <span className="revel text-muted">
            <strong>Tipo:&nbsp;</strong> {info.description || <em>Sin información</em>}
          </span>
          <span className="revel text-muted">
            <strong>Tamaño:&nbsp;</strong>
            {(attachment.size && fileSize(attachment.size)) || <em>Sin información</em>}
          </span>
        </div>
        <div className="attachment-download revel">
          <FontAwesome name={'download'} />
        </div>
      </CardBody>
    </Card>
  );
};
