import { apiNamespace } from '@config';
import { refreshToken } from '@core/login';
import { exceptionsGenerators, notificationsGenerators, sessionGenerators, store } from '@store';
import { get } from 'lodash';
import { deepCamelKeys, deepSnakeKeys } from './utils/deep-transform-keys';

let CODE_ERRORS = {
  DISABLED_BUILDING: 'DisabledBuilding',
};

let TIMEZONE_OFFSET = undefined;

const getTimezoneOffset = () => {
  if (!TIMEZONE_OFFSET) {
    TIMEZONE_OFFSET = new Date().getTimezoneOffset();
  }
  return TIMEZONE_OFFSET;
};

async function processResponse(response, parseResponse = true) {
  let json = {};

  try {
    json = await response.json();
  } catch (error) {
    // Nothing yet...
  }

  const { status, headers } = response;

  if (status.toString().startsWith('5')) {
    store.dispatch(exceptionsGenerators.toggle500());
  }

  if (status === '403')
    store.dispatch(
      notificationsGenerators.insert({
        title: 'No autorizado',
        text:
          'No cuentas con los permisos adecuados para realizar esta operación. Esto puede deberse, por ejemplo, a que no has confirmado tu dirección de correo. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
        duration: 20000,
      }),
    );

  if (json && json['$paginated']) {
    const { data, page, per, total, count, extras } = json;

    return {
      body: parseResponse ? deepCamelKeys(data) : data,
      _multiPage: true,
      pagination: { page, per, total, count },
      extras: parseResponse ? deepCamelKeys(extras) : extras,
      status,
      headers,
    };
  }

  return {
    body: parseResponse ? deepCamelKeys(json) : json,
    status,
    headers,
    _multiPage: false,
  };
}

export async function apiRequest({
  base = `${apiNamespace}`,
  endpoint,
  authHeaders,
  method,
  file,
  bodyObject,
  headers,
  parseResponse = true,
  ...settings
}) {
  try {
    const response = await fetch(`${base}${endpoint}`, {
      method,
      mode: 'cors',
      headers: {
        Accept: 'application/json, text/plain, */*',
        ...headers,
        ...authHeaders,
        'X-Client-TimezoneOffset': getTimezoneOffset(),
      },
      ...bodyObject,
      ...settings,
    });

    if (file === true) return response;
    else return await processResponse(response, parseResponse);
  } catch (error) {
    store.dispatch(exceptionsGenerators.toggleInternetException());

    return { body: {}, status: 0, headers: {} };
  }
}

export async function apiFetch({
  base = `${apiNamespace}`,
  endpoint,
  authHeaders,
  method,
  body,
  headers,
  parseBody = true,
  parseResponse = true,
  ...settings
}) {
  let bodyObject = {};

  if (body !== undefined && body !== null)
    bodyObject = {
      body: JSON.stringify(parseBody ? deepSnakeKeys(body) : body),
    };

  return await apiRequest({
    base,
    endpoint,
    authHeaders,
    method,
    bodyObject,
    parseResponse,
    headers: { ...headers, 'Content-Type': 'application/json' },
    ...settings,
  });
}

export async function loadFile({
  base = `${apiNamespace}`,
  endpoint,
  authHeaders,
  name,
  files,
  method,
  headers,
  parseResponse = true,
  ...settings
}) {
  let bodyObject = { body: new FormData() };

  bodyObject.body.append(name, files);

  return await apiRequest({
    base,
    endpoint,
    authHeaders,
    method,
    bodyObject,
    headers,
    parseResponse,
    ...settings,
  });
}

export function withToken(method) {
  return (props) => {
    // Snapshot of the current store state
    // at function runtime.
    const { session } = store.getState();

    let authHeaders = {};

    if (session.token) authHeaders['Authorization'] = `User ${session.token}`;

    return method({ ...props, authHeaders });
  };
}

export function withMembership(method, appendBuilding = true) {
  return (props) => {
    // Snapshot of the current store state
    // at function runtime.
    const { session } = store.getState();

    let authHeaders = {};

    if (session.token) authHeaders['Authorization'] = `User ${session.token}`;

    if (session.token && session.currentMembership)
      authHeaders['X-AlliotCloud-MembershipId'] = session.currentMembership;

    let buildingId = undefined;

    if (session.currentMembership && session.user) {
      const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

      if (membership) buildingId = membership.building.id;
    }

    if (appendBuilding && props.endpoint) props.endpoint = `/buildings/${buildingId}${props.endpoint}`;

    return method({ ...props, authHeaders, buildingId });
  };
}

const handle403Error = async (error) => {
  const errorCode = get(error, 'errors.code');
  switch (errorCode) {
    case CODE_ERRORS.DISABLED_BUILDING:
      const { body } = await refreshToken();
      store.dispatch(sessionGenerators.login(body));
      store.dispatch(sessionGenerators.forceSetMembership());
      store.dispatch(
        notificationsGenerators.insert({
          title: 'Instalación u Organización no disponible',
          text:
            'No es posible acceder a la instalación seleccionada, por favor re intente nuevamente con una diferente o contacte a un administrador.',
          color: 'danger',
          duration: 20000,
        }),
      );
      break;
    default:
      store.dispatch(
        notificationsGenerators.insert({
          title: 'No autorizado',
          text:
            'No te encuentras autorizado para realizar esta operación. Si los errores persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      );
  }
};

export async function handleAuth(authenticatedPromise) {
  const response = await authenticatedPromise;
  const { status, body } = response;

  if (status === 401) store.dispatch(exceptionsGenerators.toggle401());

  if (status === 403) {
    await handle403Error(body);
  }

  return response;
}

export function mountQuery(query) {
  let items = [];

  if (query && Object.keys(query).length > 0)
    items = Object.keys(query)
      .filter(
        (key) =>
          query[key] !== undefined &&
          query[key] !== null &&
          query[key] !== '' &&
          (!Array.isArray(query[key]) || query[key].length > 0),
      )
      .map((key) => `${key}=${encodeURI(query[key])}`);

  return items.length > 0 ? '?' + items.join('&') : '';
}

export const buildingDownload = (file, filters) =>
  handleAuth(
    withMembership(apiRequest)({
      endpoint: `/${file + mountQuery(filters)}`,
      method: 'GET',
      file: true,
    }),
  );

export const adminDownload = (file, filters, options = {}) =>
  handleAuth(
    withToken(apiRequest)({
      endpoint: `/admin/${file + mountQuery(filters)}`,
      method: 'GET',
      file: true,
      ...options,
    }),
  );

export const buildingSendEmail = ({ file, filters }) =>
  handleAuth(
    withMembership(apiRequest)({
      endpoint: `/${file + mountQuery(filters)}`,
      method: 'GET',
      file: true,
    }),
  );
