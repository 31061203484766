import React from 'react';
import { Pagination as PaginationLayout, PaginationItem, PaginationLink } from 'reactstrap';

const Pagination = ({ page = 1, total, radius = 2, size, className = '', setPage = console.log }) => {
  const min = Math.max(1, page - radius);
  const max = Math.min(total, page + radius);
  const pages = [];

  for (let i = min; i <= max; i++) pages.push(i);

  return (
    <PaginationLayout role="button" size={size} className={`justify-content-center d-flex ${className}`}>
      <PaginationItem disabled={page === 1}>
        <PaginationLink role="button" onClick={() => setPage(page - 1)}>
          &laquo;
        </PaginationLink>
      </PaginationItem>

      {page - radius - 1 > 1 && (
        <PaginationItem>
          <PaginationLink role="button" onClick={() => setPage(1)}>
            1
          </PaginationLink>
        </PaginationItem>
      )}

      {page - radius - 1 > 1 && (
        <PaginationItem disabled>
          <PaginationLink role="button">...</PaginationLink>
        </PaginationItem>
      )}

      {pages.map((p, index) => (
        <PaginationItem active={p === page} key={index}>
          <PaginationLink role="button" onClick={() => setPage(p)}>
            {p}
          </PaginationLink>
        </PaginationItem>
      ))}

      {page + radius + 1 < total && (
        <PaginationItem disabled>
          <PaginationLink role="button">...</PaginationLink>
        </PaginationItem>
      )}

      {page + radius + 1 < total && (
        <PaginationItem>
          <PaginationLink role="button" onClick={() => setPage(total)}>
            {total}
          </PaginationLink>
        </PaginationItem>
      )}

      <PaginationItem disabled={page === total}>
        <PaginationLink role="button" onClick={() => setPage(page + 1)}>
          &raquo;
        </PaginationLink>
      </PaginationItem>
    </PaginationLayout>
  );
};

export default Pagination;
