import React from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import { getUsers } from '@core/building/users';
import ChileanRUT from '@core/utils/chilean-rut';
import AsyncSelect from 'react-select/async';
import { getInventoryCheckerSets } from '@core/building/inventory-checker-sets';

export class InventoryCheckerSetsFilterModal extends SimpleFilterModal {
  findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return body.map(({ firstName, lastName, identifier, id }) => ({
        label: `${firstName} ${lastName} - ${
          identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
        }`,
        value: id,
      }));
    }
    return [];
  };

  findInventoryCheckerSets = async (value) => {
    const { body, status } = await getInventoryCheckerSets({ paginate: false, search: value });
    if (status === 200) {
      return body.map(({ requestNumber }) => ({
        label: requestNumber,
        value: requestNumber,
      }));
    }
    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="requests[{}]">Número de requerimiento</Label>
              <AsyncSelect
                name="requests[{}]"
                value={this.state['requests[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findInventoryCheckerSets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="auditors[{}]">Auditores</Label>
              <AsyncSelect
                name="auditors[{}]"
                value={this.state['auditors[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
