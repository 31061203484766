import React from 'react';

import DateSelector from './DateSelector';

const DateTimeSelector = ({ showTimeSelect = true, ...props }) => (
  <DateSelector
    {...props}
    showTimeSelect={showTimeSelect}
    dateFormat={`yyyy-MM-dd ${showTimeSelect ? 'h:mm a' : ''}`}
  />
);

export default DateTimeSelector;
