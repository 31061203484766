import numberCheck from './number-check';
import unwrap from './unwrap';

function numericality({ value, lessThan, greaterThan, lessThanOrEqualTo, greaterThanOrEqualTo, message }) {
  let errors = numberCheck({
    value,
    lessThan,
    greaterThan,
    lessThanOrEqualTo,
    greaterThanOrEqualTo,
  });

  if (errors.length > 0)
    return {
      valid: false,
      message:
        (message && (typeof message === 'function' ? message(errors) : message)) ||
        `No es válido. El número ${errors.join(', ')}.`,
    };

  return {
    valid: true,
  };
}

export default unwrap(numericality);
