import { Field, Form } from '@core/forms';
import { DateSelector } from '@shared/forms';
import React, { useState, useEffect } from 'react';
import { Col, Row, Label } from 'reactstrap';
import { store } from '@store';
import { MaintenanceValidityPeriodModal } from './MaintenanceValidityPeriodModal';

export const MaintenanceValidityPeriod = ({ onChange, asset }) => {
  const [title, setTitle] = useState(undefined);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);
  const [isCustomTitle, setIsCustomTitle] = useState(false);

  const {
    appData: { validity_period_titles: validityPeriodTitles },
  } = store.getState();

  const options = { '': 'Seleccione título...' };

  Object.keys(validityPeriodTitles).forEach((element) => {
    options[validityPeriodTitles[element]] = validityPeriodTitles[element];
  });

  options['Otro'] = 'Otro';

  useEffect(() => {
    const create = () => {
      const invalid = !((!title && !from && !to) || (title && from && to));
      onChange({ title, from, to, invalid });
    };
    create();
  }, [title, from, to, onChange]);

  return (
    <Form>
      <Label className="mb-3">Nuevo período de vigencia</Label>
      <Row>
        <Col className="text-left">
          <Field
            name="title"
            type="select"
            label="Tipo de Vigencia"
            options={options}
            onChange={(e) => {
              setTitle(e.target.value);
              setIsCustomTitle(e.target.value === 'Otro');
            }}
          />
          {isCustomTitle && (
            <Field
              name="title"
              type="text"
              label="Ingrese un título personalizado"
              onChange={(e) => {
                setTitle(e.target.value);
                setIsCustomTitle(true);
              }}
            />
          )}
        </Col>
        <Col className="text-left">
          <Field
            name="from"
            label="Desde"
            value={from}
            onChange={setFrom}
            component={DateSelector}
            minDate={new Date()}
            maxDate={to ? to : null}
            autoComplete="on"
          />
        </Col>
        <Col className="text-left">
          <Field
            name="to"
            label="Hasta"
            value={to}
            onChange={setTo}
            component={DateSelector}
            minDate={from ? from : new Date()}
            autoComplete="on"
          />
        </Col>
      </Row>
      <div className="form-text text-muted mb-3">
        Para un período de vigencia válido, debe completar los tres campos, de lo contrario, dejar en blanco.
      </div>
      <MaintenanceValidityPeriodModal asset={asset} />
    </Form>
  );
};
