import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { FontAwesome } from '@shared/';
import { NavLink } from 'react-router-dom';

export const MergeResultStatusModal = ({ isOpen, onToggle, message, status, redirectToMaster, redirectTo }) => {
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader toggle={onToggle}>
        {status === 200 ? (
          <FontAwesome name="check" color="success" className="text-primary mr-2" />
        ) : (
          <FontAwesome name="ban" color="danger" className="text-primary mr-2" />
        )}{' '}
        {status === 200 ? 'Combinación Exitosa' : 'Hubo un error'}
      </ModalHeader>
      <ModalBody>{status === 200 ? 'La combinación del Artículo fue éxitosa' : `Error${status}: ${message}`}</ModalBody>
      <ModalFooter>
        {status === 200 ? (
          <Button role="button" color="success" tag={NavLink} to={redirectTo}>
            OK
          </Button>
        ) : (
          <Button color="primary" onClick={onToggle}>
            OK
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
