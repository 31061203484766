import { Barcode, FontAwesome, PictureDisplay, ProcessingButton, ShowForRoles } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Media } from 'reactstrap';

export const AreaRow = ({ area, onDelete, processing }) => (
  <tr>
    <th scope="row" className="text-center align-middle">
      {area.id}
    </th>
    <td className="align-middle">
      <div className="d-flex">
        <Media>
          <Media>
            <Barcode code={area.barcode1} style={{ width: '64px' }} />
          </Media>
          <Media left className="mr-2">
            <PictureDisplay picture={area.picture} pictureSize="icon" alt={area.name} width="6rem" height="4rem" />
          </Media>
          <Media body className="align-left d-flex" style={{ height: '100%', alignItems: 'left' }}>
            <div>
              <div>{area.name}</div>
              <div className="small">
                <kbd>{area.barcode1}</kbd>
              </div>
            </div>
          </Media>
        </Media>
      </div>
    </td>
    <td className="align-middle text-center">{area.location}</td>
    <td className="align-middle text-right">
      <ShowForRoles excluded={['guest']}>
        <Button role="button" size="sm" color="primary" tag={NavLink} to={`/building/areas/${area.id}`}>
          <FontAwesome name="pencil-alt" />
        </Button>{' '}
        <ProcessingButton role="button" size="sm" color="danger" onClick={onDelete} processing={processing}>
          <FontAwesome name="times" />
        </ProcessingButton>
      </ShowForRoles>
    </td>
  </tr>
);
