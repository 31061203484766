import { status } from '@core/building/users';
import ChileanRUT from '@core/utils/chilean-rut';
import { shortName } from '@core/utils/names';
import { FontAwesome, UserPortrait } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';

const UserDisplay = ({ user }) => (
  <Media className="align-items-center">
    <Media right className="mr-2">
      <UserPortrait user={user} width="2.5rem" height="2.5rem" size="medium" />
    </Media>
    <Media body>
      <div>
        <strong>
          {user.deletedAt ? (
            <div className="text-danger">
              <FontAwesome name="user" className="mr-1" />
              {shortName(user)}
            </div>
          ) : (
            <NavLink to={`/admin/users/${user.id}`}>
              <FontAwesome name="user" className="mr-1" />
              {shortName(user)}
            </NavLink>
          )}
        </strong>
      </div>
      <div className="d-flex justify-content-between">
        <span>Identificación:</span>
        <span className="text-muted">{ChileanRUT.PartialFormat(user.identifier.value)}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span>Estado:</span>
        {user.deletedAt ? <div className="text-danger">Eliminado</div> : status(user.status)}
      </div>
    </Media>
  </Media>
);

export default UserDisplay;
