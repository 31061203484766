import { combineReducers, compose, createStore } from 'redux';
/**
 * Vendors
 */
import { reducer as form } from 'redux-form';
import persistState from 'redux-localstorage';
import { appData } from './app-data';
import { deskSession } from './desk-session';
import { dispatcher } from './dispatcher';
import { exceptions } from './exceptions';
import { notifications } from './notifications';
/**
 * Reducers imported.
 */
import { session } from './session';
import { subscriptions } from './subscriptions';

/**
 * Export generators from each function.
 */
/**
 * Export a copy of redux connect
 */
export { connect } from 'react-redux';
export { appDataGenerators } from './app-data';
export { deskSessionGenerators } from './desk-session';
export { dispatcherGenerators } from './dispatcher';
export { exceptionsGenerators } from './exceptions';
export { notificationsGenerators } from './notifications';
export { sessionGenerators } from './session';
export { subscriptionsGenerators } from './subscriptions';

/**
 * Enhacer composition through middleware
 */
let enhancers = [persistState(['appData', 'deskSession', 'session'])];

process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());

const enhancer = compose(...enhancers);

/**
 * Final store to be used
 */
export const store = createStore(
  combineReducers({
    session,
    notifications,
    exceptions,
    appData,
    form,
    deskSession,
    subscriptions: subscriptions,
    dispatcher,
  }),
  enhancer,
);
