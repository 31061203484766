import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';

import { FontAwesome } from '@shared';

import { UserCard } from '../../admin/users/components/UserCard';

const UserLayout = ({ isAdmin, user, children, onUpdate, routes = [], match }) => (
  <div className="container-fluid mt-4 mb-4">
    <Row className="justify-content-center">
      <Col xs={12} md={4} lg={3}>
        <UserCard user={user} isAdmin={isAdmin} onUpdate={onUpdate} />
      </Col>
      <Col xs={12} md={8} lg={9}>
        <Card>
          <CardHeader>
            <Nav className="nav-tabs card-header-tabs">
              {routes.map((route, index) => {
                const path = `${match.url}/${route.link}`;
                return (
                  <NavItem key={index}>
                    <NavLink role="button" tag={Link} exact={route.exact === undefined ? true : route.exact} to={path}>
                      <FontAwesome name={route.icon} /> {route.text}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
          </CardHeader>
          <CardBody>{children}</CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export { UserLayout };
