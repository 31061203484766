import React, { Component } from 'react';
import { Alert, Button, Table } from 'reactstrap';

import { connect, store } from '@store';

import MembershipBuildingInput from './components/MembershipBuildingInput';

const MembershipsBuildingManager = (createMembership, destroyMembership) =>
  class extends Component {
    state = {
      memberships: [],
      currentBuilding: undefined,
    };

    componentDidMount() {
      const { user } = this.props;
      const { session } = store.getState();
      const currentBuilding = session.user?.memberships.find(
        (membership) => membership.id === session.currentMembership,
      )?.building;

      this.setState({ user, memberships: user?.memberships, currentBuilding });
    }

    UNSAFE_componentWillReceiveProps({ user }) {
      this.setState({ user, memberships: user?.memberships });
    }

    onNew = () => {
      this.setState({
        memberships: [
          ...this.state.memberships,
          {
            id: `n_${this.state.memberships?.length + 1}-${Date.now()}`,
            building: this.state.currentBuilding,
            role: undefined,
          },
        ],
      });
    };

    onCreate = (index, membership) => {
      this.setState({
        memberships: [
          ...this.state.memberships.slice(0, index),
          membership,
          ...this.state.memberships.slice(index + 1),
        ],
      });
    };

    onDelete = (index) => {
      this.setState({
        memberships: [...this.state.memberships.slice(0, index), ...this.state.memberships.slice(index + 1)],
      });
    };

    beforeDestroy = async () => {
      const { memberships } = this.state;
      const { beforeDestroy } = this.props;

      if (!beforeDestroy) return true;
      else return await beforeDestroy(memberships);
    };

    render() {
      const { memberships, currentBuilding } = this.state;
      const { user } = this.props;

      return (
        <MembershipsManagerForm
          user={user}
          memberships={memberships}
          onNew={this.onNew}
          onDelete={this.onDelete}
          onCreate={this.onCreate}
          createMembership={createMembership}
          destroyMembership={destroyMembership}
          beforeDestroy={this.beforeDestroy}
          currentBuilding={currentBuilding}
        />
      );
    }
  };

const enhance = connect(({ appData: { roles } }) => ({
  roles,
}));

const MembershipsManagerFormPresentation = ({
  user,
  memberships,
  onNew,
  onDelete,
  onCreate,
  roles,
  beforeDestroy,
  createMembership,
  destroyMembership,
  currentBuilding,
}) => (
  <div>
    <Table>
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '70px' }} />
      </colgroup>
      <thead>
        <tr>
          <th className="text-left">Instalación</th>
          <th className="text-center">Rol</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {memberships &&
          memberships.map((membership, index) => (
            <MembershipBuildingInput
              index={index}
              membership={membership}
              key={membership.id}
              roles={roles}
              onDelete={onDelete}
              onCreate={onCreate}
              beforeDestroy={beforeDestroy}
              createMembership={createMembership}
              destroyMembership={destroyMembership}
              userId={user.id}
              currentBuilding={currentBuilding}
            />
          ))}

        {memberships && memberships?.length === 0 && (
          <tr>
            <td colSpan="3" className="text-center">
              <Alert color="warning">El usuario no tiene membresías en esta instalación todavía</Alert>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
    <div className="text-center">
      <Button role="button" color="primary" outline onClick={onNew}>
        Agregar rol
      </Button>
    </div>
  </div>
);

const MembershipsManagerForm = enhance(MembershipsManagerFormPresentation);

export default MembershipsBuildingManager;
