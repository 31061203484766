import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Row, Col } from 'reactstrap';

import { updateAccount } from '@core/account';
import { Field, Form, SubmissionError } from '@core/forms';
import Validates, { format, presence } from '@core/forms/validations';
import { connect, notificationsGenerators, sessionGenerators } from '@store';
import { ProcessingButton } from '@shared';
import { SinglePictureComponent } from '@shared/forms';

const PictureComponent = SinglePictureComponent((token) => `/avatars/${token}`, '/avatars');

class Profile extends Component {
  state = {
    processing: false,
  };

  editAccount = async (formData) => {
    this.setState({
      processing: true,
    });

    const { currentPassword, firstName, lastName, phone, avatarToken } = formData;

    const { body, status } = await updateAccount({
      currentPassword,
      firstName,
      lastName,
      phone,
      avatarToken,
    });

    this.setState({
      processing: false,
    });

    if (status === 204 || status === 200) {
      this.props.successNotification();

      this.props.updateUser({
        firstName,
        lastName,
        phone,
        avatar: body.avatar,
      });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const userData = (({ firstName, lastName, phone, avatarToken }) => ({
      firstName,
      lastName,
      phone,
      avatarToken,
    }))(this.props.user);

    return (
      <ProfileForm
        initialValues={userData}
        onSubmit={this.editAccount}
        processing={this.state.processing}
        errors={this.state.errors}
      />
    );
  }
}

const validate = Validates(
  presence({ of: 'currentPassword' }),
  presence({ of: 'firstName' }),
  presence({ of: 'lastName' }),
  format({
    of: 'phone',
    with: /^\+\d{5,}$/gi,
    message: 'Debe ser de la forma +56 ...',
  }),
);

const ProfileFormPresentation = ({ handleSubmit, invalid, submitting, pristine, error }) => {
  return (
    <Form error={error} noValidate onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field name="firstName" type="text" label="Nombre(s)" required />
        </Col>
        <Col>
          <Field name="lastName" type="text" label="Apellido(s)" required />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field name="phone" type="text" label="Teléfono" required />
        </Col>
        <Col>
          <Field name="currentPassword" type="password" label="Contraseña actual" required />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="avatarToken"
            component={PictureComponent}
            label="Imagen de perfil"
            required
            help="Debe ser una imagen jpg, jpeg, gif ó png. Se recomienda una imagen aproximadamente cuadrada de 800 x 800 píxeles. El servidor procesa la imagen para generar múltiples versiones con tamaños adecuados."
          />
        </Col>
      </Row>

      <hr />

      <div className="text-center">
        <ProcessingButton
          type="submit"
          color="primary"
          outline
          width="10em"
          disabled={pristine || invalid}
          processing={submitting}
        >
          Guardar
        </ProcessingButton>
      </div>
    </Form>
  );
};

const ProfileForm = reduxForm({
  form: 'profileForm',
  validate,
})(ProfileFormPresentation);

export default connect(
  (state) => ({ user: state.session.user }),
  (dispatch) => ({
    successNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Cuenta actualizada',
          text: 'Tu cuenta ha sido actualizada exitosamente.',
          color: 'success',
        }),
      ),
    updateUser: (data) => dispatch(sessionGenerators.updateUser(data)),
  }),
)(Profile);
