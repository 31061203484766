import { Field, Form } from '@core/forms';
import Validates, { numericality, presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { BuildingSelector, DeviceBrandSelector } from '@shared/forms';
import { connect } from '@store';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { DeviceMetadata } from './DeviceMetadata';

const validate = Validates(
  presence({ of: 'name' }),
  presence({ of: 'description' }),
  presence({ of: 'buildingId' }),
  presence({ of: 'deviceBrandId' }),
  presence({ of: 'deviceModel' }),
  presence({ of: 'deviceType' }),
  numericality({ of: 'deviceType', greaterThan: 0 }),
);

class DeviceFormPresentation extends Component {
  state = {
    metadata: [],
    selected: false,
  };

  UNSAFE_componentWillMount() {
    const initialValues = this.props.initialValues;
    if (initialValues) {
      this.filterDevice(initialValues.deviceType);
    }
  }

  filterDevice = (deviceType) => {
    switch (deviceType) {
      case 2:
        this.setState({
          metadata: ['serialNumber', 'imei', 'port'],
          selected: true,
        });
        break;
      default:
        this.setState({
          selected: false,
        });
    }
  };

  render() {
    const { handleSubmit, invalid, submitting, pristine, error, deviceTypes } = this.props;
    const { selected, metadata } = this.state;

    return (
      <Form error={error} noValidate onSubmit={handleSubmit}>
        <h4 className="mb-3">Información básica</h4>

        <Row>
          <Col>
            <Field name="buildingId" label="Instalación" component={BuildingSelector} required />
          </Col>
          <Col>
            <Field
              name="deviceType"
              type="select"
              label="Tipo de dispositivo"
              parse={parseInt}
              options={deviceTypes}
              onChange={(_e, v) => this.filterDevice(parseInt(v, 10))}
              required
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Field
              name="name"
              label="Nombre del dispositivo"
              help="Nombre completo del dispositivo, especificando marca, modelo y/o elementos distintivos."
              required
            />
          </Col>
          <Col>
            <Row>
              <Col>
                <Field
                  name="identifier"
                  label="Identificador/MAC"
                  help="Código MAC que identifica al gateway (Obligatorio para gateways)"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="lat"
                  label="Latitud"
                  help="Latitud de la ubicación del dispositivo. (Obligatorio para gateways)"
                />
              </Col>
              <Col>
                <Field
                  name="lng"
                  label="Longitud"
                  help="Longitud de la ubicación del dispositivo. (Obligatorio para gateways)"
                />
              </Col>
              <Col>
                <Field
                  name="radius"
                  label="Radio"
                  type="number"
                  help="Longitud desde la ubicación del dispositivo en metros (centro)."
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field name="address" label="Dirección" help="Nombre o dirección de la ubicación del dispositivo. " />
              </Col>
            </Row>
          </Col>
        </Row>

        <hr />

        {selected && <DeviceMetadata params={metadata} />}

        <Row>
          <Col>
            <Field name="deviceBrandId" label="Marca del dispositivo" component={DeviceBrandSelector} required />
          </Col>
          <Col>
            <Field
              name="deviceModel"
              label="Modelo del dispositivo"
              help="Especificaciones del modelo del dispositivo y/o elementos distintivos."
              required
            />
          </Col>
        </Row>

        <Field
          name="description"
          type="textarea"
          rows="6"
          label="Descripción"
          help="Descripción detallada del dispositivo, indicando características distintivas e instrucciones."
          required
        />

        <hr />

        <div className="text-center">
          <ProcessingButton
            type="submit"
            role="button"
            outline
            color="primary"
            width="10em"
            disabled={pristine || invalid}
            processing={submitting}
          >
            Guardar
          </ProcessingButton>
        </div>
      </Form>
    );
  }
}

const enhance = compose(
  connect(({ appData: { device_types: deviceTypes } }) => {
    const options = { 0: 'Seleccione...' };
    deviceTypes.forEach((dt) => (options[dt.id] = dt.description));
    return { deviceTypes: options };
  }),
  reduxForm({
    form: 'deviceForm',
    validate,
  }),
);

export const DeviceForm = enhance(DeviceFormPresentation);
