import { ProcessingButton } from '@shared/';
import { DateSelector } from '@shared/forms';
import React, { useState } from 'react';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { addDays, setHours, setMinutes } from 'date-fns';
import { AVAILABLES_CURRENCIES } from '@shared/constants';
import { DebugCard } from '@shared/';

export const UpdateMROModal = ({ isOpen, toggle, bpmType, assetRepair, updateMaintenance }) => {
  const bpmTypeSingular = {
    repair: 'Reparación',
    evaluation: 'Evaluación',
    maintenance: 'Mantención',
    preservation: 'Preservación',
    certification: 'Certificación',
    assurance: 'Garantía',
    discard: 'Dar de Baja',
  };
  const minLeadTime = setHours(setMinutes(addDays(new Date(), 0), 0), 0);
  const actualLeadTime = assetRepair.leadTime
    ? setHours(setMinutes(addDays(new Date(assetRepair.leadTime), 0), 0), 0)
    : undefined;
  const actualArrivalDate = assetRepair.arrivalDate
    ? setHours(setMinutes(addDays(new Date(assetRepair.arrivalDate), 0), 0), 0)
    : undefined;
  const actualCommittedDate = assetRepair.committedDate
    ? setHours(setMinutes(addDays(new Date(assetRepair.committedDate), 0), 0), 0)
    : undefined;
  const actualEstimatedDate = assetRepair.estimatedDate
    ? setHours(setMinutes(addDays(new Date(assetRepair.estimatedDate), 0), 0), 0)
    : undefined;
  const [leadTime, setLeadTime] = useState(actualLeadTime);
  const [arrivalDate, setArrivalDate] = useState(actualArrivalDate);
  const [committedDate, setCommittedDate] = useState(actualCommittedDate);
  const [estimatedDate, setEstimatedDate] = useState(actualEstimatedDate);
  const [price, setPrice] = useState(assetRepair.price ? assetRepair.price : undefined);
  const [currency, setCurrency] = useState(assetRepair.currency ? assetRepair.currency : undefined);
  const [qa, setQa] = useState(String(assetRepair.actualQa) !== 'undefined' ? assetRepair.actualQa : undefined);
  const [submitting, setSubmitting] = useState(false);

  const handleUpdateSubmit = async () => {
    setSubmitting(true);
    const repair = {
      id: assetRepair.id,
      price: price === actualLeadTime ? undefined : price,
      currency: currency === assetRepair.currency ? undefined : currency,
      leadTime: leadTime === assetRepair.leadTime ? undefined : leadTime,
      arrivalDate: arrivalDate === assetRepair.arrivalDate ? undefined : arrivalDate,
      committedDate: committedDate === assetRepair.committedDate ? undefined : committedDate,
      estimatedDate: estimatedDate === assetRepair.estimatedDate ? undefined : estimatedDate,
      qa: qa === assetRepair.qa ? undefined : qa,
    };
    await updateMaintenance(repair, bpmType);
    setLeadTime(undefined);
    setArrivalDate(undefined);
    setCommittedDate(undefined);
    setEstimatedDate(undefined);
    setPrice(undefined);
    setCurrency(undefined);
    setSubmitting(false);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>Actualizar {bpmTypeSingular[bpmType]}</ModalHeader>
      <ModalBody>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Lead time</b>
          </Label>
          <DateSelector
            selected={leadTime}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={setLeadTime}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
          />
        </FormGroup>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Fecha de llegada</b>
          </Label>
          <DateSelector
            selected={arrivalDate}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={setArrivalDate}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
          />
        </FormGroup>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Fecha comprometida</b>
          </Label>
          <DateSelector
            selected={committedDate}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={setCommittedDate}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
          />
        </FormGroup>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Fecha estimada de entrega</b>
          </Label>
          <DateSelector
            selected={estimatedDate}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={setEstimatedDate}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
          />
        </FormGroup>
        <Row>
          <Col md="8">
            <FormGroup>
              <Label>
                <b>Costo del MRO</b>
              </Label>
              <Input
                type="text"
                name="price"
                placeholder="Precio del Procedimiento"
                onChange={(e) => setPrice(e.target.value)}
                value={price}
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <Label>
                <b>Tipo de moneda</b>
              </Label>
              <Input type="select" name="currency" onChange={(e) => setCurrency(e.target.value)} value={currency}>
                {AVAILABLES_CURRENCIES.map((c, k) => (
                  <option key={`currency-${k}`} value={c}>
                    {c}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>
                <b>Control de Calidad</b>
              </Label>
              <Input
                name="qa"
                type="select"
                value={qa}
                onChange={(e) => {
                  console.log(e);
                  setQa(e.target.value);
                }}
              >
                <option value="">Selecciones una opción...</option>
                <option value={true}>Aprobado</option>
                <option value={false}>No Aprobado</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        {process.env.NODE_ENV === 'development' && (
          <Row>
            <Col>
              <DebugCard data={{ leadTime, arrivalDate, committedDate, estimatedDate, price, currency, qa }} />
            </Col>
          </Row>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          Cancelar
        </Button>
        <ProcessingButton
          type="submit"
          role="button"
          color="primary"
          onClick={handleUpdateSubmit}
          processing={submitting}
        >
          Actualizar
        </ProcessingButton>
      </ModalFooter>
    </Modal>
  );
};
