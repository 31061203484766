import { updateExpectedStock } from '@core/organization/expected-stocks';
import { FontAwesome } from '@shared/';
import React, { useState } from 'react';
import { Input, UncontrolledTooltip } from 'reactstrap';
import { Spinner } from '@shared/';
import ShowForRoles from '@shared/ShowForRoles';

export const ExpectedStock = ({ expectedStock, updateItem, loadingStocks }) => {
  const [minimalStock, setMinimalStock] = useState(expectedStock?.minimalStock);
  const [optimalStock, setOptimalStock] = useState(expectedStock?.optimalStock);
  const [backupStock, setBackupStock] = useState(expectedStock?.backupStock);
  const [buildingCriticality, setBuildingCriticality] = useState(expectedStock?.buildingCriticality);

  const [editStock, setEditStock] = useState(false);
  const [editing, setEditing] = useState(false);

  const setExpectedStock = async () => {
    setEditing(true);
    const payload = { building_id: expectedStock?.building?.id };
    if (Number(minimalStock) !== expectedStock?.minimalStock) payload['minimalStock'] = minimalStock;
    if (Number(optimalStock) !== expectedStock?.optimalStock) payload['optimalStock'] = optimalStock;
    if (Number(backupStock) !== expectedStock?.backupStock) payload['backupStock'] = backupStock;
    if (buildingCriticality !== expectedStock?.buildingCriticality)
      payload['buildingCriticality'] = buildingCriticality;

    if (Object.keys(payload).length > 1) {
      const { body, status } = await updateExpectedStock(expectedStock?.item?.barcode, payload);

      if (status === 200 || status === 201) {
        console.log(body);
        await updateItem();
      }
    }
    setEditStock(false);
    setEditing(false);
  };

  return (
    <tr>
      <td>{expectedStock?.building?.name}</td>
      <td className="text-center">
        {editStock ? (
          <Input
            name="minimalStock"
            type="number"
            placeholder="Cantidad"
            onChange={(e) => setMinimalStock(e.target.value)}
            value={minimalStock || ''}
            size="sm"
          />
        ) : (
          minimalStock
        )}
      </td>
      <td className="text-center">
        {editStock ? (
          <Input
            name="optimalStock"
            type="number"
            placeholder="Cantidad"
            onChange={(e) => setOptimalStock(e.target.value)}
            value={optimalStock || ''}
            size="sm"
            disabled={editing}
          />
        ) : (
          optimalStock
        )}
      </td>
      <td className="text-center">
        {editStock ? (
          <Input
            name="backupStock"
            type="number"
            placeholder="Cantidad"
            onChange={(e) => setBackupStock(e.target.value)}
            value={backupStock || ''}
            size="sm"
            disabled={editing}
          />
        ) : (
          backupStock
        )}
      </td>
      <td className="text-center">
        {editStock ? (
          <Input
            name="buildingCriticality"
            type="text"
            placeholder="Criticidad"
            onChange={(e) => setBuildingCriticality(e.target.value)}
            value={buildingCriticality || ''}
            size="sm"
            disabled={editing}
          />
        ) : (
          buildingCriticality
        )}
      </td>
      <td className="text-center">
        {editing ? (
          <Spinner id={`spinner-${expectedStock?.building?.id}`} color="primary" />
        ) : (
          <ShowForRoles accepted={['organization_admin']}>
            <span
              type="button"
              role="button"
              onClick={() => (editStock ? setExpectedStock() : setEditStock(true))}
              id="editStock"
              disabled={editing}
            >
              {editStock ? (
                <FontAwesome name="check" color={editStock ? 'success' : 'primary'} />
              ) : (
                <FontAwesome name="pencil-alt" color={editStock ? 'success' : 'primary'} />
              )}
              <UncontrolledTooltip target="editStock" placement="right">
                Has click para editar los valores
              </UncontrolledTooltip>
            </span>
          </ShowForRoles>
        )}
      </td>
    </tr>
  );
};
