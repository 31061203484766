import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';

import { status } from '@core/building/users';
import ChileanRUT from '@core/utils/chilean-rut';
import { shortName } from '@core/utils/names';
import { FontAwesome, UserPortrait } from '@shared';

const UserDisplay = ({ user }) => (
  <Media className="align-items-center">
    <Media right className="mr-3">
      <UserPortrait user={user} width="4.5rem" height="4.5rem" size="medium" />
    </Media>
    <Media body>
      <div className="h5">
        {user.deletedAt ? (
          <div className="text-muted">
            <FontAwesome name="user" className="mr-2" />
            {shortName(user)}
          </div>
        ) : (
          <NavLink to={`/admin/users/${user.id}`}>
            <FontAwesome name="user" className="mr-2" />
            {shortName(user)}
          </NavLink>
        )}
      </div>
      {user?.identifier?.value && (
        <div className="d-flex justify-content-between">
          <span>Identificación:</span>
          <span className="text-muted">{ChileanRUT.PartialFormat(user.identifier.value)}</span>
        </div>
      )}
      <div className="d-flex justify-content-between">
        <span>Estado:</span>
        {user.deletedAt ? <div className="text-muted">Eliminado</div> : status(user.status)}
      </div>
    </Media>
  </Media>
);

export default UserDisplay;
