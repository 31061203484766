import { createMembership, destroyMembership, getMemberships } from '@core/building/memberships';
import { confirmDialog, withLoader } from '@shared';
import { MembershipsBuildingManager } from '@shared/users';
import React from 'react';
import { compose, withHandlers } from 'recompose';

const Manager = MembershipsBuildingManager(createMembership, destroyMembership);

const enhance = compose(
  withLoader('memberships', ({ user: { id } }) => getMemberships(id)),
  withHandlers({
    beforeDestroy: () => async (memberships) => {
      if (memberships?.length !== 1) return true;

      return await confirmDialog(
        'Este usuario solo tiene una membresía disponible para esta instalación. Al eliminarla, perderá todo acceso a la instalación. \n\n ¿Estás seguro de que deseas continuar?',
      );
    },
  }),
);

const MembershipsPresentation = ({ beforeDestroy, memberships, user }) => (
  <Manager user={{ ...user, memberships }} beforeDestroy={beforeDestroy} />
);

export const Memberships = enhance(MembershipsPresentation);
