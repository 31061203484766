import { connect } from '@store';
import { currentMembership } from '@store/session';

const ShowForRoles = ({ session, requireRole, rootAccepted, rootExcluded, accepted, excluded, children }) => {
  const rootRole = session && session.user && session.user.rootRole;
  const userMembership = session && session.user && session.user.memberships.length > 0 && currentMembership(session);

  if (requireRole && !userMembership) return null;

  if (requireRole && userMembership) return { ...children };

  if (Array.isArray(rootAccepted) && rootAccepted.includes(rootRole) && !userMembership) return children;

  const acceptanceRules =
    (Array.isArray(rootAccepted) && rootAccepted.includes(rootRole)) ||
    (Array.isArray(accepted) && userMembership && accepted.includes(userMembership.role));

  const exclusionRules =
    (Array.isArray(rootExcluded) && rootExcluded.includes(rootRole)) ||
    (Array.isArray(excluded) && userMembership && excluded.includes(userMembership.role));

  if (!userMembership) return null;

  if ((Array.isArray(rootExcluded) || Array.isArray(excluded)) && exclusionRules) return null;

  if ((Array.isArray(rootAccepted) || Array.isArray(accepted)) && !acceptanceRules) return null;

  return children;
};

export default connect(({ session }) => ({ session }))(ShowForRoles);
