import { parseISO } from 'date-fns';
import es from 'date-fns/locale/es';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

registerLocale('es', es);

const DateSelector = ({ className, onChange, onBlur, value, ...otherProps }) => {
  if (typeof value === 'string' && value !== '') {
    value = parseISO(value);
  }
  return (
    <DatePicker
      selected={value}
      dateFormat="yyyy-MM-dd" // DO NOT TOUCH THIS
      onChange={(e) => console.log(e) || onChange(e)}
      {...otherProps}
      locale="es"
      className={`${className} form-control`}
    />
  );
};

export default DateSelector;
