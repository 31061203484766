import AsyncSelect from 'react-select/async';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { connect } from '@store';
import { getAssets } from '@core/building/assets';
import { getItems } from '@core/building/items';
import { getTags } from '@core/building/tags';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';

class MapFilterPresentation extends SimpleFilterModal {
  findItems = async (inputValue) => {
    const { body, status } = await getItems({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ name: label, barcode1: value }) => ({ label, value }));
    }

    return [];
  };

  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      const assets = body.map((asset) => ({
        label: `${asset.item.name} - ${asset.barcode1}`,
        value: asset.barcode1,
      }));
      return assets;
    }

    return [];
  };

  findTags = async (inputValue) => {
    const { body, status } = await getTags({
      identifier: inputValue,
      serializer: 'simple',
      tag_types: this.state.tag_type.value,
    });

    if (status === 200) {
      return body.map((tag) => ({
        label: tag.identifier,
        value: tag.identifier,
      }));
    }

    return [];
  };

  identifierName() {
    const identifiers = { 1: 'beacons', 2: 'epc', 4: 'imei', 6: 'eui' };
    return this.state.tag_type ? identifiers[this.state.tag_type.value].toUpperCase() : 'Identificador';
  }

  renderForm() {
    const { assetStates, tagTypes } = this.props;
    return (
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="multiple_barcodes[{}]">Activos</Label>
              <AsyncSelect
                name="multiple_barcodes[{}]"
                value={this.state['multiple_barcodes[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="states[{}]">Estados</Label>
              <Select
                name="states[{}]"
                value={this.state['states[{}]']}
                isClearable
                isSearchable
                isMulti
                closeMenuOnSelect={false}
                placeholder="Todos"
                options={assetStates}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="tag_type">Tipos de tag</Label>
              <Select
                name="tag_type"
                value={this.state['tag_type']}
                isClearable
                placeholder="Todos"
                options={tagTypes}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>{this.identifierName()}</Label>
              <AsyncSelect
                name="identifier"
                iidentifiers
                isSearchable
                isClearable
                value={this.state['identifier']}
                placeholder="Buscar..."
                loadOptions={this.findTags}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
                isDisabled={!this.state['tag_type']}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="items[{}]">Artículos</Label>
              <AsyncSelect
                name="items[{}]"
                value={this.state['items[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findItems}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

export const MapFilterModal = connect(({ appData }) => {
  const assetStates = Object.keys(appData.asset_states).map((state) => ({
    label: appData.asset_states[state].name,
    value: state,
  }));
  const tagTypes = Object.keys(appData.tag_types).map((type) => ({
    label: appData.tag_types[type].name.toUpperCase(),
    value: appData.tag_types[type].id,
  }));

  return { assetStates: assetStates, tagTypes: tagTypes };
})(MapFilterPresentation);
