/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { Button, Input } from 'reactstrap';
import FontAwesome from '../assets/FontAwesome';
import Editable from './Editable';

type EditableTextFieldProps = {
  id: string,
  name: string,
  data: Object,
  onUpdate: Function,
  toggle?: boolean,
  value: any,
  placeholder: string,
  validate?: Function,
  onToggle?: (startEdit: boolean) => void,
};

type State = {
  isEditing: boolean,
  value: any,
  placeholder: string,
};

export default class EditableTextField extends React.Component<EditableTextFieldProps, State> {
  input: HTMLInputElement;

  constructor(props: EditableTextFieldProps) {
    super(props);

    this.state = {
      isEditing: false,
      value: props.value,
      placeholder: props.placeholder,
    };
  }

  save = (event: Event) => {
    event.preventDefault();

    const { validate, name, data } = this.props;

    if (validate) {
      const errors = validate({
        ...data,
        [name]: this.input.value,
      });
      if (Object.keys(errors).length > 0) {
        alert(`${errors[name]}`);
        return;
      }
    }

    this.props.onUpdate(this.props.name, this.input.value);

    this.setState({
      isEditing: false,
      value: this.input.value,
    });

    this.props.onToggle && this.props.onToggle(false);
  };
  cancel = () => {
    this.props.onToggle && this.props.onToggle(false);
    this.setState({ isEditing: false });
    this.setState({ value: this.props.value });
  };

  handleLinkClick = () => {
    this.props.onToggle && this.props.onToggle(true);
    this.setState({ isEditing: true });
  };

  render() {
    const { name, id, toggle } = this.props;
    const { isEditing, value, placeholder } = this.state;

    if (isEditing) {
      return (
        <Editable isLoading={false} save={this.save} cancel={this.cancel}>
          <Input
            getRef={(input) => (this.input = input)}
            type="text"
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={(event) => this.setState({ value: event.target.value })}
            autoFocus
          />
        </Editable>
      );
    } else {
      return (
        <div className="editable">
          <a role="button" className={`editable-link ${value ? '' : 'editable-empty'}`} onClick={this.handleLinkClick}>
            {value || placeholder || (toggle ? '' : '-')}
          </a>
          {toggle && (
            <Button role="button" className="editable-toggle" color="primary" size="sm" onClick={this.handleLinkClick}>
              <FontAwesome name="pencil-alt" />
            </Button>
          )}
        </div>
      );
    }
  }
}
