import { FontAwesome } from '@shared';
import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { UserCard } from './UserCard';

export const UserEditorLayout = ({ user, children, onUpdate }) => (
  <div className="container-fluid mt-4 mb-4">
    <Row className="justify-content-center">
      <Col xs={12} sm={12} md={4} lg={3}>
        <UserCard user={user} onUpdate={onUpdate} />
      </Col>
      <Col xs={12} sm={12} md={8} lg={8}>
        <Card>
          <CardHeader>
            <Nav className="nav-tabs card-header-tabs">
              <NavItem>
                <NavLink role="button" tag={Link} exact to={`/admin/users/${user.id}`}>
                  <FontAwesome name="cogs" /> Editar
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink role="button" tag={Link} to={`/admin/users/${user.id}/memberships`}>
                  <FontAwesome name="cogs" /> Accesos
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>{children}</CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);
