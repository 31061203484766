import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { getAccount, clearPasswordRecovery } from '@core/account';
import Spinner from '../assets/Spinner';
import { connect, sessionGenerators } from '@store';

class RecoverPasswordModalProcessor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      cancelDisabled: false,
    };
  }

  cancel = () => {
    this.setState({
      processing: true,
    });

    clearPasswordRecovery()
      .then(() => {
        this.setState({
          processing: false,
          cancelDisabled: true,
        });

        return getAccount();
      })
      .then(({ body, status }) => {
        if (status === 200) this.props.updateUser(body);
      });
  };

  render() {
    return (
      <RecoverPasswordModal
        refresh={() => window.location.reload()}
        cancelDisabled={this.state.cancelDisabled}
        processing={this.state.processing}
        cancelAction={this.cancel}
        {...this.props}
      />
    );
  }
}

const RecoverPasswordModal = ({ opened, refresh, cancelAction, cancelDisabled, processing }) => (
  <Modal isOpen={opened} backdrop="static" keyboard={false} size="md">
    <ModalHeader>Recuperación de contraseña pendiente</ModalHeader>
    <ModalBody>
      <p>
        Recientemente has solicitado recuperar tu contraseña. Si has olvidado tu contraseña, por favor sigue las
        instrucciones enviadas a tu correo para recuperarla.
      </p>
      <p>Si tu no has enviado esta solicitud, puedes anularla haciendo click en el botón de más abajo.</p>
      <p>
        Recuerda que siempre puedes cambiar tu contraseña a través de tu{' '}
        <NavLink to="/account/password">configuración de inicio de sesión</NavLink>.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button role="button" color="secondary" disabled={cancelDisabled || processing} onClick={cancelAction}>
        {cancelDisabled ? 'Cancelado' : processing ? <Spinner /> : 'Cancelar'}
      </Button>
    </ModalFooter>
  </Modal>
);

export default connect(null, (dispatch) => ({
  updateUser: (user) => dispatch(sessionGenerators.updateUser(user)),
}))(RecoverPasswordModalProcessor);
