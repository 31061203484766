import React from 'react';
import { Input } from 'reactstrap';

import { connect } from '@store';

const enhance = connect(({ appData: { roles } }) => ({ roles }));

const BuildingRoleSelector = ({ roles, name, onChange, value, input }) => (
  <Input name={name} type="select" onChange={onChange} value={value} {...input}>
    <option value="">Elegir...</option>

    {Object.keys(roles).map((role, index) => (
      <option key={index} value={role}>
        {roles[role].title}
      </option>
    ))}
  </Input>
);

export default enhance(BuildingRoleSelector);
