import { format } from 'date-fns';
import moment from 'moment';

export function fromIso8601(date, showHour = true) {
  return date && format(Date.parse(date), `dd-MM-yyyy ${showHour ? '@ h:mm a' : ''}`);
}

export function toDate(datetime) {
  return datetime && format(Date.parse(datetime), 'dd-MM-yyyy');
}

export function toUTC(datetime) {
  return moment.utc(moment(datetime).format()).format();
}

export function fromUnixTimestamp(milliseconds) {
  const date = new Date(Number(milliseconds)).toISOString();
  return fromIso8601(date);
}
