import { getMembers } from '@core/building/members';
import { updateUserGroup, deleteUserGroup } from '@core/building/user_groups';
import { FontAwesome, ShowForRoles, UserPortrait } from '@shared';
import { connect } from '@store';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';
import { UserGroupMembersModal } from './UserGroupMembersModal';

const UserGroupRowPresentation = ({ userGroup, searchFunction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [members, setMembers] = useState(false);

  const activeGroup = async (group) => {
    const { status } = await updateUserGroup(group.id, { active: !group.active });
    if (status === 200) {
      await searchFunction();
    }
  };

  const deleteGroup = async (id) => {
    const { status } = await deleteUserGroup(id);
    if (status === 204) {
      await searchFunction();
    }
  };

  const toggle = async () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      const { body, status } = await getMembers(userGroup?.id);
      if (status === 200) {
        setMembers(body);
      }
    }
  };

  return (
    <tr>
      <td className="align-middle text-overflow">
        <NavLink className="d-flex" to={`/building/user_groups/${userGroup?.id}`}>
          <span className="ml-2" style={{ lineHeight: '2rem' }}>
            {userGroup?.name}
          </span>
        </NavLink>
      </td>
      <td className="align-middle">
        <NavLink className="d-flex" to={`/building/users/${userGroup?.creator.id}`}>
          <UserPortrait user={userGroup?.creator} />
          <span className="ml-2" style={{ lineHeight: '2rem' }}>
            {userGroup?.creator.name}
          </span>
        </NavLink>
      </td>
      <td className="align-middle text-center">
        <Badge color={userGroup?.active ? 'success' : 'danger'} pill>
          {userGroup?.active ? 'Sí' : 'No'}
        </Badge>
      </td>
      <td className="align-middle text-center">
        <Button id={`MoreInfoUserGroup-${userGroup?.id}`} size="sm" color="info" onClick={toggle}>
          <FontAwesome name="search" />
        </Button>
      </td>
      <ShowForRoles accepted={['organization_admin']}>
        <td className="align-middle text-center">
          <Button
            id={`ActiveUserGroup-${userGroup?.id}`}
            role="button"
            className="mr-1"
            color={userGroup?.active ? 'danger' : 'success'}
            size="sm"
            onClick={() => activeGroup(userGroup)}
            disabled={false}
          >
            <FontAwesome name={userGroup?.active ? 'toggle-off' : 'toggle-on'} />
          </Button>
          <Button
            id={`DeleteUserGroup-${userGroup?.id}`}
            size="sm"
            color="danger"
            onClick={() => deleteGroup(userGroup?.id)}
            disabled={false}
          >
            <FontAwesome name="times" />
          </Button>
        </td>
      </ShowForRoles>
      <UncontrolledTooltip placement="auto" target={`ActiveUserGroup-${userGroup?.id}`}>
        {userGroup?.active ? 'Desactivar' : 'Activar'}
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="auto" target={`MoreInfoUserGroup-${userGroup?.id}`}>
        Más Información
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="auto" target={`DeleteUserGroup-${userGroup?.id}`}>
        Eliminar grupo
      </UncontrolledTooltip>
      <UserGroupMembersModal userGroup={userGroup} isOpen={isOpen} toggle={toggle} members={members} />
    </tr>
  );
};

export const UserGroupRow = connect()(UserGroupRowPresentation);
