import unwrap from './unwrap';

type oneOfParams = {
  value: any,
  message: string | Function,
  alternatives: any[],
};

const oneOf = ({ value, alternatives, message }: oneOfParams) => {
  if (!Array.isArray(alternatives) || alternatives.length === 0) {
    return {
      valid: false,
      message: '"alternatives" array values should be an provided',
    };
  }

  if (alternatives.includes(value)) {
    return {
      valid: true,
    };
  }

  return {
    valid: false,
    message:
      (message && (typeof message === 'function' ? message(value, alternatives) : message)) ||
      `El valor ${value} no está permitido. Los valores disponibles son ${alternatives.join(', ')}.`,
  };
};

export default unwrap(oneOf);
