import { massImportExpectedStocks } from '@core/building/expected-stocks';
import Validates, { presence } from '@core/forms/validations';
import GenericMassImport from '@shared/mass-import';
import React from 'react';
import { MassImportInstructions } from './components/MassImportInstructions';

const columnMetadata = [
  {
    columnName: 'item_id',
    displayName: 'Item ID',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'item_id' })),
    style: {
      width: '20%',
    },
  },
  // {
  //   columnName: 'building_id',
  //   displayName: 'Building ID',
  //   editable: { type: 'text' },
  //   validate: Validates(presence({ of: 'building_id' })),
  //   style: {
  //     width: '20%',
  //   },
  // },
  {
    columnName: 'minimal_stock',
    displayName: 'Stock Mínimo',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'minimal_stock' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'optimal_stock',
    displayName: 'Stock Óptimo',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'optimal_stock' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'backup_stock',
    displayName: 'Stock de Respaldo',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'backup_stock' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'building_criticality',
    displayName: 'Criticidad',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'building_criticality' })),
    style: {
      width: '20%',
    },
  },
];

const tableConfig = {
  columnMetadata: columnMetadata,
  trackBy: '_id',
};

const headerMap = {
  item_id: ({ item_id }) => item_id,
  // building_id: ({ building_id }) => building_id,
  minimal_stock: ({ minimal_stock }) => minimal_stock,
  optimal_stock: ({ optimal_stock }) => optimal_stock,
  backup_stock: ({ backup_stock }) => backup_stock,
  building_criticality: ({ building_criticality }) => building_criticality,
};

export const ExpectedStocksMassImport = () => (
  <GenericMassImport
    entity="Stock para la instalación"
    instructions={MassImportInstructions}
    valueMapping={headerMap}
    request={massImportExpectedStocks}
    tableConfig={tableConfig}
  />
);
