import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, PictureDisplay } from '@shared';
import { getReadoutIcon } from '@shared/helpers';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

const getBatteryConfig = (level) => {
  if (level >= 80) {
    // 80-100
    return ['battery-full', '#43aa8b'];
  } else if (level >= 60) {
    // 60-79
    return ['battery-three-quarters', '#90be6d'];
  } else if (level >= 35) {
    // 35-59
    return ['battery-half', '#f9c74f'];
  } else if (level >= 10) {
    // 10-35
    return ['battery-quarter', '#f8961e'];
  } else {
    // 0-9
    return ['battery-empty', '#f94144'];
  }
};

export const ReadoutFeature = ({ feature, onClick, active, tooltip = true, link = true }) => {
  const {
    properties,
    geometry: { coordinates },
  } = feature;

  const [icon, color] = getBatteryConfig(properties.battery);

  return (
    <div id={properties.id} className={`group-item mb-2 ${active ? 'active' : ''}`} onClick={onClick || (() => null)}>
      <div>
        <PictureDisplay
          className="asset-img"
          pictureSize="icon"
          picture={properties.default_picture}
          width="65px"
          height="65px"
        />
      </div>
      <div className="ml-2 readout-content">
        <span className="d-flex justify-content-between">
          <small className="asset-name overflow-ellipsis mr-1" id={`name-${properties.id}`}>
            {properties.name}
          </small>
          <div style={{ color: color }}>
            <FontAwesome name={icon} id={`battery-level-${properties.id}`} className="battery-icon" />
          </div>

          {tooltip && (
            <>
              <UncontrolledTooltip placement="top" target={`battery-level-${properties.id}`}>
                <div style={{ width: '170px' }}>
                  <div className="small">Niveles de Bateria:</div>
                  {properties.tags.map((t) => (
                    <div key={`tag-${t.id}`} className="small d-flex justify-content-between text-monospace">
                      <span>{t.identifier}</span>
                      <span>{t.battery}%</span>
                    </div>
                  ))}
                </div>
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="top" target={`name-${properties.id}`}>
                {properties.name}
              </UncontrolledTooltip>
            </>
          )}
        </span>

        <span className="d-flex justify-content-between mb-1">
          {link && (
            <NavLink className="d-flex" to={active ? `/building/assets/${properties.barcode1}` : '#'}>
              <kbd>{properties.barcode1}</kbd>
            </NavLink>
          )}
          {!link && (
            <a className="d-flex" href={`/building/assets/${properties.barcode1}`} target="_blank" rel="noreferrer">
              <kbd>{properties.barcode1}</kbd>
            </a>
          )}
          <small>
            <FontAwesome name="calendar-alt" className="text-info" />
            {properties.read_at ? fromIso8601(properties.read_at) : '-'}
          </small>
        </span>
        <small className="overflow-ellipsis" id={`marker-${properties.id}`}>
          <FontAwesome name={getReadoutIcon(properties.origin)} className="text-info" />{' '}
          {properties.address || <span>{`Lat: ${coordinates[1] || '-'} - Lng: ${coordinates[0] || '-'}`}</span>}
          {tooltip && properties.address && (
            <UncontrolledTooltip placement="bottom" target={`marker-${properties.id}`}>
              <div className="small text-overflow" style={{ maxWidth: '23em' }}>
                Lat: <span className="text-info">{coordinates[1] || '-'}</span> - Lng:{' '}
                <span className="text-info">{coordinates[0] || '-'}</span>
              </div>
            </UncontrolledTooltip>
          )}
        </small>
      </div>
    </div>
  );
};
