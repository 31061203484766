import { deleteArea, getAreas } from '@core/building/areas';
import { FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { AreasResults } from './components/AreasResults';

class AreasIndexPresentation extends SimpleFilter {
  constructor(props) {
    super(props);
    this.state = {
      ...super.state,
      processing: undefined,
    };
  }
  searchFunction = getAreas;

  deleteArea = async (index) => {
    this.setState({ processing: index });

    const area = this.state.items[index];

    if (!area) {
      return;
    }

    const { items } = this.state;
    const { status } = await deleteArea(area.id);

    this.setState({ processing: undefined });

    if (status === 422) {
      this.props.deletingFailed();
    } else if (status === 204) {
      this.props.deletedSuccessfully();

      this.setState({
        items: [...items.slice(0, index), ...items.slice(index + 1)],
      });
    }
  };

  render() {
    const { items, pagination, processing } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <ShowForRoles excluded={['guest']}>
                <Button tag={NavLink} to="/building/areas/new" role="button" size="sm" outline color="success">
                  <FontAwesome name="plus" /> Crear Área
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="layer-group" className="text-primary mr-2" /> Gestión de Áreas
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <AreasResults
                    areas={items}
                    pagination={pagination}
                    deleteArea={this.deleteArea}
                    processing={processing}
                  />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </BuildingLayout>
    );
  }
}

export const AreasIndex = connect(null, (dispatch) => ({
  deletingFailed: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Eliminación fallida',
        text:
          'El área no puede ser eliminada. Por favor asegúrate que no contenga ningún activo ubicado antes de continuar.',
        color: 'danger',
      }),
    ),
  deletedSuccessfully: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Área eliminada',
        text: 'La área ha sido eliminada exitosamente.',
        color: 'success',
      }),
    ),
}))(AreasIndexPresentation);
