import { createItem } from '@core/building/items';
import { SubmissionError } from '@core/forms';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { ItemForm } from './components/ItemForm';

const MassHint = () => (
  <Alert color="info">
    <strong>¿Tienes que insertar muchos artículos a la vez?</strong> Prueba un proceso de{' '}
    <NavLink to="/organization/items/mass" className="alert-link">
      importación masiva de artículos
    </NavLink>
    .
  </Alert>
);

class NewItemPresentation extends Component {
  state = {
    created: false,
    barcode: '',
  };

  createItem = async (redirect, reset, data) => {
    const { body, status } = await createItem(data);

    if (status === 201) {
      this.props.successCreationNotification();

      if (redirect) {
        this.setState({ barcode: body.barcode1, created: true });
      } else {
        reset();
      }
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { barcode, created } = this.state;

    if (created) return <Redirect to={`/organization/items/${barcode}`} />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Creación de artículo" previous={<MassHint />}>
          <ItemForm onSubmit={this.createItem} />
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const NewItem = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Artículo creado',
        text: 'El artículo ha sido creado exitosamente.',
        color: 'success',
      }),
    ),
}))(NewItemPresentation);
