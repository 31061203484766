import { FontAwesome, OrganizationCard, ShowForRoles } from '@shared';
import { connect } from '@store';
import React from 'react';
import { NavLink as RouterLink, withRouter } from 'react-router-dom';
import {
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  NavItem,
  NavLink,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { compose } from 'recompose';
import { BuildingNav } from './BuildingNav';

const Link = (props) => <RouterLink {...props} activeClassName="active link-active" />;

const enhance = compose(
  connect(({ session }) => ({
    /* TODO: Reselect in the future */
    currentBuilding: session?.user?.memberships.find((m) => m.id === session?.currentMembership)?.building,
  })),
  withRouter,
);

const BuildingLayout = ({ children, currentBuilding, location }) => (
  <Container fluid>
    <Row className="building-header hidden-print">
      <Col xs="12" xl={{ size: 10, offset: 1 }}>
        <Media className="mb-3 align-items-center">
          <Media left className="mr-3">
            <OrganizationCard
              url={currentBuilding?.organization.avatar && currentBuilding?.organization.avatar.medium.url}
              alt={currentBuilding?.organization.name}
            />
          </Media>
          <Media body>
            <div className="h3">{currentBuilding?.organization.name}</div>
            <div className="d-inline-block">
              {currentBuilding?.name}
              {currentBuilding?.sapCode && (
                <p>
                  código SAP: <code>{currentBuilding?.sapCode}</code>
                </p>
              )}
            </div>
          </Media>
        </Media>
        <Nav tabs className="building-nav">
          <ShowForRoles
            accepted={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'client', 'guest']}
          >
            <NavItem>
              <NavLink role="button" tag={Link} to="/movements">
                <FontAwesome name="sync" className="mr-2" />
                Movimientos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink role="button" exact tag={Link} to="/organization/inventory">
                <FontAwesome name="th" className="mr-2" />
                Inventario
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink role="button" exact tag={Link} to="/map">
                <FontAwesome name="map" className="mr-2" />
                Mapa
              </NavLink>
            </NavItem>
          </ShowForRoles>
          <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}>
            <UncontrolledDropdown>
              <DropdownToggle
                role="button"
                nav
                caret
                className={location.pathname.startsWith('/building') ? 'active link-active' : ''}
              >
                <FontAwesome name="cogs" className="mr-2" />
                Gestionar
              </DropdownToggle>
              <DropdownMenu>
                <BuildingNav />
              </DropdownMenu>
            </UncontrolledDropdown>
          </ShowForRoles>
        </Nav>
      </Col>
    </Row>
    <Row className="app-body">
      <Col xs="12" xl={{ size: 10, offset: 1 }}>
        {children}
      </Col>
    </Row>
  </Container>
);

export default enhance(BuildingLayout);
