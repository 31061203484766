import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const getAssetEvaluations = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_evaluations${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAssetEvaluation = (assetEvaluationId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_evaluations/${assetEvaluationId}`,
      method: 'GET',
    }),
  );

export const getAssetEvaluationStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_evaluations/stats`,
      method: 'GET',
    }),
  );
