import React from 'react';
import { Col, Row } from 'reactstrap';

import { connect } from '../../@store';
import { currentMembership } from '../../@store/session';
import { FontAwesome } from '../../@shared';
import { Highcharts } from '../../@shared/charts';

import InventoryChart from './components/InventoryChart';
import ConsumableChart from './components/ConsumableChart';
import ConsumableCriticalityChart from './components/ConsumableCriticalityChart';
import ConsumableDatesChart from './components/ConsumableDatesChart';
import MaintenanceChart from './components/MaintenanceChart';
import MovementsChart from './components/MovementsChart';

import UserList from './components/UserList';

import './Dashboard.scss';

Highcharts.setOptions({
  lang: {
    contextButtonTitle: 'Menu contextual',
    downloadCSV: 'Descargar archivo CSV',
    downloadJPEG: 'Descargar imagen JPEG',
    downloadPDF: 'Descargar archivo PDF',
    downloadPNG: 'Descargar imagen PNG',
    downloadSVG: 'Descargar imagen SVG',
    downloadXLS: 'Descargar archivo XLS',
    drillUpText: 'Regresar a {series.name}',
    loading: 'Obteniendo información...',
    noData: 'No hay información disponible',
    printChart: 'Imprimir Gráfico',
    resetZoom: 'Reiniciar zoom',
  },
  colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
    return {
      radialGradient: {
        cx: 0.5,
        cy: 0.3,
        r: 0.7,
      },
      stops: [
        [0, color],
        [1, Highcharts.Color(color).brighten(-0.3).get('rgb')],
      ],
    };
  }),
});

class Dashboard extends React.Component {
  state = { name: '' };

  componentDidMount() {
    const membership = currentMembership(this.props.session);
    this.setState({
      name: `${membership.building.organization.name} - ${membership.building.name}`,
    });
  }

  render() {
    return (
      <div className="container-fluid mt-4 mb-4">
        <Row>
          <Col>
            <h4 className="mb-4">
              <FontAwesome name="chart-line" className="mr-2 text-primary" /> Dashboard: {this.state.name}
            </h4>
          </Col>
        </Row>
        <Row style={{ height: '100%' }}>
          <Col xs={12} md={6} className="mb-4">
            <MaintenanceChart title={this.state.name} />
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <MovementsChart title={this.state.name} />
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <InventoryChart title={this.state.name} />
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <ConsumableDatesChart title={this.state.name} />
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <ConsumableChart title={this.state.name} />
          </Col>
        </Row>
        <Row style={{ height: '100%' }}>
          <Col xs={12} md={6} className="mb-4">
            <ConsumableCriticalityChart title={this.state.name} />
          </Col>
          <Col xs={12} md={6} className="mb-4">
            <UserList />
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(({ session }) => ({
  session,
}))(Dashboard);
