import React from 'react';
import { Media } from 'reactstrap';

import { PictureDisplay } from '@shared';

const BuildingDisplay = ({ organization }) => (
  <Media className="align-items-center">
    <Media left className="mr-3">
      <PictureDisplay
        picture={organization.avatar}
        pictureSize="icon"
        alt={organization.name}
        width="3rem"
        height="2rem"
      />
    </Media>
    <Media body>
      <div>
        <strong>{organization.name}</strong>
      </div>
      {/* <div>&nbsp;</div> */}
    </Media>
  </Media>
);

export default BuildingDisplay;
