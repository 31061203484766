import { SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Label, Row, Input } from 'reactstrap';

export class SimCardFilterModal extends SimpleFilterModal {
  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="iccid">ICCID</Label>
              <Input
                name="iccid"
                value={this.state['iccid']}
                placeholder="ICCID"
                onChange={(e) => this.onChange(e.target.value, { name: e.target.name })}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="status">Estado</Label>
              <Select
                name="status"
                value={this.state['status']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Activo', value: 'active' },
                  { label: 'Pre Activado', value: 'preactive' },
                  { label: 'Suspendido', value: 'suspended' },
                  { label: 'Inactivo', value: 'inactive' },
                  { label: 'Terminado', value: 'terminated' },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="msisdn">MSISDN</Label>
              <Input
                name="msisdn"
                value={this.state['msisdn']}
                placeholder="MSISDN"
                onChange={(e) => this.onChange(e.target.value, { name: e.target.name })}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
