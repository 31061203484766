import React from 'react';
import { Media } from 'reactstrap';

import { OrganizationCard } from '../../@shared/index';

export const BuildingCard = ({ resource: building }) => (
  <Media className="align-items-center">
    <Media left className="mr-2">
      <OrganizationCard
        width="6rem"
        height="4rem"
        url={building?.organization.avatar.icon.url}
        alt={building?.organization.name}
      />
    </Media>
    <Media body>
      <div>
        <strong>{building?.organization.name}</strong>
      </div>
      <div>{building?.name}</div>
    </Media>
  </Media>
);
