export const shortName = function ({ firstName, lastName }) {
  return `${firstName.split(' ')[0]} ${lastName.split(' ')[0]}`;
};

export const firstName = function ({ firstName }) {
  return firstName.split(' ')[0];
};

export const fullName = function ({ firstName, lastName }) {
  return `${firstName} ${lastName}`;
};
