import React from 'react';

import { SmartGrid } from '@shared';

type Props = {
  failed: Object[],
  gridConfig: Object,
};

const MassImportFailedTable = ({ failed, gridConfig }: Props) => (
  <div>
    <h5 className="mb-3">Fila(s) con errores</h5>

    <SmartGrid {...gridConfig} data={failed} />
  </div>
);

export default MassImportFailedTable;
