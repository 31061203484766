import { parseQuery } from '@shared/simple-filter/parseQuery';
import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { calculateFinalFilters } from './simple-filter/SimpleFilter';
import { FontAwesome, Spinner } from '@shared';

class ExcelEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      fetching: false,
    };
  }

  fileDownload = async (fileFormat = '') => {
    this.setState({
      fetching: true,
    });

    const { downloadFile, file, filters } = this.props;
    const fileName = file + fileFormat;
    const actualFilters = calculateFinalFilters(filters);
    await downloadFile({ file: fileName, filters: parseQuery(actualFilters) });

    this.setState({
      fetching: false,
    });
  };

  render() {
    const { disabled, file, filters, downloadFile, name = 'Descargar', ...otherProps } = this.props;
    const { fetching } = this.state;

    return (
      <span>
        <Button
          role="button"
          color="success"
          size="sm"
          onClick={() => this.fileDownload('/excel')}
          disabled={disabled || fetching}
          {...otherProps}
        >
          {fetching && (
            <span>
              <Spinner /> Descargando
            </span>
          )}
          {!fetching && (
            <span>
              <FontAwesome name="table" /> {name}
            </span>
          )}
        </Button>
      </span>
    );
  }
}

export default ExcelEmail;
