import { FontAwesome } from '@shared/';
import React from 'react';
import { Media } from 'reactstrap';
import { EventAssetModal } from './EventAsset';

export const EventReadout = ({ readout }) => (
  <Media>
    {readout && (
      <Media body>
        {readout.asset && readout.asset.item && readout.asset.item.name && (
          <div className="w-100 text-overflow mt-1 small">
            <FontAwesome name="th-large" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{readout.asset.item.name}</span>
          </div>
        )}
        {readout.metadata && (
          <div>
            {readout.metadata.address && (
              <div className="w-100 text-overflow mt-1 small">
                <FontAwesome name="map-marked-alt" color="info" className="mr-1" />
                <span>{readout.metadata.address}</span>
              </div>
            )}
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventReadoutModal = ({ readout }) => (
  <div>
    <Media>
      {readout && (
        <Media body>
          {readout.asset && readout.asset.item && readout.asset.item.name && <EventAssetModal asset={readout.asset} />}
          {readout.metadata && (
            <div>
              {readout.metadata.address && (
                <div className="w-100 text-overflow mt-1">
                  <FontAwesome name="map-marked-alt" color="info" className="mr-1" />
                  <span>{readout.metadata.address}</span>
                </div>
              )}
              {readout.metadata.lat && readout.metadata.lng && (
                <div className="w-100 text-overflow mt-3">
                  <p className="text-muted mr-1">
                    <FontAwesome name="globe" color="info" className="mr-1" />
                    Coordenadas:
                  </p>
                  <div>
                    <FontAwesome name="grip-lines" color="info" className="ml-2 mr-1" />
                    Latitud:
                    <code>{readout.metadata.lat}</code>
                  </div>
                  <div>
                    <FontAwesome name="grip-lines-vertical" color="info" className="ml-2 mr-1" />
                    Longitud:
                    <code>{readout.metadata.lng}</code>
                  </div>
                </div>
              )}
            </div>
          )}
        </Media>
      )}
    </Media>
  </div>
);
