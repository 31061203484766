import React from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import { FontAwesome } from '@shared/';

export const MergeDisclaimerModal = ({ isOpen, onToggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={onToggle}>
      <ModalHeader>
        <FontAwesome name="exclamation-triangle" color="warning" className="text-primary mr-2" /> Advertencia Importante
      </ModalHeader>
      <ModalBody>
        <ul>
          <li>
            La combinación de artículos puede causar inconsistencias en el histórico de datos, por lo que se aconseja
            utilizar esta funcionalidad con prudencia.
          </li>
          <li>
            Los cambios realizados será irreversibles ya que se eliminarán los artículos que se consideren duplicados,
            conservando exclusivamente la versión "maestra" del artículo.{' '}
          </li>
          <li>
            Los artículos que se quieran combinar, deben ser del mismo tipo "consumible" o "no consumible", por esta
            razón se requiere especificar en el formulario este atributo.
          </li>
          <li>
            Se recomienda gestionar manualmente el inventario de los artículos a eliminar antes de emplear la
            funcionalidad. Los artículos con grandes volumenes de unidades, en ocasiones no permite terminar el proceso
            satisfactoriamente.
          </li>
          <li>
            Alliot SPA no se hará responsable del mal uso de esta funcionalidad por parte de los administradores de las
            organizaciones e instalaciones.
          </li>
        </ul>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onToggle}>
          Entendido
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
};
