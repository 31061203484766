import { destroyPost } from '@core/admin/posts';
import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class PostRowPresentation extends Component {
  state = {
    modal: false,
    modalTitle: 'Confirmación',
    modalContent: '¿Realmente desea eliminar este post?',
    redirectTo: undefined,
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  delete = async () => {
    const { post } = this.props;
    const { status } = await destroyPost(post.id);

    if (status === 204) {
      this.props.successCreationNotification();
      this.setState({ redirectTo: `/admin/posts` });
    }
  };

  render() {
    const { redirectTo } = this.state;

    if (redirectTo) return <Redirect to={redirectTo} />;

    const { modal, modalContent, modalTitle } = this.state;
    const { post } = this.props;

    return (
      <tr>
        <td className="align-middle text-center">{post.id}</td>
        <td className="align-middle">{post.title}</td>
        <td className="text-center align-middle">
          <div>
            Por <NavLink to={`/admin/users/${post.user.id}`}>{post.user.name}</NavLink>
          </div>
          <div className="text-muted">{fromIso8601(post.postedAt)}</div>
        </td>
        <td className="text-center align-middle">
          <Button role="button" color="primary" outline block tag={NavLink} to={`/admin/posts/${post.id}/edit`}>
            <FontAwesome name="pencil-alt" /> Editar
          </Button>

          {!post.active && (
            <Button role="button" color="danger" outline block size="sm" onClick={this.toggle}>
              <FontAwesome name="trash" /> Eliminar
            </Button>
          )}

          <Modal isOpen={modal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle} tag={'h4'}>
              {modalTitle}
            </ModalHeader>
            <ModalBody>{modalContent}</ModalBody>
            <ModalFooter>
              <Button role="button" onClick={this.toggle}>
                Cancelar
              </Button>
              <Button role="button" color="primary" onClick={this.delete}>
                <FontAwesome name="trash" /> Eliminar
              </Button>
            </ModalFooter>
          </Modal>
        </td>
      </tr>
    );
  }
}

export const PostRow = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Post eliminado',
        text: 'El post ha sido eliminado correctamente.',
        color: 'danger',
      }),
    ),
}))(PostRowPresentation);
