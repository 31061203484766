import { Field, Form } from '@core/forms';
import Validates, { length, presence } from '@core/forms/validations';
import { DebugCard, ProcessingButton } from '@shared';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { ColorPicker } from './color-picker';
import { GeofenceEditor } from './geofence-editor';

const validate = Validates(
  presence({ of: 'name' }),
  presence({ of: 'geofence' }),
  length({ of: 'geofence', deep: 'polygon.coordinates[0]', greaterThan: 0 }),
);

class LocationForm extends React.Component {
  state = {
    edit: false,
    color: '#fff',
  };

  onToggleEdit = (value) => {
    this.setState({
      edit: value,
    });
  };

  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };

  render() {
    const { handleSubmit, invalid, submitting, pristine, error, updating } = this.props;

    return (
      <Form error={error} noValidate onSubmit={handleSubmit}>
        <Row>
          <Col>
            <Field
              name="name"
              type="text"
              label="Nombre de la geocerca"
              required
              help="El nombre de la geocerca en cuestión. Puede (y debería) ser un nombre coloquial y fácil de identificar."
            />
          </Col>
          <Col>
            <Field name="color" label="Color" component={ColorPicker} />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="feature"
              label="Dibuje sus geocercas"
              onToggleEdit={this.onToggleEdit}
              component={GeofenceEditor}
            />
          </Col>
        </Row>

        {process.env.NODE_ENV === 'development' && (
          <Row>
            <Col>
              <DebugCard
                data={{
                  edit: this.state.edit,
                }}
              />
            </Col>
          </Row>
        )}

        <div className="text-center">
          <ProcessingButton
            type="submit"
            color="primary"
            outline
            width="10em"
            disabled={(!updating && pristine) || invalid || this.state.edit}
            processing={submitting}
          >
            Guardar
          </ProcessingButton>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'locationForm',
  validate,
})(LocationForm);
