import React from 'react';
import { Table } from 'reactstrap';
import { PickUpRow } from './PickUpRow';
import { pluralize } from '@shared/helpers';

export const PickUpsResults = ({ pickUps, pagination }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'pick up encontrado', 'pick ups encontrados')}.
      Mostrando <strong>{pagination.per}</strong> pick ups por página.
    </div>

    <Table>
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <th className="align-middle">Fecha</th>
          <th className="align-middle">Número</th>
          <th className="align-middle">Cliente</th>
          <th className="align-middle">Dispositivo</th>
          <th className="text-center align-middle">Reservados</th>
          <th className="text-center align-middle">
            <div style={{ borderBottom: '1px solid' }}>Preparados</div>Entregados
          </th>
          <th className="text-center align-middle">Estado</th>
          <th className="text-center align-middle">Detalle</th>
        </tr>
      </thead>
      <tbody>
        {pickUps.map((pickUp, index) => (
          <PickUpRow key={index} pickUp={pickUp} />
        ))}

        {pickUps.length === 0 && (
          <tr>
            <td colSpan="3" className="text-center mt-3 mb-3">
              <em>No se encontraron pick ups.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
