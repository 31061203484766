import { fromIso8601 } from '@core/utils/dates';
import { ConfirmBox, FontAwesome, PictureDisplay, Spinner } from '@shared';
import { isAfter, parseISO } from 'date-fns';
import React, { Component } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Media, Tooltip, Progress, UncontrolledTooltip } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';
import { TaskDetailModal } from './TaskDetailModal';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { TaskPosponeModal } from './TaskPosponeModal';
import { TaskCancelModal } from './TaskCancelModal';
import { TaskCompleteModal } from './TaskCompleteModal';
import './TaskRow.scss';
import { store } from '@store';
import { createMaintenance } from '@core/building/asset-repairs';
import TaskConfirmMROModal from './TaskConfirmMROModal';

export const AssetCellDisplay = ({ asset }) => (
  <Media className="align-items-center" tag={NavLink} to={`/building/assets/${asset?.barcode1}`}>
    <Media left className="mr-1" tag="div">
      <PictureDisplay
        picture={asset.item.defaultPicture}
        pictureSize="icon"
        alt={asset.item.name}
        width="3rem"
        height="2rem"
      />
    </Media>
    <Media body tag="div">
      <div>{asset.item.name}</div>
      <div>
        <code>{asset?.barcode1}</code>
      </div>
      <div>
        <FontAwesome name="industry" tooltip="Instalación" />
        {asset?.building.name}
      </div>
    </Media>
  </Media>
);

class TaskRowPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDetail: false,
      openActions: false,
      openPospone: false,
      openCancel: false,
      openCompleteTask: false,
      effectiveHours: null,
      showTooltip: undefined,
      redirectTo: false,
    };
  }

  openTaskDetail = () => {
    this.setState({ openDetail: true });
  };

  openTaskPospone = () => {
    this.setState({ openPospone: true });
  };

  openTaskCancel = () => {
    this.setState({ openCancel: true });
  };

  openCompleteTask = () => {
    this.setState({ openCompleteTask: true });
  };

  togglePospone = () => {
    this.setState({ openPospone: !this.state.openPospone });
  };

  toggleCancel = () => {
    this.setState({ openCancel: !this.state.openCancel });
  };

  toggleDetail = () => {
    this.setState({ openDetail: !this.state.openDetail });
  };

  toggleActions = () => {
    this.setState({ openActions: !this.state.openActions });
  };

  toggleCompleteTask = () => {
    this.setState({ openCompleteTask: !this.state.openCompleteTask });
  };

  closeCompleteTask = () => {
    this.setState({ openCompleteTask: false });
  };

  setEffectiveHours = (value) => {
    console.log('effectiveHours', value.target.value);
    this.setState({ effectiveHours: value.target.value });
  };

  showTooltip = (taskId) => {
    this.setState({ showTooltip: taskId });
  };

  getMembers = () => {
    const { task } = this.props;
    let membersList =
      task.members &&
      task.members
        .filter((member, index) => index < 2)
        .map((member) => `${member.firstName} ${member.lastName}`)
        .join(', ');

    if (!!membersList && task.members.length > 2) {
      membersList = `${membersList}...`;
    }

    return membersList;
  };

  isExpired() {
    const { task } = this.props;
    return isAfter(new Date(), parseISO(task.dueDate));
  }

  getStatus = () => {
    const { task } = this.props;
    const states = {
      not_started: 'No Iniciado',
      on_progress: 'En Progreso',
      finished: 'Finalizado',
      overdue: 'Retrazado',
    };
    return states[task.state];
  };

  showDueDate = (task) => {
    if (task.postponements.length >= 1) {
      return (
        <div
          onMouseLeave={() => this.showTooltip(undefined)}
          onMouseEnter={() => this.showTooltip(task.id)}
          id={`explanation-task-${task.id}`}
        >
          {fromIso8601(task?.postponements?.[0].newDate, false)}
          <FontAwesome name="exclamation" />
          <Tooltip placement="right" isOpen={this.state.showTooltip === task.id} target={`explanation-task-${task.id}`}>
            Esta tarea fue pospuesta
          </Tooltip>
        </div>
      );
    } else {
      return task.dueDate && fromIso8601(task.dueDate, false);
    }
  };

  isOrganizationAdmin = () => {
    const { session } = store.getState();
    return (
      session.user?.memberships.filter((membership) => membership.id === session.user.currentMembership)[0]?.role ===
      'organization_admin'
    );
  };

  remove = () => {
    this.props.remove();
    this.setState({ openDetail: false });
  };

  completeTask = (task) => {
    task['effectiveHours'] = this.state.effectiveHours;
    console.log('task con EFFECTIVE_HOURS', task);
    this.props.completeTask(task);
    this.closeCompleteTask();
    this.setState({ openDetail: false });
  };

  startMro = async (task) => {
    console.log(task);
    const payload = {
      task_id: task.id,
      supplier_id: task?.organization?.id,
      code: task?.code,
      [`assets_${task.mroType}`]: [
        {
          asset_id: task.asset.id,
          building_id: task?.building?.id,
          lead_time: task.dueDate,
          comments: `Generado a partir de la tarea ${task.id}: ${task.title}`,
          attachments_tokens: [],
        },
      ],
    };

    const { body, status } = await createMaintenance(payload);

    if (status === 201) {
      console.log(body, status);
      this.setState({ redirectTo: true });
      // this.props.successNotification(body.code);
      // this.props.history.push(`/mro/${task.mroType}s`);
    } else {
      // this.props.errorNotification();
    }
  };

  render() {
    const {
      task,
      isOpen,
      openConfirmBox,
      closeConfirmBox,
      toggleConfirmBox,
      isOpenMRO,
      openConfirmMROModal,
      closeConfirmMROModal,
      loading,
      startTask,
      postponeTask,
      cancelTask,
      onTaskChange,
    } = this.props;
    const { session } = store.getState();
    const {
      openDetail,
      openActions,
      openPospone,
      openCancel,
      effectiveHours,
      openCompleteTask,
      redirectTo,
    } = this.state;
    return redirectTo ? (
      <Redirect to={{ pathname: `/mro/${task.mroType}s` }} />
    ) : (
      <tr
        className={`
        task-row
        ${this.isExpired() ? 'expired' : ''}
        task-border
        ${
          task.completedAt
            ? 'task-completed'
            : task.canceledAt
            ? 'task-canceled'
            : task.postponements.length > 0
            ? 'task-postponed'
            : task.state === 'on_progress'
            ? 'task-started'
            : task.state === 'overdue'
            ? 'task-delayed'
            : task.state === 'not_started'
            ? 'task-unstarted'
            : ''
        }
        `}
      >
        {/* Titulo */}
        <td className="align-middle">
          <Button
            onClick={this.openTaskDetail}
            color="link"
            role="button"
            className={'task-title'}
            id={`task-title-${task.id}`}
          >
            {task.canceledAt ? <del>{task.title}</del> : task.title}
          </Button>
          <UncontrolledTooltip placement="top" target={`task-title-${task.id}`}>
            {task.completedAt
              ? 'Completada'
              : task.canceledAt
              ? 'Cancelada'
              : task.postponements.length > 0
              ? 'Pospuesta'
              : task.state === 'on_progress'
              ? 'Iniciada'
              : task.state === 'overdue'
              ? 'Retrasada'
              : task.state === 'not_started'
              ? 'No Iniciada'
              : ''}
          </UncontrolledTooltip>
        </td>
        {/* progreso  */}
        <td className="align-middle">
          {task.startedAt || task.completedAt ? (
            <Progress
              color={task.completePercentage === 100 || task.completedAt ? 'success' : 'primary'}
              value={task.completedAt ? 100 : task.completePercentage}
            >
              {`${
                task.completePercentage === 100 || task.completedAt
                  ? 'Completada'
                  : Number(task.completePercentage).toFixed(0) + '%'
              }`}
            </Progress>
          ) : (
            'No Iniciado'
          )}
        </td>
        {/* fecha de creacion  */}
        <td className="align-middle">{task.createdAt && fromIso8601(task.createdAt, false)}</td>
        {/* Frecuencia */}
        <td className="align-middle">{task.frequencyLabel}</td>
        {/* miembros  */}
        <td className="align-middle">{this.getMembers()}</td>
        {/* asset */}
        <td className="align-middle">{!!task.asset && <AssetCellDisplay asset={task.asset} />}</td>
        {/* fecha inicio  */}
        <td className="align-middle">{task.startDate && fromIso8601(task.startDate, false)}</td>
        {/* fecha fin  */}
        <td className="align-middle">{this.showDueDate(task)}</td>
        {/* acciones  */}
        <td className="align-middle text-right">
          <ConfirmBox
            isOpen={isOpen}
            closeConfirmBox={closeConfirmBox}
            avoidConfirmBox={toggleConfirmBox}
            removeTag={this.remove}
            type="task"
            message="Esta acción no es reversible."
            resource={task.title}
          />
          <TaskConfirmMROModal
            isOpen={isOpenMRO}
            closeConfirmMROModal={closeConfirmMROModal}
            startMro={() => this.startMro(task)}
            task={task}
          />
          <ButtonDropdown isOpen={openActions} toggle={this.toggleActions}>
            <DropdownToggle caret color="info" size="sm">
              Acciones
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem disabled={task.completedAt || task.canceledAt}>
                <NavLink style={{ textDecoration: 'none', color: '#212629' }} to={`/organization/tasks/${task.id}`}>
                  <FontAwesome name="pencil-alt" /> Editar
                </NavLink>
              </DropdownItem>
              <DropdownItem onClick={this.openTaskPospone} disabled={task.completedAt || task.canceledAt}>
                <FontAwesome name="calendar-times" /> Posponer
              </DropdownItem>
              <DropdownItem
                onClick={() => startTask(task)}
                disabled={task.completedAt || task.canceledAt || task.subtasks?.some((st) => st.completedAt)}
              >
                <FontAwesome name="calendar-check" /> {task.startedAt ? 'No Iniciar' : 'Iniciar'}
              </DropdownItem>
              <DropdownItem
                onClick={this.openTaskCancel}
                disabled={
                  ((!task.members?.some((member) => member.id === session.user.id) ||
                    task.createdBy?.id !== session.user?.id ||
                    task.completedAt) &&
                    !this.isOrganizationAdmin) ||
                  (task.integration && task.canceledAt)
                }
              >
                <FontAwesome name="calendar-minus" /> {task.canceledAt ? 'Reanudar' : 'Anular'}
              </DropdownItem>
              <DropdownItem
                onClick={openConfirmBox}
                disabled={(task.completedAt || task.createdBy?.id !== session.user?.id) && !this.isOrganizationAdmin}
              >
                {loading === 'remove' ? (
                  <Spinner />
                ) : (
                  <>
                    <FontAwesome name="trash" /> Eliminar
                  </>
                )}
              </DropdownItem>
              <DropdownItem onClick={this.openCompleteTask} disabled={task.completedAt || task.canceledAt}>
                <FontAwesome name="clipboard-check" /> Completar
              </DropdownItem>
              <DropdownItem
                onClick={openConfirmMROModal}
                disabled={
                  !task.integration ||
                  !task.mroType ||
                  task.asset?.isInMro ||
                  !task.asset?.repairable ||
                  !task.asset?.availability === 'available' ||
                  !task.asset?.state === 'stored' ||
                  task.canceledAt ||
                  task.state === 'finished'
                }
              >
                <FontAwesome name="heartbeat" /> MRO
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </td>
        <TaskDetailModal
          task={task}
          isOpen={openDetail}
          toggle={this.toggleDetail}
          onTaskChange={onTaskChange}
          openTaskPospone={this.openTaskPospone}
          openTaskCancel={this.openTaskCancel}
          openConfirmBox={openConfirmBox}
          openConfirmMROModal={openConfirmMROModal}
          openCompleteTask={this.openCompleteTask}
          startTask={() => startTask(task)}
        />
        <TaskPosponeModal postponeTask={postponeTask} task={task} isOpen={openPospone} toggle={this.togglePospone} />
        <TaskCancelModal cancelTask={cancelTask} task={task} isOpen={openCancel} toggle={this.toggleCancel} />
        <TaskCompleteModal
          openCompleteTask={openCompleteTask}
          toggleCompleteTask={this.toggleCompleteTask}
          setEffectiveHours={this.setEffectiveHours}
          closeCompleteTask={this.closeCompleteTask}
          completeTask={() => this.completeTask(task)}
          effectiveHours={effectiveHours}
        />
      </tr>
    );
  }
}

const enhance = compose(
  withState('loading', 'setLoading', false),
  withState('isOpen', 'setIsOpen', false),
  withState('isOpenMRO', 'setIsOpenMRO', false),
  withState('code', 'setCode', undefined),
  withHandlers({
    remove: ({ setLoading, removeTask, setIsOpen, setIsOpenMRO, setCode }) => async () => {
      setLoading('remove');

      await removeTask();

      setLoading(false);
      setIsOpen(false);
      setIsOpenMRO(false);
      setCode(undefined);
    },
  }),
  withHandlers({
    openConfirmBox: ({ setIsOpen, displayConfirmBox, remove }) => () => {
      if (displayConfirmBox) {
        setIsOpen(true);
      } else {
        remove();
      }
    },
    closeConfirmBox: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
    openConfirmMROModal: ({ setIsOpenMRO }) => () => {
      setIsOpenMRO(true);
    },
    closeConfirmMROModal: ({ setIsOpenMRO }) => () => {
      setIsOpenMRO(false);
    },
  }),
);

export const TaskRow = enhance(TaskRowPresentation);
