import AsyncSelect from 'react-select/async';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { getItems } from '@core/building/items';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';

export class DeviceInventoryFilterModal extends SimpleFilterModal {
  findItems = async (inputValue) => {
    const { body, status } = await getItems({ name: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ name: label, barcode1: value }) => ({ label, value }));
    }

    return [];
  };

  findInternalSku = async (inputValue) => {
    const { body, status } = await getItems({ internal_sku: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ internalSku, id: value }) => ({
        label: `${internalSku}`,
        value: `${internalSku}`,
      }));
    }

    return [];
  };

  setStartedAt = (value, stateProperty) => {
    this.setState({
      [stateProperty]: {
        ...this.state[stateProperty],
        started_at: value || undefined,
      },
    });
  };

  setEndedAt = (value, stateProperty) => {
    this.setState({
      [stateProperty]: {
        ...this.state[stateProperty],
        ended_at: value || undefined,
      },
    });
  };

  renderForm() {
    const { categories } = this.props;

    const categoriesList = categories && categories.map(({ name: label, id: value }) => ({ label, value }));

    return (
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="items[{}]">Artículos</Label>
              <AsyncSelect
                name="items[{}]"
                value={this.state['items[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findItems}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="categories[{}]">Categorías</Label>
              <Select
                name="categories[{}]"
                value={this.state['categories[{}]']}
                isClearable
                isSearchable
                isMulti
                closeMenuOnSelect={false}
                placeholder="Todos"
                options={categoriesList}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="internal_sku">SKU</Label>
              <AsyncSelect
                name="internal_sku[{}]"
                value={this.state['internal_sku[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findInternalSku}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="consumable">Consumibles</Label>
              <Select
                name="consumable"
                value={this.state['consumable']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="criticality">Criticidad</Label>
              <Select
                name="criticality"
                value={this.state['criticality']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'A', value: 'A' },
                  { label: 'B', value: 'B' },
                  { label: 'C', value: 'C' },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Cantidad disponible</Label>
              <FormGroup row>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="available_quantity{}[started_at]"
                    placeholder="Desde"
                    onChange={(e) => this.setStartedAt(e.target.value, 'available_quantity{}')}
                    value={this.state['available_quantity{}'] && this.state['available_quantity{}']['started_at']}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="available_quantity{}[ended_at]"
                    placeholder="Hasta"
                    onChange={(e) => this.setEndedAt(e.target.value, 'available_quantity{}')}
                    value={this.state['available_quantity{}'] && this.state['available_quantity{}']['ended_at']}
                    disabled={
                      !this.state['available_quantity{}'] ||
                      (this.state['available_quantity{}'] && !this.state['available_quantity{}']['started_at'])
                    }
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>Cantidad en préstamo</Label>
              <FormGroup row>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="borrowed_quantity{}[started_at]"
                    placeholder="Desde"
                    onChange={(e) => this.setStartedAt(e.target.value, 'borrowed_quantity{}')}
                    value={this.state['borrowed_quantity{}'] && this.state['borrowed_quantity{}']['started_at']}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="borrowed_quantity{}[ended_at]"
                    placeholder="Hasta"
                    onChange={(e) => this.setEndedAt(e.target.value, 'borrowed_quantity{}')}
                    value={this.state['borrowed_quantity{}'] && this.state['borrowed_quantity{}']['ended_at']}
                    disabled={
                      !this.state['borrowed_quantity{}'] ||
                      (this.state['borrowed_quantity{}'] && !this.state['borrowed_quantity{}']['started_at'])
                    }
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Cantidad en reserva</Label>
              <FormGroup row>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="reserved_quantity{}[started_at]"
                    placeholder="Desde"
                    onChange={(e) => this.setStartedAt(e.target.value, 'reserved_quantity{}')}
                    value={this.state['reserved_quantity{}'] && this.state['reserved_quantity{}']['started_at']}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="reserved_quantity{}[ended_at]"
                    placeholder="Hasta"
                    onChange={(e) => this.setEndedAt(e.target.value, 'reserved_quantity{}')}
                    value={this.state['reserved_quantity{}'] && this.state['reserved_quantity{}']['ended_at']}
                    disabled={
                      !this.state['reserved_quantity{}'] ||
                      (this.state['reserved_quantity{}'] && !this.state['reserved_quantity{}']['started_at'])
                    }
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
