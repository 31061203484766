import React from 'react';

import GridRow from './GridRow.js';
import type { ColumnMetadata } from './types';

type Props = {
  columnMetadata: ColumnMetadata[],
  data: Object[],
  editable: boolean,
  onChangeGrid: Function,
  onEditRow: Function,
  selectedRows: Object,
  showCheckbox: boolean,
  onToggleEdit: Function,
};

const GridRows = ({
  columnMetadata,
  data,
  editable,
  onChangeGrid,
  onEditRow,
  showCheckbox,
  selectedRows,
  onToggleEdit,
}: Props) => {
  const rows = data.map((item, index) => {
    return (
      <GridRow
        key={`row-${index}`}
        columnMetadata={columnMetadata}
        data={item}
        editable={editable}
        onChangeGrid={onChangeGrid}
        onToggleEdit={onToggleEdit}
        onEditRow={onEditRow}
        rowId={index}
        showCheckbox={showCheckbox}
        selectedRows={selectedRows}
        className={`row-${index}`}
      />
    );
  });

  return <tbody>{rows}</tbody>;
};

export default GridRows;
