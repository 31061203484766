import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { confirmationRedeliver } from '@core/account';
import Spinner from '../assets/Spinner';

class ConfirmationModalProcessor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      processing: false,
      redeliverDisabled: false,
    };
  }

  redeliver = () => {
    this.setState({
      processing: true,
    });

    confirmationRedeliver().then(() => {
      this.setState({
        processing: false,
        redeliverDisabled: true,
      });
    });
  };

  render() {
    return (
      <ConfirmationModal
        refresh={() => window.location.reload()}
        redeliverDisabled={this.state.redeliverDisabled}
        processing={this.state.processing}
        redeliverAction={this.redeliver}
        {...this.props}
      />
    );
  }
}

const ConfirmationModal = ({ opened, refresh, redeliverAction, className, email, redeliverDisabled, processing }) => (
  <Modal isOpen={opened} className={className} backdrop="static" keyboard={false} size="md">
    <ModalHeader>Confirmación de correo pendiente</ModalHeader>
    <ModalBody>
      <p>
        Recientemente has solicitado cambiar tu dirección de correo a <strong>{email}</strong>. Por favor, confirma tu
        dirección de correo antes de continuar.
      </p>
      <p>
        Si no has recibido este correo, puedes reenviarlo presionando el botón más abajo. Si los problemas persisten,
        por favor <NavLink to="/contact">contáctanos</NavLink>.
      </p>
      <p className="text-danger">
        <strong>Si no has solicitado este cambio,</strong> revisa tu dirección de correo actual, donde se encuentran
        instrucciones para cancelar esta solicitud. Por favor contáctanso si los problemas persisten.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button role="button" color="secondary" disabled={redeliverDisabled || processing} onClick={redeliverAction}>
        {redeliverDisabled ? 'Correo reenviado' : processing ? <Spinner /> : 'Reenviar correo'}
      </Button>
      <Button role="button" color="primary" onClick={refresh}>
        Refrescar página
      </Button>
    </ModalFooter>
  </Modal>
);

export default ConfirmationModalProcessor;
