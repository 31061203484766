import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getTopAccount = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/top_accounts/${id}`,
      method: 'GET',
    }),
  );

export const getTopAccounts = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/top_accounts${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createTopAccount = (topAccount) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/top_accounts`,
      method: 'POST',
      body: {
        topAccount,
      },
    }),
  );

export const updateTopAccount = (id, topAccount) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/top_accounts/${id}`,
      method: 'PUT',
      body: {
        topAccount,
      },
    }),
  );

export const deleteTopAccount = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/top_accounts/${id}`,
      method: 'DELETE',
    }),
  );
