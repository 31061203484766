import React, { Component, useState } from 'react';
import { Button, Col, Row, Card, CardBody, Badge } from 'reactstrap';
import { createKitResource, updateKitResource, destroyKitResource } from '@core/building/kit-resources';
import { reduxForm } from 'redux-form';
import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { connect, notificationsGenerators } from '@store';
import { compose } from 'recompose';
import { AssetSelector, ItemSelector } from '@shared/forms';
import { FontAwesome, ShowForRoles } from '@shared';
import { AssetDisplay, ItemDisplay } from '@shared/displays';

const validate = Validates(
  presence({ of: 'barcode' }),
  presence({ of: 'quantity', if: ({ consumable }) => consumable === 'true' || consumable === true }),
);

const availability = {
  true: { color: 'success', name: 'Disponible' },
  false: { color: 'danger', name: 'No disponible' },
};

const styles = {
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

class KitResourceRow extends Component {
  constructor(props) {
    super(props);

    const { kitResource } = this.props;

    this.state = {
      ...this.state,
      kitResource,
      error: false,
      edit: !kitResource.id,
      consumable: true,
    };
  }

  setEdit = () => {
    this.props.toggleEditing();
    this.setState({
      edit: true,
    });
  };

  create = (kitResource) => {
    const { kitId } = this.props;

    const bodyRequest = kitResource;

    this.setState(
      {
        processing: true,
      },
      async () => {
        const { body: newResource, status } = await createKitResource(kitId, bodyRequest);

        if (status === 201) {
          this.setState({
            kitResource: newResource,
            processing: false,
            edit: false,
          });
          this.props.onAdd(newResource);
          this.props.toggleEditing();
          this.props.successCreationNotification();
        } else if (status === 422) {
          /* TODO: Handle error with better UX? */
          this.setState({
            kitResource: bodyRequest,
            error: true,
            processing: false,
          });
          this.props.errorNotification();
        }
      },
    );
  };

  update = (kitResource) => {
    const { kitId } = this.props;

    const bodyRequest = kitResource;

    this.setState(
      {
        processing: true,
      },
      async () => {
        const { body: resource, status } = await updateKitResource(kitId, bodyRequest);

        if (status === 200) {
          this.setState({
            KitResource: resource,
            processing: false,
            edit: false,
          });
          this.props.toggleEditing();
          this.props.successCreationNotification();
        } else if (status === 422) {
          /* TODO: Handle error with better UX? */
          this.setState({
            kitResource,
            error: true,
            processing: false,
          });
          this.props.errorNotification();
        }
      },
    );
  };

  remove = () => {
    const { kitResource } = this.state;
    const { kitId, onDelete, index } = this.props;

    // New entries shall be deleted immediately.
    if (!kitResource || !kitResource.id) {
      onDelete && onDelete(index);
      this.props.toggleEditing();
      return;
    }

    // Proceed with deletion
    this.setState(
      {
        processing: true,
      },
      async () => {
        const { status } = await destroyKitResource(kitId, kitResource);

        if (status !== 204) return;

        this.setState({
          processing: false,
        });

        onDelete && onDelete(index);
      },
    );
  };

  handleCancel = () => {
    const { kitResource } = this.state;

    if (!!kitResource.id) {
      this.setState(
        {
          edit: false,
        },
        this.props.toggleEditing(),
      );
    } else {
      this.remove();
    }
  };

  render() {
    const { kitResource, edit, processing } = this.state;
    const { kitKind } = this.props;

    const ResourceForm = KitResourceForm({ kitResource, kitKind });

    const barcode = kitResource.item
      ? kitResource.item.barcode1
      : kitResource.asset
      ? kitResource.asset.barcode1
      : undefined;

    if (edit) {
      return (
        <Card className="mb-2">
          <CardBody>
            <ResourceForm
              onSubmit={!kitResource.id ? this.create : this.update}
              initialValues={{
                ...kitResource,
                kitKind,
                consumable: true,
                barcode,
              }}
              processing={processing}
              cancel={this.handleCancel}
            />
          </CardBody>
        </Card>
      );
    }
    return (
      <Card className="mb-2">
        <CardBody>
          <KitResourcePresentation
            kitResource={kitResource}
            setEdit={this.setEdit}
            remove={this.remove}
            editing={this.props.editing}
          />
        </CardBody>
      </Card>
    );
  }
}

const KitResourceFormPresentation = ({
  initialValues: { kitKind },
  processing,
  handleSubmit,
  cancel,
  resetForm,
  valid,
}) => {
  const [consumable, setConsumable] = useState('true');
  console.log(kitKind);

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Row>
        <Col xs={3} className="resource-kit-inputs align-middle">
          <Field
            type="select"
            name="consumable"
            options={{ true: 'Consumibles', false: 'Activos' }}
            onChange={(e) => {
              resetForm();
              setConsumable(e.target.value);
            }}
            value={consumable}
            disabled={kitKind === 'temporary'}
            help={kitKind === 'temporary'}
          />
        </Col>

        <Col xs={5} className="resource-kit-inputs">
          <Field
            name="barcode"
            filters={
              consumable === 'true'
                ? { active: true, consumable: true }
                : { active: true, states: 'stored', health_states: 'new,unknown,repair' }
            }
            component={consumable === 'true' ? ItemSelector : AssetSelector}
            style={{ marginBottom: 0 }}
          />
        </Col>

        <Col xs={2} className="resource-kit-inputs">
          {consumable === 'true' && <Field name="quantity" placeholder="Cantidad" autoComplete="off" />}
        </Col>

        <Col xs={2}>
          <span className="float-right">
            <Button type="submit" role="button" color="success" disabled={processing || !valid} className="mr-2">
              <FontAwesome name="check" />
            </Button>
            <Button role="button" color="danger" onClick={cancel}>
              <FontAwesome name="times" />
            </Button>
          </span>
        </Col>
      </Row>
    </Form>
  );
};

const KitResourcePresentation = ({ remove, editing, kitResource }) => {
  return (
    <Row>
      <Col xs={2}>
        {kitResource.item ? (
          <p>
            <FontAwesome name="th-large" color={'success'} /> <Badge color={'success'}>Artículo</Badge>
          </p>
        ) : (
          <p className="align-middle">
            <FontAwesome name="wrench" color={'primary'} /> <Badge color={'primary'}>Áctivo</Badge>
          </p>
        )}
      </Col>
      <Col xs={5} className="text-left">
        {kitResource.item !== null ? (
          <ItemDisplay item={kitResource.item} />
        ) : (
          <AssetDisplay asset={kitResource.asset} showAvailability={false} />
        )}
      </Col>
      <Col xs={2} className="text-left">
        <span>
          <b>Cantidad:</b>
          <p className="mb-0">{kitResource.quantity || 0}</p>
        </span>
      </Col>
      <Col xs={2}>
        <b>Disponibilidad:</b>
        <div className={`text-${availability[kitResource.available]?.color}`}>
          <strong> {availability[kitResource.available]?.name} </strong>
        </div>
      </Col>
      <Col xs={1} style={styles.buttons}>
        <ShowForRoles excluded={['guest']}>
          <Button role="button" color="danger" onClick={remove} disabled={editing}>
            <FontAwesome name="trash" />
          </Button>
        </ShowForRoles>
      </Col>
    </Row>
  );
};

const KitResourceForm = ({ kitResource: { id } }) =>
  compose(
    reduxForm({
      form: `kitResourceForm-${id}`,
      validate,
    }),
    connect(null, (dispatch) => ({
      resetForm() {
        dispatch({
          type: '@@redux-form/RESET',
          meta: {
            form: `kitResourceForm-${id}`,
          },
        });
      },
    })),
  )(KitResourceFormPresentation);

export default connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Recurso del kit creado',
        text: `El artículo o activo ha sido asignado exitosamente.`,
        color: 'success',
      }),
    ),
  errorNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error',
        text: 'No se pudo ejecutar la acción. Contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(KitResourceRow);
