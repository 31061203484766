import { FontAwesome } from '@shared';
import { AssetSelector, DateTimeSelector, ItemSelector } from '@shared/forms';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import React, { useState } from 'react';
import { Field, Form } from '@core/forms';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import Validates, { presence } from '@core/forms/validations';
import { createInventoryCheckerSet } from '@core/building/inventory-checker-sets';
import { compose } from 'recompose';
import { connect } from '@store';
import { Redirect } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { LOADING_MESSAGE, NO_OPTIONS } from '@shared/simple-filter/SimpleFilter';
import { getUsers } from '@core/building/users';
import ChileanRUT from '@core/utils/chilean-rut';
import { getAsset } from '@core/building/assets';
import { getItem } from '@core/building/items';
import { AssetDisplay, ItemDisplay } from '@shared/displays';

const InventoryCheckerSetPresentation = ({ resetForm, initialValues = null }) => {
  const [consumable, setConsumable] = useState(initialValues ? initialValues.consumable : undefined);
  const [randomItems, setRandomItems] = useState(initialValues ? initialValues.randomItems : undefined);
  const [auditors, setAuditors] = useState(initialValues ? initialValues.auditors : []);
  const [startAt, setStartAt] = useState(initialValues ? initialValues.startAt : undefined);
  const [endAt, setEndAt] = useState(initialValues ? initialValues.endAt : undefined);
  const [frequency, setFrequency] = useState(initialValues ? initialValues.frequency : undefined);
  const [size, setSize] = useState(initialValues ? initialValues.size : undefined);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState(false);
  const [samples, setSamples] = useState([]);
  const [sampleBarcode, setSampleBarcode] = useState(undefined);
  const [barcodes, setBarcodes] = useState([]);

  const toggle = () => setModal(!modal);

  // Limpia o resetea el formulario del reporte
  const clearReport = () => {
    setConsumable(false);
    setAuditors([]);
    setStartAt(undefined);
    setEndAt(undefined);
    setFrequency(undefined);
    setSize(undefined);
    setSamples([]);
    setBarcodes([]);
    setSampleBarcode(undefined);
    setRandomItems(undefined);
    resetForm();
  };

  const submitReport = async () => {
    setSubmitting(true);

    const data = {
      consumable,
      auditors: auditors.map((auditor) => auditor.value),
      startAt,
      endAt,
      frequency,
      size: randomItems === 'true' ? Number(size) : null,
      randomItems,
      barcodes,
    };

    const { body, status } = await createInventoryCheckerSet(data);
    if (status === 201) {
      setRedirect(true);
    } else {
      console.log(body?.errors ? body?.errors : 'no existe errors');
    }
    toggle();
    setSubmitting(false);
  };

  const findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return body.map(({ firstName, lastName, identifier, email }) => ({
        label: `${firstName} ${lastName} - ${
          identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
        }`,
        value: email,
      }));
    }
    return [];
  };

  // Crea la petición para generar el reporte
  const generateReport = () => {
    setModal(true);
    setBarcodes(samples.map((asset) => asset.barcode1).join(','));
  };

  // Agrega un nuevo asset o item al reporte
  const addSample = async (barcode) => {
    if (samples.some((sample) => sample.barcode1 === barcode)) {
      alert('La muestra está repetida');
      setSampleBarcode(undefined);
    } else {
      const { body, status } = consumable === 'true' ? await getItem(barcode) : await getAsset(barcode);
      if (status === 200 || status === 304) {
        setSamples([...samples, body]);
        setSampleBarcode(undefined);
      } else {
        console.log(body.errors);
      }
    }
  };

  // Elimina un Sample del reporte
  const removeSample = (barcode) => {
    setSamples([...samples.filter((sample) => sample.barcode1 !== barcode)]);
  };

  if (redirect) {
    return <Redirect to="/building/inventory-checker-sets" />;
  }

  return (
    <BuildingLayout>
      <SimpleContainerLayout title="Programar reportes de inventario">
        <Row>
          <Col>
            <Form>
              <Card>
                <CardHeader>
                  <Row>
                    <Col md={4}>
                      <Field
                        type="select"
                        name="consumable"
                        label="Tipo de artículos"
                        options={{ '': 'Seleccione...', false: 'Activos', true: 'Consumibles' }}
                        onChange={(e) => {
                          setConsumable(e.target.value);
                          setSamples([]);
                          setBarcodes([]);
                          setSampleBarcode(undefined);
                        }}
                        value={consumable}
                        required
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        type="select"
                        name="randomItems"
                        label="Artículos aleatorios"
                        options={{ '': 'Seleccione...', true: 'Sí', false: 'No' }}
                        onChange={(e) => {
                          setRandomItems(e.target.value);
                          if (!e.target.value) {
                            setSize(undefined);
                          } else {
                            setSamples([]);
                            setBarcodes([]);
                            setSampleBarcode(undefined);
                          }
                        }}
                        value={randomItems}
                        required
                      />
                    </Col>
                    {randomItems === 'true' && (
                      <Col md={4}>
                        <Field
                          type="select"
                          name="size"
                          label="Cantidad"
                          options={{
                            '': 'Seleccione...',
                            5: '5',
                            10: '10',
                            15: '15',
                            20: '20',
                          }}
                          onChange={(e) => setSize(e.target.value)}
                          value={size}
                          required
                        />
                      </Col>
                    )}
                  </Row>
                </CardHeader>
                <CardBody>
                  {randomItems === 'false' && (
                    <div>
                      <Row>
                        {consumable === 'true' ? (
                          <Col sm={12} md={8} className="pb-2">
                            <Label for="targetInput">Seleccione una muestra</Label>
                            <ItemSelector
                              name="targetInput"
                              inputProps={{ size: 'lg' }}
                              filters={{ consumable: 'true' }}
                              input={{
                                onChange: setSampleBarcode,
                              }}
                            />
                          </Col>
                        ) : (
                          <Col sm={10} md={8} className="pb-2">
                            <Label for="targetInput">Seleccione una muestra</Label>
                            <AssetSelector
                              name="targetInput"
                              inputProps={{ size: 'lg' }}
                              filters={{ states: 'stored' }}
                              label="Seleccione un activo"
                              input={{
                                onChange: setSampleBarcode,
                              }}
                            />
                          </Col>
                        )}
                        <Col sm={2}>
                          <Row>
                            <Col>
                              <Label>Agregar</Label>
                            </Col>
                          </Row>
                          <Button
                            type="button"
                            role="button"
                            color="success"
                            size="lg"
                            onClick={() => addSample(sampleBarcode)}
                            disabled={!sampleBarcode}
                          >
                            <FontAwesome name="plus" />
                          </Button>
                        </Col>
                      </Row>
                      {samples.length > 0 && <Label>Lista de muestras</Label>}
                      <Card body style={{ minHeight: '15vh' }}>
                        {samples.length > 0 &&
                          samples.map((sample, index) => (
                            <Row
                              key={`Sample-${index}`}
                              className="pt-3"
                              style={{ backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'transparent'}` }}
                            >
                              <Col sm={12} md={6} className="mb-3">
                                {consumable === 'true' ? (
                                  <ItemDisplay item={sample} />
                                ) : (
                                  <AssetDisplay asset={sample} />
                                )}
                              </Col>
                              <Col sm={2} md={1} className="mb-3">
                                <Button
                                  type="button"
                                  role="button"
                                  color="danger"
                                  onClick={() => removeSample(sample?.barcode1)}
                                >
                                  <FontAwesome name="times" />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                      </Card>
                      <hr />
                    </div>
                  )}
                  <Row>
                    <Col md={4}>
                      <Field
                        name="startAt"
                        label="Fecha de inicio"
                        required
                        component={DateTimeSelector}
                        minDate={new Date()}
                        onChange={setStartAt}
                        value={startAt}
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        name="endAt"
                        label="Fecha de finalización"
                        required
                        component={DateTimeSelector}
                        minDate={!!startAt ? startAt : new Date()}
                        onChange={setEndAt}
                        value={endAt}
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        type="select"
                        name="frequency"
                        label="Frecuencia"
                        options={{
                          '': 'Seleccione...',
                          daily: 'Diaria',
                          weekly: 'Semanal',
                          monthly: 'Mensual',
                          yearly: 'Anual',
                        }}
                        onChange={(e) => setFrequency(e.target.value)}
                        value={frequency}
                        required
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col>
                      <Label for="auditors">Auditores</Label>
                    </Col>
                  </Row>
                  <AsyncSelect
                    name="auditors"
                    value={auditors}
                    isClearable
                    isSearchable
                    isMulti
                    placeholder="Buscar..."
                    loadOptions={findUsers}
                    noOptionsMessage={() => NO_OPTIONS}
                    loadingMessage={() => LOADING_MESSAGE}
                    onChange={(e) => setAuditors(e)}
                  />
                </CardBody>
                <CardFooter>
                  <Button
                    type="button"
                    role="button"
                    color="primary"
                    className="mr-2"
                    onClick={generateReport}
                    disabled={
                      !auditors ||
                      (auditors && auditors.length === 0) ||
                      submitting ||
                      consumable === undefined ||
                      startAt === undefined ||
                      endAt === undefined ||
                      (randomItems === true && size === undefined) ||
                      (randomItems === false && !(samples.length > 0)) ||
                      frequency === undefined
                    }
                  >
                    <FontAwesome name="check" /> Programar Reportes
                  </Button>
                  <Button type="button" role="button" color="danger" onClick={clearReport} disabled={submitting}>
                    <FontAwesome name="times" /> Borrar Formulario
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>Confirmar reporte</ModalHeader>
            <ModalBody>¿Desea programar un reporte de inventario y notificar a los usuarios seleccionados?</ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={submitReport} disabled={submitting}>
                Generar
              </Button>{' '}
              <Button color="danger" onClick={toggle} disabled={submitting}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </SimpleContainerLayout>
    </BuildingLayout>
  );
};

const validate = Validates(
  presence({ of: 'consumable' }),
  presence({ of: 'size' }),
  presence({ of: 'startAt' }),
  presence({ of: 'endAt' }),
  presence({ of: 'frequency' }),
  presence({ of: 'active' }),
);

export const InventoryCheckerSetForm = compose(
  reduxForm({
    form: 'InventoryCheckerForm',
    validate,
  }),
  connect(null, (dispatch) => ({
    resetForm() {
      dispatch({
        type: '@@redux-form/RESET',
        meta: {
          form: 'InventoryCheckerForm',
        },
      });
    },
  })),
)(InventoryCheckerSetPresentation);
