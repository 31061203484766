export const dd2DMS = (lat, lng) => {
  let lt = Number(lat);
  let lg = Number(lng);
  // latitud
  const dirLat = lt < 0 ? 'S' : 'N';
  const degLat = 0 | (lt < 0 ? (lt = -lt) : lt);
  const minLat = 0 | (((lt += 1e-9) % 1) * 60);
  const secLat = (0 | (((lt * 60) % 1) * 6000)) / 100;

  // longitud
  const dirLng = lg < 0 ? 'W' : 'E';
  const degLng = 0 | (lg < 0 ? (lg = -lg) : lg);
  const minLng = 0 | (((lg += 1e-9) % 1) * 60);
  const secLng = (0 | (((lg * 60) % 1) * 6000)) / 100;

  return `${degLat}°${minLat}'${secLat}"${dirLat}+${degLng}°${minLng}'${secLng}"${dirLng}`;
};
