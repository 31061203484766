import { FontAwesome, PictureDisplay } from '@shared';
import moment from 'moment';
import React from 'react';
import { Media, UncontrolledTooltip } from 'reactstrap';
import './maintenance-display.scss';

export const MaintenanceAssetCard = ({ asset, building, leadTime, price, currency, comments, attachmentsTokens }) => {
  return (
    <div>
      <Media className="align-items-center mt-1 mb-1">
        <Media left className="mr-3">
          <PictureDisplay
            picture={asset.item.defaultPicture}
            pictureSize="icon"
            alt={asset.item.name}
            width="6rem"
            height="4rem"
          />
        </Media>
        <Media body>
          <div className="mb-1">
            <strong>{asset.item.name}</strong>
          </div>
          <div className="small">
            <kbd>{asset.barcode1}</kbd>
            {building && (
              <div>
                <FontAwesome name="industry" />
                <span className="ml-1">{building.name}</span>
              </div>
            )}
            {leadTime && (
              <div>
                <FontAwesome name="calendar-alt" />
                <span className="ml-1">{moment(leadTime).format('YYYY-MM-DD h:mm A')}</span>
              </div>
            )}
            {price && currency && (
              <div>
                <FontAwesome name="calendar-alt" />
                <span className="ml-1">{`${price} ${currency}`}</span>
              </div>
            )}
          </div>
          {((attachmentsTokens && attachmentsTokens.length > 0) || comments) && (
            <div>
              {attachmentsTokens && attachmentsTokens.length > 0 && (
                <span>
                  <FontAwesome id={`repair-${asset.id}-attachments`} name="paperclip" />
                  <UncontrolledTooltip placement="bottom" target={`repair-${asset.id}-attachments`}>
                    <span className="small">Contiene archivos adjuntos</span>
                  </UncontrolledTooltip>
                </span>
              )}
              {comments && <span className="small">Observaciones: {comments}</span>}
            </div>
          )}
        </Media>
      </Media>
    </div>
  );
};
