import React, { forwardRef } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { isEmpty } from 'lodash';

import { ProcessingButton } from '@shared';

type Props = {
  file: any,
  finished: boolean,
  onSubmit: Function,
  processing: boolean,
  queried: boolean,
  reset: Function,
  setFile: Function,
  selected: Object,
};

const MassImportFileForm = forwardRef(
  ({ file, finished, onSubmit, processing, queried, reset, setFile, selected }: Props, ref) => {
    let innerComponent;

    if (file) {
      innerComponent = (
        <div>
          <div className="mb-3">
            Archivo seleccionado:
            <br />
            <code>{file.name}</code>
          </div>
          <div className="small text-muted">Click aquí para cambiarlo</div>
        </div>
      );
    } else {
      innerComponent = (
        <div>
          <div className="mb-3">Click aquí para cargar archivo</div>
          <div className="small text-muted">
            Solo se permiten archivos <code>.csv</code>
          </div>
        </div>
      );
    }

    return (
      <Form noValidate disabled={processing || finished} onSubmit={onSubmit}>
        <FormGroup>
          <Label for="file" className="file-label mb-4" role="button">
            {innerComponent}
          </Label>
          <Input
            name="file"
            id="file"
            type="file"
            className="d-none"
            accept=".csv,text/csv,application/csv"
            multiple={false}
            onChange={setFile}
            disabled={processing || finished}
            innerRef={ref}
          />
          {!queried && !finished && (
            <ProcessingButton
              type="submit"
              role="button"
              color="success"
              size="lg"
              disabled={finished || !file || processing || isEmpty(selected)}
              processing={processing}
              block
              outline
            >
              Cargar archivo
            </ProcessingButton>
          )}
          {(queried || finished) && (
            <Button type="button" role="button" color="primary" size="lg" block outline onClick={reset}>
              Comenzar nuevamente
            </Button>
          )}
        </FormGroup>
      </Form>
    );
  },
);

export default MassImportFileForm;
