import React from 'react';
import { Media } from 'reactstrap';

import { RemoteSelector } from '@core/forms';
import { getTopAccount, getTopAccounts } from '@core/building/top-accounts';

export const TopAccountCard = ({ resource: topAccount }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{topAccount.code}</strong>
        {' - '}
        <strong>{topAccount.name}</strong>
      </div>
    </Media>
  </Media>
);

const TopAccountFinder = ({ inputProps, filters, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getTopAccount}
    remoteFinder={(query) => getTopAccounts({ search: query, ...filters })}
    dropdownTemplate={TopAccountCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por código o nombre de la cuenta mayor',
    }}
  />
);

export default TopAccountFinder;
