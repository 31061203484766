import React, { Component } from 'react';
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, Media } from 'reactstrap';

import { roleName } from '@core/account';
import { connect, sessionGenerators } from '@store';
import { OrganizationCard } from '../index';

class MembershipSelector extends Component {
  processMembership = () => {
    const { session } = this.props;

    if (!session.loggedIn || session.user.rootRole !== 'user' || session.mustSetMembership) return;

    // Check if updated session removes access to the organization
    if (session.currentMembership) {
      let membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

      if (!membership || !membership.active) {
        this.props.forceSetMembership();
      }

      return;
    }

    if (session.user.memberships.length === 1) this.props.setMembership(session.user.memberships[0].id);
  };

  componentDidMount = this.processMembership;

  componentDidUpdate = this.processMembership;

  render() {
    const { session, setMembership, roles, closeSetMembership } = this.props;
    const { user } = session;

    const opened =
      session.loggedIn &&
      session.user.memberships.length > 0 &&
      (session.mustSetMembership || (session.user.rootRole === 'user' && !session.currentMembership));

    return (
      <MembershipSelectorModal
        opened={opened}
        showNone={session.user.rootRole !== 'user'}
        memberships={user?.memberships?.filter(
          (membership) => membership.active && membership.building.active && membership.building.organization.active,
        )}
        setMembership={(e) => {
          setMembership(e);
          window.location = '/';
        }}
        roles={roles}
        closeSetMembership={closeSetMembership}
      />
    );
  }
}

const MembershipSelectorModal = ({ opened, setMembership, memberships, showNone, roles, closeSetMembership }) => (
  <Modal isOpen={opened} toggle={() => (opened ? closeSetMembership() : null)} size="lg">
    <ModalHeader>Seleccionar acceso</ModalHeader>
    <ModalBody>
      <p>¿Con cuál organización / instalación deseas conectarte?</p>

      {memberships &&
        memberships.map((membership, index) => (
          <Card
            className="mb-3"
            role={membership.building.active ? 'button' : ''}
            onClick={() => setMembership(membership.id)}
            key={index}
          >
            <CardBody>
              <Media className="align-items-center">
                <Media left className="mr-3">
                  <OrganizationCard
                    width="6em"
                    height="4em"
                    url={membership.building.organization.avatar && membership.building.organization.avatar.icon.url}
                    alt={membership.building.organization.name}
                  />
                </Media>
                <Media body>
                  <div>
                    <strong>{membership.building.organization.name}</strong>
                  </div>
                  <div>{membership.building.name}</div>
                </Media>
                <Media right className="text-muted">
                  <div>{roleName(membership.role, roles)}</div>
                </Media>
              </Media>
            </CardBody>
          </Card>
        ))}

      {showNone && (
        <div className="text-center mt-3">
          <Button type="button" role="button" color="danger" onClick={closeSetMembership}>
            Ninguno
          </Button>
        </div>
      )}
    </ModalBody>
  </Modal>
);

export default connect(
  ({ appData: { roles }, session }) => ({ roles, session }),
  (dispatch) => ({
    setMembership: (membership) => dispatch(sessionGenerators.setMembership(membership)),
    forceSetMembership: () => dispatch(sessionGenerators.forceSetMembership()),
    closeSetMembership: () => dispatch(sessionGenerators.closeSetMembership()),
  }),
)(MembershipSelector);
