import { createArea } from '@core/building/areas';
import { SubmissionError } from '@core/forms';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { AreaForm } from './components/AreaForm';

class NewAreaPresentation extends Component {
  state = {
    created: false,
  };

  createArea = async (data) => {
    const { body, status } = await createArea(data);

    if (status === 201) {
      this.setState({
        created: true,
      });

      this.props.successNotification(data.name);
    } else if (status === 422) throw new SubmissionError(body.errors);
  };

  render() {
    const { created } = this.state;

    if (created) return <Redirect to="/building/areas" />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Nueva área">
          <AreaForm onSubmit={this.createArea} />
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const NewArea = connect(null, (dispatch) => ({
  successNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Área creada',
        text: `El área ${name} ha sido creada exitosamente.`,
        color: 'success',
      }),
    ),
}))(NewAreaPresentation);
