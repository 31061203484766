import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const getProviderMovements = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/repair_provider/movements${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const createProviderMovement = (movement) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/repair_provider/movements',
      method: 'POST',
      body: { movement },
    }),
  );
