import React from 'react';
import { FontAwesome } from '@shared/';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';

export const EventGeofence = ({ geofence }) => (
  <Media className="small">
    {geofence && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <span className="mr-1">
            <FontAwesome name="dot-circle" color="info" className="mr-1" /> {geofence.name}
          </span>
        </div>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="th-large" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">{geofence.itemName}</span>
        </div>
        <NavLink to={`/building/assets/${geofence.assetBarcode1}`}>
          <FontAwesome name="barcode" color="info" className="mr-1" />
          <kbd>{geofence.assetBarcode1}</kbd>
        </NavLink>
      </Media>
    )}
  </Media>
);

export const EventGeofenceModal = ({ geofence }) => (
  <div>
    <Media>
      {geofence && (
        <Media body>
          <div className="w-100 text-overflow mt-1">
            <span className="mr-1">
              <FontAwesome name="dot-circle" color="info" className="mr-1" /> Geocerca: {geofence.name}
            </span>
          </div>
          <div className="w-100 text-overflow mt-1">
            <FontAwesome name="th-large" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{geofence.itemName}</span>
          </div>
          <NavLink to={`/building/assets/${geofence.assetBarcode1}`}>
            <FontAwesome name="barcode" color="info" className="mr-1  " />
            <span className="text-muted mr-1">Activo:</span>
            <kbd>{geofence.assetBarcode1}</kbd>
          </NavLink>
          <div className="w-100 text-overflow mt-1">
            <FontAwesome name="dot-circle" color="info" className="mr-1" />
            <span className="text-muted mr-1">Estado:</span>
            {geofence.assetState}
          </div>
          <div className="w-100 text-overflow mt-1">
            <span className="mr-1">
              <FontAwesome name="heartbeat" color="info" className="mr-1" />
              <span className="text-muted mr-1">Estado de salud:</span>
              {geofence.assetStateHealth}
            </span>
          </div>
          <div className="w-100 text-overflow mt-1">
            <span className="mr-1">
              <FontAwesome name="calendar-day" color="info" className="mr-1" />
              <span className="text-muted mr-1">Fecha:</span>
              {geofence.date}
            </span>
          </div>
        </Media>
      )}
    </Media>
  </div>
);
