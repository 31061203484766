import { branch, compose, lifecycle, mapProps, renderComponent, withState } from 'recompose';

import { Loading } from '@shared';

const withLoader = (propName, resource, { onError } = {}) =>
  compose(
    // loading - failed - success
    withState('loadState', 'setLoadState', 'loading'),
    withState('resources', 'setResources', undefined),
    lifecycle({
      async componentDidMount() {
        const { setLoadState, setResources } = this.props;

        const { body, status } = await resource(this.props);

        if (String(status).startsWith('2')) {
          setLoadState('success');
          setResources(body);
        } else {
          setLoadState('failed');
          onError && onError(body, status);
        }
      },
    }),
    branch(({ loadState, resources }) => !resources || loadState === 'loading', renderComponent(Loading)),
    mapProps(({ resources, ...rest }) => ({ ...rest, [propName]: resources })),
  );

export default withLoader;
