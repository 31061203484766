import React from 'react';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';

import { PickingsIndex } from './PickingsIndex';
import { PickingPage } from './PickingPage';
import { CreatePicking } from './CreatePicking';

export const PickingsRoutes = ({ match }) => (
  <Switch>
    {console.log(match.url)}
    <ProtectedRoute
      exact
      path={`${match.url}/`}
      component={PickingsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />

    <ProtectedRoute
      exact
      path={`${match.url}/new`}
      component={CreatePicking}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
    />

    <ProtectedRoute
      exact
      path={`${match.url}/:id`}
      component={PickingPage}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />
  </Switch>
);
