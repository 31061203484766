import { getAssetRepairs } from '@core/building/asset-repairs';
import { getAssetEvaluations } from '@core/building/asset-evaluations';
import { getAssetMaintenances } from '@core/building/asset-maintenances';
import { getAssetPreservations } from '@core/building/asset-preservations';
import { getAssetCertifications } from '@core/building/asset-certifications';
import { getAssetAssurances } from '@core/building/asset-assurances';
import { getAssetDiscards } from '@core/building/asset-discards';
import { getLastMovement } from '@core/building/movements';
import { getTags } from '@core/building/tags';
import { getEvents } from '@core/event/events';
import { getLocation } from '@core/organization/locations';
import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, InfoDisplay, ShowBool, ShowForRoles, simpleFilterQuery } from '@shared';
import { shouldBlockMovements } from '@shared/helpers';
import { MetadataDisplay } from '@shared/tags/MetadataDisplay';
import { EventsResults } from 'events/components/EventsResults';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { AssetMovements } from './AssetMovements';
import { highIsBetter, lowIsBetter } from '@core/utils/telemetries';
import { Document, Page, pdfjs } from 'react-pdf';
import ValidityPeriodRow from '@shared/validity-period/ValidityPeriodRow';
import { InventoryCheckerDetail } from 'building/inventory-checker/components/InventoryCheckerDetail';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const AssetSummary = ({ asset }) => {
  const [tags, setTags] = useState([]);
  const [location, setLocation] = useState(null);

  const [assetRepairs, setAssetRepairs] = useState([]);
  const [lastAssetRepairs, setLastAssetRepairs] = useState(null);
  const [assetRepairsCount, setAssetRepairsCount] = useState([]);

  const [assetEvaluations, setAssetEvaluations] = useState([]);
  const [lastAssetEvaluations, setLastAssetEvaluations] = useState(null);
  const [assetEvaluationsCount, setAssetEvaluationsCount] = useState([]);

  const [assetMaintenances, setAssetMaintenances] = useState([]);
  const [lastAssetMaintenances, setLastAssetMaintenances] = useState(null);
  const [assetMaintenancesCount, setAssetMaintenancesCount] = useState([]);

  const [assetPreservations, setAssetPreservations] = useState([]);
  const [lastAssetPreservations, setLastAssetPreservations] = useState(null);
  const [assetPreservationsCount, setAssetPreservationsCount] = useState([]);

  const [assetCertifications, setAssetCertifications] = useState([]);
  const [lastAssetCertifications, setLastAssetCertifications] = useState(null);
  const [assetCertificationsCount, setAssetCertificationsCount] = useState([]);

  const [assetAssurances, setAssetAssurances] = useState([]);
  const [lastAssetAssurances, setLastAssetAssurances] = useState(null);
  const [assetAssurancesCount, setAssetAssurancesCount] = useState([]);

  const [assetDiscards, setAssetDiscards] = useState([]);
  const [lastAssetDiscards, setLastAssetDiscards] = useState(null);
  const [assetDiscardsCount, setAssetDiscardsCount] = useState([]);

  const [events, setEvents] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const assetStates = useSelector(({ appData }) => appData.asset_states);
  const assetHealthStates = useSelector(({ appData }) => appData.asset_health_states);
  const computedStates = useSelector(({ appData }) => appData.computed_states);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const setPdfInfo = ({ numPages }) => {
    setTotalPages(numPages);
  };

  const previousPage = () => {
    setCurrentPage(Math.min(Math.max(1, currentPage - 1), totalPages));
  };

  const nextPage = () => {
    setCurrentPage(Math.min(Math.max(1, currentPage + 1), totalPages));
  };
  const base64ToArrayBuffer = (base64) => {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  };

  useEffect(() => {
    const fetchLocation = async (location) => {
      let { body, status } = await getLocation(location);

      if (status === 200) {
        setLocation(body);
      }
    };

    const fetchTags = async (asset) => {
      const { body: tags, status } = await getTags({ assets: asset.id, pp: 5 });
      if (status === 200) {
        setTags(tags);
      }
    };

    const fetchMovements = async (asset, bpm, cb) => {
      const today = new Date();
      const oneYearAgo = new Date(today.setDate(today.getDate() - 365));
      const params = {
        assets: asset.id,
        'movement_period[started_at]': oneYearAgo,
        paginate: false,
      };
      params[`${bpm}_state`] = 'completed';
      const { body, status } = await getLastMovement(params);

      if (status === 200) {
        cb(body);
      }
    };

    const fetchAssetsRepairs = async (asset, bpm, last, count) => {
      const today = new Date();
      const oneYearAgo = new Date(today.setDate(today.getDate() - 365));
      const params = {
        assets: asset.id,
        paginate: false,
        'updated_period[started_at]': oneYearAgo,
        state: 'completed',
      };
      const { body, status } = await bpm(params);

      if (status === 200) {
        last(body?.length > 0 ? body.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))[0] : null);
        count(body?.length > 0 ? body?.length : '');
      }
    };

    const fetchEvents = async (asset) => {
      const { body, status } = await getEvents({
        pp: 5,
        assets: asset.id,
      });

      if (status === 200) {
        setEvents(body);
      }
    };

    if (asset.location) {
      fetchLocation(asset.location);
    }

    fetchTags(asset);
    fetchMovements(asset, 'repair', setAssetRepairs);
    fetchAssetsRepairs(asset, getAssetRepairs, setLastAssetRepairs, setAssetRepairsCount);
    fetchMovements(asset, 'evaluation', setAssetEvaluations);
    fetchAssetsRepairs(asset, getAssetEvaluations, setLastAssetEvaluations, setAssetEvaluationsCount);
    fetchMovements(asset, 'maintenance', setAssetMaintenances);
    fetchAssetsRepairs(asset, getAssetMaintenances, setLastAssetMaintenances, setAssetMaintenancesCount);
    fetchMovements(asset, 'preservation', setAssetPreservations);
    fetchAssetsRepairs(asset, getAssetPreservations, setLastAssetPreservations, setAssetPreservationsCount);
    fetchMovements(asset, 'certification', setAssetCertifications);
    fetchAssetsRepairs(asset, getAssetCertifications, setLastAssetCertifications, setAssetCertificationsCount);
    fetchMovements(asset, 'assurance', setAssetAssurances);
    fetchAssetsRepairs(asset, getAssetAssurances, setLastAssetAssurances, setAssetAssurancesCount);
    fetchMovements(asset, 'discard', setAssetDiscards);
    fetchAssetsRepairs(asset, getAssetDiscards, setLastAssetDiscards, setAssetDiscardsCount);
    fetchEvents(asset);
  }, [asset]);

  const computed = computedStates[asset.availability];

  const {
    gForceEventHarmful,
    gForceEventWarning,
    temperatureLevelHarmful,
    temperatureLevelWarning,
    workTripHarmful,
    workTripWarning,
    manualAttachment: { url, base64 },
    validityPeriods,
  } = asset;
  console.log(asset);
  const regex = /([a-zA-Z0-9\s_\\.\-():])+(.pdf)$/i;
  const showManualPdf = regex.test(url);

  return (
    <div>
      <Row>
        <Col className="mb-4" sm={12} md={8}>
          <Row>
            <Col xs={7} className="mb-8">
              <h5 className="mb-3">Información relevante</h5>
              <div className="d-flex justify-content-between mb-2">
                <span>¿En renta?</span>
                <span>{<ShowBool value={asset.isRental} />}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Disponibilidad</span>
                <span>
                  {(computed && <span className={`small text-${computed.color}`}>{computed.name}</span>) || (
                    <span className="small text-muted">-</span>
                  )}
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Estado</span>
                <span>
                  {(assetStates[asset.state] && (
                    <span className={`small text-${assetStates[asset.state].color}`}>
                      {assetStates[asset.state].name}
                    </span>
                  )) || <span className="small text-muted">No disponible</span>}
                </span>
              </div>
              {asset.healthState && (
                <div className="d-flex justify-content-between mb-2">
                  <span>Estado de salud</span>
                  <span>
                    {(assetHealthStates[asset.healthState] && (
                      <div>
                        {asset.healthState === 'with_observations' ? (
                          <Button
                            role="button"
                            color="white"
                            tag={NavLink}
                            to={`/movements/${
                              [
                                ...assetRepairs,
                                ...assetEvaluations,
                                ...assetMaintenances,
                                ...assetCertifications,
                                ...assetPreservations,
                                ...assetCertifications,
                                ...assetAssurances,
                                ...assetDiscards,
                              ].sort(function (a, b) {
                                return new Date(b?.movementAt) - new Date(a?.movementAt);
                              })[0]?.id
                            }`}
                            className="pr-0"
                            disabled={false}
                          >
                            <span className={`small text-${assetHealthStates[asset.healthState].color}`}>
                              {assetHealthStates[asset.healthState].name} <FontAwesome name="link" />
                            </span>
                          </Button>
                        ) : (
                          <span className={`small text-${assetHealthStates[asset.healthState].color}`}>
                            {assetHealthStates[asset.healthState].name}
                          </span>
                        )}
                      </div>
                    )) || <span className="small text-muted">No disponible</span>}
                  </span>
                </div>
              )}
              <div className="d-flex justify-content-between mb-2">
                <span>¿Es Reparable?</span>
                <span>{<ShowBool value={asset.repairable} />}</span>
              </div>
              <div className="mb-2">
                <div>Palabras claves</div>
                {asset.comments ? (
                  <span className={`d-flex justify-content-start small`}>
                    <code>{asset.comments.join(', ')}</code>
                  </span>
                ) : (
                  <em className="text-muted">No especificadas.</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Identificador 1</div>
                {asset.factorySerialNumber1 ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset.factorySerialNumber1}</code>
                  </span>
                ) : (
                  <em className="small text-muted">Sin identificador</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Identificador 2</div>
                {asset.factorySerialNumber2 ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset.factorySerialNumber2}</code>
                  </span>
                ) : (
                  <em className="small text-muted">Sin identificador</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Serial</div>
                {asset.serial ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset.serial}</code>
                  </span>
                ) : (
                  <em className="small text-muted">Sin serial</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Marca</div>
                {asset?.item.brand ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset?.item.brand}</code>
                  </span>
                ) : (
                  <em className="small text-muted">Sin serial</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Modelo</div>
                {asset?.item.model ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset?.item.model}</code>
                  </span>
                ) : (
                  <em className="small text-muted">Sin serial</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Rótulo</div>
                {asset.mark ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset.mark}</code>
                  </span>
                ) : (
                  <em className="small text-muted">Sin rótulo</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Criticidad</div>
                {asset.criticality ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset.criticality}</code>
                  </span>
                ) : (
                  <em className="small text-muted">Indefinida</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Precio estimado</div>
                {asset.price ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>
                      {asset.price} {asset.currency}
                    </code>
                  </span>
                ) : (
                  <em className="small text-muted">No especificado.</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Peso</div>
                {asset.weight ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset.weight} g</code>
                  </span>
                ) : (
                  <em className="small text-muted">No especificado.</em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Geocerca actual</div>
                {location ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{location.name}</code>
                  </span>
                ) : (
                  <em className="small text-muted text-right">
                    Geocerca no encontrada,
                    <NavLink to={`/building/assets/${asset.barcode1}/gps`}>
                      &nbsp; consultar la última ubicación
                    </NavLink>
                  </em>
                )}
              </div>
              <div className="d-flex justify-content-between mb-2">
                <div>Dueño</div>
                {asset.owner ? (
                  <span className={`d-flex small ml-2 text-right`}>
                    <code>{asset.owner}</code>
                  </span>
                ) : (
                  <em className="small text-muted">No especificado.</em>
                )}
              </div>
              <div className="mb-2">
                <div>Descripción</div>
                {asset.description ? (
                  <span>
                    <code>{asset.description}</code>
                  </span>
                ) : (
                  <em className="small text-muted">No especificado.</em>
                )}
              </div>

              {validityPeriods && (
                <div>
                  <h5 className="mt-4 mb-4">Vigencias</h5>
                  <div>
                    {validityPeriods &&
                      validityPeriods.map((period, index) => (
                        <ValidityPeriodRow
                          index={index}
                          readOnly={true}
                          key={period.id || period.key}
                          validityPeriod={period}
                          assetBarcode={asset.barcode1}
                          onDelete={console.log}
                          toggleEditing={console.log}
                          editing={false}
                        />
                      ))}
                  </div>
                  <div>
                    {validityPeriods && validityPeriods.length >= 5 && (
                      <div className="mt-1 text-center">
                        <Button
                          role="button"
                          color="primary"
                          tag={NavLink}
                          to={`/building/assets/${asset.barcode1}/validity_periods`}
                        >
                          <FontAwesome name="search" /> Ir a Vegencias
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>
            <Col xs={5}>
              <div className="mb-2">
                <Button
                  role="button"
                  block
                  size="sm"
                  tag={NavLink}
                  color="primary"
                  to={`/building/assets?q=${simpleFilterQuery({
                    'items[{}]': [{ label: asset.item.name, value: asset.item.barcode1 }],
                  })}`}
                  className="text-left mb-2"
                >
                  <FontAwesome name="wrench" /> Ver activos similares
                </Button>
                <div>
                  <h5>Tags</h5>
                </div>
                {tags.length > 0 ? (
                  tags.map((tag, index) => (
                    <div className="text-overflow small mt-2" key={index}>
                      <div className="text-muted mb-1">
                        <NavLink
                          to={`/building/tags?q=${simpleFilterQuery({
                            'assets[{}]': [{ label: asset.barcode1, value: asset.id }],
                          })}`}
                        >
                          <FontAwesome name="caret-right" className="mr-1" />
                          {tag.identifier}
                          <FontAwesome
                            name={tag.active ? 'check-circle' : 'times-circle'}
                            className={tag.active ? 'text-success ml-1' : 'text-danger ml-1'}
                          />
                        </NavLink>
                      </div>

                      <MetadataDisplay tagId={tag.id} metadata={tag.metadata} />
                      {tag.tagType && <div className="text-muted">Tipo: {tag.tagType.description}</div>}
                    </div>
                  ))
                ) : (
                  <em className="text-muted mt-2">Sin tags asociados</em>
                )}
                {asset?.item?.telemetric && (
                  <div style={{ width: '70%' }}>
                    <hr />
                    <h5>
                      <FontAwesome name="tachometer-alt" /> Telemetría:
                    </h5>
                    <div>
                      <div>
                        Nivel de Batería:{' '}
                        {asset.telemetry.lastBatteryLevel ? `${asset.telemetry.lastBatteryLevel.level} %` : '-'}
                        <div className="float-right">
                          {asset.telemetry.lastBatteryLevel &&
                            highIsBetter(10, 40, asset.telemetry.lastBatteryLevel.level)}
                        </div>
                      </div>
                      <div>
                        Peak Fuerza G:{' '}
                        {asset.telemetry.lastGForceEvent ? `${asset.telemetry.lastGForceEvent.peak} G` : '-'}
                        <div className="float-right">
                          {asset.telemetry.lastGForceEvent &&
                            lowIsBetter(gForceEventWarning, gForceEventHarmful, asset.telemetry.lastGForceEvent.peak)}
                        </div>
                      </div>
                      <div>
                        Temperatura:{' '}
                        {asset.telemetry.lastTemperatureLevel
                          ? `${asset.telemetry.lastTemperatureLevel.temperature} ºC`
                          : '-'}
                        <div className="float-right">
                          {asset.telemetry.lastTemperatureLevel &&
                            lowIsBetter(
                              temperatureLevelWarning,
                              temperatureLevelHarmful,
                              asset.telemetry.lastTemperatureLevel.temperature,
                            )}
                        </div>
                      </div>
                      <div>
                        Horas Uso:{' '}
                        {asset.telemetry.lastWorkTrip
                          ? `${(asset.telemetry.lastWorkTrip.elapsed / 3600000).toFixed(1)} horas`
                          : '-'}
                        <div className="float-right">
                          {asset.telemetry.lastWorkTrip &&
                            lowIsBetter(
                              workTripWarning,
                              workTripHarmful,
                              (asset.telemetry.lastWorkTrip.elapsed / 3600000).toFixed(1),
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-4">
                  <hr />
                  <div>
                    <h5>Fechas MRO</h5>
                  </div>
                  <div>Mantenciones</div>
                  {lastAssetMaintenances ? (
                    <div>
                      <div className={`d-flex justify-content-between mb-2`}>
                        <div className="small">Última:</div>
                        <div className={`d-flex small ml-2 text-right`}>
                          <code>{fromIso8601(lastAssetMaintenances?.updatedAt)}</code>
                        </div>
                      </div>
                      {assetMaintenancesCount && (
                        <div className={`d-flex justify-content-between mb-2`}>
                          <div className="small">Completadas:</div>
                          <div
                            className={`d-flex small ml-2 text-right`}
                            data-toggle="tooltip"
                            title="Últimos 12 meses"
                          >
                            <code>{assetMaintenancesCount}</code>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <em className="small text-muted">No hay registro de mantenciones en los últimos 12 meses</em>
                    </div>
                  )}

                  <div>Reparaciones</div>
                  {lastAssetRepairs ? (
                    <div>
                      <div className={`d-flex justify-content-between mb-2`}>
                        <div className="small">Última:</div>
                        <div className={`d-flex small ml-2 text-right`}>
                          <code>{fromIso8601(lastAssetRepairs?.updatedAt)}</code>
                        </div>
                      </div>
                      {assetRepairsCount && (
                        <div className={`d-flex justify-content-between mb-2`}>
                          <div className="small">Completadas:</div>
                          <div
                            className={`d-flex small ml-2 text-right`}
                            data-toggle="tooltip"
                            title="Últimos 12 meses"
                          >
                            <code>{assetRepairsCount}</code>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <em className="small text-muted">No hay registro de reparaciones en los últimos 12 meses</em>
                    </div>
                  )}

                  <div>Evaluaciones</div>
                  {lastAssetEvaluations ? (
                    <div>
                      <div className={`d-flex justify-content-between mb-2`}>
                        <div className="small">Última:</div>
                        <div className={`d-flex small ml-2 text-right`}>
                          <code>{fromIso8601(lastAssetEvaluations?.updatedAt)}</code>
                        </div>
                      </div>
                      {assetEvaluationsCount && (
                        <div className={`d-flex justify-content-between mb-2`}>
                          <div className="small">Completadas:</div>
                          <div
                            className={`d-flex small ml-2 text-right`}
                            data-toggle="tooltip"
                            title="Últimos 12 meses"
                          >
                            <code>{assetEvaluationsCount}</code>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <em className="small text-muted">No hay registro de evaluaciones en los últimos 12 meses</em>
                    </div>
                  )}

                  <div>Preservaciones</div>
                  {lastAssetPreservations ? (
                    <div>
                      <div className={`d-flex justify-content-between mb-2`}>
                        <div className="small">Última:</div>
                        <div className={`d-flex small ml-2 text-right`}>
                          <code>{fromIso8601(lastAssetPreservations?.updatedAt)}</code>
                        </div>
                      </div>
                      {assetPreservationsCount && (
                        <div className={`d-flex justify-content-between mb-2`}>
                          <div className="small">Completadas:</div>
                          <div
                            className={`d-flex small ml-2 text-right`}
                            data-toggle="tooltip"
                            title="Últimos 12 meses"
                          >
                            <code>{assetPreservationsCount}</code>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <em className="small text-muted">No hay registro de preservaciones en los últimos 12 meses</em>
                    </div>
                  )}

                  <div>Certificaciones</div>
                  {lastAssetCertifications ? (
                    <div>
                      <div className={`d-flex justify-content-between mb-2`}>
                        <div className="small">Última:</div>
                        <div className={`d-flex small ml-2 text-right`}>
                          <code>{fromIso8601(lastAssetCertifications?.updatedAt)}</code>
                        </div>
                      </div>
                      {assetCertificationsCount && (
                        <div className={`d-flex justify-content-between mb-2`}>
                          <div className="small">Completadas:</div>
                          <div
                            className={`d-flex small ml-2 text-right`}
                            data-toggle="tooltip"
                            title="Últimos 12 meses"
                          >
                            <code>{assetCertificationsCount}</code>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <em className="small text-muted">No hay registro de evaluaciones en los últimos 12 meses</em>
                    </div>
                  )}

                  <div>Garantías</div>
                  {lastAssetAssurances ? (
                    <div>
                      <div className={`d-flex justify-content-between mb-2`}>
                        <div className="small">Última:</div>
                        <div className={`d-flex small ml-2 text-right`}>
                          <code>{fromIso8601(lastAssetAssurances?.updatedAt)}</code>
                        </div>
                      </div>
                      {assetAssurancesCount && (
                        <div className={`d-flex justify-content-between mb-2`}>
                          <div className="small">Completadas:</div>
                          <div
                            className={`d-flex small ml-2 text-right`}
                            data-toggle="tooltip"
                            title="Últimos 12 meses"
                          >
                            <code>{assetAssurancesCount}</code>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <em className="small text-muted">No hay registro de garantías en los últimos 12 meses</em>
                    </div>
                  )}

                  <div>Dar de Baja</div>
                  {lastAssetDiscards ? (
                    <div>
                      <div className={`d-flex justify-content-between mb-2`}>
                        <div className="small">Última:</div>
                        <div className={`d-flex small ml-2 text-right`}>
                          <code>{fromIso8601(lastAssetDiscards?.updatedAt)}</code>
                        </div>
                      </div>
                      {assetDiscardsCount && (
                        <div className={`d-flex justify-content-between mb-2`}>
                          <div className="small">Completadas:</div>
                          <div
                            className={`d-flex small ml-2 text-right`}
                            data-toggle="tooltip"
                            title="Últimos 12 meses"
                          >
                            <code>{assetDiscardsCount}</code>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="mb-2">
                      <em className="small text-muted">No hay registro de Bajas en los últimos 12 meses</em>
                    </div>
                  )}
                </div>
                <hr />
                <div>
                  <h5>Última auditoría</h5>
                </div>
                {asset?.lastInventoryChecker ? (
                  <div className="mt-4">
                    <div>
                      Auditoría: <code>{asset?.lastInventoryChecker?.requestNumber}</code>{' '}
                      <Button
                        role="button"
                        className="mb-1 mr-1"
                        color="info"
                        id={`MoreInfoButton`}
                        size="sm"
                        onClick={toggle}
                      >
                        <FontAwesome name="search" />
                      </Button>
                      <InventoryCheckerDetail
                        inventoryChecker={asset?.lastInventoryChecker}
                        isOpen={isOpen}
                        toggle={toggle}
                        states={{
                          completed: { name: 'Completado', color: 'success' },
                          processing: { name: 'Procesando', color: 'warning' },
                          canceled: { name: 'Procesando', color: 'cancelado' },
                        }}
                      />
                      <UncontrolledTooltip placement="auto" target={`MoreInfoButton`}>
                        Más Información
                      </UncontrolledTooltip>
                    </div>
                    <div>
                      Fecha: <code>{fromIso8601(asset?.lastInventoryChecker?.updatedAt)}</code>
                    </div>
                    <div>
                      Auditor: <code>{asset?.lastInventoryChecker?.user?.name}</code>
                    </div>
                  </div>
                ) : (
                  <div>
                    <em className="small text-muted">No hay registro de auditorías completadas</em>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col className="mb-4" sm={12} md={4}>
          {asset.area && (
            <InfoDisplay area={asset.area} picture={asset.defaultPicture.file || asset.item.defaultPicture.file} />
          )}
        </Col>
      </Row>
      <ShowForRoles excluded={['guest']}>
        <Row>
          <div className="col-12 d-flex justify-content-end">
            <Button
              role="button"
              size="sm"
              color="success"
              tag={NavLink}
              to={`/mro/new?of=${asset.barcode1}`}
              className="mb-3 mr-2"
              disabled={!(asset.repairable && asset.availability === 'available')}
            >
              <FontAwesome name="plus" /> Iniciar Proceso MRO
            </Button>
            <Button
              role="button"
              size="sm"
              color="success"
              tag={NavLink}
              to={`/organization/tasks/new?of=${asset.barcode1}`}
              className="mb-3 mr-2"
              disabled={false}
            >
              <FontAwesome name="plus" /> Iniciar Solicitud de Tarea
            </Button>
          </div>
        </Row>
      </ShowForRoles>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="mb-2">Últimos movimientos</h5>
        <ShowForRoles excluded={['guest']}>
          <div className="d-flex justify-content-between align-items-center">
            <Button
              role="button"
              size="sm"
              color="success"
              tag={NavLink}
              to={`/movements/new?of=${asset.barcode1}`}
              className="mb-3 mr-2"
              disabled={shouldBlockMovements(asset)}
            >
              <FontAwesome name="plus" /> Ingresar movimiento
            </Button>

            {asset.state === 'stored' && (
              <Button
                role="button"
                size="sm"
                color="success"
                tag={NavLink}
                to={`/building/transfer-asset?of=${asset.barcode1}`}
                className="mb-3"
              >
                <FontAwesome name="exchange-alt" /> Transferir Activo
              </Button>
            )}
          </div>
        </ShowForRoles>
      </div>

      <AssetMovements asset={asset} />
      <hr />
      <div>
        <h5 className="mt-4 mb-4">Últimos eventos</h5>
        <div>{events && <EventsResults events={events} />}</div>
        <div>
          {events && events.length >= 5 && (
            <div className="mt-1 text-center">
              <Button
                role="button"
                color="primary"
                tag={NavLink}
                to={`/building/events?q=${encodeURIComponent(btoa(JSON.stringify({ assets: asset.id })))}`}
              >
                <FontAwesome name="search" /> Ver todos
              </Button>
            </div>
          )}
        </div>
      </div>

      {asset && asset.manualAttachment && showManualPdf && (
        <div style={{ height: '60em' }}>
          <hr />
          <h5 className="mt-4 mb-4">Manual</h5>
          <Row>
            <Col sm={{ size: 6, order: 2, offset: 3 }}>
              <Document
                width="100%"
                onLoadSuccess={(pdfInfo) => setPdfInfo(pdfInfo._pdfInfo)}
                file={{ data: base64ToArrayBuffer(base64) }}
              >
                <Page pageNumber={currentPage} />
              </Document>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col sm={{ size: 6, order: 2, offset: 5 }}>
              <div className="buttons has-addons">
                <button onClick={previousPage} className="button">
                  {'<'}
                </button>
                <button>
                  {currentPage} de {totalPages}
                </button>
                <button onClick={nextPage} className="button">
                  {'>'}
                </button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};
