import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getInventoryCheckers = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checkers${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getInventoryChecker = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checkers/${id}`,
      method: 'GET',
    }),
  );

export const createInventoryChecker = ({ ...inventoryChecker }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checkers`,
      method: 'POST',
      body: {
        inventoryChecker,
      },
    }),
  );

export const updateInventoryChecker = ({ ...inventoryChecker }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checkers/${inventoryChecker.id}`,
      method: 'PUT',
      body: {
        inventoryChecker,
      },
    }),
  );
