import AsyncSelect from 'react-select/async';
import { getTags } from '@core/building/tags';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { connect } from '@store';
import { compose } from 'redux';

class TagsFilterModalPresentation extends SimpleFilterModal {
  findTags = async (inputValue) => {
    const { body, status } = await getTags({
      identifier: inputValue,
      serializer: 'simple',
      tag_types: this.state.tag_types.value,
    });

    if (status === 200) {
      return body.map((tag) => ({
        label: tag.identifier,
        value: tag.identifier,
      }));
    }

    return [];
  };

  identifierName() {
    const identifiers = { 1: 'beacons', 2: 'epc', 4: 'imei', 6: 'eui' };
    return identifiers[this.state.tag_types.value];
  }

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label>Tipo de tag</Label>
              <Select
                name="tag_types"
                value={this.state['tag_types']}
                placeholder="Todos"
                isClearable
                options={this.props.tagTypes}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="active">Estado</Label>
              <Select
                name="active"
                value={this.state['active']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Activo', value: true },
                  { label: 'Inactivo', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        {this.state.tag_types && (
          <Row form>
            <Col md={8}>
              <FormGroup>
                <Label>{this.identifierName().toUpperCase()}</Label>
                <AsyncSelect
                  name="identifier"
                  iidentifiers
                  isSearchable
                  isClearable
                  value={this.state['identifier']}
                  placeholder="Buscar..."
                  loadOptions={this.findTags}
                  noOptionsMessage={() => NO_OPTIONS}
                  loadingMessage={() => LOADING_MESSAGE}
                  onChange={this.onChange}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </Form>
    );
  }
}

export const TagsFilterModal = compose(
  connect(({ appData: { tag_types: tagTypes } }) => {
    return {
      tagTypes: tagTypes.map((tt) => ({ value: tt.id, label: tt.description })),
    };
  }),
)(TagsFilterModalPresentation);
