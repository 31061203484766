import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton, Spinner } from '@shared';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'name' }),
  presence({ of: 'kind' }),
  presence({ of: 'code' }),
  presence({ of: 'active' }),
);

const PartnerFormPresentation = ({ error, handleSubmit, invalid, loading, pristine, submitting }) => (
  <Form noValidate error={error} onSubmit={handleSubmit}>
    <h5 className="mb-3">Información básica</h5>
    <Row>
      <Col md={6}>
        <Field label="Nombre" name="name" required />
      </Col>
      <Col md={3}>
        <Field
          label="Tipo de socio"
          name="kind"
          type="select"
          options={{ '': 'Seleccione...', client: 'Cliente', supplier: 'Proveedor' }}
        />
      </Col>
      <Col md={3}>
        <Field label="Código" name="code" required />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name="active"
          type="checkbox"
          label="Activo?"
          help="Indica que el la relación con el socio de negocio se encuentra vigente"
        />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <Field
          name="description"
          type="textarea"
          rows="6"
          label="Descripción"
          help="Opcional. Descripción complementaria sobre el grupo"
        />
      </Col>
    </Row>

    <hr />

    <div className="text-center">
      <ProcessingButton
        type="submit"
        role="button"
        color="primary"
        outline
        disabled={pristine || invalid}
        processing={submitting}
        width="10em"
      >
        {loading ? <Spinner /> : 'Guardar'}
      </ProcessingButton>
    </div>
  </Form>
);

export const PartnerForm = reduxForm({
  form: 'partnerForm',
  enableReinitialize: true,
  validate,
  initialValues: {
    name: null,
    kind: null,
    code: null,
    active: true,
    description: null,
  },
})(PartnerFormPresentation);
