import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const getAssetAssurances = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_assurances${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAssetAssurance = (assetAssuranceId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_assurances/${assetAssuranceId}`,
      method: 'GET',
    }),
  );

export const getAssetAssuranceStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_assurances/stats`,
      method: 'GET',
    }),
  );
