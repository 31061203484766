import { fromIso8601 } from '@core/utils/dates';
import { shortName } from '@core/utils/names';
import { FontAwesome } from '@shared';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Card } from 'reactstrap';
import { connect } from '../../../../@store';
import { AssetDisplay } from './AssetDisplay';
import { AttachmentDisplay } from './AttachmentDisplay';
import './TaskInfo.scss';

const TaskController = ({ task, onComplete, onCompleteSubtask, onStar }) => (
  <div className="task-controllers">
    <div className="ctrl">
      <FontAwesome
        id={`t-c-${task.id}`}
        className="pointer"
        onClick={() => (task.taskId ? onCompleteSubtask(task) : onComplete(task))}
        name={task.completedAt ? 'check-circle' : ['far', 'circle']}
      />
    </div>
    <div className="content">
      <div className="content-title">{task.title}</div>
    </div>
    {onStar && (
      <div className="ctrl">
        <FontAwesome
          id={`t-s-${task.id}`}
          className="pointer"
          onClick={() => onStar(task)}
          name={task.starred ? 'star' : ['far', 'star']}
        />
      </div>
    )}
  </div>
);

class TaskInfoPresentation extends Component {
  state = {
    expanded: 'members',
  };

  toggleSection = (section) => {
    this.setState({
      expanded: this.state.expanded === section ? undefined : section,
    });
  };

  render() {
    const { task, onClose, user, onComplete, onCompleteSubtask, onStar, onDelete, className } = this.props;
    const { expanded } = this.state;

    const { members = [], asset = null, attachments = [], subtasks = [] } = task;

    return (
      <Card className={`task-sidebar ${className}`}>
        <section className="task-sidebar-header">
          <TaskController task={task} onComplete={onComplete} onStar={onStar} />
          <div className="subtasks">
            {subtasks.map((t, index) => (
              <TaskController key={`subtasks-${index}`} task={t} onCompleteSubtask={onCompleteSubtask} />
            ))}
          </div>
        </section>

        <section className="description">
          <div className="strike">
            <span>Descripción</span>
          </div>

          <p>{task.description}</p>
        </section>

        {asset && (
          <section>
            <div className="strike pointer" onClick={() => this.toggleSection('assets')}>
              <span>
                <FontAwesome name={expanded === 'assets' ? 'chevron-down' : 'chevron-right'} /> Activo Involucrado
              </span>
            </div>

            <div className={`collapsable ${expanded === 'assets'}`}>
              <AssetDisplay asset={asset} />
            </div>
          </section>
        )}

        {attachments.length > 0 && (
          <section className="attachments">
            <div className="strike pointer" onClick={() => this.toggleSection('attachments')}>
              <span>
                <FontAwesome name={expanded === 'attachments' ? 'chevron-down' : 'chevron-right'} /> Archivos (
                {attachments.length})
              </span>
            </div>

            <div className={`collapsable ${expanded === 'attachments'}`}>
              {attachments.map((attachment, index) => (
                <AttachmentDisplay key={`attachment-${index}`} attachment={attachment} />
              ))}
            </div>
          </section>
        )}

        {members.length > 0 && (
          <section>
            <div className="strike pointer" onClick={() => this.toggleSection('members')}>
              <span>
                <FontAwesome name={expanded === 'members' ? 'chevron-down' : 'chevron-right'} /> Responsables (
                {members.length})
              </span>
            </div>

            <div className={`collapsable text-muted ${expanded === 'members'}`}>
              {members.map((member, index) => (
                <NavLink key={`member-${index}`} to={`/building/users/${member.id}`}>
                  {shortName(member) + (index === members.length - 1 ? '' : ', ')}
                </NavLink>
              ))}
            </div>
          </section>
        )}

        <hr />

        <section className="sidebar-footer">
          <div className="ctrl">
            {onClose && <FontAwesome name="chevron-right" className="pointer" onClick={() => onClose(undefined)} />}
          </div>
          <div className="content">
            <span>Creada: {fromIso8601(task.createdAt)}</span>
          </div>
          <div className="ctrl danger">
            {user.id === task.creatorId && onDelete && (
              <FontAwesome name="trash" className="pointer" onClick={() => onDelete(task)} />
            )}
          </div>
        </section>
      </Card>
    );
  }
}

export const TaskInfo = connect((state) => ({ user: state.session.user }))(TaskInfoPresentation);
