import React from 'react';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';

import { CreateMovement } from './CreateMovement';
import { MovementsIndex } from './MovementsIndex';
import { MovementPage } from './MovementPage';
import { Desk } from './desk';
import { Assembly } from './Assembly';

export const MovementsRoutes = ({ match }) => (
  <Switch>
    <ProtectedRoute
      exact
      path={`${match.url}/new`}
      component={CreateMovement}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant']}
    />

    <ProtectedRoute
      exact
      path={`${match.url}/`}
      component={MovementsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />

    <ProtectedRoute
      exact
      path={`${match.url}/desk`}
      component={Desk}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant']}
    />

    <ProtectedRoute
      exact
      path={`${match.url}/assembly`}
      component={Assembly}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'onboarding']}
    />

    <ProtectedRoute
      exact
      path={`${match.url}/:id`}
      component={MovementPage}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />
  </Switch>
);
