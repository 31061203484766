import { getAssets } from '@core/building/assets';
import { getLocations } from '@core/organization/locations';
import { darken } from '@core/utils/color';
import { FontAwesome, Loading } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SANTIAGO } from '@shared/mapbox';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { get, sortBy } from 'lodash';
import React from 'react';
import { render } from 'react-dom';
import { connect } from 'react-redux';
import { List } from 'react-virtualized';
import 'react-virtualized/styles.css';
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { markerBluetooth, markerSatellite, markerSatelliteDish } from '../@shared/mapbox';
import { ReadoutFeature } from './components/map-view/readout';
import { MapFilterModal } from './components/MapFilterModal';
import './readouts-map.scss';
import { getDevices } from '@core/building/devices';
import { DeviceFeature } from './components/map-view/device';

const scaledSize = new window.google.maps.Size(32, 32);

const icons = {
  imei: {
    url: markerSatellite,
    scaledSize,
  },
  eui: {
    url: markerSatelliteDish,
    scaledSize,
  },
  beacon: {
    url: markerBluetooth,
    scaledSize,
  },
  device: {
    url: markerBluetooth,
    scaledSize,
  },
};

class ReadoutsMapPresentation extends SimpleFilter {
  map = undefined;
  infoWindow = undefined;

  constructor(props) {
    super(props);
    this.state = {
      ...super.state,
      // items: undefined,
      center: SANTIAGO,
      zoom: 13,
      geofences: [],
      devices: [],
      loadingDevices: false,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.setState({ loadingDevices: true });
    this.initMap();
    this.getDevices();
  }

  assetToFeature = (asset) => {
    const tags = (asset.tags || [])
      .filter((tag) => ['IMEI', 'EUI', 'BEACON'].includes(tag.identifier.split(':')[0]))
      .map((t) => ({
        id: t.id,
        identifier: t.identifier,
        battery: Number(get(t, 'metadata.battery.level', 0)).toFixed(0),
      }));

    const battery = tags.reduce((accumulator, current) => accumulator + Number(current.battery), 0) / tags.length;

    const readout = asset.lastPosition;

    const iconType = (readout.metadata.origin || 'IMEI:').split(':')[0].toLowerCase();

    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [+Number(readout.metadata.lng).toFixed(6), +Number(readout.metadata.lat).toFixed(6)],
      },
      properties: {
        type: 'asset',
        id: asset.id,
        name: asset.item.name,
        barcode1: asset.barcode1,
        default_picture: asset.defaultPicture,
        battery: battery.toFixed(0),
        tags: sortBy(tags, (t) => t.identifier),
        rid: readout.id,
        address: readout.metadata.address,
        read_at: readout.readAt,
        origin: readout.metadata.origin,
        icon: icons[iconType],
      },
    };
  };

  searchFunction = async (query) => {
    const response = await getAssets({
      ...query,
      paginate: false,
    });

    if (response.status === 200) {
      const features = [];

      response.body.forEach((asset) => {
        const readout = asset.lastPosition;

        if (!readout) {
          return;
        }

        const feature = this.assetToFeature(asset);

        features.push(feature);
      });

      response.body = features;
    }

    return response;
  };

  getDevices = async () => {
    const { body, status } = await getDevices({ serializer: 'show', paginate: false, has_location: true });

    if (status === 200) {
      const devices = body.map((device) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [+Number(device.lng).toFixed(6), +Number(device.lat).toFixed(6)],
          },
          properties: {
            type: 'device',
            id: device.id,
            name: device.name,
            barcode1: device.barcode1,
            default_picture: device.defaultPicture,
            icon: icons['device'],
            active: device.active,
          },
        };
      });

      this.setState({ devices, loadingDevices: false });
    }
  };

  initMap = () => {
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: this.state.zoom,
      center: this.state.center,
      scaleControl: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid'],
        position: window.google.maps.ControlPosition.TOP_RIGHT,
      },
    });

    this.map.setMapTypeId('hybrid');

    this.map.data.setStyle((feature) => {
      var color = feature.getProperty('color') || '#008888';

      return {
        fillColor: color,
        fillOpacity: 0.5,
        strokeWeight: 1,
        strokeColor: darken(color, 20),
        icon: feature.getProperty('icon'),
      };
    });

    this.infoWindow = new window.google.maps.InfoWindow({
      pixelOffset: new window.google.maps.Size(0, -20),
    });
    this.setupMapEvents();
    this.fetchGeofences();
  };

  hookPostResponseHandler = () => {
    if (this.infoWindow) {
      this.infoWindow.close();
    }

    this.map.data.forEach((feature) => {
      this.map.data.remove(feature);
    });

    this.map.data.addGeoJson({ type: 'FeatureCollection', features: this.state.items });
    this.map.data.addGeoJson({ type: 'FeatureCollection', features: this.state.geofences });
  };

  setupMapEvents = () => {
    const moveCenter = () => {
      const center = this.map.getCenter();
      this.setState({ center: { lng: center.lng(), lat: center.lat() } });
    };
    this.map.addListener('drag', moveCenter);
    this.map.addListener('center_changed', moveCenter);

    this.map.addListener('zoom_changed', () => this.setState({ zoom: this.map.getZoom() }));

    this.map.data.addListener('mouseover', (event) => {
      const feature = event.feature;
      if (feature.getProperty('type') === 'geofence') {
        this.map.data.revertStyle();
        this.map.data.overrideStyle(event.feature, { strokeWeight: 2, fillOpacity: 0.7 });
      }
    });

    this.map.data.addListener('mouseout', (event) => {
      this.map.data.revertStyle();
    });

    this.map.data.addListener('click', (event) => {
      const feature = event.feature;
      let html = '';
      if (feature.getProperty('type') === 'geofence') {
        html = `<div style="margin: 7px 15px;">${event.feature.getProperty('name')}</div>`;
      } else if (feature.getProperty('type') === 'asset') {
        html = document.createElement('div');
        feature.toGeoJson((json) => {
          render(<ReadoutFeature feature={json} link={false} tooltip={false} />, html);
        });
      }
      this.infoWindow.setPosition(event.latLng);
      this.infoWindow.setContent(html);
      this.infoWindow.open(this.map);
    });
  };

  fetchGeofences = async () => {
    const { status, body } = await getLocations(this.props.currentOrganizationId, {
      paginate: false,
    });
    if (status === 200) {
      this.setState(
        {
          geofences: body.map((geofence) => ({
            id: `gf-${geofence.id}`,
            ...geofence.feature,
            properties: {
              ...geofence.feature.properties,
              type: 'geofence',
              name: geofence.name,
            },
          })),
        },
        () => this.map.data.addGeoJson({ type: 'FeatureCollection', features: this.state.geofences }),
      );
    }
  };

  onSelectItem = (feature, resource = 'asset') => {
    const {
      properties,
      geometry: { coordinates },
    } = feature;
    const latLng = { lat: Number(coordinates[1]), lng: Number(coordinates[0]) };
    this.setState({
      selected: properties.id,
      position: latLng,
    });
    this.map.panTo(latLng);
    this.map.setZoom(18);

    let html = document.createElement('div');
    render(
      resource === 'asset' ? (
        <ReadoutFeature feature={feature} link={false} tooltip={false} />
      ) : (
        <DeviceFeature feature={feature} link={false} tooltip={false} />
      ),
      html,
    );

    this.infoWindow.setPosition(latLng);
    this.infoWindow.setContent(html);
    this.infoWindow.open(this.map);
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style,
  }) => {
    const feature = this.state.items[index];

    return (
      <div key={index} style={{ ...style, paddingLeft: 2, paddingRight: 2 }}>
        <ReadoutFeature
          feature={feature}
          active={feature.properties.id === this.state.selected}
          link={true}
          onClick={() => this.onSelectItem(feature)}
        />
      </div>
    );
  };

  rowDeviceRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style,
  }) => {
    const feature = this.state.devices[index];
    console.log(this.state.devices);
    return (
      <div key={index} style={{ ...style, paddingLeft: 2, paddingRight: 2 }}>
        <DeviceFeature
          feature={feature}
          active={feature.properties.id === this.state.selected}
          link={true}
          onClick={() => this.onSelectItem(feature, 'device')}
        />
      </div>
    );
  };

  render() {
    const { center, zoom, selected, modalOpen, filters, devices, loadingDevices, items: features } = this.state;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="map" className="text-primary mr-2" />
              Mapa
            </h4>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col xs="4">
            <div>
              <Card>
                <CardHeader className="group-header">Activos</CardHeader>
                <CardBody style={{ padding: '10px', height: '327px' }} className="readouts-content-list">
                  {features === undefined && <Loading />}
                  {features && features.length === 0 && (
                    <Alert color="primary" className="text-center">
                      No se encontraron activos con posiciones
                    </Alert>
                  )}
                  {features && features.length > 0 && (
                    <List
                      width={414}
                      height={300}
                      rowHeight={95}
                      rowCount={features.length}
                      rowRenderer={this.rowRenderer}
                      overscanRowCount={3}
                      data={selected}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
            <div className="pt-1">
              <Card>
                <CardHeader className="group-header">Dispositivos</CardHeader>
                <CardBody style={{ padding: '10px', height: '327px' }} className="readouts-content-list">
                  {loadingDevices && <Loading />}
                  {!loadingDevices && devices && devices.length === 0 && (
                    <Alert color="primary" className="text-center">
                      No se encontraron dispositivos con posiciones
                    </Alert>
                  )}
                  {devices && devices.length > 0 && (
                    <List
                      width={414}
                      height={300}
                      rowHeight={95}
                      rowCount={devices.length}
                      rowRenderer={this.rowDeviceRenderer}
                      overscanRowCount={3}
                      data={selected}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
          <Col>
            <div className="readouts-map">
              <div className="sidebar text-monospace">
                <div>{`Latitude: ${center.lat.toFixed(6)} Longitude: ${center.lng.toFixed(6)} Zoom: ${zoom}`}</div>
              </div>
              <div style={{ height: 751, width: '100%' }}>
                <div id="map" className="map-container" />
              </div>
            </div>
          </Col>
        </Row>
        <MapFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}

const mapStateToProps = ({ session }) => {
  let organizationId = undefined;
  let building = undefined;

  if (session.currentMembership && session.user) {
    const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

    if (membership) {
      building = membership.building;
      organizationId = building.organization.id;
    }
  }

  return {
    /* TODO: Reselect in the future */
    currentOrganizationId: organizationId,
    currentBuilding: building,
  };
};

export const ReadoutsMap = connect(mapStateToProps)(ReadoutsMapPresentation);
