import { getTags } from '@core/building/tags';
import { Spinner, FontAwesome, ShowForRoles } from '@shared';
import { TagsTable } from '@shared/tags';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';

const enhance = compose(
  withState('tags', 'setTags', undefined),
  lifecycle({
    async componentDidMount() {
      const { asset, setTags } = this.props;

      const { body, status } = await getTags({
        assets: asset.id,
      });

      if (status === 200) {
        setTags(body);
      }
    },
  }),
  branch(
    ({ tags }) => !tags,
    renderComponent(() => (
      <div className="mt-4 mb-4 text-info text-center h2">
        <Spinner />
      </div>
    )),
  ),
);

const AssetTagsPresentation = ({ asset, tags }) => (
  <div>
    <ShowForRoles excluded={['guest']}>
      <div className="float-right">
        <Button size="sm" outline tag={NavLink} color="success" to={`/building/tags/new?for=${asset.barcode1}`}>
          <FontAwesome name="plus" /> Nuevo tag
        </Button>
      </div>
    </ShowForRoles>
    <h4 className="mb-4">Tags asociados</h4>
    <TagsTable tags={tags} showAssetColumn={false} showState={true} />
  </div>
);

export const AssetTags = enhance(AssetTagsPresentation);
