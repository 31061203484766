import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { LocationRow } from './LocationRow';

export const LocationsResults = ({ pagination, locations, onRemove }) => {
  return (
    <div>
      {pagination && (
        <div className="small mb-4">
          <strong>{pagination.count}</strong>{' '}
          {pluralize(pagination.count, 'geocerca encontrada', 'geocercas encontradas')}. Mostrando{' '}
          <strong>{pagination.per}</strong> geocercas por página.
        </div>
      )}

      <Table>
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: '15%' }} />
        </colgroup>
        <thead>
          <tr>
            <th className="text-left">Nombre</th>
            <th className="text-left">Tipo</th>
            <th className="text-right" />
          </tr>
        </thead>
        <tbody>
          {locations.map((location, index) => (
            <LocationRow key={index} location={location} onRemove={onRemove} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default LocationsResults;
