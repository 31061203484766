import ChileanRUT from '@core/utils/chilean-rut';
import React, { Component } from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from 'reactstrap';

class IdentifierWidgetComponent extends Component {
  state = {
    displayedValue: '',
  };

  UNSAFE_componentWillMount() {
    this.parseInput(this.props.value);
  }

  // UNSAFE_componentWillReceiveProps() {
  //   this.parseInput(this.props.value)
  // }

  parseInput(input) {
    const regex = /[^0-9k]/gi;

    const filtered = input.replace(regex, '');

    this.setState({
      displayedValue: ChileanRUT.PartialFormat(filtered),
    });

    return filtered;
  }

  handleInput = (e) => {
    const filtered = this.parseInput(e.target.value);

    this.props.onChange && this.props.onChange(filtered);
  };

  render() {
    const { displayedValue } = this.state;
    const { inputProps } = this.props;

    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <Button disabled>RUT</Button>
        </InputGroupAddon>

        <Input
          type="text"
          placeholder="Sin puntos ni guiones"
          value={displayedValue}
          onChange={this.handleInput}
          {...inputProps}
        />
      </InputGroup>
    );
  }
}

export default IdentifierWidgetComponent;
