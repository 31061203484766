import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { FontAwesome, Spinner, ShowForRoles } from '@shared';
import { ItemDisplay } from '@shared/displays';

export const DeviceStockRow = ({ deviceStock, deleteStock, updateStock }) => {
  const [minimalStock, setMinimalStock] = useState(deviceStock?.minimalStock);
  const [optimalStock, setOptimalStock] = useState(deviceStock?.optimalStock);
  const [maxStock, setMaxStock] = useState(deviceStock?.maxStock);

  const [editStock, setEditStock] = useState(false);
  const [editing, setEditing] = useState(false);

  const setDeviceStock = async () => {
    setEditing(true);
    const payload = { item_id: deviceStock?.item?.id };
    if (Number(minimalStock) !== deviceStock?.minimalStock) payload['minimalStock'] = minimalStock;
    if (Number(optimalStock) !== deviceStock?.optimalStock) payload['optimalStock'] = optimalStock;
    if (Number(maxStock) !== deviceStock?.maxStock) payload['maxStock'] = maxStock;

    if (Object.keys(payload).length > 1) {
      await updateStock(deviceStock?.id, payload);
    }
    setEditStock(false);
    setEditing(false);
  };

  return (
    <tr>
      <td className="small">
        <ItemDisplay item={deviceStock.item} sku={true} />
      </td>
      <td className="align-middle small text-right">
        {editStock ? (
          <Input
            name="minimalStock"
            type="text"
            placeholder="Mínimo"
            onChange={(e) => setMinimalStock(e.target.value)}
            value={minimalStock || ''}
            size="sm"
            disabled={editing}
          />
        ) : (
          deviceStock.minimalStock
        )}
      </td>
      <td className="align-middle small text-right">
        {editStock ? (
          <Input
            name="optimalStock"
            type="text"
            placeholder="Óptimo"
            onChange={(e) => setOptimalStock(e.target.value)}
            value={optimalStock || ''}
            size="sm"
            disabled={editing}
          />
        ) : (
          deviceStock.optimalStock
        )}
      </td>
      <td className="align-middle small text-right">
        {editStock ? (
          <Input
            name="maxStock"
            type="text"
            placeholder="Máximo"
            onChange={(e) => setMaxStock(e.target.value)}
            value={maxStock || ''}
            size="sm"
            disabled={editing}
          />
        ) : (
          deviceStock.maxStock
        )}
      </td>
      {editing ? (
        <td className="align-middle text-center">
          <Spinner color={'primary'} />
        </td>
      ) : (
        <td className="align-middle small text-center">
          <ShowForRoles accepted={['organization_admin']}>
            {editStock ? (
              <Button role="button" size="sm" color={'success'} className="mr-1" onClick={setDeviceStock}>
                <FontAwesome name="check" />
              </Button>
            ) : (
              <Button role="button" size="sm" color={'primary'} className="mr-1" onClick={() => setEditStock(true)}>
                <FontAwesome name="pencil-alt" />
              </Button>
            )}

            <Button
              role="button"
              size="sm"
              color="danger"
              onClick={() => (editStock ? setEditStock(false) : deleteStock(deviceStock.id))}
            >
              <FontAwesome name="times" />
            </Button>
          </ShowForRoles>
        </td>
      )}
    </tr>
  );
};
