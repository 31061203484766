import { parseQuery } from '@shared/simple-filter/parseQuery';
import React, { Component } from 'react';
import { Button, ButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { calculateFinalFilters } from './simple-filter/SimpleFilter';
import { FontAwesome, Spinner } from '@shared';
import { saveAs } from 'file-saver';

class ExcelDownload extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      ...this.state,
      fetching: false,
      dropdownOpen: false,
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  fileDownload = async (fileFormat) => {
    this.setState({
      fetching: true,
    });

    const { downloadFile, file, filters } = this.props;
    const fileName = file + fileFormat;
    const actualFilters = calculateFinalFilters(filters);
    const res = await downloadFile(fileName, parseQuery(actualFilters));
    const blob = await res.blob();
    saveAs(blob, fileName);

    this.setState({
      fetching: false,
    });
  };

  render() {
    const { disabled, file, filters, downloadFile, csv, ...otherProps } = this.props;
    const { fetching } = this.state;

    return (
      <span>
        {csv && (
          <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <Button
              role="button"
              color="success"
              size="sm"
              onClick={() => this.fileDownload('.xlsx')}
              disabled={disabled || fetching}
              {...otherProps}
            >
              {fetching && (
                <span>
                  <Spinner /> Descargando
                </span>
              )}
              {!fetching && (
                <span>
                  <FontAwesome name="table" /> Descargar
                </span>
              )}
            </Button>
            <DropdownToggle color="success" caret size="sm" disabled={disabled || fetching} />
            <DropdownMenu>
              <DropdownItem onClick={() => this.fileDownload('.csv')}>Descargar CSV</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        )}
        {!csv && (
          <Button
            role="button"
            color="success"
            size="sm"
            onClick={() => this.fileDownload('.xlsx')}
            disabled={disabled || fetching}
            {...otherProps}
          >
            {fetching && (
              <span>
                <Spinner /> Descargando
              </span>
            )}
            {!fetching && (
              <span>
                <FontAwesome name="table" /> Descargar
              </span>
            )}
          </Button>
        )}
      </span>
    );
  }
}

ExcelDownload.defaultProps = {
  csv: true,
};

export default ExcelDownload;
