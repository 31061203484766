import { createMovementSet } from '@core/building/movement-sets';
import { FontAwesome, Spinner } from '@shared';
import { MovementsTable } from '@shared/movements';
import { connect, deskSessionGenerators, notificationsGenerators } from '@store';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { compose, withHandlers } from 'recompose';

const enhance = compose(
  connect(
    ({ deskSession: { currentClient, movements, savingState: state } }) => ({
      currentClient,
      movements,
      state,
    }),
    (dispatch) => ({
      setSaving: () => dispatch(deskSessionGenerators.setSavingState('saving')),
      onCancel: () => dispatch(deskSessionGenerators.setSavingState('waiting')),
      finishSession: () => dispatch(deskSessionGenerators.finish()),
      saveSuccess: () =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Sesión guardada',
            text: 'La sesión ha sido guardada exitosamente y cada movimiento ingresado al sistema.',
            color: 'success',
            duration: 10000,
          }),
        ),
      saveError: () =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Error',
            text: 'Se produjo un error al guardar la sesión. Por favor verifica los errores antes de continuar.',
            color: 'danger',
            duration: 10000,
          }),
        ),
      setSavingErrors: (errors) => dispatch(deskSessionGenerators.setSavingErrors(errors)),
    }),
  ),
  withHandlers({
    onConfirm: ({
      currentClient,
      finishSession,
      movements,
      onCancel,
      saveError,
      saveSuccess,
      setSaving,
      setSavingErrors,
    }) => async () => {
      setSavingErrors(undefined);
      setSaving();

      const data = {
        clientType: currentClient.type,
        clientId: currentClient.id,
        movements: movements.map((movement) => {
          // Client and generator won't be used.
          const { asset, item, client, generator, ...otherData } = movement;

          if (asset) {
            return {
              assetId: asset.id,
              ...otherData,
            };
          } else if (item) {
            return {
              itemId: item.id,
              ...otherData,
            };
          }

          return undefined;
        }),
      };

      const { body, status } = await createMovementSet(data);

      if (status === 201) {
        saveSuccess();
        finishSession();
      } else if (status === 422) {
        if (process.env.NODE_ENV === 'development') console.log(body);

        setSavingErrors(body.errors && body.errors.movements);
        saveError();
        onCancel();
      } else {
        saveError();
        onCancel();
      }
    },
  }),
);

const SavingModalPresentation = ({ movements, state, onCancel, onConfirm }) => (
  <Modal isOpen={state !== 'waiting'} keyboard={false} size="lg" backdrop="static">
    <ModalHeader>Guardando sesión</ModalHeader>
    <ModalBody>
      {state === 'confirming' && (
        <div>
          <p>
            Estás a punto de ingresar{' '}
            {movements.length === 1 ? (
              <span>el siguiente movimiento</span>
            ) : (
              <span>
                los siguientes <strong>{movements.length} movimientos</strong>
              </span>
            )}{' '}
            en el sistema:
          </p>
          <MovementsTable movements={movements} showInfo={false} showNumber={false} header={false} />
          <p>
            ¿Estás seguro de que deseas continuar? <strong>Esta acción no es reversible.</strong>
          </p>
        </div>
      )}

      {state === 'saving' && (
        <div className="mt-4 mb-4 text-center">
          <div className="text-primary h1 mb-3">
            <Spinner />
          </div>
          <div className="h3">Guardando...</div>
        </div>
      )}
    </ModalBody>

    {state !== 'saving' && (
      <ModalFooter>
        <Button role="button" color="secondary" onClick={onCancel}>
          Cancelar
        </Button>{' '}
        <Button role="button" color="primary" onClick={onConfirm}>
          <FontAwesome name="floppy" /> Guardar
        </Button>
      </ModalFooter>
    )}
  </Modal>
);

export const SavingModal = enhance(SavingModalPresentation);
