import AsyncSelect from 'react-select/async';
import ChileanRUT from '@core/utils/chilean-rut';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { getAssets } from '@core/organization/assets';
import { getUsers } from '@core/building/users';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import { getBuildings } from '@core/building/buildings';

export class TaskFilterModal extends SimpleFilterModal {
  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ item: { name }, barcode1, id: value }) => ({
        label: `${name} - ${barcode1}`,
        value,
      }));
    }

    return [];
  };

  findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return body.map(({ firstName, lastName, identifier, id: value }) => ({
        label: `${firstName} ${lastName} - ${
          identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
        }`,
        value: value,
      }));
    }
    return [];
  };

  findBuildings = async (inputValue) => {
    const { body, status } = await getBuildings({ search: inputValue });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="title">Título</Label>
              <Input
                type="text"
                size="md"
                name="title"
                onChange={(e) => this.onChange(e.target.value, { name: 'title' })}
                value={this.state['title']}
              />
            </FormGroup>
          </Col>
          <Col md={8}>
            <FormGroup>
              <Label for="asset">Activo</Label>
              <AsyncSelect
                name="assets[{}]"
                value={this.state['assets[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={(value, action) => {
                  this.onChange(value, action);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="complete">¿Completada?</Label>
              <Select
                name="complete"
                value={this.state['complete']}
                placeholder="Todas"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={8}>
            <FormGroup>
              <Label for="members[{}]">Responsables</Label>
              <AsyncSelect
                name="members[{}]"
                value={this.state['members[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={8}>
            <FormGroup>
              <Label for="asset">Instalaciones</Label>
              <AsyncSelect
                name="buildings[{}]"
                value={this.state['buildings[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findBuildings}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={(value, action) => {
                  this.onChange(value, action);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
