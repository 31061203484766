import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton, Spinner } from '@shared';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'name' }), presence({ of: 'code' }));

const CostAccountFormPresentation = ({ error, handleSubmit, invalid, loading, pristine, submitting }) => (
  <Form noValidate error={error} onSubmit={handleSubmit}>
    <h5 className="mb-3">Información básica</h5>
    <Row>
      <Col md={3}>
        <Field label="Código" name="code" required />
      </Col>
      <Col md={9}>
        <Field label="Nombre" name="name" required />
      </Col>
    </Row>
    <Row className="mt-3">
      <Col>
        <Field
          name="description"
          type="textarea"
          rows="6"
          label="Descripción"
          help="Opcional. Descripción complementaria sobre el centro de costo"
        />
      </Col>
    </Row>

    <hr />

    <div className="text-center">
      <ProcessingButton
        type="submit"
        role="button"
        color="primary"
        outline
        disabled={pristine || invalid}
        processing={submitting}
        width="10em"
      >
        {loading ? <Spinner /> : 'Guardar'}
      </ProcessingButton>
    </div>
  </Form>
);

export const CostAccountForm = reduxForm({
  form: 'costAccountForm',
  enableReinitialize: true,
  validate,
  initialValues: {
    name: null,
    code: null,
    description: null,
  },
})(CostAccountFormPresentation);
