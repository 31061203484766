import { apiFetch, handleAuth, withToken } from './http';

export const fullName = function fullName(user) {
  return `${user.firstName} ${user.lastName}`;
};

export const roleName = function roleName(role, roles) {
  return (roles && roles[role] && roles[role].title) || 'No disponible';
};

export const confirmEmail = ({ token }) =>
  apiFetch({
    endpoint: `/account/confirmation/${token}`,
    method: 'PUT',
  });

export const confirmationRedeliver = () =>
  withToken(apiFetch)({
    endpoint: `/account/confirmation`,
    method: 'POST',
  });

export const cancelConfirmation = ({ token }) =>
  apiFetch({
    endpoint: `/account/confirmation/${token}`,
    method: 'DELETE',
  });

export const clearPasswordRecovery = () =>
  withToken(apiFetch)({
    endpoint: `/account/recovery`,
    method: 'DELETE',
  });

export const checkInvitation = ({ token }) =>
  apiFetch({
    endpoint: `/account/invitations/${token}`,
    method: 'GET',
  });

export const confirmInvitation = ({ token, password, passwordConfirmation }) =>
  apiFetch({
    endpoint: `/account/invitations/${token}`,
    method: 'PUT',
    body: { password, passwordConfirmation },
  });

export const getAccount = () =>
  withToken(apiFetch)({
    endpoint: `/account`,
    method: 'GET',
  });

export const updateAccount = (data) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: '/account',
      method: 'PUT',
      body: { user: data },
    }),
  );

export const getUserSessions = () =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: '/user_sessions',
      method: 'GET',
    }),
  );

export const expireUserSession = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/user_sessions/${id}`,
      method: 'DELETE',
    }),
  );
