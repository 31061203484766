import { Barcode, PictureDisplay } from '@shared';
import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';

export const DeviceHeader = ({ device }) => (
  <Card className="mb-4">
    <CardBody>
      <Media className="align-items-center">
        <Media left className="mr-3">
          <PictureDisplay picture={device.defaultPicture} alt={device.name} />
        </Media>
        <Media body>
          <h4>{device.name}</h4>
          {device.deviceType && (
            <div className="small">
              Tipo: <span className="text-muted">{device.deviceType.description}</span>
            </div>
          )}
          <div className="mt-2 tiny">
            <code>{device.barcode1}</code>
          </div>
        </Media>
        <Media right>
          <Barcode code={device.barcode1} style={{ height: '5rem' }} />
        </Media>
      </Media>
    </CardBody>
  </Card>
);
