import { getLocation } from '@core/organization/locations';
import { fromIso8601, toDate } from '@core/utils/dates';
import { FontAwesome, PictureDisplay, ShowForRoles, simpleFilterQuery } from '@shared';
import { shouldBlockMovements } from '@shared/helpers';
import { connect } from '@store';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button, Media, UncontrolledTooltip } from 'reactstrap';

const isExpired = (movement) => {
  return Date.parse(movement.expectedAt) < Date.now();
};

const AssetRowPresentation = ({ asset, computedStates }) => {
  const [geofence, setGeofence] = useState(undefined);
  const computed = computedStates[asset.availability];

  const { lastMovement } = asset;
  let expectedAt = undefined;

  if (lastMovement && lastMovement.expectedAt && ['borrowed'].includes(asset.state)) {
    expectedAt = <span>Retorno: {toDate(lastMovement.expectedAt)}</span>;

    if (isExpired(lastMovement)) {
      expectedAt = <kbd className="danger">{expectedAt}</kbd>;
    }

    expectedAt = <div>{expectedAt}</div>;
  }

  useEffect(() => {
    const getGeofence = async () => {
      const { body } = await getLocation(asset.location);
      setGeofence(body.name ? body.name : null);
    };
    if (asset.location) {
      getGeofence();
    }
  }, [asset]);

  const stateCmp = (
    <div>
      <div className={`text-${computed?.color}`}>
        <strong> {computed?.name}</strong>
      </div>
      {expectedAt}
    </div>
  );

  const lastBatteryRecords = asset.tags
    ? asset.tags.filter((tag) => {
        return tag.metadata.battery && get(asset, 'lastPosition.metadata.origin') === tag.identifier;
      })
    : null;

  const readoutInfo = (
    <div>
      {asset.lastPosition && asset.lastPosition.readAt && (
        <div className="mb-1">
          <kbd>{`Último: ${fromIso8601(asset.lastPosition.readAt)}`}</kbd>
        </div>
      )}
      {asset.tags && lastBatteryRecords[0] && (
        <div>
          <div className="mb-1">
            {lastBatteryRecords[0].metadata.imei && <kbd>{`Imei: ${lastBatteryRecords[0].metadata.imei}`}</kbd>}
          </div>
          <div className="mb-1">
            {lastBatteryRecords[0].metadata.battery.level && (
              <kbd>{`Batería: ${Number(lastBatteryRecords[0].metadata.battery.level).toFixed(0)}%`}</kbd>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const { expired } = asset;

  return (
    <tr>
      <th scope="row" className="align-middle small">
        <Media className="align-items-center">
          <Media body className={`pb-1 status-border status-border-${asset.item.active ? 'active' : 'inactive'}`}>
            {/* <div className="w-100 text-overflow mb-1">
              <span className="text-muted mr-1">
                <FontAwesome name="caret-right" className="mr-1" />
                Identificador:
              </span>
              {asset.factorySerialNumber1 || asset.factorySerialNumber2}
            </div> */}
            {expired && (
              <span>
                <FontAwesome className="mr-1" color="warning" name="exclamation-triangle" id={`expired-${asset.id}`} />
                <UncontrolledTooltip placement="top" target={`expired-${asset.id}`}>
                  Expirado
                </UncontrolledTooltip>
              </span>
            )}
            <NavLink className={!expired ? 'ml-20' : ''} to={`/building/assets/${asset.barcode1}`}>
              <kbd>{asset.barcode1}</kbd>
              <FontAwesome name="search" className="ml-1" />
            </NavLink>
            {asset.mark && (
              <div className="w-100 text-overflow mt-1">
                <span className="text-muted mr-1">
                  <FontAwesome name="tag" color="info" className="mr-1" />
                  Rótulo:
                </span>
                <code>{asset.mark}</code>
              </div>
            )}
            {asset.hasGps && geofence && (
              <div className="w-100 text-overflow mt-1">
                <span className="mr-1">
                  <FontAwesome name="dot-circle" className="mr-1" /> {geofence}
                </span>
              </div>
            )}
          </Media>
        </Media>
      </th>
      <td className="align-middle small">
        <Media className="align-items-center" tag={NavLink} to={`/organization/items/${asset.item.barcode1}`}>
          <Media left className="mr-1" tag="div">
            <PictureDisplay
              picture={asset.item.defaultPicture}
              pictureSize="icon"
              alt={asset.item.name}
              width="3rem"
              height="2rem"
            />
          </Media>
          <Media body tag="div">
            <div>
              {asset.item.name} {asset.isRental && <Badge color="warning">Arrendado</Badge>}
            </div>
            <div>
              <code>{asset.item.barcode1}</code>
            </div>
          </Media>
        </Media>
      </td>
      <td className="align-middle small text-center">{stateCmp}</td>
      <td className="align-middle text-center">
        <Badge color={asset.repairable ? 'info' : 'danger'} pill>
          {asset.repairable ? 'Sí' : 'No'}
        </Badge>
      </td>
      <td className="align-middle small text-center">{readoutInfo}</td>
      <td className="align-middle text-right">
        <ShowForRoles excluded={['guest']}>
          {asset.hasGps && (
            <Button
              role="button"
              size="sm"
              color="primary"
              tag={NavLink}
              className="mr-1"
              outline
              to={`/building/assets/${asset.barcode1}/gps`}
            >
              <FontAwesome name="map-marker-alt" />
            </Button>
          )}
          <Button
            role="button"
            size="sm"
            color="primary"
            tag={NavLink}
            className="mr-1"
            to={`/movements?q=${simpleFilterQuery({
              consumable: 'false',
              'assets[{}]': [{ label: asset.barcode1, value: asset.id }],
            })}`}
          >
            <FontAwesome name="retweet" />
          </Button>
          <Button
            disabled={shouldBlockMovements(asset)}
            role="button"
            size="sm"
            color="success"
            tag={NavLink}
            to={`/movements/new?of=${asset.barcode1}`}
          >
            <FontAwesome name="plus" />
          </Button>
        </ShowForRoles>
      </td>
    </tr>
  );
};

export const AssetRow = connect(({ appData: { computed_states: computedStates } }) => ({
  computedStates,
}))(AssetRowPresentation);
