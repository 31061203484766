import React, { Component } from 'react';
import { Col, Row, Card, CardBody, Form, Label, Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import { UserFinder } from '@shared/forms';
import { DateSelector } from '@shared/forms';
import { FontAwesome } from '@shared';
import { SimpleAssetDisplay, SimpleItemDisplay } from '@shared/displays';
import Select from 'react-select';

const ConsumableRow = ({ item, quantity }) => {
  return (
    <Row>
      <Col className="text-left" md={5}>
        <SimpleItemDisplay item={item} />
      </Col>
      <Col className="text-left" md={5}>
        <span>
          <b>Nombre:</b>
          <p className="mb-0">{item.name}</p>
        </span>
      </Col>
      <Col className="text-left" md={2}>
        <span>
          <b>Cantidad:</b>
          <p className="mb-0">{quantity || 0}</p>
        </span>
      </Col>
    </Row>
  );
};

const NoConsumableRow = ({ item, opts = [], onChange, index }) => {
  return (
    <Row>
      <Col className="text-left" md={5}>
        <SimpleItemDisplay item={item} />
      </Col>
      <Col className="text-left" md={5}>
        <span>
          <b>Nombre:</b>
          <p className="mb-0">{item.name}</p>
        </span>
      </Col>
      <Col className="text-left" md={2}>
        <Select
          isClearable
          name="asset_barcodes"
          placeholder="Seleccione..."
          options={opts}
          onChange={(obj) => onChange(obj, index)}
        />
      </Col>
    </Row>
  );
};

const AssetRow = ({ asset }) => {
  return (
    <Row>
      <Col className="text-left" md={5}>
        <SimpleAssetDisplay asset={asset} />
      </Col>
      <Col className="text-left" md={5}>
        <span>
          <b>Nombre:</b>
          <p className="mb-0">{asset.item.name}</p>
        </span>
      </Col>
      <Col className="text-left" md={2}>
        <span>
          <b>Rotulo:</b>
          <p className="mb-0">{asset.mark || 0}</p>
        </span>
      </Col>
    </Row>
  );
};

class ModalConfirmation extends React.Component {
  state = {
    open: false,
  };

  open = () => {
    this.setState({
      open: true,
    });
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  save = () => {
    this.close();
    this.props.onSave();
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <Modal isOpen={open} size="md">
          <ModalHeader>Confirmación</ModalHeader>
          <ModalBody>
            <p>¿Está seguro que desea entregar los recursos seleccionados?</p>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="danger" onClick={this.close}>
              Cancelar
            </Button>
            <Button className="ml-2" color="primary" onClick={this.save}>
              Si
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export class KitBorrowForm extends Component {
  modal;
  state = {
    client: undefined,
    expectedAt: undefined,
    assetBarcodes: {},
    modal: false,
  };

  validForm = () => {
    const { client, expectedAt, assetBarcodes } = this.state;
    const { kitResources } = this.props;

    const noConsumableTotal = kitResources.reduce(
      (sum, resource) => sum + (resource.item && !resource.item.consumable ? resource.quantity : 0),
      0,
    );
    const barcodes = Object.values(assetBarcodes)?.filter((bar) => bar);
    // presence of client, present of expectedAt, present of all assetBarcodes
    return client && expectedAt && barcodes?.length === noConsumableTotal;
  };

  selectOptions = (assets) => {
    return assets?.map(({ barcode1, mark }) => ({
      label: `${barcode1} - ${mark}`,
      value: barcode1,
    }));
  };

  // Setters
  setClient = (client) => {
    this.setState({
      client,
    });
  };

  setExpectedAt = (date) => {
    this.setState({
      expectedAt: date,
    });
  };

  setBarcode = (barcodeObj, barcodeIndex) => {
    const barcode = barcodeObj ? barcodeObj.value : undefined;
    const { assetBarcodes: barcodes } = this.state;

    barcodes[barcodeIndex] = barcode;

    this.setState({
      assetBarcodes: barcodes,
    });
  };

  filter = (key, assets) => {
    const { assetBarcodes } = this.state;

    const { [key]: excluded, ...rest } = assetBarcodes;

    return assets?.filter((asset, index) => {
      // Si no esta seleccionado en el state, retornar true sino false
      return !Object.values(rest).includes(asset.barcode1);
    });
  };

  doBorrow = async () => {
    const { assetBarcodes, client, expectedAt } = this.state;
    const payload = {
      assetBarcodes: Object.values(assetBarcodes),
      client_id: client,
      expectedAt,
    };
    await this.props.borrowKit(payload);
  };

  setModalRef = (element) => {
    this.modal = element;
  };

  handleSave = () => {
    this.modal.open();
  };

  render() {
    const { kitResources = [], assets } = this.props;
    const { client, expectedAt } = this.state;

    const components = [];

    kitResources.forEach((resource, index) => {
      const { item, asset, quantity } = resource;

      if (!!item) {
        if (item.consumable) {
          components.push(<ConsumableRow key={index} item={item} quantity={quantity} />);
        } else {
          for (let i = 0; i < quantity; i++) {
            const key = `${index}-${i}`;
            components.push(
              <NoConsumableRow
                key={key}
                index={key}
                item={item}
                onChange={this.setBarcode}
                opts={this.selectOptions(this.filter(key, assets[item.barcode1]))}
              />,
            );
          }
        }
      }
      if (!!asset) {
        components.push(<AssetRow key={index} asset={asset} />);
      }
    });

    return (
      <span>
        <Form>
          <h4 className="mb-3">Prestamo de Kit</h4>
          <Row>
            <Col>
              <Label>
                <b>Cliente</b>
              </Label>
              <UserFinder
                isSearchable
                value={client}
                name="clientId"
                building={true}
                editable={true}
                input={{ onChange: this.setClient }}
                required
              />
            </Col>
            <Col>
              <Label>
                <b>Fecha estimada de retorno</b>
              </Label>
              <DateSelector
                selected={expectedAt}
                name="expectedAt"
                autoComplete="off"
                minDate={new Date()}
                onChange={this.setExpectedAt}
                required
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xs="12" className="mb-4">
              <div className="repair-form-division">
                <span className="outer-line" />
                <FontAwesome name="wrench" />
                <div className="text-muted">Recursos</div>
                <span className="outer-line" />
              </div>
            </Col>
          </Row>
          {components?.map((cmp, index) => (
            <Row className="mb-2" key={index}>
              <Col>
                <Card>
                  <CardBody>{cmp}</CardBody>
                </Card>
              </Col>
            </Row>
          ))}
        </Form>
        <div className="text-center">
          <Button
            role="button"
            color="primary"
            width="10em"
            onClick={this.handleSave}
            disabled={!this.props.kitAvailable || !this.validForm()}
          >
            Entregar
          </Button>
        </div>
        <ModalConfirmation ref={this.setModalRef} onSave={this.doBorrow} />
      </span>
    );
  }
}
