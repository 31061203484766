import { createKit } from '@core/building/kits';
import { SubmissionError } from '@core/forms';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { KitForm } from './components/KitForm';

class NewKitPresentation extends Component {
  state = {
    created: false,
  };

  createKit = async (data) => {
    const { body, status } = await createKit(data);

    if (status === 201) {
      this.props.successCreationNotification();

      this.setState({ created: true });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { created, initialValues } = this.state;

    if (created) return <Redirect to={`/building/kits/`} />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Creación de Kit">
          <KitForm onSubmit={this.createKit} initialValues={initialValues} />
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const NewKit = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Kit creado',
        text: 'El Kit ha sido creado exitosamente.',
        color: 'success',
      }),
    ),
}))(NewKitPresentation);
