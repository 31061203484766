import React from 'react';
import { Media } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { FontAwesome, Barcode } from '@shared';

export const SimpleKitDisplay = ({ kit, showBarcode = true, showStatus = true }) => {
  const className = showStatus ? `status-border status-border-${kit.available ? 'active' : 'inactive'}` : '';

  return (
    <Media className="align-items-center">
      {showBarcode && (
        <Media left className={`mr-2 ${className}`}>
          <Barcode code={kit.barcode1} style={{ height: '2.5rem' }} />
        </Media>
      )}
      <Media body className={showBarcode ? '' : `pl-2 ${className}`}>
        <div>{kit.name}</div>
        <NavLink to={`/building/kits/${kit.id}`}>
          <kbd>{kit.barcode1}</kbd>
          <FontAwesome name="search" className="ml-1" />
        </NavLink>
      </Media>
    </Media>
  );
};
