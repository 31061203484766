import { updateAttachment } from '@core/building/attachments';
import { Field, Form, SubmissionError } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { fromIso8601 } from '@core/utils/dates';
import { fileInfoFor } from '@core/utils/mime-types';
import { fileSize } from '@core/utils/number-formatting';
import { FileIcon, FontAwesome, ShowForRoles } from '@shared';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { Button, Card, CardBody, Col, Media, Row } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'title' }));

const DisplayAttachment = ({ attachment }) => (
  <Media className="align-items-center">
    <Media left className="mr-2">
      <FileIcon type={fileInfoFor(attachment.contentType).icon} width="3rem" />
    </Media>
    <Media body>
      <div className="w-100 text-overflow mb-1">
        <span className="text-muted mr-1">Tamaño:</span>
        {(attachment.size && fileSize(attachment.size)) || <em>Sin información</em>}
      </div>
      <div className="w-100 text-overflow mb-1">
        <span className="text-muted mr-1">Fecha:</span> {fromIso8601(attachment.createdAt)}
      </div>
    </Media>
  </Media>
);

const AttachmentPresentation = ({ attachment, onDelete, enterEditingMode }) => (
  <Row>
    <Col md={3} className="align-middle small">
      <DisplayAttachment attachment={attachment} />
    </Col>
    <Col md={3} className="align-middle small">
      <span className="text-muted mr-1">{attachment.title}</span>
    </Col>
    <Col md={4} className="align-middle small">
      <span className="text-muted mr-1">{attachment.description}</span>
    </Col>
    <Col md={2} className="align-middle text-right">
      <Button
        role="button"
        color="primary"
        size="sm"
        tag="a"
        href={attachment.file.url}
        target="_blank"
        className="mr-1"
      >
        <FontAwesome name="download" />
      </Button>
      <Button role="button" outline color="success" size="sm" className="mr-1" onClick={enterEditingMode}>
        <FontAwesome name="pencil-alt" />
      </Button>
      <ShowForRoles accepted={['organization_admin']}>
        <Button
          role="button"
          outline
          color="danger"
          size="sm"
          className="mr-1"
          onClick={() => onDelete(attachment.token)}
        >
          <FontAwesome name="times" />
        </Button>
      </ShowForRoles>
    </Col>
  </Row>
);

const AttachmentEditor = ({ attachment: { token, title, originalFilename, description } }) =>
  compose(
    connect(null, (dispatch) => ({
      editingNotification: (name) =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Archivo editado',
            text: `El archivo ${name} ha sido editado exitosamente.`,
            color: 'success',
          }),
        ),
    })),
    withHandlers({
      onSubmit: (props) => async (data) => {
        const { resourceId, resourceType } = props;

        const { body, status } = await updateAttachment({
          token,
          resourceId,
          resourceType,
          ...data,
        });

        if (status === 200) {
          const { title, description } = body;

          props.exitEditingMode();
          props.editingNotification(title);
          props.onSuccessfulEdition({ title, description });
        } else if (status === 422) {
          throw new SubmissionError(body.errors);
        }
      },
    }),
    reduxForm({
      form: `attachmentForm-${token}`,
      validate,
      initialValues: {
        title: title || originalFilename,
        description,
      },
    }),
  )(AttachmentForm);

const AttachmentForm = ({
  attachment,
  onEdit,
  handleSubmit,
  pristine,
  processing,
  invalid,
  submitting,
  error,
  exitEditingMode,
}) => (
  <Form onSubmit={handleSubmit} noValidate error={error} className="no-form-group-mb">
    <Row>
      <Col md={3} className="align-middle small">
        <DisplayAttachment attachment={attachment} />
      </Col>
      <Col md={3} className="align-middle small">
        <span className="text-muted mr-1">
          <Field name="title" placeholder="Título" style={{ marginBottom: '0 !important' }} />
        </span>
      </Col>
      <Col md={4} className="align-middle small">
        <span className="text-muted mr-1">
          <Field
            name="description"
            type="textarea"
            placeholder="Descripción"
            style={{ marginBottom: '0 !important' }}
          />
        </span>
      </Col>
      <Col md={2} className="text-right">
        <Button type="submit" outline size="sm" role="button" color="success" disabled={processing} className="mr-1">
          <FontAwesome name="check" />
        </Button>
        <Button role="button" outline size="sm" color="danger" onClick={exitEditingMode}>
          <FontAwesome name="times" />
        </Button>
      </Col>
    </Row>
  </Form>
);

const enhance = compose(
  withState('editingMode', 'setEditingMode', false),
  withState('attachment', 'setAttachment', ({ attachment }) => attachment),
  withHandlers({
    enterEditingMode: ({ setEditingMode }) => () => setEditingMode((v) => true),
    exitEditingMode: ({ setEditingMode }) => () => setEditingMode((v) => false),
    updateAttachmentParams: ({ setAttachment }) => ({ title, description }) =>
      setAttachment((att) => ({ ...att, title, description })),
  }),
);

const AttachmentRow = ({
  attachment,
  onDelete,
  editingMode,
  enterEditingMode,
  exitEditingMode,
  updateAttachmentParams,
}) => {
  const Editor = AttachmentEditor({ attachment });
  return (
    <Card className="mb-2">
      <CardBody>
        {editingMode && (
          <Editor
            resourceType="organizations"
            attachment={attachment}
            exitEditingMode={exitEditingMode}
            onSuccessfulEdition={updateAttachmentParams}
          />
        )}
        {!editingMode && (
          <AttachmentPresentation attachment={attachment} onDelete={onDelete} enterEditingMode={enterEditingMode} />
        )}
      </CardBody>
    </Card>
  );
};

export default enhance(AttachmentRow);
