import { updatePartner, deletePartner } from '@core/building/partners';
import { FontAwesome, ShowForRoles } from '@shared';
import { connect } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';

const PartnerRowPresentation = ({ partner, searchFunction }) => {
  const KIND = { client: 'Cliente', supplier: 'Proveedor' };

  const activePartnerRow = async (partner) => {
    const { status } = await updatePartner(partner.id, { active: !partner.active });
    if (status === 200) {
      await searchFunction();
    }
  };

  const deletePartnerRow = async (id) => {
    const { status } = await deletePartner(id);
    if (status === 204) {
      await searchFunction();
    }
  };

  return (
    <tr>
      <td className="align-middle text-overflow">
        <NavLink className="d-flex" to={`/building/partners/${partner?.id}`}>
          <span className="ml-2" style={{ lineHeight: '2rem' }}>
            {partner?.name}
          </span>
        </NavLink>
      </td>
      <td className="align-middle">
        <span className="ml-2" style={{ lineHeight: '2rem' }}>
          {KIND[partner?.kind]}
        </span>
      </td>
      <td className="align-middle text-center">
        <span className="ml-2" style={{ lineHeight: '2rem' }}>
          {partner?.code}
        </span>
      </td>
      <td className="align-middle text-center">
        <Badge color={partner?.active ? 'success' : 'danger'} pill>
          {partner?.active ? 'Sí' : 'No'}
        </Badge>
      </td>
      <ShowForRoles accepted={['organization_admin']}>
        <td className="align-middle text-center">
          <Button
            id={`ActivePartner-${partner?.id}`}
            role="button"
            className="mr-1"
            color={partner?.active ? 'danger' : 'success'}
            size="sm"
            onClick={() => activePartnerRow(partner)}
            disable={false}
          >
            <FontAwesome name={partner?.active ? 'toggle-off' : 'toggle-on'} />
          </Button>
          <Button
            id={`DeletePartner-${partner?.id}`}
            size="sm"
            color="danger"
            onClick={() => deletePartnerRow(partner?.id)}
            disabled={partner?.buildings?.length > 0 || partner?.users?.length > 0}
          >
            <FontAwesome name="times" id={`DeletePartner-${partner?.id}`} />
          </Button>
        </td>
      </ShowForRoles>
      <UncontrolledTooltip placement="auto" target={`ActivePartner-${partner?.id}`}>
        {partner?.active ? 'Desactivar' : 'Activar'}
      </UncontrolledTooltip>
      <UncontrolledTooltip placement="auto" target={`DeletePartner-${partner?.id}`}>
        {partner?.buildings?.length === 0 && partner?.users?.length === 0
          ? 'Eliminar socio de negocio'
          : partner?.buildings?.length > 0 && partner?.users?.length > 0
          ? 'El socio de negocios tiene usuarios e instalaciones asociadas'
          : partner?.buildings?.length > 0 && partner?.users?.length === 0
          ? 'El socio de negocios tiene instalaciones asociadas'
          : 'El socio de negocios tiene usuarios asociados'}
      </UncontrolledTooltip>
    </tr>
  );
};

export const PartnerRow = connect()(PartnerRowPresentation);
