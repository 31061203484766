import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getMember = (user_group_id, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${user_group_id}/members/${id}`,
      method: 'GET',
    }),
  );

export const getMembers = (user_group_id, query = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${user_group_id}/members${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createMember = (user_group_id, member) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${user_group_id}/members`,
      method: 'POST',
      body: {
        member,
      },
    }),
  );

export const updateMember = (user_group_id, id, member) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${user_group_id}/members/${id}`,
      method: 'PUT',
      body: {
        member,
      },
    }),
  );

export const deleteMember = (user_group_id, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${user_group_id}/members/${id}`,
      method: 'DELETE',
    }),
  );
