import { updateAsset } from '@core/building/assets';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { AssetForm } from './AssetForm';

class EditAssetPresentation extends Component {
  state = {
    redirectTo: undefined,
  };

  notFound = () => {
    this.props.notFoundNotification();
    this.setState({ redirectTo: '/building/assets' });
  };

  updateAsset = async (asset) => {
    const { body, status } = await updateAsset({ ...asset });

    if (status === 200) {
      this.props.setAsset(body);
      this.props.successEditingNotification();
      this.setState({ redirectTo: `/building/assets/${body.barcode1}` });
    } else if (status === 422) {
      this.props.errorNotification(body.errors);
      throw new SubmissionError(body.errors);
    } else if (status === 404) {
      this.notFound();
    }
  };

  render() {
    const { asset } = this.props;
    const { redirectTo } = this.state;
    if (redirectTo) return <Redirect to={redirectTo} />;

    return (
      <AssetForm
        onSubmit={this.updateAsset}
        initialValues={{
          ...asset,
          itemBarcode: asset.item.barcode1,
          areaId: asset.area && asset.area.id,
        }}
        itemBarcode={asset.item.barcode1}
        assetId={asset.id}
      />
    );
  }
}

export const EditAsset = connect(null, (dispatch) => ({
  successEditingNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Activo editado',
        text: `El activo ha sido editado exitosamente.`,
        color: 'success',
      }),
    ),
  errorNotification: (errors) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'El activo no se puede actualizar',
        text: errors?.base ? errors?.base.join(', ') : 'Hubo un error al procesar la petición',
        color: 'danger',
      }),
    ),
}))(EditAssetPresentation);
