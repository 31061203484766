import { getCategories } from '@core/building/categories';
import { buildingDownload } from '@core/http.js';
import { ExcelDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { connect } from '@store';
import { DeviceInventoryFilterModal } from './DeviceInventoryFilterModal';
import { DeviceInventoryTable } from './DeviceInventoryTable';

class DeviceInventoryBasePresentation extends SimpleFilter {
  constructor(props) {
    super(props);
    this.searchFunction = props.search;
    this.state = {
      ...super.state,
      position: 0,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    const { body: categories, status } = await getCategories({ paginate: false });

    if (status === 200) {
      this.setState({ categories });
    }
  }

  hookPreResponseHandler = (response) => {
    const { body: items, status } = response;
    if (status === 200 && items.length > 0 && this.props.switcher) {
      const devices = [
        ...new Set(
          [].concat.apply(
            [],
            items.map((item) => Object.keys(item.device_inventory).filter((k) => k !== '__building')),
          ),
        ),
      ];
      this.setState({
        devices,
      });
    }
  };

  next = () => {
    const { position, devices } = this.state;
    let newPos;

    if (position + 1 === devices.length) {
      newPos = 0;
    } else {
      newPos = position + 1;
    }

    this.setState({
      position: newPos,
    });
  };

  prev = () => {
    const { position, devices } = this.state;
    let newPos;

    if (position === 0) {
      newPos = devices.length - 1;
    } else {
      newPos = position - 1;
    }

    this.setState({
      position: newPos,
    });
  };

  render() {
    const { categories, items, filters, pagination, modalOpen, devices, position } = this.state;
    const { currentBuilding, device, switcher } = this.props;
    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;
    return (
      <>
        <Row>
          <Col>
            <div className="content-actions float-right" style={{ float: 'left' }}>
              {items && items?.length > 0 && (
                <Button size="sm" color="secondary" onClick={this.toggle}>
                  <FontAwesome name="filter" /> Filtros
                </Button>
              )}
              {items && items?.length > 0 && !switcher && (
                <div className="d-inline-block">
                  <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                    <ExcelDownload
                      file={device ? `device_inventory/${device.barcode1}` : 'device_inventory'}
                      filters={device ? { id: device.barcode1, ...filters } : filters}
                      csv={!currentBuilding.sapIntegration}
                      downloadFile={buildingDownload}
                      disabled={!items}
                    />
                  </ShowForRoles>
                </div>
              )}
            </div>
            <h4 className="mb-4">
              <FontAwesome name="th" className="text-primary mr-2" />
              Inventario de Dispositivos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <DeviceInventoryTable
                    items={items}
                    pagination={pagination}
                    switcher={this.props.switcher}
                    next={this.next}
                    prev={this.prev}
                    devices={devices}
                    position={position}
                  />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DeviceInventoryFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={device ? { id: device.barcode1, ...filters } : filters}
          categories={categories}
        />
      </>
    );
  }
}

export const DeviceInventoryIndex = connect(({ session }, ownProps) => ({
  ...ownProps,
  currentBuilding: session.user?.memberships.find((m) => m.id === session.currentMembership).building,
}))(DeviceInventoryBasePresentation);
