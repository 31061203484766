import { handleAuth, withToken, apiFetch } from '../http';

export const createDeviceKey = ({ ...deviceKey }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/device_keys`,
      method: 'POST',
      body: {
        deviceKey,
      },
    }),
  );

export const deleteDeviceKey = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/device_keys/${id}`,
      method: 'DELETE',
    }),
  );

export const resetSecret = ({ id, ...deviceKey }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/device_keys/${id}/reset`,
      method: 'PATCH',
      body: {
        deviceKey,
      },
    }),
  );
