import { fromIso8601 } from '@core/utils/dates';
import { dd2DMS } from '@core/utils/degrees';
import { downloadJSONFile } from '@core/utils/download-json-file';
import { FontAwesome, PictureDisplay, ShowBool } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

const imagesToShow = 3;

export const DeviceSummary = ({ device }) => (
  <div className="mb-4">
    <Row>
      <Col sm={12} md={6} className="mb-4">
        <h5 className="mb-3">Resumen del dispositivo</h5>

        <table className="w-100">
          <colgroup>
            <col style={{ width: '35%' }} />
            <col style={{ width: '75%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td className="text-right align-top pr-3">Nombre</td>
              <td className="align-top text-muted">{device.name}</td>
            </tr>
            <tr>
              <td className="text-right align-top pr-3">Identificadores</td>
              <td className="align-top text-muted small">
                <div className="mb-1">
                  <code>{device.barcode1}</code>
                </div>
                <div className="mb-1">
                  ID: <code>{device.id}</code>
                </div>
                {device.identifier && (
                  <div className="mb-1">
                    MAC: <code>{device.identifier}</code>
                  </div>
                )}
              </td>
            </tr>
            {device.deviceType && (
              <tr>
                <td className="text-right align-top pr-3">Tipo</td>
                <td className="align-top text-muted">{device.deviceType.description}</td>
              </tr>
            )}
            <tr>
              <td className="text-right align-top pr-3">Marca</td>
              <td className="align-top text-muted">{device.deviceBrand.name}</td>
            </tr>
            <tr>
              <td className="text-right align-top pr-3">¿Activo?</td>
              <td className="align-top text-muted">
                <ShowBool value={device.active} />
              </td>
            </tr>
            <tr>
              <td className="text-right align-top pr-3">Instalación</td>
              <td className="align-top text-muted">{device.building.name}</td>
            </tr>
            <tr>
              <td className="text-right align-top pr-3">Última conexión</td>
              <td className="align-top text-muted">
                {fromIso8601(
                  device.detectedAt
                    ? device.detectedAt
                    : (device?.deviceType?.name === 'vending' || device?.deviceType?.name === 'lockers') &&
                        device.updatedAt,
                )}
              </td>
            </tr>
            <tr>
              <td className="text-right align-middle pr-3 " rowSpan={2}>
                Ubicación
              </td>
              <td className="align-top text-muted">{device.address}</td>
            </tr>
            <tr>
              <td className="align-top text-muted">
                {device.lat && device.lng ? (
                  <>
                    <a
                      href={`https://www.google.com/maps/place/${dd2DMS(device.lat, device.lng)}/@${device.lat},${
                        device.lng
                      },20.00z`}
                      target="_blank"
                      rel="noreferrer"
                    >{`${device.lat},${device.lng}`}</a>
                    {device.geofenceId && (
                      <Button
                        outline
                        className="ml-1"
                        role="button"
                        size="sm"
                        color="primary"
                        tag={NavLink}
                        to={`/organization/locations/${device.geofenceId}`}
                      >
                        <FontAwesome name="map-marked" />
                      </Button>
                    )}
                  </>
                ) : (
                  'Desconocida'
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col sm={12} md={6} className="mb-4">
        <h5 className="mb-4">Información adicional</h5>

        <Row>
          <Col xs={12} md={8}>
            <h6 className="mb-2">Descripción</h6>
            <div className="mb-4 small text-muted">{device.description || <em>No especificada.</em>}</div>
            {(device?.deviceType?.name === 'vending' || device?.deviceType?.name === 'lockers') && (
              <div>
                <h6 className="mb-2">Ultima conexión</h6>
                <div className="mb-4 small text-muted">{fromIso8601(device.updatedAt)}</div>
              </div>
            )}
            <h6 className="mb-2">Datos</h6>
            {/* {Object.keys(device.metadata).map((param, index) => (
              <div key={index} className="small text-muted">
                {param + ': ' + device.metadata[param]}
              </div>
            ))} */}
            {
              <Button
                type="button"
                color={'primary'}
                onClick={() => downloadJSONFile(device.metadata, `device-${device.name}.txt`)}
              >
                <FontAwesome name="download" /> Descargar archivo con metadata
              </Button>
            }
          </Col>
        </Row>
      </Col>
    </Row>

    <Row>
      <Col className="mb-4">
        <h6 className="mb-2">Imágenes</h6>
        {device.pictures.length === 0 && <div className="text-center text-muted small">Sin imágenes todavía</div>}

        {device.pictures.slice(0, imagesToShow).map((picture, index) => (
          <PictureDisplay
            key={index}
            picture={picture}
            pictureSize="icon"
            alt={picture.title}
            width="4.5rem"
            height="3rem"
            inline={true}
          />
        ))}

        <div className="mt-2 text-center">
          <Button
            role="button"
            size="sm"
            color="success"
            tag={NavLink}
            to={`/admin/devices/${device.barcode1}/pictures`}
          >
            <FontAwesome name="file-image" /> Editar
          </Button>
        </div>
      </Col>
    </Row>
  </div>
);
