import { createFile, deleteFile, getFile, tokensFor, editFile } from '@core/files';
import React, { Component, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Button, Card, ListGroup, ListGroupItem, Media, Input, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { FontAwesome, Spinner } from '../index';
import './FileUploader.scss';

function round2(num) {
  return Math.round(num * 100) / 100;
}

function formatFileSize(size) {
  if (size > 1048576) return `${round2(size / 1048576)} MB`;

  return `${round2(size / 1024)} kB`;
}

const FileComponent = ({ file = {}, onDestroy, setFileName, editable = false }) => {
  const [toggle, setToggle] = useState(false);
  const [name, setName] = useState(undefined);

  const changeName = () => {
    setFileName(name);
    setToggle(!toggle);
  };

  return (
    <ListGroupItem>
      <Media className="w-100">
        <Media left className="mr-2">
          <FontAwesome name="file" />
        </Media>
        <Media body className="text-left">
          {file &&
            file.data &&
            (toggle && editable ? (
              <Row>
                <Col xs={8}>
                  <Input placeholder={file.data.name} onInput={setName} />
                </Col>
                <Col xs={4}>
                  <Button type="button" role="button" color="primary" className="mr-1" onClick={changeName}>
                    <FontAwesome name="edit" />
                  </Button>
                  <Button type="button" role="button" color="danger" size="small" onClick={() => setToggle(!toggle)}>
                    <FontAwesome name="times" />
                  </Button>
                </Col>
              </Row>
            ) : (
              <span id={`file-nam-${file.id}`} onClick={() => setToggle(!toggle)}>
                {file.response.title ? file.response.title : file.data.name}
                <UncontrolledTooltip target={`file-nam-${file.id}`} placement="right">
                  Has click en el archivo para cambiar nombre
                </UncontrolledTooltip>
              </span>
            ))}
          {` `}
          <span className="text-muted">({file && file.data && formatFileSize(file.data.size)})</span>
          {file.status === 'failed' && (
            <small className="text-danger ml-3">Archivo inválido. Revisar tamaño y extensión.</small>
          )}
        </Media>
        <Media right className="ml-2">
          {file.status === 'uploading' && (
            <span className="mr-1 text-info">
              <Spinner />
            </span>
          )}

          {file.status === 'uploaded' && (
            <span className="mr-1 text-success">
              <FontAwesome name="check" />
            </span>
          )}

          {file.status === 'failed' && (
            <span className="mr-1 text-danger">
              <FontAwesome name="exclamation" />
            </span>
          )}

          <span role="button" className="text-danger" onClick={onDestroy}>
            <FontAwesome name="times-circle" />
          </span>
        </Media>
      </Media>
    </ListGroupItem>
  );
};

class FileUploaderDropzone extends Component {
  state = {
    dragging: false,
  };

  setDragging = (dragging = true) => {
    this.setState({ dragging });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { onDrop } = this.props;
    onDrop(acceptedFiles, rejectedFiles);
    this.setDragging(false);
  };

  render() {
    const { multiple, disabled } = this.props;
    const { dragging } = this.state;

    return (
      <Dropzone
        accept=".zip,.rar,.xls,.doc,.xlsx,.docx,.ppt,.pptx,.pdf,.svg,.jpg,.jpeg,.gif,.png"
        multiple={multiple}
        role="button"
        className="w-100 text-left fu-dropzone"
        onDrop={this.onDrop}
        onDragStart={this.setDragging}
        onDragEnter={this.setDragging}
        onDragOver={this.setDragging}
        onDragLeave={() => this.setDragging(false)}
        disablePreview={disabled}
        disableClick={disabled}
        inputProps={{ disabled }}
      >
        {({ getRootProps, getInputProps }) => (
          <section className="w-100 text-left fu-dropzone">
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {dragging && (
                <div className="fu-dropzone-active main-animation">
                  {multiple && (
                    <div>
                      Soltar archivos aquí
                      <div className="small">Se cargarán automáticamente</div>
                    </div>
                  )}
                  {!multiple && (
                    <div>
                      Soltar archivo aquí
                      <div className="small">Se cargará automáticamente</div>
                    </div>
                  )}
                </div>
              )}
              {!dragging && (
                <div className={`fu-dropzone-button main-animation ${disabled ? 'fu-dropzone-disabled' : ''}`}>
                  {multiple ? (
                    <div>
                      Arrastrar archivos aquí
                      <div className="small">O click aquí para seleccionarlos</div>
                    </div>
                  ) : (
                    <div>
                      Arrastrar archivo aquí
                      <div className="small">O click aquí para seleccionar</div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </section>
        )}
      </Dropzone>
    );
  }
}

export class FileUploader extends Component {
  initialState = {
    count: 0,
    files: [],
    rejectedFiles: [],
    showDropzone: false,
    loading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.initialState,
    };
  }

  componentWillUnmount() {
    this.cleanAllPreviews();
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const { files, count } = this.state;
    const { disabled } = this.props;

    if (!disabled) {
      const newFiles = acceptedFiles.map((file, i) => ({
        id: count + i,
        status: 'uploading',
        response: {},
        data: file,
      }));

      this.setState({ rejectedFiles, showDropzone: false });

      if (this.props.multiple) {
        this.setState({
          count: count + newFiles.length,
          files: [...files, ...newFiles],
        });
      } else {
        this.cleanAllPreviews();
        this.setState({ files: newFiles });
      }

      newFiles.forEach(this.uploadFile);
    }
  };

  uploadFile = async (file) => {
    const { body, status } = await createFile(file, this.props.postResource || '/attachments');

    this.setFileStatus(file.id, status === 201 ? 'uploaded' : 'failed', body);
  };

  setFileStatus(id, status = 'uploaded', response = {}) {
    const index = this.state.files.findIndex((file) => file.id === id);

    if (index === -1 || this.state.files[index].status === status) return;

    const newFiles = [
      ...this.state.files.slice(0, index),
      { ...this.state.files[index], status, response },
      ...this.state.files.slice(index + 1),
    ];

    this.setState({
      files: newFiles,
    });

    status === 'uploaded' && this.props.onChange && this.props.onChange(tokensFor(newFiles, this.props.multiple));
  }

  removeFile = (index) => {
    const file = this.state.files[index];

    if (file && file.data && file.data.preview) window.URL.revokeObjectURL(file.data.preview);

    const newFiles = [...this.state.files.slice(0, index), ...this.state.files.slice(index + 1)];

    this.setState({
      files: newFiles,
    });

    const deletable = file.response && file.response.deletable;

    // In the background, remove the file...
    if (deletable && file.status === 'uploaded') {
      deleteFile(file.response.token, this.props.endpoint);
    }

    this.props.onChange && this.props.onChange(tokensFor(newFiles, this.props.multiple));
  };

  cleanAllPreviews = () => {
    const { files } = this.state;

    files.forEach((file) => file.data && file.data.preview && window.URL.revokeObjectURL(file.data.preview));
  };

  cleanAll = () => {
    this.cleanAllPreviews();
    this.setState({ ...this.initialState });
  };

  loadFiles = async () => {
    if (!this.props.multiple && this.props.token) {
      this.setState({ loading: true });

      const { body, status } = await getFile(this.props.token, this.props.endpoint);

      if (status === 200 && !body.deleted) {
        this.setState({
          count: this.state.files.length + 1,
          loading: false,
          files: [
            {
              id: this.state.count,
              status: 'uploaded',
              response: body,
            },
          ],
        });
      }
    }

    if (this.props.multiple && Array.isArray(this.props.tokens) && this.props.tokens.length > 0) {
      this.setState({ loading: true });

      for (let token in this.props.tokens) {
        const { body, status } = getFile(token, this.props.endpoint);

        if (status === 200 && !body.deleted) {
          this.setState({
            count: this.state.count + 1,
            files: [
              ...this.state.files,
              {
                id: this.state.count,
                status: 'uploaded',
                response: body,
              },
            ],
          });
        }
      }

      this.setState({ loading: false });
    }
  };

  setFileName = async (title, file) => {
    const fileNameSplit = file.data.name.split('.');
    const extensionFile = fileNameSplit[fileNameSplit.length - 1];
    const { body } = await editFile(
      this.props.token[this.state.files.indexOf(file)],
      `${title.target.value}.${extensionFile}`,
    );
    this.setState({
      files: this.state.files.map((element) => (element === file ? { ...element, response: body } : element)),
    });
  };

  render() {
    const { multiple, editable, disabled } = this.props;
    const { showDropzone, files, rejectedFiles, loading } = this.state;

    if (loading)
      return (
        <Card>
          <ListGroup flush>
            <ListGroupItem className="text-center text-info">
              <Spinner />
            </ListGroupItem>
          </ListGroup>
        </Card>
      );

    return (
      <Card>
        <ListGroup flush>
          {files.map((file, index) => (
            <FileComponent
              key={index}
              file={file}
              onDestroy={() => this.removeFile(index)}
              setFileName={(title) => this.setFileName(title, file)}
              editable={editable}
            />
          ))}

          {rejectedFiles.map((file, index) => (
            <ListGroupItem className="text-danger">
              <FontAwesome name="exclamation" /> <strong>{file.name}:</strong> archivo inválido.
            </ListGroupItem>
          ))}

          {((!multiple && files.length === 0) || files.length === 0 || showDropzone) && (
            <FileUploaderDropzone onDrop={this.onDrop} disabled={disabled} />
          )}

          {multiple && files.length > 0 && (
            <ListGroupItem>
              <Button
                type="button"
                role="button"
                color="primary"
                block
                onClick={() => this.setState({ showDropzone: true })}
              >
                {multiple ? 'Agregar archivos' : 'Agregar archivo'}
              </Button>
            </ListGroupItem>
          )}
        </ListGroup>
      </Card>
    );
  }
}
