import { editTag, getTag } from '@core/building/tags';
import { SubmissionError } from '@core/forms';
import { Loading } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { TagForm } from './components/TagForm';

class EditTagPresentation extends Component {
  state = {
    loading: true,
    edited: false,
    area: undefined,
    notFound: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) this.notFound();

    const { body: tag, status } = await getTag(id);

    this.setState({ loading: false, tag });

    if (status === 404) {
      this.notFound();
    } else if (status === 200) {
      this.setState({ tag });
    }
  }

  editTag = async (data) => {
    data.tagType = parseInt(data.tagType, 10);
    const { body, status } = await editTag(data);

    if (status === 200) {
      this.props.successNotification();
      this.setState({ edited: true });
    } else if (status === 422) {
      // handle metadata errors
      if (!!body.errors.metadata) {
        let metadata = {};
        body.errors.metadata.forEach((error_fields) => {
          metadata = { ...metadata, ...error_fields };
        });
        body.errors.metadata = metadata;
      }
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { tag, edited, loading, notFound } = this.state;

    if (notFound) return <Redirect to="/building/tags" />;

    if (loading) return <Loading />;

    if (edited) return <Redirect to="/building/tags" />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Editar Tag">
          <TagForm
            onSubmit={this.editTag}
            initialValues={{ ...tag, tagType: tag.tagType.id, assetBarcode: tag.asset.barcode1 }}
          />
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const EditTag = connect(null, (dispatch) => ({
  successNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Tag editado',
        text: 'El tag ha sido editado exitosamente.',
        color: 'success',
      }),
    ),
}))(EditTagPresentation);
