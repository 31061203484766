import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import React from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { getKits } from '@core/building/kits';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';

export class KitsFilterModal extends SimpleFilterModal {
  findKits = async (inputValue) => {
    const { body, status } = await getKits({ name: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ name, barcode1 }) => ({
        label: `${name} - ${barcode1}`,
        value: name,
      }));
    }

    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="multiple_barcodes">Kits</Label>
              <AsyncSelect
                name="name"
                value={this.state['name']}
                isClearable
                isSearchable
                placeholder="Buscar..."
                loadOptions={this.findKits}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="active">Estado</Label>
              <Select
                name="kind"
                value={this.state['kind']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Temporal', value: 'temporary' },
                  { label: 'Permanente', value: 'permanent' },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
