import { FontAwesome } from '@shared/index';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Badge } from 'reactstrap';
import { UserGroupMembers } from './UserGroupMembers';

export const UserGroupMembersModal = ({ userGroup, isOpen, toggle, members }) => (
  <Modal isOpen={isOpen} toggle={toggle} size="lg">
    <ModalHeader toggle={toggle}>
      <Row>
        <Col>
          <h5>Detalle del Grupo de usuarios</h5>
          <Row>
            <Col>
              <strong>Nombre:</strong> {userGroup.name}
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>Administrador:</strong> {userGroup.creator.name}
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>Estado:</strong>{' '}
              <Badge color={userGroup?.active ? 'success' : 'danger'}>
                {userGroup?.active ? 'Activado' : 'Desactivado'}
              </Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <strong>Descripción:</strong> {userGroup.name}
            </Col>
          </Row>
        </Col>
      </Row>
    </ModalHeader>
    <ModalBody>
      <UserGroupMembers members={members} userGroup={userGroup} />
    </ModalBody>
    <ModalFooter>
      <Button tag={NavLink} outline color="info" to={`/building/user_groups/${userGroup.id}`}>
        <FontAwesome name="arrow-up-right-from-square" /> Ver más..
      </Button>
      <Button role="button" onClick={toggle}>
        Cerrar
      </Button>
    </ModalFooter>
  </Modal>
);
