import React from 'react';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { PickUpForm } from './components/PickUpForm';

export const NewPickUp = () => (
  <BuildingLayout>
    <SimpleContainerLayout title="Nueva reserva">
      <PickUpForm />
    </SimpleContainerLayout>
  </BuildingLayout>
);
