import { createDevice } from '@core/admin/devices';
import { SubmissionError } from '@core/forms';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { DeviceForm } from './components/DeviceForm';
import circleToPolygon from 'circle-to-polygon';

class NewDevicePresentation extends Component {
  state = {
    created: false,
    barcode: '',
  };

  createDevice = async (device) => {
    if (!!device.lat && !!device.lng) {
      const coordinates = [Number(device.lng), Number(device.lat)]; //[lon, lat]
      const radius = device.radius ? Number(device.radius) : 50; // in meters
      const numberOfEdges = 360; //optional that defaults to 32
      device['polygon'] = circleToPolygon(coordinates, radius, numberOfEdges);
    }

    const { body, status } = await createDevice({ ...device });

    if (status === 201) {
      this.props.successCreationNotification();

      this.setState({ barcode: body.barcode1, created: true });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { barcode, created } = this.state;

    if (created) return <Redirect to={`/admin/devices/${barcode}`} />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4" title="Creación de dispositivo">
        <DeviceForm onSubmit={this.createDevice} />
      </SimpleContainerLayout>
    );
  }
}

export const NewDevice = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo creado',
        text: 'El dispositivo ha sido creado exitosamente.',
        color: 'success',
      }),
    ),
}))(NewDevicePresentation);
