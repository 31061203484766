export const REGIONS = {
  15: 'Arica y Parinacota',
  1: 'Tarapacá',
  2: 'Antofagasta',
  3: 'Atacama',
  4: 'Coquimbo',
  5: 'Valparaíso',
  6: 'Metropolitana de Santiago',
  7: "O'Higgins",
  8: 'Maule',
  16: 'Ñuble',
  9: 'Bío-Bío',
  10: 'La Araucanía',
  11: 'Los Ríos',
  12: 'Los Lagos',
  13: 'Aysén',
  14: 'Magallanes',
};
