import React from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { FontAwesome } from '@shared/';

const DownloadPdfFromDiv = ({ rootElementId, downloadFileName }) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);
    const button = document.getElementById('downloadExcelBtn');
    const cancelButton = document.getElementById('cancelMovement');
    button.style.visibility = 'hidden';
    cancelButton.style.visibility = 'hidden';
    input.style.border = '0px';
    const options = {
      useCORS: true,
      imageTimeout: 0,
      allowTaint: true,
    };
    html2canvas(input, options).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210;
      const pageHeight = 295;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;

      const doc = new jsPDF('p', 'mm', 'letter', true);
      let position = 0;

      doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`${downloadFileName}.pdf`);
    });
    button.style.visibility = 'visible';
    input.style.border = '1px solid rgba(0, 0, 0, 0.125)';
  };

  return (
    <button className="btn btn-secondary btn-sm" onClick={downloadPdfDocument}>
      <FontAwesome name="file-pdf" /> Descargar PDF
    </button>
  );
};

export default DownloadPdfFromDiv;
