import React from 'react';
import { FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { getInventoryCheckers } from '@core/building/inventory-checkers';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { connect, notificationsGenerators } from '@store';
import { InventoryCheckersResults } from './components/InventoryCheckersResults';
import { InventoryCheckersFilterModal } from './components/InventoryCheckersFilterModal';

class InventoryCheckersIndexPresentation extends SimpleFilter {
  constructor(props) {
    super(props);
    this.state = {
      ...super.state,
      processing: undefined,
    };
  }
  searchFunction = getInventoryCheckers;

  render() {
    const { items, filters, pagination, modalOpen, processing } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <ShowForRoles excluded={['guest', 'pod_driver', 'pod_planner', 'pod_admin', 'onboarding', 'dashboard']}>
              <div className="content-actions float-right">
                <Button size="sm" color="secondary" onClick={this.toggle}>
                  <FontAwesome name="filter" /> Filtros
                </Button>
                <Button
                  tag={NavLink}
                  to="/building/inventory-checkers/new"
                  role="button"
                  size="sm"
                  outline
                  color="success"
                >
                  <FontAwesome name="plus" /> Crear Reporte
                </Button>
                <Button
                  tag={NavLink}
                  to="/building/inventory-checker-sets/new"
                  role="button"
                  size="sm"
                  outline
                  color="success"
                >
                  <FontAwesome name="plus" /> Programar Reportes
                </Button>
              </div>
            </ShowForRoles>
            <h4 className="mb-4">
              <FontAwesome name="layer-group" className="text-primary mr-2" /> Gestión de Auditoría
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <InventoryCheckersResults
                    inventoryCheckers={items}
                    pagination={pagination}
                    processing={processing}
                    states={{
                      completed: { name: 'Completado', color: 'success' },
                      processing: { name: 'Procesando', color: 'warning' },
                      canceled: { name: 'Procesando', color: 'cancelado' },
                    }}
                  />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <InventoryCheckersFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}

export const InventoryCheckersIndex = connect(null, (dispatch) => ({
  downloadSuccessful: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Descarga completada',
        text: 'La descarga del archivo ha completada exitosamente.',
        color: 'success',
      }),
    ),
}))(InventoryCheckersIndexPresentation);
