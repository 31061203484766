import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'reactstrap';
import styles from './NotificationDisplay.module.scss';

const getTitle = (notification) => {
  const { eventType, geofence, tag } = notification?.metadata;

  switch (eventType) {
    case 'geofence-in':
      return (
        <span>
          <b>Entrada a Geocerca:</b> {geofence?.name}
        </span>
      );
    case 'geofence-out':
      return (
        <span>
          <b>Salida de Geocerca:</b> {geofence?.name}
        </span>
      );
    case 'low-battery':
      return (
        <span>
          <b>Batería Baja:</b> {tag?.batteryLevel}%
        </span>
      );
    case 'validity-period':
      return (
        <span>
          <b>Periodo de Vigencia</b>
        </span>
      );
    case 'pickup_status_updated':
      return (
        <span>
          <b>Actualización de pickUp</b>
        </span>
      );
    default:
      return 'Evento desconocido';
  }
};

const getIcon = (notification) => {
  const { eventType } = notification?.metadata;

  switch (eventType) {
    case 'geofence-in':
      return 'map-marked';
    case 'geofence-out':
      return 'map-marked';
    case 'low-battery':
      return 'battery-quarter';
    case 'validity-period':
      return 'calendar-check';
    default:
      return 'question';
  }
};

export const NotificationsDisplay = ({ notification, onClick }) => {
  const pickUpStates = useSelector(({ appData }) => appData.pick_up_states);
  const { metadata } = notification;

  function handleClick() {
    onClick(notification);
  }

  return (
    <Card className={styles.notificationWrapper} onClick={handleClick}>
      <Row style={{ margin: 0 }}>
        <Col xs={2} className={styles.iconWrapper}>
          <FontAwesome className={styles.iconLook} name={getIcon(notification)} />
        </Col>
        <Col xs={10} style={{}}>
          <div className={styles.notificationTitle}>
            <small>{getTitle(notification)}</small>
          </div>
          {metadata?.asset?.name && (
            <div className={styles.notificationBody}>
              <small>
                <strong>Activo:</strong> {metadata?.asset?.name} ({metadata?.asset?.barcode1})
              </small>
            </div>
          )}
          {metadata?.device?.name && (
            <div className={styles.notificationBody}>
              <small>
                <strong>Device:</strong> {metadata?.device?.name}
              </small>
            </div>
          )}
          {metadata?.pickup && (
            <div className={styles.notificationBody}>
              <small>
                <strong>Reserva:</strong> {metadata?.pickup?.request_number}
              </small>{' '}
              <small>
                <strong>Estado:</strong> {pickUpStates[metadata?.pickup?.state]?.name}
              </small>
            </div>
          )}
          <div>
            <small className="text-muted">{metadata?.date || fromIso8601(notification?.createdAt)}</small>
          </div>
        </Col>
      </Row>
    </Card>
  );
};
