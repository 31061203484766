import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { OrganizationSelector } from '@shared/forms';
import { GeofenceSelector } from '@shared/forms/GeofenceSelector';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { REGIONS } from './regions';
import PublishAssetsDisclaimerModal from './PublishAssetsDisclaimerModal';
import PartnerSelector from '@shared/forms/PartnerSelector';

const validate = Validates(
  presence({ of: 'organizationId' }),
  presence({ of: 'name' }),
  presence({ of: 'address1' }),
  presence({ of: 'address2' }),
  presence({ of: 'country' }),
  presence({ of: 'province' }),
);

const BuildingFormPresentation = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  currentValues,
  initialValues: { organization } = {},
  error,
  buildingTypes,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const closeModalDisclaimer = () => setIsOpen(false);

  return (
    <div>
      <Form error={error} noValidate onSubmit={handleSubmit}>
        <h5 className="mb-3">Información básica</h5>

        <Row>
          <Col>
            <Field label="Nombre de la instalación" name="name" help="Mínimo 3 caracteres." required />
          </Col>
          <Col>
            <Field
              label="Organización"
              name="organizationId"
              component={OrganizationSelector}
              help="Organización a la que pertenece esta instalación."
              required
            />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col>
            <h5 className="mb-3">Dirección</h5>

            <Field
              label="Dirección: primera línea"
              name="address1"
              placeholder="Calle, Número, Oficina o Casa"
              required
            />
            <Field label="Dirección: segunda línea" name="address2" placeholder="Villa, Comuna, Provincia" required />
            <Field label="País" name="country" type="select" options={{ '': '', chl: 'Chile' }} required />
            <Field label="Provincia" name="province" type="select" options={REGIONS} required />
          </Col>
          <Col>
            <h5 className="mb-3">Configuración</h5>
            {currentValues?.organizationId && (
              <Field
                label="Geocerca"
                name="geofence"
                component={GeofenceSelector}
                inputProps={{ organization: currentValues?.organizationId }}
                help="Geocerca asociada a esta instalación."
                required
              />
            )}
            <Row>
              <Col>
                <Field
                  label="Actualizar MRO a partir de eventos de geocercas"
                  name="configurations.mroFromGeofence"
                  type="checkbox"
                  help="Permite actualizar el proceso MRO a partir de eventos de entrada y salida de geocercas"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Field
                  name="supplier_code"
                  type="text"
                  label="Código de proveedor externo"
                  placeholder="(Opcional)"
                  help="En caso de que la instalación pertenezca o la administre una empresa externa que no use los servicios de Alliot"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="building_type_id"
                  type="select"
                  label="Tipo de Instalación"
                  placeholder="(Opcional)"
                  options={buildingTypes}
                  help="Indica el tipo de instalación cuando quieras caracterizar su uso"
                />
              </Col>
            </Row>
            <Row>
              {currentValues?.organizationId && (
                <Col>
                  <Field
                    label="Socio de negocios"
                    name="partner_id"
                    component={PartnerSelector}
                    filters={{ active: true, organizations: currentValues?.organizationId }}
                    help="Socio de negocios asociado a la organización"
                  />
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Field
                  label="Integración con GEOAUSTRAL"
                  name="geo_austral_integration"
                  type="checkbox"
                  help="Permite la actualización de Geoaustral a partir de movimientos de MRO"
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <hr />

        <h5 className="mb-3">Información adicional</h5>
        <Field
          label="¿Instalación activa?"
          name="active"
          type="checkbox"
          help="Desmarcar esta opción hará que ningún usuario no administrador pueda acceder a las características de Alliot Cloud. Utilizar con cautela."
        />

        <Field
          label="¿Instalación integrada a SAP?"
          name="sap_integration"
          type="checkbox"
          help="Seleccione esta opción para implementar la integración con SAP"
        />

        <Field
          label="¿Instalación integrada a SAP-ENAEX?"
          name="enaex_integration"
          type="checkbox"
          help="Seleccione esta opción para implementar la integración con SAP-ENAEX"
        />

        {currentValues?.sap_integration && (
          <Row>
            <Col xs={6}>
              <Field label="Código SAP" name="sap_code" help="Código de la bodega en SAP" />
            </Col>
          </Row>
        )}

        <Field
          label="¿Publicar Assets?"
          name="publish_assets"
          type="checkbox"
          help="Seleccione esta opción para que la información de los activos sea pública"
          onChange={(e) => setIsOpen(e.target.checked)}
        />

        <Field
          label="Instalación asíncrona (offline)"
          name="offline"
          type="checkbox"
          help="Seleccione esta opción para que la instalación pueda funcionar offline"
        />

        <Field
          label="Instalación con cálculo de score"
          name="has_a_score"
          type="checkbox"
          help="Seleccione esta opción para que la instalación pueda calcular un score (puntaje) general sobre el uso de la instalación"
        />

        <Field
          label="Comentarios"
          name="comments"
          type="textarea"
          help="Información interna a la cual solo administradores tienen acceso."
          rows="4"
          placeholder="Opcional"
        />

        <hr />

        <div className="text-center">
          <ProcessingButton
            type="submit"
            role="button"
            color="primary"
            outline
            disabled={pristine || invalid}
            processing={submitting}
            width="10em"
          >
            Guardar
          </ProcessingButton>
        </div>
      </Form>
      <PublishAssetsDisclaimerModal isOpen={isOpen} closeModalDisclaimer={closeModalDisclaimer} />
    </div>
  );
};

export const BuildingForm = connect((state) => ({
  currentValues: getFormValues('buildingForm')(state),
}))(
  reduxForm({
    form: 'buildingForm',
    validate,
  })(BuildingFormPresentation),
);
