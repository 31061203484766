import React from 'react';
import { getBuilding, getBuildings } from '@core/admin/buildings';

import { RemoteSelector } from '@core/forms';

import { BuildingCard } from '../../building/components/BuildingCard';

const BuildingSelector = ({
  filters,
  inputProps,
  remoteLoader = getBuilding,
  remoteFinder = getBuildings,
  ...otherProps
}) => {
  return (
    <RemoteSelector
      {...otherProps}
      remoteLoader={remoteLoader}
      remoteFinder={(query) =>
        remoteFinder({
          name: query,
          ...filters,
        })
      }
      inputProps={{
        ...inputProps,
        placeholder: 'Buscar por nombre',
      }}
      dropdownTemplate={BuildingCard}
    />
  );
};

export default BuildingSelector;
