import { formatNumber } from '@core/utils/number-formatting';
import { camelCase, mapKeys } from 'lodash';
import React from 'react';

import { ItemDisplay } from '../../../organization/items/components/ItemCard';

const getProperQuantityFormat = (switcher, quantity, total) => {
  const count = formatNumber(quantity || 0);

  if (switcher && total !== undefined) {
    return (
      <div>
        <span>
          {`${count} `}
          <small className="text-muted">/ {formatNumber(total || 0)}</small>
        </span>
      </div>
    );
  }

  return count;
};

const sumOthers = (inventory) => inventory.unknown_quantity || 0;

const cameliseKeyObject = (object) => {
  return mapKeys(object, (value, key) => camelCase(key));
};

export const InventoryRow = ({ item, inventory, total, switcher, sapIntegration }) => {
  const available_quantity = getProperQuantityFormat(switcher, inventory.available_quantity, total.available_quantity);
  const devices_quantity = getProperQuantityFormat(switcher, inventory.devices_quantity, total.devices_quantity);
  const unit = item.consumable && item.unit ? item.unit.symbol : '-';
  return (
    <tr>
      <td className="align-middle">
        <ItemDisplay item={cameliseKeyObject(item)} />
      </td>
      {!sapIntegration && <td className="align-middle text-center no-pad-l no-pad-r">{available_quantity}</td>}
      {!sapIntegration && <td className="align-middle text-center no-pad-l no-pad-r">{devices_quantity}</td>}
      {!sapIntegration && (
        <td className="align-middle text-center no-pad-l no-pad-r">
          {getProperQuantityFormat(switcher, inventory.reserved_quantity, total.reserved_quantity)}
        </td>
      )}
      {item.consumable && (
        <>
          {sapIntegration && (
            <td colSpan={1} className="align-middle text-center bg-faded no-pad-l no-pad-r">
              {available_quantity}
            </td>
          )}
          {!sapIntegration && (
            <td colSpan={5} className="align-middle text-center bg-faded no-pad-l no-pad-r">
              {<small className="text-muted">No aplica</small>}
            </td>
          )}
          {/* {sapIntegration && (
            <td colSpan={1} className="align-middle text-center bg-faded no-pad-l no-pad-r">
              {unit}
            </td>
          )} */}
        </>
      )}
      {!item.consumable && !sapIntegration && (
        <td className="align-middle text-center no-pad-l no-pad-r">
          {getProperQuantityFormat(switcher, inventory.borrowed_quantity, total.borrowed_quantity)}
        </td>
      )}
      {!item.consumable && !sapIntegration && (
        <td className="align-middle text-center no-pad-l no-pad-r">
          {getProperQuantityFormat(switcher, inventory.mro_quantity, total.mro_quantity)}
        </td>
      )}
      {!item.consumable && !sapIntegration && (
        <td className="align-middle text-center no-pad-l no-pad-r">
          {getProperQuantityFormat(switcher, inventory.withdrawed_quantity, total.withdrawed_quantity)}
        </td>
      )}
      {!item.consumable && !sapIntegration && (
        <td className="align-middle text-center no-pad-l no-pad-r">
          {getProperQuantityFormat(switcher, inventory.damaged_quantity, total.damaged_quantity)}
        </td>
      )}
      {!item.consumable && !sapIntegration && (
        <td className="align-middle text-center no-pad-l no-pad-r">
          {sapIntegration ? unit : getProperQuantityFormat(switcher, sumOthers(inventory), sumOthers(total))}
        </td>
      )}
      <td className="align-middle text-center">{unit}</td>
    </tr>
  );
};
