import React from 'react';
import { BuildingLayout } from '@shared/layouts';
import {
  Button,
  ButtonDropdown,
  Card,
  CardGroup,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { connect } from '@store';
import { compose } from 'recompose';
import { FontAwesome, Pagination, Spinner, ExcelDownload, ShowForRoles } from '@shared';
import { fromIso8601 } from '@core/utils/dates';
import { get } from 'lodash';
import { getLocation } from '@core/organization/locations';
import { getProviderMovements as getMovements } from '@core/provider/movements';
import { MaintenanceDisplay } from './components/MaintenanceDisplay';
import { MaintenanceMovementModal } from './components/MaintenanceMovementModal';
import { MaintenancesFilterModal } from './components/MaintenancesFilterModal';
import { NavLink } from 'react-router-dom';
import { pluralize } from '@shared/helpers';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { RepairFormModal } from './components/RepairForm';
import Timeline from 'rsuite/lib/Timeline';
import './timeline.scss';
import './maintenances-index.scss';
import { StatsMROTable } from './components/StatsMROTable';
import { getMaintenanceStats, updateMaintenance } from '@core/building/asset-repairs';
import { ColumnSortMultiAttr } from '@shared/table/TableSortHeader';
import { buildingDownload } from '@core/http.js';
import DownloadPdfFromDiv from './components/DownloadPdfFromDiv';
import { CancelMROModal } from './components/CancelMROModal';
import { UpdateMROModal } from './components/UpdateMROModal';

class BpmIndexPresentation extends SimpleFilter {
  constructor(props) {
    super(props);
    this.state = {
      ...super.state,
      currentAsset: null,
      currentAssetMovements: null,
      fetchingData: false,
      dropdownOpen: false,
      bpmSelected: null,
      bpmTargetType: undefined,
      bpmTargetId: undefined,
      stats: { total: { own: 0, thirdParties: 0 } },
    };
  }

  searchFunction = (query) => {
    query = {
      as_supplier: true,
      as_owner: true,
      order: 'repairs.created_at DESC',
      ...query,
    };
    return this.props.getIndex({ ...query, pp: 10 });
  };

  async componentDidMount() {
    this.setState({ fetchingData: true });
    await super.componentDidMount();
    await this.selectFirstAssetRepair();
    this.setState({
      fetchingData: false,
    });
    await this.setMROStats();
  }

  setMROStats = async () => {
    const { body } = await getMaintenanceStats();
    this.setState({
      stats: body,
    });
  };

  selectFirstAssetRepair = async () => {
    const { items } = this.state;

    if (items && items.length > 0) {
      await this.setAllAssetInformation(items[0]);
    }
  };

  setAllAssetInformation = async (bpm) => {
    await this.setCurrentAssetRepair(bpm.id);
    await this.setMovementsOfCurrentAsset(bpm.id);
  };

  async setCurrentAssetRepair(bpmId) {
    const { body, status } = await this.props.getShow(bpmId);

    this.setState({
      currentAsset: status === 200 ? body : null,
    });
  }

  async setMovementsOfCurrentAsset(bpmId) {
    const { bpmType } = this.props;
    const { body, status } = await getMovements({
      [`asset_${bpmType}_id`]: bpmId,
      serializer: 'mro',
    });

    if (status === 200) {
      const derivate_movements = body.filter((m) => m?.comments?.includes('Generado a partir de la evaluación'));
      const extendedAttributes = derivate_movements ? derivate_movements[0]?.extendedAttributes : null;
      if (extendedAttributes) {
        const sentToKey = Object.keys(extendedAttributes)
          ?.filter((x) => x.match(/asset/) && x.match(/Id/))
          .join()
          .replace('Id', '')
          .replace('asset', '');
        const sentTo = sentToKey?.toLowerCase();
        this.setState({
          bpmTargetType: sentTo,
          bpmTargetId: extendedAttributes[`asset${sentToKey}Id`],
        });
      } else {
        this.setState({
          bpmTargetType: undefined,
          bpmTargetId: undefined,
        });
      }
      this.setLocationsOfMovements(body);
    } else {
      this.setState({
        currentAssetMovements: null,
      });
    }
  }

  async setLocationsOfMovements(assetMovements) {
    const movementsWithLocation = assetMovements.map(async (movement) => {
      const locationId = get(movement, 'extendedAttributes.geofence.id');
      if (locationId) {
        const { body, status } = await getLocation(locationId);
        if (status === 200) {
          movement.location = body;
        }
      }

      return movement;
    });

    Promise.all(movementsWithLocation).then((movements) => {
      this.setState({
        currentAssetMovements: movements,
      });
    });
  }

  toggleRepairMovementModal = (event, bpm, movements) => {
    this.setState((prevState) => ({
      currentAsset: bpm ? bpm : prevState.currentAsset,
      currentAssetMovements: movements ? movements : prevState.currentAssetMovements,
      repairMovementModalOpen: !prevState.repairMovementModalOpen,
    }));
    if (bpm && movements) {
      this.componentDidMount();
    }
  };

  toggleCancelMROModal = (event, bpm, movements) => {
    this.setState((prevState) => ({
      cancelMROModalOpen: !prevState.cancelMROModalOpen,
    }));
    if (bpm && movements) {
      this.componentDidMount();
    }
  };

  toggleUpdateMROModal = (event, bpm, movements) => {
    this.setState((prevState) => ({
      updateMROModalOpen: !prevState.updateMROModalOpen,
    }));
  };

  toggleRepairForm = (bpm) => {
    this.setState((prevState) => ({
      repairFormOpen: !prevState.repairFormOpen,
      bpmSelected: bpm,
    }));
    if (!bpm) {
      this.componentDidMount();
    }
  };

  getThreedotsText = (string, limit) => {
    if (string.length > limit) {
      return `${string.substring(0, limit)}...`;
    }

    return string;
  };

  setNewFilters = async (filters) => {
    this.setState({ fetchingData: true, currentAsset: null, currentAssetMovements: null });
    await this.handleFilter({
      ...filters,
    });
    await this.selectFirstAssetRepair();
    this.setState({
      fetchingData: false,
    });
  };

  handleSelectAsset = async (bpm) => {
    this.setState({
      fetchingData: true,
      currentAsset: bpm,
      currentAssetMovements: null,
    });

    await this.setAllAssetInformation(bpm);

    this.setState({
      fetchingData: false,
    });
  };

  showAddNewMovement = () => {
    const { currentAsset } = this.state;
    const { movements } = this.props;
    var validMovement = false;

    if (currentAsset.state === 'completed') {
      return false;
    } else {
      Object.keys(movements).forEach((movement_type) => {
        var allowedLastSteps = movements[movement_type].allowed_last_steps;
        if (
          currentAsset.state !== 'completed' &&
          currentAsset.state !== 'canceled' &&
          allowedLastSteps?.includes(currentAsset.state)
        ) {
          validMovement = true;
        }
      });
      return validMovement;
    }
  };

  showSendToRepair = () => {
    const { currentAssetMovements, currentAsset } = this.state;
    const lastMovement = currentAssetMovements ? currentAssetMovements[0] : {};
    return currentAsset.state !== 'completed' && lastMovement?.movementType === 'report_evaluation';
  };

  showLinkTo = () => {
    const { bpmType } = this.props;
    const { bpmTargetType, bpmTargetId, currentAsset } = this.state;

    return (
      bpmType === 'evaluation' &&
      bpmTargetType &&
      (bpmTargetType === 'evaluation' && bpmTargetId === currentAsset?.id ? false : true)
    );
  };

  dropdownToogle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  updateBmp = async (repair, bpmType) => {
    this.setState({ fetchingData: true });
    updateMaintenance(repair, bpmType);
    setTimeout(() => {
      this.setCurrentAssetRepair(repair.id);
      this.setState({ fetchingData: false });
    }, 2000);
  };

  render() {
    const {
      items,
      filters,
      pagination,
      modalOpen,
      currentAsset,
      repairMovementModalOpen,
      cancelMROModalOpen,
      updateMROModalOpen,
      repairFormOpen,
      currentAssetMovements,
      fetchingData,
      dropdownOpen,
      order,
      bpmSelected,
      stats,
      bpmTargetType,
      bpmTargetId,
    } = this.state;
    const { movements, bpmType, getShow, assetHealthStates } = this.props;

    const bpmTypePlural = {
      repair: 'Reparaciones',
      evaluation: 'Evaluaciones',
      maintenance: 'Mantenciones',
      preservation: 'Preservaciones',
      certification: 'Certificaciones',
      assurance: 'Garantías',
      discard: 'Dar de Baja',
    };

    const bpmTypeSingular = {
      repair: 'Reparación',
      evaluation: 'Evaluación',
      maintenance: 'Mantención',
      preservation: 'Preservación',
      certification: 'Certificación',
      assurance: 'Garantía',
      discard: 'Dar de Baja',
    };

    const icons = {
      repair: 'cogs',
      evaluation: 'list-ol',
      maintenance: 'heartbeat',
      preservation: 'hand-holding-medical',
      certification: 'certificate',
      assurance: 'shield-alt',
      discard: 'trash-alt',
    };
    return (
      <BuildingLayout>
        <Row>
          <Col>
            <Row>
              <Col md={3}>
                <h4 className="mb-4">
                  <FontAwesome name={icons[bpmType]} className="text-primary mr-2" /> {bpmTypePlural[bpmType]}
                </h4>
              </Col>
              <Col>
                <StatsMROTable bpmTypeSingular={bpmTypeSingular} stats={stats} />
              </Col>
              <Col>
                <div className="content-actions float-right mb-2">
                  <Row className="mb-1">
                    <Button size="sm" color="secondary" onClick={this.toggle}>
                      <FontAwesome name="filter" /> Filtros
                    </Button>
                    <Button
                      tag={NavLink}
                      size="sm"
                      color="secondary"
                      to={`/mro/${bpmType}s`}
                      onClick={() => this.getItems(undefined)}
                    >
                      Borrar Filtros
                    </Button>
                  </Row>
                  <Row className="mb-1">
                    <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                      <ExcelDownload
                        file={`asset_${bpmType}s`}
                        filters={{ ...filters }}
                        downloadFile={buildingDownload}
                        disabled={!items}
                        csv={false}
                      />
                    </ShowForRoles>
                  </Row>
                  <Row className="mb-1">
                    <ShowForRoles excluded={['guest']}>
                      {bpmType !== 'discard' && (
                        <Button tag={NavLink} size="sm" outline color="success" to={`/mro/new?bpmType=${bpmType}`}>
                          <FontAwesome name="plus" /> Nueva {bpmTypeSingular[bpmType]}
                        </Button>
                      )}
                    </ShowForRoles>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col>
            <CardGroup>
              <Card style={{ flex: 1 }}>
                {!!items && (
                  <CardHeader>
                    <Pagination
                      page={pagination.page}
                      total={pagination.total}
                      setPage={this.setPage}
                      className="my-0"
                    />
                    <div className="small mt-4">
                      <strong>{pagination.count}</strong>{' '}
                      {pluralize(pagination.count, 'activo encontrado', 'activos encontrados')}. Mostrando{' '}
                      <strong>{pagination.per}</strong> activos por página.
                    </div>
                    <ColumnSortMultiAttr
                      attributes={[
                        { label: 'Nombre', code: 'items.name', sortable: true },
                        { label: 'Código de Barra', code: 'assets.barcode1', sortable: true },
                        { label: 'Estado', code: `asset_${bpmType}s.state`, sortable: true },
                        { label: 'Creador', code: 'users.first_name', sortable: true },
                        { label: 'Fecha Creación', code: 'repairs.created_at', sortable: true },
                      ]}
                      currentFilters={filters}
                      setFilters={this.handleFilter}
                      updateOrder={this.updateOrder}
                      order={order}
                    />
                  </CardHeader>
                )}
                <ListGroup flush>
                  {!items && fetchingData && (
                    <ListGroupItem className="d-block text-center h1 text-info">
                      <Spinner />
                    </ListGroupItem>
                  )}

                  {!!items &&
                    items.length > 0 &&
                    items.map((bpm, index) => {
                      const currentAssetId = currentAsset && currentAsset.id;

                      return (
                        <ListGroupItem
                          active={bpm.id === currentAssetId}
                          key={`a-${index}`}
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.125)',
                          }}
                          onClick={() => this.handleSelectAsset(bpm)}
                          role="button"
                        >
                          <MaintenanceDisplay
                            active={bpm.id === currentAssetId}
                            assetRepair={bpm}
                            order={bpm.repair.code}
                          />
                        </ListGroupItem>
                      );
                    })}

                  {!!items && items.length === 0 && (
                    <ListGroupItem className="d-block text-center text-muted">
                      <em>No se encontraron activos.</em>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </Card>
              <Card style={{ flex: 2 }} className="align-items-center" id="timeline-data-to-pdf">
                {currentAsset && fetchingData && (
                  <div className="align-items-center d-flex h-100 h1 text-info">
                    <Spinner />
                  </div>
                )}

                {currentAsset && !fetchingData && (
                  <Col xs={11} className="no-p-l">
                    <Row className="p-t-15">
                      <Col>
                        <span>
                          <b>Dueño:</b> {currentAsset.asset.organization}
                        </span>
                      </Col>
                      <Col>
                        <span className="float-right p-r-15">
                          <b>Activo: </b>
                          <NavLink
                            to={`/building/assets/${currentAsset.asset.barcode1}?as_supplier=${currentAsset.supplier}&bpm_type=${bpmType}`}
                            style={{ color: '#000000' }}
                          >
                            <kbd className="small">{currentAsset.asset.barcode1}</kbd>
                            <FontAwesome name="search" className="ml-1" />
                          </NavLink>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span>
                          <b>Instalación:</b> {currentAsset.asset.building}
                        </span>
                      </Col>

                      <Col>
                        <span className="float-right p-r-15">
                          <b>No. de Orden:</b> {currentAsset.repair.code}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span>
                          <b>Proveedor:</b> {currentAsset.repair.supplier?.name}
                        </span>
                      </Col>
                      <Col>
                        <span className="float-right p-r-15">
                          <b>Lead Time:</b> {fromIso8601(currentAsset.leadTime)}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span>
                          <b>Destino:</b> {currentAsset.building?.name}
                        </span>
                      </Col>
                      <Col>
                        {currentAsset.asset.mark && (
                          <div>
                            <span className="float-right p-r-15">
                              <b>Rótulo:</b> {currentAsset.asset.mark}
                            </span>
                          </div>
                        )}
                        {currentAsset.item.internalSku && (
                          <div>
                            <span className="float-right p-r-15">
                              <b>SKU Interno:</b> {currentAsset.item.internalSku}
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    {currentAsset.repair.creator && currentAsset.repair.creator.name && (
                      <Row>
                        <Col>
                          <span>
                            <b>Creado por:</b> {currentAsset.repair.creator.name}
                          </span>
                        </Col>
                        <Col>
                          {currentAsset.item.internalSku && (
                            <div>
                              <span className="float-right p-r-15">
                                <b>Identificador 1:</b> {currentAsset.asset.factorySerialNumber1}
                              </span>
                            </div>
                          )}
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col>
                        <span>
                          <b>Costo:</b>{' '}
                          {currentAsset?.price && currentAsset?.currency
                            ? `${currentAsset?.price} ${currentAsset?.currency}`
                            : 'No especificado'}
                        </span>
                      </Col>
                      <Col>
                        {currentAsset.asset.criticality && (
                          <div>
                            <span className="float-right p-r-15">
                              <b>Criticidad:</b> {currentAsset.asset.criticality}
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-4">
                        {currentAsset.humanHours && (
                          <span>
                            <b>Horas de trabajo:</b> {currentAsset.humanHours}
                          </span>
                        )}
                      </Col>
                      <Col>
                        {currentAsset.arrivalDate && (
                          <div>
                            <span className="float-right p-r-15">
                              <b>Fecha de llegada:</b> {fromIso8601(currentAsset.arrivalDate)}
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-1"></Col>
                      <Col>
                        {currentAsset.committedDate && (
                          <div>
                            <span className="float-right p-r-15">
                              <b>Fecha comprometida:</b> {fromIso8601(currentAsset.committedDate)}
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col className="col-1"></Col>
                      <Col>
                        {currentAsset.estimatedDate && (
                          <div>
                            <span className="float-right p-r-15">
                              <b>Fecha estimada:</b> {fromIso8601(currentAsset.estimatedDate)}
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    {currentAsset.qaUser && (
                      <Row>
                        <Col id="qa-checker" className="col-4">
                          <b>Control de calidad: </b>{' '}
                          {currentAsset.qa === true ? (
                            <FontAwesome name="check" color="success" />
                          ) : currentAsset.qa === false ? (
                            <FontAwesome name="times" color="danger" />
                          ) : (
                            'Indefinido'
                          )}
                        </Col>
                        <UncontrolledTooltip placement="bottom" target="qa-checker">
                          <span className="small">
                            {currentAsset.qa === true
                              ? 'Aprobado'
                              : currentAsset.qa === false
                              ? 'No Aprobado'
                              : 'Indefinido'}
                          </span>
                        </UncontrolledTooltip>
                        <Col className="float-right p-r-15">
                          <div>
                            <span className="float-right p-r-15">
                              <b>Certificador: </b> {currentAsset.qaUser?.name}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col className="col-4">
                        <span>
                          <b>{bpmTypeSingular[bpmType]} Nº:</b> {currentAsset.id}
                        </span>
                      </Col>
                      <Col>
                        {currentAsset?.asset.healthState && (
                          <div>
                            <span className="float-right p-r-15">
                              <b>Estado de salud: </b>{' '}
                              <span className={`text-${assetHealthStates[currentAsset?.asset.healthState]?.color}`}>
                                {assetHealthStates[currentAsset?.asset.healthState]?.name}
                              </span>
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <ShowForRoles excluded={['guest']}>
                      <hr />
                      <Row>
                        <Col>
                          <div id="downloadExcelBtn">
                            <DownloadPdfFromDiv
                              downloadFileName={`report-${bpmType}-${
                                currentAsset.asset.mark ? currentAsset.asset.mark : currentAsset.asset.barcode1.slice(3)
                              }`}
                              rootElementId="timeline-data-to-pdf"
                            />
                          </div>
                        </Col>
                        {currentAsset.report && (
                          <Col>
                            <div>
                              <Button
                                tag={NavLink}
                                target="_blank"
                                size="sm"
                                outline
                                color="primary"
                                to={
                                  currentAsset.report?.file?.url.substring(0, 6) === 'https:'
                                    ? currentAsset.report?.file?.url.substring(6)
                                    : currentAsset.report?.file?.url
                                }
                                rel="noopener noreferrer"
                              >
                                <FontAwesome name="file-pdf" /> Reporte
                              </Button>
                            </div>
                          </Col>
                        )}
                        <Col>
                          <ShowForRoles accepted={['organization_admin']}>
                            <Button
                              id="updateMRO"
                              role="button"
                              color="primary"
                              size="sm"
                              onClick={this.toggleUpdateMROModal}
                              disabled={currentAsset.state === 'completed' || currentAsset.state === 'canceled'}
                            >
                              <FontAwesome name="redo-alt" /> Actualizar Info
                            </Button>
                          </ShowForRoles>
                        </Col>
                        <Col>
                          <Button
                            id="cancelMovement"
                            role="button"
                            color="danger"
                            size="sm"
                            onClick={this.toggleCancelMROModal}
                            disabled={currentAsset.state === 'completed' || currentAsset.state === 'canceled'}
                          >
                            <FontAwesome name="ban" /> Cancelar
                          </Button>
                        </Col>
                      </Row>
                    </ShowForRoles>
                    <Row>
                      <Col>
                        <hr className="m-r-15" />
                      </Col>
                    </Row>
                    <Row>
                      {(this.showSendToRepair() || this.showAddNewMovement() || this.showLinkTo()) && (
                        <Col className="text-right m-r-15">
                          {this.showSendToRepair() && (
                            <ButtonDropdown className="mr-2" isOpen={dropdownOpen} toggle={this.dropdownToogle}>
                              <DropdownToggle color="primary" size="sm" caret>
                                <span className="small">Iniciar MRO</span>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem onClick={() => this.toggleRepairForm('evaluation')}>
                                  <span className="small">Enviar a Evaluación</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.toggleRepairForm('repair')}>
                                  <span className="small">Enviar a Reparar</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.toggleRepairForm('maintenance')}>
                                  <span className="small">Enviar a Mantención</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.toggleRepairForm('preservation')}>
                                  <span className="small">Enviar a Preservación</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.toggleRepairForm('certification')}>
                                  <span className="small">Enviar a Certificación</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.toggleRepairForm('assurance')}>
                                  <span className="small">Enviar a Garantía</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={() => this.toggleRepairForm('discard')}>
                                  <span className="small">Enviar a Dar de Baja</span>
                                </DropdownItem>
                              </DropdownMenu>
                            </ButtonDropdown>
                          )}
                          <ShowForRoles excluded={['guest']}>
                            {this.showAddNewMovement() && (
                              <span>
                                <UncontrolledTooltip placement="bottom" target="new-repair-movement">
                                  <span className="small">Nuevo movimiento</span>
                                </UncontrolledTooltip>
                                <Button
                                  id="new-repair-movement"
                                  role="button"
                                  color="primary"
                                  size="sm"
                                  onClick={this.toggleRepairMovementModal}
                                >
                                  <FontAwesome name="plus" />
                                </Button>
                              </span>
                            )}
                          </ShowForRoles>
                          {this.showLinkTo() && (
                            <Button
                              tag={NavLink}
                              size="sm"
                              color="primary"
                              to={`/mro/${bpmTargetType}s?q=${encodeURIComponent(
                                btoa(JSON.stringify({ id: bpmTargetId })),
                              )}`}
                              className="ml-3"
                              target="_blank"
                              // onClick={() =>
                              //   (window.location.href = `/mro/${bpmTargetType}s?q=${encodeURIComponent(
                              //     btoa(JSON.stringify({ id: bpmTargetId })),
                              //   )}`)
                              // }
                            >
                              Ir a {bpmTypeSingular[bpmTargetType]} <FontAwesome name="external-link-alt" />
                            </Button>
                          )}
                        </Col>
                      )}
                    </Row>

                    <Row className="justify-content-center">
                      <Timeline align="alternate" className="custom-timeline">
                        {currentAssetMovements &&
                          currentAssetMovements.length > 0 &&
                          currentAssetMovements.map((maintenanceMovement, index) => {
                            return (
                              <Timeline.Item
                                key={`timeline-${index}`}
                                dot={
                                  (movements[maintenanceMovement.movementType] && (
                                    <FontAwesome
                                      name={movements[maintenanceMovement.movementType].icon}
                                      className={`rs-icon ${index === 0 ? 'rs-last-icon' : ''}`}
                                    />
                                  )) ||
                                  ''
                                }
                              >
                                <span>{maintenanceMovement ? fromIso8601(maintenanceMovement.movementAt) : ''}</span>
                                <div>
                                  <NavLink style={{ color: '#000000' }} to={`/movements/${maintenanceMovement.id}`}>
                                    <b>
                                      {(movements[maintenanceMovement.movementType] &&
                                        movements[maintenanceMovement.movementType].name) ||
                                        ''}
                                    </b>
                                  </NavLink>
                                </div>
                                <div>
                                  {`${maintenanceMovement.generator?.firstName} ${maintenanceMovement.generator?.lastName}`}
                                </div>
                                <div>
                                  {maintenanceMovement.confirmed && (
                                    <span>
                                      <FontAwesome id={`sync-${maintenanceMovement.id}`} name="sync" />{' '}
                                      <FontAwesome name="check" color="success" />
                                      <UncontrolledTooltip target={`sync-${maintenanceMovement.id}`}>
                                        Sincronizado con Geoaustral
                                      </UncontrolledTooltip>
                                    </span>
                                  )}
                                </div>
                                {maintenanceMovement.comments && (
                                  <span className="word-break-word">
                                    <b>Observaciones:</b> {maintenanceMovement.comments}
                                  </span>
                                )}
                                {maintenanceMovement?.comments?.includes('Generado a partir de la evaluación') &&
                                  maintenanceMovement?.extendedAttributes['originEvaluationId'] !== currentAsset.id && (
                                    <Button
                                      tag={NavLink}
                                      size="sm"
                                      color="primary"
                                      to={`/mro/evaluations?q=${encodeURIComponent(
                                        btoa(
                                          JSON.stringify({
                                            id: maintenanceMovement?.extendedAttributes['originEvaluationId'],
                                          }),
                                        ),
                                      )}`}
                                      target="_blank"
                                      className="ml-3"
                                      // onClick={() =>
                                      //   (window.location.href = `/mro/evaluations?q=${encodeURIComponent(
                                      //     btoa(
                                      //       JSON.stringify({
                                      //         id: maintenanceMovement?.extendedAttributes['originEvaluationId'],
                                      //       }),
                                      //     ),
                                      //   )}`)
                                      // }
                                    >
                                      Ir a {bpmTypeSingular['evaluation']} <FontAwesome name="external-link-alt" />
                                    </Button>
                                  )}
                                {maintenanceMovement.location && (
                                  <span>
                                    <b>Geocerca:</b> {maintenanceMovement.location?.name}
                                  </span>
                                )}
                                <dl>
                                  {maintenanceMovement.attachments &&
                                    maintenanceMovement.attachments.length > 0 &&
                                    maintenanceMovement.attachments.map((attachment, index) => {
                                      return (
                                        <dt className="mr-2">
                                          <a href={attachment.file.url} download style={{ color: '#000000' }}>
                                            <b>
                                              <FontAwesome id={`repair-${index}-attachments`} name="paperclip" />
                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target={`repair-${index}-attachments`}
                                              >
                                                <span className="small">Contiene archivos adjuntos</span>
                                              </UncontrolledTooltip>
                                              {attachment.title || attachment.originalFilename}
                                            </b>
                                          </a>
                                        </dt>
                                      );
                                    })}
                                </dl>
                              </Timeline.Item>
                            );
                          })}
                      </Timeline>
                    </Row>
                  </Col>
                )}
              </Card>
            </CardGroup>
          </Col>
        </Row>
        <MaintenancesFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.setNewFilters}
          initialFilters={filters}
          bpmType={bpmType}
        />
        <MaintenanceMovementModal
          bpmType={bpmType}
          BpmSearcher={getShow}
          isOpen={repairMovementModalOpen}
          assetRepair={currentAsset}
          toggle={this.toggleRepairMovementModal}
        />
        <RepairFormModal
          isOpen={repairFormOpen}
          toggle={() => this.toggleRepairForm(null)}
          bpmType={bpmSelected}
          currentAsset={currentAsset ? currentAsset : null}
        />
        <CancelMROModal
          isOpen={cancelMROModalOpen}
          bpmType={bpmType}
          assetRepair={currentAsset}
          BpmSearcher={getShow}
          toggle={this.toggleCancelMROModal}
        />
        {currentAsset && (
          <UpdateMROModal
            isOpen={updateMROModalOpen}
            bpmType={bpmType}
            assetRepair={currentAsset}
            toggle={this.toggleUpdateMROModal}
            updateMaintenance={this.updateBmp}
          />
        )}
      </BuildingLayout>
    );
  }
}

const enhance = compose(
  connect(({ appData }, ownProps) => {
    return {
      movements: {
        ...appData['movements'],
        ...appData['evaluation_movements'],
        ...appData['repair_movements'],
        ...appData['maintenance_movements'],
        ...appData['preservation_movements'],
        ...appData['certification_movements'],
        ...appData['assurance_movements'],
        ...appData['discard_movements'],
      },
      assetHealthStates: appData['asset_health_states'],
    };
  }),
);
export const BpmIndex = enhance(BpmIndexPresentation);
