import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { compose, lifecycle, onlyUpdateForKeys, withState, withHandlers } from 'recompose';
import type { HOC } from 'recompose';

import Pagination from '@shared/Pagination';

type Props = {
  currentPage: number,
  onChangeGrid: Function,
  resultsOnPage: number,
  resultsPerPage: number,
  totalCount: number,
};

const calculateTotalPages = (totalCount: number, resultsPerPage: number) => {
  let totalPages = Math.floor(totalCount / resultsPerPage);
  if (totalCount % resultsPerPage !== 0) {
    totalPages++;
  }
  return totalPages;
};

const enhance: HOC<*, Props> = compose(
  onlyUpdateForKeys(['currentPage', 'onChangeGrid', 'resultsOnPage', 'resultsPerPage', 'totalCount']),
  withState('totalPages', 'setTotalPages', ({ resultsPerPage, totalCount }) =>
    calculateTotalPages(totalCount, resultsPerPage),
  ),
  withHandlers({
    handleOnPerPageChange: ({ onChangeGrid }) => (event) => {
      onChangeGrid({
        resultsPerPage: parseInt(event.target.innerText, 10),
        currentPage: 1,
      });
    },

    handleOnChangePage: ({ onChangeGrid }) => (page: number) => {
      onChangeGrid({
        currentPage: page,
      });
    },
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps: Props) {
      const { totalCount: _totalCount, resultsPerPage: _resultsPerPage, setTotalPages } = this.props;
      const { totalCount, resultsPerPage } = nextProps;

      if (totalCount !== _totalCount || resultsPerPage !== _resultsPerPage) {
        setTotalPages(calculateTotalPages(totalCount, resultsPerPage));
      }
    },
  }),
);

const GridFooter = ({
  currentPage,
  resultsOnPage,
  resultsPerPage,
  totalCount,
  // state
  totalPages,
  // handlers
  handleOnChangePage,
  handleOnPerPageChange,
}) => {
  const optionsArray = [10, 20, 50, 100];

  const pageArr = [];
  for (let i = 1; i <= Math.ceil(totalCount / resultsPerPage); i++) {
    let selected = currentPage === i ? true : false;
    pageArr.push(
      <option value={i} selected={selected}>
        {i}
      </option>,
    );
  }
  const init = totalCount > 0 && resultsOnPage > 0 ? (currentPage - 1) * resultsPerPage + 1 : 0;

  const end = (currentPage - 1) * resultsPerPage + resultsOnPage;

  return (
    <div>
      <div className="pull-left">
        Resultados por página:{' '}
        <UncontrolledDropdown className="d-inline" size="sm">
          <DropdownToggle caret>{resultsPerPage}</DropdownToggle>
          <DropdownMenu>
            {optionsArray.map((value) => (
              <DropdownItem key={`dropdown-item-${value}`} onClick={handleOnPerPageChange}>
                {value}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
      <div className="pull-right d-inline-flex">
        <div>{`${init} a ${end > totalCount ? totalCount : end} de ${totalCount} filas`}</div>
        &nbsp;
        <div>
          <Pagination size="sm" page={currentPage} total={totalPages} setPage={handleOnChangePage} />
        </div>
      </div>
    </div>
  );
};

export default enhance(GridFooter);
