import { SubmissionError } from 'redux-form';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (errors) {
  if (Array.isArray(errors)) return new SubmissionError({ _error: errors });

  const { base: _error, ...otherErrors } = errors;

  return new SubmissionError({ _error, ...otherErrors });
}
