import React from 'react';

import { handleAuth, withToken, apiFetch, loadFile, mountQuery } from '../http';
import { FontAwesome, Spinner } from '@shared';

export function role(key) {
  switch (key) {
    default:
      return 'N/A';
    case 'user':
      return 'Usuario';
    case 'support':
      return 'Soporte';
    case 'root':
      return 'Root';
  }
}

export function status(key) {
  switch (key) {
    case 'ok':
      return <span className="text-success">Activo</span>;
    case 'confirmation_pending':
      return <span className="text-warning">Esperando confirmación</span>;
    case 'password_reset_pending':
      return <span className="text-warning">Recuperando contraseña</span>;
    case 'invitation_pending':
      return <span className="text-info">Esperando invitación</span>;
    case 'locked':
      return <span className="text-danger">Bloqueada (seguridad)</span>;
    case 'not_active':
      return <span className="text-danger">Cuenta desactivada</span>;
    case 'loading':
      return (
        <span className="text-info">
          <Spinner />
        </span>
      );
    case 'error':
      return (
        <span className="text-danger">
          <FontAwesome name="times" />
        </span>
      );
    case 'done':
      return (
        <span className="text-success">
          <FontAwesome name="check" />
        </span>
      );
    default:
      return <span>No disponible</span>;
  }
}

export const createUser = (user) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: '/admin/users',
      method: 'POST',
      body: { user },
    }),
  );

export const editUser = ({ id, ...user }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}`,
      method: 'PUT',
      body: { user },
    }),
  );

export const getUser = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}`,
      method: 'GET',
    }),
  );

export const getUsers = (query = {}) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const deactivateUser = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}`,
      method: 'DELETE',
    }),
  );

export const activateUser = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}`,
      method: 'PUT',
      body: {
        user: { active: true },
      },
    }),
  );

export const softDelete = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/soft`,
      method: 'PUT',
    }),
  );

// Additional account actions in user editing

export const redeliverInvitation = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/invitations/redeliver`,
      method: 'POST',
    }),
  );

export const confirmInvitation = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/invitations/confirm`,
      method: 'POST',
    }),
  );

export const clearConfirmation = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/confirmation`,
      method: 'DELETE',
    }),
  );

export const confirmEmail = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/confirmation`,
      method: 'POST',
    }),
  );

export const redeliverRecovery = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/recovery/redeliver`,
      method: 'POST',
    }),
  );

export const cancelRecovery = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/recovery`,
      method: 'DELETE',
    }),
  );

export const clearLock = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/lock`,
      method: 'DELETE',
    }),
  );

export const redeliverLockMail = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${id}/lock`,
      method: 'POST',
    }),
  );

export const massImportUsers = (file) =>
  handleAuth(
    withToken(loadFile)({
      endpoint: `/admin/users/mass`,
      method: 'POST',
      name: 'file',
      files: file,
      parseResponse: false,
    }),
  );
