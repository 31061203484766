import { FontAwesome, ShowForRoles } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';

export const BuildingNav = ({ showAdminDivider }) => (
  <div>
    <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}>
      <div>
        {showAdminDivider && (
          <div>
            <DropdownItem divider />
            <DropdownItem header>Administrar</DropdownItem>
          </div>
        )}
        <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}>
          <DropdownItem tag={NavLink} to="/building/assets">
            <FontAwesome name="wrench" className="mr-2" /> Activos
          </DropdownItem>
          <DropdownItem tag={NavLink} to="/building/items">
            <FontAwesome name="th-large" className="mr-2" /> Artículos
          </DropdownItem>
        </ShowForRoles>
        <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
          {/* <DropdownItem tag={NavLink} to="/building/reservations">
            <FontAwesome name="calendar-check" className="mr-2" /> Reservas
          </DropdownItem> */}
          <DropdownItem tag={NavLink} to="/building/kits">
            <FontAwesome name="shopping-basket" className="mr-2" /> Kits
          </DropdownItem>
        </ShowForRoles>
      </div>
    </ShowForRoles>
    <ShowForRoles accepted={['organization_admin']}>
      <div>
        <DropdownItem tag={NavLink} to="/building/users">
          <FontAwesome name="user" className="mr-2" /> Usuarios
        </DropdownItem>
        <DropdownItem tag={NavLink} to="/building/user_groups">
          <FontAwesome name="users" className="mr-2" /> Grupos de Usuarios
        </DropdownItem>
        <DropdownItem tag={NavLink} to="/building/partners">
          <FontAwesome name="handshake" className="mr-2" /> Socios de Negocios
        </DropdownItem>
      </div>
    </ShowForRoles>
    <ShowForRoles accepted={['organization_admin', 'guest']}>
      <div>
        <DropdownItem tag={NavLink} to="/building/events">
          <FontAwesome name="calendar-alt" className="mr-2" /> Eventos
        </DropdownItem>
      </div>
    </ShowForRoles>
    <ShowForRoles accepted={['onboarding']}>
      <DropdownItem tag={NavLink} to="/building/onboarding">
        <FontAwesome name="barcode" className="mr-2" /> Onboarding
      </DropdownItem>
    </ShowForRoles>
    <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
      <DropdownItem tag={NavLink} to="/building/pick-ups">
        <FontAwesome name="mobile-alt" className="mr-2" /> Pick Up
      </DropdownItem>
    </ShowForRoles>
    <DropdownItem tag={NavLink} to="/building/inventory">
      <FontAwesome name="th" className="mr-2" /> Inventario
    </DropdownItem>
    <DropdownItem tag={NavLink} to="/building/devices/inventory">
      <FontAwesome name="th" className="mr-2" /> Inventario de Dispositivos
    </DropdownItem>
  </div>
);
