import { FontAwesome } from '@shared/';
import React from 'react';
import { Media } from 'reactstrap';
import { EventTaskModal } from './EventTask';

export const EventSubtask = ({ subtask }) => (
  <Media>
    {subtask && (
      <Media body>
        {subtask.task && (
          <div className="w-100 text-overflow mt-1 align-middle small">
            <FontAwesome name="tasks" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{subtask.task.title}</span>
          </div>
        )}
        {subtask.title && (
          <div className="w-100 text-overflow mt-1 align-middle small">
            <FontAwesome name={subtask.completedAt ? 'check-square' : 'square'} color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{subtask.title}</span>
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventSubtaskModal = ({ subtask }) => (
  <div>
    <EventTaskModal task={subtask.task} />
  </div>
);
