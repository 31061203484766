import { apiFetch, handleAuth, withMembership, mountQuery, loadFile } from '../http';

export const getItems = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getShortItems = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items${mountQuery({ ...query, serializer: 'short' })}`,
      method: 'GET',
    }),
  );

export const getItemsFromBuilding = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/from_building${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getItem = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${id}`,
      method: 'GET',
    }),
  );

export const createItem = (item) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/items',
      method: 'POST',
      body: {
        item,
      },
    }),
  );

export const editItem = ({ barcode1, ...item }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${barcode1}`,
      method: 'PUT',
      body: {
        item,
      },
    }),
  );

export const deleteItem = (itemBarcode) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${itemBarcode}`,
      method: 'DELETE',
    }),
  );

export const insertCategory = (itemBarcode, categoryId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${itemBarcode}/categorizations`,
      method: 'POST',
      body: {
        categoryId,
      },
    }),
  );

export const deleteCategory = (itemBarcode, categoryId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${itemBarcode}/categorizations`,
      method: 'DELETE',
      body: {
        categoryId,
      },
    }),
  );

export const insertPicture = (itemBarcode, token) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${itemBarcode}/pictures`,
      method: 'POST',
      body: {
        token,
      },
    }),
  );

export const deletePicture = (itemBarcode, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${itemBarcode}/pictures/${id}`,
      method: 'DELETE',
    }),
  );

export const massImportItems = (file) =>
  handleAuth(
    withMembership(loadFile)({
      endpoint: `/items/mass`,
      method: 'POST',
      name: 'file',
      files: file,
      parseResponse: false,
    }),
  );

export const massImportUpdateItems = (file) =>
  handleAuth(
    withMembership(loadFile)({
      endpoint: `/items/mass_update`,
      method: 'PUT',
      name: 'file',
      files: file,
      parseResponse: false,
    }),
  );

export const mergeItems = (items) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/to_merge`,
      method: 'POST',
      body: {
        items,
      },
    }),
  );

export const transferConsumables = (formData) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/items/transfer_consumables',
      method: 'POST',
      body: formData,
    }),
  );
