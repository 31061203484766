import React, { useState } from 'react';
import { FontAwesome } from '@shared';
import { Badge, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { fromIso8601 } from '@core/utils/dates';
import { PickUpModal } from './PickUpModal';

export const PickUpRow = ({ pickUp }) => {
  const pickUpStates = useSelector(({ appData }) => appData.pick_up_states);

  const [isOpen, setIsOpen] = useState(false);

  const onToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <tr>
      <td>{fromIso8601(pickUp.createdAt)}</td>
      <td>{pickUp.requestNumber}</td>
      <td>{pickUp.user.name}</td>
      <td>{pickUp.device && pickUp.device.name}</td>
      <td className="align-middle text-center">
        {Number(pickUp.pickUpUnits.reduce((a, b) => a + (Number(b['quantity']) || 0), 0)?.toPrecision(3))}
      </td>
      <td className="align-middle text-center">
        {Number(pickUp.pickUpUnits.reduce((a, b) => a + (Number(b['delivered']) || 0), 0)?.toPrecision(3))}
      </td>
      <td className="align-middle text-center">
        <Badge color={pickUpStates[pickUp.state].color}>
          <FontAwesome name="times" className="mr-1" /> {pickUpStates[pickUp.state].name}
        </Badge>
      </td>
      <td className="align-middle text-center">
        <Button color="primary" onClick={onToggle}>
          <FontAwesome name="search" className="mr-1" />
        </Button>
      </td>
      <PickUpModal pickUp={pickUp} isOpen={isOpen} onToggle={onToggle} pickUpStates={pickUpStates} />
    </tr>
  );
};
