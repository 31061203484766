import { getKit } from '@core/building/kits';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { Barcode, FontAwesome, Loading, ShowForRoles } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { NavLink as Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Media, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withProps, withState } from 'recompose';
import { BorrowKit } from './BorrowKit';
import KitResources from './components/KitResources';
import { KitSummary } from './components/KitSummary';
import { EditKit } from './EditKit';

const KitTitle = ({ kit }) => (
  <Card>
    <CardBody>
      <Media className="align-items-center">
        <Media body>
          <div className="small text-muted">Kit</div>
          <h4>{kit.name}</h4>
          <div>
            <kbd>{kit.barcode1}</kbd>
          </div>
        </Media>
        <Media right className="ml-3">
          <Barcode code={kit.barcode1} style={{ height: '4rem' }} />
        </Media>
      </Media>
    </CardBody>
  </Card>
);

const enhance = compose(
  connect(null, (dispatch) => ({
    notFoundNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'No encontrado',
          text:
            'No se encontró el kit buscado pues no existe o no se encuentra disponible. Si los problemas persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      ),
  })),
  withProps(({ match: { params: { id } } }) => ({ id })),
  withState('kit', 'setKit', undefined),
  withState('notFound', 'setNotFound', false),
  lifecycle({
    async componentDidMount() {
      const { id, setKit } = this.props;
      const { body: kit, status } = await getKit(id);

      if (status === 200) {
        setKit(kit);
      } else if (status === 404) {
        const { notFoundNotification, setNotFound } = this.props;

        notFoundNotification();
        setNotFound(true);
      }
    },
  }),
  branch(
    ({ notFound }) => notFound,
    renderComponent(() => <Redirect to={`/building/kits`} />),
  ),
  branch(({ kit }) => !kit, renderComponent(Loading)),
);

const KitPagePresentation = ({ kit, match, setKit }) => (
  <BuildingLayout>
    <Row>
      <Col>
        <KitTitle kit={kit} />
        <Card className="mt-4">
          <CardHeader>
            <Nav tabs className="card-header-tabs">
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/kits/${kit.id}`}>
                  <FontAwesome name="list-alt" /> Resumen
                </NavLink>
              </NavItem>
              <ShowForRoles excluded={['guest']}>
                <NavItem>
                  <NavLink role="button" exact tag={Link} to={`/building/kits/${kit.id}/edit`}>
                    <FontAwesome name="pencil-alt" /> Editar
                  </NavLink>
                </NavItem>
              </ShowForRoles>
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/kits/${kit.id}/kit_resources`}>
                  <FontAwesome name="shopping-basket" /> Recursos
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <Switch>
              <ProtectedRoute
                exact
                path={`${match.url}/edit`}
                render={(props) => <EditKit kit={kit} setKit={setKit} {...props} />}
                acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
              />
              <ProtectedRoute exact path={`${match.url}/`} render={(props) => <KitSummary kit={kit} {...props} />} />
              <ProtectedRoute
                exact
                path={`${match.url}/kit_resources`}
                render={(props) => (
                  <KitResources
                    kit={kit}
                    {...props}
                    onChangeResource={(action, kitResource) =>
                      setKit({
                        ...kit,
                        kitResources:
                          action === 'delete'
                            ? kit.kitResources.filter((resource) => resource.id !== kitResource.id)
                            : action === 'add'
                            ? [kitResource, ...kit.kitResources]
                            : undefined,
                      })
                    }
                  />
                )}
                acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
              />
              <ProtectedRoute
                exact
                path={`${match.url}/borrow`}
                render={(props) => <BorrowKit kit={kit} {...props} />}
                acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
              />
            </Switch>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </BuildingLayout>
);

export const KitPage = enhance(KitPagePresentation);
