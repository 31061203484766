import React from 'react';
import { Media } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { FontAwesome, Barcode, PictureDisplay } from '@shared';
import { connect } from '@store';

const SimpleAssetDisplayPresentation = ({
  asset,
  showBarcode = true,
  showStatus = true,
  showAvailability = true,
  showLink = true,
  computedStates,
}) => {
  const className = showStatus ? `status-border status-border-${asset.item.active ? 'active' : 'inactive'}` : '';

  const computed = computedStates[asset.availability];

  return (
    <Media className="align-items-center">
      {showBarcode && (
        <Media left className={`mr-2 ${className}`}>
          <Barcode code={asset.barcode1} style={{ height: '2.5rem' }} />
        </Media>
      )}
      <Media body className={showBarcode ? '' : `pl-2 ${className}`}>
        {showLink && (
          <NavLink to={`/building/assets/${asset.barcode1}`}>
            <kbd>{asset.barcode1}</kbd>
            <FontAwesome name="search" className="ml-1" />
          </NavLink>
        )}

        {!showLink && <kbd>{asset.barcode1}</kbd>}

        <div className="mt-1 w-100 text-overflow">
          <span className="text-muted">
            <FontAwesome name="caret-right" />
            Identificador:{' '}
          </span>
          <strong>{asset.mark || asset.factorySerialNumber1 || asset.factorySerialNumber2 || ' -'}</strong>
        </div>
        {showAvailability && (
          <div className="mt-1 w-100 text-overflow">
            <span className="text-muted">
              <FontAwesome name="caret-right" />
              Disponibilidad:{' '}
            </span>
            {(computed && <span className={`text-${computed?.color}`}>{computed?.name}</span>) || (
              <span className="text-muted">-</span>
            )}
          </div>
        )}
      </Media>
    </Media>
  );
};

export const SimpleAssetDisplay = connect(({ appData: { computed_states: computedStates } }) => ({
  computedStates,
}))(SimpleAssetDisplayPresentation);

const AssetDisplay = ({
  asset,
  computedStates,
  barcode = false,
  showArea = false,
  status = true,
  comments = false,
  pictureWidth = '6rem',
  pictureHeight = '4rem',
  quantity = undefined,
}) => {
  const computed = computedStates[asset.availability];
  return (
    <Media className="align-items-center">
      <Media left className="mr-3">
        <PictureDisplay
          picture={asset.item.defaultPicture}
          pictureSize="icon"
          alt={asset.item.name}
          width={pictureWidth}
          height={pictureHeight}
        />
      </Media>
      <Media body>
        <div>{asset.item.name}</div>
        <div className="small mb-1">
          <NavLink to={`/building/assets/${asset.barcode1}`}>
            <kbd className="mr-1">{asset.barcode1}</kbd>
            <FontAwesome name="search" />
          </NavLink>
        </div>
        {status && (
          <div className="small">
            <span>
              Estado: <span className={`text-${computed?.color}`}>{computed?.name}</span>
            </span>
          </div>
        )}
        {asset.mark && (
          <div className="small">
            <span>
              Rótulo: <code>{asset.mark}</code>
            </span>
          </div>
        )}
        {quantity && (
          <div>
            <span className="small">Cantidad solicitada: {quantity}</span>
          </div>
        )}
        {comments && asset.comments && (
          <div className="small">
            <span>
              Palabras claves: <code>{asset.comments.join(', ')}</code>
            </span>
          </div>
        )}
      </Media>
      {showArea && (
        <Media body className="ml-3">
          <div className="small text-muted">Destino</div>
          <div>{asset.area.name}</div>
          <div className="small">{asset.area.location}</div>
        </Media>
      )}
      {barcode && (
        <Media className="ml-3">
          <Barcode code={asset.barcode1} style={{ height: '4rem' }} />
        </Media>
      )}
    </Media>
  );
};

export default connect(({ appData: { computed_states: computedStates } }) => ({
  computedStates,
}))(AssetDisplay);
