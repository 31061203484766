import { FontAwesome } from '@shared';
import { MovementsTable } from '@shared/movements';
import { connect, deskSessionGenerators } from '@store';
import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { branch, compose, renderComponent } from 'recompose';

const enhance = compose(
  connect(
    ({ deskSession: { movements, savingErrors: movementErrors } }) => ({
      movements,
      movementErrors,
    }),
    (dispatch) => ({
      removeMovement: (index) => dispatch(deskSessionGenerators.removeMovement(index)),
    }),
  ),
  branch(
    ({ movements }) => !movements || movements.length === 0,
    renderComponent(() => null),
  ),
);

const MovementsListPresentation = ({ movements, removeMovement, movementErrors }) => (
  <Card>
    <CardHeader>Resumen de movimientos</CardHeader>
    <CardBody>
      <div className="small mb-3">
        Movimientos en esta sesión: <strong>{movements.length}</strong>
      </div>
      <MovementsTable
        movements={movements}
        showInfo={false}
        showNumber={false}
        header={false}
        actions={[
          ({ index }) => (
            <FontAwesome name="times" role="button" className="text-danger" onClick={() => removeMovement(index)} />
          ),
        ]}
        errors={movementErrors}
      />
    </CardBody>
  </Card>
);

export const MovementsList = enhance(MovementsListPresentation);
