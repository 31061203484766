// import { PictureDisplay } from '@shared';
import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';

const KIND = { client: 'Cliente', supplier: 'Proveedor' };
export const PartnerHeader = ({ partner }) => (
  <Card className="mb-4">
    <CardBody>
      <Media className="align-items-center">
        {/* <Media left className="mr-3">
          <PictureDisplay picture={partner.defaultPicture} alt={partner.name} />
        </Media> */}
        <Media body>
          <h4>{partner.name}</h4>
          {partner.kind && (
            <div className="small">
              Tipo: <span className="text-muted">{KIND[partner.kind]}</span>
            </div>
          )}
        </Media>
      </Media>
    </CardBody>
  </Card>
);
