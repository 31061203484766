import { createUser } from '@core/building/users';
import { FontAwesome } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { branch, compose, withHandlers, withState } from 'recompose';
import { SubmissionError } from 'redux-form';
import { UserForm } from './components/UserForm';

const enhance = compose(
  connect(null, (dispatch) => ({
    createNotification: (formData) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Usuario creado',
          text: `La cuenta de ${formData.firstName} ${formData.lastName} ha sido creada exitosamente. Un correo será enviado para confirmar su cuenta y definir su contraseña.`,
          color: 'success',
          duration: 10000,
        }),
      ),
  })),
  withState('status', 'setStatus', 'waiting'),
  withHandlers({
    handleForm: ({ createNotification, setStatus }) => async (data) => {
      setStatus('loading');

      const { body, status } = await createUser(data);

      if (status === 201) {
        createNotification(body);
        setStatus('success');
      } else if (status === 422) {
        setStatus('waiting');
        throw new SubmissionError(body.errors);
      }
    },
  }),
  branch(
    ({ status }) => status === 'success',
    () => () => <Redirect to="/building/users" />,
  ),
);

const NewUserPresentation = ({ handleForm, status }) => (
  <BuildingLayout>
    <SimpleContainerLayout
      title={
        <span>
          <FontAwesome name="user" className="text-primary mr-2" /> Nuevo usuario
        </span>
      }
    >
      <Alert color="info">
        <div className="mb-3">
          Por favor completa todos los campos. Se creará una cuenta en Alliot Cloud para el usuario definido y se le
          agregará una membresía con el rol asignado en el campo <strong>Rol</strong>. Esta membresía puede ser cambiada
          en el futuro.
        </div>

        <div>
          Si el usuario ya tiene cuenta en Alliot Cloud, puedes agregarlo a la instalación mediante el editor de
          membresías.
        </div>
      </Alert>

      <UserForm loading={status === 'loading'} onSubmit={handleForm} />
    </SimpleContainerLayout>
  </BuildingLayout>
);

export const NewUser = enhance(NewUserPresentation);
