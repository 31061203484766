import { handleAuth, withToken, apiFetch, mountQuery } from './http';

export const getDeviceBrand = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/device_brands/${id}`,
      method: 'GET',
    }),
  );

export const getDeviceBrands = ({ query } = {}) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/device_brands${mountQuery(query)}`,
      method: 'GET',
    }),
  );
