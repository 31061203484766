import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';

import { BuildingRow } from './BuildingRow';

export const BuildingsResults = ({ buildings, buildingTypes, pagination }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong>{' '}
      {pluralize(pagination.count, 'instalación encontrada', 'instalaciones encontradas')}. Mostrando{' '}
      <strong>{pagination.per}</strong> instalaciones por página.
    </div>

    <Table>
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '120px' }} />
        <col style={{ width: '80px' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Organización</th>
          <th className="text-center">Dirección</th>
          <th className="text-center">Provincia</th>
          <th className="text-center">Estado</th>
          <th className="text-center">¿Activos?</th>
          <th className="text-center">¿SAP?</th>
          <th className="text-center">Tipo</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {buildings.map((building, index) => (
          <BuildingRow key={index} building={building} buildingTypes={buildingTypes} />
        ))}

        {buildings.length === 0 && (
          <tr>
            <td colSpan="4" className="text-center mt-3 mb-3">
              <em>No se encontraron instalaciones.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
