import React from 'react';
import { Button } from 'reactstrap';

import Spinner from './Spinner';

const ProcessingButton = ({ children, processing, width, disabled, ...props }) => (
  <Button role="button" disabled={processing || disabled} style={{ width }} {...props}>
    {processing ? <Spinner /> : children}
  </Button>
);

export default ProcessingButton;
