import './create-maintenance.scss';

import { createMaintenance } from '@core/building/asset-repairs';
import { FontAwesome } from '@shared';
import { AssetSelector, BuildingSelector, DateSelector, FileUploader, ProviderSelector } from '@shared/forms';
import { getBuilding, getBuildingProviders } from '@core/building/buildings';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import { addDays, setHours, setMinutes } from 'date-fns';
import { orderBy } from 'lodash';
import React from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { compose } from 'recompose';

import { MaintenanceAssetCard } from './components/MaintenanceAssetCard';
import { AVAILABLES_CURRENCIES } from '@shared/constants';

class CreateMaintenancePresentation extends React.PureComponent {
  modal;
  initialSteps = [
    {
      content: <b>Este recorrido te guiará a través de los pasos para iniciar un ciclo de reparación de activos.</b>,
      placement: 'center',
      target: 'body',
      hideCloseButton: true,
    },
    {
      content:
        'Primero, debes seleccionar tu proveedor de reparaciones, de esta forma podrás seleccionar los activos que deseas incluir en el ciclo de reparación.',
      placement: 'right',
      target: '.tour-supplier',
      hideCloseButton: true,
    },
    {
      content:
        'Este campo corresponde al número de orden de compra u orden de trabajo asociada al a la reparación. Es opcional.',
      placement: 'right',
      target: '.tour-buy-order',
      hideCloseButton: true,
    },
    {
      content: (
        <div className="text-left">
          Busca los activos que deseas enviar a reparación. Solo puedes iniciar un ciclo de reparación a activos que
          cumplan lo siguiente:
          <br />
          <br />
          <ul className="pl-4">
            <li>
              El artículo debe haber sido ingresado como <b>Reparable</b>.
            </li>
            <li>
              El activo debe estar <span className="text-success font-weight-bold">Almacenado</span>{' '}
            </li>
            <li>
              El activo debe tener asociado al menos un tag de localización (<b>GPS</b> o <b>Beacon</b>)
            </li>
          </ul>
        </div>
      ),
      placement: 'left',
      target: '#item-selector-reservation',
      hideCloseButton: true,
    },
    {
      content:
        'A continuación, selecciona la instalación destino de tu activo. Esta instalación corresponde al proveedor de reparaciones seleccionado en el primer paso.',
      placement: 'left',
      target: '.tour-supplier-building',
      hideCloseButton: true,
    },
    {
      content: 'Selecciona la fecha y hora límite que tiene tu proveedor para culminar la reparación.',
      placement: 'left',
      target: '.tour-lead-time',
      hideCloseButton: true,
    },
    {
      content: (
        <div>
          Haz click en <b>Añadir</b> para añadir el activo a la lista de activos que entrarán en el ciclo de reparación.
        </div>
      ),
      placement: 'left',
      target: '.tour-asset-actions',
      hideCloseButton: true,
    },
    {
      content: 'Una vez hayas seleccionado activos, podrás verlos acá e iniciar el ciclo de reparaciones.',
      placement: 'top',
      target: '.tour-empty-assets',
      hideCloseButton: true,
      locale: { next: 'Finalizar' },
    },
    {
      content: 'Acá se muestran los activos seleccionados, verifica que la información esté correcta antes de iniciar.',
      placement: 'top',
      target: '.tour-assets-list',
      hideCloseButton: true,
    },
    {
      content: (
        <div>
          Para iniciar el ciclo de reparación haz click en <b>Iniciar</b>
        </div>
      ),
      placement: 'top',
      target: '.tour-init-repair',
      hideCloseButton: true,
    },
  ];

  constructor(props) {
    super(props);

    // Parse target from URL
    const {
      location: { search },
    } = this.props;

    const query = new URLSearchParams(search);
    const bpmType = query.get('bpmType');
    const assetBarcode = query.get('of');

    this.state = {
      assets: {},
      modal: false,
      supplierId: null,
      type: bpmType || null,
      code: '',
      runTour: false,
      tourStepIndex: 0,
      tourSteps: this.initialSteps,
      asset: { barcode1: assetBarcode } || undefined,
    };
  }

  setModalRef = (element) => {
    this.modal = element;
  };

  setMaintenanceItemRef = (element) => {
    this.maintenanceItemElement = element;
  };

  setSupplier = (supplierId) => {
    if (!supplierId) {
      this.maintenanceItemElement.clear();
    }
    this.setState({ supplierId: supplierId, assets: supplierId ? this.state.assets : {} });
  };

  setCode = (event) => {
    this.setState({ code: event.target.value });
  };

  setType = (event) => {
    this.setState({ type: event.target.value });
  };

  addItem = (resAsset) => {
    this.setState({
      assets: {
        ...this.state.assets,
        [resAsset.asset.id]: resAsset,
      },
    });
  };

  removeItem = (id) => {
    const { ...copy } = this.state.assets;

    delete copy[id];

    this.setState({
      assets: copy,
    });
  };

  handleSave = () => {
    this.modal.open();
  };

  doSave = async () => {
    const { assets, code, supplierId, type } = this.state;
    const payload = {
      supplier_id: supplierId,
      code,
      [`assets_${type}`]: Object.values(assets).map(
        ({
          asset,
          building,
          leadTime,
          arrivalDate,
          committedDate,
          estimatedDate,
          price,
          currency,
          comments,
          attachmentsTokens,
        }) => {
          return {
            asset_id: asset.id,
            building_id: building.id,
            lead_time: leadTime,
            arrival_date: arrivalDate,
            committed_date: committedDate,
            estimated_date: estimatedDate,
            price,
            currency,
            comments,
            attachments_tokens: attachmentsTokens,
          };
        },
      ),
    };

    const { body, status } = await createMaintenance(payload);

    if (status === 201) {
      this.props.successNotification(body.code);
      this.props.history.push(`/mro/${type}s`);
    } else {
      this.props.errorNotification();
    }
  };

  handleClickStart = (e) => {
    e.preventDefault();
    this.setState({
      runTour: true,
      tourStepIndex: 0,
    });
  };

  handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;

    if ([EVENTS.STEP_AFTER].includes(type)) {
      const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

      if (stepIndex === 7) {
        if (action === ACTIONS.PREV) {
          this.setTourStep(stepIndex - 1);
        } else {
          const totalAssets = Object.values(this.state.assets).length;

          if (!!totalAssets) {
            this.setTourStep(stepIndex + 1);
          } else {
            this.setTourStep(stepIndex);
          }
        }
      } else if (stepIndex === 8) {
        if (action === ACTIONS.PREV) {
          this.setTourStep(stepIndex);
        } else {
          this.endTour();
        }
      } else {
        this.setTourStep(stepIndex);
      }
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.endTour();
    }
  };

  setTourStep = (stepIndex) => {
    this.setState({
      tourStepIndex: stepIndex,
    });
  };

  endTour = () => {
    this.setState({ runTour: false, tourStepIndex: 0 });
  };

  editAsset = () => {
    const { type, supplierId } = this.state;
    return type && supplierId;
  };

  render() {
    const { supplierId, assets, code, type, runTour, tourSteps, tourStepIndex, asset } = this.state;

    const maintenanceAssets = orderBy(Object.values(assets), 'asset.item.name');
    const total = maintenanceAssets.length;

    return (
      <BuildingLayout>
        <SimpleContainerLayout>
          <ReactJoyride
            callback={this.handleJoyrideCallback}
            continuous
            run={runTour}
            disableCloseOnEsc
            scrollToFirstStep
            showSkipButton
            steps={tourSteps}
            stepIndex={tourStepIndex}
            locale={{ skip: 'Omitir', next: 'Siguiente', back: 'Volver', last: 'Finalizar' }}
            styles={{
              options: {
                zIndex: 10000,
                primaryColor: '#11579c',
              },
            }}
          />
          <div className="align-items-center d-flex justify-content-center mb-4">
            <h4 className="mb-0">Iniciar Ciclo de MRO</h4>
            <Button onClick={this.handleClickStart} color="info" className="info-button">
              <FontAwesome name="question" />
            </Button>
          </div>
          <Form noValidate>
            <Row form>
              <Col xs="12" md="6">
                <Row className="maintenance-general-info">
                  <Col xs="12" className="mb-4">
                    <div className="repair-form-division">
                      <span className="outer-line" />
                      <span className="repair-division-content">
                        <FontAwesome name="file-alt" />
                      </span>
                      <span className="outer-line" />
                    </div>
                  </Col>
                  <Col xs="12">
                    <Row>
                      <Col xs="12">
                        <FormGroup className="tour-buy-order">
                          <Label>
                            <b>Tipo:</b>
                          </Label>
                          <Input type="select" name="type" onChange={this.setType} value={type}>
                            <option value=""></option>
                            <option value="maintenance">Mantenimiento</option>
                            <option value="repair">Reparación</option>
                            <option value="evaluation">Evaluación</option>
                            <option value="preservation">Preservación</option>
                            <option value="certification">Certificación</option>
                            <option value="assurance">Garantía</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <FormGroup className="tour-supplier">
                          <Label>
                            <b>Proveedor</b>
                          </Label>
                          <ProviderSelector
                            value={supplierId}
                            input={{ onChange: this.setSupplier }}
                            filters={{ active: true }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12">
                        <FormGroup className="tour-buy-order">
                          <Label>
                            <b>OC/OT</b>
                          </Label>
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <Button>
                                <FontAwesome name="file-alt" />
                              </Button>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              name="code"
                              placeholder="Número de orden de compra"
                              value={code}
                              onChange={this.setCode}
                            />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs="12" md="6">
                <Row>
                  <Col xs="12" className="mb-4">
                    <div className="repair-form-division">
                      <span className="outer-line" />
                      <span className="repair-division-content">
                        <FontAwesome className="repair-division-icon" name="wrench" />
                      </span>
                      <span className="outer-line" />
                    </div>
                  </Col>
                  <Col xs="12" className="maintenance-item-content">
                    <MaintenanceItem
                      ref={this.setMaintenanceItemRef}
                      onSave={this.addItem}
                      supplierId={supplierId}
                      edit={this.editAsset()}
                      asset={asset}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Row className="mt-4">
            <Col xs="12">
              <Row>
                <Col xs="12">
                  <div className="repair-form-division">
                    <span className="outer-line" />
                    <span className="repair-division-content">
                      <FontAwesome className="repair-division-icon" name="ambulance" />
                    </span>
                    <span className="outer-line" />
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col xs="12">
                  {maintenanceAssets.length === 0 && (
                    <div className="text-center">
                      <div className="tour-empty-assets">
                        <FontAwesome name="exclamation-triangle" /> <em>No hay activos seleccionados.</em>
                      </div>
                    </div>
                  )}
                  {maintenanceAssets && maintenanceAssets.length > 0 && (
                    <div>
                      <div className="mb-2">
                        <span>
                          Total de activos: <b>{total}</b>
                        </span>
                      </div>
                      <ListGroup flush className="tour-assets-list">
                        {maintenanceAssets.map((resItem, index) => {
                          const { asset, building, leadTime, price, currency, comments, attachmentsTokens } = resItem;
                          return (
                            <ListGroupItem key={index} className="asset-list-group">
                              <div className="asset-card-content">
                                <MaintenanceAssetCard
                                  asset={asset}
                                  building={building}
                                  leadTime={leadTime}
                                  price={price}
                                  currency={currency}
                                  comments={comments}
                                  attachmentsTokens={attachmentsTokens}
                                />
                              </div>
                              <div className="ml-3">
                                <Button role="button" color="danger" onClick={() => this.removeItem(asset.id)}>
                                  <FontAwesome name="times" />
                                </Button>
                              </div>
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                      <div className="mt-4 tour-init-repair">
                        <Button
                          role="button"
                          color="success"
                          onClick={this.handleSave}
                          disabled={assets.length === 0}
                          block
                        >
                          <FontAwesome name="check" /> Iniciar
                        </Button>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalConfirmation ref={this.setModalRef} onSave={this.doSave} />
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

class MaintenanceItem extends React.Component {
  initialState = {
    supplierId: undefined,
    asset: undefined,
    building: undefined,
    leadTime: undefined,
    arrivalDate: undefined,
    committedDate: undefined,
    estimatedDate: undefined,
    price: '',
    currency: undefined,
    minLeadTime: undefined,
    comments: '',
    attachmentsTokens: [],
    fileUploading: false,
  };

  constructor(props) {
    super(props);

    let minLeadTime = setHours(setMinutes(addDays(new Date(), 0), 0), 0);

    this.initialState.minLeadTime = minLeadTime;
    this.initialState.leadTime = minLeadTime;
    this.initialState.asset = this.props.asset;
    this.state = this.initialState;
  }

  setFileUploader = (el) => {
    this.fileUploaderElement = el;
  };

  setAsset = (barcode, asset) => {
    this.setState({
      asset,
    });
  };

  setBuilding = (buildingId, building) => {
    this.setState({
      building,
    });
  };

  setLeadTime = (date) => {
    this.setState({
      leadTime: date,
    });
  };

  setCommittedDate = (date) => {
    this.setState({
      committedDate: date,
    });
  };

  setArrivalDate = (date) => {
    this.setState({
      arrivalDate: date,
    });
  };

  setEstimatedDate = (date) => {
    this.setState({
      estimatedDate: date,
    });
  };

  setPrice = (price) => {
    this.setState({
      price: price.target.value,
      currency: price.target.value && !this.state.currency ? 'CLP' : this.state.currency,
    });
  };

  setCurrency = (currency) => {
    this.setState({
      currency: currency.target.value,
    });
  };

  setComments = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  setAttachments = (attachments) => {
    this.setState({
      attachmentsTokens: attachments,
    });
  };

  handleSave = () => {
    this.props.onSave({ ...this.state });
    this.clear();
  };

  clear = () => {
    const el = document.querySelector('#item-selector-reservation .fa.fa-times');

    if (el) {
      el.click();
    }

    this.setState(this.initialState);
    this.fileUploaderElement.cleanAll();
  };

  render() {
    const {
      asset,
      building,
      leadTime,
      arrivalDate,
      committedDate,
      estimatedDate,
      minLeadTime,
      price,
      currency,
      comments,
      attachmentsTokens,
      fileUploading,
    } = this.state;
    const { supplierId, edit } = this.props;

    return (
      <div>
        <FormGroup id="item-selector-reservation">
          <Label>
            <b>Activo</b>
          </Label>
          <AssetSelector
            filters={{
              repairable: true,
              states: ['stored'],
              health_states: ['new', 'damaged', 'repaired', 'unknown', 'with_observations'],
              active: true,
            }}
            value={asset ? asset.barcode1 : null}
            input={{
              onChange: this.setAsset,
            }}
            readonly={!edit}
          />
        </FormGroup>
        <FormGroup className="tour-supplier-building">
          <Label>
            <b>Instalación de destino</b>
          </Label>
          <BuildingSelector
            filters={{ organizations: supplierId }}
            value={building ? building.id : null}
            input={{ onChange: this.setBuilding }}
            readonly={!edit}
            remoteFinder={getBuildingProviders}
            remoteLoader={getBuilding}
          />
        </FormGroup>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Lead time</b>
          </Label>
          <DateSelector
            selected={leadTime}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={this.setLeadTime}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            disabled={!edit}
          />
        </FormGroup>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Fecha de llegada</b>
          </Label>
          <DateSelector
            selected={arrivalDate}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={this.setArrivalDate}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            disabled={!edit}
          />
        </FormGroup>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Fecha comprometida</b>
          </Label>
          <DateSelector
            selected={committedDate}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={this.setCommittedDate}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            disabled={!edit}
          />
        </FormGroup>
        <FormGroup className="tour-lead-time">
          <Label>
            <b>Fecha estimada de entrega</b>
          </Label>
          <DateSelector
            selected={estimatedDate}
            selectsEnd
            minTime={minLeadTime}
            maxTime={setHours(setMinutes(new Date(), 30), 23)}
            minDate={minLeadTime}
            onChange={this.setEstimatedDate}
            shouldCloseOnSelect={false}
            popperModifiers={{
              preventOverflow: {
                escapeWithReference: false,
                boundariesElement: 'viewport',
              },
            }}
            disabled={!edit}
          />
        </FormGroup>
        <Row>
          <Col md="8">
            <FormGroup className="tour-buy-order">
              <Label>
                <b>Costo del MRO</b>
              </Label>
              <Input
                type="text"
                name="price"
                placeholder="Precio del Procedimiento"
                onChange={this.setPrice}
                value={price}
                disabled={!edit}
              />
            </FormGroup>
          </Col>
          {price && (
            <Col md="4">
              <FormGroup className="tour-buy-order">
                <Label>
                  <b>Tipo de moneda</b>
                </Label>
                <Input type="select" name="currency" onChange={this.setCurrency} value={currency} disabled={!edit}>
                  {AVAILABLES_CURRENCIES.map((c, k) => (
                    <option key={`currency-${k}`} value={c}>
                      {c}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
          )}
        </Row>
        <FormGroup>
          <Label>
            <b>Comentarios</b>
          </Label>
          <Input
            type="textarea"
            rows={5}
            name="comments"
            placeholder="Cualquier comentario que pueda aportar antecedentes adicionales respecto al inicio del ciclo."
            onChange={this.setComments}
            value={comments}
            disabled={!edit}
          />
        </FormGroup>
        <FormGroup>
          <Label>
            <b>Archivos</b>
          </Label>
          <FileUploader
            ref={this.setFileUploader}
            multiple={true}
            token={attachmentsTokens}
            onChange={this.setAttachments}
            endpoint={(token) => `/attachments/${token}`}
            disabled={!edit}
          />
        </FormGroup>
        <FormGroup className="tour-asset-actions">
          <Button
            role="button"
            color="success"
            onClick={this.handleSave}
            className="mr-2"
            disabled={!asset || !building || !leadTime || fileUploading}
          >
            Añadir
          </Button>
          <Button role="button" onClick={this.clear} disabled={fileUploading}>
            Limpiar
          </Button>
        </FormGroup>
      </div>
    );
  }
}

class ModalConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  open = () => {
    this.setState({
      open: true,
    });
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  save = () => {
    this.close();
    this.props.onSave();
  };

  render() {
    const { open } = this.state;
    return (
      <div>
        <Modal isOpen={open} size="md">
          <ModalHeader>Confirmación</ModalHeader>
          <ModalBody>
            <p>¿Está seguro que desea iniciar un ciclo de reparaciones para los activos seleccionados?</p>
          </ModalBody>
          <ModalFooter className="justify-content-end">
            <Button color="danger" onClick={this.close}>
              Cancelar
            </Button>
            <Button className="ml-2" color="primary" onClick={this.save}>
              Si
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const enhance = compose(
  connect(undefined, (dispatch) => ({
    successNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'MRO ingresado',
          text: 'Se han enviado correctamente a MRO los activos seleccionados',
          color: 'success',
        }),
      ),
    errorNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Error',
          text: 'Ocurrió un problema al intentar enviar a MRO.',
          color: 'danger',
        }),
      ),
  })),
  connect(({ session }) => {
    let organizationId = undefined;

    if (session.currentMembership && session.user) {
      const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

      if (membership) organizationId = membership.building.organization.id;
    }

    return { currentOrganizationId: organizationId };
  }),
);

export const CreateMaintenance = enhance(CreateMaintenancePresentation);
