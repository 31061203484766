import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const ReassemblyModal = ({ modal, toggle, reassemble }) => (
  <Modal isOpen={modal} toggle={toggle} size="md">
    <ModalHeader toggle={toggle}>¿Realmente desea reensamblar el activo?</ModalHeader>
    <ModalBody>
      <p className="text-danger">
        <strong>¡Importante!:</strong> Los códigos y las etiquetas asociadas seran reemplazadas, por lo cual es
        necesario prescindir de los elementos que contiene el activo antes de reensamblar.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button role="button" color="primary" onClick={reassemble}>
        Reensamblar
      </Button>
      <Button role="button" onClick={toggle}>
        Cerrar
      </Button>
    </ModalFooter>
  </Modal>
);
