import React from 'react';
import { Media, Card, CardBody, CardImg, CardTitle } from 'reactstrap';
import { PictureDisplay } from '@shared';
import noImage from './no-image.jpg';

const InfoDisplay = ({ area, picture }) => (
  <Card>
    <CardImg top width="100%" src={(picture && picture.large.url) || noImage} />
    <CardBody style={{ overflowWrap: 'break-word' }}>
      <CardTitle>{area.name}</CardTitle>
    </CardBody>
    <CardBody>
      <Media>
        <Media left className="mr-3">
          <PictureDisplay picture={area.picture} pictureSize="icon" alt={area.name} width="3rem" height="4rem" />
        </Media>
        <Media body>
          <div className="small text-muted">Area</div>
          <div className="small ">{area.name}</div>
          <div className="small ">{area.description}</div>
        </Media>
      </Media>
    </CardBody>
  </Card>
);

export default InfoDisplay;
