import React from 'react';

export const MassImportInstructions = () => (
  <ul>
    <li className="mb-2">
      Solo se permiten archivos <code>csv</code>. Usted puede guardar planillas Excel fácilmente con este formato a
      partir de la opción <code>Guardar como...</code>.
    </li>
    <li className="mb-2">
      Se recomienda que genere una planilla sencilla para crear el archivo CSV, sin formato ni columnas o filas extras.
    </li>
    <li className="mb-2">
      La primera fila del archivo CSV debe contener los nombres de las columnas asociadas. Estas pueden anotarse en el
      orden deseado.
    </li>
    <li className="mb-2">
      Las columnas a indicar son las siguientes:
      <ol className="mt-2">
        <li className="mb-2">
          <code>first_name</code>: Nombres del usuario.
        </li>
        <li className="mb-2">
          <code>last_name</code>: Apellidos del usuario.
        </li>
        <li className="mb-2">
          <code>identifier_value</code>: Identificador del usuario. De momento, es exclusivamente el RUT. Se recomienda
          ingresarlo sin puntos ni guiones, a pesar de que también es acepta el formato con puntos y guiones y el
          formato con guión. <strong>No pueden existir dos usuarios con el mismo RUT.</strong>
        </li>
        <li className="mb-2">
          <code>identifier_type</code>: El tipo de identificador. En un futuro aceptaremos pasaportes y otro tipo de
          documentos además de RUT. Por el momento, rellenar para cada usuario con <code>rut</code>.
        </li>
        <li className="mb-2">
          <code>email</code>: Dirección de correo. Utilizada para iniciar sesión. No pueden exitir dos usuarios con la
          misma cuenta.
        </li>
        <li className="mb-2">
          <code>password</code>: Contraseña utilizada para iniciar sesión por el usuario. Se recomienda ingresar una
          distinta para cada usuario.
        </li>
        <li className="mb-2">
          <code>phone</code>: Teléfono del usuario. No debe incluir espacios, y debe comenzar con <code>+56</code>.
        </li>
      </ol>
      Cualquier otra columna será ignorada automáticamente.
    </li>
    <li className="mb-2">Todas las columnas especificadas deben indicar un valor válido.</li>
    <li className="mb-2">
      Si alguna fila presenta un error en sus valores, no se creará ningun usuario. El archivo deberá ser cargado
      nuevamente.
    </li>
    <li className="mb-2">
      <strong>
        Los usuarios creados por este medio no recibirán correo de invitación y serán marcados automáticamente como
        activos.
      </strong>
    </li>
  </ul>
);
