import React from 'react';
import { Media } from 'reactstrap';

import { getProvider, getProviders } from '@core/building/providers';
import { RemoteSelector } from '@core/forms';
import { ProviderCard } from '../index';

const ProviderItem = ({ resource: provider }) => (
  <Media className="align-items-center">
    <Media left className="mr-2">
      <ProviderCard height="2rem" width="3rem" url={provider.avatar.icon.url} alt={provider.name} />
    </Media>
    <Media body>
      <div>
        <strong>{provider.name}</strong>
      </div>
    </Media>
  </Media>
);

const ProviderSelector = ({ filters, inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getProvider}
    remoteFinder={(query) => getProviders({ name: query, ...filters })}
    dropdownTemplate={ProviderItem}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre',
    }}
  />
);

export default ProviderSelector;
