import React, { Component } from 'react';
import { getAreas } from '../../@core/building/areas';
import { massImportItems } from '../../@core/building/items';
import Validates, { oneOf, presence } from '../../@core/forms/validations';
import { getUnits } from '../../@core/units';
import GenericMassImport from '../../@shared/mass-import';
import { MassImportInstructions } from './components/MassImportInstructions';

import type { ColumnMetadata } from '../../@shared';

const ITEM_TYPES = {
  DEFAULT: '0',
  CONSUMABLE: '1',
  RESTRICTED: '2',
};

const columnMetadata: ColumnMetadata[] = [
  {
    columnName: 'name',
    displayName: 'Nombre',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'name' })),
    style: {
      width: 'auto',
    },
  },
  {
    columnName: 'internal_sku',
    displayName: 'SKU Interno',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'internal_sku' })),
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'external_sku',
    displayName: 'SKU Externo',
    editable: { type: 'text' },
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'type',
    displayName: 'Tipo',
    editable: {
      type: 'select',
      props: {
        options: [
          { value: ITEM_TYPES.DEFAULT, text: 'Por Defecto' },
          { value: ITEM_TYPES.CONSUMABLE, text: 'Consumible' },
          { value: ITEM_TYPES.RESTRICTED, text: 'Restringido' },
        ],
      },
    },
    validate: Validates(
      presence({ of: 'type' }),
      oneOf({
        of: 'type',
        alternatives: [ITEM_TYPES.DEFAULT, ITEM_TYPES.CONSUMABLE, ITEM_TYPES.RESTRICTED],
      }),
    ),
    showInResults: false,
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'area_id',
    displayName: 'Área',
    // editable and validate: --> Check componentDidMount
    showInResults: false,
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'unit_id',
    displayName: 'Unidad',
    // editable and validate: --> Check componentDidMount
    showInResults: false,
    style: {
      width: '12%',
    },
  },
];

const tableConfig = {
  columnMetadata: columnMetadata,
  trackBy: '_id',
};

const headerMap = {
  name: ({ name }) => name,
  internal_sku: ({ internal_sku }) => internal_sku,
  external_sku: ({ external_sku }) => external_sku,
  // eslint-disable-next-line eqeqeq
  consumable: ({ type }) => type === ITEM_TYPES.CONSUMABLE,
  restricted: ({ type }) => type === ITEM_TYPES.RESTRICTED,
  area_id: ({ area_id }) => area_id,
  unit_id: ({ unit_id }) => unit_id,
};

export class ItemsMassImport extends Component<*, *> {
  async componentDidMount() {
    const { status: areaStatus, body: areas } = await getAreas({ paginate: false });
    const { status: unitStatus, body: units } = await getUnits();

    let areaOpts = [];
    let unitOpts = [];

    if (areaStatus === 200) {
      areaOpts = this.convertToOptions(areas);
    }

    if (unitStatus === 200) {
      unitOpts = this.convertToOptions(units);
    }

    tableConfig.columnMetadata[4] = {
      ...tableConfig.columnMetadata[4],
      editable: {
        type: 'select',
        props: {
          options: areaOpts,
        },
      },
      validate: Validates(
        oneOf({
          of: 'area_id',
          if: ({ type }) => type === ITEM_TYPES.CONSUMABLE,
          alternatives: areaOpts.map((o) => o.value),
        }),
      ),
    };

    tableConfig.columnMetadata[5] = {
      ...tableConfig.columnMetadata[5],
      editable: {
        type: 'select',
        props: {
          options: [{ text: 'Seleccione...' }, ...unitOpts],
        },
      },
      validate: Validates(
        oneOf({
          of: 'unit_id',
          if: ({ type }) => type === ITEM_TYPES.CONSUMABLE,
          alternatives: ['Seleccione...', ...unitOpts.map((o) => o.value)],
        }),
      ),
    };
  }

  convertToOptions(elements: Object[]) {
    return elements.map(({ id, name }) => ({
      value: `${id}`,
      text: name,
    }));
  }

  render() {
    return (
      <GenericMassImport
        entity="Artículos"
        instructions={MassImportInstructions}
        valueMapping={headerMap}
        request={massImportItems}
        tableConfig={tableConfig}
      />
    );
  }
}
