import UserFinder from '@shared/forms/UserFinder';
import ItemSelector from '@shared/forms/ItemSelector';
import { SimpleContainerLayout } from '@shared/layouts';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Label, Input, Button, ButtonGroup, Alert } from 'reactstrap';
import { FileUploader } from '@shared/forms';
import { getItem } from '@core/building/items';
import { createPicking } from '@core/building/movement-sets';
import { useHistory } from 'react-router-dom';
import { FontAwesome } from '@shared/index';
import { getRestrictedItems } from '@core/building/restricted-items';

export function CreatePicking() {
  const [buyerId, setBuyerId] = useState(null);
  const [requestNumber, setRequestNumber] = useState('');
  const [items, setItems] = useState([]);
  const [addingItem, setAddingItem] = useState(true);
  const [duplicate, setDuplicate] = useState(false);
  const [restricted, setRestricted] = useState(false);
  const [quantityIsZero, setQuantityIsZero] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [restrictedItems, setRestrictedItems] = useState([]);
  const userId = useSelector((state) => state.session.user.id);
  const history = useHistory();

  const setClient = async (id) => {
    setBuyerId(id);
    setRestricted(false);
    setDuplicate(false);
    // consultar membresías
    if (id) {
      const { body } = await getRestrictedItems(id);
      setRestrictedItems(body);
    }
  };

  const setItem = async (value) => {
    if (buyerId) {
      const { body } = await getRestrictedItems(buyerId);
      setRestrictedItems(body);
    }
    const isDuplicate = checkDuplicates(value);
    setDuplicate(isDuplicate);
    const isRestricted = checkRestricted(value);
    setRestricted(isRestricted);
    if (!isDuplicate && !isRestricted) {
      const { body } = await getItem(value);

      setItems([...items, { ...body, quantity: 0 }]);
      setAddingItem(false);
    } else {
      setAddingItem(false);
    }
  };

  const addAttachment = (value) => {
    setAttachments(value);
  };

  const addNewItem = () => {
    setDuplicate(false);
    setRestricted(false);
    setAddingItem(true);
  };

  const increaseItem = (sentItem) => {
    setItems(
      items.map((item) =>
        item.barcode1 === sentItem.barcode1 ? { ...item, quantity: item.quantity + 1 } : { ...item },
      ),
    );
    const someZero = items.filter((item) => item.quantity <= 0 && item.barcode1 !== sentItem.barcode1);
    setQuantityIsZero(someZero.length > 0);
  };

  const decreaseItem = (sentItem) => {
    setItems(
      items.map((item) =>
        item.barcode1 === sentItem.barcode1
          ? { ...item, quantity: item.quantity === 0 ? 0 : item.quantity - 1 }
          : { ...item },
      ),
    );
    const someZero = items.filter((item) => item.quantity <= 0 && item.barcode1 !== sentItem.barcode1);
    setQuantityIsZero(someZero.length > 0);
  };

  const submit = async () => {
    const someZero = items.filter((item) => item.quantity <= 0);
    setQuantityIsZero(someZero.length > 0);
    if (someZero.length === 0) {
      const itemsValid = items.filter((item) => item.inventory.availableQuantity > 0);
      const payload = {
        requestNumber,
        buyerId,
        userId,
        attachments,
        picking: itemsValid.map((item) => ({ barcode: item.barcode1, quantity: item.quantity })),
      };
      const { body } = await createPicking(payload);
      if (body.status === 'processing') {
        history.push('/pickings');
      }
    }
  };

  const checkDuplicates = (value) => {
    return items.some((item) => item.barcode1 === value);
  };

  const checkRestricted = (value, quantity = 0) => {
    return (
      restrictedItems.length > 0 &&
      restrictedItems.some(
        (restrictedItem) =>
          restrictedItem.item.barcode1 === value &&
          (restrictedItem.quantity <= restrictedItem.delivered ||
            restrictedItem.quantity < restrictedItem.delivered + Number(quantity)),
      )
    );
  };

  const checkRestrictedItems = () => {
    return (
      restrictedItems.length > 0 &&
      items.length > 0 &&
      items.some((item) =>
        restrictedItems.some(
          (restrictedItem) =>
            restrictedItem.item.barcode1 === item.barcode1 &&
            (restrictedItem.quantity <= restrictedItem.delivered ||
              restrictedItem.quantity < restrictedItem.delivered + Number(item.quantity)),
        ),
      )
    );
  };

  const deleteItem = (sentItem) => {
    setItems(items.filter((item) => item.barcode1 !== sentItem.barcode1));
    setDuplicate(false);
    const someZero = items.filter((item) => item.quantity <= 0 && item.barcode1 !== sentItem.barcode1);
    setQuantityIsZero(someZero.length > 0);
  };

  return (
    <SimpleContainerLayout title="Ingreso manual de Picking">
      <Row>
        <Col xl={{ size: 6, offset: 3 }}>
          <Label>Cliente:</Label>
          <UserFinder value={buyerId} building={true} input={{ onChange: setClient }} />
        </Col>

        <Col xl={{ size: 6, offset: 3 }} className="mt-2">
          <Label>Número de Solicitud:</Label>
          <Input
            type="text"
            placeholder="Ingrese nro de solicitud"
            onChange={(value) => setRequestNumber(value.target.value)}
          />
        </Col>

        <Col xl={{ size: 6, offset: 3 }} className="mt-2">
          <Label>Artículo:</Label>
          {addingItem && <ItemSelector input={{ onChange: setItem }} readonly={!buyerId} />}
          {!addingItem && (
            <div onClick={addNewItem}>
              <Button className="btn btn-success">Agregar Nuevo Artículo</Button>
            </div>
          )}
          {duplicate && (
            <Alert color="warning" className="text-center mt-3 small">
              El artículo ya se encuentra en la lista
            </Alert>
          )}
          {restricted && (
            <Alert color="danger" className="text-center mt-3 small">
              El artículo está Restringido para este cliente, si considera que hay un error, comunicarse con el
              administrador
            </Alert>
          )}
        </Col>

        <Col xl={{ size: 6, offset: 3 }} className="mt-2">
          {items.length > 0 && (
            <Row className="mt-3">
              <Col xl={{ size: 12 }}>
                <Row className="mb-3">
                  <Col xs={{ size: 1 }}></Col>
                  <Col xs={{ size: 6 }}>Nombre</Col>
                  <Col xs={{ size: 2 }}>Disponible</Col>
                  <Col xs={{ size: 3 }}>Cantidad</Col>
                </Row>
              </Col>
            </Row>
          )}
          {items.map((item, index) => (
            <Row className="mt-3" key={`items-picking-${index}`}>
              <Col xs={{ size: 1 }}>
                <Button size="sm" onClick={() => deleteItem(item)} color="danger">
                  <span className="small">
                    <FontAwesome name="times" />
                  </span>
                </Button>
              </Col>
              <Col xs={{ size: 6 }}>
                <span className="small">{item.name}</span>
              </Col>
              <Col xs={{ size: 2 }} className="text-center">
                <span className="small">{item.inventory.availableQuantity}</span>{' '}
              </Col>
              <Col xs={{ size: 3 }} className="mt-1">
                <ButtonGroup>
                  <Button onClick={() => decreaseItem(item)}>-</Button>
                  <Button>{item.quantity}</Button>
                  <Button
                    onClick={() => increaseItem(item)}
                    disabled={item?.inventory?.availableQuantity <= item.quantity}
                  >
                    +
                  </Button>
                </ButtonGroup>
              </Col>

              <Col xl={{ size: 12 }}>
                {item?.inventory?.availableQuantity <= 0 && (
                  <Alert color="danger" className="text-center mt-3 small">
                    El artículo no tiene disponibilidad por lo que no será procesado. Prefiera eliminarlo de la lista y
                    comunique a su administrador.
                  </Alert>
                )}
              </Col>
              <Col xl={{ size: 12 }}>
                {checkRestricted(item?.barcode1, item?.quantity) && (
                  <Alert color="danger" className="text-center mt-3 small">
                    El artículo está Restringido para este cliente, si considera que hay un error, comunicarse con el
                    administrador
                  </Alert>
                )}
              </Col>
            </Row>
          ))}
          <Col xl={{ size: 12 }}>
            {quantityIsZero && (
              <Alert color="danger" className="text-center mt-3 small">
                Uno o más articulos tienen cantidad cero (0). Debe ser mayor o igual a uno (1)
              </Alert>
            )}
          </Col>
        </Col>

        <Col xl={{ size: 6, offset: 3 }} className="mt-2">
          <Label>Adjuntos:</Label>
          <FileUploader multiple={true} onChange={(value) => addAttachment(value)} />
        </Col>
      </Row>
      <Row>
        <Col xl={{ size: 3, offset: 8 }} className="mt-2">
          <Button
            className="btn btn-info"
            onClick={submit}
            disabled={items.length === 0 || quantityIsZero || !buyerId || !requestNumber || checkRestrictedItems()}
          >
            Ingresar Orden
          </Button>
        </Col>
      </Row>
    </SimpleContainerLayout>
  );
}
