import { apiFetch, handleAuth, mountQuery, withToken } from '../http';

export const getAdminAttachments = ({ resourceId, resourceType, ...query }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/${resourceType}/${resourceId}/attachments${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createAdminAttachment = ({ resourceId, resourceType, token, title, description, showSummary }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/${resourceType}/${resourceId}/attachments`,
      method: 'POST',
      body: {
        token,
        title,
        description,
        showSummary,
      },
    }),
  );

export const updateAdminAttachment = ({ resourceId, resourceType, token, title, description }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/${resourceType}/${resourceId}/attachments/${token}`,
      method: 'PUT',
      body: {
        title,
        description,
      },
    }),
  );

export const deleteAdminAttachment = ({ resourceId, resourceType, token }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/${resourceType}/${resourceId}/attachments/${token}`,
      method: 'DELETE',
    }),
  );
