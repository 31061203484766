import { FontAwesome } from '@shared/';
import React from 'react';
import { Button, Media } from 'reactstrap';

export const EventAttachment = ({ attachment }) => (
  <Media className="align-middle small">
    {attachment && (
      <Media body>
        {attachment.title && (
          <div className="w-100 text-overflow mt-1">
            <span className="text-muted">
              <FontAwesome name="paperclip" color="info" className="mr-1" />
            </span>
            <span className="asset-name font-weight-bold">{attachment.title}</span>
          </div>
        )}
        {attachment.title && (
          <div className="w-100 text-overflow mt-1 mb-1">
            <span className="text-muted">
              <FontAwesome name="pen" color="info" className="mr-1" />
            </span>
            <span>{attachment.title}</span>
          </div>
        )}
        {attachment.file && (
          <div className="w-100 text-overflow mt-1 mb-1">
            <span className="text-muted">
              <FontAwesome name="download" color="info" className="mr-1" />
            </span>
            <span>
              <Button role="button" color="primary" size="sm" tag="a" href={attachment.file.url} target="_blank">
                Descargar
              </Button>
            </span>
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventAttachmentModal = ({ attachment }) => (
  <div>
    <Media>
      {attachment && (
        <Media body>
          {attachment.title && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="tag" color="info" className="mr-1" />
                Titulo:
              </span>
              <span className="asset-name font-weight-bold">{attachment.title}</span>
            </div>
          )}
          {attachment.title && (
            <div className="w-100 text-overflow mt-1 mb-1">
              <span className="text-muted mr-1">
                <FontAwesome name="pen" color="info" className="mr-1" />
                Descripción:
              </span>
              <span>{attachment.title}</span>
            </div>
          )}
          {attachment.file && (
            <div className="w-100 text-overflow mt-1 mb-1">
              <span className="text-muted">
                <FontAwesome name="download" color="info" className="mr-1" />
              </span>
              <span>
                <Button role="button" color="primary" size="sm" tag="a" href={attachment.file.url} target="_blank">
                  Descargar
                </Button>
              </span>
            </div>
          )}
        </Media>
      )}
    </Media>
  </div>
);
