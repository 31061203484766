import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, simpleFilterQuery } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';
import { ReadoutMetadata } from './ReadoutMetadata';

export const ReadoutRow = ({ readout }) => (
  <tr>
    <th scope="row" className="align-items-center ml-1">
      {readout.asset && (
        <div style={{ width: '13.5rem' }}>
          <div className="small text-muted">Activo involucrado</div>
          <NavLink to={`/building/assets/${readout.asset.barcode1}`}>
            <kbd className="small">{readout.asset.barcode1}</kbd>
            <FontAwesome name="search" className="ml-1" />
          </NavLink>
          <div className="small mt-1">
            <div className="w-100 text-overflow">
              <span className="text-muted mr-1">
                <FontAwesome name="caret-right" className="mr-1" />
                Artículo:
              </span>
              {readout.itemName}
            </div>
          </div>
        </div>
      )}
    </th>
    <td className="align-middle small text-center">
      <Media body tag="div">
        <div>
          {readout.metadata.origin && readout.metadata.origin !== 'null' ? (
            <NavLink
              to={`/building/tags?q=${simpleFilterQuery({
                identifier: readout.metadata.origin,
              })}`}
            >
              {readout.metadata.origin}
            </NavLink>
          ) : (
            <span className="text-warning">
              <strong>Desconocido</strong>
            </span>
          )}
        </div>
        <div>{fromIso8601(readout.readAt)}</div>
      </Media>
    </td>
    <td className="align-middle small text-center">
      <ReadoutMetadata metadata={readout.metadata} />
    </td>
  </tr>
);
