import { ProtectedRoute, Route } from '@core/routes';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { DeskSessionWarning, Navigation, NotFound, Notifications, RuntimeManager } from '@shared';
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import AccountRoutes from './account';
import { AdminRoutes } from './admin';
import BuildingRoutes from './building';
import Home from './Home';
import MaintenancesRoutes from './maintenances';
import MapRoutes from './map';
import { MovementsRoutes } from './movements';
import { OrganizationRoutes } from './organization';
import { PickingsRoutes } from './pickings';

library.add(fab, fas, far);

class App extends Component {
  render() {
    return (
      <div>
        <Notifications />
        <Router>
          <div>
            <RuntimeManager />
            <Navigation />
            <DeskSessionWarning />
            <Switch>
              <ProtectedRoute exact path="/" component={Home} />
              <Route path="/movements" component={MovementsRoutes} />
              <Route path="/pickings" component={PickingsRoutes} />
              <Route path="/organization" component={OrganizationRoutes} />
              <Route path="/mro" component={MaintenancesRoutes} />
              <Route path="/account" component={AccountRoutes} />
              <Route path="/admin" component={AdminRoutes} />
              <Route path="/building" component={BuildingRoutes} />
              <Route path="/map" component={MapRoutes} />
              <Route path="/admin/routes" component={AdminRoutes} />

              {/* This handles 404s */}
              <Route component={NotFound} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
