import React from 'react';
import { Col, Row } from 'reactstrap';

export const CostAccountSummary = ({ costAccount }) => (
  <div className="mb-4">
    <Row>
      <Col sm={12} md={6} className="mb-4">
        <h5 className="mb-3">Información del centro de costos </h5>

        <table className="w-100">
          <colgroup>
            <col style={{ width: '35%' }} />
            <col style={{ width: '75%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td className="text-right align-top pr-3">Código</td>
              <td className="align-top text-muted">{costAccount.code}</td>
            </tr>
            <tr>
              <td className="text-right align-top pr-3">Nombre</td>
              <td className="align-top text-muted">{costAccount.name}</td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col sm={12} md={6} className="mb-4">
        <h5 className="mb-4">Información adicional</h5>

        <Row>
          <Col xs={12} md={8}>
            <h6 className="mb-2">Descripción</h6>
            <div className="mb-4 small text-muted">{costAccount.description || <em>No especificada.</em>}</div>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);
