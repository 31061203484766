import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { saveAs } from 'file-saver';
import { FontAwesome, Spinner } from '@shared';
import { parseQuery } from '@shared/simple-filter/parseQuery';
import { calculateFinalFilters } from './simple-filter/SimpleFilter';

class CsvDownload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      fetching: false,
    };
  }

  fileDownload = async () => {
    this.setState({
      fetching: true,
    });

    const { downloadFile, filters, fileName } = this.props;
    const file = fileName + '.csv';
    const res = await downloadFile(file, parseQuery(calculateFinalFilters(filters)));
    const blob = await res.blob();
    saveAs(blob, file);

    this.setState({
      fetching: false,
    });
  };

  render() {
    const { disabled, filters, downloadFile, fileName, ...otherProps } = this.props;
    const { fetching } = this.state;

    return (
      <Button
        role="button"
        color="success"
        size="sm"
        onClick={this.fileDownload}
        disabled={disabled || fetching}
        {...otherProps}
      >
        {fetching && (
          <span>
            <Spinner /> Descargando
          </span>
        )}
        {!fetching && (
          <span>
            <FontAwesome name="table" /> Descargar CSV
          </span>
        )}
      </Button>
    );
  }
}

export default CsvDownload;
