import { apiFetch, handleAuth, withToken, mountQuery } from '../http';

export const createPost = ({ title, content }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: '/dashboard_posts',
      method: 'POST',
      body: {
        title,
        content,
      },
    }),
  );

export const updatePost = ({ post: { id, title, content } }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/dashboard_posts/${id}`,
      method: 'PUT',
      body: {
        title: title,
        content: content,
      },
    }),
  );

export const getPost = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/dashboard_posts/${id}`,
      method: 'GET',
    }),
  );

export const getPosts = (query) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/dashboard_posts${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const destroyPost = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/dashboard_posts/${id}`,
      method: 'DELETE',
    }),
  );
