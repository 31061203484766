/* eslint-disable jsx-a11y/anchor-is-valid */

import {
  deleteTask,
  getTask,
  getTasks,
  toggleComplete,
  toggleCompleteSubtask,
  toggleStarred,
} from '@core/building/tasks';
import React, { Component } from 'react';
import { Pagination } from '../../../@shared';
import { pluralize } from '../../../@shared/helpers';
import { connect } from '../../../@store';
import { TaskInfo } from './components/TaskInfo';
import { TaskList } from './components/TaskList';
import './TaskView.scss';

class TaskViewPresentation extends Component {
  state = {
    tasks: undefined,
    pagination: undefined,
    loading: true,
    selected: undefined,
    showCompleted: false,
  };

  async componentDidMount() {
    await this.fetchTasks(1);
  }

  fetchTasks = async (page = 1) => {
    this.setState({ tasks: undefined, loading: true });

    const { body: tasks = [], pagination, status } = await getTasks({
      members: this.props.user.id,
      complete: this.state.showCompleted ? undefined : false,
      p: page,
      pp: 10,
    });

    if (status === 200) {
      this.setState({ tasks, pagination, loading: false });
    }
  };

  fetchTaskDetails = async (task) => {
    const { status, body } = await getTask(task.id);

    if (status === 200) {
      this.setState({
        selected: body,
      });

      this.updateTask(body);
    }
  };

  setPage = (page) => {
    const { pagination } = this.state;

    if (pagination && page === pagination.page) {
      return;
    }

    this.setState({
      pagination: {
        ...pagination,
        page,
      },
    });

    this.fetchTasks(page);
  };

  toggleShowCompleted = async () => {
    this.setState(
      {
        showCompleted: !this.state.showCompleted,
        selected: undefined,
      },
      async () => {
        await this.fetchTasks(1);
      },
    );
  };

  selectTask = (task = undefined, e = undefined) => {
    if (e && e.target.class && e.target.class.includes('fa')) {
      return;
    }

    this.setState({
      selected: task,
    });

    if (task) {
      this.fetchTaskDetails(task);
    }
  };

  completeTask = async (task) => {
    const { status, body } = await toggleComplete(task.id);

    if (status === 200) {
      this.updateTask(body);
    }
  };

  completeSubtask = async (subtask) => {
    const { status, body } = await toggleCompleteSubtask(subtask.taskId, subtask.id);

    if (status === 200) {
      this.updateSubtask(body);
    }
  };

  starTask = async (task) => {
    const { status, body } = await toggleStarred(task.id);

    if (status === 200) {
      this.updateTask(body);
    }
  };

  deleteTask = async (task) => {
    const { status } = await deleteTask(task.id);

    if (status === 204) {
      this.setState({
        selected: undefined,
      });

      this.fetchTasks(this.state.pagination.page);
    }
  };

  updateTasksState = (tasks, task) => {
    let newState = {
      tasks,
    };

    if (this.state.selected && this.state.selected.id === task.id) {
      newState = {
        ...newState,
        selected: { ...task },
      };
    }

    this.setState(newState);
  };

  updateTask = (task) => {
    let tasksCopy = [...this.state.tasks];
    let taskIndex = tasksCopy.findIndex((t) => t.id === task.id);
    tasksCopy[taskIndex] = task;
    this.updateTasksState(tasksCopy, task);
  };

  updateSubtask = (subtask) => {
    let tasksCopy = [...this.state.tasks];
    const parentTask = tasksCopy.find((t) => t.id === subtask.taskId);
    let subtaskIndex = parentTask.subtasks.findIndex((st) => st.id === subtask.id);
    parentTask.subtasks[subtaskIndex] = subtask;
    this.updateTasksState(tasksCopy, parentTask);
  };

  render() {
    const { selected, pagination, tasks, loading, showCompleted } = this.state;

    if (loading) {
      return null;
    }

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <div>
        <div className="small mb-4">
          <strong>{pagination.count}</strong> {pluralize(pagination.count, 'tarea encontrada', 'tareas encontradas')}.
          Mostrando <strong>{pagination.per}</strong> tareas por página.
          <span className="float-right">
            <a href="#" onClick={this.toggleShowCompleted}>
              {showCompleted ? 'Ocultar' : 'Mostrar'} completadas
            </a>
          </span>
        </div>
        <div className="d-flex">
          <TaskList
            tasks={tasks}
            onSelect={this.selectTask}
            onComplete={this.completeTask}
            onStar={this.starTask}
            selected={selected}
          />
          {selected && (
            <TaskInfo
              task={selected}
              onClose={this.selectTask}
              onComplete={this.completeTask}
              onCompleteSubtask={this.completeSubtask}
              onDelete={this.deleteTask}
              onStar={this.starTask}
            />
          )}
        </div>
        {(!tasks || tasks.length > 0) && paginationTemplate}
      </div>
    );
  }
}

export const TaskView = connect((state) => ({ user: state.session.user }))(TaskViewPresentation);
