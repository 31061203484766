import { FontAwesome } from '@shared';
import { pluralize } from '@shared/helpers';
import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';

import { InventoryRow } from './InventoryRow';

const style = { padding: '0.3rem' };

export const InventoryTable = ({ items, pagination, switcher, buildings = [], position, next, prev, sap = false }) => {
  const { sapIntegration } = useSelector(
    ({ session }) => session.user?.memberships.find((m) => m.id === session.currentMembership).building,
  );
  const sap_titles = ['Disponible', 'Unidad'];
  const normal_titles = [
    'Disponible',
    'Dispositivos',
    'Reserva',
    'Préstamo',
    'MRO',
    'Retirado',
    'Dañado',
    'Otros',
    'Unidad',
  ];

  const titles = sap && sapIntegration ? sap_titles : normal_titles;

  return (
    <div>
      <div className="small mb-4">
        <strong>{pagination.count}</strong>{' '}
        {pluralize(pagination.count, 'artículo encontrado', 'artículos encontrados')}. Mostrando{' '}
        <strong>{pagination.per}</strong> artículos por página.
      </div>

      <Table>
        <colgroup>
          <col style={{ width: 'auto' }} />
          {!(sap && sapIntegration) && <col style={{ width: '90px' }} />}
          {!(sap && sapIntegration) && <col style={{ width: '90px' }} />}
          {!(sap && sapIntegration) && <col style={{ width: '90px' }} />}
          {!(sap && sapIntegration) && <col style={{ width: '90px' }} />}
          {!(sap && sapIntegration) && <col style={{ width: '90px' }} />}
          {!(sap && sapIntegration) && <col style={{ width: '90px' }} />}
          {!(sap && sapIntegration) && <col style={{ width: '90px' }} />}
          <col style={{ width: '90px' }} />
        </colgroup>
        <thead>
          <tr>
            <th rowSpan={2} className="align-middle">
              Artículo
            </th>
            <th colSpan={sap && sapIntegration ? 2 : 9} style={style} className="text-center">
              <span style={{ display: 'flex', justifyContent: switcher ? 'space-between' : 'center' }}>
                {switcher && buildings.length > 1 && (
                  <Button size="sm" outline style={{ border: 0 }} color="primary" onClick={prev}>
                    <FontAwesome name="chevron-left" />
                  </Button>
                )}

                {switcher ? (
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <span>Instalación:</span>
                    <span> {buildings[position]}</span>
                  </div>
                ) : (
                  'Cantidades'
                )}
                {switcher && buildings.length > 1 && (
                  <Button size="sm" outline style={{ border: 0 }} color="primary" onClick={next}>
                    <FontAwesome name="chevron-right" />
                  </Button>
                )}
              </span>
            </th>
          </tr>
          <tr>
            {titles.map((title) => (
              <>
                <th style={style} className="text-center small">
                  {title}
                </th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && (
            <tr>
              <td colSpan={sap && sapIntegration ? 2 : 9} className="text-center mt-4 mb-4">
                No se encontraron resultados.
              </td>
            </tr>
          )}

          {items.map((item, index) => (
            <InventoryRow
              item={item}
              sapIntegration={sapIntegration && sap}
              inventory={switcher ? item.inventory[buildings[position]] || {} : item.inventory}
              switcher={switcher}
              total={switcher ? item.inventory['__organization'] : {}}
              key={`inventory-${index}`}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
