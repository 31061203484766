import React from 'react';
import { Media } from 'reactstrap';
import { FontAwesome } from '@shared';

export const TagDisplay = ({ tag, showStatus = true }) => {
  const className = showStatus ? `pl-2 status-border status-border-${tag.active ? 'active' : 'inactive'}` : '';

  return (
    <Media className="align-items-center">
      <Media body className={className}>
        <div className="mb-1">
          <kbd>{tag.identifier}</kbd>
        </div>
        <div className="w-100 text-overflow">
          <span className="text-muted ">
            <FontAwesome name="caret-right" />
            Tipo:
          </span>
          {tag.tagType && tag.tagType.description}
        </div>
      </Media>
    </Media>
  );
};
