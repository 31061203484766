import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { UserRow } from './UserRow';

export const UsersResults = ({ users = [], pagination }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'pagina encontrada', 'paginas encontradas')}.
      Mostrando <strong>{pagination.per}</strong> usuarios por página.
    </div>

    {users.length === 0 && (
      <div className="text-center mt-3 mb-3">
        <em>No se encontraron usuarios.</em>
      </div>
    )}

    <Table hover size="sm">
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Email</th>
          <th>Identificador</th>
          <th>Roles</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user, index) => (
          <UserRow key={index} user={user} />
        ))}

        {users.length === 0 && (
          <tr>
            <td colSpan="4" className="text-center mt-3 mb-3">
              <em>No se encontraron usuarios.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
