import { getTags } from '@core/building/tags';
import { getAssets } from '@core/building/assets';
import { DateSelector } from '@shared/forms';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import { get } from 'lodash';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { connect } from '@store';

class ReadoutsFilterModalPresentation extends SimpleFilterModal {
  findTags = async (inputValue) => {
    const { body, status } = await getTags({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ identifier: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({
      search: inputValue,
      active: null,
      serializer: 'simple',
    });

    if (status === 200) {
      return body.map(({ item: { name }, id: value }) => ({
        label: `${name} - ${value}`,
        value,
      }));
    }

    return [];
  };

  renderForm = () => {
    return (
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="tag_types[{}]">Tipo de tag</Label>
              <Select
                name="tag_types[{}]"
                value={this.state['tag_types[{}]']}
                placeholder="Todos"
                isClearable
                isMulti
                options={this.props.tagTypes}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="tags[{}]">Tags</Label>
              <AsyncSelect
                name="tags[{}]"
                value={this.state['tags[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findTags}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="tags[{}]">Activo</Label>
              <AsyncSelect
                name="assets[{}]"
                value={this.state['assets[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="readout_period{}">Fecha Inicial</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'readout_period{}.started_at')}
                onChange={(date) =>
                  this.setState({
                    'readout_period{}': {
                      ...(this.state['readout_period{}'] || {}),
                      started_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="readout_period{}">Fecha Final</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'readout_period{}.ended_at')}
                onChange={(date) =>
                  this.setState({
                    'readout_period{}': {
                      ...(this.state['readout_period{}'] || {}),
                      ended_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };
}

export const ReadoutsFilterModal = connect(({ appData: { tag_types: tagTypes } }) => {
  return {
    tagTypes: tagTypes.map(({ id: value, description: label }) => ({
      value,
      label,
    })),
  };
})(ReadoutsFilterModalPresentation);
