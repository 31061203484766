import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Badge, Progress } from 'reactstrap';
import { TaskInfoModal } from '../../../account/profile/tasks/components/TaskInfoModal';
import { toggleCompleteSubtask, toggleStarred, getTask } from '@core/building/tasks';
import './TaskDetailModal.scss';
import { fromIso8601 } from '@core/utils/dates';
import { connect, notificationsGenerators } from '@store';

class TaskDetailModalPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      task: props.task,
      loading: false,
    };
  }

  completeSubtask = async (subtask) => {
    this.setState({ loading: true });
    const { status, body } = await toggleCompleteSubtask(subtask);

    if (status === 200) {
      this.props.completeSubTaskNotification();
      this.updateSubtask(body);
    }
    this.setState({ loading: false });
  };

  starTask = async (task) => {
    this.setState({ loading: true });
    const { status, body } = await toggleStarred(task.id);

    if (status === 200) {
      this.updateTask(body);
    }
    this.setState({ loading: false });
  };

  updateSubtask = async () => {
    this.setState({ loading: true });
    let taskCopy = { ...this.state.task };
    // let subtaskIndex = taskCopy.subtasks.findIndex((st) => st.id === subtask.id);
    // taskCopy.subtasks[subtaskIndex] = subtask;
    const { status, body } = await getTask(taskCopy.id);

    if (status === 200) {
      this.updateTask(body);
    }
    this.setState({ loading: false });
  };

  updateTask = async (task) => {
    this.setState({ task });
    await this.props.onTaskChange(task);
  };

  sumHours = (task, typeHours) => {
    return task.subtasks
      ?.map((subtask) => (subtask[typeHours] ? subtask[typeHours] : 0))
      .reduce((accumulator, estimatedHour) => {
        return accumulator + estimatedHour;
      }, 0);
  };

  render() {
    const {
      toggle,
      isOpen,
      task,
      openTaskPospone,
      openTaskCancel,
      openConfirmBox,
      openConfirmMROModal,
      openCompleteTask,
      startTask,
    } = this.props;
    const { loading } = this.state;

    return (
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          <Row>
            <Col sm={8}>
              <h5>Detalle de la tarea</h5>
              <div>
                <small className="text-muted">Creado: {fromIso8601(task.createdAt, true)}</small>
              </div>
              {task.canceledAt && (
                <div>
                  <small className="text-muted">Anulada: {fromIso8601(task.canceledAt, true)}</small>
                </div>
              )}
              {task.completedAt && (
                <div>
                  <small className="text-muted">Completada: {fromIso8601(task.completedAt, true)}</small>
                </div>
              )}
            </Col>
            <Col sm={4}>
              <Row>
                <Col>
                  <Progress color="success" value={task.completedAt ? 100 : task.completePercentage}>
                    {`${task.completedAt ? '100%' : Number(task.completePercentage).toFixed(0)}%`}
                  </Progress>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <small>Estimadas</small>
                  <Badge color="danger" className="mr-1">
                    {`${task.estimatedHours ? task.estimatedHours : this.sumHours(task, 'estimatedHours')} HH`}
                  </Badge>
                </Col>
                <Col sm={6}>
                  <small>Registradas</small>
                  <Badge color="success" className="mr-1">
                    {`${task.effectiveHours ? task.effectiveHours : this.sumHours(task, 'effectiveHours')} HH`}
                  </Badge>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalHeader>
        <ModalBody>
          <TaskInfoModal
            className={'task-modal'}
            task={task}
            onComplete={openCompleteTask}
            onCompleteSubtask={this.completeSubtask}
            openTaskPospone={openTaskPospone}
            openTaskCancel={openTaskCancel}
            openConfirmBox={openConfirmBox}
            openConfirmMROModal={openConfirmMROModal}
            onStar={startTask}
            loading={loading}
          />
        </ModalBody>
        <ModalFooter>
          <Button role="button" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export const TaskDetailModal = connect(
  (state) => ({ session: state.session }),
  (dispatch) => ({
    completeSubTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Sub-tarea completada',
          text: 'La sub-tarea ha sido completada correctamente.',
          color: 'success',
        }),
      ),
    completeTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea completada',
          text: 'La tarea ha sido completada correctamente.',
          color: 'success',
        }),
      ),
  }),
)(TaskDetailModalPresentation);
