import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getAssets = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getShortAssets = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets${mountQuery({ ...query, serializer: 'short' })}`,
      method: 'GET',
    }),
  );

export const getAsset = (barcode) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${barcode}?${new URLSearchParams(window.location.search)}`,
      method: 'GET',
    }),
  );

export const createAsset = ({ ...asset }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/assets',
      method: 'POST',
      body: {
        asset,
      },
    }),
  );

export const updateAsset = ({ barcode1, ...asset }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${barcode1}`,
      method: 'PUT',
      body: {
        asset,
      },
    }),
  );

export const assembly = ({ itemBarcode, code, areaId, factorySerialNumber1, repairable }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/assembly`,
      method: 'POST',
      body: {
        itemBarcode,
        code,
        areaId,
        factorySerialNumber1,
        repairable,
      },
    }),
  );

export const reassembly = ({ assetBarcode, code }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/reassembly`,
      method: 'POST',
      body: {
        assetBarcode,
        code,
      },
    }),
  );

export const insertPicture = (assetBarcode, token) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${assetBarcode}/pictures`,
      method: 'POST',
      body: {
        token,
      },
    }),
  );

export const deletePicture = (assetBarcode, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${assetBarcode}/pictures/${id}`,
      method: 'DELETE',
    }),
  );

export const transferOwnership = (formData) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/assets/transfer_ownership',
      method: 'POST',
      body: {
        barcode: formData.barcode,
        targetBuilding: formData.targetBuilding,
        areaId: formData.areaId,
        comment: formData.comment,
      },
    }),
  );
