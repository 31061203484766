import { FontAwesome } from '@shared/';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';

export const EventItem = ({ item }) => (
  <Media className="align-middle small">
    {item && (
      <Media body>
        {item.name && (
          <div className="w-100 text-overflow mt-1">
            <FontAwesome name="th-large" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{item.name}</span>
          </div>
        )}
        <NavLink className={!item.barcode1 ? 'ml-20' : ''} to={`/organization/items/${item.barcode1}`}>
          <FontAwesome name="barcode" color="info" className="mr-1" />
          <kbd>{item.barcode1}</kbd>
        </NavLink>
      </Media>
    )}
  </Media>
);

export const EventItemModal = ({ item }) => (
  <Media>
    {item && (
      <Media body className="mr-1">
        {item.name && (
          <div className="w-100 text-overflow mt-1">
            <FontAwesome name="th-large" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{item.name}</span>
          </div>
        )}
        <NavLink className={!item.barcode1 ? 'ml-20' : ''} to={`/organization/items/${item.barcode1}`}>
          <FontAwesome name="barcode" color="info" className="mr-1" />
          <span className="text-muted mr-1">Artículo:</span>
          <kbd>{item.barcode1}</kbd>
        </NavLink>
        <div>
          {item.internalSku && (
            <span>
              SKU Interno: <span className="text-muted">{item.internalSku}</span>
            </span>
          )}
        </div>
        <div>
          {item.externalSku && (
            <span>
              SKU Externo: <span className="text-muted">{item.externalSku}</span>
            </span>
          )}
        </div>
      </Media>
    )}
  </Media>
);
