import { getSubscriptionByTopic, upsertSubscription } from '@core/building/notifications';
import { FontAwesome } from '@shared';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { subscriptionsGenerators } from '../../@store';
import styles from './building-subscriptions.module.scss';

export const SubscriptionToggle = ({ title, description, topic, disabled = false, onlyEmail = false }) => {
  const [subscription, setSubscription] = useState({ topic, preferences: { push: false, email: false } });

  useEffect(() => {
    const getSubscription = async () => {
      let { body, status } = await getSubscriptionByTopic(topic);
      if (status === 200 || status === 304) {
        setSubscription({ ...subscription, ...body });
      }
    };
    getSubscription();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topic]);

  const updateSubscription = async (key) => {
    const updatedSubscription = {
      ...subscription,
      preferences: { ...subscription.preferences, [key]: !subscription.preferences[key] },
    };
    setSubscription(updatedSubscription);
    await upsertSubscription(updatedSubscription);
  };

  const tooltipKey = `toltip-${topic.replaceAll('/', '__')}`;

  return (
    <div className={styles.subscriptionToggleContainer}>
      <div className={styles.subscriptionInfo}>
        <div id={tooltipKey}>{title}</div>
        <UncontrolledTooltip placement="top" target={tooltipKey}>
          {description}
        </UncontrolledTooltip>
      </div>
      <div className={styles.subscriptionCtrl}>
        <ButtonGroup>
          <Button
            size="sm"
            color="primary"
            outline
            disabled={disabled}
            onClick={() => updateSubscription('email')}
            active={subscription.preferences.email}
          >
            <FontAwesome name="envelope" />
          </Button>

          <Button
            size="sm"
            color="primary"
            outline
            disabled={disabled}
            onClick={() => updateSubscription('push')}
            active={subscription.preferences.push}
          >
            <FontAwesome name="mobile-alt" />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export const BuildingSubscriptionModal = ({ buildingId }) => {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.subscriptions.open);

  const toggle = () => dispatch(subscriptionsGenerators.closeBuildingModal());

  return (
    <Modal isOpen={open} size="lg">
      <ModalHeader>Notificaciones</ModalHeader>
      <ModalBody className="space-y-3">
        {/* <div>
          <div>
            <FontAwesome name="th" className="mr-2" />
            <span className={styles.groupTitle}>Inventario</span>
          </div>
          <div className="space-y-1">
            <SubscriptionToggle
              disabled={true}
              topic={`building/${buildingId}/consumable-stock-warn`}
              title="Advertencia de stock"
              description="Informe semanal de consumibles con inventario por debajo del nivel de advertencia"
            />
            <SubscriptionToggle
              disabled={true}
              topic={`building/${buildingId}/consumable-stock-min`}
              title="Reposición de stock"
              description="Informe semanal de consumibles con inventario por debajo del nivel recomendado"
            />
          </div>
        </div>
        <div>
          <div>
            <FontAwesome name="wrench" className="mr-2" />
            <span className={styles.groupTitle}>Activos</span>
          </div>
          <div className="space-y-1">
            <SubscriptionToggle
              disabled={true}
              topic={`building/${buildingId}/validity-periods`}
              title="Periodos de vigencia vencidos"
              description="Informe diario activos con periodos de vigencias vencidos"
            />
          </div>
        </div>
        <div>
          <div>
            <FontAwesome name="heartbeat" className="mr-2" />
            <span className={styles.groupTitle}>MRO</span>
          </div>
          <div className="space-y-1">
            <SubscriptionToggle
              disabled={true}
              topic={`building/${buildingId}/mro`}
              title="Procesos abiertos"
              description="Informe semanal de procesos MRO en curso"
            />
          </div>
        </div> */}
        <div>
          <div>
            <FontAwesome name="dot-circle" className="mr-2" />
            <span className={styles.groupTitle}>Tracking</span>
          </div>
          <div className="space-y-1">
            <SubscriptionToggle
              topic={`building/${buildingId}/low-battery`}
              title="Batería baja"
              description="Información en sobre el estado de batería del activo"
            />
            <SubscriptionToggle
              topic={`building/${buildingId}/geofence-in`}
              title="Entrada a Geocerca"
              description="Información en tiempo real de eventos de entrada a geocerca"
            />
            <SubscriptionToggle
              topic={`building/${buildingId}/geofence-out`}
              title="Salida de Geocerca"
              description="Información en tiempo real de eventos de salida de geocerca"
            />
            <SubscriptionToggle
              topic={`building/${buildingId}/validity-period`}
              title="Vigencias"
              description="Información en tiempo real de vencimiento de vigencias"
            />
            <SubscriptionToggle
              topic={`building/${buildingId}/pickup_status_updated`}
              title="Pick Up"
              description="Información en tiempo real del estado de los pedidos de pick up"
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cerrar
        </Button>
      </ModalFooter>
    </Modal>
  );
};
