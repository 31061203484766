import { getShortTags } from '@core/building/tags';
import { buildingDownload } from '@core/http.js';
import { ExcelDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { TagsTable } from '@shared/tags';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { TagsFilterModal } from './components/TagsFilterModal';

export class TagsIndex extends SimpleFilter {
  searchFunction = getShortTags;

  setTagStatus = () => {
    this.handleFilter(this.state.filters, true);
  };

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                <ExcelDownload file="tags" filters={filters} downloadFile={buildingDownload} disabled={!items} />
              </ShowForRoles>
              <ShowForRoles excluded={['guest']}>
                <Button tag={NavLink} size="sm" outline color="success" to="/building/tags/new">
                  <FontAwesome name="plus" /> Crear Tag
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="tags" className="text-primary mr-2" />
              Gestión de Tags
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <TagsTable tags={items} pagination={pagination} setTagStatus={this.setTagStatus} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <TagsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={this.state.filters}
        />
      </BuildingLayout>
    );
  }
}
