import React, { Component } from 'react';
import { Table } from 'reactstrap';

import { deleteKit } from '@core/building/kits';
import { connect, notificationsGenerators } from '@store';
import { ShowForRoles } from '@shared';

import KitRow from './KitRow';

class KitsTable extends Component {
  static defaultProps = {
    showBarcode: true,
  };

  state = {
    displayConfirmBox: true,
    kits: this.props.kits,
  };

  deleteKit = async (index) => {
    const kit = this.state.kits[index];
    const { kits } = this.state;

    const { status } = await deleteKit(kit.id);

    if (status === 204) {
      this.props.deleteNotification();

      this.setState({
        kits: [...kits.slice(0, index), ...kits.slice(index + 1)],
      });
    }
  };

  toogleConfirmBox = () => {
    this.setState({
      displayConfirmBox: !this.state.displayConfirmBox,
    });
  };

  render() {
    const { kits, displayConfirmBox } = this.state;
    const availability = {
      true: { color: 'success', name: 'Disponible' },
      false: { color: 'danger', name: 'No disponible' },
    };
    const kind = {
      permanent: 'Permanente',
      temporary: 'Temporal',
    };

    return (
      <div>
        <Table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Total</th>
              <th>Tipo</th>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                <th />
              </ShowForRoles>
            </tr>
          </thead>
          <tbody>
            {kits.length === 0 && (
              <tr>
                <td colSpan="5" className="text-center">
                  <em>No hay kits creados o disponibles de momento.</em>
                </td>
              </tr>
            )}

            {kits.map((kit, index) => (
              <KitRow
                key={index}
                kit={kit}
                deleteKit={() => this.deleteKit(index)}
                toogleConfirmBox={this.toogleConfirmBox}
                displayConfirmBox={displayConfirmBox}
                availability={availability}
                showBarcode={this.props.showBarcode}
                kind={kind}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  deleteNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Kit eliminado',
        text: 'El kit ha sido eliminado correctamente.',
        color: 'success',
      }),
    ),
}))(KitsTable);
