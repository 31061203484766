import { apiFetch, handleAuth, withMembership } from '../http';

export const getValidityPeriods = (asset_barcode) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${asset_barcode}/validity_periods`,
      method: 'GET',
    }),
  );

export const createValidityPeriod = (asset_barcode: string, validity_period: object = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${asset_barcode}/validity_periods`,
      method: 'POST',
      body: { validity_period },
    }),
  );

export const updateValidityPeriod = (asset_barcode: string, validity_period: object = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${asset_barcode}/validity_periods/${validity_period.id}`,
      method: 'PUT',
      body: { validity_period },
    }),
  );

export const destroyValidityPeriod = (asset_barcode: string, validity_period_id: integer) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/${asset_barcode}/validity_periods/${validity_period_id}`,
      method: 'DELETE',
    }),
  );
