import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export { status, role } from '../../@core/admin/users';

export const getUser = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${id}`,
      method: 'GET',
    }),
  );

export const getUsers = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createUser = (user) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users`,
      method: 'POST',
      body: {
        user,
      },
    }),
  );

export const updateUser = (id, payload) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${id}`,
      method: 'PUT',
      body: {
        user: payload,
      },
    }),
  );

export const getUserAssets = (id, query = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${id}/assets${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getAllUsers = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/all${mountQuery(query)}`,
      method: 'GET',
    }),
  );
