import React, { useEffect, useState } from 'react';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesome } from '@shared';
import ValidityPeriodRow from '@shared/validity-period/ValidityPeriodRow';
import { getValidityPeriods } from '@core/building/validity-period';

export const MaintenanceValidityPeriodModal = ({ asset }) => {
  const [openValidityPeriodsModal, setOpenValidityPeriodsModal] = useState(false);
  const [validityPeriods, setValidityPeriods] = useState([]);
  const [editing, setEditing] = useState(false);

  const loadValidityPeriods = async () => {
    const { body, status } = await getValidityPeriods(asset.barcode1);
    if (status === 200) {
      setValidityPeriods(body);
    }
  };

  const onDelete = (index) => {
    const periods = validityPeriods.filter((item, i) => index !== i);
    setValidityPeriods(periods);
  };

  const toggleEditing = () => setEditing(!editing);

  useEffect(() => {
    loadValidityPeriods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  return (
    <Row>
      <Col className="text-right">
        <Button color="primary" onClick={() => setOpenValidityPeriodsModal(true)}>
          <FontAwesome name="calendar-alt" />
          Gestionar vigencias
        </Button>
      </Col>

      <Modal
        size="lg"
        isOpen={openValidityPeriodsModal}
        toggle={() => setOpenValidityPeriodsModal(!openValidityPeriodsModal)}
      >
        <ModalHeader toggle={() => setOpenValidityPeriodsModal(!openValidityPeriodsModal)}>
          <FontAwesome name="calendar-alt" /> Periodos de Vigencia
        </ModalHeader>
        <ModalBody>
          <div>
            {validityPeriods.length === 0 && (
              <tr>
                <td colSpan="4" className="text-center">
                  <em>No hay Periodos de Vigencia creados o disponibles de momento.</em>
                </td>
              </tr>
            )}
            {validityPeriods.length > 0 &&
              validityPeriods.map((period, index) => (
                <ValidityPeriodRow
                  index={index}
                  key={period.id || period.key}
                  validityPeriod={period}
                  assetBarcode={asset.barcode1}
                  onDelete={onDelete}
                  toggleEditing={toggleEditing}
                  editing={editing}
                  editable={false}
                />
              ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button role="button" className="ml-3" onClick={() => setOpenValidityPeriodsModal(!openValidityPeriodsModal)}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};
