import React from 'react';
import { Table } from 'reactstrap';
import { EventRow } from './EventRow';

export const EventsResults = ({ events }) => (
  <div style={{ overflow: 'auto' }}>
    <Table>
      <colgroup>
        <col style={{ minWidth: '150px' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Acción</th>
          <th>Usuario</th>
          <th>Metadata</th>
          <th className="align-middle text-center">Ver Detalles</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event, index) => (
          <EventRow key={index} event={event} />
        ))}
        {events.length === 0 && (
          <tr>
            <td colSpan={6} className="text-center">
              <div>No hay eventos generados todavía.</div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
