import { handleAuth, withMembership, apiFetch } from '../http';

export const getMemberships = (userId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${userId}/memberships`,
      method: 'GET',
    }),
  );

export const createMembership = ({ userId, buildingId, role, active = true }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${userId}/memberships`,
      method: 'POST',
      body: {
        buildingId,
        role,
        active,
      },
    }),
  );

export const destroyMembership = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/memberships/${id}`,
      method: 'DELETE',
    }),
  );
