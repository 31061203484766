import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, CardFooter, ListGroup } from 'reactstrap';

import { getPosts } from '@core/admin/posts';
import { FontAwesome, ShowForRoles, Spinner } from '@shared';
import PostCard from '@shared/pages/PostCard';

class LatestPosts extends Component {
  state = {
    loading: true,
    posts: [],
  };

  async componentDidMount() {
    const { body, status } = await getPosts({ pp: 3 });

    if (status === 200)
      this.setState({
        loading: false,
        posts: body,
      });
  }

  render() {
    const { loading, posts } = this.state;

    if (loading)
      return (
        <div className="text-center h2 text-info">
          <Spinner />
        </div>
      );

    return (
      <div>
        <ListGroup flush>
          {posts.map((post, index) => (
            <PostCard post={post} key={index} first={index === 0} />
          ))}
        </ListGroup>
        <ShowForRoles rootAccepted={['root']}>
          <CardFooter className="border-top-0">
            <Button block role="button" tag={NavLink} to="/admin/posts">
              <FontAwesome name="edit" /> Gestionar noticias
            </Button>
          </CardFooter>
        </ShowForRoles>
      </div>
    );
  }
}

export default LatestPosts;
