import { massImportUsers } from '@core/admin/users';
import Validates, { emailFormat, presence } from '@core/forms/validations';
import ChileanRUT from '@core/utils/chilean-rut';
import GenericMassImport from '@shared/mass-import';
import React from 'react';
import { MassImportInstructions } from './components/MassImportInstructions';

import type { ColumnMetadata } from '@shared';

const columnMetadata: ColumnMetadata[] = [
  {
    columnName: 'first_name',
    displayName: 'Nombre',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'first_name' })),
    style: {
      width: '15%',
    },
  },
  {
    columnName: 'last_name',
    displayName: 'Apellido',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'last_name' })),
    style: {
      width: '15%',
    },
  },
  {
    columnName: 'identifier_value',
    displayName: 'Identificador',
    render: (user) => ChileanRUT.PartialFormat(user.identifier_value && user.identifier_value),
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'identifier_value' })),
    showInResults: false,
    style: {
      width: '15%',
    },
  },
  {
    columnName: 'email',
    displayName: 'Email',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'email' }), emailFormat({ of: 'email' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'password',
    displayName: 'Password',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'password' })),
    showInResults: false,
    style: {
      width: '15%',
    },
  },
  {
    columnName: 'phone',
    displayName: 'Teléfono',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'phone' })),
    style: {
      width: '15%',
    },
  },
];

const tableConfig = {
  columnMetadata: columnMetadata,
  trackBy: '_id',
};

const headerMap = {
  first_name: ({ first_name }) => first_name,
  last_name: ({ last_name }) => last_name,
  identifier_value: ({ identifier_value }) => identifier_value,
  email: ({ email }) => email,
  password: ({ password }) => password,
  phone: ({ phone }) => phone,
};

export const UsersMassImport = () => (
  <GenericMassImport
    entity="Usuarios"
    instructions={MassImportInstructions}
    valueMapping={headerMap}
    request={massImportUsers}
    tableConfig={tableConfig}
  />
);
