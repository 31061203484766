import React from 'react';

export const MassImportUpdateInstructions = () => (
  <ul>
    <li className="mb-2">
      Solo se permiten archivos <code>csv</code>. Usted puede guardar planillas Excel fácilmente con este formato a
      partir de la opción <code>Guardar como...</code>.
    </li>
    <li className="mb-2">
      Se recomienda que genere una planilla sencilla para crear el archivo CSV, sin formato ni columnas o filas extras.
    </li>
    <li className="mb-2">
      La primera fila del archivo CSV debe contener los nombres de las columnas asociadas. Estas pueden anotarse en el
      orden deseado.
    </li>
    <li className="mb-2">
      Las columnas a indicar son las siguientes:
      <ol className="mt-2">
        <li className="mb-2">
          <code>id</code>: ID del artículo en la base de datos.
        </li>
        <li className="mb-2">
          <code>name</code>: Nombre del artículo.
        </li>
        <li className="mb-2">
          <code>internal_sku</code>: SKU interno.{' '}
          <strong>No pueden existir dos artículos con el mismo SKU interno.</strong>
        </li>
        <li className="mb-2">
          <code>external_sku</code>: SKU Externo.
        </li>
        <li className="mb-2">
          <code>type</code>: Valor numérico que si el artículo es consumible, restringido o ninguno de los anteriores.
          Los valores disponibles son:
          <ul>
            <li className="mb-2">
              <code>0</code>: Para indicar que el artículo no es consumible ni restringido
            </li>
            <li className="mb-2">
              <code>1</code>: Para indicar que el artículo es consumible
            </li>
            <li className="mb-2">
              <code>2</code>: Para indicar que el artículo es restringido
            </li>
          </ul>
        </li>
        <li className="mb-2">
          <code>price</code>: Precio del artículo.
        </li>
        <li className="mb-2">
          <code>currency</code>: Moneda en la que se encuentra el precio.
        </li>
        <li className="mb-2">
          <code>unit_id</code>: Id de la unidad correspondiente al artículo (solo consumibles).
        </li>
      </ol>
      Cualquier otra columna será ignorada automáticamente.
    </li>
    <li className="mb-2">
      Todas las columnas especificadas son opcionales, excepto <strong>ID</strong>
    </li>
    <li className="mb-2">
      Si alguna fila presenta un error en sus valores, no se creará ningun artículo. El archivo deberá ser cargado
      nuevamente.
    </li>
  </ul>
);
