import { FontAwesome, ShowForRoles } from '@shared';
import React from 'react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap';

const DeviceLayoutPresentation = ({ device, children }) => (
  <Card>
    <CardHeader>
      <Nav className="nav-tabs card-header-tabs">
        <NavItem>
          <NavLink role="button" exact tag={Link} to={`/building/devices/${device.barcode1}`}>
            <FontAwesome name="list-alt" /> Resumen
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink role="button" exact tag={Link} to={`/building/devices/${device.barcode1}/inventory`}>
            <FontAwesome name="th" /> Inventario
          </NavLink>
        </NavItem>
        <ShowForRoles accepted={['organization_admin']}>
          <NavItem>
            <NavLink role="button" tag={Link} to={`/building/devices/${device.barcode1}/keys`}>
              <FontAwesome name="key" /> API Keys
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink role="button" tag={Link} to={`/building/devices/${device.barcode1}/stocks`}>
              <FontAwesome name="chart-bar" /> Stocks
            </NavLink>
          </NavItem>
        </ShowForRoles>
      </Nav>
    </CardHeader>

    <CardBody>{children}</CardBody>
  </Card>
);

export const DeviceLayout = withRouter(DeviceLayoutPresentation);
