import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { ErrorList } from '@shared';

import MassImportFailedTable from './components/MassImportFailedTable';
import MassImportCreatedTable from './components/MassImportCreatedTable';

type Props = {
  created: Object[],
  errors: Object[],
  failed: Object[],
  gridConfig: Object,
};

export const ResultsRow = ({ created, errors, failed, gridConfig }: Props) => (
  <Row className="justify-content-center mt-4">
    <Col xs={12} sm={12} md={12} lg={10}>
      <Card>
        <CardHeader>Resultados de la importación</CardHeader>
        <CardBody>
          {errors && errors.length > 0 && <ErrorList errors={errors} />}

          {failed && failed.length > 0 && <MassImportFailedTable failed={failed} gridConfig={gridConfig} />}

          {created && created.length > 0 && <MassImportCreatedTable created={created} gridConfig={gridConfig} />}
        </CardBody>
      </Card>
    </Col>
  </Row>
);
