import { getPost, updatePost } from '@core/admin/posts';
import { Loading } from '@shared';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { PostForm } from './components/PostForm';

class EditPostPresentation extends Component {
  state = {
    loading: true,
    post: {},
    redirectTo: undefined,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) {
      this.notFound();
      return;
    }

    const { body, status } = await getPost(id);

    if (status === 200) {
      this.setState({ post: body, loading: false });
    } else if (status === 484) {
      this.notFound();
    }
  }

  notFound = () => {
    this.props.notFoundNotification();
    this.setState({ redirectTo: '/admin/posts' });
  };

  updatePost = async (post) => {
    const { body, status } = await updatePost({ post });

    if (status === 200) {
      this.props.successEditingNotification();
      this.setState({ redirectTo: `/admin/posts` });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    } else if (status === 404) {
      this.notFound();
    }
  };
  render() {
    const { post, loading, redirectTo } = this.state;

    if (redirectTo) return <Redirect to={redirectTo} />;

    if (loading) return <Loading />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4" title="Editar noticia">
        <PostForm onSubmit={this.updatePost} initialValues={post} />
      </SimpleContainerLayout>
    );
  }
}

export const EditPost = connect(null, (dispatch) => ({
  notFoundNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No encontrado',
        text:
          'No se encontró el recurso buscado o no se encuentra disponible. Si los problemas persisten, por favor contacte a un administrador.',
        color: 'danger',
      }),
    ),
  successEditingNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Post editado',
        text: 'El post ha sido editado exitosamente.',
        color: 'success',
      }),
    ),
}))(EditPostPresentation);
