import { FontAwesome } from '@shared/';
import React from 'react';
import { store } from '@store';
import { Badge, Media } from 'reactstrap';

const { roles } = store.getState().appData;

export const EventMembership = ({ membership }) => {
  return (
    <Media className="align-middle">
      {membership && (
        <Media body>
          {membership.user && (
            <div className="w-100 text-overflow mt-1 small">
              <FontAwesome name="user" color="info" className="mr-1" />
              <span className="text-muted">{`${membership.user.firstName} ${membership.user.lastName}`}</span>
            </div>
          )}
          {membership.role && roles && roles[membership.role] && (
            <div className="w-100 text-overflow mt-1 small">
              <FontAwesome name="user-tag" color="info" className="mr-1" />
              <span className="text-muted">{roles[membership.role].title}</span>
            </div>
          )}
        </Media>
      )}
    </Media>
  );
};

export const EventMembershipModal = ({ membership }) => {
  return (
    <Media>
      {membership && (
        <Media body className="mr-1">
          {membership.user && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="user" color="info" className="mr-1" />
                Nuevo Usuario:
              </span>
              <span className="text-muted">{`${membership.user.firstName} ${membership.user.lastName}`}</span>
            </div>
          )}
          {membership.role && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="user-tag" color="info" className="mr-1" />
                Rol:
              </span>
              {membership.role && roles && roles[membership.role] && (
                <span className="text-muted">{roles[membership.role].title}</span>
              )}
            </div>
          )}
          <div>
            <FontAwesome name="cogs" color="info" className="mr-1" />
            {membership.active ? (
              <Badge color="success">
                <FontAwesome name="check" className="mr-1" /> Activa
              </Badge>
            ) : (
              <Badge color="danger">
                <FontAwesome name="times" className="mr-1" /> Inactiva
              </Badge>
            )}
          </div>
        </Media>
      )}
    </Media>
  );
};
