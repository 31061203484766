import { SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import { connect } from '@store';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

export class DevicesFilterModalPresentation extends SimpleFilterModal {
  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="types[{}]">Tipo de dispositivo</Label>
              <Select
                name="types[{}]"
                value={this.state['types[{}]']}
                placeholder="Todos"
                isClearable
                isMulti
                options={this.props.deviceTypes}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="active">Estado</Label>
              <Select
                name="active"
                value={this.state['active']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Activo', value: true },
                  { label: 'Inactivo', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

export const DevicesFilterModal = connect(({ appData: { device_types: deviceTypes } }) => {
  return {
    deviceTypes: deviceTypes.map(({ id: value, description: label }) => ({
      value,
      label: label,
    })),
  };
})(DevicesFilterModalPresentation);
