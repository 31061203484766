import { AssetSelector, ItemSelector, PictureUploader } from '@shared/forms';
import { FontAwesome } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import React, { useState } from 'react';
import { Field, Form } from '@core/forms';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import Validates, { emailFormat, presence } from '@core/forms/validations';
import { getAsset } from '@core/building/assets';
import { AssetDisplay, ItemDisplay } from '@shared/displays';
import { createInventoryChecker } from '@core/building/inventory-checkers';
import { compose } from 'recompose';
import { connect } from '@store';
import { Redirect } from 'react-router-dom';
import { getItem } from '@core/building/items';

const InventoryCheckerFormPresentation = ({ resetForm }) => {
  const [samples, setSamples] = useState([]);
  const [sampleBarcode, setSampleBarcode] = useState(undefined);
  const [barcodes, setBarcodes] = useState([]);
  const [evidences, setEvidences] = useState([]);
  const [newEmail, setNewEmail] = useState(undefined);
  const [emails, setEmails] = useState([]);
  const [stock, setStock] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [signature, setSignature] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [consumable, setConsumable] = useState(undefined);
  const [countedItems, setCountedItems] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // Agrega un nuevo asset al reporte
  const addSample = async (barcode) => {
    if (samples.some((sample) => sample.barcode1 === barcode)) {
      alert('La muestra está repetida');
      setSampleBarcode(undefined);
    } else {
      const { body, status } = consumable === 'true' ? await getItem(barcode) : await getAsset(barcode);
      if (status === 200 || status === 304) {
        setSamples([...samples, body]);
        setSampleBarcode(undefined);
      } else {
        console.log(body.errors);
      }
    }
  };

  // Elimina un Sample del reporte
  const removeSample = (barcode) => {
    setSamples([...samples.filter((sample) => sample.barcode1 !== barcode)]);
    setEvidences([...evidences.filter((evidence) => evidence.sampleBarcode !== barcode)]);
    setCountedItems([...countedItems.filter((countedItem) => countedItem.sampleBarcode !== barcode)]);
  };

  // Limpia o resetea el formulario del reporte
  const clearReport = () => {
    setSamples([]);
    setSampleBarcode(undefined);
    setEvidences([]);
    setEmails([]);
    setNewEmail(undefined);
    setStock(undefined);
    setConsumable(undefined);
    setDescription(undefined);
    setSignature(undefined);
    setCountedItems([]);
    resetForm();
  };

  const submitReport = async () => {
    setSubmitting(true);
    const data = {
      stock,
      barcodes,
      description,
      emails: emails.join(','),
      signature,
      evidences,
      origin: 'manual',
      consumable,
      countedItems,
      status: 'completed',
    };
    const { body, status } = await createInventoryChecker(data);
    if (status === 201) {
      clearReport();
      setRedirect(true);
    } else {
      console.log(body?.errors ? body?.errors : 'no existe errors');
    }
    toggle();
    setSubmitting(false);
  };

  // Crea la petición para generar el reporte
  const generateReport = () => {
    setModal(true);
    setBarcodes(samples.map((asset) => asset.barcode1).join(','));
  };

  const insertEvidence = (token, sample) => {
    if (token && sample) {
      setEvidences([{ token: token, sampleBarcode: sample.barcode1 }, ...evidences]);
    } else {
      setEvidences([...evidences.filter((evidence) => evidence.sampleBarcode !== sample.barcode1)]);
    }
  };

  const insertCountedItem = (quantity, sample) => {
    if (quantity && sample) {
      if (countedItems.some((countedItem) => countedItem.sampleBarcode === sample.barcode1)) {
        setCountedItems([
          ...countedItems.map((countedItem) => {
            return countedItem.sampleBarcode === sample.barcode1 ? { ...countedItem, quantity } : countedItem;
          }),
        ]);
      } else {
        setCountedItems([{ quantity, sampleBarcode: sample.barcode1 }, ...countedItems]);
      }
    }
  };

  const insertEmail = () => {
    if (emails.some((email) => email === newEmail)) {
      alert('El email está repetido');
      setNewEmail(undefined);
    } else {
      setEmails([newEmail, ...emails]);
      setNewEmail(undefined);
    }
  };

  const removeEmail = (emailToDelete) => {
    setEmails([...emails.filter((email) => email !== emailToDelete)]);
  };

  if (redirect) {
    return <Redirect to="/building/inventory-checkers" />;
  }

  return (
    <BuildingLayout>
      <SimpleContainerLayout title="Crear reporte de inventario">
        <Row>
          <Col>
            <Form>
              <Card>
                <CardHeader>
                  <Row>
                    <Col md={4}>
                      <Field
                        type="select"
                        name="stock"
                        label="Tipo de reporte"
                        options={{ '': 'Seleccione...', confirmed: 'Existencias', missing: 'Inexistencias' }}
                        onChange={(e) => setStock(e.target.value)}
                        value={stock}
                        size="lg"
                      />
                    </Col>
                    <Col md={4}>
                      <Field
                        type="select"
                        name="consumable"
                        label="Tipo de muestra"
                        options={{ '': 'Seleccione...', false: 'Activos', true: 'Consumibles' }}
                        onChange={(e) => {
                          setConsumable(e.target.value);
                          setSamples([]);
                          setBarcodes([]);
                          setEvidences([]);
                          setCountedItems([]);
                          setSampleBarcode(undefined);
                        }}
                        value={consumable}
                        size="lg"
                      />
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    {consumable === 'true' ? (
                      <Col sm={12} md={8} className="pb-2">
                        <Label for="targetInput">Seleccione una muestra</Label>
                        <ItemSelector
                          name="targetInput"
                          inputProps={{ size: 'lg' }}
                          filters={{ consumable: 'true' }}
                          input={{
                            onChange: setSampleBarcode,
                          }}
                        />
                      </Col>
                    ) : (
                      <Col sm={10} md={8} className="pb-2">
                        <Label for="targetInput">Seleccione una muestra</Label>
                        <AssetSelector
                          name="targetInput"
                          inputProps={{ size: 'lg' }}
                          filters={{ states: 'stored' }}
                          label="Seleccione un activo"
                          input={{
                            onChange: setSampleBarcode,
                          }}
                        />
                      </Col>
                    )}
                    <Col sm={2}>
                      <Row>
                        <Col>
                          <Label>Agregar</Label>
                        </Col>
                      </Row>
                      <Button
                        type="button"
                        role="button"
                        color="success"
                        size="lg"
                        onClick={() => addSample(sampleBarcode)}
                        disabled={!sampleBarcode}
                      >
                        <FontAwesome name="plus" />
                      </Button>
                    </Col>
                  </Row>
                  {samples.length > 0 && <Label>Lista de muestras</Label>}
                  <Card body style={{ minHeight: '15vh' }}>
                    {samples.length > 0 &&
                      samples.map((sample, index) => (
                        <Row
                          key={`Sample-${index}`}
                          className="pt-3"
                          style={{ backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'transparent'}` }}
                        >
                          <Col md={3}>
                            <PictureUploader
                              postResource="/pictures"
                              endpoint={(token) => `/pictures/${token}`}
                              multiple={false}
                              onChange={(tokens) => insertEvidence(tokens, sample)}
                            />
                          </Col>
                          <Col sm={12} md={6} className="mb-3">
                            {consumable === 'true' ? <ItemDisplay item={sample} /> : <AssetDisplay asset={sample} />}
                          </Col>
                          {consumable === 'true' && (
                            <Col sm={10} md={2} className="mb-3">
                              <Label for="quantity">Cantidad</Label>
                              <Field
                                type="number"
                                name="quantity"
                                input={{ size: 'lg' }}
                                onKeyUp={(e) => insertCountedItem(e.target.value, sample)}
                              />
                            </Col>
                          )}
                          <Col sm={2} md={1} className="mb-3">
                            <Button
                              type="button"
                              role="button"
                              color="danger"
                              onClick={() => removeSample(sample?.barcode1)}
                            >
                              <FontAwesome name="times" />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Card>
                  <hr />
                  <Row>
                    <Col>
                      <Label for="newEmail">Ingrese un Usuario</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={10} md={6}>
                      <Field
                        type="text"
                        name="newEmail"
                        input={{ size: 'lg' }}
                        onKeyUp={(e) => setNewEmail(e.target.value)}
                      />
                    </Col>
                    <Col sm={2}>
                      <Button type="button" color="success" size="lg" onClick={insertEmail} disabled={!newEmail}>
                        <FontAwesome name="plus" />
                      </Button>
                    </Col>
                  </Row>
                  {emails.length > 0 && <Label>Lista de emails</Label>}
                  {emails.length > 0 && (
                    <Card body sm={12} md={8}>
                      {emails.map((email, index) => (
                        <Row
                          key={`Email-${index}`}
                          className="py-3"
                          style={{ backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'transparent'}` }}
                        >
                          <Col sm={11}>{email}</Col>
                          <Col sm={1}>
                            <Button
                              type="button"
                              role="button"
                              color="danger"
                              size="sm"
                              onClick={() => removeEmail(email)}
                            >
                              <FontAwesome name="times" />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  )}
                  <hr />
                  <Row>
                    <Col>
                      <Field
                        name="description"
                        type="textarea"
                        rows="4"
                        label="Comentarios"
                        help="Opcional. Descripción complementaria del reeporte."
                        onKeyUp={(e) => setDescription(e.target.value)}
                      />
                    </Col>
                  </Row>
                </CardBody>
                <CardFooter>
                  <Button
                    type="button"
                    role="button"
                    color="primary"
                    className="mr-2"
                    onClick={generateReport}
                    disabled={!stock || !samples.length > 0 || !emails.length > 0 || submitting}
                  >
                    <FontAwesome name="check" /> Generar Reporte
                  </Button>
                  <Button type="button" role="button" color="danger" onClick={clearReport} disabled={submitting}>
                    <FontAwesome name="times" /> Borrar reporte
                  </Button>
                </CardFooter>
              </Card>
            </Form>
          </Col>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader>Confirmar reporte</ModalHeader>
            <ModalBody>
              ¿Desea generar un reporte de inventario con el grupo de activos seleccionados y notificar a los emails
              informados?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={submitReport} disabled={submitting}>
                Generar
              </Button>{' '}
              <Button color="danger" onClick={toggle} disabled={submitting}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      </SimpleContainerLayout>
    </BuildingLayout>
  );
};

const validate = Validates(presence({ of: 'stock' }), presence({ of: 'consumable' }), emailFormat({ of: 'newEmail' }));

export const InventoryCheckerForm = compose(
  reduxForm({
    form: 'InventoryCheckerForm',
    validate,
  }),
  connect(null, (dispatch) => ({
    resetForm() {
      dispatch({
        type: '@@redux-form/RESET',
        meta: {
          form: 'InventoryCheckerForm',
        },
      });
    },
  })),
)(InventoryCheckerFormPresentation);
