import React from 'react';
import { Media } from 'reactstrap';
import { NavLink } from 'react-router-dom';

import { FontAwesome, PictureDisplay } from '@shared';
import Barcode from '@shared/assets/Barcode';

export const SimpleItemDisplay = ({ item, showBarcode = true, showStatus = true }) => {
  const className = showStatus ? `status-border status-border-${item.active ? 'active' : 'inactive'}` : '';
  return (
    <Media className="align-items-center">
      {showBarcode && (
        <Media left className={`mr-2 ${className}`}>
          <Barcode code={item.barcode1} style={{ height: '2.5rem' }} />
        </Media>
      )}
      <Media body className={showBarcode ? '' : `pl-2 ${className}`}>
        <NavLink to={`/organization/items/${item.barcode1}`}>
          <kbd>{item.barcode1}</kbd>
          <FontAwesome name="search" className="ml-1" />
        </NavLink>
        <div className="mt-1 w-100 text-overflow">
          <span className="text-muted">
            <FontAwesome name="caret-right" />
            SKU Interno:
          </span>
          {item.internal_sku || item.internalSku || ' -'}
        </div>
      </Media>
    </Media>
  );
};

const ItemDisplay = ({
  item,
  deleted,
  barcode,
  comments = true,
  inventory = true,
  sku = false,
  inventoryChecker = false,
  quantity = undefined,
  delivered = undefined,
  finished = undefined,
  available = undefined,
  requested = undefined,
}) => (
  <Media className="align-items-center">
    <Media left className="mr-3">
      <PictureDisplay picture={item.defaultPicture} pictureSize="icon" alt={item.name} width="6rem" height="4rem" />
    </Media>
    <Media body>
      <div>{item.name}</div>
      <div className="small mb-1">
        {deleted ? (
          <kbd className="mr-1">{item.barcode1}</kbd>
        ) : (
          <NavLink to={`/organization/items/${item.barcode1}`}>
            <kbd className="mr-1">{item.barcode1}</kbd>
            <FontAwesome name="search" />
          </NavLink>
        )}
        {comments && item.comments && (
          <div className="mt-1">
            <span>
              Palabras claves: <code>{item.comments.join(', ')}</code>
            </span>
          </div>
        )}
      </div>
      {sku && (
        <div className="mt-1 w-100 text-overflow">
          <span className="small">
            SKU Interno: <code>{item.internal_sku || item.internalSku || ' -'}</code>
          </span>
        </div>
      )}
      {requested >= 0 && (
        <div>
          <span className="small">Cantidad solicitada: {requested}</span>
        </div>
      )}
      {!!quantity && (
        <div>
          <span className="small">Cantidad reservada: {quantity}</span>
        </div>
      )}
      {!!delivered && !finished && (
        <div>
          <span className="small">Cantidad preparada: {delivered}</span>
        </div>
      )}
      {!!finished && (
        <div>
          <span className="small">Cantidad entregada: {finished}</span>
        </div>
      )}
      {!!available && (
        <div>
          <span className="small">Cantidad pendiente: {available}</span>
        </div>
      )}
      {inventory && item.inventory && (
        <div>
          <span className="small">Cantidad disponible: {item.inventory.availableQuantity}</span>
          {inventoryChecker && item?.countedItem && (
            <span>
              {' - '}
              <strong>Cantidad contada: {item.countedItem}</strong>
            </span>
          )}
        </div>
      )}
    </Media>
    {barcode && (
      <Media right className="ml-3">
        <Barcode code={item.barcode1} style={{ height: '4rem' }} />
      </Media>
    )}
  </Media>
);

export default ItemDisplay;
