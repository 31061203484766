import React from 'react';
import { Button, Media, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { Barcode, FontAwesome } from '@shared';

const DeviceKeyModal = ({ currentDeviceKey, modal, secret, toggle }) => (
  <Modal isOpen={modal} toggle={toggle} size="md">
    <ModalHeader toggle={toggle}>Clave secreta</ModalHeader>
    <ModalBody>
      <p>Se ha adjuntado exitosamente la siguiente clave secreta (API Secret) a esta llave de acceso:</p>
      <Media className="mb-3 align-items-center">
        <Media left className="mr-3">
          <Barcode
            code={`${currentDeviceKey && currentDeviceKey.apiKey}:${secret}`}
            style={{
              height: '6em',
              width: 'auto',
            }}
          />
        </Media>
        <Media body>
          <p>
            <div className="text-muted">Alias</div>
            <code>{currentDeviceKey && currentDeviceKey.alias}</code>
          </p>
          <p>
            <div className="text-muted">API Key</div>
            <kbd>{currentDeviceKey && currentDeviceKey.apiKey}</kbd>
          </p>
          <p>
            <div className="text-muted">Clave secreta</div>
            <kbd>{secret}</kbd>
          </p>
        </Media>
      </Media>
      <p className="text-danger">
        <strong>¡Importante!:</strong> La clave secreta no volverá a ser mostrada por motivos de seguridad. En caso de
        pérdida, una nueva clave puede ser generada presionando el botón{' '}
        <Button role="button" color="primary" size="sm">
          <FontAwesome name="retweet" />
        </Button>
        .
      </p>
    </ModalBody>
    <ModalFooter>
      <Button role="button" onClick={toggle}>
        Cerrar
      </Button>
    </ModalFooter>
  </Modal>
);

export default DeviceKeyModal;
