import { connect } from '@store';
import React, { Component } from 'react';

const commons = ['lat', 'lng'];

const TYPES = {
  beacon: [...commons, 'distance'],
  gps: commons,
  'gps lora': commons,
};

const getProperType = (identifier = '') => {
  const [prefix] = identifier.split(':');

  switch (prefix.toUpperCase()) {
    case 'BEACON':
      return 1;
    case 'EPC':
      return 2;
    case 'EUI':
      return 6;
    // IMEI por defecto incluso si origin es "null"
    case 'IMEI':
    default:
      return 4;
  }
};

class ReadoutMetadataPresentation extends Component {
  state = {
    filteredMetadata: [],
  };

  componentDidMount() {
    const { metadata, tagTypes } = this.props;
    this.filterMetadata(getProperType(metadata.origin), metadata, tagTypes);
  }

  filterMetadata = (type, metadata, tagTypes) => {
    const options = {};
    const tagType = tagTypes[type];
    const result = TYPES[tagType];

    result.forEach((value) => {
      if (metadata[value]) {
        options[value] = metadata[value];
      }
    });
    this.setState({ filteredMetadata: options });
  };

  render() {
    const { filteredMetadata } = this.state;

    return (
      <div>
        {Object.keys(filteredMetadata).map(function (key, index) {
          return (
            <code key={key}>
              {`${key} : ${filteredMetadata[key]}`}
              <br />
            </code>
          );
        })}
      </div>
    );
  }
}

export const ReadoutMetadata = connect(({ appData: { tag_types: tagTypes } }) => {
  const options = {};
  tagTypes.forEach((tt) => (options[tt.id] = tt.name));
  return { tagTypes: options };
})(ReadoutMetadataPresentation);
