import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

import { SmartGrid } from '@shared';

type Props = {
  gridConfig: Object,
};

export const PreviewRow = ({ gridConfig }: Props) => (
  <Row className="justify-content-center mt-4">
    <Col xs={12} sm={12} md={12} lg={10}>
      <Card>
        <CardHeader>Previsualización de la importación</CardHeader>
        <CardBody>
          <SmartGrid {...gridConfig} />
        </CardBody>
      </Card>
    </Col>
  </Row>
);
