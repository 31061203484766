import React from 'react';

import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';

import { OrganizationInventory } from './Inventory';

export const InventoryRoutes = ({ match }) => (
  <Switch>
    <ProtectedRoute
      exact
      path={`${match.url}/`}
      component={OrganizationInventory}
      acceptedMemberships={['organization_admin', 'guest']}
    />
  </Switch>
);
