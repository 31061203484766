import { fromIso8601 } from '@core/utils/dates';
import { PictureUploader } from '@shared/forms';
import { FontAwesome, Spinner, PictureDisplay } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import React, { useState, useEffect } from 'react';
import { Field, Form } from '@core/forms';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import Validates, { emailFormat, presence } from '@core/forms/validations';
import { AssetDisplay, ItemDisplay } from '@shared/displays';
import { getInventoryChecker, updateInventoryChecker } from '@core/building/inventory-checkers';
import { compose } from 'recompose';
import { connect } from '@store';
import { Redirect, useParams } from 'react-router-dom';

const ConfirmInventoryCheckerFormPresentation = ({ resetForm }) => {
  const { id } = useParams();
  const [inventoryChecker, setInventoryChecker] = useState(null);
  const [samples, setSamples] = useState([]);
  const [evidences, setEvidences] = useState([]);
  const [newEmail, setNewEmail] = useState(undefined);
  const [emails, setEmails] = useState([]);
  const [description, setDescription] = useState(undefined);
  const [signature, setSignature] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [countedItems, setCountedItems] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    (async () => {
      const { body, status } = await getInventoryChecker(id);
      console.log(body, status);
      setInventoryChecker(body);
      setSamples(body.consumable ? body.items : body.assets);
    })();
  }, [id, setInventoryChecker]);

  // Limpia o resetea el formulario del reporte
  const clearReport = () => {
    setEvidences([]);
    setEmails([]);
    setNewEmail(undefined);
    setDescription(undefined);
    setSignature(undefined);
    setCountedItems([]);
    resetForm();
  };

  const submitReport = async () => {
    setSubmitting(true);
    let data = {
      id,
      description: `${inventoryChecker.description}. ${description}`,
      emails: emails.join(','),
      signature,
      evidences,
      countedItems,
      status: 'completed',
    };
    const { body, status } = await updateInventoryChecker(data);
    if (status === 200) {
      clearReport();
      setRedirect(true);
    } else {
      console.log(body?.errors ? body?.errors : 'no existe errors');
    }
    toggle();
    setSubmitting(false);
  };

  // Crea la petición para generar el reporte
  const generateReport = () => {
    setModal(true);
  };

  const insertEvidence = (token, sample) => {
    if (token && sample) {
      setEvidences([{ token: token, sampleBarcode: sample.barcode1 }, ...evidences]);
    } else {
      setEvidences([...evidences.filter((evidence) => evidence.sampleBarcode !== sample.barcode1)]);
    }
  };

  const insertCountedItem = (quantity, sample) => {
    if (quantity && sample) {
      if (countedItems.some((countedItem) => countedItem.sampleBarcode === sample.barcode1)) {
        setCountedItems([
          ...countedItems.map((countedItem) => {
            return countedItem.sampleBarcode === sample.barcode1 ? { ...countedItem, quantity } : countedItem;
          }),
        ]);
        console.log('DENTRO', quantity, sample.barcode1, countedItems);
      } else {
        setCountedItems([{ quantity, sampleBarcode: sample.barcode1 }, ...countedItems]);
        console.log('Nuevo', quantity, sample.barcode1, countedItems);
      }
    }
    console.log('FUERA', quantity, sample.barcode1, countedItems);
  };

  const insertEmail = () => {
    if (emails.some((email) => email === newEmail)) {
      alert('El email está repetido');
      setNewEmail(undefined);
    } else {
      setEmails([newEmail, ...emails]);
      setNewEmail(undefined);
    }
  };

  const removeEmail = (emailToDelete) => {
    setEmails([...emails.filter((email) => email !== emailToDelete)]);
  };

  if (redirect) {
    return <Redirect to="/building/inventory-checkers" />;
  }

  return (
    <BuildingLayout>
      <SimpleContainerLayout title="Confirmar reporte de inventario">
        {inventoryChecker ? (
          <Row>
            <Col>
              <Form>
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md={4}>
                        <h5>Detalle de la auditoría</h5>
                        <div>
                          <small className="text-muted">Nº: {inventoryChecker.requestNumber}</small>
                        </div>
                        <div>
                          <small className="text-muted">Fecha: {fromIso8601(inventoryChecker.createdAt, true)}</small>
                        </div>
                        <div>
                          <small className="text-muted">
                            Tipo: {inventoryChecker.stock === 'confirmed' ? 'Existencias' : 'Inexistencias'}
                          </small>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {samples.length > 0 && <Label>Lista de muestras</Label>}
                    <Card body style={{ minHeight: '15vh' }}>
                      {samples.length > 0 &&
                        samples.map((sample, index) => (
                          <Row
                            key={`Sample-${index}`}
                            className="pt-3"
                            style={{ backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'transparent'}` }}
                          >
                            <Col md={3}>
                              {sample.evidence ? (
                                <PictureDisplay
                                  picture={sample.evidence}
                                  pictureSize="icon"
                                  alt={`Evidencia: ${sample.barcode1}`}
                                />
                              ) : (
                                <PictureUploader
                                  postResource="/pictures"
                                  endpoint={(token) => `/pictures/${token}`}
                                  multiple={false}
                                  onChange={(tokens) => insertEvidence(tokens, sample)}
                                />
                              )}
                            </Col>
                            <Col sm={12} md={6} className="mb-3">
                              {inventoryChecker.consumable ? (
                                <ItemDisplay item={sample} />
                              ) : (
                                <AssetDisplay asset={sample} />
                              )}
                            </Col>
                            {inventoryChecker.consumable && (
                              <Col sm={10} md={2} className="mb-3">
                                <Label for="quantity">Cantidad</Label>
                                <Field
                                  type="number"
                                  name="quantity"
                                  input={{ size: 'lg' }}
                                  onKeyUp={(e) => insertCountedItem(e.target.value, sample)}
                                />
                              </Col>
                            )}
                          </Row>
                        ))}
                    </Card>
                    <hr />
                    <Row>
                      <Col>
                        <Label for="newEmail">Ingrese un Destinatario (email)</Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={10} md={6}>
                        <Field
                          type="text"
                          name="newEmail"
                          input={{ size: 'lg' }}
                          onKeyUp={(e) => setNewEmail(e.target.value)}
                        />
                      </Col>
                      <Col sm={2}>
                        <Button type="button" color="success" size="lg" onClick={insertEmail} disabled={!newEmail}>
                          <FontAwesome name="plus" />
                        </Button>
                      </Col>
                    </Row>
                    {emails.length > 0 && <Label>Lista de emails</Label>}
                    {emails.length > 0 && (
                      <Card body sm={12} md={8}>
                        {emails.map((email, index) => (
                          <Row
                            key={`Email-${index}`}
                            className="py-3"
                            style={{ backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'transparent'}` }}
                          >
                            <Col sm={11}>{email}</Col>
                            <Col sm={1}>
                              <Button
                                type="button"
                                role="button"
                                color="danger"
                                size="sm"
                                onClick={() => removeEmail(email)}
                              >
                                <FontAwesome name="times" />
                              </Button>
                            </Col>
                          </Row>
                        ))}
                      </Card>
                    )}
                    <hr />
                    <Row>
                      <Col>
                        <Field
                          name="description"
                          type="textarea"
                          rows="4"
                          label="Comentarios"
                          help="Opcional. Descripción complementaria del reeporte."
                          onKeyUp={(e) => setDescription(e.target.value)}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <Button
                      type="button"
                      role="button"
                      color="primary"
                      className="mr-2"
                      onClick={generateReport}
                      disabled={!inventoryChecker.stock || !samples.length > 0 || !emails.length > 0 || submitting}
                    >
                      <FontAwesome name="check" /> Generar Reporte
                    </Button>
                    <Button type="button" role="button" color="danger" onClick={clearReport} disabled={submitting}>
                      <FontAwesome name="times" /> Borrar reporte
                    </Button>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
            <Modal isOpen={modal} toggle={toggle}>
              <ModalHeader>Confirmar reporte</ModalHeader>
              <ModalBody>
                ¿Desea generar un reporte de inventario con el grupo de activos seleccionados y notificar a los emails
                informados?
              </ModalBody>
              <ModalFooter>
                <Button color="primary" onClick={submitReport} disabled={submitting}>
                  Generar {submitting && <Spinner />}
                </Button>{' '}
                <Button color="danger" onClick={toggle} disabled={submitting}>
                  Cerrar
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
        ) : (
          <div className="text-center h1 text-info">
            <Spinner />
          </div>
        )}
      </SimpleContainerLayout>
    </BuildingLayout>
  );
};

const validate = Validates(presence({ of: 'stock' }), presence({ of: 'consumable' }), emailFormat({ of: 'newEmail' }));

export const ConfirmInventoryCheckerForm = compose(
  reduxForm({
    form: 'ConfirmInventoryCheckerForm',
    validate,
  }),
  connect(null, (dispatch) => ({
    resetForm() {
      dispatch({
        type: '@@redux-form/RESET',
        meta: {
          form: 'ConfirmInventoryCheckerForm',
        },
      });
    },
  })),
)(ConfirmInventoryCheckerFormPresentation);
