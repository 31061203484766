import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getProviders = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/providers${mountQuery({ ...query, active: true })}`,
      method: 'GET',
    }),
  );

export const getProvider = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/providers/${id}`,
      method: 'GET',
    }),
  );
