import React from 'react';
import { AssetDisplay, ItemDisplay, UserDisplay } from '@shared/displays';
import { AttachmentDisplay, FontAwesome, Loading, Markdown, ShowForRoles, simpleFilterQuery } from '@shared';
import { branch, compose, lifecycle, renderComponent, withProps, withState } from 'recompose';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { Button, Col, Row } from 'reactstrap';
import { connect } from '@store';
import { fromIso8601 } from '@core/utils/dates';
import { get } from 'lodash';
import { getLocation } from '@core/organization/locations';
import { getMovement } from '@core/building/movements';
import { NavLink, Redirect } from 'react-router-dom';

const enhance = compose(
  connect(({ appData }) => {
    return {
      movementsData: {
        ...appData.movements,
        ...appData.repair_movements,
        ...appData.evaluation_movements,
        ...appData.maintenance_movements,
        ...appData.preservation_movements,
        ...appData.certification_movements,
        ...appData.assurance_movements,
        ...appData.discard_movements,
      },
    };
  }),
  withProps(({ match: { params: { id } } }) => ({ id })),
  withState('movement', 'setMovement', undefined),
  withState('location', 'setLocation', undefined),
  withState('notFound', 'setNotFound', false),
  lifecycle({
    async componentDidMount() {
      const { id, setMovement, setLocation, setNotFound } = this.props;
      const { body, status } = await getMovement(id);

      if (status === 200) {
        setMovement(body);

        const locationId = get(body, 'extendedAttributes.geofence.id');
        if (locationId) {
          const { body, status } = await getLocation(locationId);

          if (status === 200) {
            setLocation(body);
          }
        }
      } else if (status === 404) {
        setNotFound(true);
      }
    },
  }),
  branch(
    ({ notFound }) => notFound,
    renderComponent(() => <Redirect to="/building/assets" />),
  ),
  branch(({ movement }) => !movement, renderComponent(Loading)),
);

const TypeShow = ({ type, movementsData, location }) => (
  <div className="mb-4">
    <div className="text-muted mb-1">Tipo de movimiento</div>
    <div className="h5">
      {movementsData && movementsData[type].name && <span>{movementsData[type].name}</span>}
      {location && (
        <span>
          :{' '}
          <NavLink
            to={`/building/assets?q=${simpleFilterQuery({
              'locations[{}]': [{ label: location.name, value: location.id }],
              page: 1,
            })}`}
          >
            {location.name}
          </NavLink>
        </span>
      )}
    </div>
  </div>
);

const MovementPagePresentation = ({ id, movement, movementsData, location }) => (
  <BuildingLayout>
    <SimpleContainerLayout
      title={
        <span>
          <FontAwesome name="retweet" className="mr-2 text-primary" />
          Movimiento #{id}
        </span>
      }
    >
      <div>
        {!movement.consumable && (
          <div className="mb-4">
            <div className="mb-1 text-muted">Activo</div>
            <AssetDisplay asset={movement.asset} barcode={true} />
            <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
              <div className="hidden-print mt-1 small">
                <NavLink to={`/movements/new?of=${movement.asset.barcode1}`}>
                  <FontAwesome name="plus" className="mr-1" /> Ingresar movimiento
                </NavLink>
              </div>
            </ShowForRoles>
          </div>
        )}

        {movement.consumable && (
          <div className="mb-4">
            <div className="mb-1 text-muted">Artículo</div>
            {movement.item.deletedAt ? (
              <ItemDisplay item={movement.item} deleted={true} />
            ) : (
              <div>
                <ItemDisplay item={movement.item} deleted={false} />
                <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                  <div className="hidden-print mt-1 small">
                    <NavLink to={`/movements/new?of=${movement.item.barcode1}`}>
                      <FontAwesome name="plus" className="mr-1" /> Ingresar movimiento
                    </NavLink>
                  </div>
                </ShowForRoles>
              </div>
            )}
          </div>
        )}

        <Row>
          <Col sm={12} md={8}>
            <TypeShow type={movement.movementType} movementsData={movementsData} location={location} />
            {movement.consumable && (
              <div className="mb-4">
                <div className="text-muted mb-1">Cantidad</div>
                <div className="h5">
                  {movement.extendedAttributes['decimalQuantity']
                    ? movement.extendedAttributes['decimalQuantity']
                    : movement.quantity}
                </div>
              </div>
            )}
            <div className="mb-4">
              <div className="text-muted mb-1">Fecha y hora</div>
              <div className="h5">{fromIso8601(movement.movementAt)}</div>
            </div>
            {((movementsData[movement.movementType] && movementsData[movement.movementType].expects_return_date) ||
              movement.expectedAt) && (
              <div className="mb-4">
                <div className="text-muted mb-1">Fecha de retorno esperada</div>
                <div className="h5">
                  {movement.expectedAt ? fromIso8601(movement.expectedAt) : <em>Sin especificar</em>}
                </div>
              </div>
            )}
          </Col>

          <Col sm={12} md={4} className="mb-4">
            <div className="mb-4 text-right">
              <div className="text-muted mb-1">Organización</div>
              <div className="h5">{movement.organizationName}</div>
            </div>
            <div className="mb-4 text-right">
              <div className="text-muted mb-1">Instalación</div>
              <div className="h5">{movement.buildingName}</div>
            </div>
            {movement?.costAccount && (
              <div className="mb-4 text-right">
                <div className="text-muted mb-1">Centro de Costo</div>
                <div className="h5">
                  {movement.costAccount.code}
                  {' - '}
                  {movement.costAccount.name}
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          {movement.client && movement.clientType === 'Core::User' && (
            <Col className="mb-4">
              <div className="text-muted mb-2">Cliente</div>
              <UserDisplay user={movement.client} />
            </Col>
          )}
          <Col className="mb-4">
            <div className="text-muted mb-2">Ingresado por</div>
            {movement.generatorType === 'Core::User' ? (
              <UserDisplay user={movement.generator} />
            ) : movement.generatorType === 'Core::Device' && movement?.extendedAttributes?.operator ? (
              <UserDisplay user={movement?.extendedAttributes?.operator} />
            ) : (
              'Información no disponible'
            )}
          </Col>
        </Row>

        <div className="mb-4">
          <div className="text-muted mb-1">Comentarios</div>
          <div>
            <span style={{ whiteSpace: 'pre-line' }}>
              {movement.comments ? <Markdown source={movement.comments} /> : <em>Sin comentarios</em>}
            </span>
          </div>
        </div>

        <div className="mb-4">
          <div className="text-muted mb-1">Adjuntos</div>
          <div>
            {movement.attachments.length === 0 && <div className="text-muted small">Sin adjuntos todavía</div>}

            {movement.attachments && <AttachmentDisplay attachments={movement.attachments} />}
          </div>
        </div>
        <ShowForRoles excluded={['guest']}>
          <div className="text-center hidden-print">
            <Button role="button" color="primary" onClick={() => window.print()}>
              <FontAwesome name="print" /> Imprimir
            </Button>
          </div>
        </ShowForRoles>
      </div>
    </SimpleContainerLayout>
  </BuildingLayout>
);

export const MovementPage = enhance(MovementPagePresentation);
