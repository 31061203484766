import { FontAwesome, ShowForRoles } from '@shared';
import React from 'react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Nav, NavItem, NavLink } from 'reactstrap';

const ItemLayoutPresentation = ({ item, children }) => (
  <Card>
    <CardHeader>
      <Nav tabs className="card-header-tabs">
        <NavItem>
          <NavLink role="button" exact tag={Link} to={`/organization/items/${item.barcode1}`}>
            <FontAwesome name="list-alt" /> Resumen
          </NavLink>
        </NavItem>
        <ShowForRoles accepted={['organization_admin']}>
          <NavItem>
            <NavLink role="button" tag={Link} to={`/organization/items/${item.barcode1}/edit`}>
              <FontAwesome name="edit" /> Editar
            </NavLink>
          </NavItem>
        </ShowForRoles>
        <ShowForRoles excluded={['guest']}>
          <NavItem>
            <NavLink role="button" tag={Link} to={`/organization/items/${item.barcode1}/categories`}>
              <FontAwesome name="sitemap" /> Categorías
            </NavLink>
          </NavItem>
        </ShowForRoles>
        <NavItem>
          <NavLink role="button" tag={Link} to={`/organization/items/${item.barcode1}/pictures`}>
            <FontAwesome name="image" /> Imágenes
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink role="button" tag={Link} to={`/organization/items/${item.barcode1}/attachments`}>
            <FontAwesome name="file-alt" /> Adjuntos
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink role="button" tag={Link} to={`/organization/items/${item.barcode1}/expected_stocks`}>
            <FontAwesome name="chart-bar" /> Stocks
          </NavLink>
        </NavItem>
      </Nav>
    </CardHeader>

    <CardBody>{children}</CardBody>
  </Card>
);

export const ItemLayout = withRouter(ItemLayoutPresentation);
