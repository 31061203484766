import { getAssetEvents } from '@core/building/asset-events';
import { ShowForRoles, Spinner } from '@shared';
import React from 'react';
import { Table } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';

const enhance = compose(
  withState('assetEvents', 'setAssetEvents', undefined),
  lifecycle({
    async componentDidMount() {
      const { asset, setAssetEvents } = this.props;

      const { body, status } = await getAssetEvents({
        asset_id: asset.id,
      });

      if (status === 200) {
        setAssetEvents(body);
      }
    },
  }),
  branch(
    ({ assetEvents }) => !assetEvents,
    renderComponent(() => (
      <div className="mt-4 mb-4 text-info text-center h2">
        <Spinner />
      </div>
    )),
  ),
);

const AssetEventsPresentation = ({ asset, assetEvents }) => (
  <div>
    <h4 className="mb-4">Eventos</h4>
    <div>
      <Table>
        <colgroup>
          <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
            <col style={{ width: '350px' }} />
          </ShowForRoles>
        </colgroup>
        <thead>
          <tr>
            <th>Tipo de Evento</th>
            <th className="text-center">Metadata</th>
          </tr>
        </thead>
        <tbody>
          {assetEvents.length === 0 && (
            <tr>
              <td colSpan="2" className="text-center">
                <em>No hay eventos creados o disponibles de momento.</em>
              </td>
            </tr>
          )}
          {assetEvents.map((event, index) => (
            <tr key={index}>
              <td>
                {event.eventType === 'geofence-in'
                  ? 'Entrada de Geocerca'
                  : event.eventType === 'geofence-out'
                  ? 'Salida de Geocerca'
                  : event.eventType}
              </td>
              <td className="text-center">
                <div>
                  {Object.keys(event.metadata).map(function (key, index) {
                    return (
                      <code key={index}>
                        {`${key === 'geofence' ? 'Geocerca' : key === 'position' ? 'Posición' : key} : ${
                          key === 'geofence'
                            ? event.metadata[key]?.name
                            : key === 'position'
                            ? ['Lat:' + event.metadata[key]?.latitude, 'Lng:' + event.metadata[key]?.longitude]
                            : JSON.stringify(event.metadata[key])
                        }`}
                        <br />
                      </code>
                    );
                  })}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  </div>
);

export const AssetEvents = enhance(AssetEventsPresentation);
