import { handleAuth, withMembership, loadFile } from '../http';

export const massImportExpectedStocks = (file) =>
  handleAuth(
    withMembership(loadFile)({
      endpoint: `/expected-stocks/mass`,
      method: 'POST',
      name: 'file',
      files: file,
      parseResponse: false,
    }),
  );
