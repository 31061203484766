import React, { Component } from 'react';
import { massImportUpdateItems } from '../../@core/building/items';
import Validates, { oneOf, presence } from '../../@core/forms/validations';
import { getUnits } from '../../@core/units';
import GenericMassImport from '../../@shared/mass-import';
import { MassImportUpdateInstructions } from './components/MassImportUpdateInstructions';

import type { ColumnMetadata } from '../../@shared';

const ITEM_TYPES = {
  DEFAULT: '0',
  CONSUMABLE: '1',
  RESTRICTED: '2',
};

const columnMetadata: ColumnMetadata[] = [
  {
    columnName: 'id',
    displayName: 'ID',
    editable: { type: 'number' },
    validate: Validates(presence({ of: 'id' })),
    style: {
      width: 'auto',
    },
  },
  {
    columnName: 'name',
    displayName: 'Nombre',
    editable: { type: 'text' },
    style: {
      width: 'auto',
    },
  },
  {
    columnName: 'internal_sku',
    displayName: 'SKU Interno',
    editable: { type: 'text' },
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'external_sku',
    displayName: 'SKU Externo',
    editable: { type: 'text' },
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'type',
    displayName: 'Tipo',
    editable: {
      type: 'select',
      props: {
        options: [
          { value: ITEM_TYPES.DEFAULT, text: 'Por Defecto' },
          { value: ITEM_TYPES.CONSUMABLE, text: 'Consumible' },
          { value: ITEM_TYPES.RESTRICTED, text: 'Restringido' },
        ],
      },
    },
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'price',
    displayName: 'Precio',
    editable: { type: 'number' },
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'currency',
    displayName: 'Moneda',
    editable: { type: 'text' },
    style: {
      width: '12%',
    },
  },
  {
    columnName: 'unit_id',
    displayName: 'Unidad',
    // editable and validate: --> Check componentDidMount
    style: {
      width: '12%',
    },
  },
];

const tableConfig = {
  columnMetadata: columnMetadata,
  trackBy: '_id',
};

const headerMap = {
  id: ({ id }) => id,
  name: ({ name }) => name,
  internal_sku: ({ internal_sku }) => internal_sku,
  external_sku: ({ external_sku }) => external_sku,
  // eslint-disable-next-line eqeqeq
  consumable: ({ type }) => (type ? type === ITEM_TYPES.CONSUMABLE : null),
  restricted: ({ type }) => (type ? type === ITEM_TYPES.RESTRICTED : null),
  price: ({ price }) => price,
  currency: ({ currency }) => currency,
  unit_id: ({ unit_id }) => unit_id,
};

export class ItemsUpdateMassImport extends Component<*, *> {
  async componentDidMount() {
    const { status: unitStatus, body: units } = await getUnits();

    let unitOpts = [];

    if (unitStatus === 200) {
      unitOpts = this.convertToOptions(units);
    }

    tableConfig.columnMetadata[7] = {
      ...tableConfig.columnMetadata[7],
      editable: {
        type: 'select',
        props: {
          options: [{ text: 'Seleccione...' }, ...unitOpts],
        },
      },
      validate: Validates(
        oneOf({
          of: 'unit_id',
          if: ({ type }) => type === ITEM_TYPES.CONSUMABLE,
          alternatives: ['Seleccione...', ...unitOpts.map((o) => o.value)],
        }),
      ),
    };
  }

  convertToOptions(elements: Object[]) {
    return elements.map(({ id, name }) => ({
      value: `${id}`,
      text: name,
    }));
  }

  render() {
    return (
      <GenericMassImport
        entity="Artículos"
        instructions={MassImportUpdateInstructions}
        valueMapping={headerMap}
        request={massImportUpdateItems}
        tableConfig={tableConfig}
      />
    );
  }
}
