import React from 'react';

import { FontAwesome } from '@shared';

const ShowBool = ({ value, ...otherProps }) =>
  value ? (
    <span className="text-success" {...otherProps}>
      <FontAwesome name="check" />
    </span>
  ) : (
    <span className="text-danger" {...otherProps}>
      <FontAwesome name="times" />
    </span>
  );

export default ShowBool;
