import React, { Component } from 'react';
import { Table } from 'reactstrap';

import { editTag, deleteTag, deleteTagImei, putTagImei } from '@core/building/tags';
import { connect, notificationsGenerators } from '@store';
import { ShowForRoles } from '@shared';

import TagRow from './TagRow';

class TagsTable extends Component {
  static defaultProps = {
    showAssetColumn: true,
    showState: true,
  };

  state = {
    displayConfirmBox: true,
    tags: this.props.tags,
  };

  removeTag = async (index) => {
    const tag = this.state.tags[index];
    const { tags } = this.state;

    const { status } = await deleteTag(tag.id);

    if (status === 204) {
      this.props.deleteNotification();

      this.setState({
        tags: [...tags.slice(0, index), ...tags.slice(index + 1)],
      });
    }
  };

  toggleTag = async (index) => {
    const tag = this.state.tags[index];

    const { tags } = this.state;

    const { status } = await editTag({
      id: tag.id,
      active: !tag.active,
    });

    if (status === 200) {
      const newTag = { ...tag, active: !tag.active };

      tag.active ? this.props.deactivateNotification() : this.props.activateNotification();

      this.setState({
        tags: tags.map((x) => (x.id === tag.id ? newTag : x)),
      });

      this.props.setTagStatus && this.props.setTagStatus();
    }
  };

  toggleImei = async (index) => {
    const tag = this.state.tags[index];

    const { tags } = this.state;

    const toggleFunction = tag.imeiPresent ? deleteTagImei : putTagImei;

    const { status } = await toggleFunction(tag.id, tag.identifier);

    if (status === 200) {
      const newTag = { ...tag, active: !tag.active };

      tag.active ? this.props.deactivateNotification() : this.props.activateNotification();

      this.setState({
        tags: tags.map((x) => (x.id === tag.id ? newTag : x)),
      });

      this.props.setTagStatus && this.props.setTagStatus();
    }
  };

  toogleConfirmBox = () => {
    this.setState({
      displayConfirmBox: !this.state.displayConfirmBox,
    });
  };

  render() {
    const { tags, displayConfirmBox } = this.state;
    const { memberships, showAssetColumn, showState } = this.props;

    return (
      <div>
        <Table>
          <colgroup>
            <col style={{ width: '25%' }} />
            {showAssetColumn && <col style={{ width: '300px' }} />}
            <col style={{ width: 'auto' }} />
            <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
              <col style={{ width: '150px' }} />
            </ShowForRoles>
          </colgroup>
          <thead>
            <tr>
              <th>Identificador</th>
              {showAssetColumn && <th className="text-center">Activo asociado</th>}
              <th className="text-center">Información adicional</th>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                <th />
              </ShowForRoles>
            </tr>
          </thead>
          <tbody>
            {tags.length === 0 && (
              <tr>
                <td colSpan="5" className="text-center">
                  <em>No hay tags creados o disponibles de momento.</em>
                </td>
              </tr>
            )}

            {tags.map((tag, index) => (
              <TagRow
                key={index}
                tag={tag}
                memberships={memberships}
                toggleTag={() => this.toggleTag(index)}
                toggleImei={() => this.toggleImei(index)}
                removeTag={() => this.removeTag(index)}
                toogleConfirmBox={this.toogleConfirmBox}
                displayConfirmBox={displayConfirmBox}
                showAssetColumn={showAssetColumn}
                showState={showState}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  deleteNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo eliminado',
        text: 'El dispositivo ha sido eliminado correctamente.',
        color: 'success',
      }),
    ),
  activateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Tag activado',
        text: 'El tag ha sido activado exitosamente.',
        color: 'success',
      }),
    ),
  deactivateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Tag desactivado',
        text: 'El tag ha sido desactivado exitosamente.',
        color: 'danger',
      }),
    ),
}))(TagsTable);
