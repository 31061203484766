import { deleteLocation, getLocations } from '@core/organization/locations';
import { FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { connect } from '@store';
import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { LocationFilterModal } from './components/LocationFilterModal';
import LocationsResults from './components/LocationsResults';

class LocationsIndexPresentation extends SimpleFilter {
  searchFunction = (query) => {
    return getLocations(this.props.currentOrganizationId, query);
  };

  onRemove = async (location) => {
    const { status } = await deleteLocation(location);

    if (status === 204) {
      this.handleFilter(this.state.filters, true);
    }
  };

  render() {
    const { id } = this.state;

    if (id) {
      return <Redirect to={`/organization/locations/${id}`} />;
    }

    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles excluded={['guest']}>
                <Button tag={NavLink} to="/organization/locations/new" role="button" size="sm" outline color="success">
                  <FontAwesome name="plus" /> Crear Geocerca
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="dot-circle" className="text-primary mr-2" /> Gestión de Geocercas
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <LocationsResults locations={items} pagination={pagination} onRemove={this.onRemove} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <LocationFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}

const enhance = compose(
  connect(({ session }) => {
    let organizationId = undefined;

    if (session.currentMembership && session.user) {
      const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

      if (membership) organizationId = membership.building.organization.id;
    }

    return { currentOrganizationId: organizationId };
  }),
);

export const LocationsIndex = enhance(LocationsIndexPresentation);
