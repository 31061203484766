// import { PictureDisplay } from '@shared';
import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';

export const CostAccountHeader = ({ costAccount }) => (
  <Card className="mb-4">
    <CardBody>
      <Media className="align-items-center">
        <Media body>
          <h4>{`${costAccount.code} - ${costAccount.name}`}</h4>

          <div className="small">
            Descripción: <span>{costAccount.description}</span>
          </div>
        </Media>
      </Media>
    </CardBody>
  </Card>
);
