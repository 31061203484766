import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getTasks = (query = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getTask = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${id}`,
      method: 'GET',
    }),
  );

export const createTask = ({ ...task }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks`,
      method: 'POST',
      body: {
        task,
      },
    }),
  );

export const editTask = ({ id, ...task }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${id}`,
      method: 'PUT',
      body: {
        task,
      },
    }),
  );

export const toggleStarred = (taskId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${taskId}/starred`,
      method: 'POST',
    }),
  );

export const toggleComplete = (task) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${task.id}/complete`,
      method: 'POST',
      body: {
        effectiveHours: task.effectiveHours,
      },
    }),
  );

export const toggleCompleteSubtask = (subtask) => {
  return handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${subtask.taskId}/complete_subtask`,
      method: 'POST',
      body: {
        subtask_id: subtask.id,
        effectiveHours: subtask.effectiveHours,
      },
    }),
  );
};

export const deleteTask = (taskId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${taskId}`,
      method: 'DELETE',
    }),
  );

export const getOrganizationTasks = (query = {}) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/organization${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const postponeTask = (taskId, userId, reason, newDate) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${taskId}/postpone_task`,
      method: 'POST',
      body: {
        userId,
        reason,
        newDate,
      },
    }),
  );

export const startTask = (taskId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${taskId}/start_task`,
      method: 'POST',
    }),
  );

export const unStartTask = (taskId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${taskId}/un_start_task`,
      method: 'POST',
    }),
  );

export const cancelTask = (taskId, reason) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${taskId}/cancel_task`,
      method: 'POST',
      body: {
        reason,
      },
    }),
  );

export const unCancelTask = (taskId, reason) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tasks/${taskId}/un_cancel_task`,
      method: 'POST',
      body: {
        reason,
      },
    }),
  );
