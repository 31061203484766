import React from 'react';
import { compose } from 'recompose';
import { connect, notificationsGenerators } from '@store';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button, Row, Col } from 'reactstrap';
import { ProcessingButton } from '@shared/';
import { createMovement, getMovements } from '@core/building/movements';
import { omit, capitalize } from 'lodash';
import { reduxForm } from 'redux-form';
import { Field, Form } from '@core/forms';

const enhance = compose(
  reduxForm({
    form: 'cancelMROForm',
  }),
  connect(
    () => {},
    (dispatch) => ({
      successNotification: () =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Movimiento creado',
            text: 'Se ha cancelado el proceso exitosamente.',
            color: 'success',
          }),
        ),
      failNotification: (errors) =>
        dispatch(
          notificationsGenerators.insert({
            title: 'No se pudo cancelar el proceso MRO',
            text: `${errors?.title}. Ocurrió un error al intentar cancelar el proceso MRO actual`,
            color: 'danger',
          }),
        ),
      resetForm() {
        dispatch({
          type: '@@redux-form/RESET',
          meta: {
            form: 'cancelMROForm',
          },
        });
      },
    }),
  ),
);

class CancelMROModalPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      comments: undefined,
    };
  }

  async handleSubmit() {
    const { bpmType, assetRepair } = this.props;

    const data = {
      assetBarcode: assetRepair.asset.barcode1,
      extendedAttributes: {
        repairId: assetRepair.repairId,
        [`asset${capitalize(bpmType)}Id`]: assetRepair.id,
        humanHours: null,
      },
      movementType: `${bpmType}_canceled`,
      ...omit(this.state, 'error'),
    };
    this.setState({ submitting: true });

    const { body, status } = await createMovement(data);

    if (status === 201) {
      const asset = await this.BpmSearcher(assetRepair.id);
      const movements = await this.getMovementsOfAsset(assetRepair.id);
      this.toggleModal(null, asset, movements);
      this.props.successNotification();
    } else if (status === 422) {
      this.setState({ error: body.errors });
      this.props.failNotification(body.errors);
    }
    this.setState({ submitting: false });
  }

  toggleModal = (e, assetRepair, movements) => {
    this.props.toggle(e, assetRepair, movements);
    this.setState({
      error: null,
      comments: undefined,
    });
  };

  async BpmSearcher(assetRepairId) {
    const { BpmSearcher } = this.props;

    const { body, status } = await BpmSearcher(assetRepairId);

    if (status === 200) return body;

    return null;
  }

  async getMovementsOfAsset(assetRepairId) {
    const searcher = getMovements;
    const { bpmType } = this.props;
    const { body, status } = await searcher({ [`asset_${bpmType}_id`]: assetRepairId });

    if (status === 200) return body;

    return null;
  }

  setComments = (value) => {
    this.setState({ comments: value });
  };

  render() {
    const { isOpen, className } = this.props;
    const { submitting, error } = this.state;

    return (
      <div>
        <Modal isOpen={isOpen} toggle={this.toggleModal} className={className} size={this.size}>
          <ModalHeader toggle={this.toggleModal}>Cancelar MRO</ModalHeader>
          <ModalBody>
            Se cancelará el actual flujo de MRO del asset. ¿Está seguro de continuar con esta acción?
            <Form error={error} onSubmit={this.handleSubmit}>
              <Row form>
                <Col md={12}>
                  <Field
                    name="comments"
                    label="Comentarios"
                    rows="6"
                    type="textarea"
                    help="Cualquier comentario que explique la razón de la cancelación."
                    onChange={(event, value) => this.setComments(value)}
                    placeholder={'Explica la razón de la cancelación...'}
                  />
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.toggleModal}>
              No
            </Button>
            <ProcessingButton
              type="submit"
              role="button"
              color="danger"
              onClick={() => this.handleSubmit()}
              processing={submitting}
            >
              Sí
            </ProcessingButton>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export const CancelMROModal = enhance(CancelMROModalPresentation);
