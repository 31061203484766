import {
  cancelRecovery,
  clearConfirmation,
  clearLock,
  confirmEmail,
  confirmInvitation,
  redeliverInvitation,
  redeliverLockMail,
  redeliverRecovery,
} from '@core/admin/users';
import React from 'react';
import { ActionPanel } from './ActionPanel';

export const UserActions = ({ user }) => (
  <div className="mb-4">
    {/* Actions panel for pending invitation confirmation */}
    <ActionPanel
      condition={user.status === 'invitation_pending'}
      color="info"
      actions={[
        {
          title: 'Reenviar',
          performedTitle: 'Reenviado',
          color: 'secondary',
          action: () => redeliverInvitation(user.id),
        },
        {
          title: 'Confirmar invitación',
          performedTitle: 'Invitación confirmada',
          color: 'info',
          action: () => confirmInvitation(user.id),
        },
      ]}
    >
      <h4 className="alert-heading">Invitación pendiente</h4>
      <p>
        Este usuario no ha confirmado todavía la invitación enviada a su correo. Antes de iniciar sesión y usar su
        cuenta, debe seguir las indicaciones.
      </p>
      <p>Puedes sobreescribir este comportamiento confirmando la cuenta a continuación o bien reenviando el correo.</p>
    </ActionPanel>

    {/* Actions panel for pending email confirmation */}
    <ActionPanel
      condition={user.status === 'confirmation_pending'}
      color="warning"
      actions={[
        {
          title: 'Cancelar',
          performedTitle: 'Cancelado',
          color: 'secondary',
          action: () => clearConfirmation(user.id),
        },
        {
          title: 'Confirmar correo',
          performedTitle: 'Correo confirmado',
          color: 'warning',
          action: () => confirmEmail(user.id),
        },
      ]}
    >
      <h5>Confirmación pendiente</h5>
      <p>
        Este usuario cambió su dirección de correo recientemente, y debe confirmarla antes de usar su cuenta. Se puede
        cancelar esta solicitud a continuación o bien confirmar la nueva dirección automáticamente.
      </p>
    </ActionPanel>

    {/* Actions panel for pending password reset */}
    <ActionPanel
      condition={user.status === 'password_reset_pending'}
      color="warning"
      actions={[
        {
          title: 'Reenviar',
          performedTitle: 'Reenviado',
          color: 'secondary',
          action: () => redeliverRecovery(user.id),
        },
        {
          title: 'Deshacer solicitud',
          performedTitle: 'Solicitud cancelada',
          color: 'warning',
          action: () => cancelRecovery(user.id),
        },
      ]}
    >
      <h5>Recuperación de contraseña pendiente</h5>
      <p>
        Este usuario se encuentra en proceso de recuperación de contraseña. Puedes deshacer esta solicitud a
        continuación, reenviar el correo o bien deshacer esta solicitud. También puedes cambiar la contraseña
        manualmente en el formulario más abajo.
      </p>
    </ActionPanel>

    {/* Actions panel for locked account */}
    <ActionPanel
      condition={user.status === 'locked'}
      color="danger"
      actions={[
        {
          title: 'Reenviar',
          performedTitle: 'Reenviado',
          color: 'secondary',
          action: () => redeliverLockMail(user.id),
        },
        {
          title: 'Desbloquear cuenta',
          performedTitle: 'Cuenta desbloqueada',
          color: 'danger',
          action: () => clearLock(user.id),
        },
      ]}
    >
      <h5>Cuenta bloqueada</h5>
      <p>
        Esta cuenta ha sido bloqueada debido a que se excedió el número máximo de intentos fallidos de inicio de sesión.
        Debido a la probabilidad de un ataque de fuerza bruta, se han bloqueado los subsecuentes intentos de conexión.
      </p>
      <p>
        Se ha enviado un usuario al correo para desbloquear su cuenta. Puedes reenviar este correo o bien desbloquear
        manualmente la cuenta presionando los botones más abajo.
      </p>
    </ActionPanel>
  </div>
);
