import React from 'react';
import { compose, withHandlers } from 'recompose';
import type { HOC } from 'recompose';

import { EditableTextField, EditableSelectField } from '../editable';
import type { ColumnMetadata } from './types';

type Props = {
  columnMetadata: ColumnMetadata[],
  data: Object,
  editable: boolean,
  onChangeGrid: Function,
  onEditRow: Function,
  rowId: number,
  selectedRows: Object,
  showCheckbox: boolean,
  onToggleEdit: Function,
};

const enhance: HOC<*, Props> = compose(
  withHandlers({
    handleOnEditRow: ({ onEditRow }) => (updatedData: Object) => {
      onEditRow && onEditRow(updatedData);
    },

    handleOnSelect: ({ data, onChangeGrid, rowId, selectedRows }) => () => {
      let selected = selectedRows;

      if (selected[rowId]) {
        // FIXME: Use destructuring
        // const { [rowId]: temp, ...rest } = selected
        // selected = rest
        delete selected[rowId];
      } else {
        selected[rowId] = {
          rowId: rowId,
          data: data,
        };
      }

      onChangeGrid({
        selectedRows: selected,
      });
    },

    handleToggle: ({ onToggleEdit }) => (isEditing) => {
      if (onToggleEdit) {
        onToggleEdit(isEditing);
      }
    },
  }),
);

const GridRow = ({
  // props
  columnMetadata,
  data,
  editable,
  rowId,
  showCheckbox,
  selectedRows,
  // handlers
  handleOnEditRow,
  handleOnSelect,
  handleToggle,
}) => {
  const checked = selectedRows[rowId] ? true : false;

  return (
    <tr>
      {showCheckbox && (
        <td style={{ textAlign: 'center', width: '40px' }}>
          <input type="checkbox" className="checkboxContainer" checked={checked} onChange={handleOnSelect} />
        </td>
      )}
      {columnMetadata.map(({ columnName, displayName, render, editable: columnEditable, validate }) => {
        let child;

        const validationErrors = validate ? validate(data) : {};
        const isValid = Object.keys(validationErrors).length === 0;

        let className = isValid ? '' : 'has-error';

        const id = `td-${columnName}-${rowId}`;

        if (editable && columnEditable && columnEditable.type) {
          className += ' editable-cell';

          switch (columnEditable.type) {
            case 'select':
              child = (
                <EditableSelectField
                  id={id}
                  name={columnName}
                  toggle={true}
                  data={data}
                  validate={validate}
                  {...columnEditable.props}
                  value={render ? render(data) : data[columnName]}
                  onToggle={handleToggle}
                  onUpdate={(key, value) => {
                    data[key] = value;
                    handleOnEditRow(data);
                  }}
                />
              );
              break;

            case 'text':
            default:
              child = (
                <EditableTextField
                  id={id}
                  name={columnName}
                  placeholder={displayName}
                  toggle={true}
                  data={data}
                  validate={validate}
                  value={render ? render(data) : data[columnName]}
                  onToggle={handleToggle}
                  onUpdate={(key, value) => {
                    data[key] = value;
                    handleOnEditRow(data);
                  }}
                />
              );
              break;
          }
        } else {
          child = render ? render(data) : data[columnName];
        }

        const title = isValid ? null : Object.values(validationErrors).join('\n');

        return (
          <td title={title} key={id} className={className}>
            {child}
          </td>
        );
      })}
    </tr>
  );
};

export default enhance(GridRow);
