import { getDevices } from '@core/building/devices';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { DevicesFilterModal } from './components/DevicesFilterModal';
import { DevicesResults } from './components/DevicesResults';

export class DevicesIndex extends SimpleFilter {
  searchFunction = getDevices;

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="mobile-alt" className="mr-2 text-primary" /> Gestión de Dispositivos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <DevicesResults devices={items} pagination={pagination} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DevicesFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
