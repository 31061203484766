import { deleteDevice, updateDevice } from '@core/admin/devices';
import { pluralize } from '@shared/helpers';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { DeviceRow } from './DeviceRow';

class DevicesResultsPresentation extends Component {
  state = {
    displayConfirmBox: true,
    devices: this.props.devices,
  };

  softDelete = async (index) => {
    const device = this.state.devices[index];
    const { devices } = this.state;

    const { status } = await deleteDevice(device.barcode1);

    if (status === 204) {
      this.props.deleteNotification();

      this.setState({
        devices: [...devices.slice(0, index), ...devices.slice(index + 1)],
      });
    } else {
      this.props.errorNotification();
    }
  };

  toggleStatus = async (index) => {
    const device = this.state.devices[index];
    const barcode = device.barcode1;
    device.active = !device.active;

    const { devices } = this.state;

    const { status } = await updateDevice({
      barcode,
      ...device,
    });

    if (status === 200) {
      const newDevice = { ...device, active: device.active };

      device.active ? this.props.activateNotification() : this.props.deactivateNotification();

      this.setState({
        devices: devices.map((x) => (x.id === device.id ? newDevice : x)),
      });

      this.props.setDeviceStatus && this.props.setDeviceStatus();
    }
  };

  toogleConfirmBox = () => {
    this.setState({
      displayConfirmBox: !this.state.displayConfirmBox,
    });
  };

  render() {
    const { devices, displayConfirmBox } = this.state;
    const { pagination } = this.props;

    return (
      <div>
        <div className="small mb-4">
          <strong>{pagination.count}</strong>{' '}
          {pluralize(pagination.count, 'dispositivo encontrado', 'dispositivos encontrados')}. Mostrando{' '}
          <strong>{pagination.per}</strong> dispositivos por página.
        </div>

        <Table>
          <colgroup>
            <col style={{ width: 'auto' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: 'auto' }} />
            <col style={{ width: '150px' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Identificador</th>
              <th>Dispositivo</th>
              <th className="text-center">Tipo</th>
              <th className="text-center">Última Conexión</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {devices.map((device, index) => (
              <DeviceRow
                key={index}
                device={device}
                toggleDevice={() => this.toggleStatus(index)}
                softDelete={() => this.softDelete(index)}
                toogleConfirmBox={this.toogleConfirmBox}
                displayConfirmBox={displayConfirmBox}
              />
            ))}

            {devices.length === 0 && (
              <tr>
                <td colSpan="4" className="text-center mt-3 mb-3">
                  <em>No se encontraron dispositivos.</em>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export const DevicesResults = connect(null, (dispatch) => ({
  activateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo activado',
        text: 'El dispositivo ha sido activado exitosamente.',
        color: 'success',
      }),
    ),
  deactivateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo desactivado',
        text: 'El dispositivo ha sido desactivado exitosamente.',
        color: 'danger',
      }),
    ),
  deleteNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo eliminado',
        text: 'El dispositivo ha sido eliminado correctamente.',
        color: 'success',
      }),
    ),
  errorNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error al eliminar.',
        text: 'Si el error persiste consulte con el administrador.',
        color: 'danger',
      }),
    ),
}))(DevicesResultsPresentation);
