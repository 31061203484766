import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Row, Col } from 'reactstrap';

import { Field, Form, SubmissionError } from '@core/forms';
import { updateAccount, getAccount } from '@core/account';
import Validates, { passwordsMatching, presence } from '@core/forms/validations';
import { connect, notificationsGenerators, sessionGenerators } from '@store';
import { ProcessingButton } from '@shared';

class Password extends Component {
  state = {
    processing: false,
  };

  editAccount = async (formData) => {
    this.setState({
      processing: true,
    });

    const { currentPassword, email, password, passwordConfirmation } = formData;

    const { status, body } = await updateAccount({
      currentPassword,
      email,
      password,
      passwordConfirmation,
    });

    if (status === 200 || status === 204) {
      this.props.successNotification();

      this.props.updateUser({
        email,
      });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }

    const { updateBody, updateStatus } = await getAccount();

    if (updateStatus === 200) this.props.updateUser(updateBody);
  };

  render() {
    const userData = { email: this.props.user.email };

    return (
      <PasswordForm
        initialValues={userData}
        onSubmit={this.editAccount}
        processing={this.state.processing}
        errors={this.state.errors}
      />
    );
  }
}

const validate = Validates(
  presence({ of: 'email' }),
  presence({ of: 'currentPassword' }),
  passwordsMatching('password', 'passwordConfirmation'),
);

const PasswordFormPresentation = ({ handleSubmit, invalid, submitting, pristine, error }) => {
  return (
    <Form error={error} noValidate onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field label="Nueva contraseña" name="password" type="password" required />
        </Col>
        <Col>
          <Field label="Confirmar contraseña" name="passwordConfirmation" type="password" required />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field label="Email" name="email" required />
        </Col>
        <Col>
          <Field label="Contraseña actual" name="currentPassword" type="password" required />
        </Col>
      </Row>
      <div className="text-center">
        <ProcessingButton
          type="submit"
          role="button"
          color="primary"
          outline
          disabled={pristine || invalid}
          processing={submitting}
          width="10em"
        >
          Actualizar
        </ProcessingButton>
      </div>
    </Form>
  );
};

const PasswordForm = reduxForm({
  form: 'passwordForm',
  validate,
})(PasswordFormPresentation);

export default connect(
  (state) => ({ user: state.session.user }),
  (dispatch) => ({
    successNotification: () => {
      dispatch(
        notificationsGenerators.insert({
          title: 'Cuenta actualizada',
          text: 'Tu cuenta ha sido actualizada exitosamente.',
          color: 'success',
        }),
      );
    },
    updateUser: (data) => {
      dispatch(sessionGenerators.updateUser(data));
    },
  }),
)(Password);
