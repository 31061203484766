export const NotificationsActions = {
  Insert: 'Notifications.Insert',
  Remove: 'Notifications.Remove',
};

export const notificationsInitialState = {
  notifications: [],
  idCounter: 1,
};

export const notifications = (state = notificationsInitialState, action) => {
  switch (action.type) {
    case NotificationsActions.Insert:
      return {
        ...state,
        notifications: [...state.notifications, { ...action.payload, id: state.idCounter }],
        idCounter: state.idCounter + 1,
      };
    case NotificationsActions.Remove:
      let index = state.notifications.findIndex((notification) => notification.id === action.payload.id);

      if (index === -1) return state;

      return {
        ...state,
        notifications: [...state.notifications.slice(0, index), ...state.notifications.slice(index + 1)],
      };
    default:
      return { ...state };
  }
};

export const notificationsGenerators = {
  insert: ({ title, text, color, duration }) => ({
    type: NotificationsActions.Insert,
    payload: { title, text, color, duration },
  }),
  remove: (id) => ({
    type: NotificationsActions.Remove,
    payload: { id },
  }),
};
