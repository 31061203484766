import { handleAuth, withToken, apiFetch } from '../http';

export const createMembership = ({ userId, buildingId, role, active = true }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/users/${userId}/memberships`,
      method: 'POST',
      body: {
        buildingId,
        role,
        active,
      },
    }),
  );

export const destroyMembership = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/memberships/${id}`,
      method: 'DELETE',
    }),
  );
