import { Field, Form } from '@core/forms';
import { Row, Col } from 'reactstrap';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { SinglePictureComponent } from '@shared/forms';
import React from 'react';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'name' }),
  presence({ of: 'contactName' }),
  presence({ of: 'contactEmail' }),
  presence({ of: 'avatarToken' }),
);

const OrganizationFormPresentation = ({ handleSubmit, invalid, submitting, pristine, error }) => {
  return (
    <Form error={error} noValidate onSubmit={handleSubmit}>
      <Row>
        <Col xs="12" md="6">
          <Row>
            <Col>
              <hr />
            </Col>
            <Col xs="auto">
              <h5>Información básica</h5>
            </Col>
            <Col>
              <hr />
            </Col>
          </Row>
          <Field name="name" type="text" label="Nombre de la organización" required />
          <Field
            name="active"
            type="checkbox"
            label=" ¿Organización activa?"
            help="Desmarcar esta opción hará que ningún usuario no administrador pueda acceder a las características de la organización. Utilizar con cautela."
          />
          <Field
            name="podStatus"
            type="checkbox"
            label=" Suscripción a POD"
            help="Marcar esta opción en caso que la organización suscriba el servicio de POD."
          />
          <Field
            name="hasCostAccounts"
            type="checkbox"
            label=" Tiene centros de costo"
            help="Marcar esta opción en caso que la organización quiera utilizar centros de costo"
          />
          <Field
            name="avatarToken"
            component={SinglePictureComponent((token) => `/avatars/${token}`, '/avatars')}
            label="Logo de la organización"
            required
          />
        </Col>
        <Col xs="12" md="6">
          <Row>
            <Col>
              <hr />
            </Col>
            <Col xs="auto">
              <h5>Información de contacto</h5>
            </Col>
            <Col>
              <hr />
            </Col>
          </Row>
          <Field name="contactName" type="text" label="Nombre del encargado" required />

          <Field name="contactEmail" type="text" label="Correo de contacto del encargado" required />

          <Field name="contactPhone" type="text" label="Teléfono de contacto del encargado" />
        </Col>
      </Row>
      <div className="text-center">
        <ProcessingButton
          type="submit"
          color="primary"
          outline
          width="10em"
          disabled={pristine || invalid}
          processing={submitting}
        >
          Guardar
        </ProcessingButton>
      </div>
    </Form>
  );
};

export const OrganizationForm = reduxForm({
  form: 'organizationForm',
  validate,
})(OrganizationFormPresentation);
