import { FontAwesome } from '@shared/';
import React from 'react';
import { Media } from 'reactstrap';

export const EventArea = ({ area }) => (
  <Media>
    {area && (
      <Media body>
        {area.name && (
          <div className="w-100 text-overflow mt-1 align-middle small">
            <FontAwesome name="layer-group" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{area.name}</span>
          </div>
        )}
        {area.location && (
          <div className="w-100 text-overflow mt-1 align-middle small">
            <FontAwesome name="map-marker-alt" color="info" className="mr-1" />
            <span className="text-muted">{area.location}</span>
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventAreaModal = ({ area }) => (
  <div>
    <Media>
      {area && (
        <Media body>
          {area.name && (
            <div className="w-100 text-overflow mt-1">
              <FontAwesome name="layer-group" color="info" className="mr-1" />
              <span className="asset-name font-weight-bold">{area.name}</span>
            </div>
          )}
          {area.location && (
            <div className="w-100 text-overflow mt-1">
              <FontAwesome name="map-marker-alt" color="info" className="mr-1" />
              <span className="text-muted">{area.location}</span>
            </div>
          )}
        </Media>
      )}
    </Media>
  </div>
);
