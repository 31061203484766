import { getItem, mergeItems } from '@core/building/items';
import { ProcessingButton } from '@shared/';
import { FontAwesome } from '@shared/';
import { ItemDisplay } from '@shared/displays';
import { ItemSelector } from '@shared/forms';
import { BuildingLayout } from '@shared/layouts';
import React, { useState } from 'react';
import { Button, Card, CardBody, CardFooter, Col, Label, Row, Input } from 'reactstrap';
import { Field, Form } from '@core/forms';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { MergeDisclaimerModal } from './components/MergeDisclaimerModal';
import { MergeResultStatusModal } from './components/MergeResultStatusModal';

const enhance = compose(
  reduxForm({
    form: 'mergeItem',
  }),
);

const MergeItemsFormPresentation = () => {
  const [consumable, setConsumable] = useState('');
  const [master, setMaster] = useState(undefined);
  const [duplicates, setDuplicates] = useState([]);
  const [duplicateBarcode, setDuplicateBarcode] = useState(undefined);
  const [barcodes, setBarcodes] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirectTo, setRedirectTo] = useState(undefined);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(true);
  const [isResultOpen, setIsResultOpen] = useState(false);
  const [statusResponse, setStatusResponse] = useState(false);
  const [message, setMessage] = useState(false);

  // Agrega un nuevo asset al reporte
  const addDuplicate = async (barcode) => {
    if (duplicates.some((duplicate) => duplicate.barcode1 === barcode)) {
      alert('El artículo ya se agregó');
      setDuplicateBarcode(undefined);
    } else {
      const { body, status } = await getItem(barcode);
      if (status === 200 || status === 304) {
        setDuplicates([...duplicates, body]);
        setBarcodes([...duplicates, body].map((item) => item.barcode1));
        setDuplicateBarcode(undefined);
      } else {
        console.log(body.errors);
      }
    }
  };

  const submitMerge = async () => {
    setSubmitting(true);
    const data = {
      consumable,
      barcodes,
      master,
    };
    const { body, status } = await mergeItems(data);
    setStatusResponse(status);
    setIsResultOpen(true);
    if (status === 200) {
      setSubmitting(false);
    } else {
      setMessage(body.error);
      setSubmitting(false);
    }
  };

  const removeDuplicate = (barcode) => {
    setDuplicates([...duplicates.filter((duplicate) => duplicate.barcode1 !== barcode)]);
  };

  const clearForm = () => {
    setConsumable('');
    setMaster(undefined);
    setDuplicates([]);
    setDuplicateBarcode(undefined);
  };

  const onDisclaimerToggle = () => {
    setIsDisclaimerOpen(!isDisclaimerOpen);
  };

  const onResultToggle = () => {
    setIsResultOpen(!isResultOpen);
  };

  return (
    <BuildingLayout>
      <Row>
        <Col>
          <h4 className="mb-4">
            <FontAwesome name="clone" className="text-primary mr-2" /> Combinación de Artículos
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form noValidate>
            <Card>
              <CardBody>
                <Row className="mb-3">
                  <Col md={6}>
                    <Label for="master">Tipo de artículos *</Label>
                    <Input
                      type="select"
                      name="consumable"
                      onChange={(e) => setConsumable(e.target.value)}
                      value={consumable}
                      required
                    >
                      <option value="">Seleccione...</option>
                      <option value="true">Consumible</option>
                      <option value="false">No Consumible</option>
                    </Input>
                  </Col>
                </Row>
                {consumable !== '' && (
                  <Row className="mb-3">
                    <Col md={6}>
                      <Field
                        name="master"
                        label="Seleccione el artículo maestro"
                        help={<span>Este es el artículo será el que se conserve al finalizar el merge </span>}
                        component={ItemSelector}
                        filters={{ consumable }}
                        required
                        value={master}
                        componentOnChange={(barcode, item) => {
                          setMaster(barcode);
                          setRedirectTo(`/organization/items/${barcode}`);
                        }}
                      />
                    </Col>
                  </Row>
                )}
                {consumable !== '' && (
                  <Row>
                    <Col>
                      <Label for="duplicateInput">Seleccione artículos duplicados</Label>
                    </Col>
                  </Row>
                )}
                {consumable !== '' && (
                  <Row className="mb-3">
                    <Col>
                      <ItemSelector
                        name="duplicateInput"
                        inputProps={{ bsSize: 'md' }}
                        input={{
                          onChange: setDuplicateBarcode,
                        }}
                        filters={{ consumable }}
                      />
                    </Col>
                    <Col>
                      <Button
                        type="button"
                        role="button"
                        color="success"
                        size="md"
                        onClick={() => addDuplicate(duplicateBarcode)}
                        disabled={!duplicateBarcode}
                      >
                        <FontAwesome name="plus" /> Agregar
                      </Button>
                    </Col>
                  </Row>
                )}
                Listado de artículos a fusionar
                {consumable !== '' && (
                  <Card body style={{ minHeight: '15vh' }}>
                    {duplicates.length > 0 &&
                      duplicates.map((duplicate, index) => (
                        <Row key={`Duplicate-${index}`}>
                          <Col sm={12} md={6} className="mb-3">
                            <ItemDisplay item={duplicate} />
                          </Col>
                          <Col sm={2} md={1} className="mb-3">
                            <Button
                              type="button"
                              role="button"
                              color="danger"
                              onClick={() => removeDuplicate(duplicate?.barcode1)}
                            >
                              <FontAwesome name="times" />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                  </Card>
                )}
              </CardBody>
              <CardFooter>
                <ProcessingButton
                  type="button"
                  role="button"
                  color="primary"
                  className="mr-2"
                  onClick={submitMerge}
                  disabled={consumable === '' || !master || !duplicates.length > 0 || submitting}
                >
                  <FontAwesome name="check" /> Generar Combinación
                </ProcessingButton>
                <Button type="button" role="button" color="danger" onClick={clearForm} disabled={submitting}>
                  <FontAwesome name="times" /> Borrar formulario
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>
      <MergeDisclaimerModal isOpen={isDisclaimerOpen} onToggle={onDisclaimerToggle} />
      <MergeResultStatusModal
        isOpen={isResultOpen}
        onToggle={onResultToggle}
        message={message}
        status={statusResponse}
        redirectTo={redirectTo}
      />
    </BuildingLayout>
  );
};

export const MergeItems = enhance(MergeItemsFormPresentation);
