import { format, subMonths } from 'date-fns';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Label } from 'reactstrap';

import { getMaintenance } from '../../../@core/building/reports';
import { Highcharts } from '../../../@shared/charts';
import { DateSelector } from '../../../@shared/forms';
import { connect } from '../../../@store';

import Chart from './Chart';

const DATE_FORMAT = 'yyyy-MM-dd';

const getChartOptions = (title, data) => {
  return {
    title: {
      text: title,
    },
    credits: {
      enabled: true,
      href: 'http://www.alliot.cloud',
      text: 'alliot.cloud',
    },
    tooltip: {
      shared: true,
      pointFormat: '{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
        },
        showInLegend: false,
      },
    },
    series: [
      {
        data,
        keys: ['name', 'y'],
        type: 'pie',
      },
    ],
  };
};

class ExampleCustomInput extends React.Component {
  render() {
    return (
      <Button color="primary" onClick={this.props.onClick} size="sm">
        {this.props.value}
      </Button>
    );
  }
}

class Maintenance extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();
    this.state = {
      data: [],
      startDate: subMonths(today, 1),
      endDate: today,
      today,
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  handleResponse = (response) => {
    const { status, body } = response;
    console.log('Stats', this.state.stats);
    if (status === 200) {
      this.setState({
        data: this.mapData(body || {}),
      });
    }
  };

  mapData = (data = {}) => {
    const mro = Object.keys(data).map((movementType) => [
      this.props.mroMovements[movementType]['name'],
      data[movementType],
    ]);
    return mro;
  };

  fetchData = async () => {
    const { startDate, endDate } = this.state;

    const response = await getMaintenance({
      from: format(startDate, DATE_FORMAT),
      to: format(endDate, DATE_FORMAT),
    });

    this.handleResponse(response);
  };

  handleChangeStart = (date) => {
    this.setState(
      {
        startDate: date,
      },
      async () => {
        await this.fetchData();
      },
    );
  };

  handleChangeEnd = (date) => {
    this.setState(
      {
        endDate: date,
      },
      async () => {
        await this.fetchData();
      },
    );
  };

  render() {
    const { title } = this.props;
    const { data, startDate, endDate, today } = this.state;

    return (
      <Card>
        <CardHeader className="chart-title-container">
          <span className="float-right">
            <span
              style={{
                display: 'flex',
                verticalAlign: 'middle',
              }}
            >
              <Form inline>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label className="mr-sm-2">Desde</Label>
                  <DateSelector
                    customInput={<ExampleCustomInput />}
                    selected={startDate}
                    selectsStart
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={this.handleChangeStart}
                  />
                </FormGroup>
                <FormGroup className="mb-2 mb-sm-0">
                  <Label className="mr-sm-2">Hasta</Label>
                  <DateSelector
                    customInput={<ExampleCustomInput />}
                    selected={endDate}
                    selectsEnd
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={this.handleChangeEnd}
                  />
                </FormGroup>
              </Form>
            </span>
          </span>
          <h4>Movimientos MRO</h4>
        </CardHeader>
        <CardBody className="p-2">
          <Chart options={getChartOptions(title, data)} highcharts={Highcharts} />
        </CardBody>
      </Card>
    );
  }
}

export default connect(
  ({
    appData: {
      repair_movements: repairMovements,
      maintenance_movements: maintenanceMovements,
      evaluation_movements: evaluationMovements,
      preservation_movements: preservationMovements,
      certification_movements: certificationMovements,
      assurance_movements: assuranceMovements,
      discard_movements: discardMovements,
    },
  }) => ({
    mroMovements: {
      ...repairMovements,
      ...maintenanceMovements,
      ...evaluationMovements,
      ...preservationMovements,
      ...certificationMovements,
      ...assuranceMovements,
      ...discardMovements,
    },
  }),
)(Maintenance);
