import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap';

export const TaskCompleteModal = ({
  openCompleteTask,
  toggleCompleteTask,
  setEffectiveHours,
  closeCompleteTask,
  completeTask,
  effectiveHours,
  pristine,
  invalid,
}) => (
  <Modal isOpen={openCompleteTask} toggle={toggleCompleteTask} size="md">
    <ModalHeader>Confirmación</ModalHeader>
    <ModalBody>
      <p>
        Se dispone a completar la tarea de manera anticipada, por favor, especifique las horas efectivas utilizadas para
        completar la tarea.
      </p>
      <Label>Horas Efectivas</Label>
      <Input
        name="effectiveHours"
        placeholder="HH"
        label="Horas efectivas"
        pattern="[0-9]+"
        onChange={setEffectiveHours}
      />
    </ModalBody>
    <ModalFooter>
      <div className="text-right">
        <Button role="button" onClick={closeCompleteTask}>
          Cancelar
        </Button>{' '}
        <Button role="button" color="primary" onClick={completeTask} disabled={pristine || invalid || !effectiveHours}>
          Continuar
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);
