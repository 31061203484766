import { SubmissionError } from '@core/forms';
import { editLocation, getLocation } from '@core/organization/locations';
import { Loading } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LocationForm from './components/LocationForm';
import { buildGeofencePayload } from './utils/util';

class EditLocationPresentation extends Component {
  state = {
    loading: true,
    edited: false,
    location: undefined,
    notFound: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) this.notFound();

    const { body: location, status } = await getLocation(id);

    this.setState({ loading: false });

    if (status === 404) {
      this.notFound();
    } else if (status === 200) {
      this.setState({ location });
    }
  }

  notFound = () => {
    this.props.notFound();

    this.setState({ notFound: true });
  };

  editLocation = async (data) => {
    const payload = buildGeofencePayload(data, this.props.currentOrganizationId);
    const { body, status } = await editLocation(payload);

    if (status === 200) {
      this.setState({
        edited: true,
      });

      this.props.successNotification(data.name);
    } else if (status === 422) throw new SubmissionError(body.errors);
  };

  render() {
    const { location, edited, loading, notFound } = this.state;

    if (notFound) return <Redirect to="/organization/locations" />;

    if (loading) return <Loading />;

    if (edited) return <Redirect to="/organization/locations" />;
    console.log(location);
    return (
      <BuildingLayout>
        <SimpleContainerLayout title={`Editar área: ${location && location.name}`}>
          {location && (
            <LocationForm
              initialValues={{ ...location, color: location.feature.properties.color || '#37d67a' }}
              onSubmit={this.editLocation}
              updating={true}
            />
          )}
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let organizationId = undefined;
  const { session } = state;

  if (session.currentMembership && session.user) {
    const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

    if (membership) organizationId = membership.building.organization.id;
  }
  return {
    currentOrganizationId: organizationId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    notFound: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'No encontrado',
          text:
            'La geocerca buscada no existe o no se encuentra disponible. Si los errores persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      ),
    successNotification: (name) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Geocerca creada',
          text: `La geocerca ${name} ha sido editada exitosamente.`,
          color: 'success',
        }),
      ),
  };
};

export const EditLocation = connect(mapStateToProps, mapDispatchToProps)(EditLocationPresentation);
