import { AreaDisplay, FontAwesome, PictureDisplay, ShowBool, ShowForRoles, simpleFilterQuery } from '@shared';
import { EventsResults } from 'events/components/EventsResults';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ItemMovements } from './ItemMovements';

const imagesToShow = 3;

export const ItemSummary = ({ item, events }) => {
  const showTelemetryOrDash = (key, unit) => (item[key] ? `${item[key]} ${unit}` : '-');
  return (
    <div className="mb-4">
      <Row>
        <Col sm={12} md={7} className="mb-4">
          <h5 className="mb-3">Resumen del artículo</h5>

          <table className="w-100">
            <colgroup>
              <col style={{ width: '35%' }} />
              <col style={{ width: '75%' }} />
            </colgroup>
            <tbody>
              <tr>
                <td className="text-right align-top pr-3">Nombre</td>
                <td className="align-top text-muted">{item.name}</td>
              </tr>
              <tr>
                <td className="text-right align-top pr-3">Marca</td>
                <td className="align-top text-muted">{item.brand || 'No identificado'}</td>
              </tr>
              <tr>
                <td className="text-right align-top pr-3">Modelo</td>
                <td className="align-top text-muted">{item.model || 'No identificado'}</td>
              </tr>
              <tr>
                <td className="text-right align-top pr-3">Stock Recomendado</td>
                <td className="align-top text-muted">
                  {item.recommendedStock ? item.recommendedStock : 'No definido'}
                </td>
              </tr>
              <tr>
                <td className="text-right align-top pr-3">Identificadores</td>
                <td className="align-top text-muted small">
                  <div className="mb-1">
                    <kbd>{item.barcode1}</kbd>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="text-right align-top pr-3">SKU Interno</td>
                <td className="align-top text-muted">{item.internalSku}</td>
              </tr>

              {item.consumable && (
                <tr>
                  <td className="text-right align-top pr-3">Unidad</td>
                  <td className="align-top text-muted">
                    <code>{item.unit ? item.unit.symbol : '-'}</code>
                  </td>
                </tr>
              )}

              {item.externalSku && (
                <tr>
                  <td className="text-right align-top pr-3">SKU Externo</td>
                  <td className="align-top text-muted">{item.externalSku}</td>
                </tr>
              )}
              {item.consumable && item.criticality && (
                <tr>
                  <td className="text-right align-top pr-3">Criticidad</td>
                  <td className="align-top text-muted">{item.criticality}</td>
                </tr>
              )}
              {item.backupSku && (
                <tr>
                  <td className="text-right align-top pr-3">SKU respaldo</td>
                  <td className="align-top text-muted">{item.backupSku}</td>
                </tr>
              )}
              <tr>
                <td className="text-right align-top pr-3">¿Activo?</td>
                <td className="align-top text-muted">
                  <ShowBool value={item.active} />
                </td>
              </tr>

              <tr>
                <td className="text-right align-top pr-3">¿Consumible?</td>
                <td className="align-top text-muted">
                  <ShowBool value={item.consumable} />
                </td>
              </tr>

              <tr>
                <td className="text-right align-top pr-3">¿Restringido?</td>
                <td className="align-top text-muted">
                  <ShowBool value={item.restricted} />
                </td>
              </tr>

              <tr>
                <td className="text-right align-top pr-3">¿Telemetría?</td>
                <td className="align-top text-muted">
                  <ShowBool value={item.telemetric} />
                </td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col sm={12} md={5} className="mb-4">
          <div className="text-muted mb-2">Acciones rápidas</div>

          {!item.consumable && (
            <div className="mb-2">
              <Button
                role="button"
                block
                size="sm"
                tag={NavLink}
                to={`/building/assets/new?for=${item.barcode1}`}
                className="text-left"
              >
                <FontAwesome name="plus" /> Nuevo activo asociado
              </Button>

              {/* TODO: This link must be updated */}
              <Button
                role="button"
                block
                size="sm"
                tag={NavLink}
                to={`/building/assets?q=${simpleFilterQuery({
                  'items[{}]': [{ label: item.name, value: item.barcode1 }],
                })}`}
                className="text-left"
              >
                <FontAwesome name="wrench" /> Ver activos
              </Button>
            </div>
          )}

          {item.consumable && (
            <div className="mb-2">
              <Button
                role="button"
                block
                size="sm"
                tag={NavLink}
                to={`/building/inventory?q=${simpleFilterQuery({
                  'items[{}]': [{ label: item.name, value: item.barcode1 }],
                })}`}
                className="text-left"
              >
                <FontAwesome name="table" /> Consultar inventario de la Instalación
              </Button>
              <Button
                role="button"
                block
                size="sm"
                tag={NavLink}
                to={`/organization/inventory?q=${simpleFilterQuery({
                  'items[{}]': [{ label: item.name, value: item.barcode1 }],
                })}`}
                className="text-left"
              >
                <FontAwesome name="table" /> Consultar inventario de la Organización
              </Button>
              <ShowForRoles excluded={['guest']}>
                <Button
                  role="button"
                  block
                  size="sm"
                  tag={NavLink}
                  to={`/movements/new?of=${item.barcode1}`}
                  className="text-left"
                >
                  <FontAwesome name="plus" /> Ingresar movimiento
                </Button>
              </ShowForRoles>
              <Button
                role="button"
                block
                size="sm"
                tag={NavLink}
                to={`/movements?q=${simpleFilterQuery({
                  consumable: 'true',
                  'items[{}]': [{ label: item.name, value: item.id }],
                })}`}
                className="text-left"
              >
                <FontAwesome name="retweet" /> Ver movimientos
              </Button>
            </div>
          )}

          <Button
            role="button"
            block
            size="sm"
            tag={NavLink}
            to={`/organization/items/${item.barcode1}/attachments`}
            className="text-left"
          >
            <FontAwesome name="file-alt" /> Ver archivos adjuntos
          </Button>
        </Col>
      </Row>
      {item.telemetric && (
        <div>
          <hr />
          <h5 className="mb-4">Configuración de Telemetría</h5>
          <div>
            Advertencia de Fuerza G: <code>{showTelemetryOrDash('gForceEventWarning', 'G')}</code>
          </div>
          <div>
            Daño de Fuerza G: <code>{showTelemetryOrDash('gForceEventHarmful', 'G')}</code>
          </div>
          <div>
            Advertencia de Temperatura: <code>{showTelemetryOrDash('temperatureLevelWarning', '°C')}</code>
          </div>
          <div>
            Daño de Temperatura: <code>{showTelemetryOrDash('temperatureLevelHarmful', '°C')}</code>
          </div>
          <div>
            Advertencia de Horas de Uso: <code>{showTelemetryOrDash('workTripWarning', 'horas')}</code>
          </div>
          <div>
            Daño de Horas de Uso: <code>{showTelemetryOrDash('workTripHarmful', 'horas')}</code>
          </div>
        </div>
      )}
      <hr />
      <h5 className="mb-4">Información adicional</h5>

      <Row>
        <Col xs={12} md={8}>
          <h6 className="mb-2">Descripción</h6>
          <div className="mb-4 small text-muted">{item.description || <em>No especificada.</em>}</div>

          <h6 className="mb-2">Palabras claves</h6>
          <div className="mb-4 small text-muted">
            {item.comments ? <code>{item.comments.join(', ')}</code> : <em>No especificadas.</em>}
          </div>

          <h6 className="mb-2">Precio estimado</h6>
          <div className="mb-4 small text-muted">
            {item.price ? (
              <code>
                {item.price} {item.currency}
              </code>
            ) : (
              <em>No especificado.</em>
            )}
          </div>
        </Col>
        {item.consumable && item.area && (
          <Col xs={12} md={4}>
            <h6 className="mb-2">Ubicación en la instalación</h6>
            <div className="mb-4">
              <AreaDisplay area={item.area} />
            </div>
          </Col>
        )}
      </Row>

      <Row>
        <Col className="mb-12">
          <h6 className="mb-2">Imágenes</h6>
          {item.pictures.length === 0 && <div className="text-center text-muted small">Sin imágenes todavía</div>}

          {item.pictures
            .sort((a, b) => {
              if (a.isDefault < b.isDefault) {
                return 1;
              }
              if (a.isDefault > b.isDefault) {
                return -1;
              }
              return 0;
            })
            .slice(0, imagesToShow)
            .map((picture, index) => (
              <PictureDisplay
                key={index}
                picture={picture}
                pictureSize="icon"
                alt={picture.title}
                width="4.5rem"
                height="3rem"
                inline={true}
              />
            ))}
        </Col>
      </Row>
      <ShowForRoles excluded={['guest']}>
        {item.consumable && (
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <h5 className="mb-2">Últimos movimientos</h5>

              <Button
                role="button"
                size="sm"
                color="success"
                tag={NavLink}
                to={`/movements/new?of=${item.barcode1}`}
                className="mb-3"
              >
                <FontAwesome name="plus" /> Ingresar movimiento
              </Button>
            </div>
            <ItemMovements item={item} />
          </div>
        )}
      </ShowForRoles>
      <hr />
      <div>
        <h5 className="mt-4 mb-4">Últimos eventos</h5>
        <div>{events && <EventsResults events={events} />}</div>
        <div>
          {events && events.length >= 5 && (
            <div className="mt-1 text-center">
              <Button role="button" color="primary" tag={NavLink} to={`/`}>
                <FontAwesome name="search" /> Ver todos
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
