import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Alert, Button } from 'reactstrap';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Field, Form, SubmissionError } from '@core/forms';
import { checkInvitation, confirmInvitation } from '@core/account';
import { connect, sessionGenerators } from '@store';
import Validates, { passwordsMatching, presence } from '@core/forms/validations';
import { Loading, ProcessingButton } from '@shared';
import { SimpleContainerLayout } from '@shared/layouts';

class Invitation extends Component {
  state = {
    name: '',
    loading: true,
    notFound: false,
    success: false,
  };

  async componentDidMount() {
    const { status, body } = await checkInvitation({
      token: this.props.match.params.token,
    });

    switch (status) {
      case 200:
      case 204:
        this.setState({
          loading: false,
          notFound: false,
          name: body.firstName.split(' ')[0],
        });
        break;
      case 404:
      default:
        this.setState({
          loading: false,
          notFound: true,
        });
        break;
    }
  }

  confirm = async (formData) => {
    const { token } = this.props.match.params;

    const { status, body } = await confirmInvitation({ token, ...formData });

    if (status === 200) {
      this.props.dispatchLogin(body);

      this.setState({
        success: true,
      });
    } else if (status === 404) {
      this.setState({
        notFound: true,
      });
    } else if (status === 422) {
      this.setState({
        success: false,
      });

      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { notFound, success, loading, name } = this.state;

    if (notFound) return <Redirect to="/account/login" />;

    if (success) return <InvitationSuccess name={name} />;

    return <InvitationPage loading={loading} onSubmit={this.confirm} name={name} />;
  }
}

const validate = Validates(
  presence({ of: 'currentPassword' }),
  presence({ of: 'passwordConfirmation' }),
  passwordsMatching('currentPassword', 'passwordConfirmation'),
);

const InvitationPagePresentation = ({ handleSubmit, invalid, submitting, pristine, loading, name, error }) => {
  if (loading) return <Loading />;

  return (
    <div className="mt-4 mb-4">
      <SimpleContainerLayout title={`¡Bienvenid@, ${name}!`}>
        <Alert color="info" className="text-center">
          Solo falta que definas tu contraseña.
        </Alert>
        <Form error={error} noValidate onSubmit={handleSubmit}>
          <Field label="Contraseña" name="password" type="password" required />

          <Field label="Confirmar contraseña" name="passwordConfirmation" type="password" required />

          <div className="text-center">
            <ProcessingButton
              type="submit"
              role="button"
              color="primary"
              outline
              disabled={pristine || invalid}
              processing={submitting}
              width="10em"
            >
              Guardar
            </ProcessingButton>
          </div>
        </Form>
      </SimpleContainerLayout>
    </div>
  );
};

const InvitationPage = reduxForm({
  form: 'invitationPage',
  validate,
})(InvitationPagePresentation);

export const InvitationSuccess = ({ name }) => (
  <div className="mt-4 mb-4">
    <SimpleContainerLayout title={`¡Listo, ${name}!`}>
      <div className="text-center mb-4">
        Tu cuenta está lista para ser usada. Desde ahora puedes ingresar con tu dirección de correo y la contraseña que
        acabas de escribir.
      </div>

      <div className="text-center">
        <Button tag={NavLink} to="/" color="primary">
          Comenzar
        </Button>
      </div>
    </SimpleContainerLayout>
  </div>
);

export default connect(null, (dispatch) => ({
  dispatchLogin: (token) => dispatch(sessionGenerators.login(token)),
}))(Invitation);
