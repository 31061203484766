import { Field, Form } from '@core/forms';
import Validates, { numericality, presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { AVAILABLES_CURRENCIES } from '@shared/constants';
import { AreaSelector, ItemSelector, TagsInput } from '@shared/forms';
import { connect } from '@store';
import { get } from 'lodash';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { compose, withState } from 'recompose';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'itemBarcode' }),
  numericality({
    of: 'weight',
    greaterThan: 0,
    if: ({ weight }) => weight && weight !== '',
  }),
  numericality({
    of: 'price',
    greaterThan: 0,
    if: ({ price }) => price && price !== '',
  }),
  presence({
    of: 'currency',
    condition: ({ price }) => !isNaN(parseInt(price, 10)) && parseInt(price, 10) > 0,
  }),
);

const currencyOptions = function () {
  const options = { '': 'Seleccione...' };

  AVAILABLES_CURRENCIES.forEach((cp) => (options[cp] = cp));
  return options;
};
const excludedStates = [
  'repaired',
  'maintenance',
  'requires_repair',
  'repair',
  'requires_maintenance',
  'with_observations',
  'need_maintenance',
  'on_maintenance',
  'need_evaluation',
  'on_evaluation',
  'evaluated',
  'need_preservation',
  'on_preservation',
  'need_certification',
  'on_certification',
  'need_assurance',
  'on_assurance',
  'need_discard',
  'on_discard',
];

const enhance = compose(
  reduxForm({
    form: 'assetForm',
    enableReinitialize: true,
    validate,
    initialValues: {
      area: null,
      item: null,
      itemBarcode: null,
      repairable: false,
    },
  }),
  connect(({ session, form }) => {
    return {
      showCurrency: get(form, 'assetForm.values.price', false),
      showHealthState: get(form, 'assetForm.values.repairable', false),
    };
  }),
  connect(({ appData: { asset_health_states: assetHealthStates } }) => {
    const options = { '': 'Seleccione...' };
    Object.keys(assetHealthStates).forEach((state) => {
      const isExcluded = excludedStates.indexOf(state) > -1;
      if (!isExcluded) {
        const name = assetHealthStates[state].name;
        options[state] = name;
      }
    });

    return { assetHealthStates: options };
  }),
  withState('item', 'setItem', undefined),
);

const AssetFormPresentation = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  showCurrency,
  showHealthState,
  assetHealthStates,
  setItem,
  item,
  itemBarcode,
  assetId,
  telemetryOptions,
  toggleRepairable,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <h4 className="mb-4">Información básica</h4>
    <Row>
      <Col>
        <Field
          name="itemBarcode"
          label="Artículo asociado"
          help={
            <span>
              Este es el artículo no consumible para el cual se está especificando una unidad nueva.{' '}
              <strong>No puede ser cambiado una vez creado el activo.</strong>
            </span>
          }
          component={ItemSelector}
          filters={{
            consumable: false,
            active: true,
          }}
          required
          readonly={!!itemBarcode}
          componentOnChange={(id, item) => {
            setItem(item);
          }}
        />
      </Col>
      <Col>
        <Field
          name="areaId"
          label="Ubicación"
          help="Esta es la área dentro de la cual estará ubicado el activo."
          component={AreaSelector}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name="factorySerialNumber1"
          label="Identificador 1"
          help="Identifica el activo físicamente con un número de serie del fabricante."
        />
      </Col>
      <Col>
        <Field
          name="factorySerialNumber2"
          label="Identificador 2"
          help="Campo disponible para otro identificador físico del activo."
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name="price"
          type="number"
          min={1}
          label="Precio del activo"
          help="Precio estimado del activo"
          parse={(value) => (isNaN(parseInt(value, 10)) ? null : parseInt(value, 10))}
        />
      </Col>
      <Col>
        {showCurrency && (
          <Field name="currency" type="select" label="Indique moneda" options={currencyOptions()} required />
        )}
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field name="weight" type="number" label="Peso" help="Peso en gramos (g)" min={0} />
      </Col>
      <Col md={6}>
        <Field name="owner" label="Dueño" />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field
          label="Criticidad"
          name="criticality"
          type="select"
          options={{ '': 'Ninguna', A: 'A', B: 'B', C: 'C' }}
        />
      </Col>
      <Col md={6}>
        <Field name="serial" label="Serial" help="Identifica el activo con un número serial." />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name="repairable"
          type="checkbox"
          label="¿Reparable?"
          help="Indica que el áctivo podrá ser reparado en procesos MRO."
        />
      </Col>
      {item && showHealthState && !assetId && (
        <Col>
          <Field
            name="healthState"
            type="select"
            label="Estado de salud del activo"
            options={assetHealthStates}
            required
          />
        </Col>
      )}
    </Row>
    <hr />
    <Row>
      <Col>
        <Field
          name="isRental"
          label="¿Activo en renta?"
          type="checkbox"
          help="Esta es una etiqueta que permite especificar que el activo ha sido alquilado y se encontrará disponible por un corto tiempo."
        />
      </Col>
      <Col>
        <Field
          name="watchMode"
          label="¿Hacer Seguimiento?"
          type="checkbox"
          help="Esta opción permite vigilar salidas y entradas del activo entre geocercas"
        />
      </Col>
    </Row>

    <hr />
    {item && item.telemetric && (
      <div>
        <h4 className="mb-4">Configuración de Telemetría</h4>
        <Row>
          <Col>
            <Field
              name="gForceEventWarning"
              label="Peak fuerza G Advertencia"
              help="Especifica el rango al cual se enviará una advertencia."
              type="number"
            />
          </Col>
          <Col>
            <Field
              name="gForceEventHarmful"
              label="Peak fuerza G Dañino"
              help="Especifica el rango el cuál puede ser dañino para el activo."
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="temperatureLevelWarning"
              label="Nivel de Temperatura Advertencia"
              help="Especifica el rango al cual se enviará una advertencia."
              type="number"
            />
          </Col>
          <Col>
            <Field
              name="temperatureLevelHarmful"
              label="Nivel de Temperatura Dañino"
              help="Especifica el rango el cuál puede ser dañino para el activo."
              type="number"
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="workTripWarning"
              label="Horas Uso Advertencia"
              help="Especifica el rango al cual se enviará una advertencia."
              type="number"
            />
          </Col>
          <Col>
            <Field
              name="workTripHarmful"
              label="Horas Uso Dañino"
              help="Especifica el rango el cuál puede ser dañino para el activo."
              type="number"
            />
          </Col>
        </Row>
        <hr />
      </div>
    )}
    <h4 className="mb-4">Información adicional</h4>
    <Row>
      <Col md={6}>
        <Field
          name="comments"
          type="textarea"
          rows="4"
          label="Palabras claves"
          component={TagsInput}
          help="Referencias del activo. Util para efectuar busquedas a traves de estos criterios."
        />
      </Col>
      <Col md={6}>
        <Field
          name="description"
          type="textarea"
          rows="6"
          label="Descripción"
          help="Opcional. Descripción complementaria del activo."
        />
      </Col>
    </Row>
    <hr />
    <div className="text-center">
      <ProcessingButton
        role="button"
        type="submit"
        color="primary"
        outline
        width="10em"
        disabled={pristine || invalid}
        processing={submitting}
      >
        Guardar
      </ProcessingButton>
    </div>
  </Form>
);

export const AssetForm = enhance(AssetFormPresentation);
