import React, { Component } from 'react';
import { massImportCategories } from '../../@core/building/categories';
import Validates, { presence } from '../../@core/forms/validations';
import GenericMassImport from '../../@shared/mass-import';
import { MassImportInstructions } from './components/MassImportInstructions';

import type { ColumnMetadata } from '../../@shared';

const columnMetadata: ColumnMetadata[] = [
  {
    columnName: 'name',
    displayName: 'Nombre',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'name' })),
    style: {
      width: '40%',
    },
  },
  {
    columnName: 'description',
    displayName: 'Descripción',
    editable: { type: 'text' },
    style: {
      width: '60%',
    },
  },
];

const tableConfig = {
  columnMetadata: columnMetadata,
  trackBy: '_id',
};

const headerMap = {
  name: ({ name }) => name,
  description: ({ description }) => description,
};

export class CategoriesMassImport extends Component<*, *> {
  render() {
    return (
      <GenericMassImport
        entity="Categorías"
        instructions={MassImportInstructions}
        valueMapping={headerMap}
        request={massImportCategories}
        tableConfig={tableConfig}
      />
    );
  }
}
