import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import { Field, Form } from '@core/forms';
import Validates, { emailFormat, presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';

const validate = Validates(presence({ of: 'email' }), emailFormat({ of: 'email' }), presence({ of: 'password' }));

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
  }

  componentDidMount() {
    this.emailInput.current.focus();
  }

  render() {
    const { error, handleSubmit, submitting, invalid, pristine } = this.props;

    return (
      <Form compact onSubmit={handleSubmit} error={error}>
        <Field type="email" name="email" label="Email" ref={this.emailInput} />
        <Field type="password" name="password" label="Contraseña" />
        <hr />
        <ProcessingButton
          role="button"
          type="submit"
          color="primary"
          outline
          block
          disabled={pristine || invalid}
          processing={submitting}
        >
          Iniciar Sesión
        </ProcessingButton>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'loginForm',
  validate,
})(LoginForm);
