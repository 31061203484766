import { FontAwesome, ShowForRoles } from '@shared';
import portrait from '@shared/assets/anonymous.png';
import { BuildingNav } from '@shared/layouts/BuildingNav';
import { BuildingToolNav } from '@shared/layouts/BuildingToolNav';
import { connect, sessionGenerators } from '@store';
import { get } from 'lodash';
import React, { Component } from 'react';
import { Img } from 'react-image';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { subscriptionsGenerators } from '../../@store';
import logo from '../assets/logo.svg';
import './Navigation.scss';
import { NotificationsPanel } from './NotificationPanel';
import OrganizationHeader from './OrganizationHeader';

const imgStyle = {
  height: 32,
  width: 32,
  verticalAlign: 'middle',
  borderRadius: '50%',
};

class NavigationPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      coursesMenuOpened: false,
      accountMenuOpened: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  toggleCourses = () => {
    this.setState({
      coursesMenuOpened: !this.state.coursesMenuOpened,
    });
  };

  toggleAccountMenu = () => {
    this.setState({
      accountMenuOpened: !this.state.accountMenuOpened,
    });
  };

  loggedInMenu = () => {
    const { session, forceSetMembership, handleSubscriptions, currentBuilding } = this.props;
    const firstName = session.user?.firstName.split(' ')[0];
    return (
      <Nav className="ml-auto" navbar>
        <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
          <UncontrolledDropdown>
            <DropdownToggle nav caret>
              <FontAwesome name="heartbeat" className="mr-2" /> MRO
            </DropdownToggle>
            <DropdownMenu right>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                <div>
                  <DropdownItem header>Ingresar</DropdownItem>
                  <DropdownItem tag={NavLink} to="/mro/new">
                    <FontAwesome name="plus" className="mr-2" /> Nuevo
                  </DropdownItem>
                  <DropdownItem divider />
                </div>
              </ShowForRoles>
              <div>
                <DropdownItem header>Índice</DropdownItem>
                <DropdownItem tag={NavLink} to="/mro/events">
                  <FontAwesome name="calendar-alt" className="mr-2" /> Eventos
                </DropdownItem>
                <DropdownItem divider />
              </div>
              <DropdownItem header>Explorar</DropdownItem>
              <DropdownItem tag={NavLink} exact to="/mro/evaluations">
                <FontAwesome name="list-ol" className="mr-2" /> Evaluaciones
              </DropdownItem>
              <DropdownItem tag={NavLink} exact to="/mro/repairs">
                <FontAwesome name="cogs" className="mr-2" /> Reparaciones
              </DropdownItem>
              <DropdownItem tag={NavLink} exact to="/mro/maintenances">
                <FontAwesome name="heartbeat" className="mr-2" /> Mantenciones
              </DropdownItem>
              <DropdownItem tag={NavLink} exact to="/mro/preservations">
                <FontAwesome name="hand-holding-medical" className="mr-2" /> Preservaciones
              </DropdownItem>
              <DropdownItem tag={NavLink} exact to="/mro/certifications">
                <FontAwesome name="certificate" className="mr-2" /> Certificaciones
              </DropdownItem>
              <DropdownItem tag={NavLink} exact to="/mro/assurances">
                <FontAwesome name="shield-alt" className="mr-2" /> Garantías
              </DropdownItem>
              <DropdownItem tag={NavLink} exact to="/mro/discards">
                <FontAwesome name="trash-alt" className="mr-2" /> Dar de baja
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ShowForRoles>

        <ShowForRoles
          accepted={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'onboarding', 'guest']}
        >
          <UncontrolledDropdown>
            <DropdownToggle nav caret>
              <FontAwesome name="sync" className="mr-2" /> Movimientos
            </DropdownToggle>
            <DropdownMenu right>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'assistant']}>
                <DropdownItem header>Ingresar</DropdownItem>
                <DropdownItem tag={NavLink} to="/movements/desk">
                  <FontAwesome name="desktop" className="mr-2" /> Escritorio
                </DropdownItem>
              </ShowForRoles>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding']}>
                <DropdownItem tag={NavLink} to="/movements/assembly">
                  <FontAwesome name="barcode" className="mr-2" /> Ensamblado
                </DropdownItem>
              </ShowForRoles>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                <DropdownItem tag={NavLink} to="/movements/new">
                  <FontAwesome name="plus" className="mr-2" /> Ingreso manual
                </DropdownItem>
              </ShowForRoles>
              {currentBuilding?.sapIntegration && (
                <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                  <DropdownItem tag={NavLink} to="/pickings/new">
                    <FontAwesome name="shopping-basket" className="mr-2" /> Ingresar Pedido (Picking)
                  </DropdownItem>
                </ShowForRoles>
              )}
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}>
                <DropdownItem divider />
              </ShowForRoles>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}>
                <DropdownItem header>Explorar</DropdownItem>
                <DropdownItem tag={NavLink} exact to="/movements">
                  <FontAwesome name="search" className="mr-2" /> Buscador
                </DropdownItem>
                {currentBuilding?.sapIntegration && (
                  <DropdownItem tag={NavLink} exact to="/pickings">
                    <FontAwesome name="truck-moving" className="mr-2" /> Pickings
                  </DropdownItem>
                )}
              </ShowForRoles>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ShowForRoles>

        <ShowForRoles
          accepted={[
            'organization_admin',
            'warehouse_admin',
            'assistant',
            'inspector',
            'client',
            'onboarding',
            'guest',
          ]}
        >
          <UncontrolledDropdown>
            <DropdownToggle nav caret>
              <FontAwesome name="building" className="mr-2" /> Organización
            </DropdownToggle>
            <DropdownMenu right>
              <ShowForRoles accepted={['organization_admin']}>
                <DropdownItem tag={NavLink} to="/organization/inventory">
                  <FontAwesome name="th" className="mr-2" /> Inventario
                </DropdownItem>
              </ShowForRoles>
              <DropdownItem tag={NavLink} to="/organization/items">
                <FontAwesome name="th-large" className="mr-2" /> Artículos
              </DropdownItem>
              <ShowForRoles accepted={['organization_admin']}>
                <DropdownItem tag={NavLink} to="/organization/locations">
                  <FontAwesome name="dot-circle" className="mr-2" /> Geocercas
                </DropdownItem>
                <DropdownItem tag={NavLink} to="/organization/categories">
                  <FontAwesome name="sitemap" className="mr-2" /> Categorías
                </DropdownItem>
              </ShowForRoles>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'client']}>
                <DropdownItem tag={NavLink} to="/organization/calendar">
                  <FontAwesome name="calendar-alt" className="mr-2" /> Calendario
                </DropdownItem>
                <DropdownItem tag={NavLink} to="/organization/attachments">
                  <FontAwesome name="file-image" className="mr-2" /> Multimedia
                </DropdownItem>
                <DropdownItem tag={NavLink} to="/organization/tasks">
                  <FontAwesome name="tasks" className="mr-2" /> Tareas
                </DropdownItem>
              </ShowForRoles>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ShowForRoles>

        <ShowForRoles
          accepted={[
            'organization_admin',
            'warehouse_admin',
            'assistant',
            'inspector',
            'client',
            'onboarding',
            'guest',
          ]}
        >
          <UncontrolledDropdown>
            <DropdownToggle nav caret>
              <FontAwesome name="industry" className="mr-2" /> Instalación
            </DropdownToggle>
            <DropdownMenu right>
              <ShowForRoles
                accepted={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'client', 'guest']}
              >
                <DropdownItem tag={NavLink} to="/map">
                  <FontAwesome name="map" className="mr-2" /> Mapa
                </DropdownItem>
              </ShowForRoles>
              <BuildingNav showAdminDivider={true} />
            </DropdownMenu>
          </UncontrolledDropdown>
        </ShowForRoles>

        <ShowForRoles
          accepted={[
            'organization_admin',
            'warehouse_admin',
            'assistant',
            'inspector',
            'client',
            'onboarding',
            'guest',
          ]}
        >
          <UncontrolledDropdown>
            <DropdownToggle nav caret>
              <FontAwesome name="cogs" className="mr-2" /> Herramientas
            </DropdownToggle>
            <DropdownMenu right>
              <BuildingToolNav showAdminDivider={true} currentBuilding={currentBuilding} />
            </DropdownMenu>
          </UncontrolledDropdown>
        </ShowForRoles>

        <ShowForRoles excluded={['guest', 'pod_driver', 'pod_planner', 'pod_admin', 'onboarding']}>
          <UncontrolledDropdown>
            <DropdownToggle nav caret>
              <FontAwesome name="chart-pie" className="mr-2" /> Reportería
            </DropdownToggle>
            <DropdownMenu right>
              <ShowForRoles excluded={['client', 'inspector', 'assistant']}>
                <DropdownItem tag={NavLink} to="/building/dashboard">
                  <FontAwesome name="chart-line" className="mr-2" />
                  Dashboard
                </DropdownItem>
                <DropdownItem tag={NavLink} to="/building/inventory-checker-sets">
                  <FontAwesome name="th" className="mr-2" />
                  Programación de Auditorías
                </DropdownItem>
              </ShowForRoles>
              <DropdownItem tag={NavLink} to="/building/inventory-checkers">
                <FontAwesome name="th" className="mr-2" />
                Auditoría
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ShowForRoles>

        <ShowForRoles rootAccepted={['root']}>
          <UncontrolledDropdown>
            <DropdownToggle nav caret>
              <FontAwesome name="user-cog" className="mr-2" /> Administración
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem tag={NavLink} to="/admin/organizations">
                <FontAwesome name="university" className="mr-2" />
                Organizaciones
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/admin/buildings">
                <FontAwesome name="industry" className="mr-2" /> Instalaciones
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/admin/devices">
                <FontAwesome name="mobile-alt" className="mr-2" /> Dispositivos
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/admin/simcards">
                <FontAwesome name="phone" className="mr-2" /> SIM Cards
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/admin/users">
                <FontAwesome name="user" className="mr-2" /> Usuarios
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/admin/posts">
                <FontAwesome name="newspaper" className="mr-2" /> Noticias
              </DropdownItem>
              <DropdownItem tag={NavLink} to="/admin/batches">
                <FontAwesome name="archive" className="mr-2" /> Lotes
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </ShowForRoles>
        <NotificationsPanel />

        <UncontrolledDropdown>
          <DropdownToggle nav caret>
            <FontAwesome name="headset" className="mr-2" /> Soporte
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem
              id={`ticket-support`}
              nav
              onClick={() => window.open('https://soporte.alliot.cloud/', '_blank')}
            >
              <FontAwesome name="ticket-alt" className="mr-2" /> Ticket
              <UncontrolledTooltip placement="bottom" target={`ticket-support`}>
                <span className="small">Crear ticket de soporte técnico</span>
              </UncontrolledTooltip>
            </DropdownItem>
            <DropdownItem id={`ws-support`} nav onClick={() => window.open('https://wa.me/56926195140', '_blank')}>
              <FontAwesome name={['fab', 'whatsapp']} className="mr-2" /> Whatsapp
            </DropdownItem>
            <DropdownItem id={`tv`} nav onClick={() => window.open('https://tv.alliot.cloud/', '_blank')}>
              <FontAwesome name="tv" className="mr-2" /> TV Alliot
              <UncontrolledTooltip placement="bottom" target={`tv`}>
                <span className="small">TV alliot - Visor de estado de situación para instalaciones</span>
              </UncontrolledTooltip>
            </DropdownItem>
            <DropdownItem id={`tower`} nav onClick={() => window.open('https://tower.alliot.cloud/', '_blank')}>
              <FontAwesome name="broadcast-tower" className="mr-2" /> Tower
              <UncontrolledTooltip placement="bottom" target={`tower`}>
                <span className="small">Herramienta de BI para manejar datos de Alliot</span>
              </UncontrolledTooltip>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>

        <UncontrolledDropdown>
          <DropdownToggle nav>
            <span className="mr-2">{firstName}</span>
            <Img
              className="asset-cloud-image"
              src={get(session, 'user.avatar.icon.url') || portrait}
              alt={firstName}
              loader={<Spinner />}
              unloader={<img className="asset-cloud-image" style={imgStyle} alt={firstName} src={portrait} />}
              style={imgStyle}
            />
          </DropdownToggle>
          <DropdownMenu right>
            <OrganizationHeader
              session={session}
              forceSetMembership={forceSetMembership}
              handleSubscriptions={handleSubscriptions}
            />
            <DropdownItem divider />
            <DropdownItem tag={NavLink} to="/account/profile">
              Perfil
            </DropdownItem>
            <ShowForRoles excluded={['guest']}>
              {/* {session.currentMembership && (
                <DropdownItem tag={NavLink} to="/account/reservations">
                  Mis Reservas
                </DropdownItem>
              )} */}
              {session.currentMembership && (
                <DropdownItem tag={NavLink} to="/account/tasks">
                  Mis Tareas
                </DropdownItem>
              )}
            </ShowForRoles>
            <DropdownItem divider />
            <DropdownItem tag={NavLink} to="/account/logout">
              Desconectar
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    );
  };

  loggedOutMenu = () => (
    <Nav className="ml-auto" navbar>
      <NavItem>
        <NavLink to="/account/login" className="nav-link">
          Iniciar Sesión
        </NavLink>
      </NavItem>
    </Nav>
  );

  render() {
    const { session } = this.props;

    return (
      <Navbar color="primary" className="cloud-navbar" dark={true} expand={true}>
        <NavbarBrand href="/">
          <img src={logo} alt="Alliot" style={{ height: 30 }} />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} className="mr-2" />
        <Collapse isOpen={this.state.isOpen} navbar>
          {session.loggedIn ? this.loggedInMenu() : this.loggedOutMenu()}
        </Collapse>
      </Navbar>
    );
  }
}

export default withRouter(
  connect(
    ({ session }) => ({
      session,
      currentBuilding:
        session.user?.memberships &&
        session.user?.memberships.find((m) => m.id === session.currentMembership)?.building,
    }),
    (dispatch) => ({
      forceSetMembership: () => dispatch(sessionGenerators.forceSetMembership()),
      handleSubscriptions: () => dispatch(subscriptionsGenerators.openBuildingModal()),
    }),
  )(NavigationPresentation),
);
