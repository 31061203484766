import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getPickUps = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/pick_ups${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getPickUp = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/pick_ups/${id}`,
      method: 'GET',
    }),
  );

export const createPickUp = (pickUp) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/pick_ups',
      method: 'POST',
      body: pickUp,
    }),
  );

export const createPickUps = (pickUps) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/pick_ups/mass',
      method: 'POST',
      body: { pickUps },
    }),
  );

export const updatePickUp = (id, pickUp) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/pick_ups/${id}`,
      method: 'PUT',
      body: {
        pickUp,
      },
    }),
  );
