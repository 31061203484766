import { getMovementSets } from '@core/building/movement-sets';
import { buildingDownload } from '@core/http.js';
import { ExcelDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { PickingTable } from '@shared/movement-sets';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { get } from 'lodash';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { PickingsFilterModal } from './PickingsFilterModal';

export class PickingsIndex extends SimpleFilter {
  searchFunction = getMovementSets;

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    const hasPeriod = get(filters, 'movement_period{}.started_at', false) && get(filters, 'movement_period{}.ended_at');

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                <ExcelDownload
                  file="movements/sets"
                  filters={filters}
                  downloadFile={buildingDownload}
                  disabled={(items || []).length === 0 || !hasPeriod}
                />
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="search" className="text-primary mr-2" />
              Movimientos de Pickings
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <PickingTable pickings={items} pagination={pagination} showActions={true} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <PickingsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
