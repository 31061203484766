/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Input } from 'reactstrap';
import FontAwesome from '../assets/FontAwesome';
import Editable from './Editable';

type OptionObject = {
  text: string,
  value: string | number | boolean,
};

type Option = OptionObject | string | number | boolean;

type EditableSelectFieldProps = {
  id: string,
  name: string,
  data: Object,
  onUpdate: Function,
  options: Option[],
  toggle?: boolean,
  value?: string | number | boolean,
  validate?: Function,
  placeholder?: string,
  onToggle?: (startEdit: boolean) => void,
};

type State = {
  isEditing: boolean,
  value?: string | number | boolean,
  placeholder: string,
  options: OptionObject[],
  text?: string,
};

export default class EditableSelectField extends React.Component<EditableSelectFieldProps, State> {
  input: HTMLSelectElement;

  constructor(props: EditableSelectFieldProps) {
    super(props);

    const options: OptionObject[] = this.convertOptions(props.options);

    let selected = undefined;

    if (options) {
      selected = options.find((opt) => {
        return opt.value === props.value;
      });
    }

    this.state = {
      isEditing: false,
      value: props.value,
      placeholder: props.placeholder || 'Seleccione...',
      options,
      text: selected ? selected.text : undefined,
    };

    this.input = React.createRef();
  }

  save = (event: Event) => {
    event.preventDefault();

    const { validate, name, data } = this.props;

    if (validate) {
      const errors = validate({
        ...data,
        [name]: this.input.current.value,
      });
      if (Object.keys(errors).length > 0) {
        alert(`${errors[name]}`);
        return;
      }
    }

    this.props.onUpdate(this.props.name, this.input.current.value);

    const text =
      this.input.current.options &&
      this.input.current.options[this.input.current.selectedIndex] &&
      this.input.current.options[this.input.current.selectedIndex].text;

    this.setState({
      isEditing: false,
      text,
      value: this.input.current.value,
    });
    this.props.onToggle && this.props.onToggle(false);
  };
  cancel = () => {
    this.props.onToggle && this.props.onToggle(false);
    this.setState({ isEditing: false });
    this.setState({ value: this.props.value });
  };

  handleLinkClick = () => {
    this.props.onToggle && this.props.onToggle(true);
    this.setState({ isEditing: true });
  };

  convertOptions = (options: Option[]): OptionObject[] => {
    return options.map((opt) => {
      switch (typeof opt) {
        case 'string':
        case 'number':
          return { text: `${opt}`, value: opt };
        case 'boolean':
          return { text: opt.toString(), value: opt };
        default:
          return { text: opt.text, value: opt.value };
      }
    });
  };

  render() {
    const { name, id, toggle } = this.props;
    const { isEditing, options, value, placeholder, text } = this.state;

    if (isEditing) {
      const opts =
        options &&
        options.map((opt, i) => {
          return (
            <option key={i} value={opt.value}>
              {opt.text}
            </option>
          );
        });
      return (
        <Editable isLoading={false} save={this.save} cancel={this.cancel}>
          <Input
            innerRef={this.input}
            type="select"
            name={name}
            id={id}
            placeholder={placeholder}
            value={value}
            onChange={(event) => this.setState({ value: event.target.value })}
          >
            {opts}
          </Input>
        </Editable>
      );
    } else {
      return (
        <div className="editable">
          <a role="button" className={`editable-link ${value ? '' : 'editable-empty'}`} onClick={this.handleLinkClick}>
            {text || placeholder || (toggle ? '' : '-')}
          </a>
          {toggle && (
            <Button role="button" className="editable-toggle" color="primary" size="sm" onClick={this.handleLinkClick}>
              <FontAwesome name="pencil-alt" />
            </Button>
          )}
        </div>
      );
    }
  }
}
