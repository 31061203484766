import { FontAwesome, Spinner, UserPortrait } from '@shared';
import { ConfirmBox } from '@shared';
import { connect } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';

const enhance = compose(
  connect(({ session }) => ({
    currentUserId: session.user.id,
  })),
  withState('loading', 'setLoading', false),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    remove: ({ setLoading, softDelete, setIsOpen }) => async () => {
      setLoading('remove');

      await softDelete();

      setLoading(false);
      setIsOpen(false);
    },
  }),
  withHandlers({
    openConfirmBox: ({ setIsOpen, displayConfirmBox, remove }) => () => {
      if (displayConfirmBox) {
        setIsOpen(true);
      } else {
        remove();
      }
    },
    closeConfirmBox: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
  }),
);

const StatusNotActive = () => (
  <Badge color="danger" pill>
    Inactivo
  </Badge>
);

const StatusLocked = () => (
  <Badge color="default" pill>
    Desabilitado
  </Badge>
);

const StatusInvitationPending = () => (
  <Badge color="warning" pill>
    Invitación Pendiente
  </Badge>
);

const StatusPasswordResetPending = () => (
  <Badge color="warning" pill>
    Restablecimiento de contraseña pendiente
  </Badge>
);

const StatusConfirmationPending = () => (
  <Badge color="warning" pill>
    Confirmación Pendiente
  </Badge>
);

const StatusOk = () => (
  <Badge color="success" pill>
    Ok
  </Badge>
);

const UserRowPresentation = ({
  user,
  isOpen,
  remove,
  openConfirmBox,
  closeConfirmBox,
  toogleConfirmBox,
  loading,
  currentUserId,
}) => (
  <tr key={user.id}>
    <td className="align-middle">
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <UserPortrait user={user} />
        <NavLink className="ml-2" to={`/admin/users/${user.id}`}>
          {user.firstName} {user.lastName}
        </NavLink>
      </span>
    </td>
    <td className="align-middle">{user.email}</td>
    <td className="align-middle text-center">{user.phone}</td>
    <td className="align-middle text-center">
      {user.status === 'not_active' ? (
        <StatusNotActive />
      ) : user.status === 'locked' ? (
        <StatusLocked />
      ) : user.status === 'invitation_pending' ? (
        <StatusInvitationPending />
      ) : user.status === 'password_reset_pending' ? (
        <StatusPasswordResetPending />
      ) : user.status === 'confirmation_pending' ? (
        <StatusConfirmationPending />
      ) : (
        <StatusOk />
      )}
    </td>
    <td className="align-middle text-right">
      {user.id !== currentUserId && (
        <div>
          <Button role="button" size="sm" color="danger" onClick={openConfirmBox}>
            {' '}
            {loading === 'remove' ? <Spinner /> : <FontAwesome name="times" />}
          </Button>
          <ConfirmBox
            isOpen={isOpen}
            avoidConfirmBox={toogleConfirmBox}
            closeConfirmBox={closeConfirmBox}
            removeTag={remove}
            type="usuario"
            resource={`${user.firstName} ${user.lastName}`}
          />
        </div>
      )}
    </td>
  </tr>
);

export const UserRow = enhance(UserRowPresentation);
