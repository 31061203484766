import React, { Component } from 'react';
import { Alert, Button } from 'reactstrap';

import { updatePicture } from '@core/pictures';
import { FontAwesome, ShowForRoles } from '@shared';
import { addPictureManagerNotifications, PictureCard, PictureModal } from '@shared/picture-manager';

class PictureCrud extends Component {
  state = {
    pictures: [],
    savingTitle: undefined,
    settingTarget: undefined,
    showUploader: false,
    deletePicture: undefined,
    insertPicture: undefined,
    loadingPicture: false,
  };

  componentDidMount() {
    const { pictures, deletePicture, insertPicture } = this.props;
    this.setState({ pictures, deletePicture, insertPicture });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { pictures } = nextProps;
    this.setState({ pictures });
  }

  toggleModal = () => {
    this.setState({
      showUploader: !this.state.showUploader,
    });
  };

  insertPicture = async (token) => {
    const { parentElement } = this.props;
    const { pictures } = this.state;

    this.setState({ showUploader: false });
    const { body, status } = await this.state.insertPicture(parentElement.barcode1, token);

    if (status === 201) {
      this.setState({
        pictures: [...pictures, body],
      });

      this.props.creationSuccess();
    } else {
      this.props.creationFailed();
    }
  };

  toggleEditing = (id) => {
    const { pictures } = this.state;
    let index = pictures.findIndex((p) => p.id === id);

    if (index !== -1)
      this.setState({
        pictures: [
          ...pictures.slice(0, index),
          { ...pictures[index], $editing: !pictures[index].editing },
          ...pictures.slice(index + 1),
        ],
      });
  };

  setDefault = async (id) => {
    this.setState({
      loadingPicture: true,
    });
    let { pictures } = this.state;
    const defaultPictures = pictures.filter((p) => p.isDefault);
    // const currentIndex = pictures.findIndex((p) => p.isDefault);
    const newIndex = pictures.findIndex((p) => p.id === id);

    if (defaultPictures === []) return;
    // if (currentIndex === -1 || newIndex === -1 || currentIndex === newIndex) return;

    this.setState({ settingTarget: id });

    // Persist information to server
    // POTENTIAL BUG: PERMISSIONS ON SERVER TO UPDATE PICTURE
    await Promise.all([
      ...defaultPictures.map((p) => updatePicture({ id: p.token, isDefault: false })),
      updatePicture({ id: pictures[newIndex].token, isDefault: true }),
    ]);

    const newPictures = pictures.map((p, index) => {
      if (index === newIndex) {
        return { ...p, isDefault: true };
      } else {
        return { ...p, isDefault: false };
      }
    });

    this.setState({
      pictures: newPictures,
      settingTarget: undefined,
      loadingPicture: false,
    });
  };

  changeTitle = (evt) => {
    const { pictures } = this.state;
    const index = pictures.findIndex((e) => e.$editing);

    if (index !== -1) {
      pictures[index].title = evt.target.value;

      this.setState({
        pictures,
      });
    }
  };

  setTitle = async (evt) => {
    evt.preventDefault();

    const { pictures } = this.state;
    const index = pictures.findIndex((e) => e.$editing);

    if (index !== -1) {
      pictures[index].$editing = false;

      this.setState({ pictures, savingTitle: pictures[index].id });

      await updatePicture({
        id: pictures[index].token,
        title: pictures[index].title,
      });

      this.setState({ savingTitle: undefined });
    }
  };

  onDelete = async (id) => {
    const { pictures } = this.state;
    const { parentElement } = this.props;
    const { status } = await this.state.deletePicture(parentElement.barcode1, id);

    let index = pictures.findIndex((p) => p.id === id);

    this.setState({
      pictures: [...pictures.slice(0, index), { ...pictures[index], $deleting: true }, ...pictures.slice(index + 1)],
    });

    if (status === 204) {
      if (index !== -1)
        this.setState({
          pictures: [...pictures.slice(0, index), ...pictures.slice(index + 1)],
        });

      this.props.deletionSuccess();
    } else {
      this.props.deletionFailed();
    }
  };

  render() {
    const { pictures, savingTitle, settingTarget, showUploader, loadingPicture } = this.state;

    return (
      <div>
        <Alert color="info" className="text-center">
          La imagen marcada (<FontAwesome name="check-circle" />) será la imagen mostrada por defecto.
        </Alert>
        {pictures.map((picture, index) => (
          <PictureCard
            changeTitle={this.changeTitle}
            key={index}
            onDelete={this.onDelete}
            picture={picture}
            toggleEditing={this.toggleEditing}
            savingTitle={savingTitle}
            setDefault={this.setDefault}
            settingTarget={settingTarget}
            setTitle={this.setTitle}
            loadingPicture={loadingPicture}
          />
        ))}
        <ShowForRoles excluded={['guest']}>
          <Button role="button" block outline color="primary" onClick={() => this.setState({ showUploader: true })}>
            <FontAwesome name="plus" /> Agregar imagen
          </Button>
        </ShowForRoles>

        <PictureModal showModal={showUploader} toggle={this.toggleModal} onChange={this.insertPicture} />
      </div>
    );
  }
}

export default addPictureManagerNotifications(PictureCrud);
