import { getCostAccounts } from '@core/building/cost-accounts';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

export class CostAccountsFilterModal extends SimpleFilterModal {
  findCostAccounts = async (value) => {
    const { body, status } = await getCostAccounts({ search: value });
    if (status === 200) {
      return body.map(({ name: label, name: value }) => ({ label, value }));
    }
    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="codes[{}]">Código</Label>
              <AsyncSelect
                name="codes[{}]"
                value={this.state['codes[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findCostAccounts}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="names[{}]">Nombre</Label>
              <AsyncSelect
                name="names[{}]"
                value={this.state['names[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findCostAccounts}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
