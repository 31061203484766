import React from 'react';
import { Table } from 'reactstrap';
import { Pagination } from '@shared';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';
import { fromIso8601 } from '@core/utils/dates';
import { simpleFilterQuery } from '@shared';

export default function TelemetriesTable({ telemetries, pagination, setPage }) {
  console.log(telemetries);
  const metrics = {
    elapsed: 'Tiempo',
    peak: 'Peak',
    duration: 'Duración',
    average: 'Promedio',
    level: 'Nivel',
    temperature: 'Temperatura',
  };
  const getMetric = (metric) => metrics[metric];

  const types = {
    g_force_event: 'Evento de Fuerza G',
    temperature_level: 'Nivel de Temperatura',
    battery_level: 'Nivel de Bateria',
    work_trip: 'Horas de Uso',
  };
  const readType = (telemetry) => {
    return types[telemetry.type];
  };
  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Origen</th>
            <th>Tipo de Lectura</th>
            <th>Datos</th>
          </tr>
        </thead>
        <tbody>
          {telemetries.length === 0 && (
            <tr>
              <td colSpan="5" className="text-center">
                <em>No hay datos disponibles de momento.</em>
              </td>
            </tr>
          )}

          {telemetries.map((telemetry) => (
            <tr>
              <td>
                <Media body tag="div">
                  <code>
                    {telemetry.tag.identifier ? (
                      <NavLink
                        to={`/building/tags?q=${simpleFilterQuery({
                          identifier: telemetry.tag.identifier,
                        })}`}
                      >
                        {telemetry.tag.identifier}
                      </NavLink>
                    ) : (
                      <span className="text-warning">
                        <strong>Desconocido</strong>
                      </span>
                    )}
                  </code>
                  <div>{fromIso8601(telemetry.readAt)}</div>
                </Media>
              </td>
              <td>{readType(telemetry)}</td>
              <td>
                {Object.entries(telemetry.context).map((entry) => {
                  const [metric, value] = entry;
                  return (
                    <div>
                      <code>
                        {getMetric(metric)}: {value}
                      </code>
                    </div>
                  );
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Pagination {...pagination} setPage={setPage} />
    </div>
  );
}
