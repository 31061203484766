import { FontAwesome, ProcessingButton } from '@shared/index';
import { ShowForRoles } from '@shared';
import React, { useState } from 'react';
import { Button, Input } from 'reactstrap';
import { ItemDisplay } from '@shared/displays';
import Select from 'react-select';

export const RestrictedItemRow = ({
  userId,
  restrictedItem,
  index,
  getRestrictions,
  editRestriction,
  deleteRestriction,
  ...props
}) => {
  const FREQUENCY = {
    daily: 'Diaria',
    weekly: 'Semanal',
    monthly: 'Mensual',
  };

  const [quantity, setQuantity] = useState(restrictedItem.quantity);
  const [period, setPeriod] = useState({ label: FREQUENCY[restrictedItem.period], value: restrictedItem.period });
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const editRestrictionRow = async () => {
    setLoading(true);
    await editRestriction({ id: restrictedItem.id, quantity, period: period.value });
    setEdit(false);
    setLoading(false);
  };

  return (
    <tr>
      <td className="small">
        {restrictedItem && restrictedItem.item && <ItemDisplay item={restrictedItem.item} sku={true} />}
      </td>
      {edit ? (
        <td className="align-middle text-center">
          <ShowForRoles accepted={['organization_admin']}>
            <Input
              name="quantity"
              placeholder="Cantidad"
              type="number"
              step="1"
              className="align-middle text-right"
              value={quantity}
              onKeyUp={(e) => setQuantity(e.target.value)}
              onChange={(e) => setQuantity(e.target.value)}
            />
          </ShowForRoles>
        </td>
      ) : (
        <td className="align-middle small text-right">{restrictedItem && restrictedItem.quantity}</td>
      )}
      {edit ? (
        <td className="align-middle ">
          <ShowForRoles accepted={['organization_admin']}>
            <Select
              name="period"
              value={period}
              isSearchable
              closeMenuOnSelect={true}
              placeholder="Frecuencia"
              options={[
                { label: 'Seleccione...', value: undefined },
                { label: 'Diaria', value: 'daily' },
                { label: 'Semanal', value: 'weekly' },
                { label: 'Mensual', value: 'monthly' },
              ]}
              onChange={(e) => {
                console.log(e);
                setPeriod(e);
              }}
            />
          </ShowForRoles>
        </td>
      ) : (
        <td className="align-middle small text-center">{restrictedItem && FREQUENCY[restrictedItem.period]}</td>
      )}
      <td className="align-middle small text-right">{restrictedItem && restrictedItem.delivered}</td>
      <ShowForRoles accepted={['organization_admin']}>
        <td className="align-middle text-center">
          {!edit ? (
            <div>
              <Button role="button" color="info" size="sm" id={`edit`} onClick={() => setEdit(true)} className="mr-1">
                <FontAwesome name="pencil-alt" />
                {/* <UncontrolledTooltip target={`edit`} placement="right">
                  Editar restricción
                </UncontrolledTooltip> */}
              </Button>
              <Button
                role="button"
                color="danger"
                size="sm"
                id={`delete`}
                onClick={async () => {
                  setLoadingDelete(true);
                  await deleteRestriction(restrictedItem.id);
                  setLoadingDelete(false);
                }}
              >
                <FontAwesome name="trash" />
                {/* <UncontrolledTooltip target={`delete`} placement="right">
                  Eliminar restricción
                </UncontrolledTooltip> */}
              </Button>
            </div>
          ) : (
            <div>
              <ProcessingButton
                role="button"
                color="success"
                size="sm"
                id={`check`}
                onClick={editRestrictionRow}
                className="mr-1"
                processing={loading}
              >
                <FontAwesome name="check" />
                {/* <UncontrolledTooltip target={`check`} placement="right">
                  Guardar Editor
                </UncontrolledTooltip> */}
              </ProcessingButton>
              <ProcessingButton
                role="button"
                color="danger"
                size="sm"
                id={`close`}
                onClick={() => {
                  setQuantity(restrictedItem.quantity);
                  setPeriod({ label: FREQUENCY[restrictedItem.period], value: restrictedItem.period });
                  setEdit(false);
                  setLoading(false);
                  setLoadingDelete(false);
                }}
                processing={loadingDelete}
              >
                <FontAwesome name="times" />
                {/* <UncontrolledTooltip target={`close`} placement="right">
                  Cerrar Editor
                </UncontrolledTooltip> */}
              </ProcessingButton>
            </div>
          )}
        </td>
      </ShowForRoles>
    </tr>
  );
};
