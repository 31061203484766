import { getPosts } from '@core/admin/posts';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

export class PostsFilterModal extends SimpleFilterModal {
  size = 'md';
  findPosts = async (inputValue) => {
    const { body, status } = await getPosts({ title: inputValue });

    if (status === 200) {
      return body.map(({ title: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="posts[{}]">Título</Label>
              <AsyncSelect
                name="posts[{}]"
                value={this.state['posts[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findPosts}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
