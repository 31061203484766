import React from 'react';
// import { getMovementSets } from '@core/building/movement-sets';
import { connect } from '@store';
import { SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import { Col, Form, FormGroup, Label, Row, Input } from 'reactstrap';
import { DateSelector } from '@shared/forms';
import { get } from 'lodash';

export class PickingsFilter extends SimpleFilterModal {
  renderForm = () => {
    return (
      <Form>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for="folios">Folio SAP (*)</Label>
              <Input
                type="text"
                size="md"
                name="folios"
                onChange={(e) => this.onChange(e.target.value.replace(/\s/g, ''), { name: 'folios' })}
                value={this.state['folios']}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for="pickings">Picking ID (*)</Label>
              <Input
                type="text"
                size="md"
                name="pickings"
                onChange={(e) => this.onChange(e.target.value.replace(/\s/g, ''), { name: 'pickings' })}
                value={this.state['pickings']}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for="request_numbers">Número de solicitud(*)</Label>
              <Input
                type="text"
                size="md"
                name="request_numbers"
                onChange={(e) => this.onChange(e.target.value.replace(/\s/g, ''), { name: 'request_numbers' })}
                value={this.state['request_numbers']}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="movement_period{}">Fecha Inicial</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'movement_period{}.started_at')}
                onChange={(date) =>
                  this.setState({
                    'movement_period{}': {
                      ...get(this.state, 'movement_period{}', {}),
                      started_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="movement_period{}">Fecha Final</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'movement_period{}.ended_at')}
                onChange={(date) =>
                  this.setState({
                    'movement_period{}': {
                      ...get(this.state, 'movement_period{}', {}),
                      ended_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="text-muted">
              (*) separar con "," para buscar multiples elementos (xyz..., abc..., ... )
            </span>
          </Col>
        </Row>
      </Form>
    );
  };
}

export const PickingsFilterModal = connect(({ appData }) => {})(PickingsFilter);
