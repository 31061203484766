export const SubscriptionActions = {
  OpenBuildingModal: 'Subscription.OpenBuildingModal',
  CloseBuildingModal: 'Subscription.CloseBuildingModal',
};

export const subscriptionsGenerators = {
  openBuildingModal: () => {
    return {
      type: SubscriptionActions.OpenBuildingModal,
    };
  },
  closeBuildingModal: () => {
    return {
      type: SubscriptionActions.CloseBuildingModal,
    };
  },
};

export const subscriptionsInitialState = {
  open: false,
};

export const subscriptions = (state = subscriptionsInitialState, action) => {
  switch (action.type) {
    case SubscriptionActions.OpenBuildingModal:
      return {
        ...state,
        open: true,
      };
    case SubscriptionActions.CloseBuildingModal:
      return {
        ...state,
        open: false,
      };
    default:
      return { ...state };
  }
};
