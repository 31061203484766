import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const searchOrganizationInventory = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory/organization${mountQuery(query)}`,
      method: 'GET',
      parseResponse: false,
    }),
  );
