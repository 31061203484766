import React, { useState } from 'react';
import { FontAwesome } from '@shared';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { shortName } from '@core/utils/names';
import { EventAssetModal } from './EventAsset';
import { EventItemModal } from './EventItem';
import { EventKitModal } from './EventKit';
import { EventBuildingModal } from './EventBuilding';
import { EventMovementModal } from './EventMovement';
import { EventMROModal } from './EventMRO';
import { EventTagModal } from './EventTag';
import { EventAreaModal } from './EventArea';
import { EventTaskModal } from './EventTask';
import { EventSubtaskModal } from './EventSubtask';
import { EventDeviceModal, EventDeviceKeyModal } from './EventDevice';
import { EventMembershipModal } from './EventMembership';
import { EventReadoutModal } from './EventReadout';
import { EventGeofenceModal } from './EventGeofence';
import { EventAttachmentModal } from './EventAttachment';

export const EventInfoModal = ({ pattern, description, user, eventInfo }) => {
  const [modalInfoOpen, setModalInfoOpen] = useState(false);

  const toggleInfo = () => setModalInfoOpen(!modalInfoOpen);

  return (
    <div>
      <Button color="info" size="sm" onClick={toggleInfo}>
        <FontAwesome name="search" />
      </Button>
      <Modal isOpen={modalInfoOpen} toggle={toggleInfo} centered>
        <ModalHeader toggle={toggleInfo}>Detalle del Evento</ModalHeader>
        <ModalBody style={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {description && <h5>{description}</h5>}
          {user && (
            <div className="d-block small">
              <FontAwesome name="user" /> {shortName(user)}
            </div>
          )}
          {pattern && eventInfo && (
            <div className="mt-3">
              {(pattern.includes('asset:') ||
                pattern.includes('assembly_normal_tag:') ||
                pattern.includes('reassembly_normal_tag:')) && <EventAssetModal asset={eventInfo} />}
              {pattern.includes('item:') && <EventItemModal item={eventInfo} />}
              {pattern.includes('building:') && <EventBuildingModal building={eventInfo} />}
              {pattern.includes('kit:') && <EventKitModal kit={eventInfo} />}
              {(pattern.includes('movement:') ||
                pattern.includes('repair_provider:') ||
                pattern.includes('movement_mro:') ||
                pattern.includes('repair_provider_mro:') ||
                pattern.includes('asset_repair:') ||
                pattern.includes('asset_evaluation:') ||
                pattern.includes('asset_maintenance:') ||
                pattern.includes('asset_preservation:') ||
                pattern.includes('asset_certification:') ||
                pattern.includes('asset_assurance:') ||
                pattern.includes('asset_discard:')) && <EventMovementModal movement={eventInfo} />}
              {(pattern.includes('repair_mro:') ||
                pattern.includes('evaluation_mro:') ||
                pattern.includes('maintenance_mro') ||
                pattern.includes('preservation_mro:') ||
                pattern.includes('certification_mro:') ||
                pattern.includes('assurance_mro:') ||
                pattern.includes('discard_mro:')) && <EventMROModal mro={eventInfo} />}
              {(pattern.includes('tag:') ||
                pattern.includes('assembly_batch:') ||
                pattern.includes('assembly_hard_tag:') ||
                pattern.includes('reassembly_hard_tag:')) && <EventTagModal tag={eventInfo} />}
              {pattern.includes('area:') && <EventAreaModal area={eventInfo} />}
              {pattern.match(/^task:/) && <EventTaskModal task={eventInfo} />}
              {pattern.includes('sub_task:') && <EventSubtaskModal subtask={eventInfo} />}
              {pattern.includes('device:') && <EventDeviceModal device={eventInfo} />}
              {pattern.includes('device_key:') && <EventDeviceKeyModal deviceKey={eventInfo} />}
              {pattern.includes('membership:') && <EventMembershipModal membership={eventInfo} />}
              {pattern.includes('readout:') && <EventReadoutModal readout={eventInfo} />}
              {(pattern.includes('geo_in:') || pattern.includes('geo_out:') || pattern.includes('lowbattery:')) && (
                <EventGeofenceModal geofence={eventInfo} />
              )}
              {pattern.includes('attachment:') && <EventAttachmentModal attachment={eventInfo} />}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleInfo}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
