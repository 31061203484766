import { bufferTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

const defaultTypeDelay = 500;
const defaultMinLength = 3;

const debounce = function (inputStream$, { typeDelay = defaultTypeDelay, minLength = defaultMinLength } = {}) {
  // Group all changes in chunks of InputDelay ms
  // Take the last significant change from the array of changes
  // Ensure there's actually a change
  // Then query the server.
  return inputStream$.pipe(
    bufferTime(typeDelay),
    filter((arr) => arr.length > 0),
    map((arr) => arr[arr.length - 1]),
    filter((query) => query.length >= minLength),
    startWith(''),
    distinctUntilChanged(),
  );
};

export default debounce;
