import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const createMaintenance = (repair) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/repair',
      method: 'POST',
      body: {
        repair,
      },
    }),
  );

export const getMaintenanceStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/repair/stats`,
      method: 'GET',
    }),
  );

export const getAssetRepairs = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_repairs${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAssetRepair = (assetRepairId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_repairs/${assetRepairId}`,
      method: 'GET',
    }),
  );

export const getAssetRepairStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_repairs/stats`,
      method: 'GET',
    }),
  );

export const updateMaintenance = (repair, bpmType) => {
  const data = {};
  data[`asset_${bpmType}`] = repair;
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_${bpmType}s/${repair.id}`,
      method: 'PUT',
      body: data,
    }),
  );
};
