import { editOrganization, getOrganizations } from '@core/admin/organizations';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { OrganizationsFilterModal } from './components/OrganizationsFilterModal';
import { OrganizationsResults } from './components/OrganizationsResults';

class OrganizationsIndexPresentation extends SimpleFilter {
  searchFunction = getOrganizations;

  setOrganizationStatus = async (id, active) => {
    const { status } = await editOrganization({ id, active });
    if (status === 200) {
      active ? this.props.activateNotification() : this.props.deactivateNotification();
      await this.handleFilter(this.state.filters, true);
    }
  };

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Row>
              <Col>
                <div className="content-actions float-right">
                  <Button size="sm" color="secondary" onClick={this.toggle}>
                    <FontAwesome name="filter" /> Filtros
                  </Button>
                  <Button tag={NavLink} size="sm" outline color="success" to="/admin/organizations/new">
                    <FontAwesome name="plus" /> Crear Organización
                  </Button>
                </div>
                <h4 className="mb-4">
                  <FontAwesome name="university" className="text-primary mr-2" />
                  Gestión de Organizaciones
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!items && (
                      <div className="text-center h1 text-info">
                        <Spinner />
                      </div>
                    )}

                    {items && (
                      <OrganizationsResults
                        organizations={items}
                        pagination={pagination}
                        setOrganizationStatus={this.setOrganizationStatus}
                      />
                    )}

                    {(!items || items.length > 0) && paginationTemplate}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <OrganizationsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </Container>
    );
  }
}

export const OrganizationsIndex = connect(null, (dispatch) => ({
  activateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Organización activada',
        text: 'La organización ha sido reactivada exitosamente.',
        color: 'success',
      }),
    ),
  deactivateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Organización desactivada',
        text: 'La organización ha sido desactivada exitosamente.',
        color: 'danger',
      }),
    ),
}))(OrganizationsIndexPresentation);
