import { getAttachments } from '@core/building/attachments';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { AttachmentUploader } from '@shared/attachment-manager';
import { BuildingLayout } from '@shared/layouts';
import { connect, notificationsGenerators, store } from '@store';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { reset } from 'redux-form';
import AttachmentResults from './AttachmentsResults';

const enhance = compose(
  connect(null, (dispatch) => ({
    creationNotification: (name) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Archivo cargado',
          text: `El archivo ${name} ha sido adjuntado exitosamente.`,
          color: 'success',
        }),
      ),
  })),
  withState('displayForm', 'setDisplayForm', false),
  withState('attachments', 'setAttachments', undefined),
  withState('pagination', 'setPagination', undefined),
  withHandlers({
    clearForm: () => () => {
      store.dispatch(reset('attachmentForm'));
    },
    loadAttachments: ({ setAttachments, setPagination, resourceType }) => async (page = 1) => {
      const { body, pagination, status } = await getAttachments({
        resourceId: 'not_required',
        resourceType: 'organizations',
        p: page,
      });

      if (status === 200) {
        setAttachments(body);
        setPagination(pagination);
        return body;
      }
    },
  }),
  withHandlers({
    toogleConfirmBox: ({ setDisplayForm, displayForm, clearForm }) => () => {
      setDisplayForm(!displayForm);
      clearForm();
    },
  }),
  withHandlers({
    onSuccessfulAttachment: ({ loadAttachments, setAttachments, toogleConfirmBox, creationNotification }) => async (
      resource,
    ) => {
      await loadAttachments(1);

      creationNotification(resource.title);
      toogleConfirmBox();
    },
  }),
  withHandlers({
    setPage: ({ pagination, loadAttachments }) => async (page) => {
      console.log(page);
      if (pagination && page === pagination.page) {
        return;
      }

      loadAttachments(page);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { loadAttachments } = this.props;
      await loadAttachments();
    },
  }),
);

const AttachmentView = ({
  attachments,
  pagination,
  displayForm,
  setIsOpen,
  toogleConfirmBox,
  onSuccessfulAttachment,
  setAttachments,
  setPage,
}) => {
  return (
    <BuildingLayout>
      <Row>
        <Col>
          <div className="content-actions float-right">
            <Button size="sm" outline color="success" onClick={toogleConfirmBox}>
              <FontAwesome name="plus" /> Agregar un archivo
            </Button>
          </div>
          <h4 className="mb-4">
            <FontAwesome name="file-image" className="text-primary mr-2" />
            Multimedia
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {!attachments && (
                <div className="text-center h1 text-info">
                  <Spinner />
                </div>
              )}
              {attachments && pagination && (
                <AttachmentResults setAttachments={setAttachments} attachments={attachments} pagination={pagination} />
              )}
              {(!attachments || attachments.length > 0) && pagination && (
                <Pagination page={pagination.page} total={pagination.total} setPage={setPage} />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AttachmentUploader
        resourceType="organizations"
        showModal={displayForm}
        toggle={toogleConfirmBox}
        onSuccessfulAttachment={onSuccessfulAttachment}
      />
    </BuildingLayout>
  );
};

export default enhance(AttachmentView);
