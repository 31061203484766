import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Button, CardText } from 'reactstrap';
import { NavLink, Redirect } from 'react-router-dom';

import { Field, Form, SubmissionError } from '@core/forms';
import { checkPasswordToken, changePassword } from '@core/password';
import Validates, { passwordsMatching, presence } from '@core/forms/validations';
import { connect, sessionGenerators } from '@store';
import { ProcessingButton } from '@shared';
import { SimpleContainerLayout } from '@shared/layouts';

class Recovery extends Component {
  state = {
    name: '',
    loading: true,
    notFound: false,
    success: false,
  };

  async componentDidMount() {
    const [uid, token] = this.props.match.params.token.split(':');

    if (uid === undefined || token === undefined) {
      this.setState({
        loading: false,
        notFound: true,
      });

      return;
    }

    const { status, body } = await checkPasswordToken({ uid, token });

    switch (status) {
      case 200:
      case 204:
        this.setState({
          loading: false,
          notFound: false,
          name: body.firstName.split(' ')[0],
        });
        break;
      default:
        this.setState({
          loading: false,
          notFound: true,
        });
        break;
    }
  }

  reset = async (data) => {
    const [uid, token] = this.props.match.params.token.split(':');
    const { password, passwordConfirmation } = data;

    const { status, body } = await changePassword({
      uid,
      token,
      password,
      passwordConfirmation,
    });

    if (status === 200) {
      this.props.dispatchLogin(body);

      this.setState({
        success: true,
      });
    } else if (status === 404) {
      this.setState({
        notFound: true,
      });
    } else if (status === 422) {
      this.setState({
        success: false,
      });

      throw new SubmissionError(body.errors);
    }
  };

  render() {
    if (this.state.notFound) return <Redirect to="/account/login" />;

    if (this.state.success) return <RecoverySuccess name={this.state.name} />;

    return <RecoveryForm loading={this.state.loading} onSubmit={this.reset} />;
  }
}

const validate = Validates(
  presence({ of: 'password' }),
  presence({ of: 'passwordConfirmation' }),
  passwordsMatching('password', 'passwordConfirmation'),
);

const RecoveryFormPresentation = ({ handleSubmit, invalid, submitting, pristine, error }) => {
  return (
    <div className="mt-4 mb-4">
      <SimpleContainerLayout title="Editar Email / Contraseña">
        <Form error={error} noValidate onSubmit={handleSubmit}>
          <Field label="Nueva contraseña" name="password" type="password" required />

          <Field label="Confirmar contraseña" name="passwordConfirmation" type="password" required />

          <div className="text-center">
            <ProcessingButton
              type="submit"
              role="button"
              color="primary"
              outline
              disabled={pristine || invalid}
              processing={submitting}
              width="10em"
            >
              Guardar
            </ProcessingButton>
          </div>
        </Form>
      </SimpleContainerLayout>
    </div>
  );
};

const RecoveryForm = reduxForm({
  form: 'recoveryForm',
  validate,
})(RecoveryFormPresentation);

export const RecoverySuccess = ({ name }) => (
  <div className="mt-4">
    <SimpleContainerLayout title={`¡Listo, ${name}!`}>
      <CardText className="text-center">
        Tu contraseña ha sido actualizada exitosamente. Desde ahora puedes ingresar con tu dirección de correo y la
        contraseña que acabas de escribir.
      </CardText>

      <div className="text-center">
        <Button tag={NavLink} to="/" color="primary">
          Comenzar
        </Button>
      </div>
    </SimpleContainerLayout>
  </div>
);

export default connect(null, (dispatch) => ({
  dispatchLogin: (token) => dispatch(sessionGenerators.login(token)),
}))(Recovery);
