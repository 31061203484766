import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Alert, Col, Form, FormGroup, Label, Input, Button } from 'reactstrap';

import { recoverPassword } from '@core/password';
import { ProcessingButton } from '@shared';

class Forgot extends Component {
  state = {
    working: false,
    success: false,
    error: false,
    messages: [],
    successEmail: '',
  };

  constructor(props) {
    super(props);
    this.emailInput = React.createRef();
  }

  onOpenModal = () => {
    if (this.props.show) this.emailInput.current && this.emailInput.current.focus();
  };

  recoverPassword = async (event) => {
    if (event) event.preventDefault();

    this.setState({
      working: true,
    });

    const { status } = await recoverPassword(this.emailInput.current.value);

    if (status === 201)
      this.setState({
        success: true,
        working: false,
        successEmail: this.emailInput.current.value,
      });
    else if (status === 404)
      this.setState({
        working: false,
        error: true,
        messages: [
          'No encontramos un usuario registrado con esta dirección de correo. Por favor, intenta nuevamente o contáctanos.',
        ],
      });
    else if (status === 409)
      this.setState({
        working: false,
        error: true,
        messages: [
          'El correo de recuperación de contraseña ya fue enviado. Si no has podido recibirlo, por favor contáctanos.',
        ],
      });
    else
      this.setState({
        working: false,
        error: true,
        messages: ['Ocurrió un problema. Por favor intenta más tarde. Contáctanos si los problemas persisten.'],
      });
  };

  layout(children) {
    const { toggle, show, className } = this.props;
    const { working, success } = this.state;

    const disableButton = working || success;

    return (
      <Modal isOpen={show} toggle={toggle} className={className} onOpened={this.onOpenModal}>
        <ModalHeader toggle={toggle}>Recuperar contraseña</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {success ? (
            <Button role="button" type="button" color="secondary" onClick={toggle}>
              Cerrar
            </Button>
          ) : (
            <ProcessingButton
              role="button"
              disabled={disableButton}
              type="submit"
              color="primary"
              onClick={this.recoverPassword}
              processing={working}
            >
              Recuperar contraseña
            </ProcessingButton>
          )}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { email } = this.props;
    const { success, error, messages, successEmail } = this.state;

    if (success) {
      return this.layout(
        <div>
          Hemos enviado un correo a <strong>{successEmail}</strong> con instrucciones para recuperar tu contraseña. No
          olvides revisar en tu carpeta de correo electrónico no deseado.
        </div>,
      );
    } else {
      return this.layout(
        <div>
          {error ? (
            messages.map((message, index) => (
              <Alert color="danger" key={index}>
                {message}
              </Alert>
            ))
          ) : (
            <Alert color="info">
              Introduce tu dirección de correo. Si es una cuenta registrada, te enviaremos un mensaje con instrucciones
              para recuperar tu contraseña.
            </Alert>
          )}

          <Form onSubmit={this.recoverPassword}>
            <FormGroup row>
              <Label for="email" md={2} className="text-right">
                Email
              </Label>
              <Col md={10}>
                <Input type="email" name="email" id="email" defaultValue={email} innerRef={this.emailInput} />
              </Col>
            </FormGroup>
          </Form>
        </div>,
      );
    }
  }
}

export default Forgot;
