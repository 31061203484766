import { transferConsumables } from '@core/building/items';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { TransferItemForm } from './components/TransferItemForm';

class TransferItemPresentation extends Component {
  state = {
    success: false,
    notFound: true,
  };

  componentWillMount() {
    // Parse target from URL
    const {
      location: { search },
    } = this.props;

    const query = new URLSearchParams(search);
    const target = query.get('of');
    this.setState({
      initialValues: {
        barcode: target,
      },
    });
  }

  transferConsumables = async (formData) => {
    const { body, status } = await transferConsumables(formData);

    if (status === 200) {
      this.props.transferNotification(body);

      this.setState({
        success: true,
      });
    } else if (status === 422) {
      this.props.errorNotification(body);
    }
  };

  render() {
    const { success, initialValues } = this.state;

    if (success) return <Redirect to={'/movements'} />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4">
        <TransferItemForm onSubmit={this.transferConsumables} initialValues={initialValues} />
      </SimpleContainerLayout>
    );
  }
}

export const TransferItem = connect(null, (dispatch) => ({
  transferNotification: (responseData) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Activo transferido',
        text: `El activo: ${responseData.name}, con el codigo: ${responseData.barcode1}, se transfirió exitosamente.`,
        color: 'success',
        duration: 20000,
      }),
    ),
  errorNotification: (body) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error',
        text: `El activo no pudo ser transferido. ${body?.errors.join(', ')}`,
        color: 'danger',
        duration: 10000,
      }),
    ),
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error',
        text: `Activo no encontrado.`,
        color: 'danger',
        duration: 10000,
      }),
    ),
}))(TransferItemPresentation);
