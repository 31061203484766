import React from 'react';
import { branch, compose, renderComponent } from 'recompose';
import { NavLink, withRouter } from 'react-router-dom';
import { Alert } from 'reactstrap';

import { connect } from '@store';
import { FontAwesome } from '@shared';

const enhance = compose(
  withRouter,
  connect(({ deskSession: { currentClient }, session }) => ({
    currentClient,
    session,
  })),
  branch(
    ({ currentClient, location, session }) =>
      !session || !session.loggedIn || !currentClient || location.pathname === '/movements/desk',
    renderComponent(() => null),
  ),
);

const DeskSessionWarning = () => (
  <Alert color="info" className="text-center m-0 rounded-0">
    <FontAwesome name="desktop" className="mr-2" />
    <span>Una sesión de escritorio sin finalizar se encuentra abierta actualmente.</span>{' '}
    <NavLink to="/movements/desk" className="alert-link">
      Ir a la sesión.
    </NavLink>
  </Alert>
);

export default enhance(DeskSessionWarning);
