import React from 'react';
import Validates, { presence, numericality } from '@core/forms/validations';
import { Button, Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { Field } from '@core/forms';
import { FontAwesome } from '@shared';
import { reduxForm } from 'redux-form';
import './SubtaskForm.scss';

const validate = Validates(
  presence({ of: 'title' }),
  presence({ of: 'estimatedHours' }),
  numericality({ of: 'estimatedHours', greaterThanOrEqualTo: 0 }),
);

const SubtaskFormPresentation = ({ handleSubmit, invalid, pristine, handleCancel }) => (
  <div>
    <Row className={'d-flex align-items-center'}>
      <Col xs={7}>
        <Field formGroupClassName={'mb-0'} name="title" placeholder="Título" />
      </Col>
      <Col xs={3}>
        <Field formGroupClassName={'mb-0'} name="estimatedHours" placeholder="Horas Estimadas" />
      </Col>
      <Col className={'subtask-actions'}>
        <Button
          type="button"
          role="button"
          size="sm"
          color="success"
          disabled={pristine || invalid}
          onClick={handleSubmit}
        >
          <FontAwesome name="check" />
        </Button>
        <Button
          className={'delete-subtask-btn'}
          type="button"
          role="button"
          size="sm"
          color="danger"
          onClick={handleCancel}
        >
          <FontAwesome name="times" />
        </Button>
      </Col>
    </Row>
  </div>
);

export const SubtaskForm = compose(
  reduxForm({
    form: 'subtaskForm',
    validate,
  }),
)(SubtaskFormPresentation);
