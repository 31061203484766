import { getMovements } from '@core/building/movements';
import { buildingDownload } from '@core/http.js';
import { ExcelDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { MovementsTable } from '@shared/movements';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { get } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { MovementsFilterModal } from './components/MovementsFilterModal';

export class MovementsIndex extends SimpleFilter {
  searchFunction = getMovements;

  render() {
    const { filters, items, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    const hasPeriod = get(filters, 'movement_period{}.started_at', false) && get(filters, 'movement_period{}.ended_at');

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                <ExcelDownload
                  file="movements"
                  filters={filters}
                  downloadFile={buildingDownload}
                  disabled={(items || []).length === 0 || !hasPeriod}
                />
              </ShowForRoles>
              <ShowForRoles excluded={['guest']}>
                <Button role="button" size="sm" outline color="success" tag={NavLink} to="/movements/new">
                  <FontAwesome name="plus" /> Nuevo Movimiento
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="search" className="text-primary mr-2" />
              Gestión de Movimientos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <MovementsTable movements={items} pagination={pagination} showActions={true} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <MovementsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
