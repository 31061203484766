import React from 'react';
import { Badge, Media } from 'reactstrap';

import { getItem, getItems, getItemsFromBuilding } from '@core/building/items';
import { RemoteSelector } from '@core/forms';
import { PictureDisplay } from '@shared';

export const ItemTemplate = ({ resource: item }) => (
  <Media className="align-items-center mt-1 mb-1">
    <Media left className="mr-3">
      <PictureDisplay picture={item.defaultPicture} pictureSize="icon" alt={item.name} width="6rem" height="4rem" />
    </Media>
    <Media body>
      <div>
        <strong>{item.name}</strong> {item.consumable && <Badge color="danger">Consumible</Badge>}
      </div>
      <div>
        SKU Interno: <span className="text-muted">{item.internalSku}</span>{' '}
        {item.externalSku && (
          <span>
            {' '}
            - SKU Externo: <span className="text-muted">{item.externalSku}</span>
          </span>
        )}
      </div>
    </Media>
    {item.inventory && (
      <Media right>
        <div>
          Disponible: <span className="text-muted">{item.inventory.availableQuantity || 0}</span>{' '}
        </div>
      </Media>
    )}
  </Media>
);

const ItemSelector = ({
  currentItem,
  inputProps,
  building = false,
  resourceKey = 'barcode1',
  filters,
  ...otherProps
}) => (
  <RemoteSelector
    {...otherProps}
    resourceKey={resourceKey}
    remoteLoader={getItem}
    remoteFinder={(query) =>
      building ? getItemsFromBuilding({ search: query, ...filters }) : getItems({ search: query, ...filters })
    }
    dropdownTemplate={ItemTemplate}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre o escanear código',
    }}
    minLength={5}
  />
);

export default ItemSelector;
