import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { InventoryCheckerSetRow } from './InventoryCheckerSetRow';

export const InventoryCheckerSetsResults = ({
  inventoryCheckerSets = [],
  pagination,
  processing,
  activeAutomation,
}) => {
  return (
    <div>
      <div className="small mb-4">
        <strong>{pagination.count}</strong> {pluralize(pagination.count, 'reporte encontrado', 'reportes encontrados')}.
        Mostrando <strong>{pagination.per}</strong> reportes por página.
      </div>

      <Table>
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <tr>
            <th className="text-left">Nº</th>
            <th className="text-center">Tipo</th>
            <th className="text-center">Estado</th>
            <th className="text-center">Inicio</th>
            <th className="text-center">Fin</th>
            <th className="text-center">Periodicidad</th>
            <th className="text-center">Última Orden</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {inventoryCheckerSets.length > 0 &&
            inventoryCheckerSets.map((inventoryCheckerSet, index) => (
              <InventoryCheckerSetRow
                inventoryCheckerSet={inventoryCheckerSet}
                key={index}
                processing={processing === index}
                activeAutomation={activeAutomation}
              />
            ))}
          {inventoryCheckerSets.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center mt-3 mb-3">
                <em>No se encontraron auditorías programadas.</em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
