import { format, subMonths } from 'date-fns';
import { orderBy } from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Toggle from 'react-toggle';
import { Badge, Button, Card, CardBody, CardHeader, Form, FormGroup, Label, Media, Table } from 'reactstrap';

import { getConsumablesByDate } from '../../../@core/building/reports';
import { PictureDisplay } from '../../../@shared';
import { Highcharts } from '../../../@shared/charts';
import { DateSelector } from '../../../@shared/forms';
import Chart from './Chart';

const DATE_FORMAT = 'yyyy-MM-dd';

type CustomInputProps = {
  onClick?: Function,
  value?: string,
};

class ExampleCustomInput extends React.Component<CustomInputProps> {
  render() {
    return (
      <Button color="primary" onClick={this.props.onClick} size="sm">
        {this.props.value}
      </Button>
    );
  }
}

type State = {
  list: boolean,
  data: Object[],
  startDate: Date,
  endDate: Date,
  today: Date,
};

const getChartOptions = (title, data) => {
  let categories = [];
  let entries = [];
  let withdrawals = [];

  data.forEach((obj) => {
    categories.push(obj.item.name);
    entries.push(obj.movementsResult.entry);
    withdrawals.push(obj.movementsResult.withdrawal);
  });

  return {
    title: {
      text: title,
    },
    credits: {
      enabled: true,
      href: 'http://www.alliot.cloud',
      text: 'alliot.cloud',
    },
    chart: {
      zoomType: 'x',
      type: 'column',
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: {
        text: 'Cantidad de Movimientos',
      },
    },
    series: [
      {
        name: 'Entradas',
        data: entries,
      },
      {
        name: 'Salidas',
        data: withdrawals,
        color: 'rgba(248,161,63,1)',
        pointPadding: 0.3,
        pointPlacement: 0,
      },
    ],
  };
};

class Consumable extends React.Component<*, State> {
  constructor(props: any) {
    super(props);

    const today = new Date();
    this.state = {
      list: false,
      data: [],
      startDate: subMonths(today, 1),
      endDate: today,
      today,
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  handleResponse = (response: Response) => {
    const { status, body } = response;

    const data = orderBy(body || [], 'movementsResult.withdrawal', 'desc');

    if (status === 200) {
      this.setState({
        data: data,
      });
    }
  };

  fetchData = async () => {
    const { startDate, endDate } = this.state;

    const response = await getConsumablesByDate({
      from: format(startDate, DATE_FORMAT),
      to: format(endDate, DATE_FORMAT),
    });

    this.handleResponse(response);
  };

  toggleList = (e: any) => {
    const { checked } = e.target;

    this.setState({
      list: checked,
    });
  };

  handleChangeStart = (date: Date) => {
    this.setState(
      {
        startDate: date,
      },
      async () => {
        await this.fetchData();
      },
    );
  };

  handleChangeEnd = (date: Date) => {
    this.setState(
      {
        endDate: date,
      },
      async () => {
        await this.fetchData();
      },
    );
  };

  render() {
    const { title } = this.props;
    const { data, list, startDate, endDate, today } = this.state;

    let Cmp;
    if (list) {
      Cmp = ItemTable;
    } else {
      Cmp = ConsumableChart;
    }

    return (
      <Card>
        <CardHeader className="chart-title-container">
          <span className="float-right">
            <span style={{ display: 'flex', verticalAlign: 'middle' }}>
              <Form inline>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label className="mr-sm-2">Listado</Label>
                  <Toggle className="mr-sm-2" checked={list} onChange={this.toggleList} value="yes" />
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label className="mr-sm-2">Desde</Label>
                  <DateSelector
                    customInput={<ExampleCustomInput />}
                    selected={startDate}
                    selectsStart
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={this.handleChangeStart}
                  />
                </FormGroup>
                <FormGroup className="mb-2 mb-sm-0">
                  <Label className="mr-sm-2">Hasta</Label>
                  <DateSelector
                    customInput={<ExampleCustomInput />}
                    selected={endDate}
                    selectsEnd
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={this.handleChangeEnd}
                  />
                </FormGroup>
              </Form>
            </span>
          </span>
          <h4>Consumos</h4>
        </CardHeader>
        <CardBody className={list ? 'no-pad' : 'p-2'} style={{ minHeight: '416px' }}>
          <Cmp data={data} title={title} />
        </CardBody>
      </Card>
    );
  }
}

const ConsumableChart = ({ data, title }) => <Chart options={getChartOptions(title, data)} highcharts={Highcharts} />;

const ItemRow = ({ item, movementsResult }) => (
  <tr key={item.barcode1}>
    <td style={{ width: '70%' }} className="align-middle text-overflow">
      <Media className="align-items-center pl-2" tag={NavLink} to={`/organization/items/${item.barcode1}`}>
        <Media left className="mr-1" tag="div">
          <PictureDisplay picture={item.defaultPicture} pictureSize="icon" alt={item.name} width="3rem" height="2rem" />
        </Media>
        <Media body tag="div">
          <div>{item.name}</div>
          <div>
            <code>{item.barcode1}</code>
          </div>
        </Media>
      </Media>
    </td>
    <td style={{ width: '15%', paddingTop: '0.9rem' }} className="align-middle text-center">
      <Badge color="success" pill>
        <span>{movementsResult.entry}</span>
      </Badge>
    </td>
    <td style={{ width: '15%', paddingTop: '0.9rem' }} className="align-middle text-center">
      <Badge color="danger" pill>
        <span>{movementsResult.withdrawal}</span>
      </Badge>
    </td>
  </tr>
);

const ItemTable = ({ data }) => (
  <Table size="sm" className="table-scroll">
    <thead>
      <tr>
        <th style={{ width: '70%' }} className="pl-3">
          Nombre
        </th>
        <th style={{ width: '15%' }} className="text-center">
          Entrada
        </th>
        <th style={{ width: '15%' }} className="text-center">
          Salida
        </th>
      </tr>
    </thead>
    <tbody>
      {data.map((result) => (
        <ItemRow item={result.item} movementsResult={result.movementsResult} />
      ))}
    </tbody>
  </Table>
);

export default Consumable;
