import { createBuilding, getBuildingTypes } from '@core/admin/buildings';
import { getOrganization } from '@core/admin/organizations';
import { SubmissionError } from '@core/forms';
import { Loading, simpleFilterQuery } from '@shared';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { BuildingForm } from './components/BuildingForm';

class CreateBuildingPresentation extends Component {
  state = {
    created: false,
    loading: true,
    organizationId: undefined,
    errors: [],
    building: {},
    organization: {},
    buildingTypes: [],
  };

  async componentDidMount() {
    const { organizationId } = this.props.match.params;

    await this.getBuildingTypes();

    if (organizationId === undefined || organizationId === null) {
      this.setState({ loading: false });
      return;
    }

    const { body: organization, status } = await getOrganization(organizationId);

    if (status.toString().startsWith('2'))
      this.setState({
        loading: false,
        building: { organization },
        organization: organization,
        organizationId: organizationId,
      });
  }

  async getBuildingTypes() {
    const { body: buildingTypes, status } = await getBuildingTypes();

    if (status.toString().startsWith('2')) {
      const a = { 0: 'Seleccione...' };
      buildingTypes.forEach((e) => {
        a[e.id] = e.name;
        return a;
      });
      this.setState({
        buildingTypes: a,
      });
    }
  }

  createBuilding = async (building) => {
    const { body, status } = await createBuilding({ ...building });

    if (status === 201) {
      this.props.creationNotification(body.name);

      this.setState({
        created: true,
        building: body,
      });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { created, organizationId, loading, building, buildingTypes } = this.state;

    if (created)
      return (
        <Redirect
          to={`/admin/buildings?q=${simpleFilterQuery({
            'organizations[{}]': [
              {
                label: building.organization.name,
                value: building.organization.id,
              },
            ],
          })}`}
        />
      );

    if (loading) return <Loading />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4" title="Nueva instalación">
        <BuildingForm
          onSubmit={this.createBuilding}
          initialValues={{
            ...building,
            organizationId: organizationId,
          }}
          buildingTypes={buildingTypes}
        />
      </SimpleContainerLayout>
    );
  }
}

export const CreateBuilding = connect(null, (dispatch) => ({
  creationNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Instalación creada',
        text: `La instalación '${name}' ha sido creada exitosamente.`,
        color: 'success',
        duration: 10000,
      }),
    ),
}))(CreateBuildingPresentation);
