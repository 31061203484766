import React from 'react';
import PictureUploader from './PictureUploader';

const SinglePictureComponent = (endpoint, postResource = '/avatars') => (props) => (
  <PictureUploader
    endpoint={endpoint}
    postResource={postResource}
    token={props.value}
    multiple={false}
    onChange={(tokens) =>
      Array.isArray(tokens) ? props.onChange(tokens.join(',') || '') : props.onChange(tokens || '')
    }
  />
);

export default SinglePictureComponent;
