import { editUser, getUser } from '@core/admin/users';
import { SubmissionError } from '@core/forms';
import { Loading } from '@shared';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { UserActions } from './components/UserActions';
import { UserEditorLayout } from './components/UserEditorLayout';
import { UserForm } from './components/UserForm';

class EditUserPresentation extends Component {
  state = {
    loading: true,
    success: false,
    user: {},
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id)
      this.setState({
        success: false,
      });

    const { body: user, status } = await getUser(id);

    if (status === 404) {
      this.props.notFoundNotification();

      this.setState({
        disabled: true,
        loading: false,
        success: true,
      });
    } else if (status === 200) {
      this.setState({
        loading: false,
        user,
      });
    }
  }

  editUser = async (data) => {
    const { body, status } = await editUser(data);

    if (status === 200) {
      this.setState({
        success: true,
      });

      this.props.editingNotification(data);
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { success, loading, user } = this.state;

    if (loading) return <Loading />;

    if (success) return <Redirect to={`/admin/users`} />;

    return (
      <UserEditorLayout user={user}>
        <UserActions user={user} />
        <UserForm
          showPasswordFields={true}
          onSubmit={this.editUser}
          back={this.props.history.goBack}
          initialValues={{ ...user, identifierValue: user.identifier.value, partnerId: user.partner?.id }}
          title="Editar usuario"
        />
      </UserEditorLayout>
    );
  }
}

export const EditUser = connect(null, (dispatch) => ({
  editingNotification: (formData) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Usuario editado',
        text: `La cuenta de ${formData.firstName} ${formData.lastName} ha sido editada exitosamente.`,
        color: 'success',
        duration: 10000,
      }),
    ),
  notFoundNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No encontrado',
        text: `El usuario buscado no existe. Si los problemas persisten, por favor contacta a un administrador.`,
        color: 'danger',
        duration: 10000,
      }),
    ),
}))(EditUserPresentation);
