import React, { Component } from 'react';
import { Button, Card } from 'reactstrap';

import { FontAwesome, Spinner } from '@shared';

class ImageCard extends Component {
  render() {
    const { file, height, onDelete } = this.props;

    let src;

    if (file.response)
      src = (file.response.image && file.response.image.url) || (file.response.file && file.response.file.url);
    else src = file.data.preview;

    return (
      <div className="card-container main-animation">
        {file.status === 'uploaded' && (
          <Button size="sm" color="danger" className="image-controls" role="button" onClick={onDelete}>
            <strong>&times;</strong>
          </Button>
        )}
        <Card className="image-card mr-3 mb-3" style={{ height }}>
          <div className="h-100">
            <img src={src} alt="" className="h-100" />
          </div>
          {file.status === 'uploading' && (
            <div className="layer">
              <Spinner />
            </div>
          )}

          {file.status === 'failed' && (
            <div className="layer layer-danger">
              <FontAwesome name="times" />
            </div>
          )}
        </Card>
      </div>
    );
  }
}

export default ImageCard;
