import React from 'react';
import { getEvents } from '@core/event/events';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { Card, CardBody, Col, Button, Row } from 'reactstrap';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { EventsResults } from 'events/components/EventsResults';
import { EventsFilterModal } from 'events/components/EventsFilterModal';
import { BuildingLayout } from '@shared/layouts';

export class EventsIndex extends SimpleFilter {
  searchFunction = getEvents;

  render() {
    const { items, pagination, modalOpen, filters } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="calendar-alt" className="mr-2 text-primary" /> Últimos Eventos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <EventsResults events={items} pagination={pagination} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <EventsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
