import React from 'react';
import { compose, onlyUpdateForKeys, withHandlers } from 'recompose';
import type { HOC } from 'recompose';

import { FontAwesome } from '@shared';

import type { ColumnMetadata } from './types';

type Props = {
  metatada: ColumnMetadata,
  onHeaderClick: Function,
  sortColumn: string,
  sortDirection: string,
};

const enhance: HOC<*, Props> = compose(
  onlyUpdateForKeys(['metatada', 'onHeaderClick', 'sortColumn', 'sortDirection']),
  withHandlers({
    handleOnHeaderClick: ({ onHeaderClick, sortColumn: currentSortColumn, sortDirection: currentSortDirection }) => (
      sortColumn: string,
    ) => {
      let sortDirection;

      if (sortColumn === currentSortColumn) {
        sortDirection = currentSortDirection === 'ASC' ? 'DESC' : 'ASC';
      } else {
        sortDirection = 'ASC';
      }

      onHeaderClick({
        sortColumn,
        sortDirection,
      });
    },
  }),
);

const DefaultHeaderColumn = ({
  //props
  metatada: { displayName, columnName, toolTip, style },
  sortColumn,
  sortDirection,
  // handlers
  handleOnHeaderClick,
}) => {
  let sortIcon = null;

  if (columnName === sortColumn) {
    sortIcon = <FontAwesome name={`sort-${sortDirection.toLowerCase()}`} />;
  }

  return (
    <th role="button" onClick={() => handleOnHeaderClick(columnName)} style={style}>
      <div title={toolTip || displayName}>
        {displayName} {sortIcon}
      </div>
    </th>
  );
};

export default enhance(DefaultHeaderColumn);
