import { getAllUsers } from '@core/building/users';
import { buildingDownload } from '@core/http.js';
import { ExcelDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { UsersFilterModal } from './components/UsersFilterModal';
import { UsersResults } from './components/UsersResults';

export class UsersIndex extends SimpleFilter {
  searchFunction = getAllUsers;

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                <ExcelDownload file="users" filters={filters} downloadFile={buildingDownload} disabled={!items} />
              </ShowForRoles>
              <Button tag={NavLink} size="sm" outline color="success" to="/building/users/new">
                <FontAwesome name="plus" /> Nuevo usuario
              </Button>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="user" className="mr-2 text-primary" /> Gestión de Usuarios
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <UsersResults users={items} pagination={pagination} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <UsersFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
