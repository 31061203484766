import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getLocations = (organizationId, query) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/ms-geofence',
      endpoint: `/geofences${mountQuery({ ...query, organizationId })}`,
      method: 'GET',
    }),
  );

export const getLocation = (id) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/ms-geofence',
      endpoint: `/geofences/${id}`,
      method: 'GET',
    }),
  );

export const createLocation = (location) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/ms-geofence',
      endpoint: `/geofences/`,
      method: 'POST',
      body: location,
      parseBody: false,
    }),
  );

export const editLocation = ({ id, ...location }) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/ms-geofence',
      endpoint: `/geofences/${id}`,
      method: 'PATCH',
      body: location,
      parseBody: false,
    }),
  );

export const deleteLocation = ({ id }) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/ms-geofence',
      endpoint: `/geofences/${id}`,
      method: 'DELETE',
      parseBody: false,
    }),
  );

export const getRemoteGeofences = (code) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/ms-geofence',
      endpoint: `/geofences${mountQuery({ code })}`,
      method: 'GET',
    }),
  );
