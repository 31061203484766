import { FontAwesome, ShowBool } from '@shared';
import React from 'react';
import { Col, Row } from 'reactstrap';

const KIND = { client: 'Cliente', supplier: 'Proveedor' };

export const PartnerSummary = ({ partner }) => (
  <div className="mb-4">
    <Row>
      <Col sm={12} md={6} className="mb-4">
        <h5 className="mb-3">Información del socio de negocio</h5>

        <table className="w-100">
          <colgroup>
            <col style={{ width: '35%' }} />
            <col style={{ width: '75%' }} />
          </colgroup>
          <tbody>
            <tr>
              <td className="text-right align-top pr-3">Nombre</td>
              <td className="align-top text-muted">{partner.name}</td>
            </tr>
            <tr>
              <td className="text-right align-top pr-3">Tipo</td>
              <td className="align-top text-muted small">
                <div className="mb-1">{KIND[partner.kind]}</div>
              </td>
            </tr>
            {partner.partnerType && (
              <tr>
                <td className="text-right align-top pr-3">Tipo</td>
                <td className="align-top text-muted">{partner.partnerType.description}</td>
              </tr>
            )}
            <tr>
              <td className="text-right align-top pr-3">¿Activo?</td>
              <td className="align-top text-muted">
                <ShowBool value={partner.active} />
              </td>
            </tr>
          </tbody>
        </table>
      </Col>
      <Col sm={12} md={6} className="mb-4">
        <h5 className="mb-4">Información adicional</h5>

        <Row>
          <Col xs={12} md={8}>
            <h6 className="mb-2">Descripción</h6>
            <div className="mb-4 small text-muted">{partner.description || <em>No especificada.</em>}</div>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row>
          <Col>
            <h5 className="mb-3">{partner.buildings.length > 0 ? 'Instalaciones:' : 'Sin instalaciones asociadas'}</h5>
          </Col>
        </Row>
        {partner.buildings.map((building, index) => (
          <Row key={`${building.name}-${index}`}>
            <Col>
              <FontAwesome name="industry" className="mr-2" /> {building.name}
            </Col>
          </Row>
        ))}
      </Col>
      <Col>
        <Row>
          <Col>
            <h5 className="mb-3">{partner.buildings.length > 0 ? 'Usuarios:' : 'Sin usuarios asociados'}</h5>
          </Col>
        </Row>
        {partner.users.map((user, index) => (
          <Row key={`${user.name}-${index}`}>
            <Col>
              <FontAwesome name="user" className="mr-2" /> {user.name}
            </Col>
          </Row>
        ))}
      </Col>
    </Row>
  </div>
);
