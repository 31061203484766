import { handleAuth, withToken, apiFetch, mountQuery } from '../http';

export const getPartners = (query = {}) => {
  return handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/partners${mountQuery(query)}`,
      method: 'GET',
    }),
  );
};

export const getPartner = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/partners/${id}`,
      method: 'GET',
    }),
  );
