import React from 'react';
import ReactMarkdown from 'react-markdown';

const Markdown = ({ children, ...props }) => (
  <div className="markdown-area">
    <ReactMarkdown {...props} skipHtml={true}>
      {children}
    </ReactMarkdown>
  </div>
);

export default Markdown;
