import { connect, notificationsGenerators } from '@store';

export { default as PictureCard } from './PictureCard';
export { default as PictureModal } from './PictureModal';

export const addPictureManagerNotifications = (component) =>
  connect(null, (dispatch) => ({
    creationSuccess: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Imagen cargada',
          text: 'La imagen ha sido cargada exitosamente.',
          color: 'success',
        }),
      ),
    creationFailed: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Error',
          text:
            'Se encontró un error al cargar la imagen. Si los problemas persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      ),
    editingSuccess: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Imagen editada',
          text: 'La imagen ha sido editada exitosamente.',
          color: 'success',
        }),
      ),
    editingFailed: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Error',
          text:
            'Se encontró un error al editar la imagen. Si los problemas persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      ),
    deletionSuccess: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Imagen eliminada',
          text: 'La imagen ha sido eliminada exitosamente.',
          color: 'success',
        }),
      ),
    deletionFailed: () =>
      dispatch(
        notificationsGenerators.inser({
          title: 'Error',
          text:
            'Se encontró un error al eliminar la imagen. Si los problemas persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      ),
  }))(component);
