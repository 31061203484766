import React from 'react';
import { Media } from 'reactstrap';

import { getPartner, getPartners } from '@core/admin/partners';
import { RemoteSelector } from '@core/forms';

const KIND = { client: 'Cliente', supplier: 'Proveedor' };

const PartnerItem = ({ resource: partner }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{partner.name}</strong>
      </div>
      <div>
        <span className="small text-muted">
          Organización: <strong>{partner.organization?.name}</strong>
        </span>
        <span className="small text-muted"> - {KIND[partner.kind]}</span>
      </div>
    </Media>
  </Media>
);

const PartnerSelector = ({ filters, inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getPartner}
    remoteFinder={(query) => getPartners({ name: query, ...filters })}
    dropdownTemplate={PartnerItem}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre',
    }}
  />
);

export default PartnerSelector;
