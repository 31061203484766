import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getAssets = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/organization${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAsset = (barcode) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/assets/organization/${barcode}?${new URLSearchParams(window.location.search)}`,
      method: 'GET',
    }),
  );
