/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import TagsInput from 'react-tagsinput';

const Tags = ({ value, onChange, ...otherProps }) => (
  <TagsInput
    className="form-control tags"
    focusedClassName=""
    inputProps={{
      placeholder: 'Añadir palabra',
    }}
    tagProps={{
      className: 'chip',
      classNameRemove: '',
    }}
    renderTag={(props) => {
      let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;
      return (
        <span key={key} {...other}>
          {getTagDisplayValue(tag)}
          {!disabled && (
            <a className={classNameRemove} onClick={(e) => onRemove(key)}>
              <i className="fa fa-times" />
            </a>
          )}
        </span>
      );
    }}
    onlyUnique={true}
    addOnBlur={true}
    addOnPaste={true}
    pasteSplit={(data) => data.split(' ').map((d) => d.trim())}
    value={value || []}
    onChange={onChange}
    {...otherProps}
  />
);

export default Tags;
