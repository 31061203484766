import { editOrganization, getOrganization } from '@core/admin/organizations';
import { SubmissionError } from '@core/forms';
import { Loading } from '@shared';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { OrganizationForm } from './components/OrganizationForm';

class EditOrganizationPresentation extends Component {
  state = {
    loading: true,
    edited: false,
    organization: undefined,
    notFound: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) this.notFound();

    const { body: organization, status } = await getOrganization(id);

    if (status === 404) {
      this.notFound();
    } else if (status === 200) {
      this.setState({ organization });
    }

    this.setState({ loading: false });
  }

  notFound = () => {
    this.props.notFound();

    this.setState({ notFound: true });
  };

  editOrganization = async (data) => {
    const { body, status } = await editOrganization(data);

    if (status === 200) {
      this.setState({
        edited: true,
      });

      this.props.successNotification(data.name);
    } else if (status === 422) throw new SubmissionError(body.errors);
  };

  render() {
    const { organization, edited, loading } = this.state;

    if (loading) return <Loading />;

    if (edited) return <Redirect to="/admin/organizations" />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4" title={`Editando organización: ${organization.name}`}>
        {organization && <OrganizationForm initialValues={organization} onSubmit={this.editOrganization} />}
      </SimpleContainerLayout>
    );
  }
}

export const EditOrganization = connect(null, (dispatch) => ({
  successNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Organización creada',
        text: `La organización ${name} ha sido editada exitosamente.`,
        color: 'success',
        duration: 10000,
      }),
    ),
  notFoundNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No encontrado',
        text: `La organización buscada no existe o no se encuentra disponible en este momento. Si los problemas persisten, por favor contacte a un administrador.`,
        color: 'danger',
        duration: 10000,
      }),
    ),
}))(EditOrganizationPresentation);
