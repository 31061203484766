import { UserPortrait } from '@shared';
import { IdentifierDisplay } from '@shared/displays';
import { connect } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';

const UserRowPresentation = ({ user, roles }) => (
  <tr key={user.id}>
    <td className="align-middle text-overflow">
      <NavLink className="d-flex" to={`/building/users/${user.id}`}>
        <UserPortrait user={user} />
        <span className="ml-2" style={{ lineHeight: '2rem' }}>
          {user.firstName} {user.lastName}
        </span>
      </NavLink>
    </td>
    <td className="align-middle">{user.email}</td>
    <td className="align-middle">
      <IdentifierDisplay identifier={user.identifier} />
    </td>
    <td className="align-middle small">
      <ul className="pl-3 m-0">
        {user.newRoles &&
          user.newRoles.length > 0 &&
          user.newRoles.map((role, index) => (
            <li key={`${user.id}-${index}`}>
              <strong>{role.building}:</strong>{' '}
              {(roles[role.role] && roles[role.role].title) || <em>Sin identificar</em>}
            </li>
          ))}
      </ul>
    </td>
  </tr>
);

export const UserRow = connect(({ appData: { roles } }) => ({ roles }))(UserRowPresentation);
