import React from 'react';
import { mapboxApiKey } from '@config';
import L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import './geofence-editor.scss';

const SANTIAGO = [-33.447487, -70.673676];

const buildMapboxTileLayer = (id) => {
  return L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {
    id,
    attribution:
      '<a href="https://www.mapbox.com/">&copy; Mapbox</a> | <a href="https://www.openstreetmap.org/">&copy; OpenStreetMap</a>',
    maxZoom: 18,
    tileSize: 512,
    zoomOffset: -1,
    accessToken: mapboxApiKey,
  });
};

export class GeofenceEditor extends React.Component {
  map;

  state = {
    count: 0,
  };

  componentDidMount() {
    this.map = L.map('geofence-editor', {
      center: SANTIAGO,
      zoom: 13,
    });

    navigator.geolocation.getCurrentPosition(({ coords: { longitude, latitude } }) => {
      this.map.setView([latitude, longitude]);
    });

    this.setupLayers(this.map);
    this.setupEditor(this.map);
    this.setupEvents(this.map);

    this.setState({ count: 1 });

    if (this.props.input && this.props.input.value !== '') {
      const layer = L.geoJSON(this.props.input.value);
      layer.addTo(this.map);
      this.setupCreatedLayer(layer);
      this.map.setView(layer.getBounds().getCenter());
    }
  }

  setupLayers = (map) => {
    const baseMaps = {
      Calles: buildMapboxTileLayer('mapbox/streets-v11'),
      Terreno: buildMapboxTileLayer('mapbox/outdoors-v11'),
      Oscuro: buildMapboxTileLayer('mapbox/dark-v10'),
      Claro: buildMapboxTileLayer('mapbox/light-v10'),
      Satelite: buildMapboxTileLayer('mapbox/satellite-v9'),
      'Satelite + Calles': buildMapboxTileLayer('mapbox/satellite-streets-v11'),
    };
    baseMaps.Calles.addTo(map);
    L.control.layers(baseMaps).addTo(map);
  };

  setupEditor = (map) => {
    map.pm.addControls({
      position: 'topleft',
      drawMarker: false,
      drawCircleMarker: false,
      drawPolyline: false,
      cutPolygon: false,
      drawCircle: false,
      drawRectangle: !this.state.layer,
      drawPolygon: !this.state.layer,
    });
    map.pm.setLang('es');
  };

  setupCreatedLayer = (layer) => {
    this.setState({ layer }, () => {
      this.props.onChange(this.transformLayerToJson(layer));
      this.setupLayerEvents(layer);
    });

    this.setupEditor(this.map);
  };

  setupEvents = (map) => {
    map.on('pm:create', (e) => {
      this.setupCreatedLayer(e.layer);
    });

    map.on('pm:remove', (e) => {
      this.setState({ layer: undefined }, () => this.setupEditor(map));
      this.props.onChange(undefined);
    });

    ['pm:drawstart', 'pm:drawend'].forEach((event) => {
      map.on(event, (e) => {
        this.setGlobalEdit(event === 'pm:drawstart');
      });
    });

    ['pm:globaleditmodetoggled', 'pm:globaldragmodetoggled', 'pm:globalremovalmodetoggled'].forEach((event) => {
      map.on(event, (e) => {
        this.setGlobalEdit(e.enabled);
      });
    });
  };

  setGlobalEdit = (value) => {
    this.props.onToggleEdit(value);
  };

  setupLayerEvents = (layer) => {
    layer.on('pm:edit', (e) => {
      this.props.onChange(this.transformLayerToJson(e.sourceTarget));
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.state.layer && nextProps.input.value) {
      const layer = L.geoJSON(nextProps.input.value);
      layer.addTo(this.map);
      this.setupCreatedLayer(layer);
      this.map.setView(layer.getBounds().getCenter());
    }
  }

  transformLayerToJson(layer) {
    const json = layer.toGeoJSON(14);

    if (layer instanceof L.Circle) {
      json.properties.radius = layer.getRadius();
    }

    return json;
  }

  render() {
    return (
      <React.Fragment>
        <div id="geofence-editor"></div>
      </React.Fragment>
    );
  }
}
