// Database with extensions, mime-types and descriptions
const database = [
  {
    extension: 'zip',
    type: ['application/x-compressed', 'application/x-zip-compressed', 'application/zip', 'multipart/x-zip'],
    description: 'Archivo comprimido (zip)',
    icon: 'zip',
  },
  {
    extension: 'rar',
    type: 'application/x-rar-compressed',
    description: 'Archivo comprimido (rar)',
    icon: 'unknown',
  },
  {
    extension: 'xls',
    type: 'application/vnd.ms-excel',
    description: 'Planilla Excel (xls)',
    icon: 'xls',
  },
  {
    extension: 'doc',
    type: 'application/msword',
    description: 'Documento Word (doc)',
    icon: 'doc',
  },
  {
    extension: 'xlsx',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    description: 'Planilla Excel (xlsx)',
    icon: 'xlsx',
  },
  {
    extension: 'docx',
    type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    description: 'Documento Word (docx)',
    icon: 'doc',
  },
  {
    extension: 'ppt',
    type: 'application/vnd.ms-powerpoint',
    description: 'Planilla PowerPoint (ppt)',
    icon: 'ppt',
  },
  {
    extension: 'pptx',
    type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    description: 'Planilla PowerPoint (pptx)',
    icon: 'ppt',
  },
  {
    extension: 'pdf',
    type: 'application/pdf',
    description: 'Documento PDF',
    icon: 'pdf',
  },
  {
    extension: 'svg',
    type: 'image/svg+xml',
    description: 'Imagen vectorial (SVG)',
    icon: 'svg',
  },
  {
    extension: ['jpg', 'jpeg'],
    type: ['image/jpeg', 'image/pjpeg'],
    description: 'Imagen (JPG)',
    icon: 'jpg',
  },
  {
    extension: 'gif',
    type: 'image/gif',
    description: 'Imagen (GIF)',
    icon: 'unknown',
  },
  {
    extension: 'png',
    type: 'image/png',
    description: 'Imagen (PNG)',
    icon: 'png',
  },
  {
    extension: 'csv',
    type: 'text/csv',
    description: 'Archivo CSV',
    icon: 'csv',
  },
  {
    extension: 'txt',
    type: 'text/plain',
    description: 'Archivo de texto (txt)',
    icon: 'txt',
  },
];

export const fileInfoFor = function (mimetype) {
  const data = database.find((entry) =>
    Array.isArray(entry.type) ? entry.type.includes(mimetype) : entry.type === mimetype,
  );

  return data || { description: 'Desconocido', icon: 'unknown' };
};
