import classes from '@core/utils/classes';
import debounce from '@core/utils/debounce';
import { FontAwesome, Spinner } from '@shared';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Button, Dropdown, DropdownItem, DropdownMenu, Input, InputGroup, InputGroupAddon, Media } from 'reactstrap';
import { Subject } from 'rxjs';

class RemoteSelector extends Component {
  // PROPERTIES

  static propTypes = {
    // Remote properties
    remoteFinder: PropTypes.func.isRequired,
    remoteLoader: PropTypes.func.isRequired,
    // Bindings
    resourceKey: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    input: PropTypes.object,
    meta: PropTypes.object,
    // Input behavior
    typeDelay: PropTypes.number,
    minLength: PropTypes.number,
    readonly: PropTypes.bool,
    // Templates
    selectedTemplate: PropTypes.func,
    dropdownTemplate: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
    // Custom properties to be passed
    inputProps: PropTypes.object,
    dropdownProps: PropTypes.object,
    dropdownMenuProps: PropTypes.object,
    dropdownItemProps: PropTypes.object,
  };

  static defaultProps = {
    resourceKey: 'id',
    input: {},
    meta: {},
    inputProps: {},
    typeDelay: 500,
    minLength: 3,
    readonly: false,
    dropdownProps: {},
    dropdownMenuProps: {},
    dropdownItemProps: {},
  };

  // INSTANCE VARIABLES

  state = {
    currentInputValue: '',
    showDropdown: false,
    currentDropdownPosition: undefined,
    querying: false,
    failedQuery: false,
    resourceList: [
      { id: 1, name: 'Prueba 1' },
      { id: 2, name: 'Prueba 2' },
      { id: 3, name: 'Prueba 3' },
      { id: 4, name: 'Prueba 4' },
      { id: 5, name: 'Prueba 5' },
      { id: 6, name: 'Prueba 6' },
    ],
    currentResourceKey: undefined,
    currentResource: undefined,
    loading: false,
  };

  queryStream$ = new Subject();
  subscription = undefined;
  searchInput = undefined;
  wrapper = undefined;

  // LIFECYCLE
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      ...this.state,
      currentResourceKey: value,
    };

    this.searchInput = React.createRef();
  }

  componentDidMount() {
    // 0. Add listener to clicks outside
    document.addEventListener('mousedown', this.handleBlur);

    // 1. Mount subscription from text input stream
    const { typeDelay, minLenght } = this.props;

    this.inputStream$ = debounce(this.queryStream$, {
      typeDelay,
      minLenght,
    });

    this.subscription = this.inputStream$.subscribe(this.findResources);

    // 2. When applies, load information
    const { value } = this.props;

    this.loadResource(value);
  }

  componentWillUpdate({ value }, { currentResource: newResource }) {
    const { currentResource, currentResourceKey } = this.state;

    if (!currentResource && newResource)
      // It's already done
      return;

    // Change is made from the outside
    if (value === currentResourceKey) return;

    this.setState({
      currentResourceKey: value,
    });

    this.loadResource(value);
  }

  componentWillUnmount() {
    // Remove listener to clicks outside
    document.removeEventListener('mousedown', this.handleBlur);

    // Subscription to the inputStream$ must be cleared.
    this.subscription && this.subscription.unsubscribe();
  }

  // USER INPUTS HANDLING

  handleInput = (evt) => {
    const { minLenght } = this.props;

    // Not key input? Then handle the input
    const {
      target: { value },
    } = evt;

    this.queryStream$.next(value);

    this.setState({
      currentInputValue: value,
      showDropdown: !!(value && value.length >= 1),
    });

    if (value.length < minLenght)
      this.setState({
        resourceList: [],
      });
    else
      this.setState({
        querying: true,
      });
  };

  handleFocus = (evt) => {
    const {
      input: { onFocus },
    } = this.props;
    onFocus && onFocus(evt);
  };

  handleBlur = (evt) => {
    evt.stopPropagation();
    // We're clicking inside
    if (this.wrapper && this.wrapper.contains(evt.target)) return;

    // We're clicking outside
    const { currentResourceKey } = this.state;
    const {
      input: { onBlur },
    } = this.props;

    onBlur && onBlur(currentResourceKey);
  };

  handleKeyDown = (evt) => {
    const { currentDropdownPosition: position, resourceList: options, showDropdown } = this.state;

    if (options.length === 0 || !showDropdown) return false;

    if (evt.keyCode === 38) {
      // Arrow up
      evt.preventDefault();

      if (position === undefined)
        this.setState({
          currentDropdownPosition: options.length - 1,
        });
      else if (position > 0)
        this.setState({
          currentDropdownPosition: position - 1,
        });
    } else if (evt.keyCode === 40) {
      // Arrow down
      evt.preventDefault();

      if (position === undefined)
        this.setState({
          currentDropdownPosition: 0,
        });
      else if (position < options.length - 1)
        this.setState({
          currentDropdownPosition: position + 1,
        });
    } else if (evt.keyCode === 13) {
      // Enter
      evt.preventDefault();

      if (position === undefined) return;

      this.selectDropdownOption(position);
    }
  };

  // DROPDOWN AND INPUT EVENTS

  toggleDropdown = (key) => {
    this.setState({
      showDropdown: !this.state.showDropdown,
      currentInputValue: '',
    });
  };

  selectDropdownOption = (index) => {
    const { resourceKey } = this.props;
    const { resourceList } = this.state;

    if (process.env.NODE_ENV === 'development') console.log('Selected index:', index);

    this.setState({
      currentInputValue: '',
      currentDropdownPosition: undefined,
      showDropdown: false,
      currentResourceKey: resourceList[index] && resourceList[index][resourceKey],
      currentResource: resourceList[index],
    });

    const {
      input: { onChange },
    } = this.props;
    const newKey = (resourceList[index] && resourceList[index][resourceKey]) || '';

    onChange && onChange(newKey, resourceList[index] || {});
  };

  clearSelection = () => {
    this.setState({
      currentResource: undefined,
      currentResourceKey: undefined,
      resourceList: [],
      currentInputValue: '',
    });

    this.searchInput && this.searchInput.current && this.searchInput.current.focus();

    const {
      input: { onChange },
    } = this.props;

    onChange && onChange('', {});
  };

  // REMOTE CALLBACKS

  findResources = async (query) => {
    if (!query) return;

    const { currentDropdownPosition, resourceList } = this.state;

    this.setState({
      querying: true,
      resourceList: [],
    });

    const { remoteFinder } = this.props;
    const { body, status } = await remoteFinder(query);

    if (String(status).startsWith('2'))
      this.setState({
        resourceList: body,
        currentDropdownPosition: currentDropdownPosition >= resourceList.length ? undefined : currentDropdownPosition,
      });

    this.setState({
      querying: false,
    });
  };

  loadResource = async (resourceKey) => {
    if (!resourceKey) {
      this.setState({
        currentResource: undefined,
      });

      return;
    }

    this.setState({
      loading: true,
    });

    const { remoteLoader } = this.props;
    const { body, status } = await remoteLoader(resourceKey);

    if (String(status).startsWith('2')) {
      this.setState({
        currentResource: body,
        loading: false,
      });

      const {
        input: { onChange },
      } = this.props;
      onChange && onChange(resourceKey, body);
    }
  };

  // THE TEMPLATE

  render() {
    const {
      currentDropdownPosition,
      currentInputValue,
      currentResource,
      currentResourceKey,
      querying,
      resourceList,
      showDropdown,
      loading,
    } = this.state;
    const {
      disabledDropdown,
      dropdownTemplate: DropdownTemplate,
      minLength,
      readonly,
      inputProps: { placeholder, className, getRef, ...inputProps },
      dropdownProps,
      dropdownMenuProps: { style: dropdownMenuStyle, ...dropdownMenuProps },
      dropdownItemProps: { onClick: dropdownItemOnClick, className: dropdownItemClassName, ...dropdownItemProps },
      meta,
    } = this.props;
    const { selectedTemplate: SelectedTemplate = DropdownTemplate } = this.props;

    if (loading)
      return (
        <div className="text-muted">
          <span className="text-info mr-2">
            <Spinner />
          </span>{' '}
          Cargando. Por favor espere.
        </div>
      );

    if (currentResourceKey && currentResource) {
      const selectedClasses = classes({
        'align-items-center': true,
        'text-success': meta && meta.valid,
        'text-danger': meta && meta.invalid,
        'text-warning': meta && meta.warning,
      });

      return (
        <Media className={selectedClasses}>
          <Media body>
            <SelectedTemplate resource={currentResource} />
          </Media>
          {!readonly && (
            <Media right className="ml-2">
              {meta.valid && <FontAwesome name="check" className="mr-2 text-success" />}
              <span role="button" className="text-danger" onClick={() => this.clearSelection()}>
                <FontAwesome name="times" className="text-danger" />
              </span>
            </Media>
          )}
        </Media>
      );
    }

    return (
      <div ref={(ref) => (this.wrapper = ref)}>
        <Dropdown {...dropdownProps} isOpen={showDropdown} toggle={this.toggleDropdown}>
          <InputGroup>
            <Input
              {...inputProps}
              type="text"
              value={currentInputValue}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleInput}
              onFocus={this.handleFocus}
              placeholder={placeholder || 'Comenzar a escribir para buscar...'}
              disabled={readonly}
              innerRef={this.searchInput}
            />
            <InputGroupAddon addonType="append">
              <Button color="secondary" disabled>
                <FontAwesome name="search" />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <DropdownMenu {...dropdownMenuProps} style={{ ...dropdownMenuStyle, minWidth: '100%' }}>
            {currentInputValue.length < minLength && (
              <DropdownItem header>
                Escribe {minLength - currentInputValue.length} o más caracteres para comenzar a buscar
              </DropdownItem>
            )}
            {currentInputValue.length >= minLength && (
              <div>
                {querying && (
                  <DropdownItem disabled>
                    <span className="text-info mr-2">
                      <Spinner />
                    </span>{' '}
                    Buscando...
                  </DropdownItem>
                )}

                {!querying && resourceList.length === 0 && (
                  <DropdownItem disabled>No se encontraron resultados.</DropdownItem>
                )}

                {!querying &&
                  resourceList.length > 0 &&
                  resourceList.map((resource, index) => (
                    <DropdownItem
                      disabled={!!disabledDropdown ? disabledDropdown(resource) : false}
                      {...dropdownItemProps}
                      role="button"
                      key={index}
                      className={
                        currentDropdownPosition === index
                          ? `${dropdownItemClassName} bg-primary text-white`
                          : dropdownItemClassName
                      }
                      onClick={(e) => {
                        dropdownItemOnClick && dropdownItemOnClick(e);
                        this.selectDropdownOption(index);
                      }}
                    >
                      <DropdownTemplate resource={resource} />
                    </DropdownItem>
                  ))}
              </div>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

export default RemoteSelector;
