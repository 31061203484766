import { FontAwesome } from '@shared/';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';
import { EventItemModal } from './EventItem';

export const EventMovement = ({ movement }) => (
  <Media className="align-middle small">
    {movement && movement.asset && (
      <Media body>
        {movement.asset.item && movement.asset.item.name && (
          <div className="w-100 text-overflow mt-1">
            <FontAwesome name="th-large" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{movement.asset.item.name}</span>
          </div>
        )}

        <NavLink to={`/building/assets/${movement.asset.barcode1}`}>
          <FontAwesome name="barcode" color="info" className="mr-1" />
          <kbd>{movement.asset.barcode1}</kbd>
        </NavLink>
        {movement.assetRepair && (
          <div className="w-100 text-overflow mt-1">
            <span className="text-muted mr-1">
              <FontAwesome name="file-alt" color="info" className="mr-1" />
              No. Orden:
            </span>
            {movement.assetRepair.code ? <span>{movement.assetRepair.code}</span> : <span>Sin información</span>}
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventMovementModal = ({ movement }) => (
  <div>
    {movement && movement.asset && movement.asset.item && movement.asset.item.name && (
      <EventItemModal item={movement.asset.item} />
    )}
    <Media>
      {movement && movement.asset && (
        <Media body>
          {movement.asset.mark && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="tag" color="info" className="mr-1" />
                Rótulo:
              </span>
              <code>{movement.asset.mark}</code>
            </div>
          )}
          <NavLink to={`/building/assets/${movement.asset.barcode1}`}>
            <FontAwesome name="barcode" color="info" className="mr-1" />
            <span className="text-muted mr-1">Activo:</span>
            <kbd>{movement.asset.barcode1}</kbd>
          </NavLink>
        </Media>
      )}
    </Media>
  </div>
);
