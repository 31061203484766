import { createDeviceKey, deleteDeviceKey, resetSecret } from '@core/admin/device-keys';
import { getDeviceKeys } from '@core/admin/devices';
import { Spinner, FontAwesome } from '@shared';
import { DeviceKeyModal, DeviceKeyRow } from '@shared/device-keys';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';

class DeviceKeysPresentation extends Component {
  state = {
    notFound: false,
    loading: true,
    modal: false,
    secret: '',
    currentDeviceKey: undefined,
    deviceKeys: [],
    displayConfirmBox: true,
  };

  async componentDidMount() {
    const { device } = this.props;
    const barcode = device.barcode1;

    if (!barcode) {
      this.notFound();
      return;
    }

    const { body: deviceKeys, status } = await getDeviceKeys(barcode);

    if (status === 404) {
      this.notFound();
    } else if (status === 200) {
      this.setState({
        loading: false,
        deviceKeys,
      });
    }
  }

  notFound = () => {
    this.setState({ notFound: true });

    this.props.notFound();
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
      secret: '',
      currentDeviceKey: undefined,
    });
  };

  generateKey = async (id) => {
    const { deviceKeys } = this.state;
    const { body: deviceKey, status } = await createDeviceKey({ deviceId: id });

    if (status === 201) {
      this.setState({
        modal: true,
        secret: deviceKey.apiSecret,
        deviceKeys: [deviceKey, ...deviceKeys],
        currentDeviceKey: deviceKey,
      });
    }
  };

  resetSecret = async (deviceKey) => {
    const { body: newDeviceKey, status } = await resetSecret(deviceKey);

    if (status === 200) {
      this.setState({
        modal: true,
        secret: newDeviceKey.apiSecret,
        currentDeviceKey: newDeviceKey,
      });
    }
  };

  deleteKey = async (index) => {
    const deviceKey = this.state.deviceKeys[index];
    const { deviceKeys } = this.state;
    const { status } = await deleteDeviceKey(deviceKey.id);

    if (status === 204) {
      this.props.successDeleteNotification();

      this.setState({
        deviceKeys: [...deviceKeys.slice(0, index), ...deviceKeys.slice(index + 1)],
      });
    }
  };

  toogleConfirmBox = () => {
    this.setState({
      displayConfirmBox: !this.state.displayConfirmBox,
    });
  };

  render() {
    const { device } = this.props;
    const { currentDeviceKey, deviceKeys, notFound, modal, secret, displayConfirmBox } = this.state;

    if (notFound) return <Redirect to="/organization/items" />;

    return (
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <h5>Claves de acceso</h5>

          <Button role="button" size="sm" color="success" onClick={() => this.generateKey(device.id)}>
            Nuevo API Key <FontAwesome name="plus" />
          </Button>
        </div>

        <Row>
          <Col sm={12} md={12} className="mb-4">
            {!deviceKeys && (
              <div className="text-center h1 text-info">
                <Spinner />
              </div>
            )}

            {deviceKeys && (
              <Table>
                <colgroup>
                  <col style={{ width: 'auto' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '200px' }} />
                </colgroup>
                <thead>
                  <tr>
                    <th className="text-left">API Key</th>
                    <th>Información</th>
                    <th className="text-center" />
                  </tr>
                </thead>
                <tbody>
                  {deviceKeys.map((deviceKey, index) => (
                    <DeviceKeyRow
                      key={index}
                      deviceKey={deviceKey}
                      resetSecret={this.resetSecret}
                      deleteKey={() => this.deleteKey(index)}
                      toogleConfirmBox={this.toogleConfirmBox}
                      displayConfirmBox={displayConfirmBox}
                    />
                  ))}
                  {deviceKeys.length === 0 && (
                    <tr>
                      <td colSpan="4" className="text-center mt-3 mb-3">
                        <em>No se encontraron códigos.</em>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>

        <DeviceKeyModal currentDeviceKey={currentDeviceKey} modal={modal} secret={secret} toggle={this.toggle} />
      </div>
    );
  }
}

export const DeviceKeys = connect(null, (dispatch) => ({
  successDeleteNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Clave eliminada',
        text: `La clave de acceso fue eliminada exitosamente.`,
        color: 'success',
      }),
    ),
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo no encontrado',
        text:
          'El dispositivo buscado no existe o no se encuentra disponible. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(DeviceKeysPresentation);
