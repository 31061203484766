import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const getAssetPreservations = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_preservations${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAssetPreservation = (assetPreservationId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_preservations/${assetPreservationId}`,
      method: 'GET',
    }),
  );

export const getAssetPreservationStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_preservations/stats`,
      method: 'GET',
    }),
  );
