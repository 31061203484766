import React from 'react';
import { SimpleFilterModal, NO_OPTIONS, LOADING_MESSAGE } from '@shared/simple-filter/SimpleFilter';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { getBuildings } from '@core/admin/buildings';
import { getUsers } from '@core/admin/users';
import { getAssets } from '@core/building/assets';
import { getItems } from '@core/building/items';

export class EventsMROFilterModal extends SimpleFilterModal {
  findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return body.map(({ firstName, lastName, id }) => ({
        label: `${firstName} ${lastName}`,
        value: id,
      }));
    }
    return [];
  };

  findBuildings = async (inputValue) => {
    const { body, status } = await getBuildings({ search: inputValue });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ item: { name }, barcode1: barcode, id: value }) => ({
        label: `${name} - ${barcode}`,
        value,
      }));
    }

    return [];
  };

  findItems = async (inputValue) => {
    const { body, status } = await getItems({
      search: inputValue,
      consumable: false,
      serializer: 'simple',
    });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="users[{}]">Usuario</Label>
              <AsyncSelect
                name="users[{}]"
                value={this.state['users[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="assets">Activo</Label>
              <AsyncSelect
                name="assets[{}]"
                value={this.state['assets[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="item">Artículos</Label>
              <AsyncSelect
                name="items[{}]"
                value={this.state['items[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findItems}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
