import React from 'react';
import { getEventsMRO } from '@core/event/events';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { Card, CardBody, Col, Button, Row, Container } from 'reactstrap';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { EventsResults } from 'events/components/EventsResults';
import { EventsMROFilterModal } from './components/EventsMROFilterModal';

export class EventsIndex extends SimpleFilter {
  searchFunction = getEventsMRO;

  render() {
    const { items, pagination, modalOpen, filters } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Row>
              <Col>
                <div className="content-actions float-right">
                  <Button size="sm" color="secondary" onClick={this.toggle}>
                    <FontAwesome name="filter" /> Filtros
                  </Button>
                </div>
                <h4 className="mb-4">
                  <FontAwesome name="calendar-alt" className="mr-2 text-primary" />
                  Últimos Eventos MRO
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!items && (
                      <div className="text-center h1 text-info">
                        <Spinner />
                      </div>
                    )}
                    {items && <EventsResults events={items} pagination={pagination} />}
                    {(!items || items.length > 0) && paginationTemplate}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <EventsMROFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </Container>
    );
  }
}
