import { apiFetch, handleAuth, withToken } from './http';

export const updatePicture = ({ id, ...body }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/pictures/${id}`,
      method: 'PUT',
      body,
    }),
  );

export const deletePicture = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/pictures/${id}`,
      method: 'DELETE',
    }),
  );
