import { getAsset } from '@core/building/assets';
import { getItem } from '@core/building/items';
import { validAssetBarcode, validItemBarcode } from '@core/utils/barcodes';
import classes from '@core/utils/classes';
import { FontAwesome, Spinner } from '@shared';
import {
  ASSET_HEALTH_STATES_FOR_MANUAL_MOVEMENTS_CREATION,
  ASSET_STATES_FOR_MOVEMENTS_CREATION,
} from '@shared/constants';
import { AssetSelector, ItemSelector } from '@shared/forms';
import { connect, deskSessionGenerators, notificationsGenerators } from '@store';
import React from 'react';
import { Button, Card, CardBody, Input, InputGroup, InputGroupAddon, Nav, NavItem, NavLink } from 'reactstrap';
import { compose, lifecycle, withHandlers, withState } from 'recompose';

const enhance = compose(
  connect(
    ({ deskSession: { currentTarget, movements: currentMovements } }) => ({
      currentMovements,
      currentTarget,
    }),
    (dispatch) => ({
      clearTarget: () => dispatch(deskSessionGenerators.clearTarget()),
      onSuccess: (type, id) => dispatch(deskSessionGenerators.setCurrentTarget(type, id)),
      duplicateNotification: () =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Duplicado',
            text: `Este activo ya se encuentra en la lista de movimientos de esta sesión.`,
            color: 'danger',
            duration: 5000,
          }),
        ),
    }),
  ),
  withState('inputType', 'setInputType', 'asset'),
  withState('scannerText', 'setScannerText', ''),
  withState('scannerState', 'setScannerState', 'waiting'),
  withHandlers({
    onSuccess: ({ clearTarget, currentMovements, duplicateNotification, onSuccess }) => (type, data) => {
      if (!data) {
        clearTarget();
        return;
      }

      // Do not allow assets already inserted
      if (type === 'asset' && currentMovements.find((movement) => movement.asset && movement.asset.barcode1 === data)) {
        clearTarget();
        duplicateNotification();
        return;
      }

      onSuccess && onSuccess(type, data);
    },
  }),
  withHandlers({
    handleSelection: ({ onSuccess }) => (type, value) => onSuccess && onSuccess(type, value),
    handleScannerInput: ({ onSuccess, setScannerState, setScannerText }) => async ({ target: { value } }) => {
      setScannerText(value);
      setScannerState('waiting');

      let queryingFunction;

      if (validAssetBarcode(value)) queryingFunction = getAsset;
      if (validItemBarcode(value)) queryingFunction = getItem;

      if (queryingFunction) {
        setScannerState('querying');

        const { body, status } = await queryingFunction(value);

        if (status === 404) {
          setScannerText('');
          setScannerState('failed');
        } else if (status === 200) {
          setScannerState('success');

          onSuccess && onSuccess(queryingFunction === getAsset ? 'asset' : 'item', body.barcode1);
        }
      }
    },
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps({ currentTarget: nextTarget }) {
      const { currentTarget } = this.props;

      if (!!currentTarget && !nextTarget) {
        const { setScannerState } = this.props;
        setScannerState('waiting');
      }
    },
  }),
);

const TargetInputPresentation = ({
  currentTarget,
  handleScannerInput,
  inputType,
  onSuccess,
  scannerText,
  scannerState,
  setInputType,
  setScannerState,
  setScannerText,
}) => (
  <Card>
    <CardBody>
      <Nav pills className="nav-justified mb-3">
        <NavItem>
          <NavLink
            role="button"
            active={((currentTarget && currentTarget.type) || inputType) === 'asset'}
            disabled={!!currentTarget}
            onClick={() => setInputType('asset')}
          >
            <FontAwesome name="wrench" /> Activo
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            role="button"
            active={((currentTarget && currentTarget.type) || inputType) === 'item'}
            disabled={!!currentTarget}
            onClick={() => setInputType('item')}
          >
            <FontAwesome name="th-large" /> Artículo
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            role="button"
            active={((currentTarget && currentTarget.type) || inputType) === 'scan'}
            disabled={!!currentTarget}
            onClick={() => {
              setInputType('scan');
              setScannerText('');
              setScannerState('waiting');
            }}
          >
            <FontAwesome name="qrcode" /> Escanear
          </NavLink>
        </NavItem>
      </Nav>
      <div className="mb-1">
        {inputType === 'asset' && (
          <AssetSelector
            name="targetInput"
            inputProps={{ size: 'lg' }}
            filters={{
              active: true,
              states: ASSET_STATES_FOR_MOVEMENTS_CREATION,
              health_states: ASSET_HEALTH_STATES_FOR_MANUAL_MOVEMENTS_CREATION,
            }}
            input={{
              onChange: (barcode) => onSuccess && onSuccess('asset', barcode),
            }}
            readonly={!!currentTarget}
          />
        )}

        {inputType === 'item' && (
          <ItemSelector
            name="targetInput"
            filters={{ consumable: true, active: true, serializer: 'index' }}
            input={{
              onChange: (barcode) => onSuccess && onSuccess('item', barcode),
            }}
            inputProps={{
              size: 'lg',
            }}
            building={true}
            readonly={!!currentTarget}
          />
        )}

        {inputType === 'scan' && (
          <InputGroup
            className={classes({
              'has-success': scannerState === 'success',
              'has-danger': scannerState === 'failed',
            })}
          >
            <Input
              type="text"
              name="targetInput"
              size="lg"
              placeholder={scannerState === 'failed' ? 'Activo o artículo no encontrado' : 'Escanear código QR'}
              value={scannerText}
              onChange={handleScannerInput}
              disabled={scannerState === 'querying' || !!currentTarget}
            />
            <InputGroupAddon addonType="append">
              <Button color="secondary" className="disabled">
                {scannerState === 'waiting' && <FontAwesome name="qrcode" />}
                {scannerState === 'querying' && <Spinner className="text-info" />}
                {scannerState === 'success' && <FontAwesome name="check" />}
                {scannerState === 'failed' && <FontAwesome name="times" />}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        )}
      </div>
    </CardBody>
  </Card>
);

export const TargetInput = enhance(TargetInputPresentation);
