export const buildGeofencePayload = (data, organizationId) => {
  return {
    id: data.id,
    organizationId,
    name: data.name,
    geometry: data.feature.geometry,
    properties: {
      ...data.feature.properties,
      color: data.color,
    },
  };
};
