import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { AssetRow } from './AssetRow';

export const AssetsResults = ({ assets, pagination }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'activo encontrado', 'activos encontrados')}.
      Mostrando <strong>{pagination.per}</strong> activos por página.
    </div>

    <Table>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Identificador</th>
          <th>Artículo</th>
          <th className="text-center">Disponibilidad</th>
          <th className="text-center">Reparable</th>
          <th className="text-center">Readouts</th>
          <th className="text-center">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {assets.map((asset, index) => (
          <AssetRow key={index} asset={asset} />
        ))}

        {assets.length === 0 && (
          <tr>
            <td colSpan="4" className="text-center mt-3 mb-3">
              <em>No se encontraron activos.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
