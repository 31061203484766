import React from 'react';
import { compose, withState, withHandlers } from 'recompose';

const enhance = compose(
  withState('actualWidth', 'setWidth', ({ size }) => size),
  withState('actualHeight', 'setHeight', ({ size }) => size),
  withHandlers({
    processImage: ({ setHeight, setWidth }) => ({ target }) => {
      setWidth(target.width);
      setHeight(target.height);
    },
  }),
);

const RoundedImage = ({ src, alt, processImage, actualWidth, actualHeight, size = '4em' }) => (
  <span
    style={{
      display: 'inline-block',
      width: size,
      height: size,
      overflow: 'hidden',
      borderRadius: '50%',
    }}
  >
    <img
      src={src}
      alt={alt}
      onLoad={processImage}
      style={{
        height: actualWidth > actualHeight ? '100%' : 'auto',
        width: actualWidth > actualHeight ? 'auto' : '100%',
      }}
    />
  </span>
);

export default enhance(RoundedImage);
