import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { TelemetryRow } from './TelemetryRow';

export const TelemetriesResult = ({ telemetries, pagination }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'lectura encontrada', 'lecturas encontradas')}.
      Mostrando <strong>{pagination.per}</strong> lecturas por página.
    </div>

    <Table>
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '25%' }} />
      </colgroup>
      <thead>
        <tr>
          <th className="text-center">Activo</th>
          <th className="text-center">Origen</th>
          <th className="text-right">Fecha Lectura</th>
          <th className="text-left">Datos</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {telemetries.map((telemetry, index) => (
          <TelemetryRow telemetry={telemetry} />
        ))}

        {telemetries.length === 0 && (
          <tr>
            <td colSpan="4" className="text-center mt-3 mb-3">
              <em>No se encontraron lecturas.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
