import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getAreas = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/areas${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getArea = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/areas/${id}`,
      method: 'GET',
    }),
  );

export const createArea = ({ ...area }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/areas`,
      method: 'POST',
      body: {
        area,
      },
    }),
  );

export const editArea = ({ id, ...area }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/areas/${id}`,
      method: 'PUT',
      body: {
        area,
      },
    }),
  );

export const deleteArea = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/areas/${id}`,
      method: 'DELETE',
    }),
  );

// only admin users
export const getAreasByBuilding = (buildingId, query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/areas/target/${buildingId}${mountQuery(query)}`,
      method: 'GET',
    }),
  );

// only admin users
export const getAreaByBuilding = (buildingId, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/areas/target/${buildingId}/${id}`,
      method: 'GET',
    }),
  );
