import React from 'react';
import { Alert } from 'reactstrap';

import { ErrorList } from '@shared';

const Form = ({ error, compact, noValidate = true, children, ...otherProps }) => (
  <form noValidate={noValidate} {...otherProps}>
    {compact && error && (
      <Alert color="danger">
        {Array.isArray(error) && error.length > 1 ? (
          <ul>
            {error.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          error
        )}
      </Alert>
    )}
    {!compact && error && <ErrorList errors={error} />}
    {children}
  </form>
);

export default Form;
