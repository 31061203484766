import { FontAwesome } from '@shared/index';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const TaskConfirmMROModal = ({ isOpen, startMro, closeConfirmMROModal, task }) => (
  <Modal isOpen={isOpen} toggle={closeConfirmMROModal} size="md">
    <ModalHeader>Confirmación</ModalHeader>
    <ModalBody>
      <p>
        ¿Estás seguro de que deseas iniciar un proceso MRO a partir de la tarea <code>{task?.title}</code> para el
        activo?
      </p>
      <p>Esta acción no es reversible</p>
      <p>
        Activo:{' '}
        <NavLink target="_blank" to={`/building/assets/${task?.asset?.barcode1}`}>
          <strong>{task?.asset?.item?.name}</strong> (<code className="small">{task?.asset?.barcode1}</code>)
        </NavLink>
      </p>
    </ModalBody>
    <ModalFooter>
      <div className="text-right">
        <Button role="button" onClick={closeConfirmMROModal}>
          Cancelar
        </Button>{' '}
        <Button role="button" color="primary" onClick={startMro}>
          <FontAwesome name="heartbeat" /> Iniciar MRO
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);

export default TaskConfirmMROModal;
