import { getSimCards } from '@core/admin/simcards';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { SimCardFilterModal } from './components/SimCardFilterModal';
import { SimCardsResults } from './components/SimCardsResults';

export class SimCardsIndex extends SimpleFilter {
  searchFunction = getSimCards;

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Row>
              <Col>
                <div className="content-actions float-right">
                  <Button size="sm" color="secondary" onClick={this.toggle}>
                    <FontAwesome name="filter" /> Filtros
                  </Button>
                </div>
                <h4 className="mb-4">
                  <FontAwesome name="phone" className="text-primary mr-2" />
                  Gestión de SIM Cards
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!items && (
                      <div className="text-center h1 text-info">
                        <Spinner />
                      </div>
                    )}
                    {items && <SimCardsResults simcards={items} pagination={pagination} />}
                    {(!items || items.length > 0) && paginationTemplate}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <SimCardFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </Container>
    );
  }
}
