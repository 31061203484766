import React from 'react';
import { buildingDownload } from '@core/http.js';
import { getPickUps } from '@core/building/pick-ups';
import { ExcelDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { PickUpsResults } from './components/PickUpResults';
import { PickUpsFilterModal } from './components/PickUpsFilterModal';
import { NavLink } from 'react-router-dom';

export class PickUpsIndex extends SimpleFilter {
  constructor(props) {
    super(props);
    this.state = {
      ...super.state,
      processing: undefined,
    };
  }
  searchFunction = getPickUps;

  render() {
    const { items, pagination, filters, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                <ExcelDownload
                  file="pick_ups"
                  filters={{ ...filters }}
                  downloadFile={buildingDownload}
                  disabled={!items}
                  csv={false}
                />
              </ShowForRoles>
              <ShowForRoles excluded={['guest']}>
                <Button tag={NavLink} size="sm" outline color="success" to="/building/pick-ups/new">
                  <FontAwesome name="plus" /> Crear PickUp
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="mobile-alt" className="mr-2 text-primary" /> Gestión de Pick-Ups
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <PickUpsResults pickUps={items} pagination={pagination} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <PickUpsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
