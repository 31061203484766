import { FontAwesome } from '@shared/';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';
import { EventItemModal } from './EventItem';

export const EventAsset = ({ asset }) => (
  <Media className="align-middle small">
    {asset && (
      <Media body>
        {asset.item && asset.item.name && (
          <div className="w-100 text-overflow mt-1">
            <FontAwesome name="th-large" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{asset.item.name}</span>
          </div>
        )}
        <NavLink to={`/building/assets/${asset.barcode1}`}>
          <FontAwesome name="barcode" color="info" className="mr-1" />
          <kbd>{asset.barcode1}</kbd>
        </NavLink>
        {asset.mark && (
          <div className="w-100 text-overflow mt-1">
            <span className="text-muted mr-1">
              <FontAwesome name="tag" color="info" className="mr-1" />
              Rótulo:
            </span>
            <code>{asset.mark}</code>
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventAssetModal = ({ asset }) => (
  <div>
    {asset && asset.item && asset.item.name && <EventItemModal item={asset.item} />}
    <Media>
      {asset && (
        <Media body>
          {asset.mark && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="tag" color="info" className="mr-1" />
                Rótulo:
              </span>
              <code>{asset.mark}</code>
            </div>
          )}
          <NavLink to={`/building/assets/${asset.barcode1}`}>
            <FontAwesome name="barcode" color="info" className="mr-1" />
            <span className="text-muted mr-1">Activo:</span>
            <kbd>{asset.barcode1}</kbd>
          </NavLink>
        </Media>
      )}
    </Media>
  </div>
);
