import { handleAuth, withMembership, apiFetch, mountQuery } from '../http';

export const getTelemetries = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/telemetries${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getTelemetry = (assetId, page = 1) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/telemetries/${assetId}?p=${page}}`,
      method: 'GET',
    }),
  );
