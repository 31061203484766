import React from 'react';
import { FontAwesome } from '@shared';
import { fromIso8601 } from '@core/utils/dates';
import useragent from 'useragent';
import { Button } from 'reactstrap';

export const SessionItem = ({ token, onRevoke }) => {
  const { ip, client } = token.request;

  const ua = useragent.parse(client);

  return (
    <li className="list-group-item">
      <div className="float-left" data-toggle="tooltip" title="Desktop">
        <FontAwesome name={'desktop'} className="mr-1" />
      </div>
      <div className="description float-left">
        <div>
          <strong>{ip}</strong>
        </div>
        <div>Última actividad el {fromIso8601(token.lastActivity)}</div>
        <div>
          <strong>{ua.family}</strong> en <strong>{ua.os.family}</strong>
        </div>
        <div>
          <strong>Logueado</strong> el {fromIso8601(token.createdAt)}
        </div>
      </div>
      <div className="float-right">
        <Button color="danger" onClick={() => onRevoke(token)}>
          Revocar
        </Button>
      </div>
    </li>
  );
};
