import { connect, deskSessionGenerators } from '@store';
import { branch, compose, renderComponent } from 'recompose';
import { DeskLogin } from './DeskLogin';
import { DeskSession } from './DeskSession';

const enhance = compose(
  connect(
    ({ deskSession: { currentClient } }) => ({ currentClient }),
    (dispatch) => ({
      startSession(client) {
        dispatch(deskSessionGenerators.start(client));
      },
      finishSession() {
        dispatch(deskSessionGenerators.finish());
      },
    }),
  ),
  branch(({ currentClient }) => !!currentClient, renderComponent(DeskSession)),
);

export const Desk = enhance(DeskLogin);
