import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getBuildings = (query) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      endpoint: `/buildings${mountQuery({ ...query, active: true })}`,
      method: 'GET',
    }),
  );

export const getBuildingProviders = (query) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      endpoint: `/buildings/building_providers${mountQuery({ ...query, active: true })}`,
      method: 'GET',
    }),
  );

export const getBuildingsOrganization = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/buildings_organization${mountQuery({ ...query, active: true })}`,
      method: 'GET',
    }),
  );

export const getBuilding = (barcode) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      endpoint: `/buildings/${barcode}`,
      method: 'GET',
    }),
  );
