import { FontAwesome } from '@shared';
import React, { Component } from 'react';
import { Button, Col, Row, FormGroup, ListGroupItem } from 'reactstrap';
import { SubtaskForm } from './SubtaskForm';
import './SubtaskListItem.scss';

export class SubtaskListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
    };
  }

  cancelEditMode = () => {
    this.setState({ editMode: false });
  };

  activateEditMode = () => {
    this.setState({ editMode: true });
  };

  saveSubtask = (subtask) => {
    this.setState({ editMode: false });
    this.props.handleSave(subtask, this.props.index);
  };

  removeSubtask = () => {
    this.props.handleDelete(this.props.index);
  };

  render() {
    const { index, subtask } = this.props;
    const { editMode } = this.state;

    return (
      <ListGroupItem key={index} className={'subtask-list-item'}>
        {!editMode && (
          <Row className={'d-flex align-items-center'}>
            <Col xs={7}>
              <FontAwesome
                name={subtask.completedAt ? 'check-circle' : ['far', 'circle']}
                color={subtask.completedAt ? 'success' : 'primary'}
                className="align-middle"
              />{' '}
              <span>{subtask.title}</span>
            </Col>
            <Col xs={3}>
              <span>{subtask.estimatedHours} HH</span>
            </Col>
            <Col className={'subtask-actions'}>
              <Button
                className={'edit-subtask-btn'}
                type="button"
                role="button"
                size="sm"
                color="primary"
                onClick={this.activateEditMode}
                sm={1}
                disabled={subtask.completedAt}
              >
                <FontAwesome name="pencil-alt" />
              </Button>
              <Button
                className={'delete-subtask-btn'}
                type="button"
                role="button"
                size="sm"
                color="danger"
                onClick={this.removeSubtask}
                sm={1}
                disabled={subtask.completedAt}
              >
                <FontAwesome name="times" />
              </Button>
            </Col>
          </Row>
        )}
        {editMode && (
          <FormGroup className={'subtask-edit'}>
            <SubtaskForm
              onSubmit={this.saveSubtask}
              handleCancel={this.cancelEditMode}
              initialValues={{ ...subtask }}
            />
          </FormGroup>
        )}
      </ListGroupItem>
    );
  }
}
