import { getUsers } from '@core/building/users';
import { SimpleFilterModal, LOADING_MESSAGE, NO_OPTIONS } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { compose } from 'recompose';
import { connect } from '@store';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import ChileanRUT from '@core/utils/chilean-rut';
import { get } from 'lodash';
import { getDevices } from '@core/building/devices';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { DateSelector } from '@shared/forms';
import { getPickUps } from '@core/building/pick-ups';
import { getItems } from '@core/building/items';
import { getAssets } from '@core/building/assets';

class PickUpsFilterPresentation extends SimpleFilterModal {
  findUsers = async (inputValue) => {
    const { body, status } = await getUsers({ search: inputValue });
    if (status === 200) {
      return body.map(({ firstName, lastName, identifier, id: value }) => ({
        label: `${firstName} ${lastName} - ${
          identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
        }`,
        value: value,
      }));
    }
    return [];
  };

  findDevices = async (inputValue) => {
    const { body, status } = await getDevices({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findPickUps = async (inputValue) => {
    const { body, status } = await getPickUps({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ requestNumber: label, requestNumber: value }) => ({ label, value }));
    }

    return [];
  };

  findItems = async (inputValue) => {
    const { body, status } = await getItems({ name: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ item: { name }, id: value, mark }) => ({
        label: `${name} - ${value} - ${mark}`,
        value,
      }));
    }

    return [];
  };

  renderForm() {
    const { pickUpStates } = this.props;

    return (
      <Form>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="request_numbers">Número de requerimiento</Label>
              <AsyncSelect
                name="request_numbers"
                value={this.state['request_numbers']}
                isClearable
                isSearchable
                placeholder="Buscar..."
                loadOptions={this.findPickUps}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="states[{}]">Estados</Label>
              <Select
                name="states[{}]"
                value={this.state['states[{}]']}
                isClearable
                isSearchable
                isMulti
                closeMenuOnSelect={false}
                placeholder="Todos"
                options={pickUpStates}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="devices[{}]">Dispositivos</Label>
              <AsyncSelect
                name="devices[{}]"
                value={get(this.state, 'devices[{}]')}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findDevices}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="users[{}]">Clientes</Label>
              <AsyncSelect
                name="users[{}]"
                value={get(this.state, 'users[{}]')}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="date_period{}">Fecha Inicial</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'date_period{}.started_at')}
                maxDate={new Date()}
                onChange={(date) =>
                  this.setState({
                    'date_period{}': {
                      ...get(this.state, 'date_period{}', {}),
                      started_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="date_period{}">Fecha Final</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'date_period{}.ended_at')}
                maxDate={new Date()}
                onChange={(date) =>
                  this.setState({
                    'date_period{}': {
                      ...get(this.state, 'date_period{}', {}),
                      ended_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="items[{}]">Artículos</Label>
              <AsyncSelect
                name="items[{}]"
                value={this.state['items[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findItems}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="assets[{}]">Activos</Label>
              <AsyncSelect
                name="assets[{}]"
                value={this.state['assets[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

const enhance = compose(
  connect(({ session }) => {
    let organizationId = undefined;

    if (session.currentMembership && session.user) {
      const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

      if (membership) organizationId = membership.building.organization.id;
    }

    return { currentOrganizationId: organizationId };
  }),
  connect(({ appData: { pick_up_states: pickUpStates } }) => ({
    pickUpStates: Object.keys(pickUpStates).map((state) => ({
      label: pickUpStates[state].name,
      value: state,
    })),
  })),
);

export const PickUpsFilterModal = enhance(PickUpsFilterPresentation);
