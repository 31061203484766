import { apiFetch, withMembership, handleAuth, mountQuery, loadFile } from '../http';

export const getCategories = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/categories${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getCategory = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/categories/${id}`,
      method: 'GET',
    }),
  );

export const createCategory = (category) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/categories',
      method: 'POST',
      body: { category },
    }),
  );

export const editCategory = ({ id, ...category }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/categories/${id}`,
      method: 'PUT',
      body: { category },
    }),
  );

export const deleteCategory = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/categories/${id}`,
      method: 'DELETE',
    }),
  );

export const massImportCategories = (file) =>
  handleAuth(
    withMembership(loadFile)({
      endpoint: `/categories/mass`,
      method: 'POST',
      name: 'file',
      files: file,
      parseResponse: false,
    }),
  );
