import { createMembership, destroyMembership } from '@core/admin/memberships';
import { getUser } from '@core/admin/users';
import { Loading } from '@shared';
import { MembershipsManager } from '@shared/users';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';
import { UserEditorLayout } from './components/UserEditorLayout';

const Manager = MembershipsManager(createMembership, destroyMembership);

const enhance = compose(
  withState('state', 'setState', 'loading'),
  withState('user', 'setUser', undefined),
  lifecycle({
    async componentDidMount() {
      const { notFound, setState, setUser } = this.props;
      const { id } = this.props.match.params;

      if (!id) {
        notFound();
        return;
      }

      const { body, status } = await getUser(id);

      if (status === 200) {
        setUser(body);
        setState('ok');
      } else if (status === 404) {
        setState('notFound');
      }
    },
  }),
  branch(
    ({ state }) => state === 'notFound',
    renderComponent(() => <Redirect to="/admin/users" />),
  ),
  branch(({ state }) => state === 'loading', renderComponent(Loading)),
);

const MembershipsPresentation = ({ user }) => (
  <UserEditorLayout user={user}>
    <Manager user={user} />
  </UserEditorLayout>
);

export const Memberships = enhance(MembershipsPresentation);
