import { fullName } from '@core/account';
import { getMovements } from '@core/building/movements';
import { simpleFilterQuery, withLoader } from '@shared';
import { MovementsTable } from '@shared/movements';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compose } from 'recompose';

const enhance = compose(
  withLoader('movements', ({ user: { id } }) =>
    getMovements({
      generator_type: 'Core::User',
      generators: id,
      pp: 10,
    }),
  ),
);

const MovementsPresentation = ({ movements, user }) => <MovementsTable movements={movements} showInfo={false} />;

const Movements = enhance(MovementsPresentation);

export const Summary = ({ user }) => (
  <div>
    <div className="mb-4 h5">Últimos movimientos</div>
    <Movements user={user} />
    <div className="mt-4 text-center">
      <Button
        role="button"
        tag={NavLink}
        to={`/movements?q=${simpleFilterQuery({
          generator_type: 'Core::User',
          'generators[{}]': [
            {
              label: fullName(user),
              value: user.id,
            },
          ],
        })}`}
      >
        Ver todos
      </Button>
    </div>
  </div>
);
