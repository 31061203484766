import { getBatches } from '@core/admin/batches';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { BatchesResults } from './components/BatchesResults';

export class BatchesIndex extends SimpleFilter {
  searchFunction = getBatches;

  render() {
    const { items, pagination } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Row>
              <Col>
                <div className="content-actions float-right">
                  {/* <Button size="sm" color="secondary" onClick={this.toggle}>
                    <FontAwesome name="filter" /> Filtros
                  </Button> */}
                  <Button tag={NavLink} size="sm" outline color="success" to="/admin/batches/new">
                    <FontAwesome name="plus" /> Crear Lote
                  </Button>
                </div>
                <h4 className="mb-4">
                  <FontAwesome name="archive" className="text-primary mr-2" />
                  Gestión de Lotes
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!items && (
                      <div className="text-center h1 text-info">
                        <Spinner />
                      </div>
                    )}

                    {items && <BatchesResults batches={items} pagination={pagination} />}

                    {(!items || items.length > 0) && paginationTemplate}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}
