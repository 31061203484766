import { Field, Form } from '@core/forms';
import Validates, { numericality, presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { OrganizationSelector } from '@shared/forms';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'buildingId' }),
  presence({ of: 'deviceTypeId' }),
  presence({ of: 'quantity' }),
  numericality({ of: 'quantity', greaterThan: 0, lessThanOrEqualTo: 500 }),
);

const enhance = compose(
  reduxForm({
    form: 'batch-form',
    validate,
    initialValues: {
      deviceTypeId: '2',
    },
  }),
);

const BatchFormPresentation = ({ handleSubmit, submitting, pristine, invalid }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Row>
      <Col>
        <Field
          label="Organización"
          name="organizationId"
          component={OrganizationSelector}
          help="Organización a la que pertenece este lote."
          required
        />
      </Col>
      <Col>
        <Field
          name="deviceTypeId"
          label="Tipo de etiquetas"
          help="De momento, solo un tipo de etiquetas está permitido por lote."
          type="select"
          options={{ 2: 'RFID' }}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name="quantity"
          label="Cantidad de etiquetas"
          type="number"
          help={
            <span>
              <strong>Importante:</strong> Este valor no es editable, por lo que debe establecerse con certeza.
            </span>
          }
        />
      </Col>
      <Col />
    </Row>

    <hr />

    <div className="text-center">
      <ProcessingButton
        role="button"
        type="submit"
        color="primary"
        outline
        width="10em"
        disabled={pristine || invalid}
        processing={submitting}
      >
        Guardar
      </ProcessingButton>
    </div>
  </Form>
);

export const BatchForm = enhance(BatchFormPresentation);
