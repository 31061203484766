import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getUserGroup = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${id}`,
      method: 'GET',
    }),
  );

export const getUserGroups = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createUserGroup = (userGroup) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups`,
      method: 'POST',
      body: {
        userGroup,
      },
    }),
  );

export const updateUserGroup = (id, userGroup) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${id}`,
      method: 'PUT',
      body: {
        userGroup,
      },
    }),
  );

export const deleteUserGroup = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/user_groups/${id}`,
      method: 'DELETE',
    }),
  );
