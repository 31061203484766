import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { ItemSelector } from '@shared/forms';
import { get } from 'lodash';
import React from 'react';
import { connect } from '@store';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'barcode' }),
  presence({ of: 'targetBuilding' }),
  presence({ of: 'quantity' }),
);

const enhance = compose(
  reduxForm({
    form: 'transferItemForm',
    validate,
  }),
  connect(({ session }) => ({ session }) => {
    const { memberships } = session.user;
    const options = { '': 'Seleccione...' };
    const membership = session.user?.memberships.find((membership) => membership.id === session.currentMembership);
    Object.keys(memberships).forEach((index) => {
      if (
        memberships[index].id !== session.currentMembership &&
        memberships[index].role === 'organization_admin' &&
        memberships[index].building.organization.id === membership.building.organization.id
      ) {
        const buildingName = memberships[index].building.name;
        const buildingId = memberships[index].building.id;
        options[buildingId] = buildingName;
      }
    });
    return {
      buildingsAvailable: options,
    };
  }),
  connect(({ form }) => {
    return {
      buildingId: get(form, 'transferItemForm.values.targetBuilding', false),
    };
  }),
);

const TransferItemFormPresentation = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  buildingsAvailable,
  // organizationsAvailable,
  // buildingId,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <h4 className="mb-4">Transferencia de artículos consumibles entre instalaciones</h4>
    <Row>
      <Col>
        <Field
          name="barcode"
          label="Artículo"
          component={ItemSelector}
          filters={{ consumable: true, active: true }}
          help="Artículo que se va a transferir a otra instalación. Debe tener cantidad disponible."
          required
        />
      </Col>
      <Col>
        <Field
          name="quantity"
          label="Cantidad"
          type="text"
          help="Cantidad de articulos que serán transferidos a la instalación de destino"
          required
        />
      </Col>
    </Row>
    <Row>
      {/* <Col>
        <Field
          name="targetOrganization"
          label="Organización de destino"
          type="select"
          options={organizationsAvailable}
          help="Organización de la instalación a la que se transferirán los consumibles"
          required
        />
      </Col> */}
      <Col>
        <Field
          name="targetBuilding"
          label="Instalación destino"
          type="select"
          options={buildingsAvailable}
          help="Instalación a la que se transferirán los consumibles"
          required
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          name="comment"
          label="Comentarios"
          rows="6"
          type="textarea"
          help="Cualquier comentario que pueda aportar antecedentes adicionales respecto al movimiento realizado."
        />
      </Col>
    </Row>
    <div className="text-center">
      <ProcessingButton
        role="button"
        type="submit"
        color="primary"
        outline
        width="10em"
        disabled={pristine || invalid}
        processing={submitting}
      >
        Transferir
      </ProcessingButton>
    </div>
  </Form>
);

export const TransferItemForm = enhance(TransferItemFormPresentation);
