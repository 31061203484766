import format from './format';

// Taken from:
// http://emailregex.com/
const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ message, ...props }) =>
  format({
    ...props,
    with: emailRegex,
    message: message || 'No es una dirección de correo válida',
  });
