import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';

import { PictureUploader } from '@shared/forms';

const PictureModal = ({ showModal, toggle, onChange }) => (
  <Modal isOpen={showModal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Cargar imagen</ModalHeader>
    <ModalBody className="text-center">
      <PictureUploader
        postResource="/pictures"
        endpoint={(token) => `/pictures/${token}`}
        multiple={false}
        onChange={onChange}
      />
    </ModalBody>
    <ModalFooter>
      <Button role="button" onClick={toggle}>
        Cancelar
      </Button>
    </ModalFooter>
  </Modal>
);

export default PictureModal;
