import { searchInventory } from '@core/building/inventory';
import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import { connect } from '@store';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { get, reduce } from 'lodash';
import React, { Component, Fragment } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import XLSX from 'xlsx';
import { Header } from './components/compare/Header';

class InventoryCompare extends Component {
  state = {
    file: undefined,
    processing: false,
    data: [],
  };

  setFileHandler = (e) => {
    const file = e && e.target && e.target.files && e.target.files.length > 0 && e.target.files[0];

    if (!file) {
      this.setState({
        data: [],
      });
      return;
    }

    this.setState({
      processing: true,
      file,
      data: [],
    });

    this.parseXLSX(file);
  };

  mergeInventoryData = (items, sapData) => {
    const reduced = reduce(
      items,
      (result, item, key) => {
        let sku = item.internal_sku.trim();

        if (sku === '') {
          sku = `__${item.id}`;
        }

        const exist = result[sku];

        const total = item.inventory.total;
        if (exist) {
          result[sku].quantity_alliot = total;
          result[sku].assets = item.assets;
        } else {
          result[sku] = {
            sku,
            name: item.name,
            quantity: '-',
            quantity_alliot: total,
            assets: item.assets,
          };
        }
        return result;
      },
      sapData,
    );

    return reduced;
  };

  readFile = async (e, rABS) => {
    /* Parse data */
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws);

    /* Update state */
    const sapData = this.reduceSapData(data);

    const { body: items, status } = await searchInventory({ paginate: false });

    let merged = {};

    if (status === 200) {
      merged = this.mergeInventoryData(items, sapData);
    } else {
      // FIX: Show error
      return;
    }

    this.setState({
      data: Object.values(merged).filter((i) => i.sku !== ''),
      processing: false,
    });
  };

  reduceSapData = (data) => {
    const length = data.length - 1;
    const reduced = reduce(
      data,
      (result, value, key) => {
        if (key < length) {
          const mat = value['Material'].trim();
          const exist = result[mat];
          if (exist) {
            result[mat].quantity = result[mat].quantity + parseInt(value['Unrestricted'], 10);
          } else {
            result[mat] = {
              sku: mat,
              name: value['Material Description'],
              quantity: parseInt(value['Unrestricted'], 10),
              quantity_alliot: '-',
            };
          }
        }
        return result;
      },
      {},
    );

    return reduced;
  };

  parseXLSX = (file) => {
    // var workbook = XLSX.read(file, { type: 'string' })
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => this.readFile(e, rABS);

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  downloadExcel = async () => {
    const fillRow = (sheet, row) => {
      ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'].forEach((i) => {
        const cell = sheet.getCell(`${i}${row}`);
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'E9ECEF' },
        };
      });
    };

    const addItemHeaderRow = (sheet) => {
      let rowValues = [];
      rowValues[1] = 'SKU';
      rowValues[2] = 'ARTICULO';
      rowValues[6] = 'SAP';
      rowValues[7] = 'ALLIOT';
      rowValues[8] = 'DIFERENCIA';
      sheet.addRow(rowValues);
      sheet.mergeCells(`B${sheet.rowCount}:E${sheet.rowCount}`);
      fillRow(sheet, sheet.rowCount);
    };

    const addItemRow = (sheet, item) => {
      let rowValues = [];
      rowValues = [];
      rowValues[1] = item.sku;
      rowValues[2] = item.name;
      rowValues[6] = item.quantity;
      rowValues[7] = item.quantity_alliot;
      rowValues[8] = Math.abs(getProperValue(item.quantity) - getProperValue(item.quantity_alliot));
      sheet.addRow(rowValues);
      sheet.mergeCells(`B${sheet.rowCount}:E${sheet.rowCount}`);
    };

    const addAssetHeaderRow = (sheet) => {
      sheet.addRow(['Barcode', 'Estado', 'E. Salud', 'Origen', 'Latitud', 'Longitud', 'Batería', 'Fecha']);
      fillRow(sheet, sheet.rowCount);
    };

    const addAssetRow = (sheet, asset) => {
      const readout = asset.last_position || {};
      const origen = get(readout, 'metadata.origin', '-');
      const tags = asset.tags || [];
      const tag = tags.find((t) => t.identifier === origen);
      sheet.addRow([
        asset.barcode1,
        get(this.props.assetStates, `${asset.state}.name`, '?'),
        get(this.props.assetHealthStates, `${asset.health_state}.name`, '?'),
        origen,
        get(readout, 'metadata.lat', '-'),
        get(readout, 'metadata.lng', '-'),
        get(tag, 'metadata.battery.level', '-'),
        readout.read_at ? fromIso8601(readout.read_at) : '-',
      ]);
    };

    var workbook = new Excel.Workbook();
    var sheet = workbook.addWorksheet('Inventario');
    sheet.properties.defaultColWidth = 22.5;

    this.state.data.slice(0, 300).forEach((item) => {
      addItemHeaderRow(sheet);
      addItemRow(sheet, item);
      addAssetHeaderRow(sheet);

      (item.assets || []).forEach((asset) => {
        addAssetRow(sheet, asset);
      });

      sheet.addRow([]);
      sheet.mergeCells(`A${sheet.rowCount}:H${sheet.rowCount}`);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });
    saveAs(blob, 'inventario' + fileExtension);
  };

  buildMasterTable = (data) => {
    return (
      <table id="sheetjs" className="table table-sm table-bordered text-center">
        <tbody>{data.map((item) => this.buildHTMLTable(item))}</tbody>
      </table>
    );
  };

  buildHTMLTable = (item) => {
    return (
      <Fragment key={item.id}>
        <tr className="thead-light">
          <th scope="col">SKU</th>
          <th scope="col" colSpan="4">
            ARTICULO
          </th>
          <th scope="col">SAP</th>
          <th scope="col">ALLIOT</th>
          <th scope="col">DIFERENCIA</th>
        </tr>
        <tr>
          <th>{item.sku}</th>
          <th colSpan="4" className="text-left">
            {item.name}
          </th>
          <th>{item.quantity}</th>
          <th>{item.quantity_alliot}</th>
          <th>{Math.abs(getProperValue(item.quantity) - getProperValue(item.quantity_alliot))}</th>
        </tr>
        <tr className="thead-light">
          <th colSpan="8">ACTIVOS</th>
        </tr>
        <tr className="thead-light">
          <th width="auto">Barcode</th>
          <th width="12%">Estado</th>
          <th width="12%">E. Salud</th>
          <th width="12%">Origen</th>
          <th width="12%">Latitud</th>
          <th width="12%">Longitud</th>
          <th width="12%">Batería</th>
          <th width="12%">Fecha</th>
        </tr>
        {item.assets &&
          item.assets.map((asset) => {
            const readout = asset.last_position || {};
            const origen = get(readout, 'metadata.origin', '-');
            const tags = asset.tags || [];
            const tag = tags.find((t) => t.identifier === origen);
            return (
              <tr>
                <th>{asset.barcode1}</th>
                <th>{get(this.props.assetStates, `${asset.state}.name`, '?')}</th>
                <th>{get(this.props.assetHealthStates, `${asset.health_state}.name`, '?')}</th>
                <th>{origen}</th>
                <th>{get(readout, 'metadata.lat', '-')}</th>
                <th>{get(readout, 'metadata.lng', '-')}</th>
                <th>{get(tag, 'metadata.battery.level', '-')}</th>
                <th>{readout.read_at ? fromIso8601(readout.read_at) : '-'}</th>
              </tr>
            );
          })}
        <tr>
          <td colSpan="8">&nbsp;</td>
        </tr>
      </Fragment>
    );
  };

  render() {
    const { file, processing, data } = this.state;

    return (
      <div className="container-fluid mt-4 mb-4">
        <Header title="Comparador de Inventario" file={file} processing={processing} setFile={this.setFileHandler} />
        {data.length > 0 && (
          <Row className="justify-content-center mt-4">
            <Col xs={12} lg={10}>
              <Card>
                <CardHeader>
                  Previsualización{' '}
                  <Button role="button" color="success" size="sm" className="float-right" onClick={this.downloadExcel}>
                    <span>
                      <FontAwesome name="table" /> Descargar
                    </span>
                  </Button>
                </CardHeader>
                <CardBody>{this.buildMasterTable(data)}</CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ session, appData }) => {
  return {
    currentBuilding: session.user.memberships.find((m) => m.id === session.currentMembership).building,
    user: session.user,
    assetStates: appData.asset_states,
    assetHealthStates: appData.asset_health_states,
  };
};

export default connect(mapStateToProps)(InventoryCompare);

const getProperValue = (value) => (value === '-' ? 0 : parseInt(value, 10));
