import React from 'react';
import { Observable, Subscription } from 'rxjs';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Toggle from 'react-toggle';

import { CHART_REFRESH_INTERVAL } from '../../../@config';
import { connect } from '../../../@store';
import { getInventory } from '../../../@core/building/reports';
import { Highcharts } from '../../../@shared/charts';

import Chart from './Chart';

type State = {
  realtime: boolean,
  data: Array<any[]>,
  total: number,
};

const getChartOptions = (title, total, data) => {
  return {
    title: {
      text: title,
    },
    credits: {
      enabled: true,
      href: 'http://www.alliot.cloud',
      text: 'alliot.cloud',
    },
    subtitle: {
      text: `Total: ${total || 0} activos`,
    },
    tooltip: {
      shared: true,
      pointFormat: '{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data,
        keys: ['name', 'y'],
        type: 'pie',
      },
    ],
  };
};

class Inventory extends React.Component<*, State> {
  subscription: Subscription;

  constructor(props) {
    super(props);

    this.state = {
      realtime: false,
      data: [],
      total: 0,
    };
  }

  async componentDidMount() {
    const response = await getInventory();

    this.handleResponse(response);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleResponse = (response: Response) => {
    const { status, body } = response;

    if (status === 200) {
      const { data, total } = this.mapData(body || {}, this.props.computedStates);

      this.setState({
        data,
        total,
      });
    }
  };

  mapData = (responseData: Object = {}, computedStates: Object) => {
    let data = [];
    let total = 0;

    Object.keys(responseData).forEach((key: string) => {
      let qty = parseInt(responseData[key], 10);
      if (qty > 0) {
        let cleanedKey = key.replace('_quantity', '');
        data.push([computedStates[cleanedKey] ? computedStates[cleanedKey].name : key, qty]);
        total += qty;
      }
    });

    return { data, total };
  };

  subscribe = () => {
    this.subscription = Observable.timer(0, CHART_REFRESH_INTERVAL)
      .switchMap(getInventory)
      .filter((response) => response.status === 200)
      .subscribe((response: Response) => {
        this.handleResponse(response);
      });
  };

  unsubscribe = () => {
    if (this.state.realtime) {
      this.subscription.unsubscribe();
    }
  };

  toggleRealtime = (e: any) => {
    const { checked } = e.target;

    if (checked) {
      this.subscribe();
    } else {
      this.unsubscribe();
    }

    this.setState({
      realtime: checked,
    });
  };

  render() {
    const { title } = this.props;
    const { realtime, data, total } = this.state;

    return (
      <Card>
        <CardHeader className="chart-title-container">
          <span className="float-right">
            <span
              style={{
                display: 'flex',
                verticalAlign: 'middle',
              }}
            >
              <span className="mr-2">Mostrar en tiempo real</span>
              <Toggle checked={realtime} onChange={this.toggleRealtime} value="yes" />
            </span>
          </span>
          <h4>Inventario</h4>
        </CardHeader>
        <CardBody className="p-2">
          <Chart options={getChartOptions(title, total, data)} highcharts={Highcharts} />
        </CardBody>
      </Card>
    );
  }
}

export default connect(({ appData: { computed_states: computedStates } }) => ({
  computedStates,
}))(Inventory);
