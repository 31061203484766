import { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { SubscriptionToggle } from '../../../@shared/pages/building-subscriptions';

export const AssetSubscriptionButton = ({ assetId }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  return (
    <>
      <Button color="primary" onClick={toggle}>
        Notificaciones
      </Button>
      <Modal isOpen={open}>
        <ModalHeader>Notificaciones</ModalHeader>
        <ModalBody className="space-y-3">
          {/* <div>
            <h5>Inventario</h5>
            <div className="space-y-1">
              <SubscriptionToggle
                disabled={true}
                topic={`asset/${assetId}/inventory-movements`}
                title="Movimientos de Inventario"
                description="Información en tiempo real de eventos de inventario"
              />
              <SubscriptionToggle
                disabled={true}
                topic={`asset/${assetId}/mro-movements`}
                title="Movimientos de MRO"
                description="Información en tiempo real de eventos de MRO"
              />
            </div>
          </div> */}
          <div>
            <h5>Tracking</h5>
            <div className="space-y-1">
              <SubscriptionToggle
                topic={`asset/${assetId}/low-battery`}
                title="Bateria baja"
                description="Información de eventos de batería"
              />
              <SubscriptionToggle
                topic={`asset/${assetId}/geofence-in`}
                title="Entradas a Geocercas"
                description="Información en tiempo real de eventos de entrada a geocerca"
              />
              <SubscriptionToggle
                topic={`asset/${assetId}/geofence-out`}
                title="Salidas de Geocercas"
                description="Información en tiempo real de eventos de salida de geocerca"
              />
              <SubscriptionToggle
                topic={`asset/${assetId}/validity-period`}
                title="Vigencias"
                description="Información en tiempo real de vencimiento de vigencias"
              />
              {/* <SubscriptionToggle
                topic={`asset/${assetId}/tamper`}
                title="Tamper"
                description="Información en tiempo real de eventos de tampering"
              /> */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
