import React, { Component } from 'react';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import { editTask, getTask } from '@core/building/tasks';
import { Loading } from '@shared';
import { Redirect } from 'react-router-dom';
import { SubmissionError } from '@core/forms';
import { TaskForm } from './components/TaskForm';
import { toUTC } from '@core/utils/dates';
import { TaskSubmitModal } from './components/TaskSubmitModal';

class TaskEditPresentation extends Component {
  state = {
    loading: true,
    edited: false,
    task: undefined,
    notFound: false,
    openConfirmSubmit: false,
    estimatedHours: null,
    data: {},
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) this.notFound();

    const { body: task, status } = await getTask(id);

    this.setState({ loading: false, task });

    if (status === 404) {
      this.notFound();
    } else if (status === 200) {
      this.setState({ task });
    }
  }

  notFound = () => {
    this.props.notFoundNotification();
    this.setState({ notFound: true });
  };

  editTask = async (data) => {
    console.log(data);
    const previousEditData = {
      ...data,
      startDate: toUTC(data.startDate),
      dueDate: toUTC(data.dueDate),
      code: data?.asset?.repairable ? data.code : null,
      integration: data?.asset?.repairable && data.integration,
    };
    console.log('FIN', previousEditData);

    if (this.state.estimatedHours) previousEditData['estimatedHours'] = this.state.estimatedHours;

    const { members, ...editData } = previousEditData;
    editData['subtasks'] = editData?.subtasks?.reverse();
    console.log('EDIT_DATA', editData);
    const { body, status } = await editTask(editData);

    if (status === 200) {
      this.props.successNotification();
      this.setState({ edited: true });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  checkHumanHours = (data) => {
    console.log(data);
    if (data?.subtasks.length > 0) {
      this.setState({ estimatedHours: null });
      this.editTask(data);
    } else {
      this.setState({ data, openConfirmSubmit: true });
    }
  };

  closeConfirmSubmit = () => {
    this.setState({ openConfirmSubmit: false });
  };

  toogleConfirmSubmit = () => {
    this.setState({ openConfirmSubmit: !this.state.openConfirmSubmit });
  };

  setEstimatedHours = (value) => {
    this.setState({ estimatedHours: value?.target?.value });
  };

  render() {
    const { task, edited, loading, notFound, openConfirmSubmit, estimatedHours, data } = this.state;

    if (notFound || edited) return <Redirect to="/organization/tasks" />;

    if (loading) return <Loading />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Editar Tarea">
          <TaskForm
            onSubmit={this.checkHumanHours}
            initialValues={{
              ...task,
              memberIds: (task.members && task.members.map((member) => member.id)) || [],
              members: task.members || [],
              subtasks: task.subtasks || [],
              assetBarcode: task.asset && task.asset.barcode1,
              buildingId: task.building && task.building.id,
            }}
            action="edit"
          />
        </SimpleContainerLayout>
        <TaskSubmitModal
          openConfirmSubmit={openConfirmSubmit}
          toogleConfirmSubmit={this.toogleConfirmSubmit}
          setEstimatedHours={this.setEstimatedHours}
          closeConfirmSubmit={this.closeConfirmSubmit}
          createTask={() => this.editTask(data)}
          estimatedHours={estimatedHours}
        />
      </BuildingLayout>
    );
  }
}

export const TaskEdit = connect(null, (dispatch) => ({
  successNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Tarea editada',
        text: 'La tarea ha sido editado exitosamente.',
        color: 'success',
      }),
    ),
  notFoundNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No encontrado',
        text:
          'No se encontró la tarea buscada o no se encuentra disponible. Si los problemas persisten, por favor contacte a un administrador.',
        color: 'danger',
      }),
    ),
}))(TaskEditPresentation);
