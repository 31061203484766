import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import React from 'react';
import { AreasIndex } from './areas/AreasIndex';
import { EditArea } from './areas/EditArea';
import { NewArea } from './areas/NewArea';
import { AssetPage } from './assets/AssetPage';
import { AssetsIndex } from './assets/AssetsIndex';
import { NewAsset } from './assets/NewAsset';
import { TransferOwnership } from './assets/TransferOwnership';
import { DevicePage } from './devices/DevicePage';
import { DevicesIndex } from './devices/DevicesIndex';
import { KitPage } from './kits/KitPage';
import { KitsIndex } from './kits/KitsIndex';
import { NewKit } from './kits/NewKit';
import { ReadoutsIndex } from './readouts/ReadoutsIndex';
import { TelemetriesIndex } from './telemetries/TelemetriesIndex';
import Dashboard from './reports/Dashboard';
// import { ReservationsRoutes } from './reservations';
import { Dashboard as TagsDashboard } from './tags/Dashboard';
import { EditTag } from './tags/EditTag';
import { NewTag } from './tags/NewTag';
import { TagsIndex } from './tags/TagsIndex';
import { TagsCreator } from './onboarding/TagsCreator';
import { NewUser } from './users/NewUser';
import { UserPage } from './users/UserPage';
import { UsersIndex } from './users/UsersIndex';
import { EventsIndex } from './events/EventsIndex';
import { InventoryCheckersIndex } from './inventory-checker/InventoryCheckersIndex';
import { InventoryCheckerForm } from './inventory-checker/InventoryCheckerForm';
import { ConfirmInventoryCheckerForm } from './inventory-checker/ConfirmInventoryCheckerForm';
import { InventoryCheckerSetsIndex } from './inventory-checker/InventoryCheckerSetsIndex';
import { InventoryCheckerSetForm } from './inventory-checker/InventoryCheckerSetForm';
import { PickUpsIndex } from './pick-ups/PickUpsIndex';
import { ItemsFromBuildingIndex } from './items/ItemsFromBuildingIndex';
import { DevicesInventory } from './devices/DevicesInventory';
import { BuildingInventorySap } from './inventory-sap/Inventory-sap';
import { BuildingInventory } from './inventory/Inventory';
import InventoryCompare from './inventory/InventoryCompare';
import { ExpectedStocksMassImport } from './expected-stocks/ExpectedStocksMassImport';
import { DeviceExpectedStocksMassImport } from './device-expected-stocks/DeviceExpectedStocksMassImport';
import { UserGroupPage } from './user_groups/UserGroupPage';
import { NewUserGroup } from './user_groups/NewUserGroup';
import { UserGroupsIndex } from './user_groups/UserGroupsIndex';
import { UserGroupEdit } from './user_groups/UserGroupEdit';
import { PartnersIndex } from './partners/PartnertsIndex';
import { NewPartner } from './partners/NewPartner';
import { PartnerPage } from './partners/PartnerPage';
import { PartnerEdit } from './partners/components/PartnerEdit';
import { NewPickUp } from './pick-ups/NewPickUp';
import { NewCostAccount } from './cost-accounts/NewCostAccount';
import { CostAccountEdit } from './cost-accounts/CostAccountEdit';
import { CostAccountsIndex } from './cost-accounts/CostAccountIndex';
import { CostAccountPage } from './cost-accounts/CostAccountPage';

const BuildingRoutes = ({ match }) => (
  <Switch>
    <ProtectedRoute
      exact
      path={`${match.url}/areas`}
      component={AreasIndex}
      acceptedMemberships={['organization_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/areas/new`}
      component={NewArea}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/areas/:id`}
      component={EditArea}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/assets`}
      component={AssetsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/assets/new`}
      component={NewAsset}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/transfer-asset`}
      component={TransferOwnership}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      path={`${match.url}/assets/:barcode`}
      component={AssetPage}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'inspector', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/devices`}
      component={DevicesIndex}
      acceptedMemberships={['organization_admin', 'guest']}
    />
    <ProtectedRoute
      path={`${match.url}/devices/inventory`}
      component={DevicesInventory}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'client', 'guest']}
    />
    <ProtectedRoute
      path={`${match.url}/devices/:barcode`}
      component={DevicePage}
      acceptedMemberships={['organization_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/tags`}
      component={TagsIndex}
      acceptedMemberships={['organization_admin', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/tags/dashboard`}
      component={TagsDashboard}
      acceptedMemberships={['organization_admin', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/tags/new`}
      component={NewTag}
      acceptedMemberships={['organization_admin', 'onboarding']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/tags/:id`}
      component={EditTag}
      acceptedMemberships={['organization_admin', 'onboarding']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/readouts`}
      component={ReadoutsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/telemetries`}
      component={TelemetriesIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/users/new`}
      component={NewUser}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/users`}
      component={UsersIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin']}
    />
    <ProtectedRoute
      path={`${match.url}/users/:id`}
      component={UserPage}
      acceptedMemberships={['organization_admin', 'warehouse_admin']}
    />
    <ProtectedRoute
      path={`${match.url}/dashboard`}
      component={Dashboard}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'inspector', 'dashboard', 'guest']}
    />
    {/* <ProtectedRoute
      path={`${match.url}/reservations`}
      component={ReservationsRoutes}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    /> */}
    <ProtectedRoute
      exact
      path={`${match.url}/kits`}
      component={KitsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/kits/new`}
      component={NewKit}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/kits/:id`}
      component={KitPage}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />
    <ProtectedRoute path={`${match.url}/kits/:id`} component={KitPage} />
    <ProtectedRoute
      exact
      path={`${match.url}/events`}
      component={EventsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/onboarding`}
      component={TagsCreator}
      acceptedMemberships={['onboarding']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory-checkers`}
      component={InventoryCheckersIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory-checkers/new`}
      component={InventoryCheckerForm}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory-checkers/:id`}
      component={ConfirmInventoryCheckerForm}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory-checker-sets`}
      component={InventoryCheckerSetsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory-checker-sets/new`}
      component={InventoryCheckerSetForm}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/pick-ups`}
      component={PickUpsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/pick-ups/new`}
      component={NewPickUp}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/pick-ups/edit`}
      component={PickUpsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/items`}
      component={ItemsFromBuildingIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory-sap`}
      component={BuildingInventorySap}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory`}
      component={BuildingInventory}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'client', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory/compare`}
      component={InventoryCompare}
      acceptedMemberships={['organization_admin', 'warehouse_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/expected_stocks`}
      component={ExpectedStocksMassImport}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/device_expected_stocks`}
      component={DeviceExpectedStocksMassImport}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/user_groups`}
      component={UserGroupsIndex}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/user_groups/new`}
      component={NewUserGroup}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/user_groups/:id`}
      component={UserGroupPage}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/user_groups/:id/edit`}
      component={UserGroupEdit}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/partners`}
      component={PartnersIndex}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/partners/new`}
      component={NewPartner}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/partners/:id`}
      component={PartnerPage}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/partners/:id/edit`}
      component={PartnerEdit}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/cost-accounts`}
      component={CostAccountsIndex}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/cost-accounts/new`}
      component={NewCostAccount}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/cost-accounts/:id`}
      component={CostAccountPage}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/cost-accounts/:id/edit`}
      component={CostAccountEdit}
      acceptedMemberships={['organization_admin']}
    />
  </Switch>
);

export default BuildingRoutes;
