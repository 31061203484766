import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { getOrganizations } from '@core/admin/organizations';

export class OrganizationsFilterModal extends SimpleFilterModal {
  findOrganizations = async (inputValue) => {
    const { body, status } = await getOrganizations({ search: inputValue });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="organizations[{}]">Nombre</Label>
              <AsyncSelect
                name="organizations[{}]"
                value={this.state['organizations[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findOrganizations}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="available">Estado</Label>
              <Select
                name="available"
                value={this.state['available']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Activa', value: true },
                  { label: 'Inactiva', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
