import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import React from 'react';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'name' }));

const CategoryFormPresentation = ({ handleSubmit, invalid, submitting, pristine, error }) => {
  return (
    <Form error={error} noValidate onSubmit={handleSubmit}>
      <Field name="name" type="text" label="Nombre de la categoría" required />
      <Field
        name="description"
        type="textarea"
        rows="4"
        label="Descripción"
        help="Entrega una descripción detallada de los artículos contenidos en esta categoría. Opcional, pero recomendado."
      />

      <hr />

      <div className="text-center">
        <ProcessingButton
          type="submit"
          color="primary"
          outline
          width="10em"
          disabled={pristine || invalid}
          processing={submitting}
        >
          Guardar
        </ProcessingButton>
      </div>
    </Form>
  );
};

export const CategoryForm = reduxForm({
  form: 'categoryForm',
  validate,
})(CategoryFormPresentation);
