import { getAppData, refreshToken, getAppDataVersion } from '@core/login';
import { fromIso8601 } from '@core/utils/dates';
import { appDataGenerators, connect, exceptionsGenerators, notificationsGenerators, sessionGenerators } from '@store';
import React, { Component } from 'react';
import { BuildingSubscriptionModal } from './pages/building-subscriptions';
import ConfirmationPendingModal from './pages/ConfirmationPendingModal';
import MembershipSelectorModal from './pages/MembershipSelectorModal';
import NoConnectionModal from './pages/NoConnectionModal';
import RecoverPasswordModal from './pages/RecoverPasswordModal';

const defaultRefreshInterval = 300000;

class RuntimeManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toLoginPage: false,
    };
  }

  async componentDidMount() {
    this.startedAt = new Date();
    const { body: appDataVersionChecked, status: appDataVersionStatus } = await getAppDataVersion();
    if (String(appDataVersionStatus).startsWith('2') && String(appDataVersionChecked) !== this.props.appDataVersion) {
      process.env.NODE_ENV === 'development' &&
        console.log(
          `[DEBUG] AppData versions out of sync. Locally we had ${this.props.appDataVersion} and server had version ${appDataVersionChecked}. Updating...`,
        );
      const { body, status } = await getAppData();
      if (status === 200) this.props.setAppData(body);
    } else {
      process.env.NODE_ENV === 'development' &&
        console.log(`[DEBUG] AppData versions is sync at version ${this.props.appDataVersion}.`);
    }
  }

  componentDidUpdate(previousProps) {
    if (this.props.exceptions.has401) {
      this.logout();
      this.props.toggle401();
    }

    if (this.props.exceptions.has500) {
      this.props.createNotification({
        title: 'Ocurrió un error',
        text:
          'Hemos detectado un error al procesar la información. Este error ha sido reportado. Por favor, contáctanos si los errores persisten.',
        color: 'danger',
        duration: 10000,
      });

      this.props.toggle500();
    }

    // if (!previousProps.session.loggedIn && this.props.session.loggedIn)
    //   this.sessionTickTimeout = setTimeout(
    //     this.handleSessionTick,
    //     this.props.refreshInterval || defaultRefreshInterval
    //   );
    // else if (previousProps.session.loggedIn && !this.props.session.loggedIn) {
    //   if (this.sessionTickTimeout) clearTimeout(this.sessionTickTimeout);
    // }
  }

  get showConfirmation() {
    return this.props.session.user.status === 'confirmation_pending';
  }

  get showRecoverModal() {
    return this.props.session.user.status === 'password_reset_pending';
  }

  handleSessionTick = async () => {
    const { session } = this.props;

    if (!session.loggedIn) return;

    const sessionTimeLeft = Date.parse(session.expiresAt) - new Date();

    if (sessionTimeLeft > 0) {
      const { body, status } = await refreshToken();

      if (String(status).startsWith('2')) {
        process.env.NODE_ENV === 'development' &&
          console.log(`[DEBUG] Session refreshed successfully at ${fromIso8601()}.`);

        this.props.dispatchLogin(body);

        // Let's look at appData
        if (this.props.session.appDataVersion === this.props.appDataVersion) {
          process.env.NODE_ENV === 'development' &&
            console.log(`[DEBUG] AppData versions in sync at version ${this.props.appDataVersion}.`);
        } else {
          process.env.NODE_ENV === 'development' &&
            console.log(
              `[DEBUG] AppData versions out of sync. Locally we had ${this.props.session.appDataVersion} and server had version ${this.props.appDataVersion}. Updating...`,
            );

          const { body, status } = await getAppData();

          if (status === 200) this.props.setAppData(body);
        }

        this.sessionTickTimeout = setTimeout(
          // this.handleSessionTick,
          this.props.refreshInterval || defaultRefreshInterval,
        );
      } else if (status === 401) this.logout();
    } else this.logout();
  };

  logout = () => {
    this.props.dispatchLogout();

    this.setState({
      toLoginPage: true,
    });
  };

  render() {
    return (
      <div>
        <BuildingSubscriptionModal buildingId={this.props.buildingId} />
        <MembershipSelectorModal />
        <NoConnectionModal opened={this.props.exceptions.internetException} refresh={() => window.location.reload()} />
        <ConfirmationPendingModal opened={this.showConfirmation} email={this.props.session.user.unconfirmedEmail} />
        <RecoverPasswordModal opened={this.showRecoverModal} />
      </div>
    );
  }
}

export default connect(
  ({ session, exceptions, appData: { version: appDataVersion } }) => {
    const membership = session.currentMembership
      ? session.user.memberships.find((m) => m.id === session.currentMembership)
      : null;

    return {
      session,
      exceptions,
      appDataVersion,
      buildingId: membership ? membership.building.id : null,
    };
  },
  (dispatch) => ({
    dispatchLogout: () => dispatch(sessionGenerators.logout()),
    dispatchLogin: (token) => dispatch(sessionGenerators.login(token)),
    toggle401: () => dispatch(exceptionsGenerators.toggle401()),
    toggle500: () => dispatch(exceptionsGenerators.toggle500()),
    createNotification: (props) => dispatch(notificationsGenerators.insert(props)),
    setAppData: (data) => dispatch(appDataGenerators.setAppData(data)),
  }),
)(RuntimeManager);
