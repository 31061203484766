import { createAsset } from '@core/building/assets';
import { getItem } from '@core/building/items';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { AssetForm } from './components/AssetForm';
import { AssetValidityPeriodModal } from './components/AssetValidityPeriodModal';

class NewAssetPresentation extends Component {
  state = {
    redirectTo: undefined,
    asset: undefined,
    modal: false,
  };

  constructor(props) {
    super(props);

    // Parse target from URL
    const {
      location: { search },
    } = this.props;

    const query = new URLSearchParams(search);
    const target = query.get('for');

    this.state = {
      ...this.state,
      initialValues: {
        itemBarcode: target,
      },
    };
  }

  async componentDidMount() {
    console.log(this.state);
    const { body: item, status } = await getItem(this.state.initialValues.itemBarcode);
    if (status === 200) {
      if (item.telemetric) {
        const {
          gForceEventHarmful,
          gForceEventWarning,
          temperatureLevelHarmful,
          temperatureLevelWarning,
          workTripHarmful,
          workTripWarning,
        } = item;
        this.setState({
          telemetryOptions: {
            gForceEventHarmful,
            gForceEventWarning,
            temperatureLevelHarmful,
            temperatureLevelWarning,
            workTripHarmful,
            workTripWarning,
          },
        });
      }
    }
  }

  createAsset = async (asset) => {
    const { body, status } = await createAsset({ ...asset });

    if (status === 201) {
      this.setState({ asset: body });
      this.props.successCreationNotification();
      if (body.repairable) {
        // Open Modal
        this.setState({ modal: true });
        // this.setState({ redirectTo: `/building/assets/${body.barcode1}/validity_periods` });
      } else {
        this.setState({ redirectTo: `/building/assets/${body.barcode1}` });
      }
    } else if (status === 422) {
      this.props.errorNotification(body.errors);
      throw new SubmissionError(body.errors);
    }
  };

  toggleModal = () =>
    this.setState({ modal: !this.state.modal, redirectTo: `/building/assets/${this.state.asset.barcode1}` });

  gotToValidityPeriod = () =>
    this.setState({
      modal: false,
      redirectTo: `/building/assets/${this.state.asset.barcode1}/validity_periods`,
    });

  render() {
    const { initialValues, redirectTo, telemetryOptions, modal, asset } = this.state;
    if (redirectTo) return <Redirect to={redirectTo} />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Nuevo activo">
          <AssetForm onSubmit={this.createAsset} initialValues={{ ...initialValues, ...telemetryOptions }} />
        </SimpleContainerLayout>
        {asset && (
          <AssetValidityPeriodModal
            modal={modal}
            toggle={this.toggleModal}
            gotToValidityPeriod={this.gotToValidityPeriod}
          />
        )}
      </BuildingLayout>
    );
  }
}

export const NewAsset = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Activo creado',
        text: `El activo ha sido creado exitosamente.`,
        color: 'success',
      }),
    ),
  errorNotification: (errors) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error',
        text: errors?.base ? errors?.base.join(', ') : 'Hubo un error al procesar la petición',
        color: 'danger',
      }),
    ),
}))(NewAssetPresentation);
