import { createMovement } from '@core/building/movements';
import { SubmissionError } from '@core/forms';
import { ProcessingButton } from '@shared/index';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter, Button, Alert } from 'reactstrap';
import { MovementForm } from './components/MovementForm';

class CreateMovementPresentation extends Component {
  state = {
    multiple: false,
    movementId: undefined,
    modal: false,
    consumable: false,
    disclaimerModal: false,
    timeoutModal: false,
    payload: undefined,
  };

  resetForm = undefined;

  constructor(props) {
    super(props);

    // Parse target from URL
    const {
      location: { search },
    } = this.props;

    const query = new URLSearchParams(search);
    const target = query.get('of');

    const consumable = !!(target && target.startsWith('I:'));

    this.state = {
      ...this.state,
      initialValues: {
        consumable,
        assetBarcode: !consumable ? target : undefined,
        itemBarcode: consumable ? target : undefined,
      },
      targetBarcode: null,
      consumable,
    };
  }

  setMultiple = (e) => {
    this.setState({
      multiple: e.target.checked,
    });
  };

  toggle = () =>
    this.setState({
      modal: !this.state.modal,
    });

  disclaimerToogle = (payload = undefined) =>
    this.setState({
      disclaimerModal: !this.state.disclaimerModal,
      payload,
    });

  timeoutToogle = () => this.setState({ timeoutModal: !this.state.timeoutModal });

  handleSubmit = async (data) => {
    if (process.env.NODE_ENV === 'development') console.log('Final form data:', data);

    if (data.movementType === 'discharge' && !this.state.payload) {
      this.disclaimerToogle(data);
    } else {
      // Código temporal, Eliminar al resolver la carga de consumibles
      if (!data['extendedAttributes']) data['extendedAttributes'] = {};
      data['extendedAttributes']['decimalQuantity'] = data.quantity;
      //

      const { body, status } = await createMovement(data);

      if (status === 201) {
        const { multiple } = this.state;

        if (multiple) {
          this.resetForm && this.resetForm();
          window.scrollTo(0, 0);
        } else {
          this.setState({
            movementId: body.id,
          });
        }

        this.props.successNotification();
      } else if (status === 422) {
        if (!this.state.consumable) {
          this.props.errorNotification(body.errors);
          this.toggle();
          if (data.movementType === 'discharge') this.disclaimerToogle();
        } else {
          this.props.errorNotification(body.errors);
        }
        throw new SubmissionError(body.errors);
      } else if (status === 504) {
        if (!this.state.consumable) {
          this.props.errorNotification(body.errors);
          this.toggle();
          this.timeoutToogle();
        }
      }
    }
  };

  handleTarget = (target) => {
    this.setState({
      targetBarcode: target,
    });
  };

  render() {
    const {
      initialValues,
      movementId,
      multiple,
      modal,
      disclaimerModal,
      timeoutModal,
      targetBarcode,
      payload,
    } = this.state;

    if (movementId) return <Redirect to={`/movements/${movementId}`} />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Ingreso manual de movimientos">
          <FormGroup check className="mb-4">
            <Label check>
              <Input name="multiple" type="checkbox" checked={multiple} onChange={this.setMultiple} /> Ingreso múltiple
            </Label>
            <div className="form-text text-muted">
              Al marcar esta opción, una vez guardado el movimiento el formulario se reiniciará y se podrá ingresar
              rápidamente un nuevo movimiento.
            </div>
          </FormGroup>

          <MovementForm
            onSubmit={this.handleSubmit}
            getReset={(reset) => (this.resetForm = reset)}
            initialValues={initialValues}
            handleTarget={this.handleTarget}
          />

          <Modal isOpen={modal} toggle={this.toggle}>
            <ModalHeader>Periodos de Vigencias vencidos</ModalHeader>
            <ModalBody>
              No se puede crear el movimiento porque el activo presenta periodos de vigencia vencidos u otra restricción
              que lo impide. Puede crear procesos MRO u otros tipos de movimientos actualizando y/o creando nuevas
              vigencias"
            </ModalBody>
            <ModalFooter>
              <Link to="/mro/new" color="primary">
                <Button color="primary" type="button">
                  Iniciar MRO
                </Button>
              </Link>{' '}
              <Link to={`/building/assets/${targetBarcode}/validity_periods`} color="primary">
                <Button color="primary" type="button">
                  Ajustar Vigencias
                </Button>
              </Link>{' '}
              <Button color="secondary" onClick={this.toggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={disclaimerModal} toggle={() => this.disclaimerToogle(undefined)}>
            <ModalHeader>Advertencia</ModalHeader>
            <ModalBody>
              <Alert color="danger">
                <p>
                  <strong>¡Importante!</strong> Al dar de baja un activo, este <strong>no</strong> podrá ser utilizado
                  nuevamente.
                </p>
                <p>Considere esto antes proceder con esta acción.</p>
              </Alert>
            </ModalBody>
            <ModalFooter>
              <ProcessingButton
                type="button"
                role="button"
                color="danger"
                width="15em"
                // processing={submitting}
                onClick={() => this.handleSubmit(payload)}
              >
                Dar de baja
              </ProcessingButton>
              <Button color="secondary" onClick={() => this.disclaimerToogle(undefined)}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={timeoutModal} toggle={() => this.timeoutToogle()}>
            <ModalHeader>Advertencia</ModalHeader>
            <ModalBody>
              <Alert color="danger">
                <p>
                  <strong>¡Importante!</strong> Se recibió un error por por demora en la respuesta de la plataforma, sin
                  embargo, tu solicitud se encuentra procesando.
                </p>
                <p>
                  Considere revisar la lista de movimientos en máximo 5 minutos para ver reflejada su petición. En caso
                  de no estar completada, puedes reintentar la solicitud.
                </p>
              </Alert>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => this.timeoutToogle()}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const CreateMovement = connect(null, (dispatch) => ({
  successNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Movimiento creado',
        text: 'El movimiento ha sido creado exitosamente.',
        color: 'success',
      }),
    ),
  errorNotification: (errors) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error al crear Movimiento',
        text: `${errors?.base || errors?.movementType}`,
        color: 'danger',
        duration: 10000,
      }),
    ),
}))(CreateMovementPresentation);
