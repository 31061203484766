import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import { getReadoutIcon } from '@shared/helpers';
import React from 'react';

export const Readout = ({ onClick, readout, className }) => {
  const { address, lat, lng } = readout.metadata;

  const latitude = Number(lat);
  const longitude = Number(lng);

  return (
    <div className={`readout-item flex-column ${className}`} onClick={onClick || (() => null)}>
      <div className="d-flex flex-grow-1 justify-content-between mb-1">
        <span>
          <FontAwesome name="calendar-alt" className="mr-2 text-info" />
          {fromIso8601(readout.readAt)}
        </span>
        <span>
          Lat: <span className="text-info">{latitude ? latitude.toFixed(4) : '-'}</span> Lng:{' '}
          <span className="text-info">{longitude ? longitude.toFixed(4) : '-'}</span>
        </span>
      </div>
      <div>
        <FontAwesome name={getReadoutIcon(readout.metadata.origin)} className="mr-1" />{' '}
        {address || 'Dirección no disponible'}
      </div>
    </div>
  );
};
