import React from 'react';
import { Button, Form, InputGroup, InputGroupButtonDropdown } from 'reactstrap';

import FontAwesome from '../assets/FontAwesome';

type Props = {
  cancel: Function,
  children: React$Element<any>,
  save: Function,
};

const Editable = ({ cancel, children, save }: Props) => (
  <div className="editable-container">
    <Form onSubmit={save}>
      <InputGroup className={'input-group-sm'}>
        {children}
        <InputGroupButtonDropdown>
          <Button role="button" color="primary" onClick={save}>
            <FontAwesome name="check" />
          </Button>
        </InputGroupButtonDropdown>
        <InputGroupButtonDropdown>
          <Button role="button" color="danger" onClick={cancel}>
            <FontAwesome name="times" />
          </Button>
        </InputGroupButtonDropdown>
      </InputGroup>
    </Form>
  </div>
);

export default Editable;
