import React, { Component } from 'react';
import { pluralize } from '@shared/helpers';
import { Table } from 'reactstrap';
import { TaskRow } from './TaskRow';
import {
  deleteTask,
  postponeTask,
  startTask,
  unStartTask,
  cancelTask,
  unCancelTask,
  toggleComplete,
} from '@core/building/tasks';
import { connect, notificationsGenerators } from '@store';
import { TableSortHeader } from '@shared';

class TasksResultsPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayConfirmBox: true,
      tasks: this.props.tasks,
    };
  }

  removeTask = async (index) => {
    const { tasks } = this.props;
    const task = tasks[index];

    const { status } = await deleteTask(task.id);

    if (status === 204) {
      this.props.deleteTaskNotification();

      this.updateTask(index);
    }
  };

  postponeTask = async (taskId, reason, newDate) => {
    const { session } = this.props;
    const userId = session.user.id;
    const { body, status } = await postponeTask(taskId, userId, reason, newDate);
    if (status === 200) {
      this.props.posponeTaskNotification();
      this.updateTask(body);
    }
  };

  startTask = async (taskUpdate) => {
    let response;
    if (taskUpdate.startedAt) {
      response = await unStartTask(taskUpdate.id);
      if (response.status === 200) {
        this.props.unStartTaskNotification();
        this.updateTask(response.body);
      }
    } else {
      response = await startTask(taskUpdate.id);
      if (response.status === 200) {
        this.props.startTaskNotification();
        this.updateTask(response.body);
      }
    }
  };

  cancelTask = async (taskUpdate, reason) => {
    let response;
    if (taskUpdate.canceledAt) {
      response = await unCancelTask(taskUpdate.id, reason);
      if (response.status === 200) {
        this.props.unCancelTaskNotification();
        this.updateTask(response.body);
      }
    } else {
      response = await cancelTask(taskUpdate.id, reason);
      if (response.status === 200) {
        this.props.cancelTaskNotification();
        this.updateTask(response.body);
      }
    }
  };

  toogleConfirmBox = () => {
    this.setState({
      displayConfirmBox: !this.state.displayConfirmBox,
    });
  };

  completeTask = async (taskUpdate) => {
    const { status, body } = await toggleComplete(taskUpdate);

    if (status === 200) {
      this.props.completeTaskNotification();
      this.updateTask(body);
    }
  };

  updateTask = async (taskUpdate) => {
    await this.props.updateTasks(taskUpdate);
  };

  render() {
    const { displayConfirmBox } = this.state;
    const { pagination, handleOrder, currentFilters, updateOrder, order, tasks } = this.props;
    return (
      <div>
        <div className="small mb-4">
          <strong>{pagination.count}</strong> {pluralize(pagination.count, 'tarea encontrada', 'tareas encontradas')}.
          Mostrando <strong>{pagination.per}</strong> tareas por página.
        </div>

        <Table>
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <thead>
            <TableSortHeader
              order={order}
              updateOrder={updateOrder}
              currentFilters={currentFilters}
              setFilters={handleOrder}
              attributes={[
                { label: 'Titulo', code: 'title', sortable: true },
                // { label: 'Estatus', code: 'state', sortable: true },
                { label: 'Cumplimiento', code: '1', sortable: false },
                { label: 'Fecha Creación', code: 'created_at', sortable: true },
                { label: 'Frecuencia', code: 'frequency', sortable: true },
                { label: 'Responsables', code: '3', sortable: false },
                { label: 'Activos', code: 'items.name', sortable: true },
                { label: 'Fecha de Inicio', code: 'start_date', sortable: true },
                { label: 'Fecha de Finalización', code: 'due_date', sortable: true },
                { code: '7' },
              ]}
            />
          </thead>
          <tbody>
            {tasks &&
              tasks.map((task, index) => (
                <TaskRow
                  key={`task-row-${index}`}
                  task={task}
                  removeTask={() => this.removeTask(index)}
                  postponeTask={this.postponeTask}
                  startTask={this.startTask}
                  cancelTask={this.cancelTask}
                  completeTask={this.completeTask}
                  toogleConfirmBox={this.toogleConfirmBox}
                  displayConfirmBox={displayConfirmBox}
                  onTaskChange={this.updateTask}
                />
              ))}

            {tasks.length === 0 && (
              <tr>
                <td colSpan="3" className="text-center mt-3 mb-3">
                  <em>No se encontraron tareas.</em>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export const TasksResults = connect(
  (state) => ({ session: state.session }),
  (dispatch) => ({
    deleteTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea eliminada',
          text: 'La tarea ha sido eliminada correctamente.',
          color: 'success',
        }),
      ),
    posponeTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea postergada',
          text: 'La tarea ha sido postergada correctamente.',
          color: 'success',
        }),
      ),
    startTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea iniciada',
          text: 'La tarea ha sido iniciada correctamente.',
          color: 'success',
        }),
      ),
    unStartTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea sin iniciar',
          text: 'La tarea ha sido ya no está iniciada.',
          color: 'warning',
        }),
      ),
    cancelTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea anulada',
          text: 'La tarea ha sido anulada correctamente.',
          color: 'success',
        }),
      ),
    unCancelTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea reanudada',
          text: 'La tarea ha sido reanudada correctamente.',
          color: 'success',
        }),
      ),
    completeTaskNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea completada',
          text: 'La tarea ha sido completada correctamente.',
          color: 'success',
        }),
      ),
    failureNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Acción fallida',
          text: 'La última acción a fallado',
          color: 'danger',
        }),
      ),
  }),
)(TasksResultsPresentation);
