import { getUsers } from '@core/building/users';
import { getUserGroups } from '@core/building/user_groups';
import ChileanRUT from '@core/utils/chilean-rut';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

export class UserGroupMembersFilterModal extends SimpleFilterModal {
  findUserGroups = async (value) => {
    const { body, status } = await getUserGroups({ search: value });
    if (status === 200) {
      return body.map(({ name: label, name: value }) => ({ label, value }));
    }
    return [];
  };

  findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return body.map(({ firstName, lastName, identifier, id: value }) => ({
        label: `${firstName} ${lastName} - ${
          identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
        }`,
        value,
      }));
    }
    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="identifiers[{}]">Miembros</Label>
              <AsyncSelect
                name="identifiers[{}]"
                value={this.state['identifiers[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="roles">Roles</Label>
              <Select
                name="roles"
                value={this.state['roles']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Administrador', value: 'admin' },
                  { label: 'Usuario', value: 'user' },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
