const passwordsMatching = function (key1 = 'password', key2 = 'passwordConfirmation') {
  return function (data) {
    const { [key1]: password, [key2]: passwordConfirmation } = data;

    if (password && passwordConfirmation && password !== passwordConfirmation) {
      return { passwordConfirmation: 'Las contraseñas deben coincidir' };
    }

    return {};
  };
};

export default passwordsMatching;
