import { getReadouts } from '@core/building/readouts';
import { buildingDownload } from '@core/http.js';
import { CsvDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { connect } from '@store';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { ReadoutsFilterModal } from './components/ReadoutsFilterModal';
import { ReadoutsResults } from './components/ReadoutsResults';

class ReadoutsIndexPresentation extends SimpleFilter {
  searchFunction = getReadouts;

  constructor(props) {
    super(props);
    this.state = {
      ...super.state,
      readout: undefined,
    };
  }

  render() {
    const { tagTypes } = this.props;
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                <CsvDownload fileName="readouts" filters={filters} downloadFile={buildingDownload} disabled={!items} />
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="history" className="mr-2 text-primary" />
              Gestión de Lecturas
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}

                {items && <ReadoutsResults readouts={items} pagination={pagination} tagTypes={tagTypes} />}

                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ReadoutsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}

export const ReadoutsIndex = connect(({ appData: { tag_types: tagTypes } }) => {
  const options = {};
  tagTypes.forEach((tt) => (options[tt.id] = tt.description));
  return { tagTypes: options };
})(ReadoutsIndexPresentation);
