import React from 'react';
import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import { pluralize } from '@shared/helpers';
import { NavLink } from 'react-router-dom';
import { Button, Table } from 'reactstrap';

export const PickingTable = ({ pickings, pagination }) => {
  return (
    <div>
      {pagination && (
        <div className="small mb-4">
          <strong>{pagination.count}</strong>{' '}
          {pluralize(pagination.count, 'movimiento encontrado', 'movimientos encontrados')}. Mostrando{' '}
          <strong>{pagination.per}</strong> movimientos por página.
        </div>
      )}
      <Table size="sm">
        <thead>
          <th>Vendedor</th>
          <th>Cliente</th>
          <th>Fecha</th>
          <th className="text-right">Folio SAP</th>
          <th className="text-right">Picking ID</th>
          <th className="text-right">Numero de Solicitud</th>
          <th className="text-right">Monto Total</th>
          <th></th>
        </thead>
        <tbody>
          {pickings.map((picking) => (
            <tr>
              <td>{picking.seller.fullName}</td>
              <td>{picking.buyer.fullName}</td>
              <td>{fromIso8601(picking.date)}</td>
              <td className="text-right">
                {picking.integrationInfo ? picking?.integrationInfo?.folio : 'Procesando...'}
              </td>
              <td className="text-right">
                {picking.integrationInfo ? picking?.integrationInfo?.pickingId : 'Procesando...'}
              </td>
              <td className="text-right">{picking.requestNumber}</td>
              <td className="text-right">
                $ {Math.trunc(picking.movements.reduce((prev, curr) => prev + curr.price * curr.quantity, 0))}
              </td>
              <td className="text-right">
                <Button role="button" color="primary" size="sm" tag={NavLink} to={`/pickings/${picking.id}`}>
                  <FontAwesome name="search" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PickingTable;
