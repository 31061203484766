import { getPartner, updatePartner } from '@core/building/partners';
import { FontAwesome, withLoader } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { compose, withHandlers, withState } from 'recompose';
import { SubmissionError } from 'redux-form';
import { PartnerForm } from './PartnerForm';

const enhance = compose(
  connect(null, (dispatch) => ({
    createNotification: (formData) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Socio de negocio actualizado',
          text: `El socio de negocio "${formData.name}" ha sido creado exitosamente.`,
          color: 'success',
          duration: 10000,
        }),
      ),
  })),
  withState('loading', 'setLoading', 'waiting'),
  withState('redirectTo', 'setRedirectTo', undefined),
  withLoader(
    'initialValues',
    ({
      match: {
        params: { id },
      },
    }) => getPartner(id),
    {
      onError: console.log,
    },
  ),
  withHandlers({
    handleForm: ({ createNotification, setLoading, setRedirectTo, initialValues }) => async (data) => {
      setLoading('loading');
      const { body, status } = await updatePartner(initialValues.id, data);

      if (status === 200) {
        setLoading('success');
        setRedirectTo(`/building/partners/${body.id}`);
        createNotification(body);
      } else if (status === 422) {
        setLoading('waiting');
        throw new SubmissionError(body.errors);
      }
    },
  }),
);

const PartnerEditPresentation = ({ handleForm, loading, redirectTo, initialValues }) =>
  redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <BuildingLayout>
      <SimpleContainerLayout
        title={
          <span>
            <FontAwesome name="users" className="text-primary mr-2" /> Editar socio de negocio
          </span>
        }
      >
        <PartnerForm loading={loading === 'loading'} onSubmit={handleForm} initialValues={initialValues} />
      </SimpleContainerLayout>
    </BuildingLayout>
  );

export const PartnerEdit = enhance(PartnerEditPresentation);
