import { ItemSelector } from '@shared/forms';
import React, { useEffect, useState } from 'react';
import { FontAwesome, ShowForRoles, Loading } from '@shared';
import { Alert, Button, Col, Container, Input, Row, Table, UncontrolledTooltip } from 'reactstrap';
import {
  createRestrictedItem,
  deleteRestrictedItem,
  editRestrictedItem,
  getRestrictedItems,
} from '@core/building/restricted-items';
import Select from 'react-select';
import { RestrictedItemRow } from './RestrictedItemRow';

export const RestrictedItems = ({ user, ...props }) => {
  const [restrictedItems, setRestrictedItems] = useState([]);
  const [item, setItem] = useState(undefined);
  const [itemId, setItemId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [inputEdit, setInputEdit] = useState(false);
  const [duplicated, setDuplicated] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [period, setPeriod] = useState({ label: 'Seleccione...', value: undefined });

  useEffect(() => {
    getRestrictions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cargar los items restringidos
  const getRestrictions = async () => {
    setLoading(true);
    const { body, status } = await getRestrictedItems(user.id);

    if (status === 200) {
      setRestrictedItems(body);
      setLoading(false);
    }
  };

  // Crear restricción
  const createRestriction = async () => {
    setLoading(true);
    console.log({ itemId, quantity, period });
    const { status } = await createRestrictedItem(user.id, { itemId, quantity, period: period.value });

    if (status === 201) {
      setInputEdit(false);
      getRestrictions();
    }
  };

  // Editar restricción
  const editRestriction = async (restrictedItemPayload) => {
    const { status } = await editRestrictedItem(user.id, restrictedItemPayload);

    if (status === 200) {
      getRestrictions();
    }
  };

  // Borrar restricción
  const deleteRestriction = async (restrictedItemId) => {
    const { status } = await deleteRestrictedItem(user.id, restrictedItemId);

    if (status === 204) {
      getRestrictions();
    }
  };

  // Verificar duplicados
  const checkRestrictionDuplicate = (id, item) => {
    console.log(id, item);
    if (restrictedItems.some((restrictedItem) => restrictedItem.item.id === id)) {
      setDuplicated(true);
      setItemId(undefined);
      setItem(undefined);
    } else {
      setDuplicated(false);
      setItemId(id);
      setItem(item);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <Row>
            <ShowForRoles accepted={['organization_admin']}>
              <Col>
                <div className="content-actions float-right mb-2">
                  <Button
                    outline
                    size="sm"
                    color="success"
                    onClick={() => {
                      setItemId(undefined);
                      setItem(undefined);
                      setQuantity(0);
                      setPeriod(undefined);
                      setDuplicated(false);
                      setInputEdit(true);
                    }}
                  >
                    <FontAwesome name="plus" /> Agregar Restricción
                  </Button>
                </div>
              </Col>
            </ShowForRoles>
          </Row>
          <Table>
            <colgroup>
              <col style={{ width: 'auto' }} />
              <col style={{ width: 'auto' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: 'auto' }} />
              <ShowForRoles accepted={['organization_admin']}>
                <col style={{ width: '150px' }} />
              </ShowForRoles>
            </colgroup>
            <thead>
              <th className="text-left">Artículo</th>
              <th className="align-middle text-right">Cantidad</th>
              <th className="align-middle text-center">Frecuencia</th>
              <th className="align-middle text-right">Utilizados</th>
              <ShowForRoles accepted={['organization_admin']}>
                <th className="text-center">Acciones</th>
              </ShowForRoles>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Loading />
                  </td>
                </tr>
              ) : (
                inputEdit && (
                  <ShowForRoles accepted={['organization_admin']}>
                    <tr>
                      <td className="align-middle">
                        <ItemSelector
                          inputProps={{ size: 'md' }}
                          filters={{ active: true, serializer: 'simple' }}
                          resourceKey="id"
                          currentItem={item}
                          input={{ onChange: checkRestrictionDuplicate }}
                          value={itemId}
                          required
                        />
                      </td>
                      <td className="align-middle small text-right">
                        <Input
                          name="quantity"
                          placeholder="Cantidad"
                          type="number"
                          step="1"
                          min="0"
                          className="text-right"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                        />
                      </td>
                      <td className="align-middle">
                        <Select
                          name="period"
                          isSearchable
                          closeMenuOnSelect={true}
                          placeholder="Frecuencia"
                          options={[
                            { label: 'Seleccione...', value: '' },
                            { label: 'Diaria', value: 'daily' },
                            { label: 'Semanal', value: 'weekly' },
                            { label: 'Mensual', value: 'monthly' },
                          ]}
                          onChange={(e) => {
                            console.log(item, itemId, quantity, period);
                            setPeriod(e);
                          }}
                        />
                      </td>
                      <td className="align-middle text-right">0</td>
                      <td className="align-middle small text-center">
                        <Button
                          role="button"
                          color="success"
                          size="sm"
                          className="mr-1"
                          disabled={!itemId || !period?.value || quantity < 0}
                          onClick={() => createRestriction()}
                          id={`create`}
                        >
                          <FontAwesome name="check" />
                          <UncontrolledTooltip target={`create`} placement="right">
                            Confirmar restricción
                          </UncontrolledTooltip>
                        </Button>
                        <Button
                          role="button"
                          color="danger"
                          size="sm"
                          id={`close`}
                          onClick={() => {
                            setItemId(undefined);
                            setItem(undefined);
                            setQuantity(0);
                            setPeriod(undefined);
                            setInputEdit(false);
                            setDuplicated(false);
                          }}
                        >
                          <FontAwesome name="times" />
                          <UncontrolledTooltip target={`close`} placement="right">
                            Cerrar Editor
                          </UncontrolledTooltip>
                        </Button>
                      </td>
                    </tr>
                    {duplicated && (
                      <tr>
                        <td colSpan="5" className="text-center">
                          <Alert color="warning">El artículo ya está restringido</Alert>
                        </td>
                      </tr>
                    )}
                  </ShowForRoles>
                )
              )}
              {restrictedItems && restrictedItems.length > 0 ? (
                restrictedItems.map((restrictedItem, index) => (
                  <RestrictedItemRow
                    key={`restricted-item-${index}`}
                    restrictedItem={restrictedItem}
                    index={index}
                    getRestrictions={getRestrictions}
                    editRestriction={editRestriction}
                    deleteRestriction={deleteRestriction}
                    userId={user.id}
                  />
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">
                    <Alert color="info">El usuario no tiene artículos restringidos</Alert>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};
