import React, { Component } from 'react';
import { Redirect } from 'react-router';

import { connect, notificationsGenerators, sessionGenerators } from '@store';
import { confirmEmail } from '@core/account';
import { Loading } from '@shared';

class Confirmation extends Component {
  state = {
    loading: true,
  };

  async componentDidMount() {
    const { body, status } = await confirmEmail({
      token: this.props.match.params.token,
    });

    if (status === 200) {
      this.props.login(body);
      this.props.successNotification();
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    return <ConfirmationPage loading={this.state.loading} />;
  }
}

const ConfirmationPage = ({ loading }) => {
  if (loading) return <Loading />;

  return <Redirect to="/" />;
};

export default connect(null, (dispatch) => ({
  login: (token) => dispatch(sessionGenerators.login(token)),
  successNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Correo confirmado',
        text:
          'Tu correo ha sido actualizado exitosamente. Ahora puedes iniciar sesión con tu nueva dirección de correo.',
        color: 'info',
      }),
    ),
}))(Confirmation);
