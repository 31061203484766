import { pluralize } from '@shared/helpers';
import { Table } from 'reactstrap';
import React from 'react';
import { PostRow } from './PostRow';

export const PostsResults = ({ posts, pagination, setPostStatus }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'noticia encontrada', 'noticias encontradas')}.
      Mostrando <strong>{pagination.per}</strong> noticias por página.
    </div>
    <Table>
      <colgroup>
        <col style={{ width: '80px' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '15%' }} />
      </colgroup>
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th>Título</th>
          <th className="text-center">Publicación</th>
          <th className="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {posts.map((post, index) => (
          <PostRow key={index} post={post} setPostStatus={setPostStatus} />
        ))}

        {posts.length === 0 && (
          <tr>
            <td colSpan={4} className="text-center">
              <div>No hay noticias creados todavía.</div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
