import React, { Component } from 'react';
import { connect, notificationsGenerators } from '@store';
import { SubmissionError } from '@core/forms';
import { FontAwesome } from '@shared/';
import { ItemSelector } from '@shared/forms';
import { AssetSelector } from '@shared/forms';
import { SimpleContainerLayout } from '@shared/layouts';
import { Button, Col, FormGroup, Row } from 'reactstrap';
import { ItemCreatorForm } from './components/ItemCreatorForm';
import { AssetCreatorForm } from './components/AssetCreatorForm';
import { TagCreatorForm } from './components/TagsCreatorForm';
import { TagDisplay } from '@shared/displays/TagDisplay';
import { createOnboarding } from '@core/building/tags';
import { ItemDisplay } from 'organization/items/components/ItemCard';
import { SimpleAssetDisplay } from '@shared/displays';

class TagsCreatorPresentation extends Component {
  state = {
    itemBarcode: null,
    assetBarcode: null,
    item: null,
    asset: null,
    tag: null,
    step: { prev: 0, actual: 1, next: 2 },
    assembly: { item: null, asset: null, tags: [] },
    itemOrigin: true,
    assetOrigin: true,
    tags: [],
    created: false,
  };
  initialValues = this.state;

  setItemBarcode = (itemBarcode, item) => {
    this.setState({ item, itemBarcode, assembly: { ...this.state.assembly, item: { barcode: itemBarcode } } });
    if (this.state.step.actual <= 2) this.setStep(2);
  };

  setAssetBarcode = (assetBarcode, asset) => {
    this.setState({ asset, assetBarcode, assembly: { ...this.state.assembly, asset: { barcode: assetBarcode } } });
    this.setStep(3);
  };

  setTag = (tag) => {
    this.setState({ assembly: { ...this.state.assembly, tags: [...this.state.assembly.tags, tag] } });
  };

  setStep = (step) => {
    this.setState({
      step: {
        prev: this.state.step.actual,
        actual: step,
        next: step + 1,
      },
    });
  };

  createItem = (redirect, reset, item) => {
    this.setState({ assembly: { ...this.state.assembly, item }, assetOrigin: false });
    this.setStep(2);
  };

  createAsset = (redirect, reset, asset) => {
    this.setState({ assembly: { ...this.state.assembly, asset } });
    this.setStep(3);
  };

  createTag = async (redirect, reset, tag) => {
    this.setState({ assembly: { ...this.state.assembly, tags: [...this.state.assembly.tags, tag] } });
    reset();
  };

  removeTag = (index) => {
    this.state.assembly.tags.splice(index, 1);
    this.setState({ assembly: { ...this.state.assembly } });
  };

  createOnboarding = async (data) => {
    const { body, status } = await createOnboarding(data);

    if (status === 201) {
      this.props.successCreationNotification();
      this.setState(this.initialValues);

      this.setState({ created: true });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { tagTypes } = this.props;
    const { itemBarcode, itemOrigin, assetBarcode, assetOrigin, step, assembly, asset, item } = this.state;
    return (
      <div className="layout-container container-fluid mt-4 mb-4">
        <SimpleContainerLayout
          title={
            <span>
              <FontAwesome name="barcode" className="mr-1 text-primary" /> Onboarding
            </span>
          }
        >
          <Col>
            <Row className="mb-4 text-center">
              <Col md={4}>
                <Button
                  outline
                  color={step.actual === 1 ? 'primary' : step.actual > 1 ? 'success' : ''}
                  onClick={() => {
                    this.setStep(1);
                  }}
                >
                  {step.actual === 1 ? (
                    <span>
                      <FontAwesome name="th-large" /> Artículo
                    </span>
                  ) : step.actual > 1 ? (
                    <span>
                      <FontAwesome name="th-large" /> Artículo <FontAwesome name="check" />
                    </span>
                  ) : (
                    <span>
                      <FontAwesome name="th-large" /> Artículo
                    </span>
                  )}
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  outline
                  color={step.actual === 2 ? 'primary' : step.actual > 2 ? 'success' : ''}
                  onClick={() => {
                    this.setStep(2);
                  }}
                  disabled={step.actual < 2 && !itemBarcode}
                >
                  {step.actual === 2 ? (
                    <span>
                      <FontAwesome name="wrench" /> Activo
                    </span>
                  ) : step.actual > 2 || (step.actual < 2 && itemBarcode) ? (
                    <span>
                      <FontAwesome name="wrench" /> Activo <FontAwesome name="check" />
                    </span>
                  ) : (
                    <span>
                      <FontAwesome name="wrench" /> Activo
                    </span>
                  )}
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  outline
                  color={step.actual === 3 ? 'primary' : step.actual === 4 ? 'success' : ''}
                  onClick={() => {
                    this.setStep(3);
                  }}
                  disabled={step.actual < 3 && !assetBarcode}
                >
                  {step.actual === 3 ? (
                    <span>
                      <FontAwesome name="mobile-alt" /> Tecnología
                    </span>
                  ) : step.actual > 3 || (step.actual < 3 && assembly?.tags.length) ? (
                    <span>
                      <FontAwesome name="mobile-alt" /> Tecnología <FontAwesome name="check" />
                    </span>
                  ) : (
                    <span>
                      <FontAwesome name="mobile-alt" /> Tecnología
                    </span>
                  )}
                </Button>
              </Col>
            </Row>
            <Row>
              {step.actual === 1 && (
                <Col>
                  <h4 className="text-center mb-3">Artículo</h4>
                  <FormGroup>
                    {itemOrigin ? (
                      <ItemSelector
                        value={itemBarcode}
                        input={{
                          onChange: step.prev < step.actual ? this.setItemBarcode : null,
                        }}
                        filters={{
                          consumable: false,
                          active: true,
                        }}
                      />
                    ) : (
                      <ItemCreatorForm onSubmit={this.createItem} />
                    )}
                  </FormGroup>
                  <hr />
                  <Row className="float-md-right">
                    {!itemOrigin && (
                      <Button
                        color="danger"
                        className="mr-2"
                        onClick={() => {
                          this.setState({ itemOrigin: true });
                        }}
                        disabled={itemOrigin}
                      >
                        <FontAwesome name="times" className="mr-1" />
                        Cancelar
                      </Button>
                    )}
                    {itemOrigin && (
                      <Button
                        color="primary"
                        className="mr-2"
                        onClick={() => {
                          this.setState({ itemOrigin: false });
                        }}
                      >
                        <FontAwesome name="plus" className="mr-1" />
                        Crear Artículo
                      </Button>
                    )}
                  </Row>
                </Col>
              )}
              {step.actual === 2 && (
                <Col>
                  <h4 className="text-center mb-3">Activo</h4>
                  <FormGroup>
                    {assetOrigin ? (
                      itemOrigin && (
                        <AssetSelector
                          value={assetBarcode}
                          filters={{ items: [itemBarcode] }}
                          input={{
                            onChange: step.prev < step.actual ? this.setAssetBarcode : null,
                          }}
                        />
                      )
                    ) : (
                      <AssetCreatorForm
                        input={{ name: 'itemBarcode', value: itemBarcode, readonly: true }}
                        initialValues={{ itemBarcode }}
                        onSubmit={this.createAsset}
                      />
                    )}
                  </FormGroup>
                  <hr />
                  <Row className="float-md-right">
                    {!assetOrigin && (
                      <Button
                        color="danger"
                        className="mr-2"
                        onClick={() => {
                          this.setState({ assetOrigin: true });
                        }}
                        disabled={!itemOrigin}
                      >
                        <FontAwesome name="times" className="mr-1" />
                        Cancelar
                      </Button>
                    )}
                    {assetOrigin && (
                      <Button
                        color="primary"
                        className="mr-2"
                        onClick={() => {
                          this.setState({ assetOrigin: false });
                        }}
                      >
                        <FontAwesome name="plus" className="mr-1" />
                        Crear Activo
                      </Button>
                    )}
                    <Button
                      color="warning"
                      onClick={() => {
                        this.setStep(1);
                      }}
                    >
                      <FontAwesome name="undo" className="mr-1" />
                      Volver
                    </Button>
                  </Row>
                </Col>
              )}
              {step.actual === 4 && (
                <Col sm={12}>
                  <h4 className="text-center mb-3">Resumen</h4>
                  <Row className="mb-5">
                    <Col>
                      <h4 className="mb-3">Artículo</h4>
                      {/* {JSON.stringify(assembly.item)} */}
                      <Row>
                        {item ? (
                          <ItemDisplay item={item} />
                        ) : (
                          <Col>
                            <p>Se creará un nuevo artículo</p>
                            <p>Nombre: {assembly.item?.name}</p>
                            <p>SKU Interno: {assembly.item?.internalSku}</p>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-5">
                    <Col>
                      <h4 className="mb-2">Activo</h4>
                      {/* {JSON.stringify(assembly.asset)} */}
                      <Row>
                        {asset ? (
                          <Col>
                            <SimpleAssetDisplay asset={asset} showStatus={false} showBarcode={true} />
                          </Col>
                        ) : (
                          <Col>
                            <p>Se creará un nuevo Activo</p>
                            <p>Identificador: {assembly.asset?.factorySerialNumber1}</p>
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <hr />
                  <Row className="mb-3">
                    <Col>
                      <h4 className="mb-2">Tags</h4>
                    </Col>
                  </Row>
                </Col>
              )}
              {(step.actual === 3 || step.actual === 4) && (
                <Col sm={12}>
                  {step.actual === 3 && <h4 className="text-center mb-3">Tags</h4>}
                  <FormGroup>
                    {step.actual === 3 && <TagCreatorForm assetBarcode={assetBarcode} onSubmit={this.createTag} />}
                    {step.actual === 3 && assembly.tags.length > 0 && <hr />}
                    {assembly && assembly.tags?.length > 0 && (
                      <div>
                        {step.actual === 3 && <h4 className="mt-3 mb-2 text-center">Listado de tags</h4>}
                        {assembly.tags.map((tag, index) => {
                          let tagData = {};
                          switch (tag.tagType) {
                            case 1:
                              tagData = {
                                identifier: `BEACON:${tag.metadata['major']}-${tag.metadata['minor']}`,
                                active: tag.active,
                                tagType: tagTypes.find((t) => t.id === tag.tagType),
                              };
                              break;
                            case 2:
                              tagData = {
                                identifier: `EPC:${tag.metadata['epc']}`,
                                active: tag.active,
                                tagType: tagTypes.find((t) => t.id === tag.tagType),
                              };
                              break;
                            case 4:
                              tagData = {
                                identifier: `IMEI:${tag.metadata['imei']}`,
                                active: tag.active,
                                tagType: tagTypes.find((t) => t.id === tag.tagType),
                              };
                              break;
                            case 6:
                              tagData = {
                                identifier: `EUI:${tag.metadata['eui']}`,
                                active: tag.active,
                                tagType: tagTypes.find((t) => t.id === tag.tagType),
                              };
                              break;
                            default:
                              tagData = {};
                          }
                          return (
                            <Row key={index} className="mb-2">
                              <Col>
                                <TagDisplay tag={tagData} />
                              </Col>
                              {step.actual === 3 && (
                                <Col className="pr-3" sm={1}>
                                  <Button
                                    color="danger"
                                    className="mr-2"
                                    onClick={() => {
                                      this.removeTag(index);
                                    }}
                                    disabled={!itemOrigin}
                                  >
                                    <FontAwesome name="times" className="mr-1" />
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          );
                        })}
                      </div>
                    )}
                  </FormGroup>
                  <hr />
                  {step.actual === 3 && (
                    <Row className="float-md-right">
                      <Button color="danger" className="mr-2" onClick={() => this.setState(this.initialValues)}>
                        <FontAwesome name="times" className="mr-1" />
                        Reiniciar
                      </Button>
                      <Button
                        color="warning"
                        className="mr-2"
                        onClick={() => {
                          this.setStep(2);
                        }}
                      >
                        <FontAwesome name="undo" className="mr-1" />
                        Volver
                      </Button>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.setStep(4);
                        }}
                        disabled={assembly.tags?.length < 1}
                      >
                        Siguiente <FontAwesome name="arrow-right" className="mr-1" />
                      </Button>
                    </Row>
                  )}
                  {step.actual === 4 && (
                    <Row className="float-md-right">
                      <Button
                        outline
                        color="primary"
                        onClick={() => {
                          this.createOnboarding(assembly);
                        }}
                        disabled={step.actual < 4}
                      >
                        <FontAwesome name="barcode" /> Finalizar Proceso
                      </Button>
                    </Row>
                  )}
                </Col>
              )}
            </Row>
          </Col>
        </SimpleContainerLayout>
      </div>
    );
  }
}

export const TagsCreator = connect(
  ({ appData: { tag_types: tagTypes } }) => {
    return { tagTypes };
  },
  (dispatch) => ({
    successCreationNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Onboarding creado',
          text: 'El Onboarding ha sido creado exitosamente.',
          color: 'success',
        }),
      ),
  }),
)(TagsCreatorPresentation);
