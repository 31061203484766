import { activateUser, deactivateUser, role, status } from '@core/admin/users';
import ChileanRUT from '@core/utils/chilean-rut';
import { fromIso8601 } from '@core/utils/dates';
import { Spinner } from '@shared';
import portrait from '@shared/assets/anonymous.png';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Button, Card, CardBody, CardFooter, CardTitle } from 'reactstrap';

function fullName(user) {
  return `${user.firstName} ${user.lastName}`;
}

const ProfileImage = ({ url, alt = '', height: minHeight = '10rem' }) => (
  <div className="profile-image" style={{ minHeight }}>
    <img src={url} alt={alt} style={{ minHeight }} />
  </div>
);

class UserCardContainer extends Component {
  state = {
    processing: false,
  };

  activateUser = async () => {
    const { user } = this.props;

    this.setState({ processing: true });

    const { status } = await activateUser(user.id);

    if (status === 200) {
      this.props.activateNotification();
      this.props.onUpdate && this.props.onUpdate({ status: 'ok' });
    }

    this.setState({ processing: false });
  };

  deactivateUser = async () => {
    const { user } = this.props;

    this.setState({ processing: true });

    const { status } = await deactivateUser(user.id);

    if (status === 204) {
      this.props.deactivateNotification();
      this.props.onUpdate && this.props.onUpdate({ status: 'not_active' });
    }

    this.setState({ processing: false });
  };

  render() {
    const { user } = this.props;
    const { processing } = this.state;

    return (
      <UserCardComponent
        user={user}
        processing={processing}
        activateUser={this.activateUser}
        deactivateUser={this.deactivateUser}
      />
    );
  }
}

const UserCardComponent = ({ isAdmin = false, user, processing, deactivateUser, activateUser }) => (
  <Card className="profile-card mb-4">
    <ProfileImage url={(user.avatar && user.avatar.url) || portrait} alt={fullName(user)} height="10rem" />
    <CardBody>
      <CardTitle tag={'h4'}>{fullName(user)}</CardTitle>

      <div className="mb-3">
        <div className="small text-muted">Identificación</div>
        {ChileanRUT.PartialFormat(user.identifier.value)}
      </div>

      {isAdmin && (
        <div className="mb-3">
          <div className="small text-muted">Rol global</div>
          {role(user.rootRole)}
        </div>
      )}

      <div className="mb-3">
        <div className="small text-muted">Última actividad</div>
        {user.lastActivity ? fromIso8601(user.lastActivity) : <em>No disponible</em>}
      </div>

      <div className="mb-3">
        <div className="small text-muted">Estado</div>
        {status(user.status)}
      </div>
    </CardBody>

    {isAdmin && (
      <CardFooter>
        {user.status === 'not_active' && (
          <Button role="button" block color="success" disabled={processing} onClick={activateUser}>
            {processing ? <Spinner /> : 'Reactivar'}
          </Button>
        )}

        {user.status !== 'not_active' && (
          <Button role="button" block color="danger" disabled={processing} onClick={deactivateUser}>
            {processing ? <Spinner /> : 'Desactivar'}
          </Button>
        )}
      </CardFooter>
    )}
  </Card>
);

export const UserCard = connect(null, (dispatch) => ({
  activateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Cuenta reactivada',
        text: 'La cuenta ha sido reactivada exitosamente',
        color: 'success',
      }),
    ),
  deactivateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Cuenta desactivada',
        text: 'La cuenta ha sido desactivada exitosamente',
        color: 'danger',
      }),
    ),
}))(UserCardContainer);
