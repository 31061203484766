import { getUsers } from '@core/admin/users';
import { adminDownload } from '@core/http.js';
import { ExcelDownload, FontAwesome, Pagination, Spinner } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { UsersFilterModal } from './components/UsersFilterModal';
import { UsersResults } from './components/UsersResults';

export class UsersIndex extends SimpleFilter {
  searchFunction = getUsers;

  render() {
    if (this.state.redirectId) {
      return <Redirect to={`/admin/users/${this.state.redirectId}`} />;
    }

    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Row>
              <Col>
                <div className="content-actions float-right">
                  <Button size="sm" color="secondary" onClick={this.toggle}>
                    <FontAwesome name="filter" /> Filtros
                  </Button>
                  <ExcelDownload file="users" filters={filters} downloadFile={adminDownload} disabled={!items} />
                  <Button tag={NavLink} size="sm" outline color="success" to="/admin/users/mass">
                    <FontAwesome name="upload" /> Importación
                  </Button>
                  <Button tag={NavLink} size="sm" outline color="success" to="/admin/users/new">
                    <FontAwesome name="plus" /> Crear Usuario
                  </Button>
                </div>
                <h4 className="mb-4">
                  <FontAwesome name="user" className="mr-2 text-primary" /> Gestión de Usuarios
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!items && (
                      <div className="text-center h1 text-info">
                        <Spinner />
                      </div>
                    )}

                    {items && <UsersResults users={items} pagination={pagination} />}

                    {(!items || items.length > 0) && paginationTemplate}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <UsersFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </Container>
    );
  }
}
