import { pluralize } from '@shared/helpers';
import React from 'react';
import AttachmentRow from './AttachmentRow';
import { compose, withHandlers } from 'recompose';
import { confirmDialog } from '@shared';
import { connect, notificationsGenerators } from '@store';
import { deleteAttachment } from '@core/building/attachments';

const enhance = compose(
  connect(null, (dispatch) => ({
    deletionNotification: (name) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Archivo eliminado',
          text: `El archivo ${name} ha sido eliminado exitosamente.`,
          color: 'success',
        }),
      ),
  })),
  withHandlers({
    onDelete: ({ attachments, deletionNotification, setAttachments }) => async (token) => {
      const outcome = await confirmDialog(
        '¿Estás seguro de que deseas borrar este archivo? Esta acción no se puede deshacer.',
      );

      if (!outcome) return;

      const { status } = await deleteAttachment({
        resourceType: 'organizations',
        token,
      });
      if (status === 204) {
        const index = attachments.findIndex((att) => att.token === token);

        if (index !== -1) {
          deletionNotification(attachments[index].title);

          setAttachments([...attachments.slice(0, index), ...attachments.slice(index + 1)]);
        }
      }
    },
  }),
);

const AttachmentResults = ({ attachments, pagination, onDelete }) => (
  <div>
    {attachments.length > 0 ? (
      <div className="small mb-4">
        <strong>{pagination.count}</strong> {pluralize(pagination.count, 'archivo encontrado', 'archivos encontrados')}.
        Mostrando <strong>{pagination.per}</strong> archivos por página.
      </div>
    ) : (
      <div className="text-center mb-4">No hay adjuntos cargados en este momento.</div>
    )}

    {attachments.map((attachment, index) => (
      <AttachmentRow key={attachment.token} attachment={attachment} onDelete={onDelete} />
    ))}
  </div>
);

export default enhance(AttachmentResults);
