export const AppDataActions = {
  SetAppData: 'AppDataActions.SetAppData',
};

export const appDataInitialState = {};

export function appData(state = appDataInitialState, action) {
  switch (action.type) {
    case AppDataActions.SetAppData:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const appDataGenerators = {
  setAppData: (data) => ({
    type: AppDataActions.SetAppData,
    payload: data,
  }),
};
