import { ConfirmBox, FontAwesome, PictureDisplay, ShowForRoles, Spinner } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Media, UncontrolledTooltip } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';

const enhance = compose(
  withState('loading', 'setLoading', false),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    remove: ({ setLoading, softDelete, setIsOpen }) => async () => {
      setLoading('remove');

      await softDelete();

      setLoading(false);
      setIsOpen(false);
    },
  }),
  withHandlers({
    openConfirmBox: ({ setIsOpen, displayConfirmBox, remove }) => () => {
      if (displayConfirmBox) {
        setIsOpen(true);
      } else {
        remove();
      }
    },
    closeConfirmBox: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
  }),
);

export const ItemDisplay = ({ item }) => {
  return (
    <Media>
      <Media left className="mr-2">
        <PictureDisplay
          picture={item.defaultPicture}
          pictureSize="icon"
          alt={item.name}
          width="6rem"
          height="4rem"
          imgClassName={`status-border status-border-${item.active ? 'active' : 'inactive'}`}
        />
      </Media>
      <Media body>
        <NavLink to={`/organization/items/${item.barcode1}`}>{item.name}</NavLink>{' '}
        {item.consumable && (
          <span>
            <FontAwesome className="mr-1" color="muted" name="tint" id={`consumable-${item.id}`} />
            <UncontrolledTooltip placement="top" target={`consumable-${item.id}`}>
              Consumible
            </UncontrolledTooltip>
          </span>
        )}
        {item.restricted && (
          <span>
            <FontAwesome className="mr-1" color="muted" name="exclamation-triangle" id={`restricted-${item.id}`} />
            <UncontrolledTooltip placement="top" target={`restricted-${item.id}`}>
              Restringido
            </UncontrolledTooltip>
          </span>
        )}
        <div className="small">
          SKU Interno: <span className="text-muted">{item.internalSku || item.internal_sku}</span>
          {item.externalSku && (
            <span>
              {' '}
              - SKU Externo: <span className="text.muted">{item.externalSku || item.external_sku}</span>
            </span>
          )}
        </div>
        <div className="mt-2 tiny">
          <code>{item.barcode1}</code>
        </div>
      </Media>
    </Media>
  );
};

const ItemCardPresentation = ({ item, isOpen, remove, openConfirmBox, closeConfirmBox, toogleConfirmBox, loading }) => (
  <div>
    <div className="d-flex" style={{ justifyContent: 'space-between' }}>
      <ItemDisplay item={item} />
      <ShowForRoles accepted={['organization_admin']}>
        <div>
          <Media right className="mr-2">
            <Button role="button" size="sm" color="danger" onClick={openConfirmBox}>
              {loading === 'remove' ? <Spinner /> : <FontAwesome name="times" />}
            </Button>
          </Media>
          <ConfirmBox
            isOpen={isOpen}
            avoidConfirmBox={toogleConfirmBox}
            closeConfirmBox={closeConfirmBox}
            removeTag={remove}
            type="artículo"
            resource={item.name}
          />
        </div>
      </ShowForRoles>
    </div>
    <hr />
  </div>
);

export const ItemCard = enhance(ItemCardPresentation);
