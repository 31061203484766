import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';

export const TaskSubmitModal = ({
  openConfirmSubmit,
  toogleConfirmSubmit,
  setEstimatedHours,
  closeConfirmSubmit,
  createTask,
  estimatedHours,
  pristine,
  invalid,
}) => (
  <Modal isOpen={openConfirmSubmit} toggle={toogleConfirmSubmit} size="md">
    <ModalHeader>Confirmación</ModalHeader>
    <ModalBody>
      <p>Esta petición no presenta subtareas, por favor, especifique las horas de trabajo estimadas para esta tarea</p>
      <Input
        name="estimatedHours"
        placeholder="HH"
        label="Horas estimadas"
        pattern="[0-9]+"
        onChange={setEstimatedHours}
      />
    </ModalBody>
    <ModalFooter>
      <div className="text-right">
        <Button role="button" onClick={closeConfirmSubmit}>
          Cancelar
        </Button>{' '}
        <Button role="button" color="primary" onClick={createTask} disabled={pristine || invalid || !estimatedHours}>
          Continuar
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);
