import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ opened, refresh, className }) => (
  <Modal isOpen={opened} className={className} backdrop="static" keyboard={false} size="md">
    <ModalHeader>Error de conexión</ModalHeader>
    <ModalBody>
      <p>
        Se ha perdido la conexión con el servidor. Esto puede deberse a que no te encuentras conectado a Internet, o
        bien que el servicio no se encuentra disponible en este momento.
      </p>
      <p>
        Por favor, <NavLink to="/contact">contáctanos</NavLink> si es que los problemas persisten.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button role="button" color="secondary" onClick={refresh}>
        Reiniciar
      </Button>
    </ModalFooter>
  </Modal>
);
