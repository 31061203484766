import { apiFetch, handleAuth, withToken, mountQuery } from './http';

export const getUnits = ({ query } = {}) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/units${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getUnit = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/units/${id}`,
      method: 'GET',
    }),
  );
