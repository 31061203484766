import { getMovements } from '@core/building/movements';
import { getUser } from '@core/building/users';
import { shortName } from '@core/utils/names';
import { simpleFilterQuery, Spinner } from '@shared';
import { UserDisplay } from '@shared/displays';
import { MovementsTable } from '@shared/movements';
import { connect, deskSessionGenerators } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody } from 'reactstrap';
import { compose, lifecycle, withHandlers } from 'recompose';

const movementsQty = 10;

const Loading = () => (
  <div className="mt-4 mb-4 text-primary h3 text-center">
    <Spinner />
  </div>
);

const enhance = compose(
  connect(
    ({ deskSession: { currentClient, clientData, clientLatestMovements } }) => ({
      currentClient,
      clientData,
      clientLatestMovements,
    }),
    (dispatch) => ({
      setClientData(data) {
        return dispatch(deskSessionGenerators.setClientData(data));
      },
      setClientLatestMovements(data) {
        return dispatch(deskSessionGenerators.setClientLatestMovements(data));
      },
    }),
  ),
  withHandlers({
    getClientData: (props) => async () => {
      const { currentClient, setClientData } = props;

      const { body, status } = await getUser(currentClient.id);

      if (status === 200) {
        setClientData(body);
      }
    },
    getLatestMovements: (props) => async () => {
      const { currentClient, setClientLatestMovements } = props;

      const { body, status } = await getMovements({
        client_type: currentClient.type,
        clients: currentClient.id,
        pp: movementsQty,
      });

      if (status === 200) {
        setClientLatestMovements(body);
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      const { getClientData, getLatestMovements } = this.props;

      getClientData();
      getLatestMovements();
    },
  }),
);

const UserBlockPresentation = ({ clientData, clientLatestMovements, currentClient }) => (
  <div>
    <Card className="mb-4">
      <CardBody>
        <div className="text-muted mb-3">Cliente actual</div>
        {!clientData && <Loading />}

        {clientData && <UserDisplay user={clientData} />}
      </CardBody>
    </Card>
    <Card>
      <CardBody>
        <div className="text-muted mb-3">Últimos movimientos</div>
        {!clientLatestMovements && <Loading />}

        {clientLatestMovements && (
          <div className="desk-movements">
            <MovementsTable movements={clientLatestMovements} showInfo={false} header={false} />
          </div>
        )}

        {clientLatestMovements && clientLatestMovements.length > movementsQty && clientData && (
          <Button
            role="button"
            color="primary"
            outline
            block
            tag={NavLink}
            exact
            to={`/movements?q=${simpleFilterQuery({
              client_type: currentClient.type,
              'clients[{}]': [{ label: shortName(clientData), value: clientData.id }],
            })}`}
          >
            Ver todos los movimientos de {shortName(clientData)}
          </Button>
        )}
      </CardBody>
    </Card>
  </div>
);

export const UserBlock = enhance(UserBlockPresentation);
