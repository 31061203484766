import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';
import KitResourceRow from './KitResourceRow';
import { getKitResources } from '@core/building/kit-resources';
import { Spinner, FontAwesome, ShowForRoles } from '@shared';

const enhance = compose(
  withState('kitResources', 'setKitResources', undefined),
  lifecycle({
    async componentDidMount() {
      const { kit, setKitResources } = this.props;

      const { body, status } = await getKitResources(kit.id);
      if (status === 200) {
        setKitResources(body);
      }
    },
  }),
  branch(
    ({ kitResources }) => !kitResources,
    renderComponent(() => (
      <div className="mt-4 mb-4 text-info text-center h2">
        <Spinner />
      </div>
    )),
  ),
);

class KitResources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      editing: false,
    };
  }

  onDelete = (index) => {
    const { setKitResources, kitResources } = this.props;
    const filterKitResources = kitResources.filter((item, i) => index !== i);
    setKitResources(filterKitResources);
    this.props.onChangeResource('delete', kitResources[index]);
  };

  onAdd = (newKit) => {
    this.props.onChangeResource('add', newKit);
  };

  addNew = () => {
    const { setKitResources, kitResources } = this.props;
    new setKitResources([
      {
        key: `n_${kitResources.length + 1}-${Date.now()}`,
        quantity: null,
        resourceType: '',
        resourceId: null,
      },
      ...kitResources,
    ]);
    this.toggleEditing();
  };

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  render() {
    const { kit, kitResources } = this.props;

    return (
      <div>
        <ShowForRoles excluded={['guest']}>
          <div className="float-right">
            <Button size="sm" outline color="success" role="button" onClick={this.addNew} disabled={this.state.editing}>
              <FontAwesome name="plus" /> Agregar Recurso
            </Button>
          </div>
        </ShowForRoles>
        <h4 className="mb-4">Recursos</h4>
        <div>
          {kitResources.length === 0 && (
            <div colSpan="4" className="text-center">
              <em>No hay recursos creados de momento.</em>
            </div>
          )}
          {kitResources.map((kitResource, index) => (
            <KitResourceRow
              index={index}
              key={kitResource.id || kitResource.key}
              kitResource={kitResource}
              kitId={kit.id}
              kitKind={kit.kind}
              onDelete={this.onDelete}
              toggleEditing={this.toggleEditing}
              editing={this.state.editing}
              onAdd={this.onAdd}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default enhance(KitResources);
