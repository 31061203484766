// @flow

import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Table, Badge, Media, Form, FormGroup, Label } from 'reactstrap';
import Toggle from 'react-toggle';

import { getConsumables } from '../../../@core/building/reports';
import { PictureDisplay } from '../../../@shared';
import { Highcharts } from '../../../@shared/charts';

import Chart from './Chart';

type State = {
  list: boolean,
  data: Object[],
};

const getChartOptions = (title, data) => {
  return {
    title: {
      text: title,
    },
    credits: {
      enabled: true,
      href: 'http://www.alliot.cloud',
      text: 'alliot.cloud',
    },
    chart: {
      zoomType: 'x',
      type: 'column',
    },
    tooltip: {
      shared: true,
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
      },
    },
    xAxis: {
      categories: data.map((item) => item.name),
    },
    yAxis: {
      title: {
        text: 'Cantidad',
      },
    },
    series: [
      {
        name: 'Disponibles',
        data: data.map((item) => item.inventory.availableQuantity),
      },
      {
        name: 'Reservados',
        data: data.map((item) => item.inventory.reservedQuantity),
        color: 'rgba(248,161,63,1)',
        pointPadding: 0.3,
        pointPlacement: 0,
      },
    ],
  };
};

class Consumable extends React.Component<*, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      list: false,
      data: [],
    };
  }

  async componentDidMount() {
    const response = await getConsumables();
    this.handleResponse(response);
  }

  handleResponse = (response: Response) => {
    const { status, body } = response;

    if (status === 200) {
      this.setState({
        data: body,
      });
    }
  };

  toggleList = (e: any) => {
    const { checked } = e.target;

    this.setState({
      list: checked,
    });
  };

  render() {
    const { title } = this.props;
    const { data, list } = this.state;

    let Cmp;
    if (list) {
      Cmp = ItemTable;
    } else {
      Cmp = ConsumableChart;
    }

    return (
      <Card>
        <CardHeader className="chart-title-container">
          <span className="float-right">
            <span
              style={{
                display: 'flex',
                verticalAlign: 'middle',
              }}
            >
              <Form inline>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label className="mr-sm-2">Listado</Label>
                  <Toggle checked={list} onChange={this.toggleList} value="yes" />
                </FormGroup>
              </Form>
            </span>
          </span>
          <h4>Consumibles</h4>
        </CardHeader>
        <CardBody className={list ? 'no-pad' : 'p-2'} style={{ minHeight: '416px' }}>
          <Cmp data={data} title={title} />
        </CardBody>
      </Card>
    );
  }
}

const ConsumableChart = ({ loaded, data, title }) => (
  <Chart options={getChartOptions(title, data)} highcharts={Highcharts} />
);

const ItemRow = ({ item }) => (
  <tr key={item.barcode1}>
    <td style={{ width: '70%' }} className="align-middle text-overflow">
      <Media className="align-items-center pl-2" tag={NavLink} to={`/organization/items/${item.barcode1}`}>
        <Media left className="mr-1" tag="div">
          <PictureDisplay picture={item.defaultPicture} pictureSize="icon" alt={item.name} width="3rem" height="2rem" />
        </Media>
        <Media body tag="div">
          <div>{item.name}</div>
          <div>
            <code>{item.barcode1}</code>
          </div>
        </Media>
      </Media>
    </td>
    <td style={{ width: '15%', paddingTop: '0.9rem' }} className="align-middle text-center">
      <Badge
        color={
          item.inventory.availableQuantity >= 100
            ? 'success'
            : item.inventory.availableQuantity >= 50
            ? 'warning'
            : 'danger'
        }
        pill
      >
        <span>{item.inventory.availableQuantity}</span>
      </Badge>
    </td>
    <td style={{ width: '15%', paddingTop: '0.9rem' }} className="align-middle text-center">
      <Badge color="success" pill>
        <span>{item.inventory.reservedQuantity || 0}</span>
      </Badge>
    </td>
  </tr>
);

const ItemTable = ({ data }) => (
  <Table size="sm" className="table-scroll">
    <thead>
      <tr>
        <th style={{ width: '70%' }} className="pl-3">
          Nombre
        </th>
        <th style={{ width: '15%' }} className="text-center">
          Disponible
        </th>
        <th style={{ width: '15%' }} className="text-center">
          Reservado
        </th>
      </tr>
    </thead>
    <tbody>
      {data.map((item) => (
        <ItemRow item={item} />
      ))}
    </tbody>
  </Table>
);

export default Consumable;
