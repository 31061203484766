import React from 'react';
import { Route as NavRoute, Switch } from 'react-router-dom';

import { ProtectedRoute as NavProtectedRoute, NotFound } from '@shared';

export const Route = ({ component, ...props }) => <NavRoute {...props} component={component} />;

export const ProtectedRoute = ({ component, ...props }) => <NavProtectedRoute component={component} {...props} />;

export const SwitchWithFallback = ({ children }) => (
  <Switch>
    {children}
    {/* This handles 404s */}
    <Route component={NotFound} />
  </Switch>
);
