import { FontAwesome } from '@shared/';
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const PublishAssetsDisclaimerModal = ({ isOpen, closeModalDisclaimer }) => (
  <Modal isOpen={isOpen} size="md">
    <ModalHeader>
      Advertencia <FontAwesome name="exclamation-triangle" color />
    </ModalHeader>
    <ModalBody>
      <p>¿Estás seguro de que deseas hacer públicos los activos de la instalación?</p>
      <p>
        Esta acción permitirá a terceros ver la información asociada a los activos, únicamente con fines de consulta.
      </p>
    </ModalBody>
    <ModalFooter>
      <div className="text-right">
        <Button role="button" onClick={closeModalDisclaimer}>
          Cerrar
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);

export default PublishAssetsDisclaimerModal;
