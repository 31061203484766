import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { Alert, Button, CardText } from 'reactstrap';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Field, Form, SubmissionError } from '@core/forms';
import Validates, { passwordsMatching, presence } from '@core/forms/validations';
import { checkLockToken, unlockAccount } from '@core/password';
import { connect, sessionGenerators } from '@store';
import { Loading, ProcessingButton } from '@shared';
import { SimpleContainerLayout } from '@shared/layouts';

class Unlock extends Component {
  state = {
    name: '',
    loading: true,
    notFound: false,
    success: false,
    errors: {},
  };

  async componentDidMount() {
    const token = this.props.match.params.token;

    const { status, body } = await checkLockToken({ token });

    switch (status) {
      case 200:
      case 204:
        this.setState({
          loading: false,
          notFound: false,
          name: body.firstName.split(' ')[0],
        });
        break;
      default:
        this.setState({
          loading: false,
          notFound: true,
        });
        break;
    }
  }

  unlock = async (formData) => {
    const token = this.props.match.params.token;
    const { password, passwordConfirmation } = formData;

    const { status, body } = await unlockAccount({
      token,
      password,
      passwordConfirmation,
    });

    if (status === 200) {
      this.props.dispatchLogin(body);

      this.setState({
        success: true,
      });
    } else if (status === 404) {
      this.setState({
        notFound: true,
      });
    } else if (status === 422) {
      this.setState({
        success: false,
      });

      throw new SubmissionError(body.errors);
    }
  };

  render() {
    if (this.state.notFound) return <Redirect to="/account/login" />;

    if (this.state.success) return <UnlockSuccess name={this.state.name} />;

    return (
      <UnlockForm
        loading={this.state.loading}
        onSubmit={this.unlock}
        name={this.state.name}
        processing={this.state.processing}
        errors={this.state.errors}
      />
    );
  }
}

const validate = Validates(
  presence({ of: 'password' }),
  presence({ of: 'passwordConfirmation' }),
  passwordsMatching('password', 'passwordConfirmation'),
);

const UnlockFormPresentation = ({ handleSubmit, invalid, submitting, pristine, loading, name, error }) => {
  if (loading) return <Loading />;

  return (
    <div className="mt-4 mb-4">
      <SimpleContainerLayout title={`Casi listo, ${name}`}>
        <Alert color="warning" className="text-center">
          Por razones de seguridad, debes definir una nueva contraseña antes de desbloquear tu cuenta.
        </Alert>

        <Form error={error} noValidate onSubmit={handleSubmit}>
          <Field label="Contraseña" name="password" type="password" required />

          <Field label="Confirmar contraseña" name="passwordConfirmation" type="password" required />

          <div className="text-center">
            <ProcessingButton
              type="submit"
              role="button"
              color="primary"
              outline
              disabled={pristine || invalid}
              processing={submitting}
              width="10em"
            >
              Actualizar
            </ProcessingButton>
          </div>
        </Form>
      </SimpleContainerLayout>
    </div>
  );
};

const UnlockForm = reduxForm({
  form: 'unlockForm',
  validate,
})(UnlockFormPresentation);

export const UnlockSuccess = ({ name }) => (
  <div className="mt-4 mb-4">
    <SimpleContainerLayout title={`¡Listo, ${name}!`}>
      <CardText className="text-center">
        Tu contraseña ha sido actualizada exitosamente. Desde ahora puedes ingresar con tu dirección de correo y la
        contraseña que acabas de escribir.
      </CardText>

      <div className="text-center">
        <Button tag={NavLink} to="/" color="primary">
          Continuar
        </Button>
      </div>
    </SimpleContainerLayout>
  </div>
);

export default connect(null, (dispatch) => ({
  dispatchLogin: (token) => dispatch(sessionGenerators.login(token)),
}))(Unlock);
