import React from 'react';
import { Badge, Media } from 'reactstrap';

import { getAsset, getAssets } from '@core/organization/assets';
import { RemoteSelector } from '@core/forms';
import { connect } from '@store';
import { PictureDisplay } from '@shared';

const AssetTemplatePresentation = ({ computedStates, resource: asset }) => {
  const computed = computedStates[asset.availability];

  return (
    <Media className="align-items-center mt-1 mb-1">
      <Media left className="mr-3">
        <PictureDisplay
          picture={asset.item.defaultPicture}
          pictureSize="icon"
          alt={asset.item.name}
          width="6rem"
          height="4rem"
          imgClassName={`status-border status-border-${asset.item.active ? 'active' : 'inactive'}`}
        />
      </Media>
      <Media body>
        <div className="mb-1">
          <strong>{asset.item.name}</strong>{' '}
          {asset.isRental && (
            <Badge color="warning" className="mr-1">
              En arriendo
            </Badge>
          )}
          {asset.item.restricted && <Badge color="warning">Restringido</Badge>}
        </div>
        <div className="small">
          <kbd>{asset.barcode1}</kbd> - Rótulo: {asset.mark}
        </div>
        <div className="small">
          Disponibilidad: <span className={`text-${computed?.color}`}>{computed?.name}</span>
        </div>
        <div className="small">
          Instalación: <strong>{asset?.building?.name}</strong>
        </div>
      </Media>
    </Media>
  );
};

export const AssetTemplate = connect(({ appData: { computed_states: computedStates } }) => ({
  computedStates,
}))(AssetTemplatePresentation);

const AssetOrganizationSelector = ({
  resourceKey = 'barcode1',
  filters,
  inputProps,
  remoteLoader = getAsset,
  remoteFinder = getAssets,
  ...otherProps
}) => (
  <RemoteSelector
    {...otherProps}
    resourceKey={resourceKey}
    remoteLoader={remoteLoader}
    remoteFinder={(query) => remoteFinder({ search: query, ...filters })}
    dropdownTemplate={AssetTemplate}
    inputProps={{ placeholder: 'Escanear o digitar código', ...inputProps }}
    minLength={4}
    disabledDropdown={(resource) => {
      return !resource.active;
    }}
  />
);

export default AssetOrganizationSelector;
