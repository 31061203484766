/**
 * Object for handling chilean tax identities (RUT).
 *
 * @class ChileanRUT
 */
class ChileanRUT {
  static CleanFormatRegex = /^(\d{0,3})(\d{3})(\d{3})(\d|k{1})$/gi;
  static DashedFormatRegex = /^(\d{0,3})(\d{3})(\d{3})-(\d|k{1})$/gi;
  static FullFormatRegex = /^(\d{0,3})\.(\d{3})\.(\d{3})-(\d|k{1})$/gi;

  static PartialFormat = function (identifier) {
    if (!identifier || typeof identifier !== 'string') return undefined;

    identifier = identifier.replace(/[^\dk]/gi, '');

    if (identifier.length < 2) return identifier;

    let [dv, ...digits] = identifier.split('').reverse();

    let digitsGroups = [];

    while (digits.length > 0) digitsGroups.push(digits.splice(0, 3).join(''));

    return [digitsGroups.join('.').split('').reverse().join(''), dv].join('-');
  };

  /**
   * Creates a new ChileanRUT instance from a String.
   * This method only parses strings from the following formats:
   *
   * -  XXX.XXX.XXX-X (Full Format)
   * -  XXXXXXXXX-X (Dashed Format)
   * -  XXXXXXXXXX (Clean Format)
   *
   * Any other format will return a ChileanRUT object whose isValid() method
   * is always false.
   *
   * @param {any} inputString String which is going to be parsed into ChileanRUT.
   *
   * @memberof ChileanRUT
   */
  constructor(inputString) {
    this.actualString = inputString;

    let regex;

    if (ChileanRUT.CleanFormatRegex.test(inputString)) regex = ChileanRUT.CleanFormatRegex;
    else if (ChileanRUT.DashedFormatRegex.test(inputString)) regex = ChileanRUT.DashedFormatRegex;
    else if (ChileanRUT.FullFormatRegex.test(inputString)) regex = ChileanRUT.FullFormatRegex;

    if (regex === undefined) return this;

    regex.lastIndex = 0;

    let [, group1, group2, group3, vd] = regex.exec(inputString);

    this.group1 = group1;
    this.group2 = group2;
    this.group3 = group3;

    this.vd = vd.toLowerCase();

    this.digits = [group1, group2, group3].join('');

    return this;
  }

  /**
   * Gets the actual input parsed in the constructor.
   *
   * @returns String typed in the constructor.
   *
   * @memberof ChileanRUT
   */
  toString() {
    return this.actualString;
  }

  /**
   * Check if the chilean RUT is valid using the Modulo 11 algorithm.
   *
   * Will always return false if pasing of the RUT failed.
   *
   * @returns A boolean indicating if the RUT is valid.
   *
   * @memberof ChileanRUT
   */
  isValid() {
    if (!this.group1 || !this.group2 || !this.group3 || !this.vd) return false;

    return this.expectedVerificatorDigit() === this.actualVerificatorDigit();
  }

  /**
   * Returns the expected verificator digit for a valid RUT. Used in `isValid()`.
   *
   * @returns The expected verificator digit. Returns `undefined` if the digits are invalid
   * or the input string of the constructor couldn't be parsed.
   *
   * @memberof ChileanRUT
   */
  expectedVerificatorDigit(digits) {
    digits = this.digits;

    if (digits === undefined || digits === null) return undefined;

    if (typeof digits !== 'string') digits = digits.toString();

    digits = digits.split('').map((digit) => Number(digit));

    const cyclicArray = [2, 3, 4, 5, 6, 7];

    let expectedVerificatorDigit =
      11 - (digits.reverse().reduce((s, digit, index) => s + digit * cyclicArray[index % cyclicArray.length], 0) % 11);

    switch (expectedVerificatorDigit) {
      case 11:
        return '0';
      case 10:
        return 'k';
      default:
        return expectedVerificatorDigit.toString();
    }
  }

  /**
   * Actual verification digit of the format. Allows to return the digit in uppercase.
   *
   * @param {boolean} [uppercase=false] Show letters in uppercase?
   * @returns Actual typed verification digit.
   *
   * @memberof ChileanRUT
   */
  actualVerificatorDigit(uppercase = false) {
    return uppercase ? this.vd.toUpperCase() : this.vd;
  }

  /**
   * From the current ChileanRUT object generates a full formatted string. With points and dashes.
   * Example: "32.456.234-6".
   *
   * @param {boolean} [uppercase=false] Show letters in uppercase?
   * @returns Full-formatted ChileanRUT string.
   *
   * @memberof ChileanRUT
   */
  fullFormat(uppercase = false) {
    return [[this.group1, this.group2, this.group3].join('.'), this.actualVerificatorDigit(uppercase)].join('-');
  }

  /**
   * From the current ChileanRUT object generates a dashed formatted string. Without points and with the dash.
   *
   * @param {boolean} [uppercase=false] Show letters in uppercase?
   * @returns Dashed-formatted ChileanRUT string.
   *
   * @memberof ChileanRUT
   */
  dashedFormat(uppercase = false) {
    return [[this.group1, this.group2, this.group3].join(''), this.actualVerificatorDigit(uppercase)].join('-');
  }

  /**
   * From the current ChileanRUT object generates a clean formatted string. Without points and dashes.
   * Example: "324562346"
   *
   * @param {boolean} [uppercase=false] Show letters in uppercase?
   * @returns Clean formatted ChileanRUT string.
   *
   * @memberof ChileanRUT
   */
  cleanFormat(uppercase = false) {
    return [this.group1, this.group2, this.group3, this.actualVerificatorDigit(uppercase)].join('');
  }
}

export default ChileanRUT;
