import React from 'react';
import { compose, onlyUpdateForKeys, withHandlers } from 'recompose';
import type { HOC } from 'recompose';

import DefaultHeaderColumn from './DefaultHeaderColumn.js';
import type { ColumnMetadata } from './types';

// FIXME: Don't pass data

type Props = {
  checkAll: boolean,
  columnMetadata: ColumnMetadata[],
  data: Object[],
  onChangeGrid: Function,
  resultsOnPage: number,
  selectedRows: Object,
  showCheckbox: boolean,
  showHeader: boolean,
  sortColumn: string,
  sortDirection: string,
};

const enhance: HOC<*, Props> = compose(
  onlyUpdateForKeys([
    'checkAll',
    'columnMetadata',
    'data',
    'onChangeGrid',
    'resultsOnPage',
    'selectedRows',
    'showCheckbox',
    'showHeader',
    'sortColumn',
    'sortDirection',
  ]),
  withHandlers({
    handleOnCheckAll: ({ checkAll, data, onChangeGrid, resultsOnPage, selectedRows }) => () => {
      let selected = data;
      console.log(checkAll, data, onChangeGrid, resultsOnPage, selectedRows);

      if (!checkAll) {
        for (let i = 0; i < data.length; i++) {
          selected[i] = {
            rowId: i,
            data: data[i],
          };
        }
      } else {
        selected = {};
      }

      onChangeGrid({
        checkAll: !checkAll,
        selectedRows: selected,
      });
    },
    handleOnHeaderClick: ({ onChangeGrid }) => (data: Object) => {
      onChangeGrid(data);
    },
  }),
);

const GridHeader = ({
  // props
  columnMetadata,
  showCheckbox,
  checkAll,
  showHeader,
  sortColumn,
  sortDirection,
  // handlers
  handleOnCheckAll,
  handleOnHeaderClick,
}) => {
  if (!showHeader) {
    return null;
  }

  return (
    <thead>
      <tr>
        {showCheckbox && (
          <th style={{ textAlign: 'center' }}>
            <input
              type="checkbox"
              className="checkboxContainer"
              checked={checkAll}
              value={checkAll}
              onClick={handleOnCheckAll}
            />
          </th>
        )}
        {columnMetadata.map((column, colIndex) => (
          <DefaultHeaderColumn
            key={`hcolumn-${colIndex}`}
            metatada={column}
            onHeaderClick={handleOnHeaderClick}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
          />
        ))}
      </tr>
    </thead>
  );
};

export default enhance(GridHeader);
