import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fromIso8601 } from '@core/utils/dates';
import { FormGroup, Label, Input } from 'reactstrap';
import { DateTimeSelector } from '@shared/forms';
import { ProcessingButton } from '@shared';

export const TaskPosponeModal = function ({ task, toggle, isOpen, postponeTask }) {
  const [posponeReason, setPosponeReason] = useState(undefined);
  const [posponeDate, setPosponeDate] = useState(undefined);
  const cleanAndClose = () => {
    setPosponeReason(undefined);
    setPosponeDate(undefined);
    toggle();
  };
  const saveAndClose = (taskId, posponeReason, posponeDate) => {
    postponeTask(taskId, posponeReason, posponeDate);
    toggle();
  };
  return (
    <Modal isOpen={isOpen} toggle={cleanAndClose}>
      <ModalHeader toggle={cleanAndClose}>Posponer Tarea</ModalHeader>
      <ModalBody>
        <h5>Título: {task.title}</h5>
        <p>
          Plazo original: <code>{fromIso8601(task.dueDate, false)}</code>
        </p>
        <hr />
        <FormGroup className="mt-2">
          <Label for="exampleEmail">Nuevo plazo:</Label>
          <DateTimeSelector
            minDate={new Date()}
            value={posponeDate}
            onChange={setPosponeDate}
            showTimeSelect={false}
            placeholder="Nuevo Plazo"
          />
        </FormGroup>
        <FormGroup className="mt-2">
          <Label for="exampleEmail">Motivo:</Label>
          <Input
            style={{ height: '10em' }}
            value={posponeReason}
            onChange={({ target }) => setPosponeReason(target.value)}
            type="textarea"
            name="email"
            id="exampleEmail"
            placeholder="Motivo para posponer la tarea..."
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button role="button" onClick={cleanAndClose}>
          Cerrar
        </Button>
        <ProcessingButton
          type="submit"
          role="button"
          outline
          color="primary"
          width="7em"
          onClick={() => saveAndClose(task.id, posponeReason, posponeDate)}
          // disabled={pristine || invalid}
          // processing={submitting}
        >
          Guardar
        </ProcessingButton>
      </ModalFooter>
    </Modal>
  );
};
