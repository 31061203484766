import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, PictureDisplay, ShowForRoles, Spinner } from '@shared';
import React from 'react';
import { Button, ButtonGroup, Card, CardBody, Input, InputGroup, InputGroupButtonDropdown, Media } from 'reactstrap';

const PictureCard = ({
  changeTitle,
  onDelete,
  picture,
  savingTitle,
  setDefault,
  settingTarget,
  setTitle,
  toggleEditing,
  loadingPicture,
}) => (
  <Card className="mb-3">
    <CardBody>
      <Media className="align-items-center">
        <Media left className="mr-3">
          <PictureDisplay picture={picture} alt={picture.title || 'Sin título'} />
        </Media>
        <Media body>
          <div className="mb-1">
            {picture.$editing && (
              <form noValidate onSubmit={setTitle}>
                <InputGroup>
                  <Input
                    size="sm"
                    name="editTitle"
                    className=""
                    placeholder="Escribir un título"
                    onChange={changeTitle}
                    value={picture.title || ''}
                  />

                  <InputGroupButtonDropdown>
                    <Button role="button" type="submit" size="sm" outline color="success">
                      <FontAwesome name="check" />
                    </Button>
                  </InputGroupButtonDropdown>
                </InputGroup>
              </form>
            )}

            {!picture.$editing && (
              <div>
                {picture.title || <em>Sin título</em>}{' '}
                {savingTitle === picture.id && (
                  <span className="text-info">
                    <Spinner />
                  </span>
                )}
                <ShowForRoles excluded={['guest']}>
                  {savingTitle !== picture.id && (
                    <span
                      role="button"
                      className="ml-1 text-primary"
                      onClick={() => !settingTarget && toggleEditing(picture.id)}
                    >
                      <FontAwesome name="pencil-alt" />
                    </span>
                  )}
                </ShowForRoles>
              </div>
            )}
          </div>
          <div className="small text-muted">Cargada: {fromIso8601(picture.createdAt)}</div>
        </Media>
        <Media className="ml-4" right>
          {settingTarget === picture.id && <Spinner />}
          {picture.isDefault && <FontAwesome className="mr-2" color="success" size="2x" name="check-circle" />}
          {!picture.isDefault && (
            <ShowForRoles excluded={['guest']}>
              <ButtonGroup>
                <Button
                  role="button"
                  color="success"
                  size="sm"
                  disabled={picture.isDefault || loadingPicture}
                  onClick={() => setDefault(picture.id)}
                >
                  <FontAwesome name="check" />
                </Button>
                <Button
                  role="button"
                  color="danger"
                  size="sm"
                  disabled={!!settingTarget || picture.$deleting || loadingPicture}
                  onClick={() => onDelete(picture.id)}
                >
                  <FontAwesome name="times" />
                </Button>
              </ButtonGroup>
            </ShowForRoles>
          )}
        </Media>
      </Media>
    </CardBody>
  </Card>
);

export default PictureCard;
