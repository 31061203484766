import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const getAssetDiscards = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_discards${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAssetDiscard = (assetDiscardId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_discards/${assetDiscardId}`,
      method: 'GET',
    }),
  );

export const getAssetDiscardStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_discards/stats`,
      method: 'GET',
    }),
  );
