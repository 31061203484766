import React, { forwardRef } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { Field as ReduxFormField } from 'redux-form';

import classes from '@core/utils/classes';

// Errors to be shown for a single field
const Errors = ({ errors }) => {
  if (Array.isArray(errors))
    return (
      <ul className="invalid-feedback">
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    );
  else return <div className="invalid-feedback">{errors}</div>;
};

const FieldTemplate = ({
  label,
  type,
  input,
  inputComponent,
  meta,
  meta: { autofilled, dirty, initial, touched, valid, error },
  help,
  required,
  options,
  componentOnChange,
  inputProps,
  formGroupClassName,
  customRef,
  ...otherProps
}) => {
  // When to display the validation
  const shouldDisplayValidation = autofilled || !!initial || touched || dirty;

  // Classes to be shown in the formGroup
  const formGroupClasses = classes({
    'has-success': shouldDisplayValidation && valid,
    'has-danger': shouldDisplayValidation && error,
  });

  // Classes to be shown in the input
  const inputClasses = classes({
    'is-valid': shouldDisplayValidation && valid,
    'is-invalid': shouldDisplayValidation && error,
  });

  // Let's prepare the component to be used.
  // Default is reactstrap's input.
  const FieldComponent = inputComponent || Input;

  // We're using check layout if type is checkbox or radio
  const useCheckLayout = type === 'checkbox' || type === 'radio';

  // Prepare options if type is select
  const optionsContent =
    type === 'select' &&
    options &&
    Object.keys(options).map((option, index) => (
      <option key={index} value={option}>
        {options[option]}
      </option>
    ));

  const finalOnChange = (...args) => {
    input.onChange && input.onChange(...args);
    componentOnChange && componentOnChange(...args);
  };

  const field = (
    <FieldComponent
      {...(type ? { type } : {})}
      id={input.name}
      className={inputClasses}
      {...(optionsContent ? { children: optionsContent } : {})}
      {...input}
      {...otherProps}
      onChange={finalOnChange}
      {...(useCheckLayout ? { checked: input.value, value: undefined } : {})}
      {...(inputComponent ? { input: { ...input, onChange: finalOnChange }, inputProps, meta } : {})}
      innerRef={customRef}
    />
  );

  const formGroupClass = [formGroupClasses, formGroupClassName || ''].filter((className) => !!className).join(' ');

  if (type === 'hidden') return field;

  return (
    <FormGroup className={formGroupClass} check={useCheckLayout}>
      {required && <span className="float-right small text-muted">Requerido</span>}

      {useCheckLayout && (
        <div>
          <Label for={input.name} className="form-control-label" check>
            {field} {label}
          </Label>
          {shouldDisplayValidation && error && <Errors errors={error} />}
          {(!shouldDisplayValidation || !error) && help && <div className="form-text text-muted">{help}</div>}
        </div>
      )}

      {!useCheckLayout && (
        <div>
          {label && (
            <Label for={input.name} className="form-control-label">
              {label}
            </Label>
          )}
          {field}
          {shouldDisplayValidation && error && <Errors errors={error} />}
          {(!shouldDisplayValidation || !error) && help && <div className="form-text text-muted">{help}</div>}
        </div>
      )}
    </FormGroup>
  );
};

// Let's wrap redux-form's field with our component
const Field = forwardRef(({ component, normalizeCheckbox = true, ...props }, ref) => (
  <ReduxFormField
    {...props}
    normalize={(v) => (normalizeCheckbox && props.type === 'checkbox' ? !!v : v)}
    component={FieldTemplate}
    inputComponent={component}
    customRef={ref}
  />
));

export default Field;
