import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import { AssetCellDisplay } from '../../../../organization/tasks/components/TaskRow';
import React, { Component } from 'react';
import { Card, CardHeader, CardBody, Col, Container, Row, Button, Input, Collapse } from 'reactstrap';
import { connect, store } from '@store';
import { NavLink } from 'react-router-dom';

import './TaskInfo.scss';

const TaskControllerModal = ({ task, subtask, onCompleteSubtask, loading }) => (
  <div>
    <Row>
      <Col sm={6} className="mt-1">
        <FontAwesome
          name={subtask.completedAt ? 'check-circle' : ['far', 'circle']}
          color={subtask.completedAt ? 'success' : 'primary'}
          className="align-middle"
        />{' '}
        {subtask.title}
      </Col>
      <Col sm={2}>{`${subtask.estimatedHours} HH`}</Col>
      <Col sm={2}>
        {!subtask.completedAt && (
          <Input
            label="HH"
            disabled={subtask.completedAt}
            placeholder="HH"
            value={subtask.effectiveHours}
            onChange={(e) => (subtask.effectiveHours = +e.target.value)}
            size="sm"
            required
          />
        )}
        {subtask.completedAt && `${subtask.effectiveHours} HH`}
      </Col>
      <Col sm={2}>
        <Button
          onClick={() => onCompleteSubtask(subtask)}
          color="primary"
          disabled={subtask.completedAt || task.completedAt || loading}
          size="sm"
        >
          {subtask.completedAt ? 'Completada' : 'Completar'}
        </Button>
      </Col>
    </Row>
    <hr className="mb-1 mt-1" />
  </div>
);

class TaskInfoModalPresentation extends Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    console.log(this.state.isOpen);
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  isOrganizationAdmin = () => {
    const { session } = store.getState();
    return (
      session.user?.memberships.filter((membership) => membership.id === session.user.currentMembership)[0]?.role ===
      'organization_admin'
    );
  };

  goToMro = () => {
    console.log('MRO');
  };

  render() {
    const {
      task,
      onCompleteSubtask,
      onComplete,
      openTaskPospone,
      openTaskCancel,
      openConfirmBox,
      openConfirmMROModal,
      onStar,
      loading,
    } = this.props;
    const { isOpen } = this.state;
    const { session } = store.getState();

    return (
      <Container>
        <Row>
          <Col className="mb-3">
            <h5>
              <strong>{task.canceledAt ? <del>{task.title}</del> : task.title}</strong>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>
              <strong>Descripción</strong>
            </h6>
          </Col>
          <Col>
            <p>{task.description ? task.description : 'Sin descripción'}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>
              <strong>Frecuencia</strong>
            </h6>
          </Col>
          <Col>
            <p>{task.frequencyLabel}</p>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <h6>
              <strong>Fecha</strong>
            </h6>
          </Col>
          <Col>
            <Row>
              <Col sm={4}>
                <p>
                  <FontAwesome name="calendar-day" color="primary" /> {fromIso8601(task.startDate, false)}
                </p>
              </Col>
              <Col sm={4}>
                <p>
                  <FontAwesome name="calendar-day" color="primary" /> {fromIso8601(task.dueDate, false)}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        {task.frequencyLabel && (
          <Row>
            <Col>
              <h6>
                <strong>Periodicidad</strong>
              </h6>
            </Col>
            <Col>
              <p>{task.frequencyLabel}</p>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <h6>
              <strong>Responsables</strong>
            </h6>
          </Col>
          <Col>
            <p>
              {task.members &&
                task.members.map((member, key) => (
                  <span key={`member-${key}`}>
                    {member.firstName} {member.lastName}
                    {key + 1 < task.members.length ? ', ' : ''}
                  </span>
                ))}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>
              <strong>Activos</strong>
            </h6>
            <Row>
              <Col className="mb-3">{task.asset ? <AssetCellDisplay asset={task.asset} /> : 'No tiene activos'}</Col>
            </Row>
          </Col>
        </Row>
        {task.integration && (
          <Row>
            <Col>
              <h6>
                <strong>Integración</strong>
              </h6>
              <Row>
                <Col>
                  <p>
                    <strong>Tipo de MRO:</strong>{' '}
                    {
                      {
                        maintenance: 'Mantenimiento',
                        repair: 'Reparación',
                        evaluation: 'Evaluación',
                        preservation: 'Preservación',
                        certification: 'Certificación',
                        assurance: 'Garantía',
                      }[task.mroType]
                    }
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p>
                    <strong>OC/OT:</strong> {task.code}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row>
          {task.postponements?.length > 0 && (
            <Col className="mb-3">
              <h6>
                <strong>Postergaciones</strong>
              </h6>
              <Row>
                {task.postponements.map((postponement, key) => (
                  <Col key={`postponement-${key}`} sm={4} className="mb-3">
                    <Card maxHeight={20}>
                      <CardHeader>
                        <strong>{fromIso8601(postponement.newDate, false)}</strong>
                        <p>{postponement.reason ? postponement.reason : 'No tiene motivo'}</p>
                      </CardHeader>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          )}
        </Row>
        <Row>
          <Col className="mb-3">
            <h6>
              <strong>Sub Tareas</strong>
            </h6>
            {task.subtasks.length > 0 ? (
              <div>
                <Row>
                  <Col sm={6}></Col>
                  <Col sm={2}>Estimadas</Col>
                  <Col sm={2}>Efectivas</Col>
                  <Col sm={2}></Col>
                </Row>
                {task.subtasks.map((t, index) => (
                  <TaskControllerModal
                    key={`subtasks-${index}`}
                    task={task}
                    subtask={t}
                    onCompleteSubtask={onCompleteSubtask}
                    loading={loading}
                  />
                ))}
              </div>
            ) : (
              'No tiene sub-tareas'
            )}
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <h6>
              <strong>Acciones</strong>
            </h6>
            <Button className="mr-1" disabled={task.completedAt || task.canceledAt}>
              <NavLink style={{ textDecoration: 'none', color: '#212629' }} to={`/organization/tasks/${task.id}`}>
                <FontAwesome name="pencil-alt" /> Editar
              </NavLink>
            </Button>
            <Button className="mr-1" onClick={openTaskPospone} disabled={task.completedAt || task.canceledAt}>
              <FontAwesome name="calendar-times" /> Posponer
            </Button>
            <Button
              className="mr-1"
              onClick={onStar}
              disabled={task.completedAt || task.canceledAt || task.subtasks?.some((st) => st.completedAt)}
            >
              <FontAwesome name="calendar-check" /> {task.startedAt ? 'No Iniciar' : 'Iniciar'}
            </Button>
            <Button
              className="mr-1"
              onClick={openTaskCancel}
              disabled={
                ((!task.members?.some((member) => member.id === session.user.id) ||
                  task.createdBy?.id !== session.user?.id ||
                  task.completedAt) &&
                  !this.isOrganizationAdmin) ||
                (task.integration && task.canceledAt)
              }
            >
              <FontAwesome name="calendar-minus" /> {task.canceledAt ? 'Reanudar' : 'Anular'}
            </Button>
            <Button
              className="mr-1"
              onClick={openConfirmBox}
              disabled={(task.completedAt || task.createdBy?.id !== session.user?.id) && !this.isOrganizationAdmin}
            >
              <FontAwesome name="trash" /> Eliminar
            </Button>
            <Button className="mr-1" onClick={onComplete} disabled={task.completedAt || task.canceledAt}>
              <FontAwesome name="clipboard-check" /> Completar
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="mb-3">
            <Button
              className="mr-1"
              onClick={openConfirmMROModal}
              color="primary"
              disabled={
                !task.integration ||
                !task.mroType ||
                task.asset?.isInMro ||
                !task.asset?.repairable ||
                !task.asset?.availability === 'available' ||
                !task.asset?.state === 'stored' ||
                task.canceledAt ||
                task.state === 'finished'
              }
            >
              <FontAwesome name="heartbeat" /> Iniciar MRO
            </Button>
          </Col>
        </Row>
        <Row>
          {task.reasons?.length > 0 && (
            <Col>
              <h6 className="mt-3" style={{ cursor: 'pointer' }}>
                <strong>
                  <span onClick={this.toggle}>
                    Comentarios... <FontAwesome name="caret-down" />
                  </span>
                </strong>
              </h6>
              <Collapse isOpen={isOpen}>
                {task.reasons?.map((t, key) => (
                  <div className={key % 2 === 0 ? 'pr-5 mt-3' : 'pl-5 mt-3'} key={`reason-${key}`}>
                    <div className={`card border ${t.action === 'cancel' ? 'border-danger' : 'border-primary'}`}>
                      <CardBody>
                        <Row>
                          <Container>
                            <div className="float-left">
                              <strong className={`${t.action === 'cancel' ? 'text-danger' : 'text-primary'}`}>
                                {t.action === 'cancel' ? 'Anular' : 'Reanudar'}
                              </strong>
                            </div>
                            <div className="float-right">{fromIso8601(t.date, false)}</div>
                          </Container>
                        </Row>
                        <Row>
                          <Container>
                            <div className="float-left">
                              <span className="text-muted">{`${t.user.firstName} ${t.user.lastName} dijo:`}</span>{' '}
                              {t.reason}
                            </div>
                          </Container>
                        </Row>
                      </CardBody>
                    </div>
                  </div>
                ))}
              </Collapse>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}

export const TaskInfoModal = connect((state) => ({ user: state.session.user }))(TaskInfoModalPresentation);
