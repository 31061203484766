import { getOrganizationTasks } from '@core/building/tasks';
import FullCalendar from '@fullcalendar/react';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import rrulePlugin from '@fullcalendar/rrule';
import timeGridPlugin from '@fullcalendar/timegrid';
import { FontAwesome } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

export const CalendarView = () => {
  const [data, setData] = useState({ events: [] });

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const fetchData = async () => {
      const { body } = await getOrganizationTasks({ completed: false });

      let events = body.map((event) => {
        let e = {
          id: event.id,
          title: event.title,
          start: event.startDate,
          end: event.dueDate,
        };

        if (event.frequency) {
          e = {
            ...e,
            rrule: {
              freq: event.frequency.split('=')[1],
              dtstart: event.startDate,
            },
          };
        }

        return e;
      });

      setData({
        events,
      });
    };
    fetchData();
  }, []);

  return (
    <BuildingLayout>
      <Row>
        <Col>
          <h4 className="mb-4">
            <FontAwesome name="calendar-alt" className="text-primary mr-2" />
            Calendario
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <FullCalendar
                locale={esLocale}
                initialView="dayGridMonth"
                nowIndicator={true}
                headerToolbar={{
                  left: 'prev,next today',
                  center: 'title',
                  right: 'dayGridMonth,timeGridWeek,timeGridDay listWeek',
                }}
                plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin, rrulePlugin]}
                events={data.events}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </BuildingLayout>
  );
};
