import { getCategories } from '@core/building/categories';
import { buildingDownload } from '@core/http.js';
import { CsvDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row, UncontrolledTooltip } from 'reactstrap';
import { connect } from '@store';
import { InventoryFilterModal } from './components/InventoryFilterModal';
import { InventoryTable } from './components/InventoryTable';

class InventoryBasePresentation extends SimpleFilter {
  constructor(props) {
    super(props);
    this.searchFunction = props.search;
    this.state = {
      ...super.state,
      position: 0,
    };
  }

  async componentDidMount() {
    await super.componentDidMount();

    const { body: categories, status } = await getCategories({ paginate: false });

    if (status === 200) {
      this.setState({ categories });
    }
  }

  hookPreResponseHandler = (response) => {
    const { body: items, status } = response;
    if (status === 200 && items.length > 0) {
      this.setState({
        buildings: Object.keys(items[0].inventory).filter((k) => k !== '__organization'),
      });
    }
  };

  next = () => {
    const { position, buildings } = this.state;
    let newPos;

    if (position + 1 === buildings.length) {
      newPos = 0;
    } else {
      newPos = position + 1;
    }

    this.setState({
      position: newPos,
    });
  };

  prev = () => {
    const { position, buildings } = this.state;
    let newPos;

    if (position === 0) {
      newPos = buildings.length - 1;
    } else {
      newPos = position - 1;
    }

    this.setState({
      position: newPos,
    });
  };

  render() {
    const { categories, items, filters, pagination, modalOpen, buildings, position } = this.state;
    const { currentBuilding, sap, fileName } = this.props;
    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;
    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right" style={{ float: 'left' }}>
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              {this.props.enableActions === true && (
                <div style={{ float: 'left' }}>
                  {items && items?.length > 0 && (
                    <div className="d-inline-block">
                      {/* <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                        <ExcelDownload
                          file="inventory"
                          filters={filters}
                          csv={!currentBuilding.sapIntegration}
                          downloadFile={buildingDownload}
                          disabled={!items}
                        />
                      </ShowForRoles> */}
                      <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                        <CsvDownload
                          fileName={fileName || 'inventory'}
                          filters={filters}
                          downloadFile={buildingDownload}
                          disabled={!items}
                        />
                        <Button
                          role="button"
                          color={'primary'}
                          tag="a"
                          href={'https://convertio.co/es/csv-xlsx/'}
                          target="_blank"
                          size="sm"
                          id={`convert_files`}
                        >
                          <FontAwesome name={'file-csv'} /> <FontAwesome name={'arrow-right'} />{' '}
                          <FontAwesome name={'file-excel'} />
                        </Button>
                        <UncontrolledTooltip target={`convert_files`} placement="right">
                          Servicio para convertir archivos CSV a Excel
                        </UncontrolledTooltip>
                      </ShowForRoles>
                      <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
                        {this.props.comparatorUrl && !currentBuilding.sapIntegration && (
                          <Button tag={NavLink} size="sm" outline color="success" to={this.props.comparatorUrl}>
                            <FontAwesome name="tasks" /> Comparador
                          </Button>
                        )}
                      </ShowForRoles>
                    </div>
                  )}
                </div>
              )}
            </div>
            <h4 className="mb-4">
              <FontAwesome name="th" className="text-primary mr-2" />
              Inventario
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <InventoryTable
                    items={items}
                    pagination={pagination}
                    switcher={this.props.switcher}
                    next={this.next}
                    prev={this.prev}
                    buildings={buildings}
                    position={position}
                    sap={sap}
                  />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <InventoryFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
          categories={categories}
        />
      </BuildingLayout>
    );
  }
}

export const InventoryBase = connect(({ session }, ownProps) => ({
  ...ownProps,
  currentBuilding: session.user?.memberships.find((m) => m.id === session.currentMembership).building,
}))(InventoryBasePresentation);
