import React from 'react';
import { Col, Table } from 'reactstrap';

export const StatsMROTable = ({ bpmTypeSingular, stats }) => (
  <Col>
    Procesos Abiertos
    <Table size="sm" striped responsive>
      <thead>
        <tr>
          <th>
            <small></small>
          </th>
          <th>
            <small>
              <strong>Total</strong>
            </small>
          </th>
          {Object.values(bpmTypeSingular).map((bmpType, key) => (
            <th key={`bpm-${key}`} style={{ minWidth: bmpType === 'Dar de Baja' ? 80 : 'auto' }}>
              <small>
                <strong>{bmpType}</strong>
              </small>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <small>Propios</small>
          </td>
          <td className="text-center">
            <small>
              <strong>{Object.keys(stats).reduce((sum, key) => sum + (stats[key]['own'] || 0), 0)}</strong>
            </small>
          </td>
          {Object.keys(bpmTypeSingular).map((bmpType, key) => (
            <td key={`own-${key}`} className="text-center" style={{ width: '1000px' }}>
              {stats[bmpType]?.own ? <small>{stats[bmpType].own}</small> : <small>-</small>}
            </td>
          ))}
        </tr>
        <tr>
          <td>
            <small>Terceros</small>
          </td>
          <td className="text-center">
            <small>
              <strong>{Object.keys(stats).reduce((sum, key) => sum + (stats[key]['thirdParties'] || 0), 0)}</strong>
            </small>
          </td>
          {Object.keys(bpmTypeSingular).map((bmpType, key) => (
            <td key={`third-parties-${key}`} className="text-center">
              {stats[bmpType]?.thirdParties ? <small>{stats[bmpType]?.thirdParties}</small> : <small>-</small>}
            </td>
          ))}
        </tr>
      </tbody>
    </Table>
  </Col>
);
