import { getMovements } from '@core/building/movements';
import { FontAwesome, simpleFilterQuery, Spinner } from '@shared';
import { MovementsTable } from '@shared/movements';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';

const enhance = compose(
  withState('movements', 'setMovements', undefined),
  lifecycle({
    async componentDidMount() {
      const { item, setMovements } = this.props;

      const { body, status } = await getMovements({
        items: item.id,
        pp: 6,
      });

      if (status === 200) {
        setMovements(body);
      }
    },
  }),
  branch(
    ({ movements }) => !movements,
    renderComponent(() => (
      <div className="mt-4 mb-4 text-info text-center h2">
        <Spinner />
      </div>
    )),
  ),
);

const ItemMovementsPresentation = ({ item, movements }) => (
  <div>
    <MovementsTable movements={movements} showTarget={false} />

    {movements.length >= 5 && (
      <div className="mt-1 text-center">
        <Button
          role="button"
          color="primary"
          tag={NavLink}
          to={`/movements?q=${simpleFilterQuery({
            consumable: 'true',
            'items[{}]': [{ label: item.barcode1, value: item.id }],
          })}`}
        >
          <FontAwesome name="search" /> Ver todos
        </Button>
      </div>
    )}
  </div>
);

export const ItemMovements = enhance(ItemMovementsPresentation);
