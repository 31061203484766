import { editCategory, getCategory } from '@core/building/categories';
import { SubmissionError } from '@core/forms';
import { Loading } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { CategoryForm } from './components/CategoryForm';

class EditCategoryPresentation extends Component {
  state = {
    loading: true,
    category: undefined,
    edited: false,
    notFound: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    if (!id) {
      this.notFound();
      return;
    }

    const { body: category, status } = await getCategory(id);

    this.setState({
      loading: false,
    });

    if (status === 404) this.notFound();
    else
      this.setState({
        category,
      });
  }

  notFound = () => {
    this.props.notFound();

    this.setState({ notFound: true });
  };

  editCategory = async (data) => {
    const { category } = this.state;
    const { body, status } = await editCategory({ id: category.id, ...data });

    if (status === 200) {
      this.setState({
        edited: true,
      });

      this.props.successNotification(data.name);
    } else if (status === 422) throw new SubmissionError(body.errors);
  };

  render() {
    const { loading, edited, category, notFound } = this.state;

    if (notFound) return <Redirect to="/organization/categories" />;

    if (loading) return <Loading />;

    if (edited) return <Redirect to="/organization/categories" />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title={`Editar categoría: ${category && category.name}`}>
          {category && (
            <CategoryForm
              initialValues={{
                name: category.name,
                description: category.description,
              }}
              onSubmit={this.editCategory}
            />
          )}
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const EditCategory = connect(null, (dispatch) => ({
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No encontrada',
        text:
          'La categoría buscada no existe o no se encuentra disponible. Si los errores persisten, por favor contacta a un administrador.',
        color: 'danger',
      }),
    ),
  successNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Categoría editada',
        text: `La categoría ${name} ha sido editada exitosamente.`,
        color: 'success',
      }),
    ),
}))(EditCategoryPresentation);
