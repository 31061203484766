import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { FontAwesome, ProcessingButton } from '@shared';
import { AssetSelector } from '@shared/forms';
import { connect } from '@store';
import React, { Component } from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { TagMetadata } from '../../tags/components/TagMetadata';

const validate = Validates(
  presence({ of: 'tagType' }),
  presence({ of: 'metadata.major', condition: (obj) => obj.tagType === 1 }),
  presence({ of: 'metadata.minor', condition: (obj) => obj.tagType === 1 }),
  presence({
    of: 'metadata.epc',
    condition: (obj) => obj.tagType === 2,
  }),
  presence({ of: 'metadata.eui', condition: (obj) => obj.tagType === 5 }),
  presence({ of: 'metadata.imei', condition: (obj) => obj.tagType === 4 }),
  presence({ of: 'metadata.simcard', condition: (obj) => obj.tagType === 4 }),
);

class TagCreatorFormPresentation extends Component {
  state = {
    metadata: [],
    selected: false,
  };

  UNSAFE_componentWillMount() {
    const initialValues = this.props.initialValues;
    if (initialValues) {
      this.filterTag(initialValues.tagType);
    }
  }

  filterTag = (tagType) => {
    switch (tagType) {
      case 1:
        this.setState({
          metadata: ['major', 'minor'],
          selected: true,
        });
        break;
      case 2:
        this.setState({
          metadata: ['epc'],
          selected: true,
        });
        break;
      case 4:
        this.setState({
          metadata: ['imei', 'simcard'],
          selected: true,
        });
        break;
      case 6:
        this.setState({
          metadata: ['eui'],
          selected: true,
        });
        break;
      default:
        this.setState({
          selected: false,
        });
    }
  };

  render() {
    const { handleSubmit, invalid, submitting, pristine, error, tagTypes, assetBarcode, onSubmit, reset } = this.props;
    const { selected, metadata } = this.state;

    return (
      <Form error={error}>
        {assetBarcode ? (
          <Field
            name="assetBarcode"
            input={{ value: assetBarcode ? assetBarcode : null }}
            label="Activo asociado"
            component={AssetSelector}
            help="Activo al cual sera aplicado el presente tag"
            readonly={true}
          />
        ) : (
          <p>
            <strong className="text-muted">Se creará un nuevo activo al finalizar el proceso de onboarding</strong>
          </p>
        )}

        <Field
          name="tagType"
          type="select"
          label="Tipo de tag"
          parse={parseInt}
          options={tagTypes}
          onChange={(_e, v) => this.filterTag(parseInt(v, 10))}
          required
        />

        {selected && <TagMetadata params={metadata} />}

        <hr />

        <Field
          name="active"
          type="checkbox"
          label=" ¿Tag activo?"
          help="Desmarcar esta opción hará que se deshabilite la funcion del tag sobre el activo. Utilizar con cautela."
        />

        <Field name="comments" type="textarea" rows="6" label="Comentarios" />

        <hr />

        <div className="text-center">
          <ProcessingButton
            type="submit"
            role="button"
            outline
            color="primary"
            width="10em"
            disabled={pristine || invalid || selected === false}
            processing={submitting}
            onClick={handleSubmit(onSubmit.bind(this, true, reset))}
          >
            <span>
              Agregar tag <FontAwesome name="plus" />
            </span>
          </ProcessingButton>
        </div>
      </Form>
    );
  }
}

export const TagCreatorForm = compose(
  connect(({ appData: { tag_types: tagTypes } }) => {
    const options = { 0: 'Seleccione...' };
    tagTypes.forEach((tt) => (options[tt.id] = tt.description));
    return { tagTypes: options };
  }),
  reduxForm({
    form: 'tagForm',
    validate,
  }),
)(TagCreatorFormPresentation);
