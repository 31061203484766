import { getNotifications, markAsRead } from '@core/building/notifications';
import { useInterval } from '@shared/hooks/use-interval';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { FontAwesome } from '..';
import { NotificationsDisplay } from './NotificationDisplay';
import styles from './NotificationPanel.module.scss';

export const NotificationsPanel = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  const [notifications, setNotifications] = useState([]);
  const membership = useSelector(({ session: { currentMembership, user } }) =>
    user.memberships.find((membership) => membership.id === currentMembership),
  );

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  // const modalToggle = () => setModalOpen((prevState) => !prevState);

  const fetchData = async () => {
    // if (!dropdownOpen) {
    const { body, status } = await getNotifications(membership?.building?.id, true);

    if (status >= 200 && status < 300) {
      setNotifications(body);
    }
    // }
  };

  // ComponentWillMount
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    fetchData();
  }, 60000);

  const handleClick = async (notification) => {
    history.push(`/building/assets/${notification.metadata.asset.barcode1}`);
    toggle();
    await markAsRead(notification._id);
    fetchData();
  };

  return (
    <>
      {notifications.length > 0 && (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle nav>
            <FontAwesome name="bell" />
          </DropdownToggle>
          <DropdownMenu right={true} className={styles.dropdownMenuLook}>
            <div style={{ overflowY: 'auto', maxHeight: 400, backgroundColor: 'transparent' }}>
              {notifications.map((notification) => (
                <NotificationsDisplay notification={notification} onClick={handleClick} />
              ))}
            </div>
          </DropdownMenu>
        </Dropdown>
      )}
    </>
  );
};
