import { FontAwesome } from '@shared/';
import React from 'react';
import { Col, Media, Row } from 'reactstrap';
import { EventMovementModal } from './EventMovement';

export const EventMRO = ({ mro }) => (
  <Media className="align-middle small">
    <Media body>
      {mro.assetRepairs?.length > 0 && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="cogs" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Reparaciones</span>
        </div>
      )}
      {mro.assetMaintenances?.length > 0 && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="heartbeat" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Mantenciones</span>
        </div>
      )}
      {mro.assetEvaluations?.length > 0 && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Evaluaciones</span>
        </div>
      )}
      {mro.assetPreservations?.length > 0 && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Preservaciones</span>
        </div>
      )}
      {mro.assetCertifications?.length > 0 && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Certificaciones</span>
        </div>
      )}
      {mro.assetAssurances?.length > 0 && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Garantías</span>
        </div>
      )}
      {mro.assetDiscards?.length > 0 && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Dar de Baja</span>
        </div>
      )}
    </Media>
  </Media>
);

export const EventMROModal = ({ mro }) => (
  <Media>
    {mro.assetRepairs.length > 0 && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="cogs" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Reparaciones</span>
        </div>
        {mro.assetRepairs?.map((movement, index) => (
          <Row className="mt-3" key={index}>
            <Col>
              <h5>Reparación {index + 1}</h5>
              <EventMovementModal movement={movement} />
            </Col>
          </Row>
        ))}
      </Media>
    )}
    {mro.assetMaintenances?.length > 0 && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="heartbeat" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Mantenciones</span>
        </div>
        {mro.assetMaintenances?.map((movement, index) => (
          <Row className="mt-3" key={index}>
            <Col>
              <h5>Mantención {index + 1}</h5>
              <EventMovementModal movement={movement} />
            </Col>
          </Row>
        ))}
      </Media>
    )}
    {mro.assetEvaluations?.length > 0 && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Evaluaciones</span>
        </div>
        {mro.assetEvaluations?.map((movement, index) => (
          <Row className="mt-3" key={index}>
            <Col>
              <h5>Evaluación {index + 1}</h5>
              <EventMovementModal movement={movement} />
            </Col>
          </Row>
        ))}
      </Media>
    )}
    {mro.assetPreservations?.length > 0 && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Preservaciones</span>
        </div>
        {mro.assetPreservations.map((movement, index) => (
          <Row className="mt-3" key={index}>
            <Col>
              <h5>Preservación {index + 1}</h5>
              <EventMovementModal movement={movement} />
            </Col>
          </Row>
        ))}
      </Media>
    )}
    {mro.assetCertifications?.length > 0 && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Certificaciones</span>
        </div>
        {mro.assetCertifications.map((movement, index) => (
          <Row className="mt-3" key={index}>
            <Col>
              <h5>Certificación {index + 1}</h5>
              <EventMovementModal movement={movement} />
            </Col>
          </Row>
        ))}
      </Media>
    )}
    {mro.assetAssurances?.length > 0 && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Garantías</span>
        </div>
        {mro.assetAssurances.map((movement, index) => (
          <Row className="mt-3" key={index}>
            <Col>
              <h5>Garantía {index + 1}</h5>
              <EventMovementModal movement={movement} />
            </Col>
          </Row>
        ))}
      </Media>
    )}
    {mro.assetDiscards?.length > 0 && (
      <Media body>
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="list-ol" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">Dar de Baja</span>
        </div>
        {mro.assetDiscards.map((movement, index) => (
          <Row className="mt-3" key={index}>
            <Col>
              <h5>Dar de Baja {index + 1}</h5>
              <EventMovementModal movement={movement} />
            </Col>
          </Row>
        ))}
      </Media>
    )}
  </Media>
);
