import AsyncSelect from 'react-select/async';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { getCategories } from '@core/building/categories';
import { getItemsFromBuilding } from '@core/building/items';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';

export class ItemsFilterModal extends SimpleFilterModal {
  findCategories = async (inputValue) => {
    const { body, status } = await getCategories({ name: inputValue });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findItems = async (inputValue) => {
    const { body, status } = await getItemsFromBuilding({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ name: label, barcode1: value }) => ({ label, value }));
    }

    return [];
  };

  setStartedAt = (value, stateProperty) => {
    this.setState({
      [stateProperty]: {
        ...this.state[stateProperty],
        started_at: value || undefined,
      },
    });
  };

  setEndedAt = (value, stateProperty) => {
    this.setState({
      [stateProperty]: {
        ...this.state[stateProperty],
        ended_at: value || undefined,
      },
    });
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="item">Nombre</Label>
              <AsyncSelect
                name="items[{}]"
                value={this.state['items[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findItems}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="active">Estado</Label>
              <Select
                name="active"
                value={this.state['active']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Activo', value: true },
                  { label: 'Inactivo', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="categories[{}]">Categorías</Label>
              <AsyncSelect
                name="categories[{}]"
                value={this.state['categories[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findCategories}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="consumable">¿Consumible?</Label>
              <Select
                name="consumable"
                value={this.state['consumable']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={4}>
            <Label for="restricted">¿Restringido?</Label>
            <Select
              name="restricted"
              value={this.state['restricted']}
              placeholder="Todos"
              isClearable
              options={[
                { label: 'Si', value: true },
                { label: 'No', value: false },
              ]}
              onChange={this.onChange}
            />
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Cantidad disponible</Label>
              <FormGroup row>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="available_quantity{}[started_at]"
                    placeholder="Desde"
                    onChange={(e) => this.setStartedAt(e.target.value, 'available_quantity{}')}
                    value={this.state['available_quantity{}'] && this.state['available_quantity{}']['started_at']}
                  />
                </Col>
                <Col md={6}>
                  <Input
                    type="number"
                    size="md"
                    name="available_quantity{}[ended_at]"
                    placeholder="Hasta"
                    onChange={(e) => this.setEndedAt(e.target.value, 'available_quantity{}')}
                    value={this.state['available_quantity{}'] && this.state['available_quantity{}']['ended_at']}
                    disabled={
                      !this.state['available_quantity{}'] ||
                      (this.state['available_quantity{}'] && !this.state['available_quantity{}']['started_at'])
                    }
                  />
                </Col>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
