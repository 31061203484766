import { createMaintenance } from '@core/building/asset-repairs';
import { getBuilding, getBuildingProviders } from '@core/building/buildings';
import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { DebugCard, ProcessingButton } from '@shared';
import { BuildingSelector, DateSelector, FileUploader, ProviderSelector } from '@shared/forms';
import { connect, notificationsGenerators } from '@store';
import { addDays, setHours, setMinutes } from 'date-fns';
import React, { Component } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { compose } from 'recompose';
import { formValues, reduxForm } from 'redux-form';
import { AVAILABLES_CURRENCIES } from '@shared/constants';

const modalEnhance = compose(
  connect(({ session }) => {
    let building = undefined;

    if (session.currentMembership && session.user) {
      const membership = session.user?.memberships.find((membership) => membership.id === session.currentMembership);

      if (membership) {
        building = membership.building;
      }
    }

    return {
      currentBuilding: building,
    };
  }),
  connect(null, (dispatch) => ({
    successNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Orden de MRO creada',
          test: 'la nueva orden se encuentra disponible en las reparaciones de la instalación destino',
          color: 'success',
        }),
      ),
    errorNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Error',
          text: 'Ocurrió un problema al intentar iniciar el proceso de MRO.',
          color: 'danger',
        }),
      ),
  })),
);

const UploaderComponent = ({ onChange, value }) => (
  <FileUploader multiple={true} token={value} onChange={onChange} endpoint={(token) => `/attachments/${token}`} />
);

const validate = Validates(presence({ of: 'movementType' }));

const enhance = compose(
  reduxForm({
    form: 'createRepairForm',
    validate,
  }),
  formValues({
    supplierId: 'supplierId',
  }),
  connect(null, (dispatch) => ({
    resetForm() {
      dispatch({
        type: '@@redux-form/RESET',
        meta: {
          form: 'createRepairForm',
        },
      });
    },
  })),
);

const currencyOptions = function () {
  const options = { '': 'Seleccione...' };

  AVAILABLES_CURRENCIES.forEach((cp) => (options[cp] = cp));
  return options;
};
class RepairFormPresentation extends Component {
  constructor(props) {
    super(props);

    let minLeadTime = setHours(setMinutes(addDays(new Date(), 1), 0), 0);

    this.state = {
      ...this.state,
      error: null,
      minLeadTime: minLeadTime,
      supplier: undefined,
      // this.props.bpmType === 'discard' && this.props.currentAsset
      //   ? this.props.currentAsset?.repair?.ownerId
      //   : undefined,
      buildingSupplier: undefined,
      // this.props.bpmType === 'discard' && this.props.currentAsset
      //   ? this.props.currentAsset?.asset?.buildingId
      //   : undefined,
      leadTime: minLeadTime,
      arrivalDate: undefined,
      committedDate: undefined,
      estimatedDate: undefined,
      price: undefined,
      currency: undefined,
    };
  }

  setSupplier = (supplier) => {
    this.setState({
      supplier,
    });
  };

  setBuildingSupplier = (buildingSupplier) => {
    this.setState({
      buildingSupplier,
    });
  };

  setLeadTime = (leadTime) => {
    this.setState({
      leadTime,
    });
  };

  setArrivalDate = (arrivalDate) => {
    this.setState({
      arrivalDate,
    });
  };

  setCommittedDate = (committedDate) => {
    this.setState({
      committedDate,
    });
  };

  setEstimatedDate = (estimatedDate) => {
    this.setState({
      estimatedDate,
    });
  };

  setPrice = (price) => {
    this.setState({
      price,
    });
  };
  setCurrency = (currency) => {
    this.setState({
      currency,
    });
  };

  size = 'lg';

  toggleModal = () => {
    this.props.resetForm();
    this.props.toggle();
  };

  render() {
    const { isOpen, className, invalid, pristine, supplierId, handleSubmit, bpmType } = this.props;
    const {
      error,
      submitting,
      minLeadTime,
      leadTime,
      arrivalDate,
      committedDate,
      estimatedDate,
      price,
      currency,
      supplier,
      buildingSupplier,
    } = this.state;

    const bpmTypeSingular = {
      repair: 'Reparación',
      evaluation: 'Evaluación',
      maintenance: 'Mantención',
      preservation: 'Preservación',
      certification: 'Certificación',
      assurance: 'Garantía',
      discard: 'Dar de Baja',
    };
    return (
      <Modal
        onEnter={this.onEnter}
        isOpen={isOpen}
        toggle={this.toggleModal}
        className={className}
        size={this.size}
        backdrop="static"
      >
        <Form error={error} onSubmit={handleSubmit}>
          <ModalHeader toggle={this.toggleModal}>Enviar a {bpmTypeSingular[bpmType]}</ModalHeader>
          <ModalBody>
            {bpmType !== 'discard' && (
              <Row form>
                <Col md={6}>
                  <Field
                    label="Proveedor"
                    name="supplierId"
                    component={ProviderSelector}
                    help={<span>Organización proveedora del servicio de {bpmTypeSingular[bpmType]}.</span>}
                    value={supplier}
                    onChange={this.setSupplier}
                    required
                  />
                </Col>
                <Col md={6}>
                  <Field
                    label="Instalación destino"
                    name="buildingId"
                    component={BuildingSelector}
                    filters={{ organizations: supplierId }}
                    remoteFinder={getBuildingProviders}
                    remoteLoader={getBuilding}
                    readonly={!supplierId}
                    value={buildingSupplier}
                    onChange={this.setBuildingSupplier}
                    required
                  />
                </Col>
              </Row>
            )}
            {bpmType !== 'discard' && (
              <Row form>
                <Col md={6}>
                  <Field label="OC/OT" name="code" placeholder="Número de orden de compra" />
                </Col>
                <Col md={6}>
                  <Field
                    name="leadTime"
                    label="Lead Time"
                    component={DateSelector}
                    selected={leadTime}
                    onChange={this.setLeadTime}
                    selectsEnd
                    minTime={minLeadTime}
                    maxTime={setHours(setMinutes(new Date(), 30), 23)}
                    minDate={minLeadTime}
                    shouldCloseOnSelect={false}
                    popperModifiers={{
                      preventOverflow: {
                        escapeWithReference: false,
                        boundariesElement: 'viewport',
                      },
                    }}
                    required={bpmType !== 'discard'}
                    disabled={bpmType === 'discard'}
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={6}>
                <Field
                  name="arrivalDate"
                  label="Fecha de llegada"
                  component={DateSelector}
                  selected={arrivalDate}
                  onChange={this.setArrivalDate}
                  selectsEnd
                  minTime={minLeadTime}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                  minDate={minLeadTime}
                  shouldCloseOnSelect={false}
                  popperModifiers={{
                    preventOverflow: {
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                  disabled={bpmType === 'discard'}
                />
              </Col>
              <Col md={6}>
                <Field
                  name="committedDate"
                  label="Fecha comprometida"
                  component={DateSelector}
                  selected={committedDate}
                  onChange={this.setCommittedDate}
                  selectsEnd
                  minTime={minLeadTime}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                  minDate={minLeadTime}
                  shouldCloseOnSelect={false}
                  popperModifiers={{
                    preventOverflow: {
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                  disabled={bpmType === 'discard'}
                />
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Field
                  name="estimatedDate"
                  label="Fecha estimada de entrega"
                  component={DateSelector}
                  selected={estimatedDate}
                  onChange={this.setEstimatedDate}
                  selectsEnd
                  minTime={minLeadTime}
                  maxTime={setHours(setMinutes(new Date(), 30), 23)}
                  minDate={minLeadTime}
                  shouldCloseOnSelect={false}
                  popperModifiers={{
                    preventOverflow: {
                      escapeWithReference: false,
                      boundariesElement: 'viewport',
                    },
                  }}
                  disabled={bpmType === 'discard'}
                />
              </Col>
            </Row>
            {bpmType !== 'discard' && (
              <Row form>
                <Col md={6}>
                  <Field
                    name="price"
                    value={price}
                    label="Precio"
                    type="text"
                    help="Precio del procedimiento"
                    onChange={this.setPrice}
                    disabled={bpmType === 'discard'}
                  />
                </Col>
                {price && (
                  <Col md={6}>
                    <Field
                      name="currency"
                      value={currency}
                      type="select"
                      label="Moneda"
                      options={currencyOptions()}
                      onChange={this.setCurrency}
                    />
                  </Col>
                )}
              </Row>
            )}
            <Row form>
              <Col md={12}>
                <Field
                  name="comments"
                  label="Comentarios"
                  rows="6"
                  type="textarea"
                  help="Cualquier comentario que pueda aportar antecedentes adicionales respecto al movimiento realizado."
                />
              </Col>
              <Col md={12}>
                <Field name="attachmentsTokens" label="Archivos" component={UploaderComponent} />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Cancelar
            </Button>
            <ProcessingButton
              type="submit"
              role="button"
              color="primary"
              outline
              width="15em"
              disabled={bpmType !== 'discard' && (pristine || invalid || !leadTime || !supplier || !buildingSupplier)}
              processing={submitting}
            >
              Iniciar {bpmTypeSingular[bpmType]}
            </ProcessingButton>
          </ModalFooter>
        </Form>
        {process.env.NODE_ENV === 'development' && (
          <Row>
            <Col>
              <DebugCard data={this.state} />
            </Col>
          </Row>
        )}
      </Modal>
    );
  }
}

class RepairFormModalPresentation extends Component {
  toggleModal = () => {
    this.props.toggle();
  };

  handleSubmit = async (repair) => {
    const { currentAsset, currentBuilding } = this.props;
    const movementType =
      this.props.bpmType === 'discard'
        ? 'need_discard'
        : currentBuilding.id === repair.buildingId
        ? `reception_on_${this.props.bpmType}_supplier`
        : `sent_to_${this.props.bpmType}`;
    const creatorRole =
      this.props.bpmType === 'discard' || movementType === `sent_to_${this.props.bpmType}` ? 'owner' : 'supplier';

    const payload = {
      supplier_id: this.props.bpmType === 'discard' ? currentAsset?.repair.ownerId : repair.supplierId,
      code: repair.code,
    };
    payload[`assets_${this.props.bpmType}`] = [
      {
        asset_id: currentAsset.assetId,
        building_id: this.props.bpmType === 'discard' ? currentAsset?.asset?.buildingId : repair.buildingId,
        lead_time: repair.leadTime
          ? repair.leadTime
          : this.props.bpmType !== 'discard'
          ? setHours(setMinutes(addDays(new Date(), 1), 0), 0)
          : repair.leadTime,
        arrivalDate: repair.arrivalDate,
        committedDate: repair.committedDate,
        estimatedDate: repair.estimatedDate,
        price: repair.price,
        currency: repair.currency,
        comments: `Generado a partir de la evaluación ${currentAsset.id}. \n\n${
          repair.comments ? repair.comments : ''
        }`,
        origin_type: 'Core::AssetEvaluation',
        origin_id: currentAsset.id,
        attachments_tokens: repair.attachmentsTokens,
        movementType: movementType,
        creatorRole: creatorRole,
        extendedAttributes: { originEvaluationId: currentAsset.id },
      },
    ];

    const { body, status } = await createMaintenance(payload);

    if (status === 201) {
      this.props.successNotification(body.code);
      this.toggleModal();
    } else {
      this.props.errorNotification();
    }
  };

  render() {
    const { isOpen, toggle, asset, bpmType, currentBuilding, currentAsset } = this.props;
    return (
      <RepairForm
        onSubmit={this.handleSubmit}
        isOpen={isOpen}
        toggle={toggle}
        asset={asset}
        bpmType={bpmType}
        currentBuilding={currentBuilding}
        currentAsset={currentAsset}
      />
    );
  }
}

const RepairForm = enhance(RepairFormPresentation);

export const RepairFormModal = modalEnhance(RepairFormModalPresentation);
