import { getShortAssets } from '@core/building/assets';
import { buildingSendEmail } from '@core/http.js';
import { FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { AssetsFilterModal } from './components/AssetsFilterModal';
import { AssetsResults } from './components/AssetsResults';
import ExcelEmail from '@shared/ExcelEmail';

export class AssetsIndex extends SimpleFilter {
  searchFunction = getShortAssets;

  constructor(props) {
    super(props);
    this.state = { isOpen: false, email: undefined };
  }

  toggleInfo = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const { id } = this.state;

    if (id) {
      return <Redirect to={`/building/assets/${id}`} />;
    }

    const { items, filters, pagination, modalOpen, isOpen, email } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                {/* <ExcelDownload
                  file="assets"
                  filters={{ ...filters }}
                  downloadFile={buildingDownload}
                  disabled={!items}
                  csv={false}
                /> */}
                <Button
                  size="sm"
                  color="success"
                  disabled={!items}
                  onClick={(e) => {
                    this.setState({ isOpen: true });
                  }}
                >
                  <FontAwesome name="table" /> Descargar
                </Button>
              </ShowForRoles>
              <ShowForRoles excluded={['guest']}>
                <Button tag={NavLink} size="sm" outline color="success" to="/building/assets/new">
                  <FontAwesome name="plus" /> Crear Activo
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="wrench" className="text-primary mr-2" />
              Gestión de Activos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && <AssetsResults assets={items} pagination={pagination} />}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AssetsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
        <Modal isOpen={isOpen} toggle={this.toggleInfo}>
          <ModalHeader>Información</ModalHeader>
          <ModalBody>
            <p>El archivo excel será generado y enviado por correo una vez que esté listo.</p>
            <p>
              <Input
                type="email"
                placeholder="email"
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </p>
            <p>
              En caso de dejar en blanco el email o utilizar uno inválido, se enviará el reporte al correo del usuario
              registrado en Alliot Cloud.
            </p>
          </ModalBody>
          <ModalFooter>
            <div className="text-right">
              <ExcelEmail
                file="assets"
                filters={{ ...filters, email }}
                downloadFile={(e) => {
                  this.toggleInfo();
                  buildingSendEmail(e);
                }}
                disabled={!items}
                size="md"
                name={'Enviar excel'}
              />
              <Button role="button" color="primary" onClick={this.toggleInfo} className={'ml-3'}>
                Cerrar
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </BuildingLayout>
    );
  }
}
