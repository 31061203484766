import React from 'react';

import RoundedImage from './RoundedImage';

import portrait from './anonymous.png';

const UserPortrait = ({ user, width = '2rem', size = 'icon' }) => {
  const altText = user.firstName ? user.firstName.split(' ')[0] : user.name || 'Sin nombre';

  return <RoundedImage src={(user.avatar && user.avatar[size].url) || portrait} alt={altText} size={width} />;
};

export default UserPortrait;
