import React from 'react';
import { Media } from 'reactstrap';

import { getUnit, getUnits } from '@core/units';
import { RemoteSelector } from '@core/forms';

const UnitCard = ({ resource: unit }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{unit.name}</strong>
      </div>
      <div className="text-muted">{unit.symbol}</div>
    </Media>
  </Media>
);

const UnitSelector = ({ inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getUnit}
    remoteFinder={(query) =>
      getUnits({
        search: query,
      })
    }
    dropdownTemplate={UnitCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar unidad por simbolo o nombre...',
    }}
  />
);

export default UnitSelector;
