import { getReadouts } from '@core/building/readouts';
import { getLocations } from '@core/organization/locations';
import { darken } from '@core/utils/color';
import { Loading, Pagination } from '@shared';
import { SANTIAGO } from '@shared/mapbox';
import React from 'react';
import { connect } from 'react-redux';
import { List } from 'react-virtualized';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { Readout } from './readout';
import './readouts-map.scss';

export class ReadoutsMapPresentation extends React.Component {
  map = undefined;
  infoWindow = undefined;

  constructor(props) {
    super(props);
    this.state = {
      center: SANTIAGO,
      zoom: 13,
      geofences: [],
      pagination: { page: 1, total: 1 },
      loading: true,
    };
  }

  componentDidMount = async () => {
    this.initMap(this.map);
    await this.fetchGeofences();
    await this.fetchReadouts();
  };

  initMap = () => {
    this.map = new window.google.maps.Map(document.getElementById('map'), {
      zoom: this.state.zoom,
      center: this.state.center,
      scaleControl: true,
      mapTypeControlOptions: {
        mapTypeIds: ['roadmap', 'terrain', 'satellite', 'hybrid'],
        position: window.google.maps.ControlPosition.TOP_RIGHT,
      },
    });

    this.infoWindow = new window.google.maps.InfoWindow();

    this.map.setMapTypeId('hybrid');

    this.map.data.setStyle((feature) => {
      var color = feature.getProperty('color') || '#008888';

      return {
        fillColor: color,
        fillOpacity: 0.5,
        strokeWeight: 1,
        strokeColor: darken(color, 20),
        icon: feature.getProperty('icon'),
      };
    });

    this.setupMapEvents();
  };

  setupMapEvents = (map) => {
    const moveCenter = () => {
      const center = this.map.getCenter();
      this.setState({ center: { lng: center.lng(), lat: center.lat() } });
    };
    this.map.addListener('drag', moveCenter);
    this.map.addListener('center_changed', moveCenter);

    this.map.addListener('zoom_changed', () => this.setState({ zoom: this.map.getZoom() }));
  };

  fetchGeofences = async () => {
    const { status, body } = await getLocations(this.props.currentOrganizationId, {
      paginate: false,
    });

    if (status === 200) {
      this.setState(
        {
          geofences: body.map((geofence) => ({
            id: `gf-${geofence.id}`,
            ...geofence.feature,
            properties: {
              ...geofence.feature.properties,
              type: 'geofence',
              name: geofence.name,
            },
          })),
        },
        () => this.map.data.addGeoJson({ type: 'FeatureCollection', features: this.state.geofences }),
      );
    }
  };

  fetchReadouts = async (page = 1) => {
    this.setState({ loading: true });

    const { body: readouts, status, pagination } = await getReadouts({
      assets: this.props.asset.id,
      p: page,
    });

    if (status === 200) {
      this.setState({ readouts, pagination, loading: false });
      this.onSelect(0);
    }
  };

  onPage = async (page) => {
    await this.fetchReadouts(page);
  };

  onSelect = (index) => {
    this.setState({ current: index }, () => {
      const readout = this.state.readouts[index];
      if (readout && readout.metadata && readout.metadata.lat && readout.metadata.lng) {
        const { lat, lng } = readout.metadata;

        this.map.panTo({ lat, lng });
        this.map.setZoom(18);

        this.map.data.addGeoJson({
          type: 'Feature',
          id: 'current-marker',
          properties: {
            type: 'asset',
          },
          geometry: {
            type: 'Point',
            coordinates: [+Number(lng).toFixed(6), +Number(lat).toFixed(6)],
          },
        });
      }
    });
  };

  rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style,
  }) => {
    const readout = this.state.readouts[index];
    const className = this.state.current === index ? 'active' : '';

    return (
      <div key={index} style={{ ...style, paddingLeft: 2, paddingRight: 2 }}>
        <Readout readout={readout} key={index} className={className} onClick={() => this.onSelect(index)} />
      </div>
    );
  };

  render() {
    const { center, zoom, pagination, loading, readouts, current } = this.state;

    return (
      <Row>
        <Col xs="4">
          <Card>
            <CardHeader className="group-header pt-0">
              <Pagination page={pagination.page} total={pagination.total} setPage={this.onPage} className="my-0" />
            </CardHeader>
            <CardBody style={{ height: 700, padding: '0px 10px' }}>
              {loading && <Loading />}
              {!loading && (
                <List
                  width={400}
                  height={700}
                  rowCount={readouts.length}
                  rowHeight={95}
                  rowRenderer={this.rowRenderer}
                  overscanRowCount={3}
                  data={current}
                />
              )}
            </CardBody>
          </Card>
        </Col>
        <Col>
          <div className="readouts-map">
            <div className="sidebar text-monospace">
              <div>{`Latitude: ${center.lat.toFixed(6)} Longitude: ${center.lng.toFixed(6)} Zoom: ${zoom}`}</div>
            </div>
            <div style={{ height: 751, width: '100%' }}>
              <div id="map" className="map-container" />
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = ({ session }) => {
  let organizationId = undefined;
  let building = undefined;

  if (session.currentMembership && session.user) {
    const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

    if (membership) {
      building = membership.building;
      organizationId = building.organization.id;
    }
  }

  return {
    /* TODO: Reselect in the future */
    currentOrganizationId: organizationId,
    currentBuilding: building,
  };
};

export const ReadoutsMap = connect(mapStateToProps)(ReadoutsMapPresentation);
