import { updateAdminAttachment } from '@core/admin/attachments';
import { updateAttachment } from '@core/building/attachments';
import { Field, Form, SubmissionError } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { fromIso8601 } from '@core/utils/dates';
import { fileInfoFor } from '@core/utils/mime-types';
import { fileSize } from '@core/utils/number-formatting';
import { FileIcon, FontAwesome, ProcessingButton, ShowForRoles } from '@shared';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';
import { reduxForm } from 'redux-form';

const ItemInfoCard = ({ info, attachment }) => (
  <Col xs={12} md={4} className="small text-center">
    <div className="mb-3">
      <FileIcon type={info.icon} width="5rem" />
    </div>
    <table className="w-100">
      <tbody>
        <tr>
          <td className="text-right pr-2">
            <strong>Tipo:</strong>
          </td>
          <td className="text-left text-muted">{info.description || <em>Sin información</em>}</td>
        </tr>
        <tr>
          <td className="text-right pr-2">
            <strong>Tamaño:</strong>
          </td>
          <td className="text-left text-muted">
            {(attachment.size && fileSize(attachment.size)) || <em>Sin información</em>}
          </td>
        </tr>
        <tr>
          <td className="text-right pr-2">
            <strong>Fecha:</strong>
          </td>
          <td className="text-left text-muted">{fromIso8601(attachment.createdAt)}</td>
        </tr>
      </tbody>
    </table>
  </Col>
);

const validate = Validates(presence({ of: 'title' }));

const AttachmentEditorForm = ({ exitEditingMode, saveItem, handleSubmit, pristine, invalid, submitting, error }) => (
  <div>
    <Form error={error} noValidate onSubmit={handleSubmit}>
      <Field name="title" placeholder="Título" />
      <Field name="description" type="textarea" rows="3" placeholder="Descripción" />
      <div>
        <ProcessingButton
          type="submit"
          color="primary"
          width="6.5em"
          disabled={pristine || invalid}
          processing={submitting}
        >
          Guardar
        </ProcessingButton>{' '}
        <Button role="button" onClick={exitEditingMode}>
          Cancelar
        </Button>
      </div>
    </Form>
  </div>
);

const AttachmentEditor = ({ attachment: { token, title, originalFilename, description } }, isAdmin) =>
  compose(
    connect(null, (dispatch) => ({
      editingNotification: (name) =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Archivo editado',
            text: `El archivo ${name} ha sido editado exitosamente.`,
            color: 'success',
          }),
        ),
    })),
    withHandlers({
      onSubmit: (props) => async (data) => {
        const { resourceId, resourceType } = props;

        const payload = {
          token,
          resourceId,
          resourceType,
          ...data,
        };

        const { body, status } = isAdmin ? await updateAdminAttachment(payload) : await updateAttachment(payload);

        if (status === 200) {
          const { title, description } = body;

          props.exitEditingMode();
          props.editingNotification(title);
          props.onSuccessfulEdition({ title, description });
        } else if (status === 422) {
          throw new SubmissionError(body.errors);
        }
      },
    }),
    reduxForm({
      form: `attachmentEditor-${token}`,
      validate,
      initialValues: {
        title: title || originalFilename,
        description,
      },
    }),
  )(AttachmentEditorForm);

const AttachmentCardContent = ({ attachment, enterEditingMode, onDelete }) => (
  <div>
    <h5>{attachment.title || attachment.originalFilename || <em>Sin título</em>}</h5>
    {attachment.originType === 'Core::Movement' && (
      <div className="small text-muted mt-1">
        <FontAwesome name="info" className="mr-1 text-info" />
        Cargado desde movimiento #<a href={`/movements/${attachment.originId}`}>{attachment.originId}</a>
      </div>
    )}

    {attachment.description && <div className="mt-2">{attachment.description}</div>}

    <div className="mt-3">
      <Button role="button" color="primary" size="sm" tag="a" href={attachment.file.url} target="_blank">
        <FontAwesome name="download" /> Descargar
      </Button>{' '}
      <ShowForRoles excluded={['guest']}>
        <Button onClick={enterEditingMode} role="button" outline color="success" size="sm">
          <FontAwesome name="pencil-alt" /> Editar
        </Button>{' '}
        <Button role="button" outline color="danger" size="sm" onClick={onDelete}>
          <FontAwesome name="times" /> Eliminar
        </Button>
      </ShowForRoles>
    </div>
  </div>
);

const enhance = compose(
  withState('editingMode', 'setEditingMode', false),
  withState('attachment', 'setAttachment', ({ attachment }) => attachment),
  withHandlers({
    enterEditingMode: ({ setEditingMode }) => () => setEditingMode((v) => true),
    exitEditingMode: ({ setEditingMode }) => () => setEditingMode((v) => false),
    updateAttachmentParams: ({ setAttachment }) => ({ title, description }) =>
      setAttachment((att) => ({ ...att, title, description })),
  }),
);

const AttachmentCard = ({
  attachment,
  editingMode,
  enterEditingMode,
  exitEditingMode,
  resourceType,
  resourceId,
  isAdmin,
  updateAttachmentParams,
  onDelete,
}) => {
  const info = fileInfoFor(attachment.contentType);
  const Editor = AttachmentEditor({ attachment }, isAdmin);

  return (
    <Card className="mb-4" outline={attachment.$created} color={attachment.$created ? 'success' : undefined}>
      <CardBody>
        <Row>
          <ItemInfoCard info={info} attachment={attachment} />
          <Col xs={12} md={8}>
            {!editingMode && (
              <AttachmentCardContent
                enterEditingMode={enterEditingMode}
                attachment={attachment}
                onDelete={() => onDelete(attachment.token)}
              />
            )}
            {editingMode && (
              <Editor
                exitEditingMode={exitEditingMode}
                resourceType={resourceType}
                resourceId={resourceId}
                onSuccessfulEdition={updateAttachmentParams}
              />
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default enhance(AttachmentCard);
