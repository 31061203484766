import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared/';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Badge, UncontrolledTooltip } from 'reactstrap';
import { AssetDisplay, ItemDisplay } from '@shared/displays';

export const InventoryCheckerSetDetail = ({ inventoryCheckerSet, isOpen, toggle, activeAutomation }) => {
  const FREQUENCY = {
    daily: 'Diaria',
    weekly: 'Semanal',
    monthly: 'Mensual',
    yearly: 'Anual',
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {inventoryCheckerSet && isOpen && (
          <Row>
            <Col sm={8}>
              <h5>Detalle de la programación</h5>
              <div>
                <small className="text-muted">Creado: {fromIso8601(inventoryCheckerSet?.createdAt, true)}</small>
              </div>
            </Col>
            <Col sm={4}>
              <Row>
                <Col></Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <small>Estado</small>
                  <Badge color={inventoryCheckerSet?.active ? 'success' : 'danger'}>
                    {inventoryCheckerSet?.active ? 'Habilitado' : 'Inhabilitado'}
                  </Badge>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </ModalHeader>
      <ModalBody>
        {inventoryCheckerSet && isOpen && (
          <div>
            <Row>
              <Col>
                <strong>Instalación:</strong> {inventoryCheckerSet.building.name}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Creado por:</strong> {inventoryCheckerSet.user.name}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Tipo:</strong>{' '}
                <Badge color={inventoryCheckerSet.consumable ? 'primary' : 'primary'}>
                  {inventoryCheckerSet?.consumable ? 'Consumibles' : 'Activos'}
                </Badge>
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Muestra aleatoria:</strong> {inventoryCheckerSet.randomItems ? 'Sí' : 'No'}
              </Col>
            </Row>
            <Row>
              {inventoryCheckerSet.randomItems && (
                <Col>
                  <strong>Tamaño del lote:</strong> {inventoryCheckerSet.size}
                </Col>
              )}
            </Row>
            <hr />
            <Row>
              <Col>
                <strong>Inicio:</strong> {fromIso8601(inventoryCheckerSet.startAt)}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Fin: </strong> {fromIso8601(inventoryCheckerSet.endAt)}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Periodicidad:</strong> {FREQUENCY[inventoryCheckerSet.frequency]}
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Última Orden:</strong> {fromIso8601(inventoryCheckerSet.lastOrderAt)}
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <strong>Auditores:</strong>
              </Col>
            </Row>
            {inventoryCheckerSet?.auditors.map((auditor, index) => (
              <Row key={`auditor-${index}`}>
                <Col>
                  <a href={`mailto:${auditor.email}`}>{`${auditor.name}: ${auditor.email}`}</a>
                </Col>
              </Row>
            ))}
            {!inventoryCheckerSet.randomItems && <hr />}
            <Row>
              <Col>{!inventoryCheckerSet.randomItems && <strong className="mb-3">Muestras:</strong>}</Col>
            </Row>
            <Row>
              <Col>
                {inventoryCheckerSet?.assets?.length > 0 &&
                  inventoryCheckerSet.assets.map((asset, index) => (
                    <Row className="mb-1 mt-2">
                      <AssetDisplay key={`asset-${index}`} asset={asset} />
                    </Row>
                  ))}
                {inventoryCheckerSet?.items?.length > 0 &&
                  inventoryCheckerSet.items.map((item, index) => (
                    <Row className="mb-1 mt-2">
                      <ItemDisplay
                        key={`item-${index}`}
                        item={item}
                        inventory={true}
                        sku={true}
                        inventoryCheckerSet={true}
                      />
                    </Row>
                  ))}
              </Col>
            </Row>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {inventoryCheckerSet && isOpen && (
          <div>
            <Button
              id={`ActiveButtonModal-${inventoryCheckerSet.id}`}
              role="button"
              className="mb-1 mr-1"
              color={inventoryCheckerSet.active ? 'danger' : 'success'}
              onClick={() => activeAutomation(inventoryCheckerSet)}
              disable={inventoryCheckerSet.endAt > new Date()}
            >
              {inventoryCheckerSet?.active ? 'Cancelar' : 'Activar'}{' '}
              <FontAwesome name={inventoryCheckerSet?.active ? 'toggle-off' : 'toggle-on'} />
            </Button>
            <UncontrolledTooltip placement="auto" target={`ActiveButtonModal-${inventoryCheckerSet.id}`}>
              {inventoryCheckerSet?.active ? 'Cancelar' : 'Activar'}
            </UncontrolledTooltip>
            <Button role="button" onClick={toggle}>
              Cerrar
            </Button>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};
