import { SubmissionError } from '@core/forms';
import { createLocation } from '@core/organization/locations';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LocationForm from './components/LocationForm';
import { buildGeofencePayload } from './utils/util';

class NewLocationPresentation extends Component {
  state = {
    created: false,
  };

  createLocation = async (data) => {
    const payload = buildGeofencePayload(data, this.props.currentOrganizationId);
    const { body, status } = await createLocation(payload);

    if (status === 201) {
      this.setState({
        created: true,
      });

      this.props.successNotification(data.name);
    } else if (status === 422) throw new SubmissionError(body.errors);
  };

  render() {
    const { created } = this.state;

    if (created) return <Redirect to="/organization/locations" />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Nueva Geocerca">
          <LocationForm initialValues={{ color: '#37d67a' }} onSubmit={this.createLocation} />
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let organizationId = undefined;
  const { session } = state;

  if (session.currentMembership && session.user) {
    const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

    if (membership) organizationId = membership.building.organization.id;
  }
  return {
    currentOrganizationId: organizationId,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    successNotification: (name) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Geocerca creada',
          text: `La geocerca ${name} ha sido creada exitosamente.`,
          color: 'success',
        }),
      ),
  };
};

export const NewLocation = connect(mapStateToProps, mapDispatchToProps)(NewLocationPresentation);
