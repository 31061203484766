import { getKits } from '@core/building/kits';
import { FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { KitsTable } from '@shared/kits';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { KitsFilterModal } from './components/KitsFilterModal';

export class KitsIndex extends SimpleFilter {
  searchFunction = getKits;

  setKitStatus = () => {
    this.handleFilter(this.state.filters, true);
  };

  render() {
    const { items, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles excluded={['guest']}>
                <Button tag={NavLink} size="sm" outline color="success" to="/building/kits/new">
                  <FontAwesome name="plus" /> Crear Kit
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="shopping-basket" className="text-primary mr-2" />
              Gestión de kits
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <KitsTable
                    kits={items}
                    pagination={pagination}
                    setKitStatus={this.setKitStatus}
                    showBarcode={false}
                  />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <KitsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={this.state.filters}
        />
      </BuildingLayout>
    );
  }
}
