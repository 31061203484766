import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, Markdown, ShowForRoles, UserPortrait } from '@shared';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Badge,
  Button,
  Input,
  Label,
  ListGroupItem,
  ListGroupItemHeading,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { connect, sessionGenerators } from '@store';

// In hours, 1 week
const NewPostInterval = 24 * 7;

const PostContent = ({ post: { id, title, content, user, postedAt }, toggle, show, auto = false, dontShowAgain }) => (
  <Modal size="lg" isOpen={show} toggle={toggle}>
    <ModalHeader toggle={toggle}>{title}</ModalHeader>
    <ModalBody>
      <Media className="mb-3 align-items-center">
        <Media left className="mr-2">
          <UserPortrait user={user} />
        </Media>
        <Media body className="small">
          <div>Publicado por {user.name}</div>
          <div>
            Fecha de publicación: <span className="text-muted">{fromIso8601(postedAt)}</span>
          </div>
        </Media>
      </Media>
      <Markdown source={content} />
    </ModalBody>
    <ModalFooter>
      {auto && (
        <div className="text-left">
          <Label check>
            <Input type="checkbox" name="skip" id="skip" onChange={dontShowAgain} className="mr-2" /> No volver a
            preguntar
          </Label>
        </div>
      )}
      <div className="text-right">
        <ShowForRoles rootAccepted={['root']}>
          <Button role="button" className="ml-3" color="success" tag={NavLink} to={`/admin/posts/${id}/edit`}>
            <FontAwesome name="pencil-alt" /> Editar
          </Button>
        </ShowForRoles>
        <Button role="button" className="ml-3" onClick={toggle}>
          Cerrar
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);

class PostCard extends Component {
  state = {
    modal: false,
  };

  ancient = new Date().getTime() - new Date(this.props?.post?.postedAt).getTime() <= NewPostInterval * 3600000;

  async componentDidMount() {
    const { hideAutomaticPostModal, first } = this.props;
    if (
      first &&
      this.ancient &&
      (hideAutomaticPostModal?.id !== this.props?.post?.id ||
        (hideAutomaticPostModal?.id === this.props?.post?.id && !hideAutomaticPostModal?.hide))
    ) {
      this.setState({ modal: true });
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  render() {
    const { modal } = this.state;
    const { first, post, dontShowAgain, hideAutomaticPostModal } = this.props;
    const { title, postedAt, user } = post;

    return (
      <ListGroupItem className={`${first && 'border-top-0'} d-block post-card`} role="button" onClick={this.toggle}>
        <ListGroupItemHeading className="w-100 pb-2 text-overflow">{title}</ListGroupItemHeading>
        {this.ancient && (
          <div className="float-right">
            <Badge color="danger">¡Nuevo!</Badge>
          </div>
        )}
        <div className="small text-muted">
          <FontAwesome name="user" /> {user.name}
        </div>
        <div className="small text-muted">
          <FontAwesome name="calendar-alt" />
          {fromIso8601(postedAt)}
        </div>
        <PostContent
          post={post}
          auto={this.ancient && first}
          toggle={this.toggle}
          show={modal}
          ancient={this.ancient}
          dontShowAgain={(e) => {
            console.log(e, e.target.checked, post.id !== hideAutomaticPostModal?.id, first, post);
            if (first && e.target.checked) {
              dontShowAgain({ id: post?.id, hide: true });
            } else {
              dontShowAgain({ id: post?.id, hide: false });
            }
          }}
        />
      </ListGroupItem>
    );
  }
}

export default connect(
  (state) => ({ hideAutomaticPostModal: state.session.hideAutomaticPostModal }),
  (dispatch) => ({
    dontShowAgain: (payload) => dispatch(sessionGenerators.SetHideAutomaticPostModal(payload)),
  }),
)(PostCard);
