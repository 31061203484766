import { apiFetch, withToken, handleAuth } from './http';
import { appDataGenerators, store } from '@store';

export const getAppData = (email, passowrd) =>
  withToken(apiFetch)({
    endpoint: '/',
    method: 'GET',
    parseResponse: false,
  });

export const getAppDataVersion = () =>
  withToken(apiFetch)({
    endpoint: '/check-version',
    method: 'GET',
    parseResponse: false,
  });

export const login = async (email, password) => {
  const { body, status, headers } = await apiFetch({
    endpoint: '/session',
    method: 'POST',
    body: {
      user: {
        email,
        password,
      },
    },
  });

  const { body: data } = await getAppData();

  // Save roles in appData
  store.dispatch(appDataGenerators.setAppData(data));

  return { body, status, headers };
};

export const refreshToken = () =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: '/session',
      method: 'GET',
    }),
  );

export const logout = () =>
  withToken(apiFetch)({
    endpoint: '/session',
    method: 'DELETE',
  });
