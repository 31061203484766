import { getTelemetry } from '@core/building/telemetries';
import { Spinner } from '@shared';
import { TelemetriesTable } from '@shared/telemetries';
import React, { useState, useEffect } from 'react';

export default function AssetTelemetryPresentation({ asset }) {
  const [telemetries, setTelemetries] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({});
  useEffect(() => {
    (async () => {
      const { body, status, pagination } = await getTelemetry(asset.id);
      console.log('respuesta desde el endpoint show', body);
      if (status === 200) {
        setTelemetries(body);
        setPagination(pagination);
        setIsLoading(false);
      }
    })();
  }, [asset.id]);
  const setPage = async (page) => {
    setIsLoading(true);
    const { body } = await getTelemetry(asset.id, page);
    setPagination({ ...pagination, page });
    setTelemetries(body);
    setIsLoading(false);
  };
  console.log(pagination);
  return (
    <div>
      {isLoading ? (
        <div className="mt-4 mb-4 text-info text-center h2">
          <Spinner />
        </div>
      ) : (
        <div>
          <h4 className="mb-4">Lecturas de Telemetría</h4>
          <TelemetriesTable
            setPage={setPage}
            pagination={pagination}
            telemetries={telemetries}
            showAssetColumn={false}
            showState={true}
          />
        </div>
      )}
    </div>
  );
}
