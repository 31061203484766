import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { CategoryRow } from './CategoryRow';

export const CategoriesResults = ({ categories, pagination, deleteCategory, processing }) => {
  return (
    <div>
      {pagination && (
        <div className="small mb-4">
          <strong>{pagination.count}</strong>{' '}
          {pluralize(pagination.count, 'categorias encontrada', 'categorias encontradas')}. Mostrando{' '}
          <strong>{pagination.per}</strong> categorias por página.
        </div>
      )}
      <Table>
        <colgroup>
          <col style={{ width: '80px' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: '35%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th>Nombre</th>
            <th>Descripción</th>
            <th className="text-right" />
          </tr>
        </thead>
        <tbody>
          {categories.map((category, index) => (
            <CategoryRow
              category={category}
              key={index}
              onDelete={() => deleteCategory(index)}
              processing={processing === index}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
