import { FontAwesome, OrganizationCard } from '@shared';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Button, Media } from 'reactstrap';
import { REGIONS } from './regions';

class BuildingRowPresentation extends Component {
  state = {
    deleted: false,
  };

  render() {
    const { building } = this.props;

    return (
      <tr>
        <td className="align-middle">{building.name}</td>
        <td className="align-middle small">
          <Media className="align-items-center" tag={NavLink} to={`/admin/organizations/${building.organization.id}`}>
            <Media left className="mr-1" tag="div">
              <OrganizationCard
                width="4.5rem"
                height="3rem"
                url={building.organization.avatar.url}
                alt={building.organization.name}
              />
            </Media>
            <Media body tag="div">
              <div>{building.organization.name}</div>
              <div>
                <code>{building.organization.contactPhone}</code>
              </div>
            </Media>
          </Media>
        </td>
        <td className="align-middle text-center">{building.address1}</td>
        <td className="align-middle text-center">{REGIONS[building.province]}</td>
        <td className="align-middle text-center">
          <Badge color={building.active ? 'success' : 'danger'} pill>
            {building.active ? 'Activa' : 'Inactiva'}
          </Badge>
        </td>
        <td className="align-middle text-center">
          <Badge color={building.publishAssets ? 'warning' : 'success'} pill>
            {building.publishAssets ? 'Públicos' : 'Privados'}
          </Badge>
        </td>
        <td className="align-middle text-center">{building.sapIntegration ? 'Sí' : 'No'}</td>
        <td className="align-middle text-center">{building.buildingType?.name}</td>
        <td className="align-middle text-right">
          <Button role="button" size="sm" color="primary" tag={NavLink} to={`/admin/buildings/${building.id}`}>
            <FontAwesome name="pencil-alt" />
          </Button>
        </td>
      </tr>
    );
  }
}

export const BuildingRow = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo eliminado',
        text: 'El dispositivo ha sido eliminado correctamente.',
        color: 'danger',
      }),
    ),
}))(BuildingRowPresentation);
