import { handleAuth, apiFetch, mountQuery, withMembership } from '../http';

export const getEvents = (query = {}) => {
  return handleAuth(
    withMembership(apiFetch)({
      endpoint: `/events${mountQuery(query)}`,
      method: 'GET',
    }),
  );
};

export const getEventsMRO = (query = {}) => {
  return handleAuth(
    withMembership(apiFetch)({
      endpoint: `/mro/events/${mountQuery(query)}`,
      method: 'GET',
    }),
  );
};
