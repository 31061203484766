import { apiFetch } from './http';

export const recoverPassword = (email) =>
  apiFetch({
    endpoint: '/account/recovery',
    method: 'POST',
    body: {
      user: { email },
    },
  });

export const checkPasswordToken = ({ uid, token }) =>
  apiFetch({
    endpoint: `/account/recovery/${uid}/${token}`,
    method: 'GET',
  });

export const changePassword = ({ uid, token, password, passwordConfirmation }) =>
  apiFetch({
    endpoint: `/account/recovery/${uid}/${token}`,
    method: 'PUT',
    body: {
      user: {
        password,
        passwordConfirmation,
      },
    },
  });

export const checkLockToken = ({ token }) =>
  apiFetch({
    endpoint: `/account/lock/${token}`,
    method: 'GET',
  });

export const unlockAccount = ({ token, password, passwordConfirmation }) =>
  apiFetch({
    endpoint: `/account/lock/${token}`,
    method: 'PUT',
    body: { password, passwordConfirmation },
  });
