import { fromIso8601 } from '@core/utils/dates';
import { Barcode, FontAwesome, PictureDisplay } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Media } from 'reactstrap';

export const DeviceRow = ({ device }) => (
  <tr>
    <th scope="row" className="align-middle small">
      <Media className="align-items-center">
        <Media left className="mr-2">
          <Barcode code={device.barcode1} style={{ height: '2.5rem' }} />{' '}
        </Media>
        <Media body>
          <NavLink to={`/building/devices/${device.barcode1}`}>
            <kbd>{device.barcode1}</kbd>
            <FontAwesome name="search" className="ml-1" />
          </NavLink>
          <div className="mt-1">
            <div className="w-100">
              {device.active && (
                <Badge color="success">
                  <FontAwesome name="check" className="mr-1" /> Activo
                </Badge>
              )}
              {!device.active && (
                <Badge color="danger">
                  <FontAwesome name="times" className="mr-1" /> Inactivo
                </Badge>
              )}
            </div>
          </div>
        </Media>
      </Media>
    </th>
    <td className="align-middle small">
      <Media className="align-items-center">
        <Media left className="mr-1" tag="div">
          <PictureDisplay
            picture={device.defaultPicture}
            pictureSize="icon"
            alt={device.name}
            width="3rem"
            height="2rem"
          />
        </Media>
        <Media body tag="div">
          <NavLink to={`/building/devices/${device.barcode1}`}>{device.name} </NavLink>
          <div className="w-100 text-overflow">
            <span className="text-muted mr-1">
              <FontAwesome name="caret-right" className="mr-1" />
              Modelo: {device.deviceModel}
            </span>
          </div>
        </Media>
      </Media>
    </td>
    <td className="align-middle text-center small">{device.deviceType && device.deviceType.description}</td>
    <td className="align-middle text-center small">
      {fromIso8601(
        device.detectedAt
          ? device.detectedAt
          : (device?.deviceType?.name === 'vending' || device?.deviceType?.name === 'lockers') && device.updatedAt,
      )}
    </td>
  </tr>
);
