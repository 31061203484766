import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getMovement = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/movements/${id}`,
      method: 'GET',
    }),
  );

export const getMovements = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/movements${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getLastMovement = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/movements/last${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createMovement = (movement) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/movements',
      method: 'POST',
      body: { movement },
    }),
  );
