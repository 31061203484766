import { getPartner } from '@core/building/partners';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { Loading } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { PartnerHeader } from './components/PartnerHeader';
import { PartnerLayout } from './components/PartnerLayout';
import { PartnerEdit } from './components/PartnerEdit';
import { PartnerSummary } from './components/PartnerSummary';

class PartnerPagePresentation extends Component {
  state = {
    notFound: false,
    loading: true,
    partner: {},
  };

  async componentDidMount() {
    console.log(this.props.match.params);
    const { id } = this.props.match.params;

    if (!id) {
      this.notFound();
      return;
    }

    const { body: partner, status } = await getPartner(id);

    if (status === 404) {
      this.notFound();
      return;
    } else if (status === 200) {
      this.setState({
        loading: false,
        partner,
      });
    }
  }

  notFound = () => {
    this.setState({ notFound: true });
    this.props.notFound();
  };

  render() {
    const { match } = this.props;
    const { loading, partner, notFound } = this.state;

    if (notFound) return <Redirect to="/building/partners" />;

    if (loading) return <Loading />;

    return (
      <BuildingLayout>
        <div className="container-fluid">
          <Row className="justify-content-center">
            <Col>
              {partner && (
                <div>
                  <PartnerHeader partner={partner} />

                  <PartnerLayout partner={partner}>
                    <Switch>
                      <ProtectedRoute
                        exact
                        path={`${match.url}/edit`}
                        render={(props) => <PartnerEdit partner={partner} {...props} />}
                        id={partner.id}
                      />
                      <ProtectedRoute
                        exact
                        path={`${match.url}/`}
                        render={() => <PartnerSummary partner={partner} />}
                      />
                    </Switch>
                  </PartnerLayout>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </BuildingLayout>
    );
  }
}

export const PartnerPage = connect(null, (dispatch) => ({
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Socio de Negocios no encontrado',
        text: 'El socio de negocio no existe. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(PartnerPagePresentation);
