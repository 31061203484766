import { editItem } from '@core/building/items';
import { SubmissionError } from '@core/forms';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ItemForm } from './ItemForm';

class EditItemPresentation extends Component {
  state = {
    edited: false,
  };

  editItem = async (redirect, reset, data) => {
    const {
      item: { barcode1 },
    } = this.props;

    if (!data.consumable) {
      data.areaId = null;
      data.criticality = null;
      data.unitId = null;
      data.machineTypeId = null;
    }

    const { body, status } = await editItem({ barcode1, ...data });

    if (status === 200) {
      this.props.setItem(body);
      this.props.successNotification();
      this.setState({ edited: true });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { edited } = this.state;
    const { item } = this.props;
    let initialValues = {};

    if (edited) return <Redirect to={`/organization/items/${item.barcode1}`} />;
    if (item.consumable) {
      initialValues = {
        ...item,
        areaId: item.area ? item.area.id : undefined,
        unitId: item.unit ? item.unit.id : undefined,
        machineTypeId: item.machineType ? item.machineType.id : undefined,
      };
    } else {
      initialValues = {
        ...item,
      };
    }

    return <ItemForm onSubmit={this.editItem} initialValues={initialValues} editMode={true} />;
  }
}

export const EditItem = connect(null, (dispatch) => ({
  successNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Artículo editado',
        text: 'El artículo ha sido editado exitosamente.',
        color: 'success',
      }),
    ),
}))(EditItemPresentation);
