import { searchInventoryByBuilding } from '@core/building/inventory';
import { InventoryBase } from '@shared/inventory';
import React from 'react';

export const BuildingInventory = (props) => (
  <InventoryBase
    search={searchInventoryByBuilding}
    fileName={'inventory/building'}
    enableActions={true}
    comparatorUrl="/building/inventory/compare"
    {...props}
  />
);
