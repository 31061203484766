import React from 'react';
import { Media } from 'reactstrap';

import { getDeviceBrand, getDeviceBrands } from '@core/device-brands';
import { RemoteSelector } from '@core/forms';

const DeviceBrandCard = ({ resource: device }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{device.name}</strong>
      </div>
      <div className="text-muted">{device.description}</div>
    </Media>
  </Media>
);

const DeviceBrandSelector = ({ inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getDeviceBrand}
    remoteFinder={(query) =>
      getDeviceBrands({
        name: query,
      })
    }
    dropdownTemplate={DeviceBrandCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar marca del dispositivo por nombre...',
    }}
  />
);

export default DeviceBrandSelector;
