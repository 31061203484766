import { updateDevice } from '@core/admin/devices';
import { SubmissionError } from '@core/forms';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { DeviceForm } from './DeviceForm';
import circleToPolygon from 'circle-to-polygon';

class EditDevicePresentation extends Component {
  state = {
    edited: false,
  };

  editDevice = async (device) => {
    console.log(device, !!device.lat || device.lat === 0, !!device.lng || device.lng === 0);
    if ((!!device.lat || device.lat === 0) && (!!device.lng || device.lng === 0)) {
      const coordinates = [Number(device.lng), Number(device.lat)]; //[lon, lat]
      const radius = device.radius ? Number(device.radius) : 50; // in meters
      const numberOfEdges = 360; //optional that defaults to 32
      device['polygon'] = circleToPolygon(coordinates, radius, numberOfEdges);
    }

    const { body, status } = await updateDevice({ ...device });

    if (status === 200) {
      this.props.setDevice(body);
      this.props.successNotification();
      this.setState({ edited: true });
    } else if (status === 422) {
      // handle metadata errors
      if (!!body.errors.metadata) {
        let metadata = {};
        body.errors.metadata.forEach((error_fields) => {
          metadata = { ...metadata, ...error_fields };
        });
        body.errors.metadata = metadata;
      }
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { edited } = this.state;
    const { device } = this.props;

    if (edited) return <Redirect to={`/admin/devices/${device.barcode1}`} />;

    return <DeviceForm onSubmit={this.editDevice} initialValues={{ ...device, deviceType: device.deviceType.id }} />;
  }
}

export const EditDevice = connect(null, (dispatch) => ({
  successNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo editado',
        text: 'El dispositivo ha sido editado exitosamente.',
        color: 'success',
      }),
    ),
}))(EditDevicePresentation);
