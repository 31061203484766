import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import React from 'react';
import { ReadoutsMap } from './readouts-map';

const MapRoutes = ({ match }) => (
  <Switch>
    <ProtectedRoute
      exact
      path={`${match.url}/`}
      component={ReadoutsMap}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'assistant', 'inspector', 'client', 'guest']}
    />
  </Switch>
);

export default MapRoutes;
