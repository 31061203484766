import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardHeader, Table, Badge } from 'reactstrap';
import { orderBy } from 'lodash';
import { buildingDownload } from '../../../@core/http.js';
import { getUsers } from '../../../@core/building/reports';
import { UserPortrait, ExcelDownload, ShowForRoles } from '@shared';
import { IdentifierDisplay } from '../../../@shared/displays';

import './UserList.scss';

type State = {
  reatime: boolean,
  data: Object[],
};

const UserRow = ({ user, maintenance, borrowed }) => (
  <tr key={user.id}>
    <td style={{ width: '60%' }} className="align-middle text-overflow">
      <NavLink className="d-flex pl-2" to={`/building/users/${user.id}/assets`} target="blank">
        <UserPortrait user={user} />
        <span className="ml-2" style={{ lineHeight: '2rem' }}>
          {user.firstName} {user.lastName}
        </span>
      </NavLink>
    </td>
    <td style={{ width: '20%' /*, paddingTop: '0.5rem' */ }} className="align-middle text-overflow text-center">
      <IdentifierDisplay identifier={user.identifier} />
    </td>
    <td style={{ width: '20%' /*, paddingTop: '0.45rem' */ }} className="align-middle text-overflow text-center">
      <Badge color={borrowed === 0 ? 'success' : 'warning'} pill>
        {borrowed}
      </Badge>
    </td>
  </tr>
);

export default class UserList extends React.Component<*, State> {
  state = {
    data: [],
  };

  async componentDidMount() {
    const response = await getUsers();
    this.handleResponse(response);
  }

  handleResponse = (response: Response) => {
    const { status, body } = response;
    if (status === 200) {
      this.setState({
        data: orderBy(body || [], 'total', 'desc'),
      });
    }
  };

  render() {
    const { data } = this.state;

    return (
      <Card>
        <CardHeader className="chart-title-container">
          <ShowForRoles excluded={['guest']}>
            <span className="float-right">
              <span
                style={{
                  display: 'flex',
                  verticalAlign: 'middle',
                }}
              >
                <ExcelDownload file="reports/users" downloadFile={buildingDownload} disabled={!data} filters={{}} />
              </span>
            </span>
          </ShowForRoles>
          <h4>Usuarios Morosos</h4>
        </CardHeader>
        <CardBody className="no-pad">
          <Table size="sm" className="table-scroll table-scroll-users">
            <thead>
              <tr>
                <th style={{ width: '60%' }} className="pl-3">
                  Nombre
                </th>
                <th style={{ width: '20%' }} className="text-center">
                  Identificador
                </th>
                <th style={{ width: '20%' }} className="text-center">
                  Préstamo
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((current) => (
                <UserRow
                  key={current.user.id}
                  user={current.user}
                  maintenance={current.maintenance}
                  borrowed={current.borrowed}
                />
              ))}
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={2} style={{ width: '80%' }} className="pl-3">
                  Total
                </th>
                <th style={{ width: '20%' }} className="text-center">
                  {data.reduce((prev, current, index, arr) => prev + current.borrowed, 0)}
                </th>
              </tr>
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    );
  }
}
