import { handleAuth, withMembership, apiFetch, mountQuery } from '../http';

export const getDevices = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getDevice = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices/${id}`,
      method: 'GET',
    }),
  );

export const getDeviceKeys = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices/${id}/keys`,
      method: 'GET',
    }),
  );

export const getDeviceInventory = ({ id, ...query }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/device_inventory/${id}${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getDeviceInventoryByBuilding = ({ ...query }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/device_inventory${mountQuery(query)}`,
      method: 'GET',
      parseResponse: false,
    }),
  );

export const getDeviceStocks = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices/${id}/stocks`,
      method: 'GET',
    }),
  );
