import { FontAwesome } from '@shared/';
import React from 'react';
import { Media } from 'reactstrap';
import { EventAsset, EventAssetModal } from './EventAsset';

export const EventTag = ({ tag }) => (
  <Media>
    {tag && (
      <Media body>
        <EventAsset asset={tag.asset} />
        {tag.tagType && (
          <div className="w-100 text-overflow mt-1">
            <span className="text-muted mr-1">
              <FontAwesome name="tag" color="info" className="mr-1" />
              Tipo:
            </span>
            <code>{tag.tagType?.description}</code>
          </div>
        )}
        {tag.identifier && (
          <div className="w-100 text-overflow mt-1 align-middle small">
            <FontAwesome name="tag" color="info" className="mr-1" />
            <kbd>{tag.identifier}</kbd>
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventTagModal = ({ tag }) => (
  <div>
    <Media>
      {tag && (
        <Media body>
          <EventAssetModal asset={tag.asset} />
          {tag.tagType && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="tag" color="info" className="mr-1" />
                Tipo:
              </span>
              <code>{tag.tagType?.description}</code>
            </div>
          )}
          {tag.identifier && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="tag" color="info" className="mr-1" />
                Identificador:
              </span>
              <kbd>{tag.identifier}</kbd>
            </div>
          )}
        </Media>
      )}
    </Media>
  </div>
);
