import { getLocation } from '@core/organization/locations';
import { fromIso8601, toDate } from '@core/utils/dates';
import { shortName } from '@core/utils/names';
import { FontAwesome } from '@shared';
import { pluralize } from '@shared/helpers';
import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Alert, Badge, Button, Col, Media, Row, Table } from 'reactstrap';

const GeofenceName = ({ id }) => {
  const [name, setName] = useState(undefined);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchGeofence = async () => {
      const { body } = await getLocation(id);
      setLoading(false);

      setName(body.name ? body.name : 'No se puede determinar');
    };
    fetchGeofence();
  }, [id]);

  return (
    <React.Fragment>
      {loading && <FontAwesome name="spinner" spin />}
      {!loading && <span>{name}</span>}
    </React.Fragment>
  );
};

const MovementsTableRow = ({
  actions = [],
  errors = [],
  movement,
  movementTypes,
  showInfo,
  showNumber,
  showTarget,
  showActions,
}) => {
  return (
    <tr>
      {showNumber && <td className="align-middle text-center small">{movement.id}</td>}
      {showTarget && (
        <td className="align-middle">
          <Row className="align-items-center ml-1">
            {showTarget && (
              <Col xs="auto">
                {movement.consumable && (
                  <div>
                    <div className="small text-muted">Artículo involucrado </div>
                    {movement.item.deletedAt ? (
                      <div>
                        <div>
                          {movement.item.name}
                          <Badge color="danger" className="ml-1 small pull">
                            Consumible
                          </Badge>
                        </div>
                        <kbd className="small">{movement.item.barcode1}</kbd>
                      </div>
                    ) : (
                      <NavLink to={`/organization/items/${movement.item.barcode1}`}>
                        <div>
                          {movement.item.name}
                          <Badge color="danger" className="ml-1 small">
                            Consumible
                          </Badge>
                        </div>
                        <kbd className="small">{movement.item.barcode1}</kbd>
                      </NavLink>
                    )}
                  </div>
                )}

                {!movement.consumable && (
                  <div>
                    <div className="small text-muted">Activo involucrado</div>
                    <NavLink to={`/building/assets/${movement.asset.barcode1}`}>
                      <kbd className="small">{movement.asset.barcode1}</kbd>
                    </NavLink>
                    <div className="small mt-1">
                      <div className="w-100 text-overflow">
                        <span className="text-muted mr-1">
                          <FontAwesome name="caret-right" className="mr-1" />
                          Artículo:
                        </span>
                        <NavLink to={`/organization/items/${movement.asset.item.barcode1}`}>
                          {movement.asset.item.name}
                        </NavLink>
                      </div>
                      {movement.asset?.mark && (
                        <div className="w-100 text-overflow">
                          <span className="text-muted mr-1">
                            <FontAwesome name="caret-right" className="mr-1" />
                            Rótulo:
                          </span>
                          <code>{movement.asset.mark}</code>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Col>
            )}
          </Row>
        </td>
      )}
      <td className="align-middle">
        <Row>
          <Col>
            <Media className={`${showTarget && 'ml-2'} align-items-center`}>
              <Media left className="mr-1 h4 text-info">
                <FontAwesome name="caret-right" />
              </Media>
              <Media body className="d-block small">
                <div>
                  <strong>{get(movementTypes[movement.movementType], 'name', movement.movementType)}</strong>
                </div>
                {movement.consumable && (
                  <div>
                    Cantidad:{' '}
                    {movement?.extendedAttributes?.decimalQuantity
                      ? movement.extendedAttributes.decimalQuantity
                      : movement.quantity}
                  </div>
                )}
                <div>
                  Fecha: <span className="text-muted">{fromIso8601(movement.movementAt)}</span>
                </div>
                {!movement.consumable && movement.expectedAt && (
                  <div>
                    Retorno: <span className="text-muted">{toDate(movement.expectedAt)}</span>
                  </div>
                )}
              </Media>
            </Media>
          </Col>
        </Row>
        {errors && Object.keys(errors).length > 0 && (
          <Alert color="danger" className="mt-2 p-2 small">
            <div>Esta fila tiene errores. Asegúrate que el activo o artículo no haya sido actualizado.</div>
            <ul className="mb-1 mt-1">
              {Object.keys(errors).map((key, i) =>
                errors[key].map((error, j) => (
                  <li key={`${i}-${j}`}>
                    {key !== 'base' && key} {error}
                  </li>
                )),
              )}
            </ul>
          </Alert>
        )}
      </td>
      {showInfo && (
        <td className="align-middle small">
          {['geofence-in', 'geofence-out'].includes(movement.movementType) && (
            <div>
              <div>
                <div className="text-muted">Geocerca</div>
                <NavLink to={`/organization/locations/${movement.extendedAttributes.geofence.id}`}>
                  <GeofenceName id={movement.extendedAttributes.geofence.id}></GeofenceName>
                </NavLink>
              </div>
            </div>
          )}
          {movement.client && (
            <div>
              {movement.clientType === 'Core::User' && (
                <div>
                  <div className="text-muted">Cliente</div>
                  {movement.client.deletedAt || isExternalMovement(movement) ? (
                    <div className="text-muted">
                      <FontAwesome name="user" className="mr-1" />
                      {shortName(movement.client)}
                    </div>
                  ) : (
                    <NavLink to={`/building/users/${movement.client.id}`}>
                      <FontAwesome name="user" className="mr-1" />
                      {shortName(movement.client)}
                    </NavLink>
                  )}
                </div>
              )}
            </div>
          )}
        </td>
      )}
      {showInfo && (
        <td className="align-middle small">
          <div>
            <div className="text-muted">Ingresado por</div>
            <div>
              {movement.generatorType === 'Core::User' && (
                <div>
                  {movement.generator.deletedAt || isExternalMovement(movement) ? (
                    <div className="text-muted">
                      <FontAwesome name="user" className="mr-1" />
                      {shortName(movement.generator)}
                    </div>
                  ) : (
                    <NavLink to={`/building/users/${movement.generator.id}`}>
                      <FontAwesome name="user" className="mr-1" />
                      {shortName(movement.generator)}
                    </NavLink>
                  )}
                </div>
              )}

              {movement.generatorType === 'Core::Device' && (
                <div>
                  {movement.generator.deletedAt || isExternalMovement(movement) ? (
                    <div className="text-muted">
                      <FontAwesome name="mobile-alt" className="mr-1" />
                      {movement.generator.name}
                    </div>
                  ) : (
                    <div>
                      {movement?.extendedAttributes?.operator && (
                        <div>
                          <NavLink to={`/building/users/${movement.extendedAttributes?.operator?.id}`}>
                            <FontAwesome name="user" className="mr-1" />
                            {shortName(movement?.extendedAttributes?.operator)}
                          </NavLink>
                        </div>
                      )}
                      <NavLink to={`/building/devices/${movement.generator.barcode1}`}>
                        <FontAwesome name="mobile-alt" className="mr-1" />
                        {movement.generator.name}
                      </NavLink>
                    </div>
                  )}
                </div>
              )}

              {movement.generatorType === 'Core::PickingUnit' && (
                <div>
                  <NavLink to={`/pickings/${movement.generator.movementSetId}`}>
                    <FontAwesome name="truck-moving" className="mr-1" />
                    Picking {movement.generator.pickingId}
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </td>
      )}
      {(actions.length > 0 || showActions) && (
        <td className="align-middle text-right">
          {actions}
          {showActions && (
            <Button role="button" color="primary" size="sm" tag={NavLink} to={`/movements/${movement.id}`}>
              <FontAwesome name="search" />
            </Button>
          )}
        </td>
      )}
    </tr>
  );
};

const isExternalMovement = (movement) => {
  if (movement.generatorType === 'Core::Device') {
    if (movement.asset) {
      return movement.asset.item.buildingId !== movement.generator.buildingId;
    } else {
      return movement.item.buildingId !== movement.generator.buildingId;
    }
  } else {
    return false;
  }
};

export const MovementsTable = ({
  errors = {},
  movements,
  showNumber = false,
  showTarget = true,
  showInfo = true,
  header = true,
  showActions = false,
  actions = [],
  pagination,
}) => {
  // const ratios = [4.5 * showTarget, showTarget ? 0.5 : 2.5, 1.0 * showInfo, 0.75];
  // const total = ratios.reduce((c, v) => c + v, 0);
  const numberRows = 3 + 2 * showInfo;
  // const w = window.innerWidth;
  const widths = [
    showNumber ? 'auto' : 0,
    showTarget ? 'auto' : 0,
    'auto',
    showInfo ? 'auto' : 0,
    showInfo ? 'auto' : 0,
    actions.length > 0 || showActions ? 'auto' : 0,
  ];

  const movementTypes = useSelector(({ appData }) => ({
    ...appData.movements,
    ...appData.repair_movements,
    ...appData.evaluation_movements,
    ...appData.maintenance_movements,
    ...appData.preservation_movements,
    ...appData.certification_movements,
    ...appData.assurance_movements,
    ...appData.discard_movements,
  }));

  return (
    <div>
      {pagination && (
        <div className="small mb-4">
          <strong>{pagination.count}</strong>{' '}
          {pluralize(pagination.count, 'movimientos encontrado', 'movimientos encontrados')}. Mostrando{' '}
          <strong>{pagination.per}</strong> movimientos por página.
        </div>
      )}
      <Table
        size="sm"
        style={{
          tableLayout: 'auto',
        }}
      >
        <colgroup>
          {/* {showNumber && <col style={{ width: 'auto' }} />}
          {showTarget && <col style={{ width: '600px' }} />}
          <col style={{ width: 'auto' }} />
          {showInfo && <col style={{ width: 'auto' }} />}
          {showInfo && <col style={{ width: 'auto' }} />}
          {(actions.length > 0 || showActions) && <col style={{ width: 'auto' }} />} */}
          {widths
            .filter((width) => width !== 0)
            .map((width, index) => (
              <col key={index} style={{ width }} />
            ))}
        </colgroup>
        {header && (
          <thead>
            <tr>
              {showNumber && <th className="text-center">#</th>}
              {showTarget && <th>Descripción</th>}
              <th>{showTarget ? '' : 'Description'}</th>
              {showInfo && <th>Información</th>}
              {showInfo && <th></th>}
              {(actions.length > 0 || showActions) && <th></th>}
            </tr>
          </thead>
        )}
        <tbody>
          {movements.map((movement, index) => (
            <MovementsTableRow
              key={`${movement.id}-${index}`}
              movement={movement}
              errors={errors[String(index)]}
              showTarget={showTarget}
              showInfo={showInfo}
              showNumber={showNumber}
              movementTypes={movementTypes}
              showActions={showActions}
              actions={actions && actions.map((Action) => <Action key={index} index={index} />)}
            />
          ))}

          {movements.length === 0 && (
            <tr>
              <td colSpan={numberRows} className="text-center pt-4 pb-4">
                Sin resultados
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default MovementsTable;
