import React from 'react';
import { ListGroup } from 'reactstrap';
import { SubtaskListItem } from './SubtaskListItem';

export const SubtaskList = ({ subtasks, onSave, onDelete }) => (
  <ListGroup>
    {subtasks.map((subtask, index) => (
      <SubtaskListItem handleSave={onSave} handleDelete={onDelete} subtask={subtask} key={index} index={index} />
    ))}
  </ListGroup>
);
