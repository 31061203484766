import React from 'react';

import csv from './csv.svg';
import unknown from './unknown.svg';
import doc from './doc.svg';
import jpg from './jpg.svg';
import pdf from './pdf.svg';
import png from './png.svg';
import ppt from './ppt.svg';
import svg from './svg.svg';
import txt from './txt.svg';
import xls from './xls.svg';
import zip from './zip.svg';

const entries = {
  csv,
  doc,
  jpg,
  pdf,
  png,
  ppt,
  svg,
  txt,
  xls,
  zip,
};

const FileIcon = ({ type, width = '3rem', height, ...otherProps }) => {
  return <img src={entries[type] || unknown} alt={type} style={{ width, height: height || width }} {...otherProps} />;
};

export default FileIcon;
