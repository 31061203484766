import { RemoteSelector } from '@core/forms';
import { getLocation, getLocations } from '@core/organization/locations';
import React from 'react';
import { Media } from 'reactstrap';

const GeofenceItem = ({ resource: geofence }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{geofence.name}</strong> - {geofence.code}
      </div>
    </Media>
  </Media>
);

export const GeofenceSelector = ({ filters, inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getLocation}
    remoteFinder={(query) => getLocations(inputProps.organization, { name: query, ...filters })}
    dropdownTemplate={GeofenceItem}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre',
    }}
  />
);
