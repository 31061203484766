import { createValidityPeriod, destroyValidityPeriod, updateValidityPeriod } from '@core/building/validity-period';
import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { FontAwesome, ShowForRoles } from '@shared';
import { DateSelector } from '@shared/forms';
import { store, connect, notificationsGenerators } from '@store';
import moment from 'moment';
import React, { Component, useState } from 'react';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'title' }),
  presence({ of: 'notifyBefore' }),
  presence({ of: 'from' }),
  presence({ of: 'to' }),
);

const styles = {
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
};

const {
  appData: { validity_period_titles: validityPeriodTitles },
} = store.getState();

const options = { '': 'Seleccione título...' };

const NOTIFY_BEFORE = {
  '': 'Seleccione...',
  '1 day': 'Un día antes',
  '3 days': '3 días antes',
  '7 days': 'Una semana antes',
  '15 days': '2 semanas antes',
  '21 days': '3 semanas antes',
  '30 days': 'Un mes antes',
  '90 days': '3 meses antes',
  '180 days': '6 meses antes',
  '365 days': '1 año antes',
};

class ValidityPeriodRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      validityPeriod: this.props.validityPeriod,
      error: false,
      edit: !this.props.validityPeriod.id,
      isCustomTitle: false,
    };

    if (validityPeriodTitles) {
      Object.keys(validityPeriodTitles).forEach((element) => {
        options[validityPeriodTitles[element]] = validityPeriodTitles[element];
      });
      options['Otro'] = 'Otro';
    }
  }

  setEdit = () => {
    this.setState({
      isCustomTitle:
        this.state.validityPeriod.title === 'Otro' ||
        !Object.keys(options).some((option) => option === this.state.validityPeriod.title),
    });
    this.props.toggleEditing();
    this.setState({
      edit: true,
    });
  };

  create = (validityPeriod) => {
    const { assetBarcode } = this.props;

    this.setState(
      {
        processing: true,
      },
      async () => {
        const { body, status } = await createValidityPeriod(assetBarcode, validityPeriod);

        if (status === 201 || status === 200) {
          this.setState({
            validityPeriod: body,
            processing: false,
            edit: false,
          });
          this.props.toggleEditing();
        } else if (status === 422) {
          /* TODO: Handle error with better UX? */
          this.setState({
            validityPeriod,
            error: true,
            processing: false,
          });
          this.props.errorNotification(body?.errors);
        }
      },
    );
  };

  update = (validityPeriod) => {
    const { assetBarcode } = this.props;

    this.setState(
      {
        processing: true,
      },
      async () => {
        const { body, status } = await updateValidityPeriod(assetBarcode, validityPeriod);

        if (status === 200) {
          this.setState({
            validityPeriod: body,
            processing: false,
            edit: false,
          });
          this.props.toggleEditing();
        } else if (status === 422) {
          /* TODO: Handle error with better UX? */
          this.setState({
            validityPeriod,
            error: true,
            processing: false,
          });
          this.props.errorNotification(body?.errors);
        }
      },
    );
  };

  remove = () => {
    const { validityPeriod } = this.state;
    const { assetBarcode, onDelete, index } = this.props;

    // New entries shall be deleted immediately.
    if (!validityPeriod || !validityPeriod.id) {
      onDelete && onDelete(index);
      this.props.toggleEditing();
      return;
    }

    // Proceed with deletion
    this.setState(
      {
        processing: true,
      },
      async () => {
        const { status } = await destroyValidityPeriod(assetBarcode, validityPeriod.id);

        if (status !== 204) return;

        this.setState({
          processing: false,
        });

        onDelete && onDelete(index);
      },
    );
  };

  handleCancel = () => {
    const { validityPeriod } = this.state;
    if (validityPeriod.id) {
      this.setState({
        edit: false,
      });
      this.props.toggleEditing();
    } else {
      this.remove();
    }
  };

  render() {
    const { edit, processing, isCustomTitle } = this.state;
    const { validityPeriod } = this.props;
    const PeriodForm = ValidityPeriodForm({ validityPeriod });

    if (edit) {
      return (
        <Card className="mb-2">
          <CardBody>
            <PeriodForm
              onSubmit={!validityPeriod.id ? this.create : this.update}
              initialValues={validityPeriod}
              processing={processing}
              cancel={this.handleCancel}
              isCustomTitle={isCustomTitle}
            />
          </CardBody>
        </Card>
      );
    }
    return (
      <Card className="mb-2">
        <CardBody>
          <ValidityPeriodPresentation
            readOnly={this.props.readOnly}
            validityPeriod={validityPeriod}
            setEdit={this.setEdit}
            remove={this.remove}
            editing={this.props.editing}
            editable={this.props.editable}
            deletable={this.props.deletable}
          />
        </CardBody>
      </Card>
    );
  }
}

const ValidityPeriodFormPresentation = (props) => {
  const { processing, invalid, handleSubmit, cancel, isCustomTitle } = props;

  const [isCustom, setIsCustom] = useState(isCustomTitle);

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Row>
        <Col className="text-left">
          <Field
            name="title"
            type="select"
            label="Tipo de Vigencia"
            options={options}
            onChange={(e) => {
              setIsCustom(e.target.value === 'Otro');
            }}
          />
          {isCustom && (
            <Field
              name="title"
              type="text"
              label="Ingrese un título personalizado"
              onChange={(e) => {
                setIsCustom(true);
              }}
            />
          )}
        </Col>
        <Col className="text-left">
          <Field name="notifyBefore" type="select" label="Notificar" options={NOTIFY_BEFORE} />
        </Col>
        <Col className="text-left">
          <Field name="from" label="Desde" component={DateSelector} autoComplete="off" minDate={new Date()} />
        </Col>
        <Col className="text-left">
          <Field name="to" label="Hasta" component={DateSelector} autoComplete="off" minDate={new Date()} />
        </Col>
        <Col style={styles.buttons}>
          <Button type="submit" role="button" color="success" disabled={processing || invalid} className="mr-2">
            <FontAwesome name="check" />
          </Button>
          <Button role="button" color="danger" onClick={cancel}>
            <FontAwesome name="times" />
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const ValidityPeriodPresentation = ({
  remove,
  setEdit,
  editing,
  validityPeriod: { title, from, to, notifyBefore, icon },
  editable = true,
  deletable = true,
  readOnly = false,
}) => {
  return (
    <Row>
      <Col className="text-left">
        <b>Tipo de Vigencia</b>
        <p className="mb-0">{title}</p>
      </Col>
      <Col className="text-left">
        <b>Notificar</b>
        <p className="mb-0">{NOTIFY_BEFORE[notifyBefore]}</p>
      </Col>
      <Col className="text-left">
        <b>Desde:</b>
        <p className="mb-0">{moment(from, 'YYYY-MM-DD').format('DD-MM-YYYY')}</p>
      </Col>
      <Col className="text-left">
        <b>Hasta:</b>
        <p className="mb-0">{moment(to, 'YYYY-MM-DD').format('DD-MM-YYYY')}</p>
      </Col>
      <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
        {!readOnly ? (
          <Col style={styles.buttons}>
            {editable && (
              <Button role="button" color="primary" onClick={setEdit} className="mr-2" disabled={editing}>
                <FontAwesome name="pencil-alt" />
              </Button>
            )}
            {deletable && (
              <Button role="button" color="danger" onClick={remove} disabled={editing}>
                <FontAwesome name="trash" />
              </Button>
            )}
          </Col>
        ) : (
          <FontAwesome name={icon?.name} style={{ color: icon?.color }} />
        )}
      </ShowForRoles>
    </Row>
  );
};

const ValidityPeriodForm = ({ validityPeriod: { id } }) =>
  reduxForm({
    form: `validityPeriodForm-${id}`,
    validate,
  })(ValidityPeriodFormPresentation);

export default connect(null, (dispatch) => ({
  errorNotification: (errors) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error',
        text: `${errors?.title}. No se pudo ejecutar la acción. Contacta a un administrador si los problemas persisten.`,
        color: 'danger',
      }),
    ),
}))(ValidityPeriodRow);
