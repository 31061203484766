import React from 'react';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';

/// Items System
import { NewItem } from './items/NewItem';
import { ItemsIndex } from './items/ItemsIndex';
import { ItemPage } from './items/ItemPage';
import { ItemsMassImport } from './items/ItemsMassImport';
import { ItemsUpdateMassImport } from './items/ItemsUpdateMassImport';

// Categories System
import { CategoriesIndex } from './categories/CategoriesIndex';
import { NewCategory } from './categories/NewCategory';
import { EditCategory } from './categories/EditCategory';
import { CategoriesMassImport } from './categories/CategoriesMassImport';

/// Geofence System
import { LocationsIndex } from './locations/LocationsIndex';
import { NewLocation } from './locations/NewLocation';
import { EditLocation } from './locations/EditLocation';
import { InventoryRoutes } from './inventory';

import { CalendarView } from './calendar/CalendarView';
import AttachmentView from './attachment/AttachmentView';
import { MergeItems } from './items/MergeItems';
import { TasksIndex } from 'organization/tasks/TasksIndex';
import { TaskNew } from 'organization/tasks/TaskNew';
import { TaskEdit } from 'organization/tasks/TaskEdit';
import { TransferItem } from './items/TransferItem';

export const OrganizationRoutes = ({ match }) => (
  <Switch>
    <ProtectedRoute
      exact
      path={`${match.url}/items`}
      component={ItemsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/items/mass`}
      component={ItemsMassImport}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/items/mass-update`}
      component={ItemsUpdateMassImport}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/items/new`}
      component={NewItem}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding']}
    />
    <ProtectedRoute
      path={`${match.url}/items/to_merge`}
      component={MergeItems}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      path={`${match.url}/items/:barcode`}
      component={ItemPage}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'onboarding', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/calendar`}
      component={CalendarView}
      acceptedMemberships={['organization_admin', 'warehouse_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/categories`}
      component={CategoriesIndex}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      path={`${match.url}/categories/mass`}
      component={CategoriesMassImport}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/categories/new`}
      component={NewCategory}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/categories/:id`}
      component={EditCategory}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/locations`}
      component={LocationsIndex}
      acceptedMemberships={['organization_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/locations/new`}
      component={NewLocation}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/locations/:id`}
      component={EditLocation}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/inventory`}
      component={InventoryRoutes}
      acceptedMemberships={['organization_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/attachments`}
      component={AttachmentView}
      acceptedMemberships={['organization_admin', 'warehouse_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/tasks`}
      component={TasksIndex}
      acceptedMemberships={['organization_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/tasks/new`}
      component={TaskNew}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/tasks/:id`}
      component={TaskEdit}
      acceptedMemberships={['organization_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/transfer_consumables`}
      component={TransferItem}
      acceptedMemberships={['organization_admin']}
    />
  </Switch>
);
