import React from 'react';

import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';

// Users system
import { CreateUser } from './users/CreateUser';
import { EditUser } from './users/EditUser';
import { Memberships } from './users/Memberships';
import { UsersMassImport } from './users/UsersMassImport';
import { UsersIndex } from './users/UsersIndex';

// Buildings
import { CreateBuilding } from './buildings/CreateBuilding';
import { EditBuilding } from './buildings/EditBuilding';
import { BuildingsIndex } from './buildings/BuildingsIndex';

// Organizations
import { CreateOrganization } from './organizations/CreateOrganization';
import { OrganizationsIndex } from './organizations/OrganizationsIndex';
import { EditOrganization } from './organizations/EditOrganization';

// Posts
import { NewPost } from './posts/NewPost';
import { EditPost } from './posts/EditPost';
import { PostsIndex } from './posts/PostsIndex';

// Devices
import { NewDevice } from './devices/NewDevice';
import { DevicesIndex } from './devices/DevicesIndex';
import { DevicePage } from './devices/DevicePage';

// Batches
import { BatchesIndex } from './batches/BatchesIndex';
import { CreateBatch } from './batches/CreateBatch';

// Sim Cards
import { SimCardsIndex } from './simcards/SimCardIndex';

export const AdminRoutes = ({ match }) => (
  <Switch>
    {/* Admin: Buildings */}
    <ProtectedRoute exact path={`${match.url}/buildings`} component={BuildingsIndex} acceptedRootRoles={['root']} />
    <ProtectedRoute exact path={`${match.url}/buildings/new`} component={CreateBuilding} acceptedRootRoles={['root']} />
    <ProtectedRoute exact path={`${match.url}/buildings/:id`} component={EditBuilding} acceptedRootRoles={['root']} />

    {/* Admin: Organizations */}
    <ProtectedRoute
      exact
      path={`${match.url}/organizations/new`}
      component={CreateOrganization}
      acceptedRootRoles={['root']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/organizations`}
      component={OrganizationsIndex}
      acceptedRootRoles={['root']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/organizations/:id`}
      component={EditOrganization}
      acceptedRootRoles={['root']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/organizations/:organizationId/buildings/new`}
      component={CreateBuilding}
      acceptedRootRoles={['root']}
    />

    {/* Admin: Users management */}
    <ProtectedRoute exact path={`${match.url}/users/mass`} component={UsersMassImport} acceptedRootRoles={['root']} />
    <ProtectedRoute exact path={`${match.url}/users/new`} component={CreateUser} acceptedRootRoles={['root']} />
    <ProtectedRoute exact path={`${match.url}/users/:id`} component={EditUser} acceptedRootRoles={['root']} />
    <ProtectedRoute exact path={`${match.url}/users`} component={UsersIndex} acceptedRootRoles={['root']} />
    <ProtectedRoute
      exact
      path={`${match.url}/users/:id/memberships`}
      component={Memberships}
      acceptedRootRoles={['root']}
      animated={false}
    />

    {/* Admin: Posts management */}
    <ProtectedRoute
      exact
      path={`${match.url}/posts/new`}
      component={NewPost}
      acceptedRootRoles={['root']}
      animated={false}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/posts/:id/edit`}
      component={EditPost}
      acceptedRootRoles={['root']}
      animated={false}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/posts`}
      component={PostsIndex}
      acceptedRootRoles={['root']}
      animated={false}
    />

    {/* Admin: Devices management */}
    <ProtectedRoute
      exact
      path={`${match.url}/devices`}
      component={DevicesIndex}
      acceptedRootRoles={['root']}
      animated={false}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/devices/new`}
      component={NewDevice}
      acceptedRootRoles={['root']}
      animated={false}
    />
    <ProtectedRoute
      path={`${match.url}/devices/:barcode`}
      component={DevicePage}
      acceptedRootRoles={['root']}
      animated={false}
    />

    {/* Admin: Batches */}
    <ProtectedRoute
      exact
      path={`${match.url}/batches`}
      component={BatchesIndex}
      acceptedRootRoles={['root']}
      animated={false}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/batches/new`}
      component={CreateBatch}
      acceptedRootRoles={['root']}
      animated={false}
    />

    <ProtectedRoute
      exact
      path={`${match.url}/simcards`}
      component={SimCardsIndex}
      acceptedRootRoles={['root']}
      animated={false}
    />
  </Switch>
);
