import { adminDownload } from '@core/http';
import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, Spinner } from '@shared';
import { BuildingDisplay, UserTableDisplay } from '@shared/displays';
import { saveAs } from 'file-saver';
import React from 'react';
import { Button } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';

const enhance = compose(
  withState('state', 'setRowState', 'waiting'),
  withHandlers({
    download: ({ batch, setRowState }) => async () => {
      setRowState('loading');

      const res = await adminDownload(`assembly_batches/${batch.id}/csv`, undefined, {
        headers: { Accept: 'text/csv' },
      });

      setRowState('waiting');

      if (res.status === 200) saveAs(await res.blob(), `batch-${batch.id}.csv`);
    },
  }),
);

const BatchRowPresentation = ({ batch, download, state }) => (
  <tr>
    <td className="align-middle text-center">{batch.id}</td>
    <td className="align-middle small">
      <BuildingDisplay organization={batch.organization} />
    </td>
    <td className="align-middle small">
      {batch.creatorType === 'Core::User' && <UserTableDisplay user={batch.creator} />}
    </td>
    <td className="align-middle text-center small">{fromIso8601(batch.createdAt)}</td>
    <td className="align-middle text-right">
      <Button role="button" size="sm" color="success" onClick={download} disabled={state === 'loading'}>
        {state === 'loading' ? <Spinner /> : <FontAwesome name="download" />}
      </Button>
    </td>
  </tr>
);

export const BatchRow = enhance(BatchRowPresentation);
