import { deleteCategory, getCategories } from '@core/building/categories';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Card, CardBody } from 'reactstrap';
import { CategoriesResults } from './components/CategoriesResults';

class CategoriesIndexPresentation extends SimpleFilter {
  constructor(props) {
    super(props);
    this.state = {
      ...super.state,
      processing: undefined,
    };
  }
  searchFunction = getCategories;

  deleteCategory = async (index) => {
    this.setState({ processing: index });

    const category = this.state.items[index];

    if (!category) {
      return;
    }

    const { items } = this.state;
    const { status } = await deleteCategory(category.id);

    this.setState({ processing: undefined });

    if (status === 422) {
      this.props.deletingFailed();
    } else if (status === 204) {
      this.props.deletedSuccessfully();

      this.setState({
        items: [...items.slice(0, index), ...items.slice(index + 1)],
      });
    }
  };

  render() {
    const { items, pagination, processing } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button tag={NavLink} to="/organization/categories/mass" role="button" size="sm" outline color="success">
                <FontAwesome name="upload" /> Importación masiva
              </Button>
              <Button tag={NavLink} to="/organization/categories/new" role="button" size="sm" outline color="success">
                <FontAwesome name="plus" /> Crear Categoría
              </Button>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="sitemap" className="mr-2 text-primary" /> Gestión de Categorías
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <CategoriesResults
                    categories={items}
                    pagination={pagination}
                    deleteCategory={this.deleteCategory}
                    processing={processing}
                  />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </BuildingLayout>
    );
  }
}

export const CategoriesIndex = connect(null, (dispatch) => ({
  deletingFailed: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Eliminación fallida',
        text:
          'La categoría no pudo ser eliminada. Por favor asegúrate que no contenga ningún artículo antes de continuar.',
        color: 'danger',
      }),
    ),
  deletedSuccessfully: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Categoría eliminada',
        text: 'La categoría ha sido eliminada exitosamente.',
        color: 'success',
      }),
    ),
}))(CategoriesIndexPresentation);
