export const currentMembership = function (session) {
  const currentMembership = session.currentMembership;

  return (
    currentMembership &&
    session.user &&
    session.user.memberships &&
    session.user.memberships.find((m) => m.id === currentMembership)
  );
};

export const SessionActions = {
  Login: 'Session.Login',
  Logout: 'Session.Logout',
  UpdateUser: 'Session.UpdateUser',
  SetCurrentMembership: 'Session.SetCurrentMembership',
  ForceSetMembership: 'Session.ForceSetMembership',
  SetHideAutomaticPostModal: 'SessionActions.SetHideAutomaticPostModal',
  CloseSetMembership: 'Session.CloseSetMembership',
};

export const sessionInitialState = {
  loggedIn: false,
  user: {},
  token: '',
  expiresAt: '',
  lastActivity: '',
  currentMembership: undefined,
  mustSetMembership: false,
  hideAutomaticPostModal: {},
};

export const session = (state = sessionInitialState, action) => {
  switch (action.type) {
    case SessionActions.Login:
      return {
        ...state,
        ...action.payload,
        loggedIn: true,
      };
    case SessionActions.Logout:
      return {
        ...sessionInitialState,
        hideAutomaticPostModal: state.hideAutomaticPostModal,
      };
    case SessionActions.UpdateUser:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case SessionActions.SetCurrentMembership:
      return {
        ...state,
        currentMembership: action.payload,
        mustSetMembership: false,
      };
    case SessionActions.CloseSetMembership:
      return {
        ...state,
        mustSetMembership: false,
      };
    case SessionActions.ForceSetMembership:
      return {
        ...state,
        mustSetMembership: true,
      };
    case SessionActions.SetHideAutomaticPostModal:
      return {
        ...state,
        hideAutomaticPostModal: action.payload,
      };
    default:
      return state;
  }
};

export const sessionGenerators = {
  login: ({ user, publicToken: token, expiresAt, lastActivity, appDataVersion }) => {
    return {
      type: SessionActions.Login,
      payload: {
        user,
        token,
        expiresAt,
        lastActivity,
        appDataVersion,
      },
    };
  },
  logout: () => {
    return {
      type: SessionActions.Logout,
    };
  },
  updateUser: (data) => {
    return {
      type: SessionActions.UpdateUser,
      payload: data,
    };
  },
  setMembership: (currentMembership) => {
    return {
      type: SessionActions.SetCurrentMembership,
      payload: currentMembership,
    };
  },
  closeSetMembership: () => {
    return {
      type: SessionActions.CloseSetMembership,
    };
  },
  forceSetMembership: () => {
    return {
      type: SessionActions.ForceSetMembership,
    };
  },
  SetHideAutomaticPostModal: (hideAutomaticPostModal) => {
    console.log(hideAutomaticPostModal, 'HIDE');
    return {
      type: SessionActions.SetHideAutomaticPostModal,
      payload: hideAutomaticPostModal,
    };
  },
};
