export const DeskSessionActions = {
  Start: 'DeskSession.Start',
  Finish: 'DeskSession.Finish',
  SetClientData: 'DeskSession.SetClientData',
  SetClientLatestMovements: 'DeskSession.SetClientLatestMovements',
  SetCurrentTarget: 'DeskSession.SetCurrentTarget',
  ClearTarget: 'DeskSession.ClearTarget',
  AddMovement: 'DeskSession.AddMovement',
  RemoveMovement: 'DeskSession.RemoveMovement',
  SetSavingState: 'DeskSession.SetSavingState',
  SetSavingErrors: 'DeskSession.SetSavingErrors',
};

export const deskSessionInitialState = {
  currentClient: undefined,
  clientData: undefined,
  clientLatestMovements: undefined,
  currentTarget: undefined,
  movements: [],
  savingState: 'waiting',
  savingErrors: undefined,
};

export const deskSession = (state = deskSessionInitialState, { type, payload }) => {
  switch (type) {
    case DeskSessionActions.Start:
      const { type: clientType, id } = payload;

      return {
        ...deskSessionInitialState, // *NOT* state
        currentClient: { type: clientType, id },
      };
    case DeskSessionActions.Finish:
      return {
        ...deskSessionInitialState,
        currentClient: undefined,
      };
    case DeskSessionActions.SetClientData:
      return {
        ...state,
        clientData: payload,
      };
    case DeskSessionActions.SetClientLatestMovements:
      return {
        ...state,
        clientLatestMovements: payload,
      };
    case DeskSessionActions.SetCurrentTarget:
      const { type: targetType, id: targetId } = payload;

      return {
        ...state,
        currentTarget: { type: targetType, id: targetId },
      };
    case DeskSessionActions.ClearTarget:
      return {
        ...state,
        currentTarget: undefined,
      };
    case DeskSessionActions.AddMovement:
      return {
        ...state,
        movements: [...state.movements, payload],
      };
    case DeskSessionActions.RemoveMovement:
      const { savingErrors = {} } = state;
      const keptKeys = Object.keys(savingErrors).filter((key) => String(payload) > key);
      const movedKeys = Object.keys(savingErrors).filter((key) => String(payload) < key);

      const newSavingErrors = {};

      keptKeys.forEach((key) => (newSavingErrors[key] = savingErrors[key]));
      movedKeys.forEach((key) => (newSavingErrors[String(key - 1)] = savingErrors[key]));

      return {
        ...state,
        movements: [...state.movements.slice(0, payload), ...state.movements.slice(payload + 1)],
        savingErrors: newSavingErrors,
      };
    case DeskSessionActions.SetSavingState:
      return {
        ...state,
        savingState: payload,
      };
    default:
      return state;
  }
};

export const deskSessionGenerators = {
  start(client) {
    return { type: DeskSessionActions.Start, payload: client };
  },
  finish() {
    return { type: DeskSessionActions.Finish };
  },
  setClientData(data) {
    return { type: DeskSessionActions.SetClientData, payload: data };
  },
  setClientLatestMovements(data) {
    return { type: DeskSessionActions.SetClientLatestMovements, payload: data };
  },
  setCurrentTarget(type, id) {
    return { type: DeskSessionActions.SetCurrentTarget, payload: { type, id } };
  },
  clearTarget() {
    return { type: DeskSessionActions.ClearTarget };
  },
  addMovement(movement) {
    return { type: DeskSessionActions.AddMovement, payload: movement };
  },
  removeMovement(index) {
    return { type: DeskSessionActions.RemoveMovement, payload: index };
  },
  setSavingState(state) {
    return { type: DeskSessionActions.SetSavingState, payload: state };
  },
  setSavingErrors(errors) {
    return { type: DeskSessionActions.SetSavingErrors, payload: errors };
  },
};
