import React from 'react';
import { Table } from 'reactstrap';
import { ExpectedStock } from './ExpectedStock';

export const ExpectedStockIndex = ({ expectedStocks, updateItem, loadingStocks }) => {
  return (
    <div style={{ overflow: 'auto' }}>
      <Table>
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <tr>
            <th>Instalación</th>
            <th className="text-right">Stock Mínimo</th>
            <th className="text-right">Stock Óptimo</th>
            <th className="text-right">Stock de Respaldo</th>
            <th className="text-right">Criticidad</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {expectedStocks?.length > 0 &&
            expectedStocks.map((expectedStock, index) => (
              <ExpectedStock key={`expectedStock-${index}`} expectedStock={expectedStock} updateItem={updateItem} />
            ))}
          {expectedStocks.length === 0 && (
            <tr>
              <td colSpan={6} className="text-center">
                <div>No hay stocks generados todavía.</div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
