import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

export const ConsumableSelector = ({ value, onChange }) => (
  <div className="mb-3">
    <ButtonGroup className="w-100">
      <Button role="button" color="primary" outline className="w-50" active={!value} onClick={() => onChange(false)}>
        Activo
      </Button>
      <Button role="button" color="primary" outline className="w-50" active={value} onClick={() => onChange(true)}>
        Artículo
      </Button>
    </ButtonGroup>
  </div>
);
