import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { AssetSelector, AreaSelector } from '@shared/forms';
import { getAreaByBuilding, getAreasByBuilding } from '@core/building/areas';
import { get } from 'lodash';
import React from 'react';
import { connect } from '@store';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'barcode' }), presence({ of: 'targetBuilding' }), presence({ of: 'areaId' }));

const enhance = compose(
  reduxForm({
    form: 'transferAssetForm',
    validate,
  }),
  connect(({ session }) => ({ session }) => {
    const { memberships } = session.user;
    const options = { '': 'Seleccione...' };
    const membership = session.user?.memberships.find((membership) => membership.id === session.currentMembership);
    Object.keys(memberships).forEach((index) => {
      if (
        memberships[index].id !== session.currentMembership &&
        memberships[index].role === 'organization_admin' &&
        memberships[index].building.organization.id === membership.building.organization.id
      ) {
        const buildingName = memberships[index].building.name;
        const buildingId = memberships[index].building.id;
        options[buildingId] = buildingName;
      }
    });
    return {
      buildingsAvailable: options,
    };
  }),
  connect(({ form }) => {
    return {
      buildingId: get(form, 'transferAssetForm.values.targetBuilding', false),
    };
  }),
);

const TransferAssetFormPresentation = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  buildingsAvailable,
  buildingId,
}) => (
  <Form noValidate onSubmit={handleSubmit}>
    <h4 className="mb-4">Transferencia de activo entre instalaciones</h4>
    <Row>
      <Col>
        <Field
          name="barcode"
          label="Activo"
          component={AssetSelector}
          filters={{ states: 'stored', active: true }}
          help="Activo que se va a transferir a otra instalación, el mismo debe estar en estado: Disponible"
          required
        />
      </Col>
      <Col>
        <Field
          name="targetBuilding"
          label="Instalación destino"
          type="select"
          options={buildingsAvailable}
          help="Instalación a la que va a pertenecer el activo."
          required
        />
      </Col>
    </Row>
    {buildingId && (
      <Row>
        <Col>
          <Field
            name="areaId"
            label="Ubicación"
            help="Esta es la área dentro de la cual estará ubicado el activo."
            remoteLoader={(search) => getAreaByBuilding(buildingId, search)}
            remoteFinder={(search) => getAreasByBuilding(buildingId, search)}
            component={AreaSelector}
            required
          />
        </Col>
      </Row>
    )}
    <Row>
      <Col>
        <Field
          name="comment"
          label="Comentarios"
          rows="6"
          type="textarea"
          help="Cualquier comentario que pueda aportar antecedentes adicionales respecto al movimiento realizado."
        />
      </Col>
    </Row>
    <div className="text-center">
      <ProcessingButton
        role="button"
        type="submit"
        color="primary"
        outline
        width="10em"
        disabled={pristine || invalid}
        processing={submitting}
      >
        Transferir
      </ProcessingButton>
    </div>
  </Form>
);

export const TransferAssetForm = enhance(TransferAssetFormPresentation);
