import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { BatchRow } from './BatchRow';

export const BatchesResults = ({ batches, pagination }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'lote encontrado', 'lotes encontrados')}.
      Mostrando <strong>{pagination.per}</strong> lotes por página.
    </div>
    <Table>
      <colgroup>
        <col style={{ width: '70px' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '70px' }} />
      </colgroup>
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th>Instalación</th>
          <th>Creador</th>
          <th className="text-center">Fecha creación</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {batches.map((batch, index) => (
          <BatchRow batch={batch} key={index} />
        ))}
      </tbody>
    </Table>
  </div>
);
