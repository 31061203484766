import { deletePicture, getDeviceAttachments, insertPicture } from '@core/admin/devices';
import { getDevice } from '@core/admin/devices';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { PictureCrud } from '@shared';
import { Loading } from '@shared';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { DeviceHeader } from './components/DeviceHeader';
import { DeviceKeys } from './components/DeviceKeys';
import { DeviceLayout } from './components/DeviceLayout';
import { EditDevice } from './components/EditDevice';
import { DeviceSummary } from './components/DeviceSummary';
import AttachmentManager from '@shared/attachment-manager';

class DevicePagePresentation extends Component {
  state = {
    notFound: false,
    loading: true,
    device: {},
  };

  async componentDidMount() {
    const { barcode } = this.props.match.params;

    if (!barcode) {
      this.notFound();
      return;
    }

    const { body: device, status } = await getDevice(barcode);

    if (status === 404) {
      this.notFound();
      return;
    } else if (status === 200) {
      this.setState({
        loading: false,
        device,
      });
    }
  }

  setDevice = (device) => {
    this.setState({ device });
  };

  petitionInsertPicture = async (barcode1, token) => {
    return await insertPicture(barcode1, token);
  };

  petitionDeletePicture = async (barcode1, id) => {
    return await deletePicture(barcode1, id);
  };

  notFound = () => {
    this.setState({ notFound: true });
    this.props.notFound();
  };

  render() {
    const { match } = this.props;
    const { loading, device, notFound } = this.state;

    if (notFound) return <Redirect to="/admin/devices" />;

    if (loading) return <Loading />;

    return (
      <Container className="mt-4 mb-4" fluid>
        <Row>
          <Col xl={{ size: 10, offset: 1 }}>
            {device && (
              <div>
                <DeviceHeader device={device} />

                <DeviceLayout device={device}>
                  <Switch>
                    <ProtectedRoute exact path={`${match.url}/`} render={() => <DeviceSummary device={device} />} />

                    <ProtectedRoute
                      exact
                      path={`${match.url}/edit`}
                      render={() => <EditDevice device={device} setDevice={this.setDevice} />}
                    />

                    <ProtectedRoute
                      exact
                      path={`${match.url}/pictures`}
                      render={() => (
                        <PictureCrud
                          pictures={device.pictures}
                          deletePicture={this.petitionDeletePicture}
                          insertPicture={this.petitionInsertPicture}
                          parentElement={device}
                        />
                      )}
                    />

                    <ProtectedRoute
                      exact
                      path={`${match.url}/attachments`}
                      render={() => (
                        <AttachmentManager
                          resourceId={device.barcode1}
                          resourceType={'devices'}
                          isAdmin={true}
                          loaderResource={() => getDeviceAttachments(device.barcode1)}
                        />
                      )}
                    />

                    <ProtectedRoute exact path={`${match.url}/keys`} render={() => <DeviceKeys device={device} />} />
                  </Switch>
                </DeviceLayout>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export const DevicePage = connect(null, (dispatch) => ({
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo no encontrado',
        text:
          'El dispositivo buscado no existe o no se encuentra disponible. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(DevicePagePresentation);
