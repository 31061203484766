import React from 'react';
import { Media } from 'reactstrap';

import { RemoteSelector } from '@core/forms';
import { getCostAccount, getCostAccounts } from '@core/building/cost-accounts';

export const CostAccountCard = ({ resource: costAccount }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{costAccount.code}</strong>
        {' - '}
        <strong>{costAccount.name}</strong>
      </div>
    </Media>
  </Media>
);

const CostAccountFinder = ({ inputProps, filters, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getCostAccount}
    remoteFinder={(query) => getCostAccounts({ search: query, ...filters })}
    dropdownTemplate={CostAccountCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por código o nombre de centro de costos',
    }}
  />
);

export default CostAccountFinder;
