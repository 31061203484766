import { fromIso8601 } from '@core/utils/dates';
import { DateTime } from 'luxon';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesome } from '..';

export const MetadataDisplay = ({ tagId, metadata, excluded = ['imei', 'epc', 'major', 'minor'] }) => {
  const values = [];

  Object.keys(metadata)
    .filter((value) => !excluded.includes(value))
    .forEach((key) => {
      switch (key) {
        case 'battery':
          values.push(
            <div className="mb-1" key={`${tagId}-${key}`}>
              <kbd>{key + ': ' + metadata[key].level}</kbd>
            </div>,
          );
          break;
        case 'lastPosition':
        case 'lastConnection':
          values.push(
            <div className="mb-1" key={`${tagId}-${key}`}>
              <kbd>{key + ': ' + fromIso8601(DateTime.fromMillis(metadata[key]))}</kbd>
            </div>,
          );
          break;
        case 'telemetry':
          // values.push(
          //   ...Object.keys(metadata[key]).map((k) => (
          //     <div className="mb-1" key={`${key}-${k}`}>
          //       <kbd>{k + ': ' + metadata[key][k]}</kbd>
          //     </div>
          //   )),
          // );
          values.push(
            <div className="mb-1" key={`${tagId}-${key}`}>
              <kbd id={`telemetry-${tagId}`} style={{ cursor: 'pointer' }}>
                Ver telemetría <FontAwesome name="eye" />
              </kbd>
              <UncontrolledTooltip target={`telemetry-${tagId}`} placement="right">
                <ul className="space-y-2" style={{ listStyle: 'none', margin: 0, padding: 0 }}>
                  {Object.keys(metadata[key]).map((k) => (
                    <li key={`${tagId}-${key}-${k}`}>
                      {`${k}: ${
                        k === 'timestamp' ? fromIso8601(DateTime.fromMillis(metadata[key][k])) : metadata[key][k]
                      }`}
                    </li>
                  ))}
                </ul>
              </UncontrolledTooltip>
            </div>,
          );
          break;
        default:
          values.push(
            <div className="mb-1" key={`${tagId}-${key}`}>
              <kbd>{key + ': ' + metadata[key]}</kbd>
            </div>,
          );
          break;
      }
    });

  return values;
};
