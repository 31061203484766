import { deleteCostAccount } from '@core/building/cost-accounts';
import { FontAwesome, ShowForRoles } from '@shared';
import { connect } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, UncontrolledTooltip } from 'reactstrap';

const CostAccountRowPresentation = ({ costAccount, searchFunction }) => {
  const deleteCostAccountRow = async (id) => {
    const { status } = await deleteCostAccount(id);
    if (status === 204) {
      await searchFunction();
    }
  };

  return (
    <tr>
      <td className="align-middle text-left">
        <span className="ml-2" style={{ lineHeight: '2rem' }}>
          {costAccount?.code}
        </span>
      </td>
      <td className="align-middle text-overflow">
        <NavLink className="d-flex" to={`/building/cost-accounts/${costAccount?.id}`}>
          <span className="ml-2" style={{ lineHeight: '2rem' }}>
            {costAccount?.name}
          </span>
        </NavLink>
      </td>
      <ShowForRoles accepted={['organization_admin']}>
        <td className="text-center">
          <Button
            id={`DeleteCostAccount-${costAccount?.id}`}
            size="sm"
            color="danger"
            onClick={() => deleteCostAccountRow(costAccount?.id)}
            disabled={false}
          >
            <FontAwesome name="times" />
          </Button>
        </td>
        <UncontrolledTooltip placement="auto" target={`DeleteCostAccount-${costAccount?.id}`}>
          {costAccount?.buildings?.length === 0 && costAccount?.users?.length === 0
            ? 'Eliminar Centro de Costo'
            : costAccount?.buildings?.length > 0 && costAccount?.users?.length > 0
            ? 'El socio de negocios tiene usuarios e instalaciones asociadas'
            : costAccount?.buildings?.length > 0 && costAccount?.users?.length === 0
            ? 'El socio de negocios tiene instalaciones asociadas'
            : 'El socio de negocios tiene usuarios asociados'}
        </UncontrolledTooltip>
      </ShowForRoles>
    </tr>
  );
};

export const CostAccountRow = connect()(CostAccountRowPresentation);
