import React from 'react';

import { Route, ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';

import Login from './Login';
import Logout from './Logout';
import Profile from './profile/Profile';
import Recovery from './Recovery';
import Confirmation from './Confirmation';
import ConfirmationCancel from './ConfirmationCancel';
import Invitation from './Invitation';
import Unlock from './Unlock';

const AccountRoutes = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/login`} component={Login} />
    <Route path={`${match.url}/forgot`} component={Login} />
    <Route exact path={`${match.url}/recovery/:token`} component={Recovery} />
    <Route exact path={`${match.url}/confirmation/:token/cancel`} component={ConfirmationCancel} />
    <Route exact path={`${match.url}/confirmation/:token`} component={Confirmation} />
    <Route exact path={`${match.url}/invitations/:token`} component={Invitation} />
    <Route exact path={`${match.url}/unlock/:token`} component={Unlock} />
    <ProtectedRoute exact path={`${match.url}/logout`} component={Logout} />
    <ProtectedRoute path={`${match.url}`} component={Profile} />
  </Switch>
);

export default AccountRoutes;
