import './TaskForm.scss';

import { deleteAttachment } from '@core/building/attachments';
import { getUsers } from '@core/building/users';
import { Field, Form } from '@core/forms';
import Validates, { length, presence } from '@core/forms/validations';
import ChileanRUT from '@core/utils/chilean-rut';
import { confirmDialog, FontAwesome, ProcessingButton } from '@shared';
import { AttachmentCard } from '@shared/attachment-manager';
import { AssetOrganizationSelector, BuildingSelector, DateTimeSelector, FileUploader } from '@shared/forms';
import { LOADING_MESSAGE, NO_OPTIONS } from '@shared/simple-filter/SimpleFilter';
import { connect, notificationsGenerators } from '@store';
import moment from 'moment';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

import { SubtaskForm } from './SubtaskForm';
import { SubtaskList } from './SubtaskList';

import { getAsset } from '@core/organization/assets';
import { getBuildingsOrganization } from '@core/building/buildings';

const validate = Validates(
  presence({ of: 'title' }),
  presence({ of: 'startDate' }),
  presence({ of: 'dueDate' }),
  presence({ of: 'memberIds' }),
  presence({ of: 'buildingId' }),
  length({ of: 'memberIds', greaterThan: 0 }),
);

class TaskFormPresentation extends Component {
  attachmentResourceType = 'tasks';

  constructor(props) {
    super(props);

    this.state = {
      title: '',
      isCustomTitle: false,
      description: '',
      startDate: '',
      dueDate: '',
      memberIds: [],
      'members[{}]': [],
      attachmentsTokens: [],
      attachments: [],
      addNewSubtask: false,
      subtasksList: [],
      openConfirmSubmit: false,
      isRepairable: false,
      code: null,
      integration: false,
      buildingSelected: undefined,
      assetSelected: undefined,
      integrationCheck: false,
    };
  }

  componentDidMount() {
    const {
      initialValues: { members, subtasks, title, asset },
      taskTitles,
    } = this.props;
    if (!!members?.length) {
      this.setState({ 'members[{}]': this.usersTransform(members) });
    }
    if (!!subtasks?.length) {
      this.setState({ subtasksList: subtasks });
    }
    if (title) {
      this.setState({
        isCustomTitle: title === 'Otro' || !Object.keys(taskTitles).some((taskTitle) => taskTitle === title),
      });
    }
    if (asset) {
      this.setState({ isRepairable: asset.repairable });
    }
  }

  findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return this.usersTransform(body);
    }
    return [];
  };

  usersTransform = (users) => {
    return users.map(({ firstName, lastName, identifier, id: value }) => ({
      label: `${firstName} ${lastName} - ${
        identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
      }`,
      value,
    }));
  };

  handleStartDateChange = (startDate) => {
    const { dueDateValue, initialValues, change } = this.props;
    const dueDate = dueDateValue || (initialValues && initialValues.dueDateValue);
    const convertedDueDate = dueDate && moment(dueDate).valueOf();
    const convertedSartDate = moment(startDate).valueOf();
    if (convertedDueDate && convertedDueDate < convertedSartDate) {
      change('dueDate', null);
    }
  };

  handleDueDateChange = (dueDate) => {
    const { startDateValue, initialValues, change } = this.props;
    const startDate = startDateValue || (initialValues && initialValues.startDate);
    const convertedDueDate = moment(dueDate).valueOf();
    const convertedSartDate = startDate && moment(startDate).valueOf();
    if (convertedSartDate && convertedSartDate > convertedDueDate) {
      change('startDate', null);
    }
  };

  onChangeUser = (users) => {
    const usersIds = users?.map((user) => user.value);
    this.props.change('memberIds', usersIds);
    this.setState({ 'members[{}]': users });
  };

  setAttachmentsTokens = (attachments) => {
    this.props.change('attachmentsTokens', attachments);
  };

  onDeleteAttachment = async (token) => {
    const outcome = await confirmDialog(
      '¿Estás seguro de que deseas borrar este archivo? Esta acción no se puede deshacer.',
    );

    if (!outcome) return;

    const {
      initialValues: { id },
    } = this.props;

    const { status } = await deleteAttachment({
      resourceId: id,
      resourceType: this.attachmentResourceType,
      token,
    });

    if (status === 204) {
      this.removeAttachment(token);
    }
  };

  removeAttachment = (token) => {
    const {
      initialValues: { attachments },
      attachmentsValue,
      deleteAttachmentNotification,
      change,
    } = this.props;
    const taskAttachments = attachmentsValue || attachments;
    const index = taskAttachments.findIndex((att) => att.token === token);

    if (index !== -1) {
      deleteAttachmentNotification(taskAttachments[index].title);

      change('attachments', [...taskAttachments.slice(0, index), ...taskAttachments.slice(index + 1)]);
    }
  };

  onAddSubtask = () => {
    this.setState({ addNewSubtask: true });
  };

  createSubtask = (subtask) => {
    const subtasksList = [subtask, ...this.state.subtasksList];
    this.setState({ addNewSubtask: false, subtasksList });
    this.props.change('subtasks', subtasksList);
  };

  cancelCreateSubtask = () => {
    this.setState({ addNewSubtask: false });
  };

  updateSubtask = (subtask, index) => {
    const subtasksList = this.state.subtasksList.map((st, i) => (i === index ? subtask : st));
    this.setState({ subtasksList });
    this.props.change('subtasks', subtasksList);
  };

  deleteSubtask = (index) => {
    const subtasksList = this.state.subtasksList.filter((st, i) => i !== index);
    this.setState({ subtasksList });
    this.props.change('subtasks', subtasksList);
  };

  setTaskTitle = (isCustomValue = true) => {
    if (isCustomValue) {
      this.setState({ title: undefined, isCustomTitle: true });
    } else {
      this.setState({ isCustomTitle: false });
    }
  };

  checkRepairable = async (barcode) => {
    this.setState({ assetSelected: barcode });
    // const { body, status } = await getAsset(barcode);
    const { body, status } = await getAsset(barcode);
    if (status === 200) {
      if (body.repairable) {
        this.setState({ isRepairable: true });
      } else {
        this.setState({ isRepairable: false, code: null, integration: false });
      }
    } else {
      this.setState({ isRepairable: false, code: null, integration: false });
    }
  };

  toggleBuildingSelected = (building) => {
    this.setState({ buildingSelected: building });
    this.props.change('assetBarcode', undefined);
  };

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,
      pristine,
      error,
      startDateValue,
      initialValues: { id, startDate, attachments },
      attachmentsTokensValue,
      attachmentsValue,
      taskTitles,
      action,
    } = this.props;
    const { addNewSubtask, subtasksList, isCustomTitle, isRepairable, integrationCheck, buildingSelected } = this.state;
    const taskStartDate = startDateValue || startDate;
    const taskAttachments = attachmentsValue || attachments;
    return (
      <Form error={error} noValidate onSubmit={handleSubmit}>
        <h4 className="mb-3">Información básica</h4>
        <Row>
          <Col className="task-col col-md-6">
            <Field
              name="title"
              type="select"
              label="Título"
              options={taskTitles}
              onChange={(event, value) => this.setTaskTitle(value === 'Otro')}
            />
            {isCustomTitle && (
              <Field name="title" type="text" label="Ingrese un título personalizado" onChange={this.setTaskTitle} />
            )}
          </Col>
        </Row>
        <Row>
          <Col className="task-col">
            <Field
              label="Instalación"
              name="buildingId"
              component={BuildingSelector}
              remoteFinder={getBuildingsOrganization}
              inputProps={{ placeholder: 'Buscar instalación...' }}
              onChange={this.toggleBuildingSelected}
              readonly={action === 'edit'}
              required
            />
          </Col>
          <Col className="task-col">
            <Field
              label="Activo"
              name="assetBarcode"
              component={AssetOrganizationSelector}
              filters={{ buildings: buildingSelected }}
              inputProps={{ placeholder: 'Buscar o escanear código QR (Opcional)' }}
              onChange={(event, value) => this.checkRepairable(value)}
              readonly={action === 'edit' || !buildingSelected}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="startDate"
              label="Fecha de inicio"
              component={DateTimeSelector}
              minDate={new Date()}
              onChange={this.handleStartDateChange}
              required
            />
          </Col>
          <Col>
            <Field
              name="dueDate"
              label="Fecha de finalización"
              component={DateTimeSelector}
              minDate={!!taskStartDate ? taskStartDate : new Date()}
              onChange={this.handleDueDateChange}
              required
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Field
              name="frequency"
              label="Frecuencia"
              type="select"
              options={{
                '': 'Correctivo',
                'FREQ=DAILY': 'Diario',
                'FREQ=WEEKLY': 'Semanal',
                'FREQ=BIWEEKLY': 'Quincenal',
                'FREQ=TRIWEEKLY': '21 días',
                'FREQ=MONTHLY': 'Mensual',
                'FREQ=QUARTERLY': 'Trimestral',
                'FREQ=BIANNUAL': 'Semestral',
                'FREQ=YEARLY': 'Anual',
              }}
              required
            />
          </Col>
          {/* <Col>
            <Field name="estimatedHours" label="Horas Estimadas" placeholder="HH" />
            <div className="form-text text-muted">
              Este campo se tomará en cuenta en caso que no se agreguen subtareas
            </div>
          </Col> */}
          <Col>
            {isRepairable && (
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Field
                        label="Integrar con MRO"
                        name="integration"
                        type="checkbox"
                        help="Permite iniciar un proceso MRO a partir de esta tarea"
                        onChange={() => this.setState({ integrationCheck: !integrationCheck })}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field
                        name="mroType"
                        label="Tipo de MRO"
                        type="select"
                        options={{
                          '': 'Seleccione',
                          maintenance: 'Mantenimiento',
                          repair: 'Reparación',
                          evaluation: 'Evaluación',
                          preservation: 'Preservación',
                          certification: 'Certificación',
                          assurance: 'Garantía',
                        }}
                        disabled={!integrationCheck}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Field name="code" type="text" rows="6" label="Código OC/OT" disabled={!integrationCheck} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Field name="description" type="textarea" rows="6" label="Descripción" />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label for="members[{}]">Responsables</Label>
              <AsyncSelect
                name="members[{}]"
                isClearable={true}
                isSearchable={true}
                isMulti={true}
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChangeUser}
                value={this.state['members[{}]']}
              />
              <Field name="memberIds" type="hidden" />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Archivos</Label>
              <FileUploader
                multiple={true}
                token={attachmentsTokensValue}
                onChange={this.setAttachmentsTokens}
                endpoint={(token) => `/attachments/${token}`}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            {id &&
              taskAttachments.map((attachment) => (
                <AttachmentCard
                  key={attachment.token}
                  attachment={attachment}
                  resourceType={this.attachmentResourceType}
                  resourceId={id}
                  onDelete={this.onDeleteAttachment}
                />
              ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>Subtareas</Label>{' '}
              <Button type="button" role="button" size="sm" outline color="success" onClick={this.onAddSubtask}>
                <FontAwesome name="plus" />
              </Button>
            </FormGroup>
            {addNewSubtask && (
              <FormGroup>
                <SubtaskForm onSubmit={this.createSubtask} handleCancel={this.cancelCreateSubtask} />
              </FormGroup>
            )}
            {subtasksList && subtasksList.length > 0 && (
              <SubtaskList subtasks={subtasksList} onSave={this.updateSubtask} onDelete={this.deleteSubtask} />
            )}
          </Col>
        </Row>

        <hr />
        <div className="text-center">
          <ProcessingButton
            type="submit"
            role="button"
            outline
            color="primary"
            width="10em"
            disabled={pristine || invalid || !buildingSelected}
            processing={submitting}
          >
            Guardar
          </ProcessingButton>
        </div>
      </Form>
    );
  }
}

export const TaskForm = compose(
  connect(
    ({ appData: { task_titles: taskTitles } }) => {
      const options = { '': 'Seleccione título...' };
      Object.keys(taskTitles).forEach((state) => {
        options[taskTitles[state]] = taskTitles[state];
      });
      options['Otro'] = 'Otro';
      return { taskTitles: options };
    },
    (dispatch) => ({
      deleteAttachmentNotification: (name) =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Archivo eliminado',
            text: `El archivo ${name} ha sido eliminado exitosamente.`,
            color: 'success',
          }),
        ),
    }),
  ),
  reduxForm({
    form: 'taskForm',
    validate,
  }),
)(TaskFormPresentation);
