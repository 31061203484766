import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, simpleFilterQuery } from '@shared';
import { highIsBetter, lowIsBetter } from '@core/utils/telemetries';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';

export function TelemetryRow({ telemetry }) {
  const {
    gForceEventHarmful,
    gForceEventWarning,
    temperatureLevelHarmful,
    temperatureLevelWarning,
    workTripHarmful,
    workTripWarning,
  } = telemetry.asset;

  return (
    <tr>
      <th scope="row" className="text-center">
        {telemetry.asset && (
          <div>
            <div className="small text-muted">Activo involucrado</div>
            <NavLink to={`/building/assets/${telemetry.asset.barcode1}`}>
              <kbd className="small">{telemetry.asset.barcode1}</kbd>
              <FontAwesome name="search" className="ml-1" />
            </NavLink>
            <div className="small mt-1">
              <div className="w-100 text-overflow">
                <span className="text-muted mr-1">
                  <FontAwesome name="caret-right" className="mr-1" />
                  Artículo:
                </span>
                {telemetry.item.name}
              </div>
            </div>
          </div>
        )}
      </th>
      <td className="align-middle small text-center">
        <Media body tag="div">
          <code>
            {telemetry.tag.identifier ? (
              <NavLink
                to={`/building/tags?q=${simpleFilterQuery({
                  identifier: telemetry.tag.identifier,
                })}`}
              >
                {telemetry.tag.identifier}
              </NavLink>
            ) : (
              <span className="text-warning">
                <strong>Desconocido</strong>
              </span>
            )}
          </code>
          <div>{fromIso8601(telemetry.readAt)}</div>
        </Media>
      </td>
      <td className="align-middle small text-right">
        <div>{telemetry.lastBatteryLevel ? fromIso8601(telemetry.lastBatteryLevel.readAt) : '-'}</div>
        <div>{telemetry.lastGForceEvent ? fromIso8601(telemetry.lastGForceEvent.readAt) : '-'}</div>
        <div>{telemetry.lastTemperatureLevel ? fromIso8601(telemetry.lastTemperatureLevel.readAt) : '-'}</div>
        <div>{telemetry.lastWorkTrip ? fromIso8601(telemetry.lastWorkTrip.readAt) : '-'}</div>
      </td>
      <td className="align-middle small text-left">
        <div>{telemetry.lastBatteryLevel ? `${telemetry.lastBatteryLevel.level} %` : '-'}</div>
        <div>{telemetry.lastGForceEvent ? `${telemetry.lastGForceEvent.peak} G` : '-'}</div>
        <div>{telemetry.lastTemperatureLevel ? `${telemetry.lastTemperatureLevel.temperature} ºC` : '-'}</div>
        <div>{telemetry.lastWorkTrip ? `${(telemetry.lastWorkTrip.elapsed / 3600000).toFixed(1)} horas` : '-'}</div>
      </td>
      <td>
        <div>{telemetry.lastBatteryLevel ? highIsBetter(10, 40, telemetry.lastBatteryLevel.level) : '-'}</div>
        <div>
          {telemetry.lastGForceEvent
            ? lowIsBetter(gForceEventWarning, gForceEventHarmful, telemetry.lastGForceEvent.peak)
            : '-'}
        </div>
        <div>
          {telemetry.lastTemperatureLevel
            ? lowIsBetter(temperatureLevelWarning, temperatureLevelHarmful, telemetry.lastTemperatureLevel.temperature)
            : '-'}
        </div>
        <div>
          {telemetry.lastWorkTrip
            ? lowIsBetter(workTripWarning, workTripHarmful, (telemetry.lastWorkTrip.elapsed / 3600000).toFixed(1))
            : '-'}
        </div>
      </td>
    </tr>
  );
}
