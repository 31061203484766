import { createOrganization } from '@core/admin/organizations';
import { SubmissionError } from '@core/forms';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { OrganizationForm } from './components/OrganizationForm';

class CreateOrganizationPresentation extends Component {
  state = {
    created: false,
  };

  CreateOrganization = async (data) => {
    const { body, status } = await createOrganization(data);

    if (status === 201) {
      this.setState({
        created: true,
      });

      this.props.successNotification(data.name);
    } else if (status === 422) throw new SubmissionError(body.errors);
  };

  render() {
    const { created } = this.state;

    if (created) return <Redirect to="/admin/organizations" />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4" title="Nueva organización">
        <OrganizationForm onSubmit={this.CreateOrganization} />
      </SimpleContainerLayout>
    );
  }
}

export const CreateOrganization = connect(null, (dispatch) => ({
  successNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Organización creada',
        text: `La organización ${name} ha sido creada exitosamente.`,
        color: 'success',
        duration: 10000,
      }),
    ),
}))(CreateOrganizationPresentation);
