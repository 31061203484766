import { deleteItem } from '@core/building/items';
import { pluralize } from '@shared/helpers';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { ItemCard } from './ItemCard';

class ItemsResultsPresentation extends Component {
  state = {
    displayConfirmBox: true,
    items: this.props.items,
  };

  openConfirmBox = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeConfirmBox = () => {
    this.setState({
      isOpen: false,
    });
  };

  softDelete = async (index) => {
    const item = this.state.items[index];
    const { items } = this.state;

    const { body, status } = await deleteItem(item.barcode1);

    if (status === 204) {
      this.props.deleteNotification();

      this.setState({
        items: [...items.slice(0, index), ...items.slice(index + 1)],
      });
    } else {
      this.props.errorNotification(body.error);
    }
  };

  toogleConfirmBox = () => {
    this.setState({
      displayConfirmBox: !this.state.displayConfirmBox,
    });
  };

  render() {
    const { items, displayConfirmBox } = this.state;
    const { pagination } = this.props;

    return (
      <div>
        <div className="small mb-4">
          <strong>{pagination.count}</strong>{' '}
          {pluralize(pagination.count, 'artículo encontrado', 'artículos encontrados')}. Mostrando{' '}
          <strong>{pagination.per}</strong> artículos por página.
        </div>

        {items.length === 0 && (
          <div className="text-center mt-3 mb-3">
            <em>No se encontraron artículos.</em>
          </div>
        )}

        {items.length > 0 && <hr />}

        {items.map((item, index) => (
          <ItemCard
            key={index}
            item={item}
            softDelete={() => this.softDelete(index)}
            toogleConfirmBox={this.toogleConfirmBox}
            displayConfirmBox={displayConfirmBox}
          />
        ))}
      </div>
    );
  }
}

export const ItemsResults = connect(null, (dispatch) => ({
  deleteNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Artículo eliminado',
        text: 'El artículo ha sido eliminado correctamente.',
        color: 'success',
      }),
    ),
  errorNotification: (message) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error al eliminar.',
        text: message,
        color: 'danger',
      }),
    ),
}))(ItemsResultsPresentation);
