import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { SinglePictureComponent } from '@shared/forms';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'name' }), presence({ of: 'location' }));

const AreaFormPresentation = ({ handleSubmit, invalid, submitting, pristine, error }) => {
  return (
    <Form error={error} noValidate onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Field
            name="name"
            type="text"
            label="Nombre del área"
            required
            help="El nombre del área en cuestión. Puede (y debería) ser un nombre coloquial y fácil de identificar."
          />
        </Col>
        <Col>
          <Field
            name="location"
            type="text"
            label="Ubicación"
            required
            help="Ubicación específica, con instrucciones para llegar al área dentro de la instalación. Si las áreas tienen códigos o identificadores asignados, este es el campo para especificarlo."
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            name="pictureToken"
            component={SinglePictureComponent((token) => `/pictures/${token}`, '/pictures')}
            label="Imagen"
            help="Una imagen descriptiva del área."
          />
        </Col>
        <Col>
          <Field
            name="description"
            type="textarea"
            rows="8"
            label="Descripción"
            help="Informacion relevante del área, como advertencias de seguridad."
          />
        </Col>
      </Row>

      <hr />

      <div className="text-center">
        <ProcessingButton
          type="submit"
          color="primary"
          outline
          width="10em"
          disabled={pristine || invalid}
          processing={submitting}
        >
          Guardar
        </ProcessingButton>
      </div>
    </Form>
  );
};

export const AreaForm = reduxForm({
  form: 'areaForm',
  validate,
})(AreaFormPresentation);
