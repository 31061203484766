import { apiFetch, handleAuth, loadFile, withToken } from './http';

export const getPicture = ({ token, endpoint = undefined, ...otherProps }) =>
  apiFetch({
    endpoint: endpoint ? endpoint(token) : `/pictures/${token}`,
    method: 'GET',
    ...otherProps,
  });

export const deletePicture = ({ token, endpoint = undefined, ...otherProps }) =>
  apiFetch({
    endpoint: endpoint ? endpoint(token) : `/pictures/${token}`,
    method: 'DELETE',
    ...otherProps,
  });

export const getFile = (token, endpoint = undefined) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: endpoint ? endpoint(token) : `/attachments/${token}`,
      method: 'GET',
    }),
  );

export const deleteFile = (token, endpoint = undefined) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: endpoint ? endpoint(token) : `/attachments/${token}`,
      method: 'DELETE',
    }),
  );

export const createFile = (file, resource = 'attachments') =>
  handleAuth(
    withToken(loadFile)({
      endpoint: resource,
      method: 'POST',
      name: 'file',
      files: file.data,
    }),
  );

export function tokensFor(files, multiple = true) {
  if (multiple) return files.reduce((c, f) => (f.status === 'uploaded' ? [...c, f.response.token] : c), []);

  const file = files.find((f) => f.status === 'uploaded');

  return file && file.response && file.response.token;
}

export const editFile = (token, title, endpoint = undefined) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: endpoint ? endpoint(token) : `/attachments/${token}`,
      method: 'PUT',
      body: {
        title,
      },
    }),
  );
