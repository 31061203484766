import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getInventoryCheckerSets = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checker_sets${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getInventoryCheckerSet = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checker_sets/${id}`,
      method: 'GET',
    }),
  );

export const createInventoryCheckerSet = ({ ...inventoryCheckerSet }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checker_sets`,
      method: 'POST',
      body: {
        inventoryCheckerSet,
      },
    }),
  );

export const editInventoryCheckerSet = ({ ...inventoryCheckerSet }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checker_sets/${inventoryCheckerSet.id}`,
      method: 'PUT',
      body: {
        inventoryCheckerSet,
      },
    }),
  );

export const deleteInventoryCheckerSet = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory_checker_sets/${id}`,
      method: 'DELETE',
    }),
  );
