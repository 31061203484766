import { getShortItems } from '@core/building/items';
import { buildingDownload } from '@core/http.js';
import { ExcelDownload, FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { ItemsResults } from './components/ItemsResults';
import { ItemsFilterModal } from './components/ItemsFilterModal';

export class ItemsIndex extends SimpleFilter {
  searchFunction = getShortItems;

  constructor(props) {
    super(props);
    this.state = { filters: { ...this.state.filters, serializer: 'short' } };
    console.log('CONSTRUCTOR');
    console.log(this.state);
  }

  render() {
    const { id } = this.state;

    if (id) {
      return <Redirect to={`/organization/items/${id}`} />;
    }

    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
                <ExcelDownload file="items" filters={filters} downloadFile={buildingDownload} disabled={!items} />
              </ShowForRoles>
              <ShowForRoles rootAccepted={['root']}>
                <Button tag={NavLink} size="sm" outline color="success" to="/organization/items/to_merge">
                  <FontAwesome name="clone" /> Combinar
                </Button>
              </ShowForRoles>
              <ShowForRoles accepted={['organization_admin']}>
                <Button tag={NavLink} size="sm" outline color="success" to="/organization/items/mass">
                  <FontAwesome name="upload" /> Importación
                </Button>
                <Button tag={NavLink} size="sm" outline color="success" to="/organization/items/mass-update">
                  <FontAwesome name="upload" /> Actualización Masiva
                </Button>
                <Button tag={NavLink} size="sm" outline color="success" to="/organization/items/new">
                  <FontAwesome name="plus" /> Crear Artículo
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="th-large" className="text-primary mr-2" /> Gestión de Artículos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}

                {items && <ItemsResults items={items} pagination={pagination} />}

                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ItemsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
