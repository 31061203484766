import { FontAwesome } from '@shared';
import { fromIso8601 } from '@core/utils/dates';
import { Badge, Button, UncontrolledTooltip } from 'reactstrap';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { InventoryCheckerDetail } from './InventoryCheckerDetail';
import { getInventoryChecker } from '@core/building/inventory-checkers';

export const InventoryCheckerRow = ({ inventoryChecker, processing, states }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inventoryCheckerDetail, setInventoryCheckerDetail] = useState(undefined);

  const toggle = () => {
    if (!isOpen)
      (async () => {
        const { body, status } = await getInventoryChecker(inventoryChecker.id);
        console.log(body, status);
        setInventoryCheckerDetail(body);
      })();
    setIsOpen(!isOpen);
  };

  return (
    <tr>
      <td className="align-middle text-left small">{inventoryChecker.requestNumber}</td>
      <td className="align-middle text-left small">{fromIso8601(inventoryChecker.createdAt)}</td>
      <td className="align-middle text-left small">{fromIso8601(inventoryChecker.updatedAt)}</td>
      <td className="align-middle">
        <Badge id="Tipo" color={inventoryChecker?.stock === 'confirmed' ? 'success' : 'primary'}>
          {inventoryChecker.stock === 'confirmed' ? 'Existencias' : 'Inexistencias'}
        </Badge>
      </td>
      <td className="align-middle">
        {inventoryChecker?.status && (
          <Badge id="Estado" color={states[inventoryChecker.status].color}>
            {states[inventoryChecker.status].name}
          </Badge>
        )}
      </td>
      <td className="align-middle">
        <Badge id="Origen" color={inventoryChecker?.origin === 'auto' ? 'success' : 'primary'}>
          {inventoryChecker?.origin === 'auto' ? 'Programada' : 'Manual'}
        </Badge>
        <UncontrolledTooltip placement="auto" target="Origen">
          {inventoryChecker?.origin === 'auto' ? 'Programada' : 'Generada desde el cloud o APP móvil'}
        </UncontrolledTooltip>
      </td>
      <td className="align-middle text-center">
        {inventoryChecker?.attachments?.length > 0 &&
          inventoryChecker.attachments.map((attachment, index) => (
            <Button
              role="button"
              className="mb-1 mr-1"
              color={attachment?.file?.url.includes('.xlsx') ? 'success' : 'danger'}
              tag="a"
              href={attachment?.file?.url}
              target="_blank"
              size="sm"
              download
              key={index}
            >
              <FontAwesome name={attachment?.file?.url.includes('.xlsx') ? 'file-excel' : 'file-pdf'} />
            </Button>
          ))}
      </td>
      <td className="align-middle text-center">
        <Button
          role="button"
          className="mb-1 mr-1"
          color="info"
          id={`MoreInfoButton-${inventoryChecker.id}`}
          size="sm"
          onClick={toggle}
        >
          <FontAwesome name="search" />
        </Button>
        <Button
          role="button"
          className="mb-1 mr-1"
          color="primary"
          id={`EditButton-${inventoryChecker.id}`}
          size="sm"
          tag={NavLink}
          to={`/building/inventory-checkers/${inventoryChecker.id}`}
          disabled={inventoryChecker.status === 'completed' || inventoryChecker.status === 'canceled'}
        >
          <FontAwesome name="edit" />
        </Button>
        {/* <Button
          role="button"
          className="mb-1 mr-1"
          color="danger"
          id={`CancelButton-${inventoryChecker.id}`}
          size="sm"
          onClick={() => {}}
          disabled={inventoryChecker.status === 'completed' || inventoryChecker.status === 'canceled'}
        >
          <FontAwesome name="times" />
        </Button> */}
        <UncontrolledTooltip placement="auto" target={`MoreInfoButton-${inventoryChecker.id}`}>
          Más Información
        </UncontrolledTooltip>
        <UncontrolledTooltip placement="auto" target={`EditButton-${inventoryChecker.id}`}>
          {inventoryChecker?.status === 'completed'
            ? 'Auditoría Completada'
            : inventoryChecker?.status === 'completed'
            ? 'Auditoría Cancelada'
            : 'Editar auditoría'}{' '}
        </UncontrolledTooltip>
        {/* <UncontrolledTooltip placement="auto" target={`CancelButton-${inventoryChecker.id}`}>
          {inventoryChecker?.status === 'completed'
            ? 'Auditoría Completada'
            : inventoryChecker?.status === 'completed'
            ? 'Auditoría Cancelada'
            : 'Cancelar auditoría'}{' '}
        </UncontrolledTooltip> */}
      </td>
      <InventoryCheckerDetail
        inventoryChecker={inventoryCheckerDetail}
        isOpen={isOpen}
        toggle={toggle}
        states={states}
      />
    </tr>
  );
};
