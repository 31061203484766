import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getCostAccount = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/cost_accounts/${id}`,
      method: 'GET',
    }),
  );

export const getCostAccounts = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/cost_accounts${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createCostAccount = (costAccount) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/cost_accounts`,
      method: 'POST',
      body: {
        costAccount,
      },
    }),
  );

export const updateCostAccount = (id, costAccount) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/cost_accounts/${id}`,
      method: 'PUT',
      body: {
        costAccount,
      },
    }),
  );

export const deleteCostAccount = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/cost_accounts/${id}`,
      method: 'DELETE',
    }),
  );
