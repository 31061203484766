import { assembly } from '@core/building/assets';
import { FontAwesome } from '@shared';
import { ToggleAssembly } from '@shared/assets/ToggleAssembly';
import { AreaSelector, ItemSelector } from '@shared/forms';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { Button, FormGroup, Label } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';
import { AssemblyForm } from './components/AssemblyForm';

const enhance = compose(
  connect(null, (dispatch) => ({
    successNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'Ensamblado exitoso',
          text: 'El artículo ha sido ensamblado exitosamente',
          color: 'success',
        }),
      );
    },
    notFoundNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'No encontrado',
          text:
            'El código de barra ingresado no se encuentra registrado. Por favor contacta a un administrador si los problemas persisten.',
          color: 'danger',
        }),
      );
    },
    conflictNotification(text) {
      dispatch(
        notificationsGenerators.insert({
          title: 'Código ensamblado',
          text,
          color: 'danger',
        }),
      );
    },
    InvalidEpcNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'EPC incorrecto',
          text:
            'El código EPC debe contener 24 caracteres. Por favor contacta a un administrador si los problemas persisten.',
          color: 'warning',
        }),
      );
    },
    InvalidAssetCodeNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'Código de activo incorrecto',
          text:
            'El código debe contener 23 caracteres. Por favor contacta a un administrador si los problemas persisten.',
          color: 'warning',
        }),
      );
    },
    UnprocessableEntity(message) {
      dispatch(
        notificationsGenerators.insert({
          title: 'No se pudo ensamblar',
          text: message,
          color: 'danger',
        }),
      );
    },
    resetForm() {
      // No method to do it, reset the form manually then.
      dispatch({
        type: '@@redux-form/RESET',
        meta: {
          form: 'assemblyForm',
        },
      });
    },
  })),
  withState('item', 'setItem', undefined),
  withState('prefix', 'setPrefix', false),
  withState('area', 'setArea', undefined),
  withState('state', 'setState', 'init'),
  withHandlers({
    assemble: ({
      area,
      item,
      prefix,
      setState,
      successNotification,
      notFoundNotification,
      conflictNotification,
      InvalidEpcNotification,
      InvalidAssetCodeNotification,
      UnprocessableEntity,
      resetForm,
    }) => async ({ barcode, identifier, repairable = false }) => {
      setState('processing');

      let code = '';

      prefix === true ? (code = 'EPC:' + barcode) : (code = barcode);

      if (prefix === true && barcode.length !== 24) {
        InvalidEpcNotification();
      } else if (prefix === false && barcode.length !== 23) {
        InvalidAssetCodeNotification();
      } else {
        const { body, status } = await assembly({
          itemBarcode: item,
          code: code,
          areaId: area,
          factorySerialNumber1: identifier,
          repairable: repairable,
        });

        if (status === 201) {
          resetForm();
          successNotification();
        } else if (status === 404) {
          // In theory, the user already found the item and the area.
          // 404 is because of the asset barcode
          notFoundNotification();
        } else if (status === 409) {
          conflictNotification(Object.values(body.errors).join('\n'));
        } else if (status === 422) {
          Object.keys(body).forEach((key) => {
            if (Object.keys(body[key]).length !== 0) {
              let messages = body[key].map((error) => {
                return Object.values(error).join('\n');
              });
              UnprocessableEntity(messages.join('\n'));
            }
          });
        }

        setState('waiting');
      }
    },
    togglePrefix: ({ prefix, setPrefix }) => () => {
      setPrefix(!prefix);
    },
  }),
);

const AssemblyPresentation = ({ area, assemble, item, setArea, setItem, setState, state, togglePrefix, prefix }) => (
  <div className="layout-container container-fluid mt-4 mb-4">
    <SimpleContainerLayout
      title={
        <span>
          <FontAwesome name="barcode" className="mr-1 text-primary" /> Ensamblado
        </span>
      }
    >
      <FormGroup>
        <Label>Artículo</Label>
        <ItemSelector
          inputProps={{ size: 'lg' }}
          value={item}
          filters={{ active: true, consumable: false }}
          input={{ onChange: (id) => setItem(id) }}
          readonly={state !== 'init'}
        />
      </FormGroup>

      <FormGroup>
        <Label>Área</Label>
        <AreaSelector
          inputProps={{ size: 'lg' }}
          value={area}
          input={{ onChange: (id) => setArea(id) }}
          readonly={state !== 'init'}
        />
      </FormGroup>

      <div className="mt-4">
        {state === 'init' && (
          <Button
            role="button"
            size="lg"
            block
            color="primary"
            disabled={!item || !area || state !== 'init'}
            onClick={() => setState('waiting')}
          >
            <FontAwesome name="check" /> Comenzar
          </Button>
        )}

        {state !== 'init' && (
          <Button
            role="button"
            size="lg"
            block
            disabled={state !== 'waiting'}
            color="danger"
            onClick={() => {
              setItem(undefined);
              setArea(undefined);
              setState('init');
            }}
          >
            <FontAwesome name="caret-up" /> Volver
          </Button>
        )}
      </div>
    </SimpleContainerLayout>

    {state !== 'init' && (
      <SimpleContainerLayout>
        <ToggleAssembly
          checked={prefix}
          toggle={togglePrefix}
          onMessage="Hard Tag"
          offMessage="Normal"
          className="pull-right"
        />
        <AssemblyForm onSubmit={assemble} />
      </SimpleContainerLayout>
    )}
  </div>
);

export const Assembly = enhance(AssemblyPresentation);
