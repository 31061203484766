import React, { Component } from 'react';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import { createTask } from '@core/building/tasks';
import { Redirect } from 'react-router-dom';
import { TaskForm } from './components/TaskForm';
import { toUTC } from '@core/utils/dates';
import { TaskSubmitModal } from './components/TaskSubmitModal';

class TaskNewPresentation extends Component {
  constructor(props) {
    super(props);

    const {
      location: { search },
    } = this.props;
    // Parse de URL para detectar si existe un BARCODE asociado a un ASSET
    const query = new URLSearchParams(search);
    const assetBarcode = query.get('of');

    this.state = {
      created: false,
      assetBarcode: assetBarcode ? assetBarcode : undefined,
      openConfirmSubmit: false,
      estimatedHours: null,
      data: {},
    };
  }

  createTask = async (data) => {
    console.log(data);
    const previousCreateData = {
      ...data,
      startDate: data.startDate ? toUTC(data.startDate) : '',
      dueDate: toUTC(data.dueDate),
    };
    if (this.state.estimatedHours) previousCreateData['estimatedHours'] = this.state.estimatedHours;

    const { members, ...createData } = previousCreateData;
    createData['subtasks'] = createData?.subtasks?.reverse();
    const { body, status } = await createTask(createData);

    if (status === 201) {
      this.props.successCreationNotification();
      this.setState({ created: true });
    } else if (status === 422) {
      this.props.errorNotification(body.errors);
    }
  };

  checkHumanHours = (data) => {
    if (data?.subtasks.length > 0) {
      this.setState({ estimatedHours: null });
      this.createTask(data);
    } else {
      this.setState({ data, openConfirmSubmit: true });
    }
  };

  closeConfirmSubmit = () => {
    this.setState({ openConfirmSubmit: false });
  };

  toogleConfirmSubmit = () => {
    this.setState({ openConfirmSubmit: !this.state.openConfirmSubmit });
  };

  setEstimatedHours = (value) => {
    this.setState({ estimatedHours: value?.target?.value });
  };

  render() {
    const { created, assetBarcode, buildingId, openConfirmSubmit, estimatedHours, data } = this.state;
    const { currentUser } = this.props;
    if (created) return <Redirect to={`/organization/tasks/`} />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Creación de Tarea">
          <TaskForm
            onSubmit={this.checkHumanHours}
            initialValues={{
              memberIds: [currentUser.id],
              members: [currentUser],
              assetBarcode: assetBarcode,
              buildingId: buildingId,
              subtasks: [],
            }}
          />
        </SimpleContainerLayout>
        <TaskSubmitModal
          openConfirmSubmit={openConfirmSubmit}
          toogleConfirmSubmit={this.toogleConfirmSubmit}
          setEstimatedHours={this.setEstimatedHours}
          closeConfirmSubmit={this.closeConfirmSubmit}
          createTask={() => this.createTask(data)}
          estimatedHours={estimatedHours}
        />
      </BuildingLayout>
    );
  }
}

export const TaskNew = connect(
  ({ session: { user } }) => ({
    currentUser: user,
  }),
  (dispatch) => ({
    successCreationNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Tarea creada',
          text: 'La tarea ha sido creado exitosamente.',
          color: 'success',
        }),
      ),
    errorNotification: (errors) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Error',
          text: errors?.base ? errors?.base.join(', ') : 'Hubo un error al procesar la petición',
          color: 'danger',
        }),
      ),
  }),
)(TaskNewPresentation);
