import React from 'react';

import ChileanRUT from '@core/utils/chilean-rut';

const IdentifierDisplay = ({ identifier, ...otherProps }) => {
  if (!identifier || !identifier.value) return null;

  switch (identifier.type) {
    case 'rut':
      return <span {...otherProps}>{ChileanRUT.PartialFormat(identifier.value)}</span>;
    default:
      return null;
  }
};

export default IdentifierDisplay;
