import React from 'react';
import { Media } from 'reactstrap';

import { getPartner, getPartners } from '@core/building/partners';
import { RemoteSelector } from '@core/forms';

const PartnerItem = ({ resource: partner }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{partner.name}</strong>
      </div>
    </Media>
  </Media>
);

const PartnerBuildingSelector = ({ filters, inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getPartner}
    remoteFinder={(query) => getPartners({ name: query, ...filters })}
    dropdownTemplate={PartnerItem}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre',
    }}
  />
);

export default PartnerBuildingSelector;
