import { FontAwesome } from '@shared/';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Media } from 'reactstrap';

export const EventKit = ({ kit }) => (
  <Media className="align-middle small">
    <Media body>
      {kit.name && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="shopping-basket" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">{kit.name}</span>
        </div>
      )}
      <NavLink to={`/building/assets/${kit.barcode1}`}>
        <FontAwesome name="barcode" color="info" className="mr-1" />
        <kbd>{kit.barcode1}</kbd>
      </NavLink>
      {kit.mark && (
        <div className="w-100 text-overflow mt-1">
          <span className="text-muted mr-1">
            <FontAwesome name="tag" color="info" className="mr-1" />
            Rótulo:
          </span>
          <code>{kit.mark}</code>
        </div>
      )}
    </Media>
  </Media>
);

export const EventKitModal = ({ kit }) => (
  <Media>
    <Media body>
      {kit.name && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="shopping-basket" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">{kit.name}</span>
        </div>
      )}
      <NavLink to={`/building/assets/${kit.barcode1}`}>
        <FontAwesome name="barcode" color="info" className="mr-1" />
        <kbd>{kit.barcode1}</kbd>
      </NavLink>
      {kit.mark && (
        <div className="w-100 text-overflow mt-1">
          <span className="text-muted mr-1">
            <FontAwesome name="tag" color="info" className="mr-1" />
            Rótulo:
          </span>
          <code>{kit.mark}</code>
        </div>
      )}
    </Media>
  </Media>
);
