import { Field, Form } from '@core/forms';
import Validates, { numericality, presence } from '@core/forms/validations';
import { OrganizationCard, ProcessingButton } from '@shared';
import { AVAILABLES_CURRENCIES } from '@shared/constants';
import { SinglePictureComponent, TagsInput, UnitSelector, MachineTypeSelector } from '@shared/forms';
import { connect } from '@store';
import { currentMembership } from '@store/session';
import { get } from 'lodash';
import React from 'react';
import { Col, FormGroup, Label, Media, Row } from 'reactstrap';
import { compose } from 'recompose';
import { change, reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'name' }),
  presence({ of: 'internalSku' }),
  numericality({
    of: 'price',
    greaterThan: 0,
    if: ({ price }) => price && price !== '',
  }),
  presence({
    of: 'currency',
    condition: ({ price }) => !isNaN(parseInt(price, 10)) && parseInt(price, 10) > 0,
  }),
);

const currencyOptions = function () {
  const options = { '': 'Seleccione...' };

  AVAILABLES_CURRENCIES.forEach((cp) => (options[cp] = cp));
  return options;
};

const ItemCreatorFormPresentation = ({
  handleSubmit,
  invalid,
  submitting,
  pristine,
  membership,
  showCurrency,
  initialValues,
  toggleConsumable,
  toggleRepairable,
  consumable,
  onSubmit,
  error,
  reset,
}) => {
  return (
    <div>
      <Form error={error} noValidate>
        <h4 className="mb-3">Información básica</h4>
        <FormGroup>
          <Label>Instalación actual</Label>
          <Media className="align-items-center">
            <Media left className="mr-2">
              <OrganizationCard
                url={membership.building.organization.avatar && membership.building.organization.avatar.icon.url}
                alt={membership.building.organization.name}
              />
            </Media>
            <Media body>
              <strong>{membership.building.organization.name}</strong>
              <br />
              {membership.building.name}
            </Media>
          </Media>

          <div className="form-text text-muted">
            Esta opción solo puede ser editada accediendo con el rol adecuado de otra instalación, y es mostrada para
            verificar que se está ingresando el artículo en el lugar adecuado.
          </div>
        </FormGroup>

        <Row>
          <Col>
            <Field
              name="name"
              label="Nombre del artículo"
              help="Nombre completo del artículo, especificando marca, modelo y/o elementos distintivos."
              required
            />
          </Col>
          <Col>
            <Field
              name="recommendedStock"
              label="Cantidad recomendada para el stock"
              help="Cantidad de unidades en stock estadisticamente recomendada"
              type="number"
              required
            />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field name="brand" label="Marca" help="Nombre de la marca del artículo" />
          </Col>
          <Col>
            <Field name="model" label="Modelo" help="Nombre/Código del modelo del artículo" />
          </Col>
        </Row>

        <Row>
          <Col>
            <Field
              name="price"
              type="number"
              min={1}
              label="Precio del artículo"
              help="Precio estimado del artículo"
              parse={(value) => (isNaN(parseInt(value, 10)) ? null : parseInt(value, 10))}
            />
          </Col>
          <Col>
            {showCurrency && (
              <Field name="currency" type="select" label="Indique moneda" options={currencyOptions()} required />
            )}
          </Col>
        </Row>

        <hr />

        <h4 className="mb-3">Información de comportamiento</h4>

        <Field
          name="consumable"
          type="checkbox"
          label="Este artículo tiene naturaleza consumible"
          onChange={(_e, v) => toggleConsumable(v)}
          help={
            <span>
              Los artículos consumibles no son distinguidos individualmente mediante activos, y por su naturaleza, se
              espera por lo general que sean entregados sin esperar devolución. Esta directiva marca la forma en que se
              realiza el seguimiento de este artículo en el inventario. <strong>Importante:</strong> Esta directriz no
              puede ser cambiada en el futuro.
            </span>
          }
          disabled
        />

        <Field
          name="active"
          type="checkbox"
          label="¿Artículo activo?"
          help="Indica si se pueden realizar movimientos en el artículo y si este se encuentra disponible en el catálogo. Los artículos creados no pueden ser eliminados, por lo cual esta directiva es útil para desactivar aquellos artículos descontinuados en la instalación."
        />

        <Field
          name="restricted"
          type="checkbox"
          label="Este artículo es restringido"
          help={
            <span>
              Indica que este artículo es restringido y se solicitará una verificación durante algunos movimientos. Solo
              tiene significado útil si el artículo no es consumible.
            </span>
          }
        />

        <hr />

        <h4 className="mb-3">Especificaciones</h4>

        {consumable && (
          <div>
            <Row>
              <Col md={6}>
                <Field name="machineTypeId" label="Tipo de Máquina" component={MachineTypeSelector} />
              </Col>
              <Col>
                <Field name="unitId" label="Unidad" component={UnitSelector} />
              </Col>
            </Row>
          </div>
        )}

        <Row>
          <Col>
            <Field name="internalSku" label="SKU Interno" required />
            <Field name="externalSku" label="SKU Externo" />
            {consumable && (
              <Field
                label="Criticidad"
                name="criticality"
                type="select"
                options={{ '': 'Ninguna', A: 'A', B: 'B', C: 'C' }}
                required
              />
            )}
          </Col>
          <Col>
            <Field
              name="description"
              type="textarea"
              rows="6"
              label="Descripción"
              help="Opcional. Descripción detallada del artículo, indicando características distintivas e instrucciones."
            />
          </Col>
        </Row>

        <hr />

        <Field
          name="pictureToken"
          component={SinglePictureComponent((token) => `/pictures/${token}`, '/pictures')}
          label="Imagen"
          help="Imagen principal del artículo."
        />

        <hr />

        <h4 className="mb-3">Palabras claves</h4>

        <Field
          name="comments"
          type="textarea"
          rows="3"
          label={null}
          component={TagsInput}
          help="Referencias del artículo. Util para efectuar busquedas a traves de estos criterios."
        />
      </Form>
      <div className="text-center">
        <ProcessingButton
          type="submit"
          role="button"
          outline
          color="primary"
          width="14em"
          disabled={pristine || invalid}
          processing={submitting}
          onClick={handleSubmit(onSubmit.bind(this, true, reset))}
        >
          Guardar
        </ProcessingButton>
      </div>
    </div>
  );
};

const enhance = compose(
  connect(
    ({ session, form }) => {
      return {
        membership: currentMembership(session),
        consumable: get(form, 'itemForm.values.consumable', false),
        showCurrency: get(form, 'itemForm.values.price', false),
      };
    },
    (dispatch) => ({
      toggleConsumable: (value) => {
        dispatch(change('itemForm', 'consumable', value));
      },
    }),
  ),
  reduxForm({
    form: 'itemForm',
    validate,
    initialValues: {
      active: true,
      consumable: false,
      restricted: false,
    },
  }),
);

export const ItemCreatorForm = enhance(ItemCreatorFormPresentation);
