import React from 'react';

import { default as Spinner } from '../assets/Spinner';

const Loading = () => (
  <div className="text-center mt-3 mb-3">
    <h1 className="text-info mb-3">
      <Spinner />
    </h1>
    <h4 className="mb-2">Cargando</h4>
    <h5>Por favor espera...</h5>
  </div>
);

export default Loading;
