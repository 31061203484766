import AsyncSelect from 'react-select/async';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { connect } from '@store';
import { getAssets } from '@core/building/assets';
import { getOrganizations } from '@core/admin/organizations';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import { getUsers } from '@core/building/users';
import { getBuildings } from '@core/building/buildings';

class MaintenancesFilter extends SimpleFilterModal {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      order_number: '',
      as_supplier: true,
      as_owner: true,
    };
  }

  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({
      search: inputValue,
      repairable: true,
      serializer: 'simple',
      as_supplier: true,
      as_owner: true,
      bpm_type: this.props.bpmType,
    });

    if (status === 200) {
      return body.map(({ item: { name }, barcode1, mark, id: value }) => ({
        label: `${name} - ${barcode1} - ${mark}`,
        value,
      }));
    }

    return [];
  };

  findOrganizations = async (inputValue) => {
    const { body, status } = await getOrganizations({ search: inputValue, active: true });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findCreators = async (inputValue) => {
    const { body, status } = await getUsers({ search: inputValue });

    if (status === 200) {
      return body.map(({ firstName, lastName, id: value }) => ({ label: `${firstName} ${lastName}`, value }));
    }

    return [];
  };

  findBuildings = async (inputValue) => {
    const { body, status } = await getBuildings({ search: inputValue });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  renderForm() {
    const { maintenanceStates } = this.props;

    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="assets[{}]">Activos</Label>
              <AsyncSelect
                name="assets[{}]"
                value={this.state['assets[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="state[{}]">Estado</Label>
              <Select
                name="state[{}]"
                value={this.state['state[{}]']}
                placeholder="Todos"
                isClearable
                isMulti
                options={maintenanceStates}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="suppliers[{}]">Proveedores</Label>
              <AsyncSelect
                name="suppliers[{}]"
                value={this.state['suppliers[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findOrganizations}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="order_number">No. Orden</Label>
              <Input
                type="text"
                size="md"
                name="order_number"
                placeholder="Número de orden de compra"
                onChange={(e) => this.onChange(e.target.value, { name: 'order_number' })}
                value={this.state['order_number']}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="owners[{}]">Dueño</Label>
              <AsyncSelect
                name="owners[{}]"
                value={this.state['owners[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findOrganizations}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="creators[{}]">Creadores</Label>
              <AsyncSelect
                name="creators[{}]"
                value={this.state['creators[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findCreators}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="buildings[{}]">Instalación de origen</Label>
              <AsyncSelect
                name="buildings[{}]"
                value={this.state['buildings[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findBuildings}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="destinations[{}]">Instalación de destino</Label>
              <AsyncSelect
                name="destinations[{}]"
                value={this.state['destinations[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findBuildings}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col className="ml-5">
            <Input
              type="checkbox"
              name="as_supplier"
              onChange={(e) => this.onChange(!this.state['as_supplier'], { name: 'as_supplier' })}
              value={this.state['as_supplier']}
              checked={this.state['as_supplier']}
            />{' '}
            Como Proveedor
          </Col>
        </Row>
        <Row>
          <Col className="ml-5">
            <Input
              type="checkbox"
              name="as_owner"
              onChange={(e) => this.onChange(!this.state['as_owner'], { name: 'as_owner' })}
              value={this.state['as_owner']}
              checked={this.state['as_owner']}
            />{' '}
            Como Dueño
          </Col>
        </Row>
      </Form>
    );
  }
}

export const MaintenancesFilterModal = connect(({ appData }, ownProps) => {
  const states = appData[`${ownProps.bpmType}s_states`];
  const maintenanceStates = Object.keys(states).map((key) => ({
    value: key,
    label: states[key].name,
  }));

  return {
    maintenanceStates,
  };
})(MaintenancesFilter);
