import React from 'react';
import { Card, CardBody, CardHeader, Input, Nav, NavItem, NavLink } from 'reactstrap';
import { compose, withState } from 'recompose';

import { Markdown } from '@shared';

const enhance = compose(withState('preview', 'setPreviewMode', false));

const MarkdownPreviewer = ({ preview, setPreviewMode, input, inputProps, meta, ...otherProps }) => (
  <Card>
    <CardHeader>
      <Nav className="nav-tabs card-header-tabs" tabs>
        <NavItem>
          <NavLink active={!preview} onClick={() => setPreviewMode(false)}>
            Edición
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={preview} onClick={() => setPreviewMode(true)}>
            Vista previa
          </NavLink>{' '}
        </NavItem>
      </Nav>
    </CardHeader>

    <CardBody>
      {!preview && <Input {...otherProps} type="textarea" rows={5} />}

      {preview && (otherProps.value ? <Markdown source={otherProps.value} /> : <em>Sin contenidos todavía.</em>)}
    </CardBody>
  </Card>
);

export default enhance(MarkdownPreviewer);
