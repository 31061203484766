import { pluralize } from '@shared/helpers';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { SimCardRow } from './SimCardRow';
import { suspendSimCard, unsuspendSimCard } from '@core/admin/simcards';

class SimCardsResultsPresentation extends Component {
  state = {
    simcards: this.props.simcards,
  };

  toggleSimcard = async (index) => {
    const simcard = this.state.simcards[index];

    const fn = simcard.status === 'active' ? suspendSimCard : unsuspendSimCard;

    // simcard.status = simcard.status === 'active' ? 'suspended' : 'active'

    const { simcards } = this.state;

    const { status, body } = await fn(simcard.iccid);

    if (status === 200) {
      const newDevice = { ...simcard, status: body.status };

      body.status === 'active' ? this.props.activateNotification() : this.props.deactivateNotification();

      this.setState({
        simcards: simcards.map((s) => (s.iccid === simcard.iccid ? newDevice : s)),
      });
    }
  };

  render() {
    const { simcards } = this.state;
    const { pagination } = this.props;

    return (
      <div>
        <div className="small mb-4">
          <strong>{pagination.count}</strong>{' '}
          {pluralize(pagination.count, 'simcard encontrada', 'simcards encontradas')}. Mostrando{' '}
          <strong>{pagination.per}</strong> simcards por página.
        </div>

        <Table>
          <colgroup>
            <col style={{ width: 'auto' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '200px%' }} />
            <col style={{ width: '150px' }} />
          </colgroup>
          <thead>
            <tr>
              <th>ID / Product</th>
              <th className="text-center">Estado de Red</th>
              <th className="text-center">Activo asociado</th>
              <th className="text-center">Tag</th>
              <th className="text-center">Acciones</th>
            </tr>
          </thead>
          <tbody>
            {simcards.map((simcard, index) => (
              <SimCardRow key={index} simcard={simcard} toggleSimcard={() => this.toggleSimcard(index)} />
            ))}

            {simcards.length === 0 && (
              <tr>
                <td colSpan="4" className="text-center mt-3 mb-3">
                  <em>No se encontraron dispositivos.</em>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

export const SimCardsResults = connect(null, (dispatch) => ({
  activateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Tarjeta SIM activado',
        text: 'La tarjeta SIM ha sido activado exitosamente.',
        color: 'success',
      }),
    ),
  deactivateNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Tarjeta SIM desactivada',
        text: 'La tarjeta SIM ha sido desactivado exitosamente.',
        color: 'warning',
      }),
    ),
}))(SimCardsResultsPresentation);
