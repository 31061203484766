export const parseQuery = function (query, cleanParenthesis = true) {
  let finalQuery = {};

  Object.keys(query).forEach((k) => {
    if (k.endsWith('[]')) finalQuery[cleanParenthesis ? k.slice(0, k.length - 2) : k] = query[k] && query[k].join(',');
    else if (k.endsWith('[{}]'))
      finalQuery[cleanParenthesis ? k.slice(0, k.length - 4) : k] =
        query[k] && query[k].map((item) => item.value).join(',');
    else if (k.endsWith('{}')) {
      query[k] &&
        Object.keys(query[k]).forEach((key) => {
          finalQuery[`${k.slice(0, k.length - 2)}[${key}]`] = query[k][key];
        });
    } else finalQuery[k] = query[k];
  });

  return finalQuery;
};
