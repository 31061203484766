import React from 'react';
import { Button } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';
import { ConfirmBox, FontAwesome, ShowForRoles, Spinner } from '@shared';
import { SimpleKitDisplay } from './KitDisplay';

const enhance = compose(
  withState('loading', 'setLoading', false),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    remove: ({ setLoading, deleteKit, setIsOpen }) => async () => {
      setLoading('remove');

      await deleteKit();

      setLoading(false);
      setIsOpen(false);
    },
  }),
  withHandlers({
    openConfirmBox: ({ setIsOpen, displayConfirmBox, remove }) => () => {
      if (displayConfirmBox) {
        setIsOpen(true);
      } else {
        remove();
      }
    },
    closeConfirmBox: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
  }),
);

const KitRow = ({
  kit,
  showBarcode,
  isOpen,
  openConfirmBox,
  closeConfirmBox,
  toogleConfirmBox,
  remove,
  loading,
  kind,
}) => (
  <tr>
    <td className="align-middle small text-overflow">
      <SimpleKitDisplay kit={kit} showBarcode={showBarcode} />
    </td>
    <td className="align-middle small text-overflow">{kit.size}</td>
    <td className="align-middle small text-overflow">{kind[kit.kind]}</td>
    <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
      <td className="align-middle text-right">
        <Button role="button" size="sm" color="danger" onClick={openConfirmBox} disabled={!!loading}>
          {loading === 'remove' ? <Spinner /> : <FontAwesome name="times" />}
        </Button>
        <ConfirmBox
          isOpen={isOpen}
          closeConfirmBox={closeConfirmBox}
          avoidConfirmBox={toogleConfirmBox}
          removeTag={remove}
          type="kit"
          message="Esta acción no es reversible."
          resource={kit.barcode1}
        />
      </td>
    </ShowForRoles>
  </tr>
);

export default enhance(KitRow);
