import { fullName } from '@core/account';
import { DebugCard, FontAwesome } from '@shared';
import portrait from '@shared/assets/anonymous.png';
import { IdentifierDisplay } from '@shared/displays';
import { connect } from '@store';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Input, Row, UncontrolledTooltip } from 'reactstrap';

const UserCardPresentation = ({ user, roles, onSubmit }) => {
  const [editName, setEditName] = useState(false);
  const [editCompany, setEditCompany] = useState(false);
  const [editArea, setEditArea] = useState(false);
  const [editWorkshift, setEditWorkshift] = useState(false);
  // const [editEmail, setEditEmail] = useState(false);
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [company, setCompany] = useState(user.company);
  const [area, setArea] = useState(user.area);
  const [workshift, setWorkshift] = useState(user.workshift);
  // const [email, setEmail] = useState(user.email);
  const [editing, setEditing] = useState(false);

  const updateUser = async () => {
    const payload = {};
    if (firstName !== user.firstName) payload['firstName'] = firstName;
    if (lastName !== user.lastName) payload['lastName'] = lastName;
    if (company !== user.company) payload['company'] = company;
    if (area !== user.area) payload['area'] = area;
    if (workshift !== user.workshift) payload['workshift'] = workshift;
    // if (email !== user.email) payload['email'] = email;
    console.log(Object.keys(payload).length !== 0, payload);
    if (Object.keys(payload).length !== 0) {
      setEditing(true);
      await onSubmit(user.id, payload);
      setEditing(false);
    }
  };

  return (
    <Card style={{ overflow: 'hidden' }}>
      <div className="w-100">
        {user.avatar ? (
          <img src={user.avatar.url} className="w-100" alt={fullName(user)} />
        ) : (
          <img src={portrait} className="w-100" alt={fullName(user)} />
        )}
      </div>
      <CardBody>
        <div className="mb-3">
          <div className="h5 mb-1 d-flex justify-content-between">
            {!editName ? (
              fullName(user)
            ) : (
              <div>
                <div>
                  <small>Nombre: </small>
                  <Input
                    name="firstName"
                    type="text"
                    placeholder="Nombre"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName || ''}
                    size="sm"
                    disabled={editing}
                  />
                </div>
                <div>
                  <small>Apellido: </small>
                  <Input
                    name="lastName"
                    type="text"
                    placeholder="Apellido"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName || ''}
                    size="sm"
                    disabled={editing}
                  />
                </div>
              </div>
            )}{' '}
            <Button
              size="sm"
              id={`name-edit`}
              onClick={() => {
                setEditName(!editName);
                updateUser();
              }}
              style={{ border: 0 }}
              disabled={editing}
            >
              {editName ? (
                <FontAwesome name="check" color={editName ? 'success' : 'primary'} />
              ) : (
                <FontAwesome name="pencil-alt" color={editName ? 'success' : 'primary'} />
              )}
              {editing && (
                <FontAwesome
                  name="circle-notch"
                  className="rotating-spinner"
                  color={editName ? 'success' : 'primary'}
                />
              )}
              <UncontrolledTooltip target={`name-edit`} placement="right">
                Editar
              </UncontrolledTooltip>
            </Button>
          </div>
          <div className="text-muted">{<IdentifierDisplay identifier={user.identifier} />}</div>
        </div>

        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <span>
              <FontAwesome name="envelope" color="primary" />
              Correo{' '}
            </span>
            {/* <Button
              size="sm"
              id={`email-edit`}
              onClick={() => {
                setEditEmail(!editEmail);
                updateUser();
              }}
            >
              {editEmail ? (
                <FontAwesome name="check" color={editEmail ? 'success' : 'primary'} />
              ) : (
                <FontAwesome name="pencil-alt" color={editEmail ? 'success' : 'primary'} />
              )}
              <UncontrolledTooltip target={`email-edit`} placement="right">
                Editar
              </UncontrolledTooltip>
            </Button> */}
          </div>
          <div className="text-muted">
            {user.email}
            {/* {!editEmail ? (
              user.email
            ) : (
              <div className="mt-1">
                <Input
                  name="email"
                  type="text"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email || ''}
                  size="sm"
                  disabled={editing}
                />
              </div>
            )} */}
          </div>
        </div>

        <div className="mb-3">
          <div>
            <FontAwesome name="phone" color="primary" /> Teléfono
          </div>
          <div className="text-muted">{user.phone}</div>
        </div>

        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <span>
              <FontAwesome name="layer-group" color="primary" /> Empresa{' '}
            </span>{' '}
            <Button
              size="sm"
              id={`company-edit`}
              onClick={() => {
                setEditCompany(!editCompany);
                updateUser();
              }}
              style={{ border: 0 }}
              disabled={editing}
            >
              {editCompany ? (
                <FontAwesome name="check" color={editCompany ? 'success' : 'primary'} />
              ) : (
                <FontAwesome name="pencil-alt" color={editCompany ? 'success' : 'primary'} />
              )}
              {editing && (
                <FontAwesome
                  name="circle-notch"
                  className="rotating-spinner"
                  color={editCompany ? 'success' : 'primary'}
                />
              )}
              <UncontrolledTooltip target={`company-edit`} placement="right">
                Editar Empresa
              </UncontrolledTooltip>
            </Button>
          </div>
          <div className="text-muted">
            {!editCompany ? (
              company
            ) : (
              <div>
                <div>
                  <Input
                    name="company"
                    type="text"
                    placeholder="Company"
                    onChange={(e) => setCompany(e.target.value)}
                    value={company || ''}
                    size="sm"
                    disabled={editing}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <span>
              <FontAwesome name="layer-group" color="primary" /> Área{' '}
            </span>{' '}
            <Button
              size="sm"
              id={`area-edit`}
              onClick={() => {
                setEditArea(!editArea);
                updateUser();
              }}
              style={{ border: 0 }}
              disabled={editing}
            >
              {editArea ? (
                <FontAwesome name="check" color={editArea ? 'success' : 'primary'} />
              ) : (
                <FontAwesome name="pencil-alt" color={editArea ? 'success' : 'primary'} />
              )}
              {editing && (
                <FontAwesome
                  name="circle-notch"
                  className="rotating-spinner"
                  color={editArea ? 'success' : 'primary'}
                />
              )}
              <UncontrolledTooltip target={`area-edit`} placement="right">
                Editar Area
              </UncontrolledTooltip>
            </Button>
          </div>
          <div className="text-muted">
            {!editArea ? (
              area
            ) : (
              <div>
                <div>
                  <Input
                    name="area"
                    type="text"
                    placeholder="Area"
                    onChange={(e) => setArea(e.target.value)}
                    value={area || ''}
                    size="sm"
                    disabled={editing}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <span>
              <FontAwesome name="layer-group" color="primary" /> Turno{' '}
            </span>{' '}
            <Button
              size="sm"
              id={`workshift-edit`}
              onClick={() => {
                setEditWorkshift(!editWorkshift);
                updateUser();
              }}
              style={{ border: 0 }}
              disabled={editing}
            >
              {editWorkshift ? (
                <FontAwesome name="check" color={editWorkshift ? 'success' : 'primary'} />
              ) : (
                <FontAwesome name="pencil-alt" color={editWorkshift ? 'success' : 'primary'} />
              )}
              {editing && (
                <FontAwesome
                  name="circle-notch"
                  className="rotating-spinner"
                  color={editWorkshift ? 'success' : 'primary'}
                />
              )}
              <UncontrolledTooltip target={`workshift-edit`} placement="right">
                Editar Turno
              </UncontrolledTooltip>
            </Button>
          </div>
          <div className="text-muted">
            {!editWorkshift ? (
              workshift
            ) : (
              <div>
                <div>
                  <Input
                    name="workshift"
                    type="text"
                    placeholder="Workshift"
                    onChange={(e) => setWorkshift(e.target.value)}
                    value={workshift || ''}
                    size="sm"
                    disabled={editing}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="mb-3">
          <div>
            <FontAwesome name="handshake" color="primary" /> Socio de negocios
          </div>
          <div className="text-muted">{user.partner?.name}</div>
        </div>

        {user.newRoles.length > 0 && (
          <div className="mb-3">
            <div className="d-flex justify-content-between">
              <span>
                <FontAwesome name="key" color="primary" /> {user.newRoles.length > 1 ? 'Permisos' : 'Permiso'}
              </span>
              <span>
                <Button
                  role="button"
                  color="success"
                  size="sm"
                  tag={NavLink}
                  to={`/building/users/${user.id}/memberships`}
                >
                  <FontAwesome name="pencil-alt" />
                </Button>
              </span>
            </div>
            <div className="text-muted">
              <ul className="pl-4">
                {user.newRoles.map((role, index) => (
                  <li key={index}>
                    <strong>{role.building}:</strong>{' '}
                    {(roles[role.role] && roles[role.role].title) || <em>Sin identificar</em>}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {process.env.NODE_ENV === 'development' && (
          <Row>
            <Col>
              <DebugCard data={{ firstName: firstName, lastName: lastName }} />
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export const UserCard = connect(({ appData: { roles } }) => ({ roles }))(UserCardPresentation);
