import React from 'react';
import { Media } from 'reactstrap';

import { getMachineTypes, getMachineType } from '@core/machine-types';
import { RemoteSelector } from '@core/forms';

const MachineTypeCard = ({ resource: machineType }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{machineType.name}</strong>
      </div>
    </Media>
  </Media>
);

const MachineTypeSelector = ({ inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getMachineType}
    remoteFinder={(query) =>
      getMachineTypes({
        search: query,
      })
    }
    dropdownTemplate={MachineTypeCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre del tipo de máquina (Workstation, Vending, Lockers...)',
    }}
  />
);

export default MachineTypeSelector;
