import React from 'react';
import type { ComponentType, ElementRef } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { withStateHandlers } from 'recompose';
import type { HOC } from 'recompose';

import { FontAwesome } from '@shared';

import MassImportFileForm from './components/MassImportFileForm';

type Props = {
  entity: string,
  file: File,
  finished: boolean,
  instructions: ComponentType<any>,
  onSubmit: Function,
  processing: boolean,
  queried: boolean,
  reset: (input: ElementRef<'input'>) => any,
  selected: Object,
  setFile: Function,
};

let fileInput: ElementRef<'input'> = React.createRef();

const enhance: HOC<*, Props> = withStateHandlers(
  () => ({
    showInstructions: false,
  }),
  {
    toogleInstructions: ({ showInstructions }, _) => () => ({
      showInstructions: !showInstructions,
    }),
  },
);

const HeaderRow = ({
  // props
  entity,
  file,
  finished,
  instructions: InstructionsComponent,
  onSubmit,
  processing,
  queried,
  reset,
  selected,
  setFile,
  // state handlers
  showInstructions,
  toogleInstructions,
}) => (
  <Row className="justify-content-center">
    <Col xs={12} sm={12} md={12} lg={10}>
      <Card>
        <CardBody>
          <CardTitle className="mb-4 text-center" tag={'h4'}>
            {entity}: Importación masiva
          </CardTitle>
          <MassImportFileForm
            file={file}
            finished={finished}
            ref={fileInput}
            onSubmit={onSubmit}
            processing={processing}
            queried={queried}
            reset={() => {
              reset(fileInput);
            }}
            setFile={setFile}
            selected={selected}
          />
        </CardBody>

        <CardFooter role="button" onClick={toogleInstructions} className="alert-info h5 m-0">
          {showInstructions ? <FontAwesome name="chevron-down" /> : <FontAwesome name="chevron-right" />}
          {` `}
          Instrucciones y más información
        </CardFooter>

        {showInstructions && (
          <ListGroup flush>
            <ListGroupItem>
              <InstructionsComponent />
            </ListGroupItem>
          </ListGroup>
        )}
      </Card>
    </Col>
  </Row>
);

export default enhance(HeaderRow);
