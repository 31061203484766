import React from 'react';
import { Row, Col } from 'reactstrap';
import { Field } from '@core/forms';

export const TagMetadata = ({ params }) => (
  <Row>
    <Col md="12">
      <h4 className="mb-3">Parametros</h4>
    </Col>
    {params.map((param, index) => (
      <Col md="4" key={index}>
        <Field name={'metadata.' + param} label={param.replace(/([A-Z])/g, ' $1')} size="sm" required={true} />
      </Col>
    ))}
  </Row>
);
