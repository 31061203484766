import React from 'react';
import * as $ from 'jquery';
import { Table } from 'reactstrap';
import { compose, defaultProps, withHandlers, withStateHandlers } from 'recompose';

import type { HOC } from 'recompose';

import GridHeader from './GridHeader.js';
import GridFooter from './GridFooter.js';
import GridRows from './GridRows.js';

import type { ColumnMetadata } from './types';

type Props = {
  checkAll: boolean,
  columnMetadata: ColumnMetadata[],
  currentPage: number,
  data: Object[],
  editable?: boolean,
  onChangeGrid: Function,
  onEditRow: Function,
  resultsPerPage: number,
  selectedRows: Object,
  showCheckbox: boolean,
  showFooter: boolean,
  showHeader: boolean,
  sortColumn: string,
  sortDirection: string,
  trackBy: string,
};

const sortDataOnColumn = (data: Object[], sortColumn: string, sortDirection: string) => {
  if (sortColumn === '') {
    return data;
  }

  data.sort((a, b) => {
    const aValue = a[sortColumn] || '',
      bValue = b[sortColumn] || '';

    if (sortDirection === 'ASC') {
      const num = aValue.toString().localeCompare(bValue.toString());
      if (num > 0) return 1;
      if (num < 0) return -1;
      return 0; // equal
    } else {
      const num = bValue.toString().localeCompare(aValue.toString());
      if (num > 0) return 1;
      if (num < 0) return -1;
      return 0;
    }
  });
  return data;
};

const getResponseData = (
  data: Object[],
  currentPage: number,
  resultsPerPage: number,
  sortColumn: string,
  sortDirection: string,
) => {
  const startIndex = (currentPage - 1) * resultsPerPage;
  const endIndex = startIndex + resultsPerPage;

  let pageData = sortDataOnColumn(data, sortColumn, sortDirection);

  pageData = pageData.slice(startIndex, endIndex);
  return pageData;
};

const enhance: HOC<*, Props> = compose(
  defaultProps({
    checkAll: false,
    currentPage: 1,
    data: [],
    editable: true,
    resultsPerPage: 10,
    selectedRows: {},
    showCheckbox: true,
    showFooter: true,
    showHeader: true,
    sortColumn: '',
    sortDirection: '',
    onEditRow: () => {},
  }),
  withStateHandlers(() => ({ isEditing: false }), {
    updateState: () => (state) => state,
  }),
  withHandlers({
    onChangeGrid: ({ onChangeGrid }) => (data: Object[]) => {
      data = data || {};

      onChangeGrid && onChangeGrid(data);
    },

    onEditRow: ({ onEditRow, trackBy }) => (updatedRow: Object) => {
      onEditRow(trackBy, updatedRow);
    },
  }),
);

const SmartGrid = ({
  // props
  checkAll,
  columnMetadata,
  currentPage,
  data,
  editable,
  resultsPerPage,
  showCheckbox,
  showFooter,
  showHeader,
  selectedRows,
  sortColumn,
  sortDirection,
  // state
  isEditing,
  // handlers
  onChangeGrid,
  onEditRow,
  // state handlers
  updateState,
}) => {
  const pageData = data ? getResponseData(data, currentPage, resultsPerPage, sortColumn, sortDirection) : [];

  const resultsOnPage = data && data.length <= resultsPerPage ? data.length : resultsPerPage;

  return (
    <Table hover className={`smartgrid ${isEditing ? 'is-editing' : ''}`}>
      <GridHeader
        checkAll={checkAll}
        columnMetadata={columnMetadata}
        data={data}
        onChangeGrid={onChangeGrid}
        resultsOnPage={resultsOnPage}
        showCheckbox={showCheckbox}
        showHeader={showHeader}
        selectedRows={selectedRows}
        sortColumn={sortColumn}
        sortDirection={sortDirection}
      />
      <GridRows
        columnMetadata={columnMetadata}
        data={pageData}
        editable={editable}
        onChangeGrid={onChangeGrid}
        onToggleEdit={(isEditing) => {
          updateState({ isEditing });
        }}
        onEditRow={onEditRow}
        showCheckbox={showCheckbox}
        selectedRows={selectedRows}
      />
      <GridFooter
        columnMetadata={columnMetadata}
        currentPage={currentPage}
        onChangeGrid={(params) => {
          $('.smartgrid.is-editing .btn.btn-danger').click();
          onChangeGrid(params);
        }}
        showFooter={showFooter}
        showCheckbox={showCheckbox}
        totalCount={data.length}
        resultsOnPage={resultsOnPage}
        resultsPerPage={resultsPerPage}
      />
    </Table>
  );
};

export default enhance(SmartGrid);
