import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getTags = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getShortTags = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags${mountQuery({ ...query, serializer: 'short' })}`,
      method: 'GET',
    }),
  );

export const getTag = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags/${id}`,
      method: 'GET',
    }),
  );

export const createTag = ({ ...tag }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags`,
      method: 'POST',
      body: {
        tag,
      },
    }),
  );

export const editTag = ({ id, ...tag }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags/${id}`,
      method: 'PUT',
      body: {
        tag,
      },
    }),
  );

export const deleteTag = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags/${id}`,
      method: 'DELETE',
    }),
  );

export const putTagImei = (id, imei) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags/${id}/put_tag_imei`,
      method: 'POST',
      body: {
        id,
        imei,
      },
    }),
  );

export const deleteTagImei = (id, imei) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags/${id}/delete_tag_imei?imei=${imei}`,
      method: 'DELETE',
    }),
  );

export const getDelayedTags = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags/dashboard${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createOnboarding = (tag) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/tags/create_from_app`,
      method: 'POST',
      body: tag,
    }),
  );
