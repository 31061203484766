import { FontAwesome, ProcessingButton } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

export const CategoryRow = ({ category, onDelete, processing }) => (
  <tr>
    <th scope="row" className="text-center align-middle">
      {category.id}
    </th>
    <td className="align-middle">{category.name}</td>
    <td className="align-middle">{category.description}</td>
    <td className="align-middle text-right">
      <Button role="button" size="sm" color="primary" tag={NavLink} to={`/organization/categories/${category.id}`}>
        <FontAwesome name="pencil-alt" />
      </Button>{' '}
      <ProcessingButton role="button" size="sm" color="danger" onClick={onDelete} processing={processing}>
        <FontAwesome name="times" />
      </ProcessingButton>
    </td>
  </tr>
);
