import Highcharts from 'highcharts';
import applyExporting from 'highcharts/modules/exporting';
import applyOffline from 'highcharts/modules/offline-exporting';
import applyDrilldown from 'highcharts/modules/drilldown';

applyExporting(Highcharts);
applyOffline(Highcharts);
applyDrilldown(Highcharts);

export { Highcharts };
