import { ConfirmBox, FontAwesome, ShowForRoles, Spinner } from '@shared';
import { TagDisplay } from '@shared/displays';
import { SimpleAssetDisplay } from '@shared/displays/AssetDisplay';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';
import { MetadataDisplay } from './MetadataDisplay';

const enhance = compose(
  withState('loading', 'setLoading', false),
  withState('isOpen', 'setIsOpen', false),
  withHandlers({
    remove: ({ setLoading, removeTag, setIsOpen }) => async () => {
      setLoading('remove');

      await removeTag();

      setLoading(false);
      setIsOpen(false);
    },
  }),
  withHandlers({
    toggleImei: ({ setLoading, toggleImei }) => async () => {
      setLoading('imei');

      await toggleImei();

      setLoading(false);
    },
  }),
  withHandlers({
    toggleStatus: ({ setLoading, toggleTag }) => async () => {
      setLoading('toggle');

      await toggleTag();

      setLoading(false);
    },
    openConfirmBox: ({ setIsOpen, displayConfirmBox, remove }) => () => {
      if (displayConfirmBox) {
        setIsOpen(true);
      } else {
        remove();
      }
    },
    closeConfirmBox: ({ setIsOpen }) => () => {
      setIsOpen(false);
    },
  }),
);

const TagRow = ({
  tag,
  isOpen,
  openConfirmBox,
  closeConfirmBox,
  toogleConfirmBox,
  showAssetColumn,
  showState,
  remove,
  toggleStatus,
  toggleImei,
  loading,
}) => (
  <tr>
    <td className="align-middle small">
      <TagDisplay tag={tag} showStatus={showState} />
    </td>
    {showAssetColumn && (
      <th scope="row" className="align-middle small">
        <SimpleAssetDisplay asset={tag.asset} showStatus={false} showBarcode={true} />
      </th>
    )}

    <td className="align-middle text-center small">
      <MetadataDisplay tagId={tag.id} metadata={tag.metadata} />
    </td>

    <ShowForRoles accepted={['organization_admin', 'warehouse_admin']}>
      <td className="align-middle text-right">
        <Button role="button" size="sm" color="primary" tag={NavLink} to={`/building/tags/${tag.id}`}>
          <FontAwesome name="pencil-alt" />
        </Button>{' '}
        <Button
          role="button"
          size="sm"
          color={tag.active ? 'warning' : 'success'}
          onClick={toggleStatus}
          disabled={!!loading}
        >
          {loading === 'toggle' ? <Spinner /> : <FontAwesome name="power-off" />}
        </Button>{' '}
        {tag.tagType.name === 'gps' && (
          <>
            <Button
              role="button"
              size="sm"
              color={tag.imeiPresent ? 'success' : 'warning'}
              onClick={toggleImei}
              disabled={!!loading}
            >
              {loading === 'imei' ? <Spinner /> : <FontAwesome name="globe-europe" />}
            </Button>{' '}
          </>
        )}
        <Button role="button" size="sm" color="danger" onClick={openConfirmBox} disabled={!!loading}>
          {loading === 'remove' ? <Spinner /> : <FontAwesome name="times" />}
        </Button>
        <ConfirmBox
          isOpen={isOpen}
          closeConfirmBox={closeConfirmBox}
          avoidConfirmBox={toogleConfirmBox}
          removeTag={remove}
          type="tag"
          message="Esta acción no es reversible y el identificador del tag podría ser utilizado en cualquier otra instalación."
          resource={tag.identifier}
        />
      </td>
    </ShowForRoles>
  </tr>
);

export default enhance(TagRow);
