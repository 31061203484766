import { getUserGroup } from '@core/building/user_groups';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { FontAwesome, withLoader } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import React from 'react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Media, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { branch, compose, renderNothing, withProps, withState } from 'recompose';
import { MembersIndex } from './components/UserGroupMembers';

const enhance = compose(
  withRouter,
  withState('members', 'setMembers', []),
  withProps(({ match: { params: { id } } }) => ({ id })),
  withLoader(
    'userGroup',
    ({
      match: {
        params: { id },
      },
    }) => getUserGroup(id),
    {
      onError: console.log,
    },
  ),
  branch(({ userGroup }) => !userGroup, renderNothing),
);

const UserGroupPagePresentation = ({ match, userGroup }) => (
  <BuildingLayout>
    {console.log(match)}
    <Row>
      <Col>
        <Card>
          <CardBody>
            <Media className="align-items-center">
              <Media body>
                <div className="small text-muted">Grupo de usuarios</div>
                <h4>{userGroup.name}</h4>
                <div>
                  <small>{userGroup.description}</small>
                </div>
              </Media>
            </Media>
          </CardBody>
        </Card>
        <Card className="mt-4">
          <CardHeader>
            <Nav tabs className="card-header-tabs">
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/user_groups/${userGroup.id}`}>
                  <FontAwesome name="list-alt" /> Miembros
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <Switch>
              <ProtectedRoute
                exact
                path={`${match.url}`}
                render={(props) => <MembersIndex userGroup={userGroup} {...props} />}
                id={userGroup.id}
              />
            </Switch>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </BuildingLayout>
);

export const UserGroupPage = enhance(UserGroupPagePresentation);
