import { Spinner } from '@shared';
import React, { Component } from 'react';
import { Alert, Button } from 'reactstrap';

export class ActionPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actionIndex: undefined,
      performedActionIndex: undefined,
      hide: false,
    };
  }

  // Adds state wrapper to current action
  get actions() {
    const { actions } = this.props;

    return actions.map((action, index) => ({
      ...action,
      action: () => this.executeAction(index),
    }));
  }

  executeAction = (index) => {
    const action = this.props.actions[index];

    if (!action) return;

    this.setState({
      actionIndex: index,
      performedActionIndex: undefined,
    });

    action.action().then(() => {
      this.setState({
        actionIndex: undefined,
        performedActionIndex: index,
      });

      setTimeout(() => this.setState({ hide: true }), 1500);
    });
  };

  render() {
    const { condition, children, color } = this.props;
    const { actionIndex, performedActionIndex, hide } = this.state;

    if (hide || !condition) return null;

    return (
      <ActionDialog
        actions={this.actions}
        currentAction={actionIndex}
        performedAction={performedActionIndex}
        color={color}
        close={() => this.setState({ hide: true })}
      >
        {children}
      </ActionDialog>
    );
  }
}

const ActionDialog = ({ actions, currentAction, performedAction, color, children, close }) => (
  <Alert color={color} className="alert-dismissible">
    <button type="button" className="close" onClick={close}>
      <span aria-hidden="true">&times;</span>
    </button>
    {children}
    <p>
      {actions.map((action, index) => (
        <span key={index}>
          <Button
            role="button"
            color={action.color}
            onClick={action.action}
            disabled={currentAction !== undefined || performedAction !== undefined}
          >
            {currentAction === index ? <Spinner /> : performedAction === index ? action.performedTitle : action.title}
          </Button>
          {` `}
        </span>
      ))}
    </p>
  </Alert>
);
