import { fromIso8601 } from '@core/utils/dates';
import { PictureDisplay, Spinner } from '@shared/';
import { AssetDisplay, ItemDisplay } from '@shared/displays';
import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col, Badge } from 'reactstrap';

export const InventoryCheckerDetail = ({ inventoryChecker, isOpen, toggle, states }) => (
  <Modal isOpen={isOpen} toggle={toggle} size="xl">
    <ModalHeader toggle={toggle}>
      <Row>
        <Col>
          <h5>Detalle de la auditoría</h5>
          <div>
            <small className="text-muted">
              Nº: {inventoryChecker && isOpen ? inventoryChecker.requestNumber : <Spinner />}
            </small>
          </div>
          <div>
            <small className="text-muted">
              Fecha: {inventoryChecker && isOpen ? fromIso8601(inventoryChecker.createdAt, true) : <Spinner />}
            </small>
          </div>
          <div>
            <small className="text-muted">
              Última Actualización:{' '}
              {inventoryChecker && isOpen ? fromIso8601(inventoryChecker.updatedAt, true) : <Spinner />}
            </small>
          </div>
          <div>
            <small className="text-muted">
              Tipo:{' '}
              {inventoryChecker && isOpen ? (
                inventoryChecker.stock === 'confirmed' ? (
                  'Existencias'
                ) : (
                  'Inexistencias'
                )
              ) : (
                <Spinner />
              )}
            </small>
          </div>
        </Col>
      </Row>
    </ModalHeader>
    {inventoryChecker && isOpen ? (
      <ModalBody>
        <Row>
          <Col>
            <strong>Instalación:</strong> {inventoryChecker.building.name}
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Auditor:</strong> {inventoryChecker.user.name}
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Tipo de Muestra:</strong>{' '}
            <Badge color={inventoryChecker.consumable ? 'primary' : 'primary'}>
              {inventoryChecker?.consumable ? 'Consumibles' : 'Activos'}
            </Badge>
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Estado:</strong>{' '}
            {inventoryChecker?.status && (
              <Badge id="Estado" color={states[inventoryChecker.status].color}>
                {states[inventoryChecker.status].name}
              </Badge>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Origen:</strong>{' '}
            <Badge color={inventoryChecker.consumable ? 'primary' : 'primary'}>
              {inventoryChecker?.origin === 'manual'
                ? 'Manual'
                : inventoryChecker?.origin === 'auto'
                ? 'Automático'
                : ''}
            </Badge>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <strong>Destinatarios:</strong>
          </Col>
        </Row>
        {inventoryChecker?.emails &&
          inventoryChecker?.emails.split(',').map((email, index) => (
            <Row key={`email-${index}`}>
              <Col>
                <a href={`mailto:${email.trim()}`}>{email.trim()}</a>
              </Col>
            </Row>
          ))}
        <hr />
        <Row>
          <Col>
            <strong className="mb-3">Muestras:</strong>
            {inventoryChecker?.assets?.length > 0 &&
              inventoryChecker.assets.map((asset, index) => (
                <Row className="mb-1 mt-2">
                  {asset.evidence && (
                    <PictureDisplay picture={asset.evidence} pictureSize="icon" alt={`Evidencia: ${asset.barcode1}`} />
                  )}
                  <AssetDisplay key={`asset-${index}`} asset={asset} />
                </Row>
              ))}
            {inventoryChecker?.items?.length > 0 &&
              inventoryChecker.items.map((item, index) => (
                <Row className="mb-1 mt-2">
                  {item.evidence && (
                    <PictureDisplay picture={item.evidence} pictureSize="icon" alt={`Evidencia: ${item.barcode1}`} />
                  )}
                  <ItemDisplay key={`item-${index}`} item={item} inventory={true} sku={true} inventoryChecker={true} />
                </Row>
              ))}
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>
            <strong>Comentarios:</strong>
            <div>{inventoryChecker.description}</div>
          </Col>
        </Row>
      </ModalBody>
    ) : (
      <div className="text-center h1 text-info mt-1">
        <Spinner />
      </div>
    )}
    <ModalFooter>
      <Button role="button" onClick={toggle}>
        Cerrar
      </Button>
    </ModalFooter>
  </Modal>
);
