import { createMember, deleteMember, getMembers } from '@core/building/members';
import { getUsers } from '@core/building/users';
import ChileanRUT from '@core/utils/chilean-rut';
import { FontAwesome, Pagination, ShowForRoles, Spinner, UserPortrait } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Table, Badge, Button, UncontrolledTooltip, Card, CardBody, Media, Row, Col, Container } from 'reactstrap';
import { UserGroupMembersFilterModal } from './UserGroupMembersFilterModal';
import AsyncSelect from 'react-select/async';
import { LOADING_MESSAGE, NO_OPTIONS } from '@shared/simple-filter/SimpleFilter';
import { SubmissionError } from '@core/forms';
import { pluralize } from '@shared/helpers';

export const UserGroupMembers = ({ members, userGroup, handleFunction, pagination }) => {
  const deleteUserMember = async (id) => {
    const { status } = await deleteMember(userGroup.id, id);
    if (status === 204) {
      await handleFunction();
    }
  };

  return (
    <Card>
      <CardBody>
        <Media className="align-items-center">
          <Media body>
            <Table size="sm">
              <colgroup>
                <col style={{ width: 'auto' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: 'auto' }} />
                <col style={{ width: 'auto' }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th className="text-center">Role</th>
                  <ShowForRoles accepted={['organization_admin']}>
                    <th className="text-center">Acciones</th>
                  </ShowForRoles>
                </tr>
              </thead>
              <tbody>
                {members &&
                  members.map((member, index) => (
                    <tr key={`member-${index}`}>
                      <td className="align-middle">
                        <NavLink className="d-flex" to={`/building/users/${member?.user?.id}`}>
                          <UserPortrait user={member?.user} />
                          <span className="ml-2" style={{ lineHeight: '2rem' }}>
                            {member?.user?.name}
                          </span>
                        </NavLink>
                      </td>
                      <td className="align-middle text-center">
                        <Badge color={member?.role === 'admin' ? 'success' : 'info'} pill>
                          {member?.role === 'admin' ? 'Administrador' : 'Usuario'}
                        </Badge>
                      </td>
                      <ShowForRoles accepted={['organization_admin']}>
                        <td className="align-middle text-center">
                          <Button
                            id={`DeleteMember-${member?.id}`}
                            size="sm"
                            color="danger"
                            onClick={() => deleteUserMember(member?.id)}
                            disabled={member?.role === 'admin'}
                          >
                            <FontAwesome name="times" />
                          </Button>
                        </td>
                      </ShowForRoles>
                      <UncontrolledTooltip placement="auto" target={`DeleteMember-${member?.id}`}>
                        Eliminar del grupo
                      </UncontrolledTooltip>
                    </tr>
                  ))}

                {members.length === 0 && (
                  <tr>
                    <td colSpan="5" className="text-center mt-3 mb-3">
                      <em>No se encontraron miembros en este grupo.</em>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Media>
        </Media>
      </CardBody>
    </Card>
  );
};

export class MembersIndex extends SimpleFilter {
  state = { ...this.state, newMember: undefined };

  searchFunction = (value) => getMembers(this.props.userGroup.id, { pp: 10, ...value });

  findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return body.map(({ firstName, lastName, identifier, id }) => ({
        label: `${firstName} ${lastName} - ${
          identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
        }`,
        value: id,
      }));
    }
    return [];
  };

  setNewMember = (member) => {
    this.setState({ newMember: member });
  };

  addNewMember = async () => {
    const { newMember } = this.state;
    const { body, status } = await createMember(this.props.userGroup.id, {
      user_id: newMember.value,
    });
    if (status === 201) {
      this.getItems();
    } else {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { items, filters, pagination, modalOpen, newMember } = this.state;
    const { userGroup } = this.props;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button tag={NavLink} size="sm" outline color="info" to={`/building/user_groups/${userGroup?.id}/edit`}>
                <FontAwesome name="pencil-alt" /> Editar Grupo
              </Button>
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
            </div>
            <div className="content-actions col-9">
              <Row>
                <Col md={9}>
                  <AsyncSelect
                    name="newMember"
                    value={newMember}
                    isClearable
                    isSearchable
                    placeholder="Buscar nuevo miembro..."
                    loadOptions={this.findUsers}
                    noOptionsMessage={() => NO_OPTIONS}
                    loadingMessage={() => LOADING_MESSAGE}
                    onChange={this.setNewMember}
                  />
                </Col>
                <Col>
                  <Button outline color="success" onClick={this.addNewMember}>
                    <FontAwesome name="plus" /> Agregar
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <CardBody>
            {!items && (
              <div className="text-center h1 text-info">
                <Spinner />
              </div>
            )}
            {
              <div className="small mb-4">
                <strong>{pagination?.count}</strong>{' '}
                {pluralize(pagination?.count, 'miembro encontrado', 'miembros encontrados')}. Mostrando{' '}
                <strong>{pagination?.per}</strong> miembros por página.
              </div>
            }
            {items && <UserGroupMembers members={items} userGroup={userGroup} handleFunction={this.getItems} />}
            {(!items || items.length > 0) && paginationTemplate}
          </CardBody>
        </Row>
        <UserGroupMembersFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </Container>
    );
  }
}
