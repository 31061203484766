import React from 'react';
import { Media, Card, CardBody } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Barcode, PictureDisplay } from '@shared';
import { get } from 'lodash';

import './AssetDisplay.scss';

export const AssetDisplay = ({ asset, geofence }) => (
  <Card className="asset-display" tag={NavLink} to={`/building/assets/${asset.barcode1}`}>
    <CardBody>
      <Media className="align-items-center">
        <Media left className="media-container">
          <Barcode code={asset.barcode1} style={{ height: '3rem' }} className="media-barcode" />
          <PictureDisplay
            className="media-picture"
            picture={get(asset, 'defaultPicture.file', null) || get(asset, 'item.defaultPicture.file', null)}
            pictureSize="icon"
            alt={asset.item.name}
            width="3rem"
            height="3rem"
          />
        </Media>
        <Media body>
          <div>
            <span>{asset.item.name}</span>
          </div>
          <div>
            <kbd>{asset.barcode1}</kbd>
          </div>
        </Media>
      </Media>
    </CardBody>
  </Card>
);
