import { getDevice } from '@core/building/devices';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { Loading } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { DeviceHeader } from './components/DeviceHeader';
import { DeviceKeys } from './components/DeviceKeys';
import { DeviceInventoryIndex } from './components/DeviceInventoryIndex';
import { DeviceLayout } from './components/DeviceLayout';
import { DeviceSummary } from './components/DeviceSummary';
import { getDeviceInventory } from '@core/building/devices';
import { DeviceStocks } from './components/DeviceStocks';

class DevicePagePresentation extends Component {
  state = {
    notFound: false,
    loading: true,
    device: {},
  };

  async componentDidMount() {
    const { barcode } = this.props.match.params;

    if (!barcode) {
      this.notFound();
      return;
    }

    const { body: device, status } = await getDevice(barcode);

    if (status === 404) {
      this.notFound();
      return;
    } else if (status === 200) {
      this.setState({
        loading: false,
        device,
      });
    }
  }

  notFound = () => {
    this.setState({ notFound: true });
    this.props.notFound();
  };

  render() {
    const { match, location } = this.props;
    const { loading, device, notFound } = this.state;

    if (notFound) return <Redirect to="/building/devices" />;

    if (loading) return <Loading />;

    return (
      <BuildingLayout>
        <div className="container-fluid">
          <Row className="justify-content-center">
            <Col>
              {device && (
                <div>
                  <DeviceHeader device={device} />

                  <DeviceLayout device={device}>
                    <Switch>
                      <ProtectedRoute exact path={`${match.url}/`} render={() => <DeviceSummary device={device} />} />
                      <ProtectedRoute
                        exact
                        path={`${match.url}/inventory`}
                        render={() => (
                          <DeviceInventoryIndex
                            device={device}
                            location={location}
                            search={getDeviceInventory}
                            switcher={false}
                          />
                        )}
                      />
                      <ProtectedRoute exact path={`${match.url}/keys`} render={() => <DeviceKeys device={device} />} />
                      <ProtectedRoute
                        exact
                        path={`${match.url}/stocks`}
                        render={() => <DeviceStocks device={device} />}
                      />
                    </Switch>
                  </DeviceLayout>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </BuildingLayout>
    );
  }
}

export const DevicePage = connect(null, (dispatch) => ({
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Dispositivo no encontrado',
        text:
          'El dispositivo buscado no existe o no se encuentra disponible. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(DevicePagePresentation);
