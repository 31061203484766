import { Field, Form } from '@core/forms';
import Validates, { emailFormat, format, presence } from '@core/forms/validations';
import { ProcessingButton, Spinner } from '@shared';
import { BuildingRoleSelector, IdentifierWidgetComponent } from '@shared/forms';
import PartnerBuildingSelector from '@shared/forms/PartnerBuildingSelector';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { reduxForm } from 'redux-form';

const validate = Validates(
  presence({ of: 'firstName' }),
  emailFormat({ of: 'email' }),
  format({
    of: 'phone',
    with: /^\+\d{5,}$/gi,
    message: 'Debe ser de la forma +56 ...',
  }),
  presence({ of: 'role' }),
);

const UserFormPresentation = ({ error, handleSubmit, invalid, loading, pristine, submitting }) => (
  <Form noValidate error={error} onSubmit={handleSubmit}>
    <h5 className="mb-3">Identificación</h5>
    <Row>
      <Col>
        <Field label="Nombres" name="firstName" required />
      </Col>
      <Col>
        <Field label="Apellidos" name="lastName" required />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          label="Identificación"
          component={IdentifierWidgetComponent}
          name="identifierValue"
          type="text"
          required
          help="Sin puntos ni guiones."
        />
      </Col>
      <Col />
    </Row>

    <h5 className="mb-3">Información de contacto</h5>

    <Row>
      <Col>
        <Field label="Email" name="email" type="email" required />
      </Col>
      <Col>
        <Field label="Teléfono" name="phone" required />
      </Col>
    </Row>

    <h5 className="mb-3">Información Empresarial</h5>

    <Row>
      <Col>
        <Field label="Empresa" name="company" type="text" />
      </Col>
      <Col>
        <Field label="Área" name="area" type="text" />
      </Col>
      <Col>
        <Field label="Turno" name="workshift" type="text" />
      </Col>
      <Col>
        <Field
          label="Socio de negocios"
          name="partnerId"
          component={PartnerBuildingSelector}
          filters={{ active: true }}
          help="Socio de negocios al que pertenecerá el usuario"
        />
      </Col>
    </Row>

    <h5 className="mb-3">Información de acceso</h5>

    <Row>
      <Col>
        <Field
          label="Rol"
          name="role"
          component={BuildingRoleSelector}
          required
          help="Este es el rol inicial asignado al usuario en la instalación. Posteriormente podrás agregar más roles para el mismo usuario o incluso removerlos."
        />
      </Col>
      <Col />
    </Row>

    <hr />

    <div className="text-center">
      <ProcessingButton
        type="submit"
        role="button"
        color="primary"
        outline
        disabled={pristine || invalid}
        processing={submitting}
        width="10em"
      >
        {loading ? <Spinner /> : 'Guardar'}
      </ProcessingButton>
    </div>
  </Form>
);

export const UserForm = reduxForm({
  form: 'buildingUserForm',
  validate,
})(UserFormPresentation);
