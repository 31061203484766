import React from 'react';
import { Media } from 'reactstrap';

import { getUser, getUsers } from '@core/admin/users';
import { getUser as getBuildingUser, getUsers as getBuildingUsers } from '@core/building/users';
import ChileanRUT from '@core/utils/chilean-rut';
import { RemoteSelector } from '@core/forms';
import { UserPortrait } from '@shared';

export const UserCard = ({ resource: user }) => (
  <Media className="align-items-center">
    <Media left className="mr-3">
      <UserPortrait user={user} width="3em" />
    </Media>
    <Media body>
      <div>
        <strong>{[user?.firstName, user?.lastName].join(' ')}</strong>
      </div>
      <div className="small">
        <strong>Email:</strong> <span className="text-muted">{user?.email}</span> | <strong>Identificador:</strong>{' '}
        <span className="text-muted">{ChileanRUT.PartialFormat(user?.identifier?.value)}</span>
      </div>
    </Media>
  </Media>
);

const UserFinder = ({ inputProps, filters, building = false, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={building ? getBuildingUser : getUser}
    remoteFinder={(query) => (building ? getBuildingUsers : getUsers)({ search: query, ...filters })}
    dropdownTemplate={UserCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por RUT, nombre, apellido, email o telefono',
    }}
  />
);

export default UserFinder;
