import { FontAwesome } from '@shared';
import { UserFinder } from '@shared/forms';
import { SimpleContainerLayout } from '@shared/layouts';
import React, { Component } from 'react';

export class DeskLogin extends Component {
  finderInput = undefined;

  componentDidMount() {
    this.finderInput && this.finderInput.focus();
  }

  setCurrentClient = (value) => {
    const { startSession } = this.props;

    startSession &&
      startSession({
        type: 'Core::User',
        id: value,
      });
  };

  render() {
    return (
      <div className="container-fluid mt-4 mb-4">
        <SimpleContainerLayout
          title={
            <span>
              <FontAwesome name="desktop" className="text-primary mr-2" />
              Escritorio
            </span>
          }
        >
          <div className="mt-4 mb-4">
            <UserFinder
              inputProps={{
                size: 'lg',
                getRef: (input) => (this.finderInput = input),
              }}
              input={{ onChange: this.setCurrentClient }}
              building={true}
            />
            <div className="text-muted mt-2">
              Debes primero especificar un cliente involucrado en los movimientos de esta sesión.
            </div>
          </div>
        </SimpleContainerLayout>
      </div>
    );
  }
}
