import { getUser, updateUser } from '@core/building/users';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { FontAwesome, withLoader } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import React, { useState } from 'react';
import { NavLink as Link, withRouter } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { branch, compose, renderNothing } from 'recompose';
import { Memberships } from './components/Memberships';
import { RestrictedItems } from './components/RestrictedItems';
import { Summary } from './components/Summary';
import { UserAssets } from './components/UserAssets';
import { UserCard } from './components/UserCard';
import { connect, notificationsGenerators } from '@store';

const enhance = compose(
  connect(null, (dispatch) => ({
    updateNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Cambio de nombre',
          text: `La información del usuario fue modificada con éxito`,
          color: 'success',
          duration: 10000,
        }),
      ),
    errorNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Error al cambia nombre',
          text: `No pudo realizarse la modificación de la información del usuario debido a un error`,
          color: 'danger',
          duration: 10000,
        }),
      ),
  })),
  withRouter,
  withLoader(
    'userInfo',
    ({
      match: {
        params: { id },
      },
    }) => getUser(id),
    {
      onError: console.log,
    },
  ),
  branch(({ userInfo }) => !userInfo, renderNothing),
);

const UserPagePresentation = ({ match, userInfo, updateNotification, errorNotification }) => {
  const [user, setUser] = useState(userInfo);

  const submitUpdateUser = async (id, payload) => {
    const { body, status } = await updateUser(id, payload);

    if (status === 200) {
      setUser(body);
      updateNotification();
    } else if (status === 422) {
      console.log(body.errors);
      errorNotification(body.errors);
    }
  };

  return (
    <BuildingLayout>
      <Row>
        <Col>
          <h4 className="mb-4">
            <FontAwesome name="user" className="text-primary mr-2" />
            Gestión de usuarios
          </h4>
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={4} xl={3}>
          <UserCard user={user} onSubmit={submitUpdateUser} />
        </Col>
        <Col xs={12} md={8} xl={9}>
          <Card>
            <CardHeader>
              <Nav tabs className="card-header-tabs">
                <NavItem>
                  <NavLink role="button" exact tag={Link} to={`/building/users/${user.id}`}>
                    <FontAwesome name="list-alt" /> Resumen
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink role="button" exact tag={Link} to={`/building/users/${user.id}/assets`}>
                    <FontAwesome name="wrench" /> Activos
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink role="button" exact tag={Link} to={`/building/users/${user.id}/restricted_items`}>
                    <FontAwesome name="ban" /> Restricciones
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink role="button" exact tag={Link} to={`/building/users/${user.id}/memberships`}>
                    <FontAwesome name="key" /> Permisos
                  </NavLink>
                </NavItem>
              </Nav>
            </CardHeader>
            <CardBody>
              <Switch>
                <ProtectedRoute exact path={`${match.url}/`} render={(props) => <Summary user={user} {...props} />} />
                <ProtectedRoute
                  exact
                  path={`${match.url}/assets`}
                  render={(props) => <UserAssets user={user} {...props} />}
                />
                <ProtectedRoute
                  exact
                  path={`${match.url}/restricted_items`}
                  render={(props) => <RestrictedItems user={user} {...props} />}
                />
                <ProtectedRoute
                  exact
                  path={`${match.url}/memberships`}
                  render={(props) => <Memberships user={user} {...props} />}
                  acceptedMemberships={['organization_admin']}
                />
              </Switch>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </BuildingLayout>
  );
};

export const UserPage = enhance(UserPagePresentation);
