import { apiFetch, handleAuth, withToken, mountQuery } from './http';

export const getMachineTypes = ({ query } = {}) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/machine_types${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getMachineType = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/machine_types/${id}`,
      method: 'GET',
    }),
  );
