import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Dropzone from 'react-dropzone';

import { FontAwesome, Spinner } from '@shared';

import ImageCard from './ImageCard';

export const PictureUploaderForm = ({
  files,
  onDrop,
  height = '10rem',
  removeFile,
  multiple = true,
  rejectedFiles,
  dragging = true,
  showDraggingCard,
  hideDraggingCard,
  loading = false,
}) => {
  const color = (dragging && 'primary') || '';
  return (
    <div className="text-left">
      <div className="d-flex flex-wrap text-center align-items-center">
        {files.map((file, index) => (
          <ImageCard key={index} file={file} height={height} onDelete={() => removeFile(index)} />
        ))}

        {loading && (
          <Card
            className="active-area mb-3"
            style={{ width: '8rem', height }}
            role="button"
            inverse={dragging}
            color={color}
          >
            <CardBody className="d-flex justify-content-center align-items-center h1 text-info">
              <Spinner />
            </CardBody>
          </Card>
        )}

        {(files.length === 0 || multiple) && !loading && (
          <Card
            className="active-area mb-3"
            style={{ width: '8rem', height }}
            role="button"
            inverse={dragging}
            color={color}
          >
            <Dropzone
              accept="image/*"
              onDrop={onDrop}
              multiple={multiple}
              onDragStart={showDraggingCard}
              onDragEnter={showDraggingCard}
              onDragOver={showDraggingCard}
              onDragLeave={hideDraggingCard}
            >
              {({ getRootProps, getInputProps }) => {
                return (
                  <section className="h-100 d-flex align-items-center">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      {dragging ? (
                        <CardBody className="main-animation">
                          <h1>
                            <FontAwesome name="camera" />
                          </h1>
                          <div className="small">Soltar imagen aquí</div>
                        </CardBody>
                      ) : (
                        <CardBody>
                          <h1 className="text-primary">
                            <FontAwesome name="plus" />
                          </h1>
                          <h4 className="text-success">
                            <FontAwesome name="camera" />
                          </h4>
                          <div className="small text-muted">Click o arrastrar</div>
                        </CardBody>
                      )}
                    </div>
                  </section>
                );
              }}
            </Dropzone>
          </Card>
        )}
      </div>
      {rejectedFiles.length > 0 && (
        <ul className="text-danger small">
          {rejectedFiles.map((file, index) => (
            <li key={index}>
              <strong>{file.name}</strong> es un archivo inválido.
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
