import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import {
  ASSET_HEALTH_STATES_FOR_MANUAL_MOVEMENTS_CREATION,
  ASSET_STATES_FOR_MOVEMENTS_CREATION,
} from '@shared/constants';
import { AssetSelector, DateTimeSelector, FileUploader, ItemSelector, UserFinder } from '@shared/forms';
import { connect } from '@store';
import { get } from 'lodash';
import React from 'react';
import { Alert, Input, Label } from 'reactstrap';
import { compose, lifecycle, withHandlers, withState } from 'recompose';
import { formValues, reduxForm } from 'redux-form';

import { ConsumableSelector } from './ConsumableSelector';
import { TypeSelector } from './TypeSelector';
import CostAccountFinder from '@shared/forms/CostAccountFinder';

const excluded = [
  'geofence-in',
  'geofence-out',
  'requires_maintenance',
  'sent_to_repair',
  'start_maintenance',
  'maintenance_return',
  'reception_from_maintenance',
  'picking',
  'transfer',
  'reserve',
  'transfer_received',
  'transfer_sent',
];

const excludedConsumables = [...excluded, 'devolution'];

const validate = Validates(
  presence({ of: 'movementType' }),
  presence({ of: 'assetBarcode', if: ({ consumable }) => !consumable }),
  presence({ of: 'itemBarcode', if: ({ consumable }) => consumable }),
  presence({
    of: 'clientId',
    if: ({ clientType }) => !!clientType,
  }),
  presence({ of: 'quantity', if: ({ consumable }) => consumable }),
);

const enhance = compose(
  reduxForm({
    form: 'createMovement',
    validate,
    initialValues: {
      consumable: false,
    },
  }),
  formValues({
    movementType: 'movementType',
    consumable: 'consumable',
    clientType: 'clientType',
  }),
  withState('asset', 'setAsset', undefined),
  withState('item', 'setItem', undefined),
  withState('client', 'setClient', undefined),
  withState('costAccountId', 'setCostAccountId', undefined),
  withState('quantity', 'setQuantity', undefined),
  withState('authorization', 'setAuthorization', false),
  withHandlers({
    onAuthCheckChange: (props) => (event) => {
      props.setAuthorization(event.target.checked);
    },
  }),
  connect(({ appData: { movements }, session: { user, currentMembership } }) => ({
    movements,
    user,
    membership: user?.memberships.find((membership) => membership.id === currentMembership),
  })),
  lifecycle({
    componentDidMount() {
      const { getReset, reset } = this.props;

      getReset && reset && getReset(reset);
    },
    UNSAFE_componentWillReceiveProps({ movementType, nextClientType }) {
      const { autofill, clientType, movements } = this.props;

      if (movements[movementType] && movements[movementType].expects_client && !clientType && !nextClientType) {
        autofill('clientType', 'Core::User');
      }

      if (movements[movementType] && !movements[movementType].expects_client && clientType && nextClientType) {
        autofill('clientType', '');
      }
    },
  }),
);

const UploaderComponent = ({ onChange, value }) => (
  <FileUploader multiple={true} token={value} onChange={onChange} endpoint={(token) => `/attachments/${token}`} />
);

const MovementFormPresentation = ({
  // Redux form
  autofill,
  error,
  handleSubmit,
  invalid,
  submitting,
  pristine,
  // Form Values
  consumable,
  movementType,
  movements,
  user,
  onAuthCheckChange,
  authorization,
  // State
  membership: {
    building: { organization },
    role,
  },
  asset,
  setAsset,
  item,
  setItem,
  setAuthorization,
  handleTarget,
  client,
  setClient,
  reset,
  quantity,
  setQuantity,
  setCostAccountId,
}) => {
  const expectsClient = movementType && movements[movementType] && movements[movementType].expects_client;

  const showAlert = expectsClient && get(asset, 'item.restricted');
  const showRestrictionAlert =
    expectsClient &&
    client &&
    (asset
      ? get(client, 'restrictedItems')?.some(
          (restrictedItem) =>
            restrictedItem.item.id === asset.item.id &&
            (restrictedItem.quantity <= restrictedItem.delivered ||
              restrictedItem.quantity < restrictedItem.delivered + 1),
        )
      : item
      ? get(client, 'restrictedItems')?.some(
          (restrictedItem) =>
            restrictedItem.item.id === item.id &&
            (restrictedItem.quantity <= restrictedItem.delivered ||
              restrictedItem.quantity < restrictedItem.delivered + Number(quantity)),
        )
      : false);

  return (
    <Form error={error} onSubmit={handleSubmit}>
      <h5 className="mb-3">Identificación</h5>

      <div className="mb-3">
        Debes especificar el activo o artículo para el cual se está registrando el presente movimiento.
      </div>

      <Field
        name="consumable"
        component={ConsumableSelector}
        componentOnChange={(value) => {
          console.log(consumable, value);
          if (value !== consumable) {
            setAsset(undefined);
            setItem(undefined);
            setClient(undefined);
            setQuantity(undefined);
            setAuthorization(false);
            autofill('assetBarcode', '');
            autofill('itemBarcode', '');
            autofill('movementType', '');
            autofill('clientId', '');
          }
        }}
      />

      {!consumable && (
        <Field
          label="Activo"
          name="assetBarcode"
          component={AssetSelector}
          filters={{
            active: true,
            states: ASSET_STATES_FOR_MOVEMENTS_CREATION,
            health_states: ASSET_HEALTH_STATES_FOR_MANUAL_MOVEMENTS_CREATION,
          }}
          inputProps={{ placeholder: 'Buscar o escanear código QR' }}
          componentOnChange={(id, asset) => {
            setAsset(asset);
            handleTarget(asset.barcode1);
            if (!id) {
              setClient(undefined);
              setQuantity(undefined);
              setAuthorization(false);
              autofill('movementType', '');
              autofill('clientId', '');
            }
          }}
          required
          help="Debes especificar un activo para este movimiento."
        />
      )}

      {consumable && (
        <Field
          label="Artículo (solo consumibles)"
          name="itemBarcode"
          component={ItemSelector}
          filters={{ consumable: true, active: true }}
          componentOnChange={(id, item) => {
            setItem(item);
            handleTarget(item?.barcode1);
            if (!id && movementType) {
              setClient(undefined);
              setQuantity(undefined);
              setAuthorization(false);
              autofill('movementType', '');
              autofill('clientId', '');
            }
          }}
          inputProps={{ placeholder: 'Buscar o escanear código QR' }}
          required
          help="Debes especificar un artículo para este movimiento."
        />
      )}

      <hr />

      <h5 className="mb-3">Especificaciones</h5>

      {consumable && (
        <Field
          label="Cantidad"
          name="quantity"
          type="number"
          required
          min={1}
          onChange={(id, quantity) => {
            setQuantity(quantity);
          }}
        />
      )}

      <Field
        label="Tipo de movimiento"
        name="movementType"
        component={TypeSelector}
        asset={asset}
        item={item}
        consumable={consumable}
        excluded={excluded}
        excludedConsumables={
          role === 'organization_admin' ? excludedConsumables : ['devolution', 'adjust', ...excludedConsumables]
        }
        onChange={() => setAuthorization(false)}
        help={
          <span>
            El tipo de movimiento realizado sobre el activo o artículo. Dependiendo del tipo seleccionado, deberán
            completarse campos adicionales.{' '}
            <strong>
              Dependiendo del estado actual del activo, no todos los movimientos se encuentran permitidos.
            </strong>
          </span>
        }
        required
      />

      {(organization.name === 'Serviall' || organization.name === 'Finning') && consumable && movementType === 'entry' && (
        <span>
          <Field label="Número de reserva" name="extendedAttributes.reservationNumber" />
          <Field label="Número vale interno" name="extendedAttributes.valeNumber" />
          <Field label="Número de traspaso" name="extendedAttributes.transferNumber" />
        </span>
      )}

      {movementType && movements[movementType] && movements[movementType].expects_return_date && !consumable && (
        <Field name="expectedAt" label="Fecha de retorno" required component={DateTimeSelector} minDate={new Date()} />
      )}

      {expectsClient && (
        <span>
          <Field
            name="clientId"
            label="Cliente"
            component={UserFinder}
            building={true}
            editable={true}
            required
            help="Para este tipo de movimiento debe especificarse el usuario que lo está generando y/o solicitando."
            componentOnChange={(id, client) => {
              setClient(client);
            }}
          />
          {showAlert && (
            <Alert color="danger">
              <strong>¡{consumable ? 'Artículo' : 'Activo'} con restricciones!</strong>
              <p className="mt-2">
                Yo, <strong>{[user.firstName, user.lastName].join(' ')}</strong>, hice las validaciones necesarias y
                confirmo que el cliente seleccionado cuenta con la autorización necesaria para realizar este movimiento.
              </p>
              <Label check>
                <Input
                  type="checkbox"
                  name="auth-validated"
                  checked={authorization}
                  onChange={onAuthCheckChange}
                  className="mr-2"
                />
                Autorización verificada
              </Label>
            </Alert>
          )}
          {showRestrictionAlert && (
            <Alert color="danger">
              <strong>
                El artículo está Restringido para este cliente, si considera que hay un error, comunicarse con el
                administrador
              </strong>
            </Alert>
          )}
        </span>
      )}

      {expectsClient && <Field name="clientType" type="hidden" />}
      <hr />

      {consumable && organization?.hasCostAccounts && movementType === 'withdrawal' && (
        <Field
          name="extendedAttributes.costAccountId"
          label="Centro de Costo"
          component={CostAccountFinder}
          building={true}
          editable={true}
          help="Para este tipo de movimiento puede especificar el centro de costos al que desea cargarlo."
          componentOnChange={(id, costAccountId) => {
            setCostAccountId(costAccountId);
          }}
        />
      )}

      <h5 className="mb-3">Información adicional</h5>

      <Field
        name="comments"
        label="Comentarios"
        rows="6"
        type="textarea"
        help="Cualquier comentario que pueda aportar antecedentes adicionales respecto al movimiento realizado."
      />

      <Field name="attachmentsTokens" label="Archivos" component={UploaderComponent} />

      <hr />

      <Alert color="danger">
        <strong>¡Importante!</strong> Los movimientos <strong>no</strong> son editables, por lo cual es importante que
        verifiques correctamente toda la información antes de enviar el formulario.
      </Alert>

      <div className="text-center">
        <ProcessingButton
          type="submit"
          role="button"
          color="primary"
          outline
          width="15em"
          processing={submitting}
          disabled={invalid || pristine || (showAlert && !authorization) || showRestrictionAlert}
        >
          Ingresar movimiento
        </ProcessingButton>
      </div>
    </Form>
  );
};

export const MovementForm = enhance(MovementFormPresentation);
