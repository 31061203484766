import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

export const AssetValidityPeriodModal = ({ modal, toggle, gotToValidityPeriod }) => (
  <Modal isOpen={modal} toggle={toggle} size="md">
    <ModalHeader toggle={toggle}>Crear vigencias para el activo</ModalHeader>
    <ModalBody>
      <p className="text-danger">
        <strong>¡Sugerencia!:</strong> Como el activo es reparable se recomienda crear vigencias para las acciones que
        se requieran.
      </p>
    </ModalBody>
    <ModalFooter>
      <Button role="button" color="primary" onClick={gotToValidityPeriod}>
        Ir a vigencias
      </Button>
      <Button role="button" onClick={toggle}>
        Cerrar
      </Button>
    </ModalFooter>
  </Modal>
);
