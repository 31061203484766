import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getKits = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getKit = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${id}`,
      method: 'GET',
    }),
  );

export const createKit = ({ ...kit }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/kits',
      method: 'POST',
      body: {
        kit,
      },
    }),
  );

export const updateKit = ({ id, ...kit }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${id}`,
      method: 'PUT',
      body: {
        kit,
      },
    }),
  );

export const deleteKit = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${id}`,
      method: 'DELETE',
    }),
  );

export const borrowKit = (id, params) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/kits/${id}/borrow`,
      method: 'POST',
      body: {
        ...params,
      },
    }),
  );
