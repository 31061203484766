import React from 'react';
import { Media } from 'reactstrap';

import { getArea, getAreas } from '@core/building/areas';
import { RemoteSelector } from '@core/forms';
import { PictureDisplay } from '@shared';

const AreaCard = ({ resource: area }) => (
  <Media className="align-items-center">
    <Media left className="mr-3">
      <PictureDisplay picture={area.picture} pictureSize="icon" width="6rem" height="4rem" />
    </Media>
    <Media body>
      <div>
        <strong>{area.name}</strong>
      </div>
      <div className="text-muted">{area.location}</div>
    </Media>
  </Media>
);

const AreaSelector = ({ inputProps, filters, remoteLoader = getArea, remoteFinder = getAreas, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={remoteLoader}
    remoteFinder={(query) =>
      remoteFinder({
        search: query,
        ...filters,
      })
    }
    dropdownTemplate={AreaCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar área por nombre o ubicación...',
    }}
  />
);

export default AreaSelector;
