export const fileSize = function (size, unit = 'MB', decimals = 2) {
  let exponent = 2;

  switch (unit) {
    case 'MB':
    default:
      exponent = 2;
      break;
  }

  let number = Math.round((10 ** decimals * size) / 1024 ** exponent) / 10 ** decimals;

  return `${formatNumber(number)} ${unit}`;
};

export const formatNumber = function (number) {
  return number.toLocaleString();
};
