export const extractQueryParams = function (search) {
  const query = new URLSearchParams(search);

  let object = {};

  if (query.get('q')) {
    try {
      object = JSON.parse(atob(query.get('q')));
    } catch (SyntaxError) {
      object = {};
      console.warn('Invalid query.');
    }
  }

  return object;
};
