import { AssetSelector, ItemSelector, UserFinder, AreaSelector, BuildingSelector } from '@shared/forms';
import { DebugCard, FontAwesome } from '@shared';
import React, { useState } from 'react';
import { Field, Form } from '@core/forms';
import {
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Label,
  CardTitle,
} from 'reactstrap';
import { reduxForm } from 'redux-form';
import Validates, { emailFormat, presence } from '@core/forms/validations';
import { getAsset } from '@core/building/assets';
import { AssetDisplay, ItemDisplay } from '@shared/displays';
import { compose } from 'recompose';
import { connect, notificationsGenerators } from '@store';
import { Redirect } from 'react-router-dom';
import { getItem } from '@core/building/items';
import { createPickUps } from '@core/building/pick-ups';
import UserGroupFinder from '@shared/forms/UserGroupFinder';
import { getUser } from '@core/building/users';
import { getMembers } from '@core/building/members';
import { UserCard } from '@shared/forms/UserFinder';
import CostAccountFinder from '@shared/forms/CostAccountFinder';
import { getBuilding, getBuildingsOrganization } from '@core/building/buildings';
import { getAreaByBuilding, getAreasByBuilding } from '@core/building/areas';
import TopAccountFinder from '@shared/forms/TopAccountFinder';

const PickUpFormPresentation = ({ resetForm, successCreationNotification, errorNotification, currentOrganization }) => {
  const [reservations, setReservations] = useState([]);
  const [userId, setUserId] = useState(undefined);
  const [userSource, setUserSource] = useState('user');
  const [userGroupId, setUserGroupId] = useState(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // Agrega una nueva reserva
  const addReservation = async (userSourceId) => {
    if (userSource === 'user') {
      if (!!userSourceId) {
        const { body, status } = await getUser(userSourceId);
        if (status === 200 || status === 304) {
          if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === body?.id)) {
            alert(`El usuario ${body?.firstName} ${body?.lastName} ya se encuentra en la lista`);
          } else {
            setReservations([...reservations, { user: body, consumable: 'true', pickUpUnits: [] }]);
          }
          setUserId(undefined);
        } else {
          console.log(body.errors);
        }
      }
    } else if (userSource === 'group') {
      if (!!userSourceId) {
        const { body, status } = await getMembers(userSourceId, { serializer: 'show', paginate: false });
        if (status === 200 || status === 304) {
          if (body.length > 1) {
            const newUsers = body
              .filter((member) => {
                let condition = false;
                if (
                  reservations.length > 0 &&
                  reservations.some((reservation) => reservation?.user?.id === member?.user?.id)
                ) {
                  alert(`El usuario ${member?.user?.firstName} ${member?.user?.lastName} ya se encuentra en la lista`);
                  condition = false;
                } else {
                  condition = member.role !== 'admin';
                }
                return condition;
              })
              ?.map((member) => {
                return { user: member.user, consumable: 'true', pickUpUnits: [] };
              });
            setReservations([...reservations, ...newUsers]);
            setUserGroupId(undefined);
          }
        } else {
          console.log(body.errors);
        }
      }
    }
  };

  const setConsumable = (consumable, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === selected?.user?.id)) {
      setReservations(
        reservations.map((reservation) => {
          return reservation?.user?.id === selected?.user?.id ? { ...reservation, consumable } : reservation;
        }),
      );
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  const setCostAccountId = (costAccountId, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === selected?.user?.id)) {
      setReservations(
        reservations.map((reservation) => {
          return reservation?.user?.id === selected?.user?.id ? { ...reservation, costAccountId } : reservation;
        }),
      );
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  const setTopAccountId = (topAccountId, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === selected?.user?.id)) {
      setReservations(
        reservations.map((reservation) => {
          return reservation?.user?.id === selected?.user?.id ? { ...reservation, topAccountId } : reservation;
        }),
      );
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  const setBuildingTargetId = (buildingTargetId, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === selected?.user?.id)) {
      setReservations(
        reservations.map((reservation) => {
          return reservation?.user?.id === selected?.user?.id ? { ...reservation, buildingTargetId } : reservation;
        }),
      );
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  const setAreaTargetId = (areaTargetId, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === selected?.user?.id)) {
      setReservations(
        reservations.map((reservation) => {
          return reservation?.user?.id === selected?.user?.id ? { ...reservation, areaTargetId } : reservation;
        }),
      );
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  const setComments = (comments, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === selected?.user?.id)) {
      setReservations(
        reservations.map((reservation) => {
          return reservation?.user?.id === selected?.user?.id ? { ...reservation, comments } : reservation;
        }),
      );
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  const setExternalReserve = (externalReserve, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation?.user?.id === selected?.user?.id)) {
      setReservations(
        reservations.map((reservation) => {
          return reservation?.user?.id === selected?.user?.id ? { ...reservation, externalReserve } : reservation;
        }),
      );
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  // Agrega un nuevo recurso a la reserva
  const addSample = async (barcode, selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation.user.id === selected.user.id)) {
      if (selected.pickUpUnits.some((sample) => sample.barcode1 === barcode)) {
        alert('La muestra está repetida');
      } else {
        if (!!barcode) {
          const { body, status } = selected?.consumable === 'true' ? await getItem(barcode) : await getAsset(barcode);
          if (status === 200 || status === 304) {
            setReservations(
              reservations.map((reservation) =>
                reservation.user.id === selected.user.id
                  ? { ...reservation, pickUpUnits: [...reservation.pickUpUnits, { ...body, quantity: 1 }] }
                  : reservation,
              ),
            );
          } else {
            console.log(body.errors);
          }
        }
      }
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  const setQuantity = (quantity, selected, sample) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation.user.id === selected.user.id)) {
      if (quantity && selected && sample && selected?.consumable === 'true') {
        if (selected.pickUpUnits.some((pickUp) => pickUp.barcode1 === sample.barcode1)) {
          setReservations(
            reservations.map((reservation) =>
              reservation.user.id === selected.user.id
                ? {
                    ...reservation,
                    pickUpUnits: reservation.pickUpUnits.map((pickUp) =>
                      pickUp.barcode1 === sample.barcode1
                        ? { ...pickUp, quantity: quantity < 1 ? 1 : quantity }
                        : pickUp,
                    ),
                  }
                : reservation,
            ),
          );
        } else {
          alert(`No existe una muestra para el recurso ${sample?.barcode1}`);
        }
      }
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  // Elimina un Recurso de la reserva
  const removeSample = (selected, sample) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation.user.id === selected.user.id)) {
      if (selected && sample) {
        setReservations(
          reservations.map((reservation) =>
            reservation.user.id === selected.user.id
              ? {
                  ...reservation,
                  pickUpUnits: reservation.pickUpUnits.filter((pickUp) => pickUp.barcode1 !== sample.barcode1),
                }
              : reservation,
          ),
        );
      }
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  // Elimina un Recurso de la reserva
  const removeReservation = (selected) => {
    if (reservations.length > 0 && reservations.some((reservation) => reservation.user.id === selected.user.id)) {
      setReservations(reservations.filter((reservation) => reservation.user.id !== selected.user.id));
    } else {
      alert('No hay reservaciones en el formulario');
    }
  };

  // Limpia o resetea el formulario del reporte
  const clearReport = () => {
    setReservations([]);
    setUserId(undefined);
    setUserGroupId(undefined);
    // setPickUps([]);
    resetForm();
  };

  // Abrir pop up de confirmación
  const generateReport = () => {
    setModal(true);
  };

  const submitReport = async () => {
    setSubmitting(true);
    const data = reservations.map((reservation) => {
      const pickUp = {
        userId: reservation.user.id,
        pickUpUnits: reservation.pickUpUnits.map((sample) => {
          return { barcode: sample.barcode1, quantity: sample.quantity };
        }),
      };
      if (reservation?.costAccountId) pickUp['costAccountId'] = reservation.costAccountId;
      if (reservation?.topAccountId) pickUp['topAccountId'] = reservation.topAccountId;
      if (reservation?.externalReserve) pickUp['externalReserve'] = reservation.externalReserve;
      if (reservation?.comments) pickUp['comments'] = reservation.comments;
      if (reservation?.buildingTargetId && reservation?.areaTargetId) {
        pickUp['buildingTargetId'] = reservation.buildingTargetId;
        pickUp['areaTargetId'] = reservation.areaTargetId;
      }
      return { pickUp };
    });
    const { body, status } = await createPickUps(data);
    if (status === 201 || status === 200) {
      //     clearReport();
      setSubmitting(false);
      toggle();
      successCreationNotification();
      setRedirect(true);
    } else {
      const errors = body?.errors
        ? body?.errors?.base.join(', ')
        : body?.message
        ? body?.message
        : 'no existe mensaje de errors';
      setSubmitting(false);
      errorNotification(errors);
      toggle();
    }
  };

  if (redirect) {
    return <Redirect to="/building/pick-ups" />;
  }

  return (
    <Row>
      <Col>
        <Form>
          <Card>
            <CardHeader>
              <Row>
                <Col md={3}>
                  <Field
                    type="select"
                    name="userSource"
                    label="Usuarios o Grupos"
                    placeholder="Seleccione una fuente para los usuarios"
                    options={{
                      user: 'Usuarios',
                      group: 'Grupos de Usuarios',
                    }}
                    onChange={(e) => {
                      setUserSource(e.target.value);
                      setUserId(undefined);
                      setUserGroupId(undefined);
                    }}
                    value={userSource}
                  />
                </Col>
                <Col md={9}>
                  {userSource === 'group' && (
                    <Row>
                      <Col>
                        <Label for="userGroupId">Ingrese un Grupo de Usuario</Label>
                      </Col>
                    </Row>
                  )}
                  {userSource === 'group' && (
                    <Row>
                      <Col md={10}>
                        <UserGroupFinder
                          isSearchable
                          value={userGroupId}
                          name="userGroupId"
                          editable={true}
                          input={{ onChange: setUserGroupId }}
                          required
                        />
                      </Col>
                      <Col>
                        <Button
                          type="button"
                          role="button"
                          color="success"
                          onClick={() => addReservation(userGroupId)}
                          disabled={!userGroupId}
                        >
                          <FontAwesome name="plus" />
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {userSource === 'user' && (
                    <Row>
                      <Col>
                        <Label for="userId">Ingrese un Usuario</Label>
                      </Col>
                    </Row>
                  )}
                  {userSource === 'user' && (
                    <Row>
                      <Col md={10}>
                        <UserFinder
                          isSearchable
                          value={userId}
                          name="userId"
                          building={true}
                          editable={true}
                          input={{ onChange: setUserId }}
                          required
                        />
                      </Col>
                      <Col>
                        <Button
                          type="button"
                          role="button"
                          color="success"
                          onClick={() => addReservation(userId)}
                          disabled={!userId}
                        >
                          <FontAwesome name="plus" />
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <CardTitle tag={'h5'}>{'Reservas'}</CardTitle>
              <hr />
              {reservations?.length > 0 ? (
                <Card>
                  <CardBody style={{ minHeight: '10vh' }}>
                    {reservations?.map((reservation, index) => (
                      <Row
                        key={`Reservation-${index}`}
                        className="mb-3 "
                        style={{
                          backgroundColor: `${index % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'transparent'}`,
                          padding: '10px',
                          borderRadius: '4px',
                          minHeight: '10vh',
                          border: '1px solid rgba(0, 0, 0, 0.125)',
                        }}
                      >
                        <Col>
                          <Row>
                            <Col md={6}>
                              <UserCard resource={reservation?.user} />
                            </Col>
                            <Col md={4}>
                              <Field
                                type="select"
                                name={`consumable-${index}`}
                                label="Tipo de artículo"
                                options={{ true: 'Consumibles', false: 'Activos' }}
                                onChange={(e) => {
                                  setConsumable(e.target.value, reservation);
                                }}
                                value={reservation?.consumable}
                                disabled={reservation?.pickUpUnits?.length > 0}
                              />
                            </Col>
                            <Col sm={2} md={2} className="mb-3">
                              <Row>
                                <Col>
                                  <Label for="userId">Eliminar</Label>
                                </Col>
                              </Row>
                              <Button
                                type="button"
                                role="button"
                                color="danger"
                                onClick={() => removeReservation(reservation)}
                              >
                                <FontAwesome name="times" />
                              </Button>
                            </Col>
                          </Row>
                          <hr />
                          <Row>
                            {reservation?.consumable === 'true' && (
                              <Col className="pb-2">
                                <Label for={`targetInput-${index}`}>Seleccione los artículos</Label>
                                <ItemSelector
                                  name={`targetInput-${index}`}
                                  inputProps={{ size: 'lg' }}
                                  filters={{ consumable: 'true' }}
                                  input={{
                                    onChange: (e) => addSample(e, reservation),
                                  }}
                                />
                              </Col>
                            )}
                            {reservation?.consumable === 'false' && (
                              <Col className="pb-2">
                                <Label for={`targetInput-${index}`}>Seleccione los activos</Label>
                                <AssetSelector
                                  name={`targetInput-${index}`}
                                  inputProps={{ size: 'lg' }}
                                  filters={{
                                    active: true,
                                    states: 'stored',
                                    is_in_mro: false,
                                    health_states: 'new,repaired,unknown,with_observations',
                                  }}
                                  label="Seleccione un activo"
                                  input={{
                                    onChange: (e) => addSample(e, reservation),
                                  }}
                                />
                              </Col>
                            )}
                          </Row>
                          {reservation?.pickUpUnits?.length > 0 && (
                            <Card body>
                              {reservation?.pickUpUnits.length > 0 &&
                                reservation?.pickUpUnits.map((sample, index2) => (
                                  <Row
                                    key={`Sample-${index}-${index2}`}
                                    className="pt-3"
                                    style={{
                                      backgroundColor: `${index2 % 2 === 0 ? 'rgba(0, 0, 0, 0.03)' : 'transparent'}`,
                                      borderRadius: '4px',
                                    }}
                                  >
                                    <Col sm={12} md={reservation.consumable === 'true' ? 8 : 10} className="mb-3">
                                      {reservation?.consumable === 'true' ? (
                                        <ItemDisplay item={sample} />
                                      ) : (
                                        <AssetDisplay asset={sample} />
                                      )}
                                    </Col>
                                    {reservation.consumable === 'true' && (
                                      <Col sm={10} md={2} className="mb-3">
                                        <Label for={`quantity-${index}-${index2}`}>Cantidad</Label>
                                        <Field
                                          type="number"
                                          name={`quantity-${index}-${index2}`}
                                          input={{
                                            min: 1,
                                            onChange: (e) => setQuantity(e.target.value, reservation, sample),
                                            value: sample.quantity < 1 ? 1 : sample.quantity,
                                          }}
                                        />
                                      </Col>
                                    )}
                                    <Col sm={2} md={2} className="mb-3">
                                      <Row>
                                        <Col>
                                          <Label for="userId">Eliminar</Label>
                                        </Col>
                                      </Row>
                                      <Button
                                        type="button"
                                        role="button"
                                        color="danger"
                                        onClick={() => removeSample(reservation, sample)}
                                      >
                                        <FontAwesome name="times" />
                                      </Button>
                                    </Col>
                                  </Row>
                                ))}
                            </Card>
                          )}
                          <hr />
                          <Row className="mt-3">
                            <Col>
                              <Label>Nº reserva cliente</Label>
                              <Field
                                type="input"
                                name={`external_reserve-${index}`}
                                value={reservation?.externalReserve}
                                input={{ onChange: (e) => setExternalReserve(e.target.value, reservation) }}
                              />
                            </Col>
                          </Row>
                          {currentOrganization?.hasCostAccounts && reservation?.consumable === 'true' && (
                            <Row className="mt-3">
                              <Col>
                                <Label for="costAccountId">Seleccione una Cuenta Mayor</Label>
                                <TopAccountFinder
                                  isSearchable
                                  name={`topAccountId-${index}`}
                                  value={reservation?.topAccountId}
                                  editable={true}
                                  input={{ onChange: (e) => setTopAccountId(e, reservation) }}
                                  disabled={reservation?.pickUpUnits?.length > 0}
                                />
                              </Col>
                            </Row>
                          )}
                          {currentOrganization?.hasCostAccounts && reservation?.consumable === 'true' && (
                            <Row className="mt-3">
                              <Col>
                                <Label for="costAccountId">Seleccione un centro de costo</Label>
                                <CostAccountFinder
                                  isSearchable
                                  name={`costAccountId-${index}`}
                                  value={reservation?.costAccountId}
                                  editable={true}
                                  input={{ onChange: (e) => setCostAccountId(e, reservation) }}
                                  disabled={reservation?.pickUpUnits?.length > 0}
                                />
                              </Col>
                            </Row>
                          )}
                          {reservation?.consumable === 'true' && (
                            <Row className="mt-3">
                              <Col>
                                <Label>
                                  <b>Instalación de destino</b>
                                </Label>
                                <BuildingSelector
                                  isSearchable
                                  name={`buildingTargetId-${index}`}
                                  value={reservation?.buildingTargetId}
                                  remoteFinder={getBuildingsOrganization}
                                  remoteLoader={getBuilding}
                                  input={{ onChange: (e) => setBuildingTargetId(e, reservation) }}
                                  disabled={reservation?.pickUpUnits?.length > 0}
                                />
                                <div className="text-muted">
                                  Para realizar la transferencia se requiere que especifique una instalación diferente a
                                  la actual
                                </div>
                              </Col>

                              <Col>
                                {reservation?.buildingTargetId && (
                                  <>
                                    <Label>
                                      <b>Área de la instalación</b> (Obligatorio)
                                    </Label>
                                    <AreaSelector
                                      isSearchable
                                      name={`areaTargetId-${index}`}
                                      value={reservation?.setAreaTargetId}
                                      input={{ onChange: (e) => setAreaTargetId(e, reservation) }}
                                      remoteLoader={(search) =>
                                        getAreaByBuilding(reservation?.buildingTargetId, search)
                                      }
                                      remoteFinder={(search) =>
                                        getAreasByBuilding(reservation?.buildingTargetId, search)
                                      }
                                      disabled={reservation?.pickUpUnits?.length > 0}
                                    />
                                    <div className="form-text text-muted">
                                      Si la instalación está presente, se requiere que especifique un área de la
                                      instalación seleccionada.
                                    </div>
                                  </>
                                )}
                              </Col>
                            </Row>
                          )}
                          <Row className="mt-3">
                            <Col>
                              <Label>Comentarios</Label>
                              <Field
                                type="textarea"
                                name={`comments-${index}`}
                                value={reservation?.comments}
                                input={{ onChange: (e) => setComments(e.target.value, reservation) }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ))}
                  </CardBody>
                </Card>
              ) : (
                <div className="text-center mb-3 mt-3">
                  Seleccione un usuario o grupo de usuarios para iniciar las reservas
                </div>
              )}
            </CardBody>
            <CardFooter>
              <Button
                type="button"
                role="button"
                color="primary"
                className="mr-2"
                onClick={generateReport}
                disabled={
                  !reservations.length > 0 ||
                  reservations.filter((reservation) => reservation.pickUpUnits.length === 0)?.length > 0 ||
                  submitting
                }
              >
                <FontAwesome name="check" /> Generar Reserva
              </Button>
              <Button type="button" role="button" color="danger" onClick={clearReport} disabled={submitting}>
                <FontAwesome name="times" /> Borrar
              </Button>
            </CardFooter>
          </Card>
        </Form>
        {process.env.NODE_ENV === 'development' && (
          <Row className="mt-3">
            <Col>
              <DebugCard
                data={reservations.map((reservation) => {
                  return {
                    userId: reservation.user.id,
                    costAccountId: reservation.costAccountId,
                    pickUpUnits: reservation.pickUpUnits.map((sample) => {
                      return { barcode: sample.barcode1, quantity: sample.quantity };
                    }),
                    externalReserve: reservation.externalReserve,
                    topAccountId: reservation.topAccountId,
                    comments: reservation.comments,
                  };
                })}
              />
            </Col>
          </Row>
        )}
      </Col>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader>Confirmar reserva</ModalHeader>
        <ModalBody>¿Desea generar una reserva con los datos ingresados en el formulario?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={submitReport} disabled={submitting}>
            Generar
          </Button>{' '}
          <Button color="danger" onClick={toggle} disabled={submitting}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

const validate = Validates(presence({ of: 'stock' }), presence({ of: 'consumable' }), emailFormat({ of: 'newEmail' }));

export const PickUpForm = compose(
  reduxForm({
    form: 'InventoryCheckerForm',
    validate,
  }),
  connect(
    ({ session }) => ({
      /* TODO: Reselect in the future */
      currentOrganization: session?.user?.memberships.find((m) => m.id === session?.currentMembership)?.building
        ?.organization,
    }),
    (dispatch) => ({
      successCreationNotification: () =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Reserva creada',
            text: `La reserva ha sido creado exitosamente.`,
            color: 'success',
          }),
        ),
      errorNotification: (errors) =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Error',
            text: errors ? errors : 'Hubo un error al procesar la petición',
            color: 'danger',
          }),
        ),
      resetForm() {
        dispatch({
          type: '@@redux-form/RESET',
          meta: {
            form: 'InventoryCheckerForm',
          },
        });
      },
    }),
  ),
)(PickUpFormPresentation);
