export const pluralize = function (qty, singular, plural) {
  return qty === 1 ? singular : plural;
};

export const shouldBlockMovements = (asset) => {
  if (asset.item.active) {
    return false;
  }

  return ['stored', 'reserved'].includes(asset.state) && ['unknown', 'new', 'repaired'].includes(asset.healthState);
};

export const getReadoutIcon = (origin) => {
  if (!origin) {
    return 'map-marker-alt';
  }

  const type = origin.split(':')[0];
  switch (type) {
    case 'IMEI':
      return 'satellite';
    case 'EUI':
      return 'satellite-dish';
    case 'BEACON':
      return ['fab', 'bluetooth'];
    default:
      return 'map-marker-alt';
  }
};
