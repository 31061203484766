import React from 'react';
import { Media } from 'reactstrap';

import { RemoteSelector } from '@core/forms';
import { getUserGroup, getUserGroups } from '@core/building/user_groups';

export const UserGroupCard = ({ resource: userGroup }) => (
  <Media className="align-items-center">
    <Media body>
      <div>
        <strong>{userGroup.name}</strong>
      </div>
    </Media>
  </Media>
);

const UserGroupFinder = ({ inputProps, filters, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getUserGroup}
    remoteFinder={(query) => getUserGroups({ search: query, ...filters })}
    dropdownTemplate={UserGroupCard}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre del grupo',
    }}
  />
);

export default UserGroupFinder;
