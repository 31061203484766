import { camelCase, isArray, isPlainObject, map, mapKeys, mapValues, snakeCase } from 'lodash';

/**
 * Transforms the keys of the object and its children. Also apllies to arrays of objects.
 *
 * @export
 * @param {(object: any) => any} transformation The transformation function which will apply to the keys. Ideally receives a string and returns a string.
 * @param {*} object Object which keys will be deeply transformed.
 * @returns {*} Return the object with the deeply transformed keys.
 */
export function deepTransformKeys(transformation, object) {
  if (isArray(object)) {
    return map(object, (item) => deepTransformKeys(transformation, item));
  } else if (object === null || object === undefined) {
    return object;
  } else if (isPlainObject(object)) {
    let keysTransformed = mapKeys(object, (value, key) => transformation(key));

    return mapValues(keysTransformed, (value) => deepTransformKeys(transformation, value));
  } else {
    return object;
  }
}

/*
  Composed functions.
*/
export const deepSnakeKeys = (object) => deepTransformKeys(snakeCase, object);
export const deepCamelKeys = (object) => deepTransformKeys(camelCase, object);

export function flattenObject(object) {
  let flattened = {};

  for (let key of Object.keys(object)) {
    if (!isPlainObject(object[key])) flattened[key] = object[key];
    else flattened = { ...flattened, ...flattenObject(object[key]) };
  }

  return flattened;
}
