import { FontAwesome } from '@shared';

export const lowIsBetter = (warn, harm, read, margin) => {
  if (read >= harm) {
    return <FontAwesome name="exclamation-circle" style={{ color: '#a30000' }} />;
  } else if (read >= warn) {
    return <FontAwesome name="exclamation-triangle" style={{ color: '#ffc107' }} />;
  } else {
    return <FontAwesome name="check" style={{ color: '#499f68' }} />;
  }
};
export const highIsBetter = (warn, harm, read) => {
  if (read <= harm) {
    return <FontAwesome name="exclamation-circle" style={{ color: '#a30000' }} />;
  } else if (read <= warn) {
    return <FontAwesome name="exclamation-triangle" style={{ color: '#ffc107' }} />;
  } else {
    return <FontAwesome name="check" style={{ color: '#499f68' }} />;
  }
};
