import React from 'react';

import { SmartGrid } from '@shared';

type Props = {
  gridConfig: Object,
  created: Object[],
};

const MassImportCreatedTable = ({ created, gridConfig }: Props) => (
  <div>
    <h5 className="mb-3">Registros insertados exitosamente</h5>

    <SmartGrid {...gridConfig} data={created} />
  </div>
);

export default MassImportCreatedTable;
