function numberCheck({ value, lessThan, greaterThan, lessThanOrEqualTo, greaterThanOrEqualTo }) {
  let errors = [];

  if (greaterThan !== undefined && value <= greaterThan) errors.push(`no puede ser menor o igual que ${greaterThan}`);

  if (lessThan !== undefined && value >= lessThan) errors.push(`no puede ser mayor o igual que ${lessThan}`);

  if (greaterThanOrEqualTo !== undefined && value <= greaterThanOrEqualTo)
    errors.push(`no puede ser menor que ${greaterThanOrEqualTo}`);

  if (lessThanOrEqualTo !== undefined && value > lessThanOrEqualTo)
    errors.push(`no puede ser mayor que ${lessThanOrEqualTo}`);

  return errors;
}

export default numberCheck;
