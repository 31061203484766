import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { OrganizationRow } from './OrganizationRow';

export const OrganizationsResults = ({ organizations, pagination, setOrganizationStatus }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong>{' '}
      {pluralize(pagination.count, 'organización encontrada', 'organizaciones encontradas')}. Mostrando{' '}
      <strong>{pagination.per}</strong> organizaciones por página.
    </div>
    <Table>
      <colgroup>
        <col />
        <col style={{ width: '80px' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '15%' }} />
      </colgroup>
      <thead>
        <tr>
          <th className="text-center">#</th>
          <th>Nombre</th>
          <th>Contacto</th>
          <th className="text-center">¿Activo?</th>
          <th className="text-center">¿POD?</th>
          <th className="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {organizations.map((organization, index) => (
          <OrganizationRow key={index} organization={organization} setOrganizationStatus={setOrganizationStatus} />
        ))}

        {organizations.length === 0 && (
          <tr>
            <td colSpan={5} className="text-center">
              <div>No hay organizaciones creadas todavía.</div>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
