import { FontAwesome, ShowForRoles } from '@shared';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

export class KitSummary extends Component {
  render() {
    const { kit } = this.props;

    const availability = {
      true: { color: 'success', name: 'Disponible' },
      false: { color: 'danger', name: 'No disponible' },
    };

    const kind = {
      permanent: 'Permanente',
      temporary: 'Temporal',
    };
    return (
      <div>
        {kit && (
          <Row>
            <Col className="mb-4" sm={12} md={6}>
              <h5 className="mb-3">Información relevante</h5>
              <div className="d-flex justify-content-between mb-2">
                <span>Nombre</span>
                <span>{kit.name}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Identificador</span>
                <span>
                  <kbd>{kit.barcode1}</kbd>
                </span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Tipo</span>
                <span>{kind[kit.kind]}</span>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <span>Disponibilidad</span>
                <span>
                  <div className={`text-${availability[kit.available].color}`}>
                    <strong> {availability[kit.available].name} </strong>
                  </div>
                </span>
              </div>
            </Col>
            <Col sm={12} md={6}>
              <div className="mb-2">
                <ShowForRoles excluded={['guest']}>
                  <Button
                    role="button"
                    size="sm"
                    tag={Link}
                    to={`/building/kits/${kit.id}/borrow`}
                    color="primary"
                    disabled={!kit.available || kit?.kitResources?.some((resource) => resource?.asset?.expired)}
                    className="mb-3 mr-2"
                  >
                    <FontAwesome name="arrow-right" /> Entregar Kit
                  </Button>
                </ShowForRoles>
                <div>Recursos</div>
                {kit.kitResources.length > 0 ? (
                  kit.kitResources.map((kitResource, index) => (
                    <div key={index}>
                      {kitResource && (
                        <div className="text-overflow small mt-2">
                          <div className="text-muted mb-1">
                            <NavLink
                              to={`/${kitResource.item ? 'organization' : 'building'}/${
                                kitResource.item ? 'items' : 'assets'
                              }/${kitResource.item ? kitResource?.item?.barcode1 : kitResource?.asset?.barcode1}`}
                            >
                              <FontAwesome name="caret-right" className="mr-1" />
                              {kitResource.item
                                ? kitResource?.item?.barcode1
                                : kitResource?.asset
                                ? kitResource?.asset?.mark
                                  ? `${kitResource?.asset?.barcode1} - Rotulo: ${kitResource?.asset?.mark}`
                                  : `${kitResource?.asset?.barcode1}`
                                : undefined}
                              <FontAwesome
                                name={kitResource.available ? 'check-circle' : 'times-circle'}
                                className={kitResource.available ? 'text-success ml-1' : 'text-danger ml-1'}
                              />
                            </NavLink>
                          </div>
                          <div className="text-muted">
                            {kitResource.item
                              ? kitResource.item.name
                              : kitResource.asset
                              ? kitResource.asset.item.name
                              : undefined}
                          </div>
                          {kitResource?.item?.barcode1 && (
                            <div className="text-muted">Cantidad: {kitResource.quantity}</div>
                          )}
                          {kitResource?.asset?.expired && (
                            <NavLink
                              to={`/building/assets/${kitResource?.asset?.barcode1}/validity_periods`}
                              target="_blank"
                            >
                              <span color="danger">Existen periodos de vigencias vencidos </span>
                              <FontAwesome color="danger" name="external-link-alt" className="mr-1" />
                            </NavLink>
                          )}
                        </div>
                      )}
                    </div>
                  ))
                ) : (
                  <em className="text-muted">Sin recursos asociados</em>
                )}
              </div>
            </Col>
          </Row>
        )}
      </div>
    );
  }
}
