import { FontAwesome } from '@shared/';
import React from 'react';
import { Media } from 'reactstrap';
import { EventAssetModal } from './EventAsset';

export const EventTask = ({ task }) => (
  <Media>
    {task && (
      <Media body>
        {task.title && (
          <div className="w-100 text-overflow mt-1 align-middle small">
            <FontAwesome name="tasks" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{task.title}</span>
          </div>
        )}
        {task.createdBy && (
          <div className="w-100 text-overflow mt-1 align-middle small">
            <FontAwesome name="user" color="info" className="mr-1" />
            <span className="text-muted">{`${task.createdBy.firstName} ${task.createdBy.lastName}`}</span>
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventTaskModal = ({ task }) => (
  <div>
    <Media>
      {task && (
        <Media body>
          {task.title && (
            <div className="w-100 text-overflow mt-1">
              <FontAwesome name="tasks" color="info" className="mr-1" />
              <span className="asset-name font-weight-bold">{task.title}</span>
            </div>
          )}
          {task.createdBy && (
            <div className="w-100 text-overflow mt-1">
              <span className="text-muted mr-1">
                <FontAwesome name="user" color="info" className="mr-1" />
                Autor:
              </span>
              <span className="text-muted">{`${task.createdBy.firstName} ${task.createdBy.lastName}`}</span>
            </div>
          )}
          {task.description && (
            <div className="mt-1 mb-3">
              <span className="text-muted mr-1">
                <FontAwesome name="caret-right" color="info" className="mr-1" />
                Descripción:
              </span>
              <span className="text-muted">{task.description}</span>
            </div>
          )}
          <EventAssetModal asset={task.asset} />
          {task.subtasks && task.subtasks.length > 0 && (
            <div className="mt-3">
              <span className="text-muted mr-1">
                <FontAwesome name="caret-right" color="info" className="mr-1" />
                Sub-tareas:
              </span>
              {task.subtasks.map((subtask, index) => {
                return (
                  <div key={index}>
                    <FontAwesome name={subtask.completedAt ? 'check-square' : 'square'} color="info" className="mr-1" />
                    <span className="asset-name font-weight-bold">{subtask.title}</span>
                  </div>
                );
              })}
            </div>
          )}
        </Media>
      )}
    </Media>
  </div>
);
