import { DebugCard } from '@shared';
import { forIn, get, isArray, isEqual, isNil } from 'lodash';
import React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { extractQueryParams } from './extractQueryParams';
import { parseQuery } from './parseQuery';

export const mountQuery = (filters) => {
  return encodeURIComponent(btoa(JSON.stringify(filters)));
};

export const mountUrl = (filters) => {
  return '?q=' + mountQuery(filters);
};

export const NO_OPTIONS = 'Sin opciones... Modifique el criterio de búsqueda';
export const LOADING_MESSAGE = 'Buscando...';

export class SimpleFilter extends React.Component {
  initialState = {
    filters: {},
    items: undefined,
    pagination: undefined,
    page: undefined,
    modalOpen: false,
    order: {
      attribute: undefined,
      direction: undefined,
      icon: '',
    },
    extras: undefined,
  };

  state = this.initialState;

  async componentDidMount() {
    // 1. Parse URL
    const {
      location: { search },
    } = this.props;

    const {
      device: { barcode1 },
    } = this.props?.device ? this.props : { device: { barcode1: undefined } };

    const { page, ...filters } = extractQueryParams(search);

    // Update state accordingly:
    this.setState({
      filters,
      pagination: { page },
      page,
      barcode1,
    });

    // 2. Load items based on set filters
    await this.getItems(filters, page, barcode1);
  }

  toggle = () => {
    this.setState((prevState) => ({
      modalOpen: !prevState.modalOpen,
    }));
  };

  updateFilter = (data) => {
    this.setState({
      filters: data,
    });
  };

  updateOrder = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      order: {
        ...data,
      },
    }));
  };

  hookPreResponseHandler = (response) => {};

  hookPostResponseHandler = (response) => {};

  getItems = async (filters = {}, page = undefined, id = undefined) => {
    const { searchFunction } = this;

    this.setState({ items: undefined });

    const query = {
      ...calculateFinalFilters(filters),
      p: page,
    };

    const response = await searchFunction({ id, ...parseQuery(query) });

    this.hookPreResponseHandler(response);

    const { body: items, pagination, status, extras } = response;

    if (status === 200)
      this.setState({ items, pagination, extras }, () => {
        this.hookPostResponseHandler(response);
      });
  };

  handleFilter = async (filters = {}, force = false, id = undefined) => {
    if (isEqual(filters, this.state.filters || {}) && !force) {
      return;
    }

    this.setState({
      filters,
    });

    this.setUrl(filters, 1);
    await this.getItems(filters, 1, id);
  };

  setPage = (page, id = undefined) => {
    const { filters, pagination } = this.state;

    if (pagination && page === pagination.page) return;

    this.setState({
      pagination: {
        ...pagination,
        page,
      },
    });

    this.setUrl(filters, page);
    this.getItems(filters, page, id);
  };

  setUrl = (filters, page) => {
    const query = {
      ...filters,
      page,
    };

    // Just update the URL. We won't be re-rendering.
    window.history.pushState({}, {}, `${mountUrl(query)}`);
  };

  removeFilters = () => {
    this.handleFilter();
  };
}

export const calculateFinalFilters = (filters) => {
  let final = {};

  forIn(filters, (value, key) => {
    if (isNil(value)) {
      return;
    }

    let finalValue;

    if (isArray(value)) {
      if (value.length === 0) {
        return;
      }

      finalValue = value;
    } else {
      finalValue = get(value, 'value', value);
    }

    final[key] = finalValue;
  });

  return final;
};

export class SimpleFilterModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  size = 'lg';

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.initialFilters !== this.props.initialFilters) {
      this.setInitialValues(this.props.initialFilters);
    }
  }

  onChange = (value, action) => {
    this.setState({
      [action.name]: value,
    });
  };

  onEnter = (data) => {
    this.setInitialValues(this.props.initialFilters);
  };

  setInitialValues = (data) => {
    this.setState({
      ...data,
    });
  };

  applyFilter = () => {
    this.props.handleFilter(this.state);
    this.props.toggle();
  };

  renderForm() {
    throw new Error('You have to implement the method renderForm!');
  }

  render() {
    const { isOpen, toggle, className } = this.props;
    return (
      <Modal
        onEnter={this.onEnter}
        isOpen={isOpen}
        toggle={toggle}
        className={className}
        size={this.size}
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>Filtros</ModalHeader>
        <ModalBody>
          {this.renderForm()}
          {process.env.NODE_ENV === 'development' && (
            <Row>
              <Col>
                <DebugCard data={calculateFinalFilters(this.state)} />
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={this.applyFilter}>
            Filtrar
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}
