import { massImportDeviceExpectedStocks } from '@core/building/device-expected-stocks';
import Validates, { presence } from '@core/forms/validations';
import GenericMassImport from '@shared/mass-import';
import React from 'react';
import { MassImportInstructions } from './components/MassImportInstructions';

const columnMetadata = [
  {
    columnName: 'item_id',
    displayName: 'Item ID',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'item_id' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'device_id',
    displayName: 'Device ID',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'device_id' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'minimal_stock',
    displayName: 'Stock Mínimo',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'minimal_stock' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'optimal_stock',
    displayName: 'Stock Óptimo',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'optimal_stock' })),
    style: {
      width: '20%',
    },
  },
  {
    columnName: 'max_stock',
    displayName: 'Stock Máximo',
    editable: { type: 'text' },
    validate: Validates(presence({ of: 'max_stock' })),
    style: {
      width: '20%',
    },
  },
];

const tableConfig = {
  columnMetadata: columnMetadata,
  trackBy: '_id',
};

const headerMap = {
  item_id: ({ item_id }) => item_id,
  // building_id: ({ building_id }) => building_id,
  device_id: ({ device_id }) => device_id,
  minimal_stock: ({ minimal_stock }) => minimal_stock,
  optimal_stock: ({ optimal_stock }) => optimal_stock,
  max_stock: ({ max_stock }) => max_stock,
};

export const DeviceExpectedStocksMassImport = () => (
  <GenericMassImport
    entity="Stock de dispositivos"
    instructions={MassImportInstructions}
    valueMapping={headerMap}
    request={massImportDeviceExpectedStocks}
    tableConfig={tableConfig}
  />
);
