import React from 'react';
import type { ElementRef } from 'react';
import { compose, withStateHandlers, withHandlers } from 'recompose';
import type { HOC } from 'recompose';
import { isNil } from 'lodash';
import Papa from 'papaparse';

import { connect, notificationsGenerators } from '@store';

import HeaderRow from './HeaderRow';
import { PreviewRow } from './PreviewRow';
import { ResultsRow } from './ResultsRow';

const initialState = {
  created: [],
  errors: [],
  failed: [],
  file: undefined,
  finished: false,
  processing: false,
  queried: false,
  showInstructions: false,
};

type Props = {
  entity: string,
  request: (file: File) => Response,
  tableConfig: Object,
  valueMapping: { [key: string]: Function },
};

const parseCSV = (file: File, callback: (data: Object[]) => any) => {
  Papa.parse(file, {
    header: true,
    complete: (results, file) => {
      const { data } = results;

      data.forEach((item, index) => {
        item['_id'] = index;
      });

      callback(data);
    },
  });
};

const enhance: HOC<*, Props> = compose(
  connect(null, (dispatch) => ({
    successNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Importación exitosa',
          text: 'La importación ha sido realizada exitosamente. Más abajo se muestran los registros creados.',
          color: 'success',
        }),
      ),
    failureNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Importación fallida',
          text:
            'La importación ha fallado debido a un error encontrado en una fila del archivo. Por favor revisa esta fila e intenta nuevamente. Si los problemas persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      ),
    invalidFileNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Archivo inválido',
          text:
            'El tipo de archivo enviado no pudo ser procesado debido a que tiene un formato inválido. Por favor contacta a un administrador si los problemas persisten.',
          color: 'warning',
        }),
      ),
  })),
  withStateHandlers(
    ({ tableConfig }) => ({
      ...initialState,
      gridConfig: tableConfig,
    }),
    {
      toogleInstructions: ({ showInstructions }, _) => () => ({
        showInstructions: !showInstructions,
      }),

      setFile: ({ gridConfig: config }, __) => (file) => ({
        ...initialState,
        gridConfig: {
          ...config,
          currentPage: 1,
          data: [],
          sortColumn: '',
          sortDirection: '',
          selectedRows: {},
        },
        file: file,
      }),

      updateState: () => (state) => state,

      updateGrid: ({ gridConfig: config }, _) => (changes: any) => {
        const gridConfig = {
          ...config,
          ...changes,
        };

        return { gridConfig };
      },

      reset: () => (fileInput: ElementRef<'input'>) => {
        fileInput.current.value = '';

        return initialState;
      },
    },
  ),
  withHandlers({
    handleSetFile: ({ setFile, updateGrid }) => (e) => {
      const file = e && e.target && e.target.files && e.target.files.length > 0 && e.target.files[0];

      setFile(file);

      parseCSV(file, (data: Object[]) => {
        updateGrid({ data });
      });
    },
    onEditRow: ({ gridConfig: { data }, updateGrid }) => (trackBy: string, updatedRow: Object) => {
      const index = data.findIndex((item) => item[trackBy] === updatedRow[trackBy]);

      if (index >= 0) {
        data[index] = updatedRow;
        updateGrid({ data });
      }
    },
    onSubmit: ({
      request,
      file,
      gridConfig: { selectedRows },
      updateState,
      successNotification,
      invalidFileNotification,
      failureNotification,
      valueMapping,
    }) => async (e: Event) => {
      e.preventDefault();

      updateState({ finished: false, processing: true });

      const fields = Object.keys(valueMapping);

      const rows = Object.values(selectedRows).map((item: any, index) => {
        const row = [];

        fields.forEach((field) => {
          row.push(valueMapping[field](item.data));
        });

        return row;
      });

      var csvText = Papa.unparse({
        fields: fields,
        data: rows,
      });

      const parsedFile = new File([csvText], file.name, {
        type: 'text/plain;charset=utf-8',
      });

      const { body, status } = await request(parsedFile);

      if (status === 201) {
        updateState({
          processing: false,
          queried: true,
          finished: true,
          created: body,
        });

        successNotification();
      } else if (status === 400) {
        updateState({
          processing: false,
        });

        invalidFileNotification();
      } else if (status === 422) {
        updateState({
          processing: false,
          queried: true,
          // TODO: Extend to multiple errors
          errors: Array.isArray(body) && body[0] && body[0].errors,
          failed: [Array.isArray(body) && body[0] && body[0].data],
        });

        failureNotification();
      }
    },
  }),
);

const MassImport = ({
  // props
  entity,
  instructions,
  // state
  created,
  errors,
  failed,
  file,
  finished,
  gridConfig,
  processing,
  queried,
  showInstructions,
  // state handlers
  handleSetFile,
  onEditRow,
  onSubmit,
  reset,
  toogleInstructions,
  updateGrid,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { columnMetadata, data, ...rest } = gridConfig;

  const resultsConfig = {
    columnMetadata: columnMetadata.filter((column) => isNil(column.showInResults) || column.showInResults !== false),
    data,
    showCheckbox: false,
    editable: false,
  };

  return (
    <div className="container-fluid mt-4 mb-4">
      <HeaderRow
        entity={entity}
        file={file}
        finished={finished}
        instructions={instructions}
        onSubmit={onSubmit}
        processing={processing}
        queried={queried}
        reset={reset}
        selected={gridConfig.selectedRows}
        setFile={handleSetFile}
      />
      {file && !queried && gridConfig.data && gridConfig.data.length > 0 && (
        <PreviewRow
          gridConfig={{
            ...gridConfig,
            onChangeGrid: updateGrid,
            onEditRow: onEditRow,
          }}
        />
      )}
      {queried && <ResultsRow created={created} errors={errors} failed={failed} gridConfig={resultsConfig} />}
    </div>
  );
};

export default enhance(MassImport);
