import { createUser } from '@core/admin/users';
import { SubmissionError } from '@core/forms';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { UserForm } from './components/UserForm';

class CreateUserPresentation extends Component {
  state = {
    success: false,
    id: undefined,
  };

  createUser = async (formData) => {
    const { body, status } = await createUser(formData);

    if (status === 201) {
      this.props.createNotification(formData);

      this.setState({
        id: body.id,
        success: true,
      });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { success, id } = this.state;

    if (success) return <Redirect to={`/admin/users/${id}/memberships`} />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4">
        <UserForm showPasswordFields={false} onSubmit={this.createUser} back={this.props.history.goBack} />
      </SimpleContainerLayout>
    );
  }
}

export const CreateUser = connect(null, (dispatch) => ({
  createNotification: (formData) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Usuario creado',
        text: `La cuenta de ${formData.firstName} ${formData.lastName} ha sido creada exitosamente. Un correo será enviado para confirmar su cuenta y definir su contraseña.`,
        color: 'success',
        duration: 10000,
      }),
    ),
}))(CreateUserPresentation);
