import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const createMovementSet = (movementSet) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/movements/sets',
      method: 'POST',
      body: { movementSet },
    }),
  );

export const getMovementSets = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/movements/sets${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getMovementSet = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/movements/sets/${id}`,
      method: 'GET',
    }),
  );

export const createPicking = (body) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: '/inventory/picking',
      method: 'POST',
      body,
    }),
  );
