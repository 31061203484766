import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { UserGroupRow } from './UserGroupRow';

export const UserGroupsResults = ({ userGroups = [], pagination, searchFunction }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong> {pluralize(pagination.count, 'grupo encontrada', 'grupos encontradas')}.
      Mostrando <strong>{pagination.per}</strong> grupos por página.
    </div>

    <Table hover size="sm">
      <colgroup>
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
        <col style={{ width: 'auto' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Creador</th>
          <th className="text-center">Estado</th>
          <th className="text-center">Info</th>
          <th className="text-center">Acciones</th>
        </tr>
      </thead>
      <tbody>
        {userGroups.map((userGroup, index) => (
          <UserGroupRow key={`userGroups-${index}`} userGroup={userGroup} searchFunction={searchFunction} />
        ))}

        {userGroups.length === 0 && (
          <tr>
            <td colSpan="5" className="text-center mt-3 mb-3">
              <em>No se encontraron grupos de usuarios.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
