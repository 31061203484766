import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const upsertSubscription = (payload) =>
  handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/srv-notifications',
      endpoint: `/subscriptions`,
      method: 'POST',
      body: payload,
      parseResponse: false,
      parseBody: false,
    }),
  );

export const getSubscriptionByTopic = (topic) => {
  return handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/srv-notifications',
      endpoint: `/subscriptions/topic${mountQuery({ topic })}`,
      method: 'GET',
      parseResponse: false,
      parseBody: false,
    }),
  );
};

export const getNotifications = (building, excludeReaded) => {
  return handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/srv-notifications',
      endpoint: `/notifications${mountQuery({ page: 1, limit: 20, building: building, excludeReaded: excludeReaded })}`,
      method: 'GET',
      parseResponse: false,
      parseBody: false,
    }),
  );
};

export const markAsRead = (id) => {
  return handleAuth(
    withMembership(
      apiFetch,
      false,
    )({
      base: '/srv-notifications',
      endpoint: `/notifications/${id}/read`,
      method: 'POST',
      parseResponse: false,
      parseBody: false,
    }),
  );
};
