import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withState } from 'recompose';
import ValidityPeriodRow from '@shared/validity-period/ValidityPeriodRow';
import { getValidityPeriods } from '@core/building/validity-period';
import { Spinner, FontAwesome, ShowForRoles } from '@shared';

const enhance = compose(
  withState('validityPeriods', 'setValidityPeriods', undefined),
  lifecycle({
    async componentDidMount() {
      const { asset, setValidityPeriods } = this.props;

      const { body, status } = await getValidityPeriods(asset.barcode1);
      if (status === 200) {
        setValidityPeriods(body);
      }
    },
  }),
  branch(
    ({ validityPeriods }) => !validityPeriods,
    renderComponent(() => (
      <div className="mt-4 mb-4 text-info text-center h2">
        <Spinner />
      </div>
    )),
  ),
);

class AssetValidityPeriods extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      editing: false,
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const { asset, setValidityPeriods } = this.props;
    if (prevState?.editing) {
      const { body, status } = await getValidityPeriods(asset?.barcode1);
      if (status === 200) {
        await setValidityPeriods(body);
      }
    }
  }

  onDelete = (index) => {
    const { setValidityPeriods, validityPeriods } = this.props;
    const periods = validityPeriods.filter((item, i) => index !== i);
    setValidityPeriods(periods);
  };

  addNew = () => {
    const { setValidityPeriods, validityPeriods } = this.props;
    setValidityPeriods([
      {
        key: `n_${validityPeriods.length + 1}-${Date.now()}`,
        title: '',
        from: null,
        to: null,
      },
      ...validityPeriods,
    ]);
    this.toggleEditing();
  };

  toggleEditing = () => this.setState({ editing: !this.state.editing });

  render() {
    const { asset, validityPeriods } = this.props;

    return (
      <div>
        <ShowForRoles excluded={['guest']}>
          <div className="float-right">
            <Button size="sm" outline color="success" role="button" onClick={this.addNew} disabled={this.state.editing}>
              <FontAwesome name="plus" /> Agregar Periodo
            </Button>
          </div>
        </ShowForRoles>
        <h4 className="mb-4">Periodos de Vigencia</h4>
        <div>
          {validityPeriods.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center">
                <em>No hay Periodos de Vigencia creados o disponibles de momento.</em>
              </td>
            </tr>
          )}
          {validityPeriods.length > 0 &&
            validityPeriods.map((period, index) => (
              <ValidityPeriodRow
                index={index}
                key={period.id || period.key}
                validityPeriod={period}
                assetBarcode={asset.barcode1}
                onDelete={this.onDelete}
                toggleEditing={this.toggleEditing}
                editing={this.state.editing}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default enhance(AssetValidityPeriods);
