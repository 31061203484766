import unwrap from './unwrap';

function format({ value, with: regex, message }) {
  if (typeof value !== 'string')
    return {
      valid: false,
      message: 'No tiene formato de texto.',
    };

  regex.lastIndex = 0;

  if (!regex.test(value))
    return {
      valid: false,
      message: message || 'No tiene un formato válido.',
    };

  return {
    valid: true,
  };
}

export default unwrap(format);
