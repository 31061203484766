import React, { Component } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';

import { connect, notificationsGenerators } from '@store';

import './Notifications.scss';

class NotificationWidget extends Component {
  componentDidMount() {
    const { deleteAction, duration } = this.props;

    setTimeout(deleteAction, duration || 5000);
  }

  render() {
    const { color, title, children, deleteAction } = this.props;

    return (
      <Card inverse color={color}>
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <button type="button" className="close" onClick={deleteAction}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div>{children}</div>
        </CardBody>
      </Card>
    );
  }
}

export const NotificationsPanel = ({ notifications, remove: deleteNotification }) => (
  <div className="notification">
    {notifications.map((notification, index) => (
      <NotificationWidget
        key={index}
        index={index}
        color={notification.color}
        title={notification.title}
        deleteAction={() => deleteNotification(notification.id)}
      >
        {notification.text}
      </NotificationWidget>
    ))}
  </div>
);

export default connect(
  ({ notifications }) => ({ notifications: notifications.notifications }),
  (dispatch) => ({
    remove: (id) => dispatch(notificationsGenerators.remove(id)),
  }),
)(NotificationsPanel);
