import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, PictureDisplay } from '@shared';
import { getReadoutIcon } from '@shared/helpers';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

export const DeviceFeature = ({ feature, onClick, active, tooltip = true, link = true }) => {
  const {
    properties,
    geometry: { coordinates },
  } = feature;

  return (
    <div id={properties.id} className={`group-item mb-2 ${active ? 'active' : ''}`} onClick={onClick || (() => null)}>
      <div>
        <PictureDisplay
          className="asset-img"
          pictureSize="icon"
          picture={properties.default_picture}
          width="65px"
          height="65px"
        />
      </div>
      <div className="ml-2 readout-content">
        <span className="d-flex justify-content-between">
          <small className="asset-name" id={`device-name-${properties.id}`}>
            {properties.name}
          </small>
          <UncontrolledTooltip placement="top" target={`device-name-${properties.id}`}>
            {properties.name}
          </UncontrolledTooltip>
        </span>

        <span className="d-flex justify-content-between mb-1">
          {link && (
            <NavLink className="d-flex" to={active ? `/building/devices/${properties.barcode1}` : '#'}>
              <kbd>{properties.barcode1}</kbd>
            </NavLink>
          )}
          {!link && (
            <a className="d-flex" href={`/building/devices/${properties.barcode1}`} target="_blank" rel="noreferrer">
              <kbd>{properties.barcode1}</kbd>
            </a>
          )}
          <small>
            <FontAwesome name="calendar-alt" className="text-info" />
            {properties.read_at ? fromIso8601(properties.read_at) : '-'}
          </small>
        </span>
        <small className="overflow-ellipsis" id={`marker-${properties.id}`}>
          <FontAwesome name={getReadoutIcon(properties.origin)} className="text-info" />{' '}
          {properties.address || <span>{`Lat: ${coordinates[1] || '-'} - Lng: ${coordinates[0] || '-'}`}</span>}
          {tooltip && properties.address && (
            <UncontrolledTooltip placement="bottom" target={`marker-${properties.id}`}>
              <div className="small text-overflow" style={{ maxWidth: '23em' }}>
                Lat: <span className="text-info">{coordinates[1] || '-'}</span> - Lng:{' '}
                <span className="text-info">{coordinates[0] || '-'}</span>
              </div>
            </UncontrolledTooltip>
          )}
        </small>
      </div>
    </div>
  );
};
