import React from 'react';
import { Card, CardBody, CardText, CardTitle } from 'reactstrap';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ errors, keyNames }) => {
  let errorList = Array.isArray(errors) ? { base: errors } : errors;

  const keys = Object.keys(errorList || {});
  const names = keyNames || {};

  if (keys.length === 0) return null;

  return (
    <div>
      <Card className="card-outline-danger text-danger">
        <CardBody>
          <CardTitle tag="h5">Se encontraron errores</CardTitle>
          <CardText>Por favor, corrige los siguientes errores antes de continuar:</CardText>
          <ul>
            {keys.map((key, i) =>
              errorList[key].map((error, j) => (
                <li key={`${i}-${j}`}>
                  {key !== 'base' ? names[key] || key : ''} {error}.
                </li>
              )),
            )}
          </ul>
        </CardBody>
      </Card>
      <br />
    </div>
  );
};
