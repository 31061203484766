export const DispatcherActions = {
  AddCompleted: 'DispatcherActions.AddCompleted',
  AddSelected: 'DispatcherActions.AddSelected',
  FinishSession: 'DispatcherActions.FinishSession',
  RemoveSelected: 'DispatcherActions.RemoveSelected',
  SetAssets: 'DispatcherActions.SetAssets',
  ToggleDispatching: 'DispatcherActions.ToggleDispatching',
};

export const dispatcherInitialState = {
  assets: [],
  completed: [],
  dispatching: false,
  selected: [],
};

export const dispatcher = (state = dispatcherInitialState, action) => {
  switch (action.type) {
    case DispatcherActions.AddCompleted:
      return {
        ...state,
        completed: [...state.completed, action.payload],
      };
    case DispatcherActions.AddSelected:
      return {
        ...state,
        selected: [...state.selected, action.payload],
      };
    case DispatcherActions.FinishSession:
      return dispatcherInitialState;
    case DispatcherActions.RemoveSelected:
      return {
        ...state,
        selected: [...state.selected.slice(0, action.payload), ...state.selected.slice(action.payload + 1)],
      };
    case DispatcherActions.SetAssets:
      return {
        ...state,
        assets: action.payload,
      };
    case DispatcherActions.ToggleDispatching:
      return {
        ...state,
        dispatching: !state.dispatching,
      };
    default:
      return state;
  }
};

export const dispatcherGenerators = {
  addCompleted(completed) {
    return {
      type: DispatcherActions.AddCompleted,
      payload: completed,
    };
  },
  addSelected(selected) {
    return {
      type: DispatcherActions.AddSelected,
      payload: selected,
    };
  },
  finishSession() {
    return {
      type: DispatcherActions.FinishSession,
    };
  },
  removeSelected(index) {
    return {
      type: DispatcherActions.RemoveSelected,
      payload: index,
    };
  },
  setAssets(asset) {
    return {
      type: DispatcherActions.SetAssets,
      payload: asset,
    };
  },
  toggleDispatching() {
    return {
      type: DispatcherActions.ToggleDispatching,
    };
  },
};
