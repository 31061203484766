import { SimpleContainerLayout } from '@shared/layouts';
import React from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <div className="mt-4">
    <SimpleContainerLayout title="Página no encontrada">
      <p className="text-center">
        La página que buscas no se encuentra o no está disponible. Si consideras que este mensaje puede tratarse de un
        error, por favor{' '}
        <a href="https://soporte.alliot.cloud/support/home" rel="noreferrer" target="_blank">
          contáctanos
        </a>
        .
      </p>
    </SimpleContainerLayout>
  </div>
);
