import { confirmDialog, FontAwesome } from '@shared';
import { connect, deskSessionGenerators } from '@store';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Alert, Button, Col, Row } from 'reactstrap';
import { compose, withHandlers } from 'recompose';
import { MovementsList } from './components/MovementsList';
import { SavingModal } from './components/SavingModal';
import { TargetEditor } from './components/TargetEditor';
import { TargetInput } from './components/TargetInput';
import { UserBlock } from './components/UserBlock';
import { get } from 'lodash';

const enhance = compose(
  connect(
    ({
      deskSession: { clientData, currentTarget, movements: sessionMovements },
      appData: { movements: movementTypes },
    }) => ({
      clientData,
      currentTarget,
      sessionMovements,
      movementTypes,
    }),
    (dispatch) => ({
      saveSession: () => dispatch(deskSessionGenerators.setSavingState('confirming')),
    }),
  ),
  withHandlers({
    finishSession: ({ finishSession }) => async () => {
      const value = await confirmDialog(
        '¿Estás seguro de que deseas salir? Todos los cambios sin guardar se perderán.',
      );

      if (value) finishSession && finishSession();
    },
  }),
);

const DeskSessionPresentation = ({
  currentClient,
  currentTarget,
  finishSession,
  saveSession,
  sessionMovements,
  clientData,
  movementTypes,
}) => {
  let restrictedItemDetected = undefined;
  const movementTypesToRestrictedItems = Object.keys(movementTypes).filter(
    (movementType) => movementTypes[movementType].expects_client,
  );
  const showRestrictionAlert =
    sessionMovements.length > 0 &&
    clientData &&
    sessionMovements.some((movement) =>
      get(clientData, 'restrictedItems')?.some((restrictedItem) => {
        restrictedItemDetected = { item: restrictedItem.item.name };
        return (
          restrictedItem.item.barcode1 ===
            (movement?.consumable ? movement.item.barcode1 : movement.asset.item.barcode1) &&
          (restrictedItem.quantity <= restrictedItem.delivered ||
            restrictedItem.quantity <
              restrictedItem.delivered + (movement?.consumable ? Number(movement?.quantity) : 1)) &&
          movementTypesToRestrictedItems.some((movementTypeRestricted) => {
            restrictedItemDetected['movementType'] = movementTypes[movementTypeRestricted];
            return movement.movementType === movementTypeRestricted;
          })
        );
      }),
    );
  return (
    <div className="layout-container container-fluid mt-4 mb-4">
      <h2 className="mb-4">
        <FontAwesome name="desktop" className="mr-3 text-primary" />
        Sesión de escritorio
      </h2>
      <Row>
        <Col md={5}>
          <UserBlock client={currentClient} />
        </Col>
        <Col md={7}>
          <Row>
            <Col md={8} className="mb-3">
              <TargetInput client={currentClient} />
            </Col>
            <Col md={4} className="mb-3">
              <Button
                role="button"
                block
                size="lg"
                color="primary"
                disabled={sessionMovements.length === 0 || currentTarget || showRestrictionAlert}
                onClick={saveSession}
              >
                <FontAwesome name="floppy" /> Guardar
              </Button>
              <Button role="button" block color="danger" onClick={finishSession}>
                <FontAwesome name="sign-out" /> Salir sin guardar
              </Button>
              <Button role="button" block tag={NavLink} to="/movements/new">
                <FontAwesome name="pencil-alt" /> Ingreso manual
              </Button>
            </Col>
          </Row>

          <Alert color="info">
            <strong>
              <FontAwesome name="light-bulb" /> Consejo:
            </strong>{' '}
            Puedes explorar cualquier sección e incluso dirigirte a otra página web sin perder tus cambios.
          </Alert>

          {showRestrictionAlert && (
            <Alert color="danger">
              <strong>
                <FontAwesome name="ban" /> Restringido:
              </strong>{' '}
              El artículo <strong>{restrictedItemDetected?.item}</strong> agregado a la lista "Resumen movimientos" se
              encuentra restringido para <strong>{restrictedItemDetected?.movementType?.name}</strong> por parte de este
              cliente. Eliminelo de de la lista para poder guardar la sessión
            </Alert>
          )}

          <TargetEditor />

          <MovementsList />
        </Col>
      </Row>
      <SavingModal />
    </div>
  );
};

export const DeskSession = enhance(DeskSessionPresentation);
