import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { reset } from 'redux-form';

import { getAttachments, deleteAttachment } from '@core/building/attachments';
import { connect, store, notificationsGenerators } from '@store';
import { FontAwesome, confirmDialog, Loading, Pagination, ShowForRoles } from '@shared';
import { AttachmentCard, AttachmentUploader } from '@shared/attachment-manager';
import { pluralize } from '@shared/helpers';
import { getAdminAttachments, deleteAdminAttachment } from '@core/admin/attachments';

const creationColorDelay = 5000;

class AttachmentManager extends Component {
  state = {
    attachments: [],
    loading: true,
    pagination: {
      page: 1,
      per: 30,
      total: 0,
    },
    showUpload: false,
  };

  async componentDidMount() {
    await this.loadAttachments();
  }

  loadAttachments = async (page = 1) => {
    const { resourceId, resourceType, isAdmin } = this.props;

    const payload = {
      resourceId,
      resourceType,
      p: page,
    };

    const { body, pagination, status } = isAdmin ? await getAdminAttachments(payload) : await getAttachments(payload);

    if (status === 200) {
      this.setState({
        attachments: body,
        loading: false,
        pagination,
      });

      return body;
    }
  };

  showUploadForm = () => {
    this.clearForm();
    this.setState({ showUpload: true });
  };

  toggleUpload = () => {
    this.clearForm();
    this.setState({ showUpload: !this.state.showUpload });
  };

  onSuccessfulAttachment = async (resource) => {
    const attachments = await this.loadAttachments(1);

    this.setState({
      showUpload: false,
    });

    const index = attachments.findIndex((attachment) => attachment.token === resource.token);

    if (index !== -1) {
      this.setState({
        attachments: [
          ...attachments.slice(0, index),
          { ...attachments[index], $created: true },
          ...attachments.slice(index + 1),
        ],
      });

      setTimeout(() => this.clearSuccessfulAttachmentColor(resource.token), creationColorDelay);
    }

    this.props.creationNotification(resource.title);
    this.clearForm();
  };

  clearSuccessfulAttachmentColor = (token) => {
    const { attachments } = this.state;

    const index = attachments.findIndex((attachment) => attachment.token === token);

    if (index !== -1) {
      attachments[index].$created = false;

      this.setState({
        attachments: [
          ...attachments.slice(0, index),
          { ...attachments[index], $created: false },
          ...attachments.slice(index + 1),
        ],
      });
    }
  };

  onDelete = async (token) => {
    const outcome = await confirmDialog(
      '¿Estás seguro de que deseas borrar este archivo? Esta acción no se puede deshacer.',
    );

    if (!outcome) return;

    const { resourceId, resourceType, isAdmin } = this.props;
    const payload = {
      resourceId,
      resourceType,
      token,
    };
    const { status } = isAdmin ? await deleteAdminAttachment(payload) : await deleteAttachment(payload);

    if (status === 204) {
      const { attachments } = this.state;

      const index = attachments.findIndex((att) => att.token === token);

      if (index !== -1) {
        this.props.deletionNotification(attachments[index].title);

        this.setState({
          attachments: [...attachments.slice(0, index), ...attachments.slice(index + 1)],
        });
      }
    }
  };

  clearForm() {
    store.dispatch(reset('attachmentForm'));
  }

  setPage = async (page) => {
    const { pagination } = this.state;
    if (pagination && page === pagination.page) {
      return;
    }

    await this.loadAttachments(page);
  };

  render() {
    const {
      attachments,
      loading,
      pagination: { page, total },
      showUpload,
    } = this.state;

    const { resourceId, resourceType, isAdmin } = this.props;

    if (loading) return <Loading />;

    return (
      <div>
        {attachments.length > 0 ? (
          <p>
            Hay <strong>{attachments.length}</strong>{' '}
            {pluralize(attachments.length, 'archivo cargado', 'archivos cargados')}. Los archivos son ordenados
            automáticamente por fecha.
          </p>
        ) : (
          <div className="text-center mb-4">No hay adjuntos cargados en este momento.</div>
        )}
        <ShowForRoles rootExcluded={['root']} excluded={['guest']}>
          <div className="mb-4">
            <Button role="button" block color="danger" onClick={this.showUploadForm}>
              <FontAwesome name="upload" /> Cargar archivo adjunto
            </Button>
          </div>
        </ShowForRoles>
        <ShowForRoles rootAccepted={['root']}>
          <div className="mb-4">
            <Button role="button" block color="danger" onClick={this.showUploadForm}>
              <FontAwesome name="upload" /> Cargar archivo adjunto
            </Button>
          </div>
        </ShowForRoles>

        {attachments.map((attachment) => (
          <AttachmentCard
            key={attachment.token}
            attachment={attachment}
            resourceType={resourceType}
            resourceId={resourceId}
            isAdmin={isAdmin}
            onDelete={this.onDelete}
          />
        ))}
        {attachments.length > 0 && <Pagination page={page} total={total} setPage={this.setPage} />}
        <AttachmentUploader
          resourceType={resourceType}
          resourceId={resourceId}
          isAdmin={isAdmin}
          showModal={showUpload}
          toggle={this.toggleUpload}
          onSuccessfulAttachment={this.onSuccessfulAttachment}
        />
      </div>
    );
  }
}

export default connect(null, (dispatch) => ({
  creationNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Archivo cargado',
        text: `El archivo ${name} ha sido adjuntado exitosamente.`,
        color: 'success',
      }),
    ),
  deletionNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Archivo eliminado',
        text: `El archivo ${name} ha sido eliminado exitosamente.`,
        color: 'success',
      }),
    ),
}))(AttachmentManager);
