import { createBatch } from '@core/admin/batches';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, withHandlers } from 'recompose';
import { SubmissionError } from 'redux-form';
import { BatchForm } from './components/BatchForm';

const enhance = compose(
  connect(null, (dispatch) => ({
    creationNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Lote creado',
          text: `El lote ha sido creado exitosamente.`,
          color: 'success',
        }),
      ),
  })),
  withRouter,
  withHandlers({
    onSubmit: ({ history, creationNotification }) => async (batch) => {
      const { body, status } = await createBatch(batch);

      if (status === 201) {
        creationNotification();
        history && history.push('/admin/batches');
      } else if (status === 422) {
        throw new SubmissionError(body.errors);
      }
    },
  }),
);

const CreateBatchPresentation = ({ onSubmit }) => (
  <SimpleContainerLayout padded className="mt-4 mb-4" title="Nuevo Lote">
    <BatchForm onSubmit={onSubmit} />
  </SimpleContainerLayout>
);

export const CreateBatch = enhance(CreateBatchPresentation);
