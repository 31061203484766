import React, { Component } from 'react';
import { Card, CardBody, CardGroup, CardTitle, Col, Media, Row } from 'reactstrap';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { login } from '@core/login';
import { SubmissionError } from '@core/forms';
import { connect, sessionGenerators, notificationsGenerators, deskSessionGenerators } from '@store';
import { FontAwesome } from '@shared';

import Forgot from './Forgot';
import LoginForm from './components/LoginForm';

class Login extends Component {
  state = {
    isForgot: false,
    inputProps: { email: '', password: '' },
    returnTo: '/',
  };

  componentDidMount() {
    this.setState({
      isForgot: this.props.match.path.endsWith('forgot'),
    });

    const returnTo =
      this.props.location.state && this.props.location.state.from && this.props.location.state.from.pathname;

    if (
      returnTo &&
      !returnTo.startsWith('/account/login') &&
      !returnTo.startsWith('/account/forgot') &&
      !returnTo.startsWith('/account/logout')
    )
      this.setState({
        returnTo,
      });
  }

  setLoginInput = (data) => {
    this.setState({
      inputProps: data,
    });
  };

  setForgot = (e) => {
    e.preventDefault();

    this.setState({
      isForgot: true,
    });

    window.history.pushState('', '', '/account/forgot');
  };

  closeForgot = () => {
    this.setState({
      isForgot: false,
    });

    window.history.pushState('', '', '/account/login');
  };

  performLogin = async ({ email, password }) => {
    const { body, status } = await login(email, password);

    if (status === 201) {
      this.props.dispatchLogin(body);
      this.props.finishSession();

      this.props.insertNotification({ name: body.user.firstName });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { loggedIn } = this.props;
    const {
      isForgot,
      inputProps: { email },
      returnTo,
    } = this.state;

    if (loggedIn) return <Redirect to={returnTo} />;

    return (
      <div>
        <LoginPresentation
          performLogin={this.performLogin}
          setForgot={this.setForgot}
          setLoginInput={this.setLoginInput}
        />
        <Forgot show={isForgot} email={email} toggle={this.closeForgot} />
      </div>
    );
  }
}

const LoginPresentation = ({ inputProps, performLogin, setForgot, setLoginInput }) => (
  <div className="container-fluid layout-container mt-4 mb-4">
    <Row className="justify-content-center">
      <Col xs={12} sm={12} md={12} xl={10}>
        <CardGroup>
          <Card>
            <CardBody>
              <LoginForm onChange={setLoginInput} onSubmit={performLogin} />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <CardTitle tag={'h4'}>Inicia sesión</CardTitle>
              <div className="mb-3">
                Para ingresar a esta sección, primero debes iniciar sesión. Por favor ingresa tu usuario y contraseña a
                continuación.
              </div>
              <Media className="mb-3">
                <Media className="mr-3 h1 text-info align-self-center mb-0" left>
                  <FontAwesome name="question" />
                </Media>
                <Media body className="align-self-center">
                  <strong>Si olvidaste tu contraseña,</strong> puedes recuperarla{' '}
                  <a href="/account/forgot" onClick={setForgot}>
                    aquí
                  </a>
                  .
                </Media>
              </Media>
              <Media className="mb-3">
                <Media className="mr-3 h1 text-warning align-self-center mb-0" left>
                  <FontAwesome name="envelope" />
                </Media>
                <Media body className="align-self-center">
                  <strong>Si tienes problemas con tu cuenta,</strong> por favor{' '}
                  <a href="mailto:info@alliot.cl">contáctanos</a>.
                </Media>
              </Media>
            </CardBody>
          </Card>
        </CardGroup>
      </Col>
    </Row>
  </div>
);

export default compose(
  withRouter,
  connect(
    (state) => ({
      loggedIn: state.session.loggedIn,
    }),
    (dispatch) => ({
      dispatchLogin: (token) => dispatch(sessionGenerators.login(token)),
      finishSession: () => dispatch(deskSessionGenerators.finish()),
      insertNotification: ({ name }) => {
        dispatch(
          notificationsGenerators.insert({
            title: '¡Bienvenid@!',
            text: `Bienvenid@ nuevamente, ${name}.`,
            color: 'success',
          }),
        );
      },
    }),
  ),
)(Login);
