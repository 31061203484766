import React from 'react';

export const MassImportInstructions = () => (
  <ul>
    <li className="mb-2">
      Solo se permiten archivos <code>csv</code>. Usted puede guardar planillas Excel fácilmente con este formato a
      partir de la opción <code>Guardar como...</code>.
    </li>
    <li className="mb-2">
      Se recomienda que genere una planilla sencilla para crear el archivo CSV, sin formato ni columnas o filas extras.
    </li>
    <li className="mb-2">
      La primera fila del archivo CSV debe contener los nombres de las columnas asociadas. Estas pueden anotarse en el
      orden deseado.
    </li>
    <li className="mb-2">
      Las columnas a indicar son las siguientes:
      <ol className="mt-2">
        <li className="mb-2">
          <code>item_id</code>: ID del artículo.
        </li>
        <li className="mb-2">
          <code>device_id</code>: Id del dispositivo.
        </li>
        <li className="mb-2">
          <code>minimal_stock</code>: Stock Mínimo.
        </li>
        <li className="mb-2">
          <code>optimal_stock</code>: Stock Óptimo.
        </li>
        <li className="mb-2">
          <code>max_stock</code>: Stock Máximo.
        </li>
      </ol>
      Cualquier otra columna será ignorada automáticamente.
    </li>
    <li className="mb-2">Todas las columnas especificadas deben indicar un valor válido.</li>
    <li className="mb-2">
      Si alguna fila presenta un error en sus valores, no se procesará. El archivo deberá ser cargado nuevamente.
    </li>
  </ul>
);
