import { createPost } from '@core/admin/posts';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { PostForm } from './components/PostForm';

class NewPostPresentation extends Component {
  state = {
    redirectTo: undefined,
  };

  createPost = async (post) => {
    const { body, status } = await createPost({ ...post });

    if (status === 201) {
      this.props.successCreationNotification();
      this.setState({ redirectTo: `/admin/posts` });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };
  render() {
    const { redirectTo } = this.state;

    if (redirectTo) return <Redirect to={redirectTo} />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4" title="Nueva noticia">
        <PostForm onSubmit={this.createPost} initialValues={{ content: '' }} />
      </SimpleContainerLayout>
    );
  }
}

export const NewPost = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Post creado',
        text: 'El post ha sido creado exitosamente.',
        color: 'success',
      }),
    ),
}))(NewPostPresentation);
