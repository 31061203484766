import { apiFetch, handleAuth, withMembership } from '../http';

export const getExpectedStock = (barcode, building_id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${barcode}/expected_stocks/${building_id}`,
      method: 'GET',
    }),
  );

export const getExpectedStocks = (barcode) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${barcode}/expected_stocks`,
      method: 'GET',
    }),
  );

export const updateExpectedStock = (barcode, expectedStock) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/items/${barcode}/expected_stocks`,
      method: 'POST',
      body: {
        expectedStock,
      },
    }),
  );
