import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const getAssetMaintenances = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_maintenances${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAssetMaintenance = (assetMaintenanceId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_maintenances/${assetMaintenanceId}`,
      method: 'GET',
    }),
  );

export const getAssetMaintenanceStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_maintenances/stats`,
      method: 'GET',
    }),
  );
