import { FontAwesome, Loading } from '@shared';
import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { InventoryCompareInstructions } from './Instructions';
import { UploadForm } from './UploadForm';

export class Header extends Component {
  state = {
    showInstructions: false,
  };

  toogleInstructionsHandler = () => {
    this.setState({
      showInstructions: !this.state.showInstructions,
    });
  };

  render() {
    const { showInstructions } = this.state;
    const { title, file, processing, setFile } = this.props;

    return (
      <Row className="justify-content-center">
        <Col xs={12} lg={10}>
          <Card>
            <CardBody>
              <CardTitle className="mb-4 text-center">{title}</CardTitle>
              {processing && <Loading />}
              {!processing && (
                <UploadForm
                  file={file}
                  getRef={(input) => {
                    this.fileInput = input;
                  }}
                  processing={processing}
                  setFile={setFile}
                />
              )}
            </CardBody>

            <CardFooter role="button" onClick={this.toogleInstructionsHandler} className="alert-info h5 m-0">
              <FontAwesome name={`chevron-${showInstructions ? 'down' : 'right'}`} />
              {` `}
              Instrucciones y más información
            </CardFooter>
            {showInstructions && (
              <ListGroup flush>
                <ListGroupItem>
                  <InventoryCompareInstructions />
                </ListGroupItem>
              </ListGroup>
            )}
          </Card>
        </Col>
      </Row>
    );
  }
}
