import React from 'react';
import { Card, CardBody, CardTitle, Col, Row, Container } from 'reactstrap';

export { default as BuildingLayout } from './BuildingLayout';

export const SimpleContainerLayout = ({ className, title, children, previous, padded = false }) => (
  <Container fluid className={`mb-4 ${className} ${padded ? '' : 'no-pad'}`}>
    <Row noGutters className="justify-content-center">
      <Col xl={padded ? 10 : null}>
        {previous}

        <Card>
          <CardBody>
            {title && (
              <CardTitle className="mb-4 text-center" tag={'h4'}>
                {title}
              </CardTitle>
            )}

            {children}
          </CardBody>
        </Card>
      </Col>
    </Row>
  </Container>
);
