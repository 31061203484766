import React from 'react';
import { FileIcon } from '@shared';
import { fileInfoFor } from '@core/utils/mime-types';

const getFileType = (contentType) => {
  const info = fileInfoFor(contentType);
  return info.icon;
};

const AttachmentDisplay = ({ attachments }) => {
  return (
    <div className="d-inline-block m-1">
      {attachments.slice(0, attachments.length).map((attachment, index) => (
        <div className="d-flex mb-2" key={index}>
          <a href={attachment.file.url} download>
            <FileIcon type={getFileType(attachment.contentType)} />{' '}
            {attachment.title ? attachment.title : attachment.originalFilename}
          </a>
        </div>
      ))}
    </div>
  );
};

export default AttachmentDisplay;
