import React from 'react';
import { Task } from './Task';
import './TaskList.scss';

export const TaskList = ({ tasks = [], onSelect, onComplete, onStar, selected = {} }) => {
  return (
    <div className="task-list">
      {tasks.map((t, index) => (
        <Task
          key={t.id}
          task={t}
          className="task-item"
          selected={t.id === selected.id}
          onClick={onSelect}
          onComplete={onComplete}
          onStar={onStar}
        />
      ))}
    </div>
  );
};
