import React from 'react';
import { Media } from 'reactstrap';
import { PictureDisplay, FontAwesome } from '@shared';
import './maintenance-display.scss';

export const MaintenanceDisplay = ({ assetRepair, order, active, semaphore = true }) => {
  const { asset, item } = assetRepair;
  const className = semaphore ? `status-border mro-${assetRepair.state}` : '';

  return (
    <Media className="align-items-center">
      <Media left className="mr-2">
        <PictureDisplay
          picture={item.defaultPicture}
          imgClassName={className}
          pictureSize="icon"
          alt={item.name}
          width="6rem"
          height="4rem"
        />
      </Media>
      <Media body>
        <h6 className="asset-name">
          <small className="font-weight-bold">{item.name}</small>
        </h6>
        <div className="w-100 text-overflow">
          <kbd className="small">{asset.barcode1}</kbd>
          <div>
            <span className={`order-number ${active ? 'order-active' : ''}`}>No. Orden: {order}</span>{' '}
            <span>{assetRepair.id && <span className="order-number">Nº MRO: {assetRepair.id}</span>}</span>
          </div>
          <div>
            {item.internalSku && <span className="order-number mr-3">SKU Interno: {item.internalSku}</span>}
            {asset.factorySerialNumber1 && (
              <span className="order-number">Identificador 1: {asset.factorySerialNumber1}</span>
            )}
          </div>
          <div>{asset.mark && <span className="order-number">Rótulo: {asset.mark}</span>} </div>
          <div>
            {assetRepair.owner && (
              <span className="mr-4">
                <FontAwesome name="industry" className="mr-1" /> Dueño
              </span>
            )}
            {assetRepair.supplier && (
              <span>
                <FontAwesome name="wrench" className="mr-1" /> Proveedor
              </span>
            )}
          </div>
        </div>
      </Media>
    </Media>
  );
};
