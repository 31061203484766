import React, { Component } from 'react';

import { logout } from '@core/login';
import { connect, sessionGenerators, notificationsGenerators, deskSessionGenerators } from '@store';
import { Loading } from '@shared';

const mapDispatchToProps = (dispatch) => ({
  doLogout: () => {
    dispatch(sessionGenerators.logout());
  },
  finishSession: () => dispatch(deskSessionGenerators.finish()),
  insertNotification: () => {
    dispatch(
      notificationsGenerators.insert({
        title: 'Desconectado',
        text: 'Has sido desconectado exitosamente.',
        color: 'success',
      }),
    );
  },
});

class LogoutPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    await logout();
    this.props.insertNotification();
    this.props.finishSession();
    this.props.doLogout();
  }

  render = () => {
    return <Loading />;
  };
}

const Logout = connect(null, mapDispatchToProps)(LogoutPresentation);

export default Logout;
