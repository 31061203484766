import React from 'react';
import { Card, CardBody, Input } from 'reactstrap';
import { connect } from '@store';
import { compose } from 'recompose';

const TypeSelectorPresentation = ({
  autofill,
  movements,
  consumable,
  repairable,
  asset,
  assetRepair,
  item,
  meta,
  onChange,
  inputProps,
  value,
  excluded = [],
  excludedConsumables = [],
}) => {
  let filteredMovements = [];

  if (consumable && item) {
    filteredMovements = Object.keys(movements).filter((movement) => {
      if (excludedConsumables.includes(movement)) {
        return false;
      }
      return movements[movement]['behavior']['consumable'];
    });
  }

  if (!consumable && asset && asset.state && !repairable) {
    filteredMovements = Object.keys(movements).filter((movement) => {
      if (excluded.includes(movement)) {
        return false;
      }

      const movementIsNotEntry = movement !== 'entry';

      const movementMustHaveAllowedBehavior = movements[movement]['behavior']['non_consumable'];

      const assetHealthStateMustBeAllowed =
        !!movements[movement].allowed_health_states &&
        movements[movement].allowed_health_states.includes(asset.healthState);
      const assetStateMustBeAllowed =
        !movements[movement].allowed_states || movements[movement].allowed_states.includes(asset.state);

      return (
        movementIsNotEntry &&
        movementMustHaveAllowedBehavior &&
        assetStateMustBeAllowed &&
        assetHealthStateMustBeAllowed
      );
    });
  }

  if (!consumable && asset && asset.healthState && repairable) {
    filteredMovements = Object.keys(movements).filter((movement) => {
      if (excluded.includes(movement)) {
        return false;
      }

      const movementIsNotEntry = movement !== 'entry';

      const assetLastStepsMustBeAllowed =
        !!movements[movement].allowed_last_steps && movements[movement].allowed_last_steps.includes(assetRepair.state);

      return movementIsNotEntry && assetLastStepsMustBeAllowed;
    });
  }

  const enabled = (!consumable || item) && (consumable || asset);

  return (
    <div>
      <Input type="select" value={value} onChange={onChange} disabled={!enabled} {...inputProps}>
        <option value="">{enabled ? 'Selecciona un tipo' : 'Selecciona primero un activo o artículo'}</option>
        {filteredMovements.map((key) => (
          <option value={key} key={key}>
            {movements[key].name}
          </option>
        ))}
      </Input>
      {value && (
        <Card className="mt-2 mb-2" color="info" outline>
          <CardBody>{movements[value].description}</CardBody>
        </Card>
      )}
    </div>
  );
};

const enhance = compose(
  connect(({ appData }, ownProps) => {
    const key = ownProps.bpmType ? `${ownProps.bpmType}_movements` : 'movements';
    return { movements: appData[key] };
  }),
);

export const TypeSelector = enhance(TypeSelectorPresentation);
