import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';

import { Highcharts } from '../../../@shared/charts';

import { getMaintenanceStats } from '@core/building/asset-repairs';

import Chart from './Chart';

const getChartOptions = (title, data) => {
  return {
    title: {
      text: title,
    },
    credits: {
      enabled: true,
      href: 'http://www.alliot.cloud',
      text: 'alliot.cloud',
    },
    tooltip: {
      shared: true,
      pointFormat: '{series.name}: {point.y} <b>({point.percentage:.1f}%)</b>',
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)',
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data,
        keys: ['name', 'y'],
        type: 'pie',
      },
    ],
  };
};

export default class MaintenanceChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
    };
  }

  async componentDidMount() {
    await this.setMROStats();
  }

  setMROStats = async () => {
    const { body } = await getMaintenanceStats();
    const stats = body;
    this.setState({
      data: [
        ['Reparaciones', stats.repair?.total],
        ['Evaluaciones', stats.evaluation?.total],
        ['Mantenciones', stats.maintenance?.total],
        ['Preservaciones', stats.preservation?.total],
        ['Certificaciones', stats.certification?.total],
        ['Garantías', stats.assurance?.total],
        ['Dar de Baja', stats.discard?.total],
      ],
    });
  };

  render() {
    const { title } = this.props;
    const { data } = this.state;
    return (
      <Card>
        <CardHeader className="chart-title-container">
          <h4>Procesos MRO activos</h4>
        </CardHeader>
        <CardBody className="p-2">
          <Chart options={getChartOptions(title, data)} highcharts={Highcharts} />
        </CardBody>
      </Card>
    );
  }
}
