export const ExceptionsActions = {
  Toggle401: 'ExceptionsActions.Toggle401',
  Toggle500: 'ExceptionsActions.Toggle500',
  ToggleInternetException: 'ExceptionsActions.ToggleInternetException',
};

export const exceptionsInitialState = {
  has401: false,
  has500: false,
  internetException: false,
};

export const exceptions = (state = exceptionsInitialState, action) => {
  switch (action.type) {
    case ExceptionsActions.Toggle401:
      return {
        ...state,
        has401: !state.has401,
      };
    case ExceptionsActions.Toggle500:
      return {
        ...state,
        has500: !state.has500,
      };
    case ExceptionsActions.ToggleInternetException:
      return {
        ...state,
        internetException: true,
      };
    default:
      return state;
  }
};

export const exceptionsGenerators = {
  toggle401: () => ({
    type: ExceptionsActions.Toggle401,
  }),
  toggle500: () => ({
    type: ExceptionsActions.Toggle500,
  }),
  toggleInternetException: () => ({
    type: ExceptionsActions.ToggleInternetException,
  }),
};
