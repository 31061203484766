import { handleAuth, apiFetch, withMembership, mountQuery } from '../http';

export const getAssetCertifications = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_certifications${mountQuery({ ...query })}`,
      method: 'GET',
    }),
  );

export const getAssetCertification = (assetCertificationId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_certifications/${assetCertificationId}`,
      method: 'GET',
    }),
  );

export const getAssetCertificationStats = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/asset_certifications/stats`,
      method: 'GET',
    }),
  );
