import { updateKit } from '@core/building/kits';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import { KitForm } from './components/KitForm';

class EditKitPresentation extends Component {
  state = {
    redirectTo: undefined,
  };

  notFound = () => {
    this.props.notFoundNotification();
    this.setState({ redirectTo: '/building/kits' });
  };

  updateKit = async (kit) => {
    const { body, status } = await updateKit({ ...kit });

    if (status === 200) {
      this.props.setKit(body);
      this.props.successEditingNotification();
      this.setState({ redirectTo: `/building/kits/${body.id}` });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    } else if (status === 404) {
      this.notFound();
    }
  };

  render() {
    const { kit } = this.props;
    const { redirectTo } = this.state;

    if (redirectTo) return <Redirect to={redirectTo} />;

    return <KitForm onSubmit={this.updateKit} initialValues={kit} />;
  }
}

export const EditKit = connect(null, (dispatch) => ({
  successEditingNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Kit editado',
        text: `El kit ha sido editado exitosamente.`,
        color: 'success',
      }),
    ),
}))(EditKitPresentation);
