import numberCheck from './number-check';
import unwrap from './unwrap';

function length({ value, message, lessThan, greaterThan, lessThanOrEqualTo, greaterThanOrEqualTo }) {
  if (Array.isArray(value) || typeof value === 'string') {
    const valueLength = value.length;
    let errors = numberCheck({
      value: valueLength,
      lessThan,
      greaterThan,
      lessThanOrEqualTo,
      greaterThanOrEqualTo,
    });

    if (errors.length > 0)
      return {
        valid: false,
        message:
          (message && (typeof message === 'function' ? message(errors) : message)) ||
          `No tiene un largo válido. El largo ${errors.join(', ')}.`,
      };
  }

  return {
    valid: true,
  };
}

export default unwrap(length);
