import { Barcode, PictureDisplay } from '@shared';
import React from 'react';
import { Card, CardBody, Media } from 'reactstrap';

export const ItemHeader = ({ item }) => (
  <Card className="mb-4">
    <CardBody>
      <Media className="align-items-center">
        <Media left className="mr-3">
          <PictureDisplay picture={item.defaultPicture} alt={item.name} />
        </Media>
        <Media body>
          <h4>{item.name}</h4>
          <div className="small">
            SKU Interno: <span className="text-muted">{item.internalSku}</span>
            {item.externalSku && (
              <span>
                {' '}
                - SKU Externo: <span className="text.muted">{item.externalSku}</span>
              </span>
            )}
          </div>
          <div className="mt-2 tiny">
            <code>{item.barcode1}</code>
          </div>
        </Media>
        <Media right>
          <Barcode code={item.barcode1} style={{ height: '5rem' }} />
        </Media>
      </Media>
    </CardBody>
  </Card>
);
