import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from 'reactstrap';

const ConfirmBox = ({ isOpen, avoidConfirmBox, closeConfirmBox, removeTag, type, message, resource }) => (
  <Modal isOpen={isOpen} size="md">
    <ModalHeader>Confirmación</ModalHeader>
    <ModalBody>
      <p>
        ¿Estás seguro de que deseas eliminar <strong>permanentemente</strong> este {type}
        {': '}
        <code>{resource}</code>
        {' ?'}
      </p>
      <p>{message}</p>
    </ModalBody>
    <ModalFooter className="justify-content-between">
      <div className="text-left">
        <Label check>
          <Input type="checkbox" name="skip" id="skip" onChange={avoidConfirmBox} className="mr-2" />
          No volver a preguntar
        </Label>
      </div>
      <div className="text-right">
        <Button role="button" onClick={closeConfirmBox}>
          Cancelar
        </Button>{' '}
        <Button role="button" color="danger" onClick={removeTag}>
          Eliminar
        </Button>
      </div>
    </ModalFooter>
  </Modal>
);

export default ConfirmBox;
