import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getAttachments = ({ resourceId, resourceType, ...query }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/${resourceType}/${resourceId}/attachments${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createAttachment = ({ resourceId, resourceType, token, title, description, showSummary }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/${resourceType}/${resourceId}/attachments`,
      method: 'POST',
      body: {
        token,
        title,
        description,
        showSummary,
      },
    }),
  );

export const updateAttachment = ({ resourceId, resourceType, token, title, description }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/${resourceType}/${resourceId}/attachments/${token}`,
      method: 'PUT',
      body: {
        title,
        description,
      },
    }),
  );

export const deleteAttachment = ({ resourceId, resourceType, token }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/${resourceType}/${resourceId}/attachments/${token}`,
      method: 'DELETE',
    }),
  );
