import { getDelayedTags } from '@core/building/tags';
import { FontAwesome, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { mountUrl } from '@shared/simple-filter/SimpleFilter';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { Button, Col, Row } from 'reactstrap';

const getTitle = (from, to) => {
  if (Number(from) === 0 && Number(to) === 0) {
    return 'Sin atraso';
  } else if (!to) {
    return `${from} días o más de atraso`;
  } else {
    return `${from} a ${to} días de atraso`;
  }
};

const getBarColor = (from) => {
  const value = Number(from);
  // colors: #43aa8b, #90be6d, #f9c74f, #f8961e, #f3722c, #f94144, #d00000, #9d0208
  let color = '#9d0208';

  if (value >= 90) {
    color = '#9d0208';
  } else if (value >= 60) {
    color = '#d00000';
  } else if (value >= 30) {
    color = '#f3722c';
  } else if (value >= 15) {
    color = '#f8961e';
  } else if (value >= 1) {
    color = '#f9c74f';
  } else if (value === 0) {
    color = '#43aa8b';
  }

  return color;
};

export class Dashboard extends Component {
  state = {
    loading: true,
    active: null,
    data: { total: 0, ranges: [] },
  };

  async componentDidMount() {
    await this.fetchData();
  }

  fetchData = async () => {
    this.setState({ loading: true });

    const { body, status } = await getDelayedTags({
      active: this.state.active ? this.state.active.value : undefined,
    });

    if (status === 200) {
      this.setState({
        data: body,
        loading: false,
      });
    } else {
      // handle errors
    }
  };

  onChange = async (value, action) => {
    this.setState(
      {
        [action.name]: value,
      },
      () => this.fetchData(),
    );
  };

  render() {
    const { active, loading, data } = this.state;
    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right d-flex">
              <span style={{ width: '200px' }}>
                <Select
                  name="active"
                  value={active}
                  placeholder="Todos"
                  isClearable
                  options={[
                    { label: 'Activo', value: true },
                    { label: 'Inactivo', value: false },
                  ]}
                  onChange={this.onChange}
                />
              </span>
              <Button tag={NavLink} color="secondary" to="/building/tags">
                <FontAwesome name="list" /> Ver Listado
              </Button>
              <ShowForRoles excluded={['guest']}>
                <Button tag={NavLink} outline color="success" to="/building/tags/new">
                  <FontAwesome name="plus" /> Crear Tag
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="tags" className="text-primary mr-2" />
              Dashboard de Tags
            </h4>
          </Col>
        </Row>
        <Row>
          {loading && (
            <Col>
              <div className="text-center h1 text-info">
                <Spinner />
              </div>
            </Col>
          )}
          {!loading &&
            data.ranges.map((r, i) => {
              return (
                <Col xs="4" className="mb-4">
                  <TagCard
                    key={`tag-card-${i}`}
                    label={getTitle(r.from, r.to)}
                    value={r.total}
                    total={data.total}
                    from={r.from}
                    to={r.to}
                    barColor={getBarColor(r.from)}
                    active={active}
                  />
                </Col>
              );
            })}
        </Row>
      </BuildingLayout>
    );
  }
}

const TagCard = ({ label, value, total, barColor, from, to, active }) => {
  let percent = total ? (value * 100) / Number(total) : value;

  const delayed_days = [from];

  if (to) {
    delayed_days.push(to);
  }

  const query = mountUrl({
    delayed_days: delayed_days.join(','),
    active: active ? active : undefined,
  });

  return (
    <div className="card card-custom bg-light-success card-stretch gutter-b">
      <div className="card-body">
        <span
          className="card-title font-weight-bolder text-hover-state-dark font-size-h6 mb-4 d-block"
          style={{ color: '#777' }}
        >
          {label}
        </span>
        <div className="font-weight-bold text-muted font-size-sm">
          <span className="text-dark-75 font-size-h2 font-weight-bolder">{percent.toFixed(2)}%</span> - {value} de{' '}
          {total} tags
        </div>
        <div className="progress progress-xs mt-7 bg-success-o-60">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${percent}%`, backgroundColor: barColor }}
          ></div>
        </div>
        <Button tag={NavLink} className="mt-3" color="light" size="sm" block to={`/building/tags${query}`}>
          Ver en detalle
        </Button>
      </div>
    </div>
  );
};
