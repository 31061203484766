import { get } from 'lodash';

// the function called in validation declaration
const unwrap = function (validation) {
  // the function called by the user in validations declaration
  return function ({ of: target, if: condition, deep: deepReference, ...otherSettings }) {
    // the function called internally by Validates() in order to pass the data
    const result = function (data) {
      // the validation itself, with the datum to validate
      if (condition)
        return condition(data)
          ? validation({
              value: deepReference ? get(data[target], `${deepReference}`) : data[target],
              ...otherSettings,
            })
          : { valid: true };
      else
        return validation({
          value: deepReference ? get(data[target], `${deepReference}`) : data[target],
          ...otherSettings,
        });
    };

    // this labels the function as an standard validation
    // and indicates the target
    result.prototype.__standardValidation__ = true;
    result.prototype.__validationTarget__ = target;

    return result;
  };
};

export default unwrap;
