import React, { Component } from 'react';

import { loadFile, withToken } from '@core/http';
import { getPicture, deletePicture, tokensFor } from '@core/files';
import { PictureUploaderForm } from '@core/forms';

import './PictureUploader.scss';

function isProcessing(files) {
  return files.reduce((c, f) => c || f.status === 'uploading', false);
}

class PictureUploader extends Component {
  static defaultProps = {
    multiple: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      count: 0,
      files: [],
      rejectedFiles: [],
      dragging: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadPictures();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.token && this.props.token) ||
      prevProps.token !== this.props.token ||
      ((!prevProps.tokens || !this.props.tokens.length > 0) && prevProps.tokens && this.props.tokens.length > 0)
    )
      this.loadPictures();

    if (!this.props.onProcessingStart && !this.props.onProcessingFinish) return;

    const wasProcessing = isProcessing(prevState.files);
    const processingNow = isProcessing(this.state.files);

    if (!wasProcessing && processingNow) this.props.onProcessingStart && this.props.onProcessingStart(this.state.files);

    if (wasProcessing && !processingNow)
      this.props.onProcessingFinish && this.props.onProcessingFinish(this.state.files);
  }

  componentWillUnmount() {
    // Cleanup all previews from memory before destroying.
    // See https://react-dropzone.netlify.com/
    // Under: "Word of caution when working with previews"
    this.cleanAllPreviews();
  }

  loadPictures = async () => {
    const { token, tokens, endpoint, multiple } = this.props;

    if (!multiple && token) {
      this.setState({ loading: true });

      const { body, status } = await withToken(getPicture)({ token, endpoint });

      if (status === 200 && !body.deleted) {
        this.setState({
          count: this.state.files.length + 1,
          loading: false,
          files: [
            {
              id: this.state.count,
              status: 'uploaded',
              response: body,
            },
          ],
        });
      }
    }

    if (multiple && Array.isArray(tokens) && tokens.length > 0) {
      this.setState({ loading: true });

      for (let token in tokens) {
        const { body, status } = await withToken(getPicture)({
          token,
          endpoint,
        });

        if (status === 200 && !body.deleted) {
          this.setState({
            count: this.state.count + 1,
            files: [
              ...this.state.files,
              {
                id: this.state.count,
                status: 'uploaded',
                response: body,
              },
            ],
          });
        }
      }

      this.setState({ loading: false });
    }
  };

  onDrop = (accepted, rejectedFiles) => {
    const { files, count } = this.state;

    const newFiles = accepted.map((file, i) => ({
      id: count + i,
      status: 'uploading',
      response: {},
      data: file,
    }));

    this.setState({ rejectedFiles, dragging: false });

    if (this.props.multiple) {
      this.setState({
        count: count + newFiles.length,
        files: [...files, ...newFiles],
      });
    } else {
      this.cleanAllPreviews();
      this.setState({ files: newFiles });
    }

    newFiles.forEach(this.uploadFile);
  };

  uploadFile = async (file) => {
    const { body, status } = await withToken(loadFile)({
      endpoint: this.props.postResource || '/avatars',
      method: 'POST',
      name: 'file',
      files: file.data,
    });

    if (status === 201) {
      this.setFileStatus(file.id, 'uploaded', body);
    } else {
      this.setFileStatus(file.id, 'failed');
    }
  };

  setFileStatus(id, status = 'uploaded', response = {}) {
    const index = this.state.files.findIndex((file) => file.id === id);

    if (index === -1 || this.state.files[index].status === status) return;

    const newFiles = [
      ...this.state.files.slice(0, index),
      { ...this.state.files[index], status, response },
      ...this.state.files.slice(index + 1),
    ];

    this.setState({
      files: newFiles,
    });

    status === 'uploaded' && this.props.onChange && this.props.onChange(tokensFor(newFiles, this.props.multiple));
  }

  removeFile = (index) => {
    const file = this.state.files[index];

    if (file && file.data && file.data.preview) window.URL.revokeObjectURL(file.data.preview);

    const newFiles = [...this.state.files.slice(0, index), ...this.state.files.slice(index + 1)];

    this.setState({
      files: newFiles,
    });

    // In the background, remove the file...
    withToken(deletePicture)({
      token: file.response.token,
      endpoint: this.props.endpoint,
    });

    this.props.onChange && this.props.onChange(tokensFor(newFiles, this.props.multiple));
  };

  cleanAllPreviews = () => {
    const { files } = this.state;

    files.forEach((file) => file.data && file.data.preview && window.URL.revokeObjectURL(file.data.preview));
  };

  showDraggingCard = () => {
    this.setState({ dragging: true });
  };

  hideDraggingCard = () => {
    this.setState({ dragging: false });
  };

  get tokens() {
    if (!this.props.multiple) {
      const file = this.state.files.find((file) => file.status === 'uploaded');

      return file && file.response.token;
    }

    return tokensFor(this.state.files, this.props.multiple);
  }

  render() {
    const { files, rejectedFiles, dragging, loading } = this.state;
    const { multiple } = this.props;

    return (
      <PictureUploaderForm
        loading={loading}
        files={files}
        rejectedFiles={rejectedFiles}
        onDrop={this.onDrop}
        removeFile={this.removeFile}
        multiple={multiple}
        dragging={dragging}
        showDraggingCard={this.showDraggingCard}
        hideDraggingCard={this.hideDraggingCard}
      />
    );
  }
}

export default PictureUploader;
