import { ItemDisplay } from '@shared/displays';
import { camelCase, mapKeys } from 'lodash';
import React from 'react';

const cameliseKeyObject = (object) => {
  return mapKeys(object, (value, key) => camelCase(key));
};

export const DeviceInventoryRow = ({ item, inventory }) => {
  return (
    <tr>
      <td className="align-middle">
        <ItemDisplay item={cameliseKeyObject(item)} />
      </td>
      <td className="align-middle text-center no-pad-l no-pad-r">
        {cameliseKeyObject(inventory).availableQuantity + cameliseKeyObject(inventory).reservedQuantity || 0}
      </td>
      <td className="align-middle text-center">{item.consumable && item.unit ? item.unit.symbol : '-'}</td>
    </tr>
  );
};
