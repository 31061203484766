import { getDevices } from '@core/admin/devices';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { DevicesFilterModal } from './components/DevicesFilterModal';
import { DevicesResults } from './components/DevicesResults';

export class DevicesIndex extends SimpleFilter {
  searchFunction = getDevices;

  setDeviceStatus = () => {
    this.handleFilter(this.state.filters, true);
  };

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <Container fluid className="mt-4 mb-4">
        <Row>
          <Col xs="12" xl={{ size: 10, offset: 1 }}>
            <Row>
              <Col>
                <div className="content-actions float-right">
                  <Button size="sm" color="secondary" onClick={this.toggle}>
                    <FontAwesome name="filter" /> Filtros
                  </Button>
                  <Button tag={NavLink} size="sm" outline color="success" to="/admin/devices/new">
                    <FontAwesome name="plus" /> Crear dispositivo
                  </Button>
                </div>
                <h4 className="mb-4">
                  <FontAwesome name="mobile-alt" className="text-primary mr-2" />
                  Gestión de Dispositivos
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    {!items && (
                      <div className="text-center h1 text-info">
                        <Spinner />
                      </div>
                    )}

                    {items && (
                      <DevicesResults devices={items} pagination={pagination} setDeviceStatus={this.setDeviceStatus} />
                    )}

                    {(!items || items.length > 0) && paginationTemplate}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <DevicesFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </Container>
    );
  }
}
