import { getCategories } from '@core/building/categories';
import { deleteCategory, insertCategory } from '@core/building/items';
import { FontAwesome, Spinner } from '@shared';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, FormGroup, Label } from 'reactstrap';

const displayDelay = 5000;

class ItemCategoriesPresentation extends Component {
  state = {
    categories: [],
    loading: true,
    itemCategories: [],
    status: [],
  };

  async componentDidMount() {
    const { item } = this.props;

    this.setState({
      itemCategories: (item.categories || []).map((c) => c.id),
    });

    const { body: categories, status } = await getCategories({ paginate: false });

    if (status === 200) this.setState({ loading: false, categories });
  }

  toggleCategory = async (id) => {
    const { itemCategories } = this.state;
    const { item } = this.props;

    const index = itemCategories.findIndex((c) => c === id);

    this.setLoadingCategory(id);

    // We must insert the categorization
    if (index === -1) {
      const { status } = await insertCategory(item.barcode1, id);

      if (status === 201) {
        this.setState({
          itemCategories: [...itemCategories, id],
        });
      } else if (status === 422) {
        this.props.failedInsertion();
      }

      this.setLoadingCategory(id, 'success');
    } else {
      // We must destroy the categorization
      const { status } = await deleteCategory(item.barcode1, id);

      if (status === 204) {
        this.setState({
          itemCategories: [...itemCategories.slice(0, index), ...itemCategories.slice(index + 1)],
        });
      }

      this.setLoadingCategory(id, 'success');
    }
  };

  setLoadingCategory = (id, state = 'loading') => {
    const { status } = this.state;

    const index = status.findIndex((i) => i.id === id);

    // Insert with status
    if (index === -1) {
      this.setState({
        status: [...status, { id, state }],
      });
    } else {
      // Update
      this.setState({
        status: [...status.slice(0, index), { id, state }, ...status.slice(index + 1)],
      });
    }

    if (state !== 'loading')
      setTimeout(() => {
        this.removeLoadingCategory(id);
      }, displayDelay);
  };

  removeLoadingCategory = (id) => {
    const { status } = this.state;

    const index = status.findIndex((i) => i.id === id);

    if (index === -1) return;

    this.setState({
      status: [...status.slice(0, index), ...status.slice(index + 1)],
    });
  };

  categoriesList = (categories, itemCategories, status) =>
    categories.map((category, index) => {
      let controlCell = null;
      let result = status.find((i) => i.id === category.id);

      if (!!result) {
        switch (result.state) {
          case 'success':
            controlCell = (
              <span className="text-success">
                <FontAwesome name="check" />
              </span>
            );
            break;
          default:
            controlCell = (
              <span className="text-info">
                <Spinner />
              </span>
            );
        }
      }

      return (
        <FormGroup check key={index}>
          <Label check>
            <input
              type="checkbox"
              name={`categories[${category.id}]`}
              checked={itemCategories.includes(category.id)}
              onChange={() => this.toggleCategory(category.id)}
              disabled={!!result && result.state === 'loading'}
            />{' '}
            &nbsp;
            {category.name} {controlCell}
          </Label>
        </FormGroup>
      );
    });

  render() {
    const { loading, categories, itemCategories, status } = this.state;

    if (loading)
      return (
        <div className="h3 text-info text-center">
          <Spinner />
        </div>
      );

    return (
      <div>
        <p>Selecciona la o las categorías a las cuales este artículo pertenecerá:</p>

        {this.categoriesList(categories, itemCategories, status)}

        <div className="mt-4 text-center">
          <Button role="button" tag={NavLink} to="/organization/categories" color="primary">
            <FontAwesome name="sitemap" /> Gestionar Categorías
          </Button>
        </div>
      </div>
    );
  }
}

export const ItemCategories = connect(null, (dispatch) => ({
  failedInsertion: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Inserción fallida',
        text:
          'No se pudo insertar la categoría debido a que se produjo un error interno. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(ItemCategoriesPresentation);
