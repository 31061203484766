import AsyncSelect from 'react-select/async';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from '@store';
import { getAreas } from '@core/building/areas';
import { getAssets } from '@core/building/assets';
import { getItems } from '@core/building/items';
import { getLocations } from '@core/organization/locations';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';

class AssetsFilterPresentation extends SimpleFilterModal {
  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ item: { name }, barcode1: value, mark }) => ({
        label: `${name} - ${value} - ${mark}`,
        value,
      }));
    }

    return [];
  };

  findItems = async (inputValue) => {
    const { body, status } = await getItems({
      search: inputValue,
      consumable: false,
      serializer: 'simple',
    });

    if (status === 200) {
      return body.map(({ name: label, barcode1: value }) => ({ label, value }));
    }

    return [];
  };

  findAreas = async (inputValue) => {
    const { body, status } = await getAreas({ search: inputValue });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findLocations = async (inputValue) => {
    const { body, status } = await getLocations(this.props.currentOrganizationId, {
      search: inputValue,
    });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findMarks = async (inputValue) => {
    const { body, status } = await getAssets({ mark: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ mark: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  renderForm() {
    const { assetStates, assetHealthStates } = this.props;
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="multiple_barcodes">Activo</Label>
              <AsyncSelect
                name="multiple_barcodes"
                value={this.state['multiple_barcodes']}
                isClearable
                isSearchable
                placeholder="Buscar..."
                loadOptions={this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="states[{}]">Estados</Label>
              <Select
                name="states[{}]"
                value={this.state['states[{}]']}
                isClearable
                isSearchable
                isMulti
                closeMenuOnSelect={false}
                placeholder="Todos"
                options={assetStates}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="item">Artículos</Label>
              <AsyncSelect
                name="items[{}]"
                value={this.state['items[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findItems}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="states[{}]">Estados de Salud</Label>
              <Select
                name="health_states[{}]"
                value={this.state['health_states[{}]']}
                isClearable
                isSearchable
                isMulti
                closeMenuOnSelect={false}
                placeholder="Todos"
                options={assetHealthStates}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="locations[{}]">Geocercas</Label>
              <AsyncSelect
                name="locations[{}]"
                value={this.state['locations[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findLocations}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="rental">¿En Arriendo?</Label>
              <Select
                name="rental"
                value={this.state['rental']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="areas">Áreas</Label>
              <AsyncSelect
                name="areas"
                value={this.state['areas']}
                isClearable
                isSearchable
                placeholder="Buscar..."
                loadOptions={this.findAreas}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="expired">Expirado?</Label>
              <Select
                name="expired"
                value={this.state['expired']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="is_in_mro">Está en MRO?</Label>
              <Select
                name="is_in_mro"
                value={this.state['is_in_mro']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="repairable">¿Reparable?</Label>
              <Select
                name="repairable"
                value={this.state['repairable']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="audit">¿Auditado?</Label>
              <Select
                name="audit"
                value={this.state['audit']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

const enhance = compose(
  connect(({ session }) => {
    let organizationId = undefined;

    if (session.currentMembership && session.user) {
      const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

      if (membership) organizationId = membership.building.organization.id;
    }

    return { currentOrganizationId: organizationId };
  }),
  connect(({ appData: { asset_states: assetStates, asset_health_states: assetHealthStates } }) => ({
    assetStates: Object.keys(assetStates).map((state) => ({
      label: assetStates[state].name,
      value: state,
    })),
    assetHealthStates: Object.keys(assetHealthStates).map((state) => ({
      label: assetHealthStates[state].name,
      value: state,
    })),
  })),
);

export const AssetsFilterModal = enhance(AssetsFilterPresentation);
