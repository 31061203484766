import React from 'react';
import { reduxForm } from 'redux-form';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { compose, withHandlers } from 'recompose';

import { Field, Form, SubmissionError } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { createAttachment } from '@core/building/attachments';
import { ProcessingButton } from '@shared';
import { FileUploader } from '@shared/forms';
import { createAdminAttachment } from '@core/admin/attachments';

const validate = Validates(presence({ of: 'token' }), presence({ of: 'title' }));

const enhance = compose(
  withHandlers({
    onSubmit: (props) => async (data) => {
      const { resourceId, resourceType, isAdmin, onSuccessfulAttachment } = props;

      const payload = {
        resourceId,
        resourceType,
        ...data,
      };
      const { body, status } = isAdmin ? await createAdminAttachment(payload) : await createAttachment(payload);

      if (status === 201) {
        onSuccessfulAttachment && onSuccessfulAttachment(body);
      } else if (status === 422) {
        throw new SubmissionError(body.errors);
      }
    },
  }),
  reduxForm({
    form: 'attachmentForm',
    validate,
  }),
);

const UploaderComponent = ({ onChange, value }) => (
  <FileUploader multiple={false} token={value} onChange={onChange} endpoint={(token) => `/attachments/${token}`} />
);

const AttachmentUploader = ({
  pristine,
  invalid,
  submitting,
  instructions,
  showModal,
  toggle,
  handleSubmit,
  error,
}) => (
  <Modal isOpen={showModal} toggle={toggle} size="lg">
    <ModalHeader toggle={toggle}>Cargar archivo adjunto</ModalHeader>
    <Form error={error} noValidate onSubmit={handleSubmit}>
      <ModalBody className="m-2">
        {instructions && <div className="mb-4">{instructions}</div>}
        <Field
          name="token"
          label="Archivo a cargar"
          required
          component={UploaderComponent}
          help="Archivos permitidos: Excel, Word, PowerPoint, PDF, Imágenes, CSV y texto."
        />

        <Field
          name="title"
          label="Nombre del adjunto"
          required
          help="Debes especificar un título para este archivo adjunto."
        />

        <Field
          name="showSummary"
          label="Mostrar en resumen"
          type="checkbox"
          help="Al marcar esta opción, se mostrará el documento en el resumen. Solo puedes marcar un documento"
        />

        <Field
          name="description"
          type="textarea"
          rows="4"
          label="Descripción"
          help="Puedes especificar una descripción que aporta información adicional respecto al archivo cargado."
        />

        <div>Tu nombre y la fecha de carga quedarán asociados automáticamente al archivo cargado.</div>
      </ModalBody>
      <ModalFooter>
        <Button role="button" onClick={toggle}>
          Cancelar
        </Button>

        <ProcessingButton
          type="submit"
          color="primary"
          width="12em"
          disabled={pristine || invalid}
          processing={submitting}
        >
          Adjuntar archivo
        </ProcessingButton>
      </ModalFooter>
    </Form>
  </Modal>
);

export default enhance(AttachmentUploader);
