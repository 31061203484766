import { deletePicture, insertPicture } from '@core/building/items';
import { getItem } from '@core/building/items';
import { getEvents } from '@core/event/events';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { Loading } from '@shared';
import { PictureCrud } from '@shared';
import AttachmentManager from '@shared/attachment-manager';
import { BuildingLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import { ExpectedStockIndex } from 'organization/expected-stocks/ExpectedStockIndex';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { EditItem } from './components/EditItem';
import { ItemCategories } from './components/ItemCategories';
import { ItemHeader } from './components/ItemHeader';
import { ItemLayout } from './components/ItemLayout';
import { ItemSummary } from './components/ItemSummary';

class ItemPagePresentation extends Component {
  state = {
    notFound: false,
    loading: true,
    item: undefined,
    events: undefined,
    loadingStocks: false,
  };

  async componentDidMount() {
    const { barcode } = this.props.match.params;

    if (!barcode) {
      this.notFound();
      return;
    }

    const { body: item, status } = await getItem(barcode);

    if (status === 404) {
      this.notFound();
      return;
    } else if (status === 200) {
      this.setState({
        loading: false,
        item,
      });
    }

    const { body: events } = await getEvents({
      pp: 5,
      items: item.id,
    });
    if (status === 200) {
      this.setState({
        events,
      });
    }
  }

  petitionInsertPicture = async (barcode1, token) => {
    return await insertPicture(barcode1, token);
  };

  petitionDeletePicture = async (barcode1, id) => {
    return await deletePicture(barcode1, id);
  };

  setItem = (item) => {
    this.setState({
      item,
    });
  };

  notFound = () => {
    this.setState({ notFound: true });
    this.props.notFound();
  };

  updateItem = async () => {
    this.setState({
      loadingStocks: true,
    });
    const { body: item, status } = await getItem(this.state.item.barcode1);

    if (status === 404) {
      this.notFound();
      return;
    } else if (status === 200) {
      this.setState({
        loadingStocks: false,
        item,
      });
    }
  };

  render() {
    const { match } = this.props;
    const { loading, item, notFound, events, loadingStocks } = this.state;

    if (notFound) return <Redirect to="/organization/items" />;

    if (loading) return <Loading />;

    return (
      <BuildingLayout>
        <div className="container-fluid">
          <Row className="justify-content-center">
            <Col>
              {item && (
                <div>
                  <ItemHeader item={item} />

                  <ItemLayout item={item}>
                    <Switch>
                      <ProtectedRoute
                        exact
                        path={`${match.url}/`}
                        render={() => <ItemSummary item={item} events={events} />}
                      />
                      <ProtectedRoute
                        exact
                        path={`${match.url}/edit`}
                        render={() => <EditItem setItem={this.setItem} item={item} />}
                      />
                      <ProtectedRoute
                        exact
                        path={`${match.url}/categories`}
                        render={() => <ItemCategories item={item} />}
                      />
                      <ProtectedRoute
                        exact
                        path={`${match.url}/pictures`}
                        render={() => (
                          <PictureCrud
                            pictures={item.pictures}
                            deletePicture={this.petitionDeletePicture}
                            insertPicture={this.petitionInsertPicture}
                            parentElement={item}
                          />
                        )}
                      />
                      <ProtectedRoute
                        path={`${match.url}/attachments`}
                        render={() => <AttachmentManager resourceId={item.barcode1} resourceType={'items'} />}
                      />
                      <ProtectedRoute
                        path={`${match.url}/expected_stocks`}
                        render={() => (
                          <ExpectedStockIndex
                            expectedStocks={item?.expectedStocks}
                            updateItem={this.updateItem}
                            loadingStocks={loadingStocks}
                          />
                        )}
                      />
                    </Switch>
                  </ItemLayout>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </BuildingLayout>
    );
  }
}

export const ItemPage = connect(null, (dispatch) => ({
  notFound: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Artículo no encontrado',
        text:
          'El artículo buscado no existe o no se encuentra disponible. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(ItemPagePresentation);
