import { deletePicture, getAsset, insertPicture } from '@core/building/assets';
import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';
import { Barcode, FontAwesome, Loading, PictureCrud, PictureDisplay, ShowForRoles } from '@shared';
import AttachmentManager from '@shared/attachment-manager';
import { BuildingLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { NavLink as Link, Redirect } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Media, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { branch, compose, lifecycle, renderComponent, withProps, withState } from 'recompose';
import { AssetSubscriptionButton } from './components/asset-subscription';
import { AssetEvents } from './components/AssetEvents';
import { AssetReassembly } from './components/AssetReassembly';
import { AssetSummary } from './components/AssetSummary';
import { AssetTags } from './components/AssetTags';
import AssetTelemetry from './components/AssetTelemetry';
import AssetValidityPeriods from './components/AssetValidityPeriod';
import { EditAsset } from './components/EditAsset';
import { ReadoutsMap } from './components/map/readouts-map';

const AssetTitle = ({ asset }) => (
  <Card>
    <CardBody>
      <Media className="align-items-center">
        <Media left className="mr-3">
          <PictureDisplay
            picture={asset.defaultPicture.file || asset.item.defaultPicture.file}
            pictureSize="icon"
            alt={asset.item.name}
            width="6rem"
            height="4rem"
          />
        </Media>
        <Media body>
          <div className="small text-muted">Activo</div>
          <h4>{asset.item.name}</h4>
          <div>
            <kbd>{asset.barcode1}</kbd>
          </div>
        </Media>

        <Media right className="ml-3">
          <Barcode code={asset.barcode1} className="mr-3" style={{ height: '4rem' }} />
          <ShowForRoles excluded={['guest']}>
            <AssetSubscriptionButton assetId={asset.id}></AssetSubscriptionButton>
          </ShowForRoles>
        </Media>
      </Media>
    </CardBody>
  </Card>
);

const enhance = compose(
  connect(null, (dispatch) => ({
    notFoundNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'No encontrado',
          text:
            'No se encontró el activo buscado pues no existe o no se encuentra disponible. Si los problemas persisten, por favor contacta a un administrador.',
          color: 'danger',
        }),
      ),
  })),
  withProps(({ match: { params: { barcode } } }) => ({ barcode })),
  withState('asset', 'setAsset', undefined),
  withState('notFound', 'setNotFound', false),
  lifecycle({
    async componentDidMount() {
      const { barcode, setAsset } = this.props;
      const { body: asset, status: assetStatus } = await getAsset(barcode);

      if (assetStatus === 200) {
        setAsset(asset);
      } else if (assetStatus === 404) {
        const { notFoundNotification, setNotFound } = this.props;

        notFoundNotification();
        setNotFound(true);
      }
    },
    async componentWillReceiveProps(nextProps) {
      const { barcode, setAsset } = this.props;
      if (barcode !== nextProps.barcode) {
        const { body: asset, status: assetStatus } = await getAsset(nextProps.barcode);

        if (assetStatus === 200) {
          setAsset(asset);
        } else if (assetStatus === 404) {
          const { notFoundNotification, setNotFound } = this.props;

          notFoundNotification();
          setNotFound(true);
        }
      }
    },
  }),
  branch(
    ({ notFound }) => notFound,
    renderComponent(() => <Redirect to={`/building/assets`} />),
  ),
  branch(({ asset }) => !asset, renderComponent(Loading)),
);

const petitionInsertPicture = async (barcode1, token) => {
  return await insertPicture(barcode1, token);
};

const petitionDeletePicture = async (barcode1, id) => {
  return await deletePicture(barcode1, id);
};

const AssetPagePresentation = ({ asset, match, movements, setAsset }) => (
  <BuildingLayout>
    <Row>
      <Col>
        <AssetTitle asset={asset} />
        <Card className="mt-4">
          <CardHeader>
            <Nav tabs className="card-header-tabs">
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}`}>
                  <FontAwesome name="list-alt" /> Resumen
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/attachments`}>
                  <FontAwesome name="file-upload" /> Adjuntos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/pictures`}>
                  <FontAwesome name="file-image" /> Imágenes
                </NavLink>
              </NavItem>
              <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding']}>
                <NavItem>
                  <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/edit`}>
                    <FontAwesome name="pencil-alt" /> Editar
                  </NavLink>
                </NavItem>
              </ShowForRoles>
              <ShowForRoles accepted={['organization_admin']}>
                <NavItem>
                  <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/tags`}>
                    <FontAwesome name="tags" /> Tags
                  </NavLink>
                </NavItem>
              </ShowForRoles>
              {asset.hasGps && (
                <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding']}>
                  <NavItem>
                    <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/gps`}>
                      <FontAwesome name="map-marker-alt" /> Ubicación
                    </NavLink>
                  </NavItem>
                </ShowForRoles>
              )}
              {asset.item.telemetric && (
                <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding']}>
                  <NavItem>
                    <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/telemetries`}>
                      <FontAwesome name="tachometer-alt" /> Telemetría
                    </NavLink>
                  </NavItem>
                </ShowForRoles>
              )}
              {asset.item.active && (
                <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'onboarding']}>
                  <NavItem>
                    <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/reassembly`}>
                      <FontAwesome name="barcode" /> Reensamblar
                    </NavLink>
                  </NavItem>
                </ShowForRoles>
              )}
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/asset_events`}>
                  <FontAwesome name="list-alt" /> Eventos
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink role="button" exact tag={Link} to={`/building/assets/${asset.barcode1}/validity_periods`}>
                  <FontAwesome name="calendar-alt" /> Vigencias
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody>
            <Switch>
              <ProtectedRoute
                exact
                path={`${match.url}/edit`}
                render={(props) => <EditAsset setAsset={setAsset} asset={asset} {...props} />}
                barcode={asset.barcode1}
              />
              <ProtectedRoute
                exact
                path={`${match.url}/`}
                render={(props) => <AssetSummary asset={asset} {...props} />}
                barcode={asset.barcode1}
              />
              <ProtectedRoute
                path={`${match.url}/attachments`}
                render={() => <AttachmentManager resourceId={asset.barcode1} resourceType={'assets'} />}
                barcode={asset.barcode1}
              />
              <ProtectedRoute
                exact
                path={`${match.url}/tags`}
                render={(props) => <AssetTags asset={asset} {...props} />}
                barcode={asset.barcode1}
              />
              <ProtectedRoute
                exact
                path={`${match.url}/gps`}
                render={(props) => <ReadoutsMap asset={asset} {...props} />}
                barcode={asset.barcode1}
              />
              <ProtectedRoute
                exact
                path={`${match.url}/telemetries`}
                render={(props) => <AssetTelemetry asset={asset} {...props} />}
                barcode={asset.barcode1}
              />
              {asset.item.active && (
                <ProtectedRoute
                  exact
                  path={`${match.url}/reassembly`}
                  render={(props) => <AssetReassembly setAsset={setAsset} asset={asset} {...props} />}
                  barcode={asset.barcode1}
                />
              )}
              <ProtectedRoute
                exact
                path={`${match.url}/asset_events`}
                render={(props) => <AssetEvents asset={asset} {...props} />}
                barcode={asset.barcode1}
              />
              <ProtectedRoute
                exact
                path={`${match.url}/pictures`}
                barcode={asset.barcode1}
                render={() => (
                  <PictureCrud
                    pictures={asset.pictures}
                    deletePicture={petitionDeletePicture}
                    insertPicture={petitionInsertPicture}
                    parentElement={asset}
                  />
                )}
              />
              <ProtectedRoute
                exact
                path={`${match.url}/validity_periods`}
                barcode={asset.barcode1}
                render={(props) => <AssetValidityPeriods asset={asset} {...props} />}
              />
            </Switch>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </BuildingLayout>
);

export const AssetPage = enhance(AssetPagePresentation);
