import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'kind' }), presence({ of: 'name' }));

class KitFormPresentation extends Component {
  render() {
    const { handleSubmit, invalid, submitting, pristine, error } = this.props;

    return (
      <Form error={error} noValidate onSubmit={handleSubmit}>
        <h4 className="mb-3">Información básica</h4>
        <Row>
          <Col>
            <Field name="name" label="Nombre" required />
          </Col>
          <Col>
            <Field
              name="kind"
              type="select"
              label="Tipo de kit"
              options={{ '': '', temporary: 'Temporal', permanent: 'Permanente' }}
              required
            />
          </Col>
        </Row>

        <div className="text-center">
          <ProcessingButton
            type="submit"
            role="button"
            outline
            color="primary"
            width="10em"
            disabled={pristine || invalid}
            processing={submitting}
          >
            Guardar
          </ProcessingButton>
        </div>
      </Form>
    );
  }
}

export const KitForm = compose(
  reduxForm({
    form: 'kitForm',
    validate,
  }),
)(KitFormPresentation);
