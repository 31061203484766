import React from 'react';
import { Card, CardBody, CardHeader, Row, Col } from 'reactstrap';

import { ShowForRoles } from '@shared';

import LatestPosts from '@shared/pages/LatestPosts';

import { EventsIndex } from 'events/EventsIndex';

const Home = () => (
  <div className="layout-container container-fluid mt-4">
    <Row className="justify-content-center">
      <Col xs={12} sm={12} md={8} lg={7}>
        <Card className="mb-4">
          <CardBody>
            <h4 className="mb-3">¡Bienvenido a Alliot Cloud!</h4>
            <div>
              Continuamente estamos agregando y optimizando las funcionalidades para mejorar tu experiencia de
              administración y gestión de activos.
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xs={12} sm={12} md={4} lg={3}>
        <Card>
          <CardHeader>Últimas novedades en Alliot Cloud</CardHeader>
          <LatestPosts />
        </Card>
        <div className="mt-3 small text-muted">
          &copy; Alliot. Todos los derechos reservados.
          <ShowForRoles rootAccepted={['root']}>
            <div>
              {process.env.REACT_APP_BUILD_NUMBER && <div>Build #: {process.env.REACT_APP_BUILD_NUMBER}</div>}

              {process.env.REACT_APP_VERSION && <div>{process.env.REACT_APP_VERSION}</div>}
            </div>
          </ShowForRoles>
        </div>
      </Col>
    </Row>
    <ShowForRoles
      accepted={['root', 'organization_admin', 'warehouse_admin', 'assistant', 'client', 'onboarding', 'guest']}
    >
      <EventsIndex />
    </ShowForRoles>
  </div>
);

export default Home;
