import { handleAuth, withMembership, apiFetch } from '../http';

export const getRestrictedItems = (userId) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${userId}/restricted_items`,
      method: 'GET',
    }),
  );

export const createRestrictedItem = (userId, { ...restrictedItem }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${userId}/restricted_items`,
      method: 'POST',
      body: {
        restrictedItem,
      },
    }),
  );

export const editRestrictedItem = (userId, { ...restrictedItem }) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${userId}/restricted_items/${restrictedItem.id}`,
      method: 'PUT',
      body: {
        restrictedItem,
      },
    }),
  );

export const deleteRestrictedItem = (userId, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/users/${userId}/restricted_items/${id}`,
      method: 'DELETE',
    }),
  );
