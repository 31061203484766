import { createPartner } from '@core/building/partners';
import { FontAwesome } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { compose, withHandlers, withState } from 'recompose';
import { SubmissionError } from 'redux-form';
import { PartnerForm } from './components/PartnerForm';

const enhance = compose(
  connect(null, (dispatch) => ({
    createNotification: (formData) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Nuevo socio de negocio creado',
          text: `El socio de negocio "${formData.name}" ha sido creado exitosamente.`,
          color: 'success',
          duration: 10000,
        }),
      ),
  })),
  withState('loading', 'setLoading', 'waiting'),
  withState('redirectTo', 'setRedirectTo', undefined),
  withHandlers({
    handleForm: ({ createNotification, setLoading, setRedirectTo }) => async (data) => {
      setLoading('loading');
      const { body, status } = await createPartner(data);

      if (status === 201) {
        setLoading('success');
        setRedirectTo(`/building/partners/${body.id}`);
        createNotification(body);
      } else if (status === 422) {
        setLoading('waiting');
        throw new SubmissionError(body.errors);
      }
    },
  }),
);

const NewPartnerPresentation = ({ handleForm, loading, redirectTo }) =>
  redirectTo ? (
    <Redirect to={redirectTo} />
  ) : (
    <BuildingLayout>
      <SimpleContainerLayout
        title={
          <span>
            <FontAwesome name="users" className="text-primary mr-2" /> Nuevo socio de negocio
          </span>
        }
      >
        <PartnerForm loading={loading === 'loading'} onSubmit={handleForm} />
      </SimpleContainerLayout>
    </BuildingLayout>
  );

export const NewPartner = enhance(NewPartnerPresentation);
