import { getCostAccounts } from '@core/building/cost-accounts';
import { FontAwesome, Pagination, ShowForRoles, Spinner } from '@shared';
import { BuildingLayout } from '@shared/layouts';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { CostAccountsFilterModal } from './components/CostAccountsFilterModal';
import { CostAccountsResults } from './components/CostAccountsResults';

export class CostAccountsIndex extends SimpleFilter {
  searchFunction = getCostAccounts;

  render() {
    const { items, filters, pagination, modalOpen } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <ShowForRoles accepted={['organization_admin']}>
                <Button tag={NavLink} size="sm" outline color="success" to="/building/cost-accounts/new">
                  <FontAwesome name="plus" /> Nuevo Centro de Costo
                </Button>
              </ShowForRoles>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="cogs" className="mr-2 text-primary" /> Gestión de Centro de Costos
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <CostAccountsResults costAccounts={items} pagination={pagination} searchFunction={this.getItems} />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CostAccountsFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
