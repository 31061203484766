import noImage from '@shared/assets/no-image.jpg';
import React from 'react';
import { Img } from 'react-image';
import { Spinner } from '../';

const PictureDisplay = ({
  picture,
  pictureSize = 'medium',
  alt = 'Sin título',
  width = '9rem',
  height = '6rem',
  inline = false,
  className = '',
  containerStyle = {},
  imgClassName = '',
}) => {
  const component = (
    <div
      className={`d-flex align-items-center justify-content-center rounded ${className}`}
      style={{
        width,
        height,
        overflow: 'hidden',
        ...containerStyle,
      }}
    >
      <Img
        className={`asset-cloud-image ${imgClassName}`}
        src={(picture && picture.file && picture.file[pictureSize].url) || (picture && picture.url) || noImage}
        alt={alt}
        loader={<Spinner />}
        unloader={<img className={`asset-cloud-image ${imgClassName}`} alt={alt} src={noImage} />}
      />
    </div>
  );

  if (inline)
    return (
      <div
        className="d-inline-block m-1"
        style={{
          width,
          height,
        }}
      >
        {component}
      </div>
    );
  else return component;
};

export default PictureDisplay;
