import { Spinner } from '@shared';
import noImage from '@shared/assets/no-image.jpg';
import React from 'react';
import { Img } from 'react-image';

const OrganizationCard = ({ url, alt = 'Sin título', size, width, height }) => (
  <div className="card-wrapper">
    <div className="organization-card" style={{ width: width || size, height: height || size }}>
      <Img
        className="asset-cloud-image"
        src={url || noImage}
        alt={alt}
        loader={<Spinner />}
        unloader={<img className="asset-cloud-image" alt={alt} src={noImage} />}
      />
    </div>
  </div>
);

export default OrganizationCard;
