import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const searchInventory = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory${mountQuery(query)}`,
      method: 'GET',
      parseResponse: false,
    }),
  );

export const searchInventoryByBuilding = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory/building${mountQuery(query)}`,
      method: 'GET',
      parseResponse: false,
    }),
  );

export const getInventory = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/inventory/${id}`,
      method: 'GET',
    }),
  );
