import { apiFetch, handleAuth, mountQuery, withMembership, withToken } from '../http';

export const getPartner = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/partners/${id}`,
      method: 'GET',
    }),
  );

export const getPartners = (query) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/partners${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const getAllPartners = (query) =>
  withToken(
    withToken(apiFetch)({
      endpoint: `admin/partners${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createPartner = (partner) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/partners`,
      method: 'POST',
      body: {
        partner,
      },
    }),
  );

export const updatePartner = (id, partner) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/partners/${id}`,
      method: 'PUT',
      body: {
        partner,
      },
    }),
  );

export const deletePartner = (id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/partners/${id}`,
      method: 'DELETE',
    }),
  );
