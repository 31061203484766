import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { AreaRow } from './AreaRow';

export const AreasResults = ({ areas, pagination, deleteArea, processing }) => {
  return (
    <div>
      <div className="small mb-4">
        <strong>{pagination.count}</strong> {pluralize(pagination.count, 'área encontrada', 'areas encontradas')}.
        Mostrando <strong>{pagination.per}</strong> áreas por página.
      </div>

      <Table>
        <colgroup>
          <col style={{ width: '80px' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: '35%' }} />
          <col style={{ width: '15%' }} />
        </colgroup>
        <thead>
          <tr>
            <th className="text-center">#</th>
            <th className="text-left">Nombre</th>
            <th className="text-center">Ubicacion</th>
            <th className="text-center" />
          </tr>
        </thead>
        <tbody>
          {areas.map((area, index) => (
            <AreaRow area={area} key={index} onDelete={() => deleteArea(index)} processing={processing === index} />
          ))}

          {areas.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center mt-3 mb-3">
                <em>No se encontraron áreas.</em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
