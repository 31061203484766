import { handleAuth, withMembership, apiFetch } from '../http';

export const getDeviceStocks = (deviceBarcode) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices/${deviceBarcode}/device_expected_stocks`,
      method: 'GET',
    }),
  );

export const createDeviceStock = (deviceBarcode, deviceExpectedStock) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices/${deviceBarcode}/device_expected_stocks`,
      method: 'POST',
      body: {
        deviceExpectedStock,
      },
    }),
  );

export const updateDeviceStock = (deviceBarcode, id, deviceExpectedStock) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices/${deviceBarcode}/device_expected_stocks/${id}`,
      method: 'PUT',
      body: {
        deviceExpectedStock,
      },
    }),
  );

export const deleteDeviceStock = (deviceBarcode, id) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/devices/${deviceBarcode}/device_expected_stocks/${id}`,
      method: 'DELETE',
    }),
  );
