import { FontAwesome } from '@shared/';
import React from 'react';
import { Badge, Media } from 'reactstrap';

export const EventBuilding = ({ building }) => (
  <Media className="align-middle small">
    <Media body>
      {building.name && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="industry" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">{building.name}</span>
        </div>
      )}
      {building.organization && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="university" color="info" className="mr-1" />
          <code>{building.organization.name}</code>
        </div>
      )}
    </Media>
  </Media>
);

export const EventBuildingModal = ({ building }) => (
  <Media>
    <Media body className="mr-1">
      {building.name && (
        <div className="w-100 text-overflow mt-1">
          <FontAwesome name="industry" color="info" className="mr-1" />
          <span className="asset-name font-weight-bold">{building.name}</span>
        </div>
      )}
      {building.organization && (
        <div className="w-100 text-overflow mt-1">
          <span>
            <FontAwesome name="university" color="info" className="mr-1" />
            Organización:
          </span>
          <code>{building.organization.name}</code>
        </div>
      )}
      {building.active && (
        <div className="w-100 text-overflow mt-1">
          <Badge color={building.active ? 'success' : 'danger'} pill>
            {building.active ? 'Activa' : 'Inactiva'}
          </Badge>
          {building.active}
        </div>
      )}
    </Media>
  </Media>
);
