import { handleAuth, withToken, apiFetch, mountQuery } from '../http';

export const getBuilding = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/buildings/${id}`,
      method: 'GET',
    }),
  );

export const getBuildingWithoutParse = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/buildings/${id}`,
      method: 'GET',
      parseResponse: false,
    }),
  );

export const getBuildings = (query = {}) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/buildings${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createBuilding = ({ organizationId, ...rest }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/organizations/${organizationId}/buildings`,
      method: 'POST',
      body: rest,
      parseBody: false,
    }),
  );

export const updateBuilding = ({ id, ...rest }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/buildings/${id}`,
      method: 'PUT',
      body: rest,
      parseBody: false,
    }),
  );

export const getBuildingTypes = () =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/buildings/types`,
      method: 'GET',
    }),
  );

export const getBuildingType = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/buildings/types/${id}`,
      method: 'GET',
    }),
  );
