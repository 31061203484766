import { handleAuth, withToken, apiFetch, mountQuery } from '../http';

export const getBatches = (query) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/assembly_batches${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createBatch = (body) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: '/admin/assembly_batches',
      method: 'POST',
      body,
    }),
  );
