import { apiFetch, handleAuth, mountQuery, withMembership } from '../http';

export const getInventory = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/reports/inventory`,
      method: 'GET',
      parseResponse: false,
    }),
  );

export const getMaintenance = (query: Object) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/reports/maintenance${mountQuery(query)}`,
      method: 'GET',
      parseResponse: false,
    }),
  );

export const getUsers = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/reports/users`,
      method: 'GET',
    }),
  );

export const getConsumables = () =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/reports/consumables`,
      method: 'GET',
    }),
  );

export const getConsumablesByDate = (query: Object) =>
  handleAuth(
    withMembership(apiFetch)({
      endpoint: `/reports/consumables_by_date${mountQuery(query)}`,
      method: 'GET',
    }),
  );
