import { format, subMonths } from 'date-fns';
import { groupBy, orderBy, sumBy } from 'lodash';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Form, FormGroup, Label } from 'reactstrap';

import { getConsumablesByDate } from '../../../@core/building/reports';
import { Highcharts } from '../../../@shared/charts';
import { DateSelector } from '../../../@shared/forms';
import Chart from './Chart';

const DATE_FORMAT = 'yyyy-MM-dd';

type CustomInputProps = {
  onClick?: Function,
  value?: string,
};

class ExampleCustomInput extends React.Component<CustomInputProps> {
  render() {
    return (
      <Button color="primary" onClick={this.props.onClick} size="sm">
        {this.props.value}
      </Button>
    );
  }
}

type State = {
  startDate: Date,
  endDate: Date,
  today: Date,
  data: Object[],
};

const initialSerie = {
  A: {
    name: 'A',
    y: 0,
    drilldown: 'A',
  },
  B: {
    name: 'B',
    y: 0,
    drilldown: 'B',
  },
  C: {
    name: 'C',
    y: 0,
    drilldown: 'C',
  },
  Otros: {
    name: 'Otros',
    y: 0,
    drilldown: 'Otros',
  },
};

const initialDrilldown = {
  A: {
    name: 'A',
    id: 'A',
    data: [],
  },
  B: {
    name: 'B',
    id: 'B',
    data: [],
  },
  C: {
    name: 'C',
    id: 'C',
    data: [],
  },
  Otros: {
    name: 'Otros',
    id: 'Otros',
    data: [],
  },
};

const getChartOptions = (title, data) => {
  const groups = groupBy(data, (obj) => obj.item.criticality || 'Otros');

  let drilldown = initialDrilldown;
  let series = initialSerie;

  Object.keys(groups).forEach((key) => {
    drilldown = {
      ...drilldown,
      [key]: {
        ...drilldown[key],
        data: groups[key].map((obj) => [obj.item.name, obj.movementsResult.withdrawal]),
      },
    };
    series = {
      ...series,
      [key]: {
        ...series[key],
        y: sumBy(groups[key], 'movementsResult.withdrawal'),
      },
    };
  });

  const options = {
    chart: {
      zoomType: 'x',
      type: 'column',
    },
    title: {
      text: title,
    },
    credits: {
      enabled: true,
      href: 'http://www.alliot.cloud',
      text: 'alliot.cloud',
    },
    xAxis: {
      type: 'category',
    },
    yAxis: {
      title: {
        text: 'Cantidad de Movimientos',
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y}',
        },
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name} {point.key}</span><br>',
      pointFormat: '<span>Total</span>: <b>{point.y}</b><br/>',
    },
    series: [
      {
        name: 'Criticidad',
        colorByPoint: true,
        data: Object.values(series),
      },
    ],
    drilldown: {
      series: Object.values(drilldown),
    },
  };
  return options;
};

class Consumable extends React.Component<*, State> {
  constructor(props: any) {
    super(props);

    const today = new Date();
    this.state = {
      startDate: subMonths(today, 1),
      endDate: today,
      today,
      data: [],
    };
  }

  async componentDidMount() {
    await this.fetchData();
  }

  handleResponse = (response: Response) => {
    const { status, body } = response;

    const data = orderBy(body || [], 'movementsResult.withdrawal', 'desc');

    if (status === 200) {
      this.setState({
        data,
      });
    }

    this.forceUpdate();
  };

  fetchData = async () => {
    const { startDate, endDate } = this.state;

    const response = await getConsumablesByDate({
      from: format(startDate, DATE_FORMAT),
      to: format(endDate, DATE_FORMAT),
    });

    this.handleResponse(response);
  };

  handleChangeStart = (date: Date) => {
    this.setState(
      {
        startDate: date,
      },
      async () => {
        await this.fetchData();
      },
    );
  };

  handleChangeEnd = (date: Date) => {
    this.setState(
      {
        endDate: date,
      },
      async () => {
        await this.fetchData();
      },
    );
  };

  render() {
    const { title } = this.props;
    const { startDate, endDate, today, data } = this.state;

    return (
      <Card>
        <CardHeader className="chart-title-container">
          <span className="float-right">
            <span style={{ display: 'flex', verticalAlign: 'middle' }}>
              <Form inline>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label className="mr-sm-2">Desde</Label>
                  <DateSelector
                    customInput={<ExampleCustomInput />}
                    selected={startDate}
                    selectsStart
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={this.handleChangeStart}
                  />
                </FormGroup>
                <FormGroup className="mb-2 mb-sm-0">
                  <Label className="mr-sm-2">Hasta</Label>
                  <DateSelector
                    customInput={<ExampleCustomInput />}
                    selected={endDate}
                    selectsEnd
                    showYearDropdown
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={today}
                    onChange={this.handleChangeEnd}
                  />
                </FormGroup>
              </Form>
            </span>
          </span>
          <h4>Consumos por Criticidad</h4>
        </CardHeader>
        <CardBody className="p-2" style={{ minHeight: '416px' }}>
          <Chart options={getChartOptions(title, data)} highcharts={Highcharts} />
        </CardBody>
      </Card>
    );
  }
}

export default Consumable;
