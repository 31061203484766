import React from 'react';
import { Switch } from 'react-router-dom';
import { ProtectedRoute } from '../../@core/routes';
import { connect } from '../../@store';
import PasswordForm from './Password';
import ProfileForm from './ProfileForm';
// import Reservations from './Reservations';
import { SessionsView } from './sessions/sessions-view';
import { TaskView } from './tasks/TaskView';
import { UserLayout } from './UserLayout';
import { currentMembership } from '@store/session';
import { RestrictedItems } from './restricted-items/RestrictedItems';

type Props = {
  user: Object,
  match: Object,
};

const routes = [
  {
    text: 'General',
    link: 'profile',
    icon: 'address-card',
    component: ProfileForm,
  },
  {
    text: 'Contraseña',
    link: 'password',
    icon: 'unlock-alt',
    component: PasswordForm,
  },
  {
    text: 'Sesiones activas',
    link: 'sessions',
    icon: 'desktop',
    component: SessionsView,
  },
];

class Profile extends React.PureComponent<Props, *> {
  render() {
    const { session, match } = this.props;
    const membership = currentMembership(session);
    let r = [...routes];
    console.log(membership, membership?.role);

    if (session.currentMembership && membership?.role !== 'guest') {
      r.push(
        // {
        //   text: 'Mis reservas',
        //   link: 'reservations',
        //   icon: 'calendar-check',
        //   component: Reservations,
        //   exact: false,
        // },
        {
          text: 'Mis tareas',
          link: 'tasks',
          icon: 'tasks',
          component: TaskView,
          exact: false,
        },
        {
          text: 'Restricciones',
          link: 'restricted_items',
          icon: 'ban',
          component: RestrictedItems,
          exact: true,
        },
      );
    }

    return (
      <UserLayout isAdmin={false} user={session.user} routes={r} match={match}>
        <Switch>
          {r
            .filter((route) => route.link !== 'restricted_items')
            .map((route, index) => {
              const path = `${match.url}/${route.link}`;
              return <ProtectedRoute key={index} exact={route.exact} path={path} component={route.component} />;
            })}
          <ProtectedRoute
            exact
            path={`${match.url}/restricted_items`}
            render={(props) => <RestrictedItems user={session.user} {...props} />}
          />
        </Switch>
      </UserLayout>
    );
  }
}

export default connect((state) => ({ session: state.session }))(Profile);
