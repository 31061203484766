import React from 'react';

import { ProtectedRoute, SwitchWithFallback as Switch } from '@core/routes';

import {
  RepairIndex,
  MaintenanceIndex,
  EvaluationIndex,
  PreservationIndex,
  CertificationIndex,
  AssuranceIndex,
  DiscardIndex,
} from './ListOfMaintenance';
import { CreateMaintenance } from './CreateMaintenance';
import { EventsIndex } from './EventsIndex';

const MaintenancesRoutes = ({ match }) => (
  <Switch>
    <ProtectedRoute
      exact
      path={`${match.url}/maintenances`}
      component={MaintenanceIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/repairs`}
      component={RepairIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/evaluations`}
      component={EvaluationIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/preservations`}
      component={PreservationIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/certifications`}
      component={CertificationIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/assurances`}
      component={AssuranceIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/discards`}
      component={DiscardIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/new`}
      component={CreateMaintenance}
      acceptedMemberships={['organization_admin', 'warehouse_admin']}
    />
    <ProtectedRoute
      exact
      path={`${match.url}/events`}
      component={EventsIndex}
      acceptedMemberships={['organization_admin', 'warehouse_admin', 'guest']}
    />
  </Switch>
);

export default MaintenancesRoutes;
