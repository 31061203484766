import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome, Spinner } from '@shared';
import { SimpleAssetDisplay } from '@shared/displays/AssetDisplay';
import { TagDisplay } from '@shared/displays/TagDisplay';
import React from 'react';
import { Button, Media } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';

const enhance = compose(
  withState('loading', 'setLoading', false),
  withHandlers({
    toggleStatus: ({ setLoading, toggleSimcard }) => async () => {
      setLoading(true);
      await toggleSimcard();
      setLoading(false);
    },
  }),
);

const STATUS = {
  active: 'Activo',
  preactive: 'Pre Activado',
  suspended: 'Suspendido',
  inactive: 'Inactivo',
  terminated: 'Terminado',
};

const SimCardRowPresentation = ({
  simcard,
  // isOpen,
  // openConfirmBox,
  // closeConfirmBox,
  // toogleConfirmBox,
  // remove,
  toggleStatus,
  loading,
}) => {
  return (
    <tr id={`sc-${simcard.id}`}>
      <td className="align-middle small">
        <Media body className={`pl-2 status-border status-border-${simcard.status}`}>
          <kbd>{simcard.iccid}</kbd>
          <div className="mt-1">
            <div className="w-100">
              <span className="text-muted mr-1">
                <FontAwesome name="caret-right" className="mr-1" />
                MSISDN: {simcard.msisdn}
              </span>
            </div>
            <div className="w-100">
              <span className="text-muted mr-1">
                <FontAwesome name="caret-right" className="mr-1" />
                Producto: {simcard.localProductName || '-'}
              </span>
            </div>
            <div className="w-100">
              <span className="text-muted mr-1">
                <FontAwesome name="caret-right" className="mr-1" />
                Estado: {STATUS[simcard.status]}
              </span>
            </div>
          </div>
        </Media>
      </td>
      <td className="align-middle small">
        <Media body>
          <div className="mt-1">
            <div className="w-100">
              <span className="text-muted mr-1">
                <FontAwesome name="check-circle" className="mr-1" />
                Activación:
              </span>
              {fromIso8601(simcard.activationDate)}
            </div>
            <div className="w-100">
              <span className="text-muted mr-1">
                <FontAwesome name="clock" className="mr-1" />
                Ult. Conexión:
              </span>{' '}
              {simcard.endTime ? fromIso8601(simcard.endTime) : '-'}
            </div>
          </div>
        </Media>
      </td>
      <td className="align-middle small">
        {simcard.asset && <SimpleAssetDisplay asset={simcard.asset} showStatus={false} showLink={false} />}
      </td>
      <td className="align-middle small">{simcard.tag && <TagDisplay tag={simcard.tag} showStatus={false} />}</td>
      <td className="align-middle text-right">
        <Button
          role="button"
          size="sm"
          color={simcard.status === 'active' ? 'warning' : 'success'}
          onClick={toggleStatus}
          title={simcard.status === 'active' ? 'Suspender' : 'Activar'}
          disabled={loading}
        >
          {loading ? <Spinner /> : <FontAwesome name="power-off" />}
        </Button>
      </td>
    </tr>
  );
};

export const SimCardRow = enhance(SimCardRowPresentation);
