import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared/';
import { AssetDisplay, ItemDisplay } from '@shared/displays';
import React, { useState } from 'react';
import {
  Button,
  Badge,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  Row,
  Input,
  Label,
} from 'reactstrap';
import { compose } from 'recompose';
import { connect, notificationsGenerators } from '@store';
import { updatePickUp } from '@core/building/pick-ups';
import { DebugCard, FileIcon, Spinner } from '@shared/index';
import { AreaSelector, BuildingSelector, FileUploader } from '@shared/forms';
import { getBuilding, getBuildingsOrganization } from '@core/building/buildings';
import { getAreaByBuilding, getAreasByBuilding } from '@core/building/areas';
import { fileInfoFor } from '@core/utils/mime-types';
import noImage from '@shared/assets/no-image.jpg';
import { Img } from 'react-image';

export const PickUpModalPresentation = ({
  pickUp,
  isOpen,
  onToggle,
  pickUpStates,
  successCreationNotification,
  successCancelNotification,
  errorNotification,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);
  const [modal4, setModal4] = useState(false);
  const [pickUpUnits, setPickUpUnits] = useState(
    pickUp.pickUpUnits.map((pickUpUnit) => {
      return {
        ...pickUpUnit,
        barcode: pickUpUnit?.asset ? pickUpUnit?.asset.barcode1 : pickUpUnit?.item.barcode1,
      };
    }),
  );
  const [building, setBuilding] = useState(pickUp?.buildingTarget ? pickUp.buildingTarget.id : undefined);
  const [area, setArea] = useState(pickUp?.areaTarget ? pickUp.areaTarget.id : undefined);
  const [comments, setComments] = useState(undefined);
  const [tokens, setTokens] = useState([]);

  const toggle = () => setModal(!modal);
  const toggle2 = () => {
    setModal2(!modal2);
    setPickUpUnits(
      pickUp.pickUpUnits.map((pickUpUnit) => {
        return {
          ...pickUpUnit,
          barcode: pickUpUnit?.asset ? pickUpUnit?.asset.barcode1 : pickUpUnit?.item.barcode1,
        };
      }),
    );
  };
  const toggle3 = () => setModal3(!modal3);
  const toggle4 = () => {
    setBuilding(undefined);
    setArea(undefined);
    setComments(undefined);
    setTokens([]);
    setModal4(!modal4);
  };

  const setCommentTarget = (e) => {
    setComments(!!e ? e.target.value : undefined);
  };

  const setTokensTarget = (e) => {
    setTokens(!!e ? e : undefined);
  };

  const setUpdatePickUp = async (newState) => {
    setSubmitting(true);

    const data = { state: newState };
    if (!!comments) data['comments'] = comments;

    if (newState === 1) {
      data['pickUps'] = pickUpUnits;
    }

    if (newState === 4) {
      data['buildingTargetId'] = building;
      data['areaTargetId'] = area;
      if (tokens.length > 0) data['attachmentsTokens'] = tokens;
    }

    const { body, status } = await updatePickUp(pickUp.requestNumber, data);

    if (status === 200) {
      setSubmitting(false);
      if (newState === 1) successCreationNotification();
      if (newState === 2) successCancelNotification();
      setModal2(false);
      setModal(false);
      window.location.reload(false);
    } else {
      const errors = body?.errors
        ? body?.errors
        : body?.errors?.base
        ? body?.errors?.base?.join(', ')
        : body?.message
        ? body?.message
        : 'no existe mensaje de errors';
      setSubmitting(false);
      errorNotification(errors);
      setModal2(false);
      setModal(false);
      onToggle();
    }
  };

  const setDelivered = (event, pickUpUnit) => {
    let { value, min, max } = event.target;
    const delivered = value === '' ? '' : Number(value) < min ? min : Number(value) > max ? max : value;
    if (pickUpUnit) {
      setPickUpUnits([
        ...pickUpUnits.map((pickUp) => {
          if (pickUpUnit.asset) {
            return pickUp.barcode === pickUpUnit.asset.barcode1
              ? {
                  ...pickUp,
                  delivered: delivered === '' ? 1 : Number(delivered),
                  deliveredDisplay: delivered,
                }
              : pickUp;
          } else {
            return pickUp.barcode === pickUpUnit.item.barcode1
              ? {
                  ...pickUp,
                  delivered: delivered === '' ? pickUp?.quantity : Number(delivered),
                  deliveredDisplay: delivered,
                }
              : pickUp;
          }
        }),
      ]);
    }
  };

  const preparePickUp = () => {
    setModal2(true);
    setPickUpUnits([
      ...pickUpUnits.map((pickUp) => {
        if (pickUp.asset) {
          return { ...pickUp, delivered: 1, deliveredDisplay: 1 };
        } else {
          return { ...pickUp, delivered: Number(pickUp?.quantity), deliveredDisplay: pickUp?.quantity };
        }
      }),
    ]);
  };

  return (
    <Modal isOpen={isOpen} toggle={onToggle} size="lg">
      <ModalHeader>
        <Row>
          <Col md={10}>Pick Up #{pickUp.requestNumber}</Col>
          <Col md={2}>
            {' '}
            <Badge className="align-items-right" color={pickUpStates[pickUp.state].color}>
              <FontAwesome name="times" className="mr-1" /> {pickUpStates[pickUp.state].name}
            </Badge>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody>
        <div className="mb-2">
          Fecha: <strong>{fromIso8601(pickUp.createdAt)}</strong>
        </div>
        <div className="mb-2">
          Cliente: <strong>{pickUp.user?.name}</strong>
        </div>
        {pickUp.costAccount && (
          <div className="mb-2">
            Centro de Costo: <strong>{`${pickUp.costAccount?.code} - ${pickUp.costAccount?.name}`}</strong>
          </div>
        )}
        {pickUp.topAccount && (
          <div className="mb-2">
            Cuenta Mayor: <strong>{`${pickUp.topAccount?.code} - ${pickUp.topAccount?.name}`}</strong>
          </div>
        )}
        {pickUp.device && (
          <Row className="mb-2">
            <Col>
              Dispositivo: <strong>{pickUp.device?.name}</strong>
            </Col>
            <Col>
              Slots: <strong>{pickUp.slot}</strong>
            </Col>
          </Row>
        )}

        <div className="mb-2">
          Cantidad total solicitada:{' '}
          <strong>{Number(pickUp.pickUpUnits.reduce((a, b) => a + (b['quantity'] || 0), 0))}</strong>
        </div>
        {(pickUp.state === 1 || pickUp.state === 3 || pickUp.state === 4 || pickUp.state === 5) && (
          <div className="mb-2">
            Cantidad total {pickUp.state === 1 ? 'preparada' : 'entregada'}:{' '}
            <strong>{pickUp.pickUpUnits.reduce((a, b) => a + (b['delivered'] || 0), 0)}</strong>
          </div>
        )}

        {!!pickUp.buildingTarget && (
          <div className="mb-2">
            Instalación de destino: <strong>{pickUp.buildingTarget?.name}</strong>
          </div>
        )}

        {pickUp.areaTarget && (
          <div className="mb-2">
            Área de destino: <strong>{pickUp.areaTarget?.name}</strong>
          </div>
        )}

        {pickUp.externalReserve && (
          <div className="mb-2">
            Nº reserva cliente: <strong>{pickUp.externalReserve}</strong>
          </div>
        )}

        {pickUp.comments && <div className="mt-2 mb-2">Comentarios: {pickUp.comments}</div>}
        <Card>
          <CardBody>
            <CardTitle tag="h5">Artículos solicitados</CardTitle>
            {pickUpUnits.map((pick, index) => {
              return (
                <div key={`pick-up-${index}-${pick?.id}`}>
                  {pick.asset ? (
                    <Row className="mb-3">
                      <Col>
                        <AssetDisplay asset={pick.asset} status={false} barcode={true} />
                      </Col>
                    </Row>
                  ) : (
                    <Row className="mb-3">
                      <Col>
                        <ItemDisplay
                          item={pick.item}
                          barcode={true}
                          sku={true}
                          inventory={false}
                          comments={false}
                          quantity={pick.quantity}
                          delivered={pick.delivered}
                          finished={pickUp.state === 3 || pickUp.state === 5 ? pick.delivered : undefined}
                          available={pickUp.state === 5 ? pick.quantity - pick.delivered : undefined}
                          requested={pick.requested}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              );
            })}
          </CardBody>
        </Card>
        {pickUp.attachments.length > 0 && (
          <Card className="mt-3">
            <CardBody>
              <CardTitle tag="h5">Archivos adjuntos</CardTitle>
              {pickUp.attachments.map((attachment, index) => {
                const info = fileInfoFor(attachment.contentType);
                return (
                  <Row xs={12}>
                    <Col xs={2} className="mb-3">
                      {attachment.contentType.includes('image') ? (
                        <Img
                          className={`asset-cloud-image`}
                          src={(attachment && attachment.file && attachment.file.url) || noImage}
                          alt={attachment.title || attachment.originalFilename || 'Sin título'}
                          loader={<Spinner />}
                          unloader={
                            <img
                              className={`asset-cloud-image`}
                              alt={attachment.title || attachment.originalFilename || 'Sin título'}
                              src={noImage}
                            />
                          }
                        />
                      ) : (
                        <FileIcon type={info.icon} width="4rem" />
                      )}
                    </Col>
                    <Col xs={10}>
                      <Row>
                        <Col>
                          <strong>Nombre:</strong>{' '}
                          <span className="text-left text-muted">
                            {attachment.title || attachment.originalFilename || <em>Sin título</em>}
                          </span>
                          <div>
                            <Button
                              role="button"
                              color="primary"
                              size="sm"
                              tag="a"
                              href={attachment.file.url}
                              target="_blank"
                            >
                              <FontAwesome name="download" /> Descargar
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                );
              })}
            </CardBody>
          </Card>
        )}
      </ModalBody>
      <ModalFooter>
        {pickUp?.state === 0 && (
          <Button color="primary" onClick={preparePickUp} disabled={submitting}>
            Preparar la reserva
          </Button>
        )}
        {pickUp?.state === 1 && (
          <Button color="success" onClick={() => setModal3(true)} disabled={submitting || !!pickUp?.device}>
            Entregar la reserva
          </Button>
        )}
        {pickUp?.state === 1 && (
          <Button color="success" onClick={() => setModal4(true)} disabled={submitting || !!pickUp?.device}>
            Transferir la reserva
          </Button>
        )}
        {(pickUp?.state === 0 || pickUp?.state === 1) && !pickUp?.device && (
          <Button color="danger" onClick={() => setModal(true)} disabled={submitting}>
            Cancelar la reserva
          </Button>
        )}
        <Button color="secondary" onClick={onToggle}>
          Cerrar
        </Button>
      </ModalFooter>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader>Cancelar reserva</ModalHeader>
        <ModalBody>
          ¿Desea cancelar esta reserva?
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Label>Comentarios</Label>
                  <Input name="comments" type="textarea" rows="6" value={comments} onChange={setCommentTarget} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={() => setUpdatePickUp(2)} disabled={submitting}>
            Confirmar
          </Button>{' '}
          <Button color="danger" onClick={toggle} disabled={submitting}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal2} toggle={toggle2} size="lg">
        <ModalHeader>Preparar reserva</ModalHeader>
        <ModalBody>
          ¿Desea preparar esta reserva? Ingerese las cantidades que entregará al cliente.
          <Card>
            <CardBody>
              <CardTitle>Artículos solicitados</CardTitle>
              {pickUpUnits.map((pick, index) => (
                <Row key={`pick-up-delivered-${index}`}>
                  <Col md={9}>
                    {pick.asset ? (
                      <AssetDisplay asset={pick.asset} status={false} barcode={true} />
                    ) : (
                      <ItemDisplay item={pick.item} barcode={true} sku={false} />
                    )}
                  </Col>
                  <Col md={3}>
                    <Input
                      type="number"
                      name="delivered"
                      placeholder={pick.quantity}
                      max={pick.quantity}
                      min={0}
                      value={pick.deliveredDisplay}
                      onChange={(e) => setDelivered(e, pick)}
                    />
                    <div className="form-text small text-muted">Se puede preparar máximo la cantidad reservada</div>
                  </Col>
                </Row>
              ))}
              <Row className="mt-3">
                <Col>
                  <Label>Comentarios</Label>
                  <Input name="comments" type="textarea" rows="6" value={comments} onChange={setCommentTarget} />
                </Col>
              </Row>
              {process.env.NODE_ENV === 'development' && (
                <Row className="mt-3">
                  <Col>
                    <DebugCard
                      data={pickUpUnits.map((pick) => {
                        return {
                          asset: pick?.asset?.barcode1,
                          item: pick?.item?.barcode1,
                          quantity: pick.quantity,
                          delivered: pick.delivered,
                          deliveredDisplay: pick.deliveredDisplay,
                          comments: comments,
                        };
                      })}
                    />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setUpdatePickUp(1)} disabled={submitting}>
            Preparar
          </Button>{' '}
          <Button color="danger" onClick={toggle2} disabled={submitting}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal2} toggle={toggle2} size="lg">
        <ModalHeader>Preparar reserva</ModalHeader>
        <ModalBody>
          ¿Desea preparar esta reserva? Ingerese las cantidades que entregará al cliente.
          <Card>
            <CardBody>
              <CardTitle>Artículos solicitados</CardTitle>
              {pickUpUnits.map((pick, index) => (
                <Row key={`pick-up-delivered-${index}`}>
                  <Col md={9}>
                    {pick.asset ? (
                      <AssetDisplay asset={pick.asset} status={false} barcode={true} />
                    ) : (
                      <ItemDisplay item={pick.item} barcode={true} sku={false} />
                    )}
                  </Col>
                  <Col md={3}>
                    <Input
                      type="number"
                      name="delivered"
                      placeholder={pick.quantity}
                      max={pick.quantity}
                      min={0}
                      value={pick.deliveredDisplay}
                      onChange={(e) => setDelivered(e, pick)}
                    />
                    <div className="form-text small text-muted">Se puede preparar máximo la cantidad reservada</div>
                  </Col>
                </Row>
              ))}
              <Row className="mt-3">
                <Col>
                  <Label>Comentarios</Label>
                  <Input name="comments" type="textarea" rows="6" value={comments} onChange={setCommentTarget} />
                </Col>
              </Row>
              {process.env.NODE_ENV === 'development' && (
                <Row className="mt-3">
                  <Col>
                    <DebugCard
                      data={pickUpUnits.map((pick) => {
                        return {
                          asset: pick?.asset?.barcode1,
                          item: pick?.item?.barcode1,
                          quantity: pick.quantity,
                          delivered: pick.delivered,
                          deliveredDisplay: pick.deliveredDisplay,
                          comments: comments,
                        };
                      })}
                    />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setUpdatePickUp(1)} disabled={submitting}>
            Preparar
          </Button>{' '}
          <Button color="danger" onClick={toggle2} disabled={submitting}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal3} toggle={toggle3} size="lg">
        <ModalHeader>Entregar reserva</ModalHeader>
        <ModalBody>
          ¿Desea entregar esta reserva?
          <Row className="mb-3">
            <Col>
              <Label>Comentarios</Label>
              <Input name="comments" type="textarea" rows="6" value={comments} onChange={setCommentTarget} />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setUpdatePickUp(3)} disabled={submitting}>
            Entregar
          </Button>{' '}
          <Button color="danger" onClick={toggle3} disabled={submitting}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal4} toggle={toggle4} size="lg">
        <ModalHeader>Tranferir reserva</ModalHeader>
        <ModalBody>
          ¿Desea transferir esta reserva?
          <Row className="mt-3">
            <Col md={9}>Artículo/Activo</Col>
            <Col md={3}>Cantidad</Col>
          </Row>
          <Row className="mb-3">
            <Col>
              {pickUpUnits.map((pick, index) => (
                <Row key={`pick-up-delivered-${index}`}>
                  <Col md={9}>
                    {pick.asset ? (
                      <AssetDisplay asset={pick.asset} status={false} />
                    ) : (
                      <ItemDisplay item={pick.item} sku={false} />
                    )}
                  </Col>
                  <Col md={3}>{pick.delivered}</Col>
                </Row>
              ))}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label>
                <b>Instalación de destino</b> <span className="text-muted">(Obligatorio)</span>
              </Label>
              <BuildingSelector
                value={building}
                input={{ onChange: setBuilding }}
                remoteFinder={getBuildingsOrganization}
                remoteLoader={getBuilding}
                required
              />
              <div className="text-muted">
                Para realizar la transferencia se requiere que especifique una instalación diferente a la actual
              </div>
            </Col>

            <Col>
              {building && (
                <>
                  <Label>
                    <b>Área de la instalación</b> (Obligatorio)
                  </Label>
                  <AreaSelector
                    value={area}
                    input={{ onChange: setArea }}
                    remoteLoader={(search) => getAreaByBuilding(building, search)}
                    remoteFinder={(search) => getAreasByBuilding(building, search)}
                  />
                  <div className="form-text text-muted">
                    Para realizar la transferencia se requiere que especifique un área de la instalación seleccionada.
                  </div>
                </>
              )}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Label>Comentarios</Label>
              <Input name="comments" type="textarea" rows="6" value={comments} onChange={setCommentTarget} />
            </Col>
          </Row>
          <Row>
            <Col>
              <FileUploader
                multiple={true}
                token={tokens}
                onChange={setTokensTarget}
                endpoint={(token) => `/attachments/${token}`}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => setUpdatePickUp(4)} disabled={submitting || !(building && area)}>
            Transferir
          </Button>{' '}
          <Button color="danger" onClick={toggle4} disabled={submitting}>
            Cerrar
          </Button>
        </ModalFooter>
      </Modal>
    </Modal>
  );
};

export const PickUpModal = compose(
  connect(null, (dispatch) => ({
    successCreationNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Reserva creada',
          text: `La reserva ha sido creado exitosamente.`,
          color: 'success',
        }),
      ),
    successCancelNotification: () =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Reserva cancelada',
          text: `La reserva ha sido cancelada exitosamente.`,
          color: 'success',
        }),
      ),
    errorNotification: (errors) =>
      dispatch(
        notificationsGenerators.insert({
          title: 'Error',
          text: errors ? errors : 'Hubo un error al procesar la petición',
          color: 'danger',
        }),
      ),
  })),
)(PickUpModalPresentation);
