import { FontAwesome, OrganizationCard, simpleFilterQuery } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Media } from 'reactstrap';

const logoStyles = {
  width: '6em',
  height: '4em',
};

export const OrganizationRow = ({ organization, setOrganizationStatus }) => (
  <tr>
    <td className="align-middle text-center">{organization.id}</td>
    <td className="align-middle">
      <Media className="align-items-center">
        <Media left className="mr-3" style={logoStyles}>
          {organization.avatar && (
            <OrganizationCard
              width={logoStyles.width}
              height={logoStyles.height}
              url={organization.avatar.icon.url}
              alt={organization.name}
            />
          )}
        </Media>
        <Media>{organization.name}</Media>
      </Media>
    </td>
    <td className="align-middle">
      <div>
        <strong>{organization.contactName}</strong>
      </div>
      {organization.contactEmail && (
        <div>
          <span className="text-info">
            <FontAwesome name="envelope" />:
          </span>{' '}
          {organization.contactEmail}
        </div>
      )}
      {organization.contactPhone && (
        <div>
          <span className="text-info">
            <FontAwesome name="phone" />:
          </span>{' '}
          {organization.contactPhone}
        </div>
      )}
    </td>
    <td className="text-center align-middle">
      {organization.active ? (
        <FontAwesome name="check" className="text-success" />
      ) : (
        <FontAwesome name="times" className="text-danger" />
      )}
    </td>
    <td className="text-center align-middle">
      {organization.podStatus ? (
        <FontAwesome name="check" className="text-success" />
      ) : (
        <FontAwesome name="times" className="text-danger" />
      )}
    </td>
    <td className="text-right align-middle">
      <Button
        role="button"
        color="primary"
        size="sm"
        outline
        block
        tag={NavLink}
        to={`/admin/organizations/${organization.id}`}
      >
        Editar
      </Button>

      <Button
        role="button"
        size="sm"
        color="primary"
        outline
        tag={NavLink}
        to={`/admin/buildings?q=${simpleFilterQuery({
          'organizations[{}]': [{ label: organization.name, value: organization.id }],
        })}`}
        block
      >
        Instalaciones
      </Button>

      {organization.active && (
        <Button
          role="button"
          color="danger"
          outline
          block
          size="sm"
          onClick={() => setOrganizationStatus(organization.id, false)}
        >
          Desactivar
        </Button>
      )}

      {!organization.active && (
        <Button
          role="button"
          color="success"
          outline
          block
          size="sm"
          onClick={() => setOrganizationStatus(organization.id, true)}
        >
          Activar
        </Button>
      )}
    </td>
  </tr>
);
