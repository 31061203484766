import React from 'react';
import { compose, onlyUpdateForKeys, withHandlers, withState } from 'recompose';
import type { HOC } from 'recompose';

import DefaultFooter from './DefaultFooter';
import type { ColumnMetadata } from './types';

type Props = {
  columnMetadata: ColumnMetadata[],
  currentPage: number,
  onChangeGrid: Function,
  resultsOnPage: number,
  resultsPerPage: number,
  showCheckbox: boolean,
  showFooter: boolean,
  totalCount: number,
};

const enhance: HOC<*, Props> = compose(
  onlyUpdateForKeys([
    'columnMetadata',
    'currentPage',
    'onChangeGrid',
    'resultsOnPage',
    'resultsPerPage',
    'showCheckbox',
    'showFooter',
    'totalCount',
  ]),
  withState('totalPages', 'setTotalPages', ({ totalCount, resultsPerPage }) => Math.ceil(totalCount / resultsPerPage)),
  withHandlers({
    handleOnChangeGrid: ({ onChangeGrid }) => (data) => {
      onChangeGrid(data);
    },
  }),
);

const GridFooter = ({
  // props
  columnMetadata,
  currentPage,
  resultsOnPage,
  resultsPerPage,
  showCheckbox,
  showFooter,
  totalCount,
  // state
  totalPages,
  // handlers
  handleOnChangeGrid,
}) => {
  if (!showFooter) {
    return null;
  }

  const colspan = columnMetadata.length + (showCheckbox ? 1 : 0);
  const optionsArray = [];

  for (let i = 1; i <= totalPages; i++) {
    optionsArray.push(<option>{i}</option>);
  }

  return (
    <tfoot>
      <tr>
        <td colSpan={colspan}>
          <DefaultFooter
            currentPage={currentPage}
            onChangeGrid={handleOnChangeGrid}
            resultsOnPage={resultsOnPage}
            resultsPerPage={resultsPerPage}
            totalCount={totalCount}
          />
        </td>
      </tr>
    </tfoot>
  );
};

export default enhance(GridFooter);
