import { handleAuth, withToken, apiFetch, mountQuery } from '../http';

export const getSimCards = (query) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/sim_cards${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const unsuspendSimCard = (iccid) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/sim_cards/${iccid}/unsuspend`,
      method: 'PUT',
    }),
  );

export const suspendSimCard = (iccid) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/sim_cards/${iccid}/suspend`,
      method: 'PUT',
    }),
  );
