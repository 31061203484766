import { reassembly } from '@core/building/assets';
import { FontAwesome } from '@shared';
import { ToggleAssembly } from '@shared/assets/ToggleAssembly';
import { connect, notificationsGenerators } from '@store';
import React from 'react';
import { Card, CardBody, CardFooter, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { compose, withHandlers, withState } from 'recompose';
import { ReassemblyForm } from './ReassemblyForm';
import { ReassemblyModal } from './ReassemblyModal';

const enhance = compose(
  connect(null, (dispatch) => ({
    successNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'Ensamblado exitoso',
          text: 'El artículo ha sido ensamblado exitosamente',
          color: 'success',
        }),
      );
    },
    notFoundNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'No encontrado',
          text:
            'El código de barra ingresado no se encuentra registrado. Por favor contacta a un administrador si los problemas persisten.',
          color: 'danger',
        }),
      );
    },
    conflictNotification(text) {
      dispatch(
        notificationsGenerators.insert({
          title: 'Código ensamblado',
          text,
          color: 'danger',
        }),
      );
    },
    InvalidEpcNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'EPC incorrecto',
          text:
            'El código EPC debe contener 24 caracteres. Por favor contacta a un administrador si los problemas persisten.',
          color: 'warning',
        }),
      );
    },
    InvalidAssetCodeNotification() {
      dispatch(
        notificationsGenerators.insert({
          title: 'Código de activo incorrecto',
          text:
            'El código debe contener 23 caracteres. Por favor contacta a un administrador si los problemas persisten.',
          color: 'warning',
        }),
      );
    },
    resetForm() {
      // No method to do it, reset the form manually then.
      dispatch({
        type: '@@redux-form/RESET',
        meta: {
          form: 'assemblyForm',
        },
      });
    },
  })),
  withState('instruction', 'setInstruction', true),
  withState('prefix', 'setPrefix', false),
  withState('modal', 'setModal', false),
  withState('barcode', 'setBarcode', undefined),
  withHandlers({
    reassemble: ({
      setAsset,
      asset,
      setModal,
      modal,
      prefix,
      barcode,
      successNotification,
      notFoundNotification,
      conflictNotification,
      InvalidEpcNotification,
      InvalidAssetCodeNotification,
      resetForm,
      history,
    }) => async () => {
      let code = '';
      let assetBarcode = '';

      prefix === true ? (code = 'EPC:' + barcode) : (code = barcode);

      if (prefix === true && barcode.length !== 24) {
        setModal(!modal);
        InvalidEpcNotification();
      } else if (prefix === false && barcode.length !== 23) {
        setModal(!modal);
        InvalidAssetCodeNotification();
      } else {
        const { body, status } = await reassembly({
          assetBarcode: asset.barcode1,
          code: code,
        });

        if (status === 201) {
          resetForm();
          successNotification();

          if (prefix === true) {
            assetBarcode = asset.barcode1;
            setAsset(body);
          } else {
            assetBarcode = body.inventariable.barcode1;
            setAsset(body.inventariable);
          }

          history.replace(`/building/assets/${assetBarcode}`);
        } else if (status === 404) {
          // In theory, the user already found the item and the area.
          // 404 is because of the asset barcode
          setModal(!modal);
          notFoundNotification();
        } else if (status === 409) {
          setModal(!modal);
          conflictNotification(Object.values(body.errors).join('\n'));
        }
      }
    },
    toggleInstruction: ({ instruction, setInstruction }) => () => {
      setInstruction(!instruction);
    },
    toggleModal: ({ modal, setModal, setBarcode }) => ({ barcode }) => {
      setBarcode(barcode);
      setModal(!modal);
    },
    togglePrefix: ({ prefix, setPrefix }) => () => {
      setPrefix(!prefix);
    },
  }),
);

const Reassembly = ({
  reassemble,
  toggleInstruction,
  instruction,
  togglePrefix,
  prefix,
  toggleModal,
  modal,
  barcode,
  setBarcode,
}) => {
  return (
    <div className="layout-container container-fluid mb-4">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={10} lg={8}>
          <Card>
            <CardBody>
              <ToggleAssembly
                checked={prefix}
                toggle={togglePrefix}
                onMessage="Hard Tag"
                offMessage="Etiqueta"
                className="pull-right"
              />
              <ReassemblyForm onSubmit={toggleModal} />
            </CardBody>
            <CardFooter role="button" onClick={toggleInstruction} className="alert-info h5 m-0">
              {instruction ? <FontAwesome name="chevron-down" /> : <FontAwesome name="chevron-right" />}
              {` `}
              Instrucciones y más información
            </CardFooter>

            {instruction && (
              <ListGroup flush>
                <ListGroupItem>
                  <ul>
                    <li className="mb-2">Elimine la etiqueta deteriorada del activo.</li>
                    <li className="mb-2">
                      Coloque la etiqueta nueva, y a continuación verifiquela a traves del lector o coloque el código de
                      forma manual.
                    </li>
                    <li className="mb-2">
                      Para realizar el reensamblaje de un <b>tag estandar</b> el código debe contener 23 caracteres,
                      incluyendo el prefijo <code>AS:</code> ejemplo: <code>AS:WXCaTm_X3vzjuNdiZLwB</code>
                    </li>
                    <li className="mb-2">
                      Para realizar el reensamblaje de un <b>hard tag</b> el código EPC debe contener 24 caracteres,
                      ejemplo: <code>4A885D2D080F1B833B73EF1F</code>
                    </li>
                    <li className="mb-2">
                      Haga click en el botón reensamblar y estara disponible para efectuar operaciones con la nueva
                      etiqueta.
                    </li>
                  </ul>
                </ListGroupItem>
              </ListGroup>
            )}
          </Card>
          <ReassemblyModal modal={modal} toggle={toggleModal} reassemble={reassemble} />
        </Col>
      </Row>
    </div>
  );
};

export const AssetReassembly = enhance(Reassembly);
