import { getPartners } from '@core/building/partners';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';

export class PartnersFilterModal extends SimpleFilterModal {
  findPartners = async (value) => {
    const { body, status } = await getPartners({ search: value });
    if (status === 200) {
      return body.map(({ name: label, name: value }) => ({ label, value }));
    }
    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="names[{}]">Nombre</Label>
              <AsyncSelect
                name="names[{}]"
                value={this.state['names[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findPartners}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="active">Estado</Label>
              <Select
                name="active"
                value={this.state['active']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Activo', value: true },
                  { label: 'Inactivo', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="codes[{}]">Código</Label>
              <AsyncSelect
                name="codes[{}]"
                value={this.state['codes[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findPartners}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="kind">Tipo</Label>
              <Select
                name="kind"
                value={this.state['kind']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Cliente', value: 0 },
                  { label: 'Proveedor', value: 1 },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}
