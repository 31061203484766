import { FontAwesome } from '@shared/';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Badge, Media } from 'reactstrap';

export const EventDevice = ({ device }) => (
  <Media className="align-middle">
    {device && (
      <Media body>
        {device.name && (
          <div className="w-100 text-overflow small mt-1">
            <FontAwesome name="mobile-alt" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{device.name}</span>
          </div>
        )}
        <NavLink to={`/building/devices/${device.barcode1}`} className="small">
          <FontAwesome name="barcode" color="info" className="mr-1" />
          <kbd>{device.barcode1}</kbd>
        </NavLink>
        <div className="small">
          <FontAwesome name="signal" color="info" className="mr-1" />
          {device.active ? (
            <Badge color="success">
              <FontAwesome name="check" className="mr-1" /> Activo
            </Badge>
          ) : (
            <Badge color="danger">
              <FontAwesome name="times" className="mr-1" /> Inactivo
            </Badge>
          )}
        </div>
      </Media>
    )}
  </Media>
);

export const EventDeviceModal = ({ device }) => (
  <Media>
    {device && (
      <Media body className="mr-1">
        {device.name && (
          <div className="w-100 text-overflow mt-1">
            <FontAwesome name="mobile-alt" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{device.name}</span>
          </div>
        )}
        <NavLink to={`/building/devices/${device.barcode1}`}>
          <FontAwesome name="barcode" color="info" className="mr-1" />
          <kbd>{device.barcode1}</kbd>
        </NavLink>
        <div>
          <FontAwesome name="signal" color="info" className="mr-1" />
          {device.active ? (
            <Badge color="success">
              <FontAwesome name="check" className="mr-1" /> Activo
            </Badge>
          ) : (
            <Badge color="danger">
              <FontAwesome name="times" className="mr-1" /> Inactivo
            </Badge>
          )}
        </div>
        {device.deviceModel && (
          <div className="w-100 text-overflow mt-1">
            <span className="text-muted mr-1">
              <FontAwesome name="hashtag" color="info" className="mr-1" />
              Modelo:
            </span>
            <code>{device.deviceModel}</code>
          </div>
        )}
        {device.deviceType && (
          <div className="w-100 text-overflow mt-1">
            <span className="text-muted mr-1">
              <FontAwesome name="tag" color="info" className="mr-1" />
              Tipo:
            </span>
            <span className="text-muted">{device.deviceType.description}</span>
          </div>
        )}
      </Media>
    )}
  </Media>
);

export const EventDeviceKey = ({ deviceKey }) => (
  <Media>
    {deviceKey && (
      <Media body>
        {deviceKey.device && deviceKey.device.name && (
          <div className="w-100 text-overflow small mt-1">
            <FontAwesome name="mobile-alt" color="info" className="mr-1" />
            <span className="asset-name font-weight-bold">{deviceKey.device.name}</span>
          </div>
        )}
        {deviceKey.device && (
          <NavLink to={`/building/devices/${deviceKey.device.barcode1}`} className="small">
            <FontAwesome name="barcode" color="info" className="mr-1" />
            <kbd>{deviceKey.device.barcode1}</kbd>
          </NavLink>
        )}
        <div className="small">
          <FontAwesome name="key" color="info" className="mr-1" />
          {deviceKey.active ? (
            <Badge color="success">
              <FontAwesome name="check" className="mr-1" /> Key Activa
            </Badge>
          ) : (
            <Badge color="danger">
              <FontAwesome name="times" className="mr-1" /> Key Inactiva
            </Badge>
          )}
        </div>
      </Media>
    )}
  </Media>
);

export const EventDeviceKeyModal = ({ deviceKey }) => (
  <Media>
    {deviceKey && (
      <Media body className="mr-1">
        <div>
          <FontAwesome name="key" color="info" className="mr-1" />
          {deviceKey.active ? (
            <Badge color="success">
              <FontAwesome name="check" className="mr-1" /> Key Activa
            </Badge>
          ) : (
            <Badge color="danger">
              <FontAwesome name="times" className="mr-1" /> Key Inactiva
            </Badge>
          )}
        </div>
        {deviceKey.creator && (
          <div className="w-100 text-overflow mt-1">
            <span className="text-muted mr-1">
              <FontAwesome name="user" color="info" className="mr-1" />
              Creada por:
            </span>
            <span className="text-muted">{`${deviceKey.creator.firstName} ${deviceKey.creator.lastName}`}</span>
          </div>
        )}
        {deviceKey.device && <EventDeviceModal device={deviceKey.device} />}
      </Media>
    )}
  </Media>
);
