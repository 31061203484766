import { buildingDownload } from '@core/http.js';
import React, { Component } from 'react';
import { getUserAssets } from '@core/building/users';
import { ExcelDownload, Loading, Pagination, ShowForRoles } from '@shared';
import { AssetsResults } from '../../assets/components/AssetsResults';

type Props = {
  user: Object,
};

type State = {
  loading: boolean,
  assets: Object[],
  pagination: Object,
};

export class UserAssets extends Component<Props, State> {
  state = {
    loading: true,
    assets: [],
    pagination: {},
    userId: undefined,
  };

  componentDidMount() {
    this.fetchPageData();
  }

  fetchPageData = async (page: number = 1) => {
    const {
      user: { id },
    } = this.props;

    this.setState({ userId: id });

    this.setState({ loading: true });

    const { status, body: assets, pagination } = await getUserAssets(id, { p: page });

    if (String(status).startsWith('2')) {
      this.setState({ loading: false, assets, pagination });
    }

    this.setState({ loading: false });
  };

  setPage = (page: number) => {
    this.fetchPageData(page);
  };

  render() {
    const { pagination, loading, assets, userId } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    return (
      <div>
        <span className="float-right">
          <ShowForRoles accepted={['organization_admin', 'warehouse_admin', 'guest']}>
            <ExcelDownload
              file={`users/${userId}/assets`}
              filters={{}}
              downloadFile={buildingDownload}
              disabled={!assets}
            />
          </ShowForRoles>
        </span>
        {paginationTemplate}
        {loading ? <Loading /> : <AssetsResults assets={assets} pagination={pagination} />}
        {paginationTemplate}
      </div>
    );
  }
}
