import React, { Fragment, useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { SessionItem } from './components/session-item';
import { getUserSessions, expireUserSession } from '@core/account';
import { Spinner } from '@shared';

export const SessionsView = () => {
  const [modal, setModal] = useState(false);
  const [tokens, setTokens] = useState([]);
  const [currentToken, setCurrentToken] = useState({});

  const toggle = () => setModal(!modal);

  const expireSession = async () => {
    const { status } = await expireUserSession(currentToken.id);
    if (status === 204) {
      const filter_tokens = tokens.filter((token, i) => token.id !== currentToken.id);
      setTokens(filter_tokens);
      toggle();
    }
  };

  const openModal = (token) => {
    setCurrentToken(token);
    toggle();
  };

  useEffect(() => {
    const fetchData = async () => {
      const { body } = await getUserSessions();
      const sessions = body;

      setTokens(sessions);
    };
    fetchData();
  }, []);

  return (
    <Fragment>
      <Row>
        <Col sm="12" md="4">
          <h4 className="mb-4">Sesiones activas</h4>
          <p>
            Esta es una lista de dispositivos que han iniciado sesión en su cuenta. Revoca cualquier sesión que no
            reconozcas
          </p>
        </Col>
        {tokens.length === 0 && (
          <Col sm="12" md="8">
            <div className="text-center h1 text-info">
              <Spinner />
            </div>
          </Col>
        )}
        {tokens.length > 0 && (
          <Col sm="12" md="8">
            <ul className="list-group list-group-flush">
              {tokens.map((t) => (
                <SessionItem key={t.id} token={t} onRevoke={openModal} />
              ))}
            </ul>
          </Col>
        )}
      </Row>
      <Modal isOpen={modal} toggle={toggle} backdrop={true}>
        <ModalBody>¿Está seguro?. Se cerrará sesión de Alliot Cloud en este dispositivo</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancelar
          </Button>
          <Button color="danger" onClick={expireSession}>
            Sí
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};
