import { getBuildingTypes, getBuildingWithoutParse, updateBuilding } from '@core/admin/buildings';
import { SubmissionError } from '@core/forms';
import { Loading, simpleFilterQuery } from '@shared';
import { SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { BuildingForm } from './components/BuildingForm';

class EditBuildingPresentation extends Component {
  state = {
    loading: true,
    building: {},
    edited: false,
  };

  async componentDidMount() {
    const { id } = this.props.match.params;

    await this.getBuildingTypes();

    if (!id) this.notFound();

    const { body: building, status } = await getBuildingWithoutParse(id);

    if (status === 200) {
      this.setState({
        building,
        loading: false,
      });
    } else if (status === 404) {
      this.notFound();
    }
  }

  async getBuildingTypes() {
    const { body: buildingTypes, status } = await getBuildingTypes();

    if (status.toString().startsWith('2')) {
      const a = { 0: 'Seleccione...' };
      buildingTypes.forEach((e) => {
        a[e.id] = e.name;
        return a;
      });
      this.setState({
        buildingTypes: a,
      });
    }
  }

  notFound = () => {
    this.props.notFoundNotification();
    this.setState({ redirectTo: '/admin/buildings' });
  };

  updateBuilding = async (building) => {
    const { body, status } = await updateBuilding(building);

    if (status === 200) {
      this.props.editNotification(building.name);

      this.setState({
        edited: true,
      });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    } else if (status === 404) {
      this.notFound();
    }
  };

  render() {
    const { edited, building, loading, buildingTypes } = this.state;

    if (edited)
      return (
        <Redirect
          to={`/admin/buildings?q=${simpleFilterQuery({
            'organizations[{}]': [
              {
                label: building.organization.name,
                value: building.organization.id,
              },
            ],
          })}`}
        />
      );

    if (loading) return <Loading />;

    return (
      <SimpleContainerLayout padded className="mt-4 mb-4" title={`Editar instalación: ${building.name}`}>
        <BuildingForm
          initialValues={{
            ...building,
            organizationId: building.organization.id,
            partner_id: building.partner?.id,
          }}
          onSubmit={this.updateBuilding}
          buildingTypes={buildingTypes}
        />
      </SimpleContainerLayout>
    );
  }
}

export const EditBuilding = connect(null, (dispatch) => ({
  editNotification: (name) =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Instalación editada',
        text: `La instalación '${name}' ha sido editada exitosamente.`,
        color: 'success',
        duration: 10000,
      }),
    ),
  notFoundNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No encontrado',
        text: `La instalación buscada no existe o no se encuentra disponible en este momento. Si los problemas persisten, por favor contacta a un administrador.`,
        color: 'danger',
        duration: 10000,
      }),
    ),
}))(EditBuildingPresentation);
