import { FontAwesome } from '@shared';
import { pluralize } from '@shared/helpers';
import React from 'react';
import { Button, Table } from 'reactstrap';

import { DeviceInventoryRow } from './DeviceInventoryRow';

const style = { padding: '0.3rem' };

export const DeviceInventoryTable = ({ items, pagination, switcher, devices = [], position, next, prev }) => {
  const titles = ['Disponible', 'Unidad'];

  return (
    <div>
      <div className="small mb-4">
        <strong>{pagination.count}</strong>{' '}
        {pluralize(pagination.count, 'artículo encontrado', 'artículos encontrados')}. Mostrando{' '}
        <strong>{pagination.per}</strong> artículos por página.
      </div>

      <Table>
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: '135px' }} />
          <col style={{ width: '135px' }} />
        </colgroup>
        <thead>
          <tr>
            <th rowSpan={2} className="align-middle">
              Artículo
            </th>
            <th colSpan={9} style={style} className="text-center">
              <span style={{ display: 'flex', justifyContent: switcher ? 'space-between' : 'center' }}>
                {switcher && devices.length > 1 && (
                  <Button size="sm" outline style={{ border: 0 }} color="primary" onClick={prev}>
                    <FontAwesome name="chevron-left" />
                  </Button>
                )}

                {switcher ? (
                  <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <span>Dispositivo:</span>
                    <span> {devices[position]}</span>
                  </div>
                ) : (
                  'Cantidades'
                )}
                {switcher && devices.length > 1 && (
                  <Button size="sm" outline style={{ border: 0 }} color="primary" onClick={next}>
                    <FontAwesome name="chevron-right" />
                  </Button>
                )}
              </span>
            </th>
          </tr>
          <tr>
            {titles.map((title) => (
              <>
                <th style={style} className="text-center small">
                  {title}
                </th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length === 0 && (
            <tr>
              <td colSpan={4} className="text-center mt-4 mb-4">
                No se encontraron resultados.
              </td>
            </tr>
          )}

          {items.map((item, index) =>
            switcher
              ? item.device_inventory[devices[position]] && (
                  <DeviceInventoryRow
                    item={item}
                    inventory={item.device_inventory[devices[position]] || {}}
                    key={`item-device-${index}`}
                  />
                )
              : item.deviceInventory && (
                  <DeviceInventoryRow item={item} inventory={item.deviceInventory} key={`item-device-${index}`} />
                ),
          )}
        </tbody>
      </Table>
    </div>
  );
};
