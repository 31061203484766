import { handleAuth, withToken, apiFetch, mountQuery } from '../http';

export const getDevice = (id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices/${id}`,
      method: 'GET',
    }),
  );

export const getDevices = (query) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices${mountQuery(query)}`,
      method: 'GET',
    }),
  );

export const createDevice = ({ ...device }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices`,
      method: 'POST',
      body: {
        device,
      },
    }),
  );

export const updateDevice = ({ barcode1, ...device }) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices/${barcode1}`,
      method: 'PUT',
      body: {
        device,
      },
    }),
  );

export const deleteDevice = (barcode1) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices/${barcode1}`,
      method: 'DELETE',
    }),
  );

export const insertPicture = (deviceBarcode, token) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices/${deviceBarcode}/pictures`,
      method: 'POST',
      body: {
        token,
      },
    }),
  );

export const deletePicture = (deviceBarcode, id) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices/${deviceBarcode}/pictures/${id}`,
      method: 'DELETE',
    }),
  );

export const getDeviceKeys = (deviceBarcode) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices/${deviceBarcode}/keys`,
      method: 'GET',
    }),
  );

export const getDeviceAttachments = (deviceBarcode) =>
  handleAuth(
    withToken(apiFetch)({
      endpoint: `/admin/devices/${deviceBarcode}/attachments`,
      method: 'GET',
    }),
  );
