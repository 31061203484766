import React from 'react';
import Validates, { presence } from '@core/forms/validations';
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { compose } from 'recompose';
import { connect, notificationsGenerators } from '@store';
import { createMovement, getMovements } from '@core/building/movements';
import { createProviderMovement, getProviderMovements } from '@core/provider/movements';
import { Field, Form } from '@core/forms';
import { FileUploader } from '@shared/forms';
import { formValues, reduxForm } from 'redux-form';
import { omit, capitalize } from 'lodash';
import { ProcessingButton } from '@shared';
import { TypeSelector } from '../../movements/components/TypeSelector';
import { MaintenanceValidityPeriod } from './MaintenanceValidityPeriod';
import { createValidityPeriod } from '@core/building/validity-period';

const excluded = [
  'withdrawal',
  'reserve',
  'borrowing',
  'devolution',
  'requires_repair',
  'requires_evaluation',
  'requires_maintenance',
  'requires_preservation',
  'requires_certification',
  'requires_assurance',
  'requires_discard',
  'geofence-in',
  'geofence-out',
];

const excludedForOwnerLikeSupplier = [
  ...excluded,
  // 'sent_to_repair',
  // 'repair_return',
  'reception_from_repair',
  // 'sent_to_evaluation',
  // 'shiped_from_evaluation',
  'received_from_evaluation',
  // 'sent_to_maintenance',
  // 'shiped_from_maintenance',
  'received_from_maintenance',
  // 'sent_to_preservation',
  // 'shiped_from_preservation',
  'received_from_preservation',
  // 'sent_to_certification',
  // 'shiped_from_certification',
  'received_from_certification',
  // 'sent_to_assurance',
  // 'shiped_from_assurance',
  'received_from_assurance',
  // 'sent_to_discard',
  // 'shiped_from_discard',
  'received_from_discard',
];

const excludedForOwner = [
  ...excluded,
  // 'start_repair',
  // 'repair_return',
  // 'repair_finished',
  // 'evaluation',
  // 'report_evaluation',
  // 'shiped_from_evaluation',
  // 'evaluation_finished',
  // 'on_maintenance',
  // 'shiped_from_maintenance',
  // 'maintenance_finished',
  // 'on_preservation',
  // 'shiped_from_preservation',
  // 'preservation_finished',
  // 'on_certification',
  // 'shiped_from_certification',
  // 'certification_finished',
  // 'on_assurance',
  // 'shiped_from_assurance',
  // 'assurance_finished',
  // 'on_discard',
  // 'shiped_from_discard',
  // 'discard_finished',
];

const excludedForSupplier = [
  ...excluded,
  // 'sent_to_repair',
  // 'reception_from_repair',
  // 'repair_finished',
  // 'sent_to_evaluation',
  // 'received_from_evaluation',
  // 'evaluation_finished',
  // 'sent_to_maintenance',
  // 'received_from_maintenance',
  // 'maintenance_finished',
  // 'sent_to_preservation',
  // 'received_from_preservation',
  // 'preservation_finished',
  // 'sent_to_certification',
  // 'received_from_certification',
  // 'certification_finished',
  // 'sent_to_assurance',
  // 'received_from_assurance',
  // 'assurance_finished',
  // 'sent_to_discard',
  // 'received_from_discard',
  // 'discard_finished',
];

const excludedStates = [
  'new',
  'maintenance',
  'requires_repair',
  'repair',
  'requires_maintenance',
  'unknown',
  'on_transit',
  'rejected',
  'evaluating',
  'need_evaluation',
  'on_evaluation',
  'evaluated',
  'need_maintenance',
  'on_maintenance',
  'need_preservation',
  'on_preservation',
  'need_certification',
  'on_certification',
  'need_assurance',
  'on_assurance',
  'need_discard',
  'on_discard',
];

const UploaderComponent = ({ onChange, value }) => (
  <FileUploader
    multiple={true}
    editable={true}
    token={value}
    onChange={onChange}
    endpoint={(token) => `/attachments/${token}`}
  />
);

const validate = Validates(presence({ of: 'movementType' }));

const enhance = compose(
  reduxForm({
    form: 'createRepairMovementForm',
    validate,
  }),
  formValues({
    movementType: 'movementType',
  }),
  connect(
    ({ appData: { mro_health_states: assetHealthStates }, appData, session: { user } }, ownProps) => {
      const options = { '': 'Seleccione...' };
      const movements = appData[`${ownProps.bpmType}_movements`];
      Object.keys(assetHealthStates).forEach((state) => {
        const isExcluded = excludedStates.indexOf(state) > -1;
        if (!isExcluded) {
          const name = assetHealthStates[state].name;
          options[state] = name;
        }
      });

      return { movements, user, assetHealthStates: options };
    },
    (dispatch) => ({
      successNotification: () =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Movimiento creado',
            text: 'Se ha añadido el movimiento exitosamente.',
            color: 'success',
          }),
        ),
      successValidityPeriodNotification: (barcode1) =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Nueva vigencia creada',
            text: `Se ha creado una nueva vigencia para el activo ${barcode1}`,
            color: 'success',
          }),
        ),
      failValidityPeriodNotification: (barcode1, errors) =>
        dispatch(
          notificationsGenerators.insert({
            title: 'Error al crear nueva vigencia',
            text: `${errors?.title}. Ocurrió un error al intentar crear una nueva vigencia al activo ${barcode1}`,
            color: 'danger',
          }),
        ),
      resetForm() {
        dispatch({
          type: '@@redux-form/RESET',
          meta: {
            form: 'createRepairMovementForm',
          },
        });
      },
    }),
  ),
);

class MaintenanceMovementModalPresentation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      error: null,
      movementType: undefined,
      comments: undefined,
      humanHours: undefined,
      attachmentsTokens: undefined,
      assetHealthState: undefined,
      validityPeriod: { invalid: false },
    };
  }

  size = 'lg';

  setMovementType = (value) => {
    this.setState({ movementType: value });
  };

  setComments = (value) => {
    this.setState({ comments: value });
  };

  setAttachments = (value) => {
    this.setState({ attachmentsTokens: value });
  };

  setHealthState = (value) => {
    this.setState({ assetHealthState: value });
  };

  setHumanHours = (value) => {
    this.setState({ humanHours: value });
  };

  setValidityPeriod = (value) => {
    this.setState({ validityPeriod: value });
  };

  handleSubmit = async (withTask) => {
    let { assetRepair } = this.props;
    const {
      validityPeriod: { title, from, to },
    } = this.state;
    const validityPeriod = { title, from, to };
    const { body, status } = await this.createRepairMovement(assetRepair);
    if (status === 201) {
      const asset = await this.BpmSearcher(assetRepair.id);
      const movements = await this.getMovementsOfAsset(assetRepair.id);
      this.toggleModal(null, asset, movements);
      this.props.successNotification();
      if (this.state.validityPeriod?.title && this.state.validityPeriod?.from && this.state.validityPeriod?.to) {
        const { body, status } = await createValidityPeriod(assetRepair.asset.barcode1, validityPeriod);
        if (status === 201 || status === 200) {
          this.props.successValidityPeriodNotification(assetRepair.asset.barcode1);
        } else if (status === 422) {
          this.props.failValidityPeriodNotification(assetRepair.asset.barcode1, body?.errors);
        }
      }

      if (withTask) {
        setTimeout(() => {
          window.location.href = `/organizations/tasks/new?of=${assetRepair?.asset?.barcode1}`;
        }, 3000);
      }
    } else if (status === 422) {
      this.setState({ error: body.errors });
    }
  };

  async createRepairMovement(assetRepair) {
    const { bpmType, movements } = this.props;
    const { movementType, humanHours } = this.state;

    const data = {
      assetBarcode: assetRepair.asset.barcode1,
      extendedAttributes: {
        repairId: assetRepair.repairId,
        [`asset${capitalize(bpmType)}Id`]: assetRepair.id,
        humanHours: humanHours ? humanHours : null,
      },
      ...omit(this.state, 'error', 'humanHours'),
    };
    this.setState({ submitting: true });

    const response =
      movements[movementType].creator_role === 'owner'
        ? await createMovement(data)
        : await createProviderMovement(data);

    this.setState({ submitting: false });
    return response;
  }

  async BpmSearcher(assetRepairId) {
    const { BpmSearcher } = this.props;

    const { body, status } = await BpmSearcher(assetRepairId);

    if (status === 200) return body;

    return null;
  }

  async getMovementsOfAsset(assetRepairId) {
    const { movements } = this.props;
    const { movementType } = this.state;

    const searcher = movements[movementType].creator_role === 'owner' ? getMovements : getProviderMovements;

    const { bpmType } = this.props;
    const { body, status } = await searcher({ [`asset_${bpmType}_id`]: assetRepairId });

    if (status === 200) return body;

    return null;
  }

  toggleModal = (e, assetRepair, movements) => {
    this.props.resetForm();
    this.props.toggle(e, assetRepair, movements);
    this.setState({
      error: null,
      movementType: undefined,
      comments: undefined,
      attachmentsTokens: undefined,
      assetHealthState: undefined,
    });
  };

  validateHealthState = (data) => {
    return !data ? 'No puede estar en blanco.' : undefined;
  };

  render() {
    const { isOpen, className, assetRepair, invalid, pristine, assetHealthStates, movements, bpmType } = this.props;
    const { error, submitting, movementType, comments, assetHealthState, validityPeriod } = this.state;
    const excludedMovement = [];
    if (assetRepair) {
      if (assetRepair.supplier && assetRepair.owner) {
        excludedMovement.push(...excludedForOwnerLikeSupplier);
      } else if (assetRepair.supplier && !assetRepair.owner) {
        excludedMovement.push(...excludedForSupplier);
      } else if (!assetRepair.supplier && assetRepair.owner) {
        excludedMovement.push(...excludedForOwner);
      }
    }

    return (
      <div>
        <Modal
          onEnter={this.onEnter}
          isOpen={isOpen}
          toggle={this.toggleModal}
          className={className}
          size={this.size}
          backdrop="static"
        >
          <ModalHeader toggle={this.toggleModal}>Nuevo movimiento</ModalHeader>
          <ModalBody>
            <Form error={error} onSubmit={this.handleSubmit}>
              <Row form>
                <Col md={12}>
                  <h5 className="mb-3">Especificaciones</h5>
                  <FormGroup>
                    <Field
                      label="Tipo de movimiento"
                      name="movementType"
                      component={TypeSelector}
                      bpmType={bpmType}
                      asset={assetRepair && assetRepair.asset}
                      assetRepair={assetRepair}
                      excluded={excludedMovement}
                      repairable={true}
                      onChange={(event, value) => this.setMovementType(value)}
                      help={
                        <span>
                          El tipo de movimiento realizado sobre el activo.{' '}
                          <strong>
                            Dependiendo del estado de salud actual del activo, no todos los movimientos se encuentran
                            permitidos.
                          </strong>
                        </span>
                      }
                      required
                    />
                  </FormGroup>
                  {movementType && movements[movementType] && movements[movementType].expects_health_state && (
                    <div>
                      <FormGroup>
                        <Field
                          name="assetHealthState"
                          type="select"
                          label="Estado de salud del activo"
                          options={assetHealthStates}
                          validate={this.validateHealthState}
                          onChange={(event, value) => this.setHealthState(value)}
                          required
                        />
                        <Field
                          name="humanHours"
                          label="Horas trabajadas"
                          type="text"
                          help="horas humanas trabajadas"
                          onChange={(event, value) => this.setHumanHours(value)}
                        />
                      </FormGroup>
                      <hr />
                      <MaintenanceValidityPeriod onChange={this.setValidityPeriod} asset={assetRepair.asset} />
                      <hr />
                    </div>
                  )}
                  <h5 className="mb-3">Información adicional</h5>
                  <FormGroup>
                    <Field
                      name="comments"
                      label="Comentarios"
                      rows="6"
                      type="textarea"
                      help="Cualquier comentario que pueda aportar antecedentes adicionales respecto al movimiento realizado."
                      onChange={(event, value) => this.setComments(value)}
                      placeholder={
                        movementType === 'rejected_on_evaluation_supplier'
                          ? 'Explica la razón del rechazo y de ser necesario adjunta algún documento que acredite la situación...'
                          : ''
                      }
                      required={movementType === 'rejected_on_evaluation_supplier'}
                    />
                    <Field
                      name="attachmentsTokens"
                      label="Archivos"
                      component={UploaderComponent}
                      onChange={(event, value) => this.setAttachments(value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleModal}>
              Cancelar
            </Button>
            <ProcessingButton
              type="submit"
              role="button"
              color="primary"
              outline
              width="15em"
              onClick={() => this.handleSubmit(false)}
              disabled={
                pristine ||
                invalid ||
                (!comments && assetHealthState === 'with_observations') ||
                validityPeriod.invalid ||
                (!comments && movementType === 'rejected_on_evaluation_supplier')
              }
              processing={submitting}
            >
              Ingresar movimiento
            </ProcessingButton>
            {movements[movementType]?.expects_health_state && (
              <ProcessingButton
                role="button"
                color="success"
                outline
                onClick={() => this.handleSubmit(true)}
                disabled={
                  pristine ||
                  invalid ||
                  (!comments && assetHealthState === 'with_observations') ||
                  validityPeriod.invalid ||
                  submitting
                }
              >
                Ingresar movimiento y crear tarea
              </ProcessingButton>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export const MaintenanceMovementModal = enhance(MaintenanceMovementModalPresentation);
