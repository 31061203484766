import React from 'react';

export const MassImportInstructions = () => (
  <ul>
    <li className="mb-2">
      Solo se permiten archivos <code>csv</code>. Usted puede guardar planillas Excel fácilmente con este formato a
      partir de la opción <code>Guardar como...</code>.
    </li>
    <li className="mb-2">
      Se recomienda que genere una planilla sencilla para crear el archivo CSV, sin formato ni columnas o filas extras.
    </li>
    <li className="mb-2">
      La primera fila del archivo CSV debe contener los nombres de las columnas asociadas. Estas pueden anotarse en el
      orden deseado.
    </li>
    <li className="mb-2">
      Las columnas a indicar son las siguientes:
      <ol className="mt-2">
        <li className="mb-2">
          <code>name</code>: Nombre de la categoría.
          <strong> No pueden existir dos categorías con el mismo nombre.</strong>
        </li>
        <li className="mb-2">
          <code>description</code>: Descripción.{' '}
        </li>
      </ol>
      Cualquier otra columna será ignorada automáticamente.
    </li>
    <li className="mb-2">Todas las columnas especificadas deben indicar un valor válido.</li>
    <li className="mb-2">
      Si alguna fila presenta un error en sus valores, no se creará ningun usuario. El archivo deberá ser cargado
      nuevamente.
    </li>
  </ul>
);
