import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { DeviceRow } from './DeviceRow';

export const DevicesResults = ({ devices, pagination }) => (
  <div>
    <div className="small mb-4">
      <strong>{pagination.count}</strong>{' '}
      {pluralize(pagination.count, 'dispositivo encontrado', 'dispositivos encontrados')}. Mostrando{' '}
      <strong>{pagination.per}</strong> dispositivos por página.
    </div>

    <Table>
      <colgroup>
        <col style={{ width: '30%' }} />
        <col style={{ width: '30%' }} />
        <col style={{ width: '20%' }} />
        <col style={{ width: '20%' }} />
      </colgroup>
      <thead>
        <tr>
          <th>Identificador</th>
          <th>Dispositivo</th>
          <th className="text-center">Tipo</th>
          <th className="text-center">Última Conexión</th>
        </tr>
      </thead>
      <tbody>
        {devices.map((device, index) => (
          <DeviceRow key={index} device={device} />
        ))}

        {devices.length === 0 && (
          <tr>
            <td colSpan="3" className="text-center mt-3 mb-3">
              <em>No se encontraron dispositivos.</em>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  </div>
);
