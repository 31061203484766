import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';

import { connect, notificationsGenerators } from '@store';
import { roleName } from '@core/account';
import { FontAwesome, Spinner } from '@shared';
import { BuildingSelector } from '@shared/forms';

class MembershipInput extends Component {
  constructor(props) {
    super(props);

    const { membership } = this.props;

    this.state = {
      buildingId: membership && membership.building && membership.building.id,
      role: membership && membership.role,
      processing: false,
      error: false,
    };
  }

  setBuildingId = (value) => {
    this.setState({
      buildingId: value,
    });
  };

  setRole = (e) => {
    this.setState({
      role: e.target.value,
    });

    if (this.state.buildingId && e.target.value) this.create(this.state.buildingId, e.target.value);
  };

  create = async (buildingId, role) => {
    const { createMembership, onCreate, index, userId } = this.props;

    this.setState({
      processing: true,
    });

    const { body: membership, status } = await createMembership({
      userId,
      buildingId,
      role,
    });

    if (status === 201) {
      onCreate && onCreate(index, membership);
    } else if (status === 422) {
      /* TODO: Handle error with better UX? */
      this.props.errorNotification();

      this.setState({ error: true });
    }

    this.setState({
      processing: false,
    });
  };

  delete = async () => {
    const { beforeDestroy, destroyMembership, onDelete, index, membership } = this.props;

    const proceed = await beforeDestroy();

    if (!proceed) return;

    // New entries shall be deleted immediately.
    if (!membership || !membership.id || (typeof membership.id === 'string' && membership.id.startsWith('n_'))) {
      onDelete && onDelete(index);
      return;
    }

    // Proceed with deletion
    this.setState({
      processing: true,
    });

    const { status } = await destroyMembership(membership.id);

    if (status !== 204) return;

    this.setState({
      processing: false,
    });

    onDelete && onDelete(index);
  };

  render() {
    const { roles, membership } = this.props;
    const { buildingId, role, processing, error } = this.state;

    return (
      <tr>
        <td className="text-left align-middle">
          <BuildingSelector value={buildingId} input={{ onChange: this.setBuildingId }} readonly={!!buildingId} />
        </td>
        <td className="text-center align-middle">
          {buildingId && !role && (
            <Input type="select" value={membership && membership.role} onChange={this.setRole}>
              <option value="" />
              {Object.keys(roles)
                .reverse()
                .map((role, i) => (
                  <option key={i} value={role}>
                    {roles[role].title}
                  </option>
                ))}
            </Input>
          )}

          {role && <div>{roleName(role, roles)}</div>}
        </td>
        <td className="text-right align-middle">
          <Button role="button" color="danger" onClick={this.delete} disabled={processing}>
            {error && <FontAwesome name="exclamation" />}
            {!error && processing && <Spinner />}
            {!error && !processing && <FontAwesome name="times" />}
          </Button>
        </td>
      </tr>
    );
  }
}

export default connect(null, (dispatch) => ({
  errorNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error',
        text:
          'El rol se encuentra duplicado, o el usuario no está. Contacta a un administrador si los problemas persisten.',
        color: 'danger',
      }),
    ),
}))(MembershipInput);
