import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { FontAwesome, ProcessingButton } from '@shared';
import React from 'react';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'barcode' }));

const enhance = compose(
  reduxForm({
    form: 'assemblyForm',
    validate,
  }),
);

const AssemblyFormPresentation = ({ handleSubmit, pristine, valid, submitting, invalid }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Field name="barcode" label="Código de barra" placeholder="Escanear código de barra" size="lg" />

    <Field name="identifier" label="Número de serie" help="Opcional" size="lg" />

    <Field
      name="repairable"
      type="checkbox"
      label="¿Reparable?"
      help="Indica que el áctivo podrá ser reparado en procesos MRO."
    />

    <div className="mt-4">
      <ProcessingButton
        role="button"
        type="submit"
        color="success"
        block
        size="lg"
        disabled={pristine || invalid}
        processing={submitting}
      >
        <FontAwesome name="download" /> Ensamblar
      </ProcessingButton>
    </div>
  </Form>
);

export const AssemblyForm = enhance(AssemblyFormPresentation);
