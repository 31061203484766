import React, { useState } from 'react';
import { FontAwesome } from '@shared';
import { Button, Dropdown, Row, Col, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

/**
 * TableSortHeader
 *
 * Componente que se utiliza para renderizar la cabecera de una tabla que permite ordenar
 * los registros según el atributo clickeado.
 * se debe generar en el modelo el `scope` by_order para poder utilizarlo
 * se debe agregar el `scope` by_order en el controlador del recurso para utilizarlo
 *
 * @param {Array} attributes: Atributos de la tabla, contiene el label de la cabecera, el nombre
 * del campo en la base de datos por el que se ordenará y un booleano para indiciar si el atributo
 * es ordenable.
 * @param {Object} order: Objeto que contiene el la dirección, el atributo y el icono a mostrar
 * @param {Object} currentFilters: Contiene los filtros actualmente aplicados en la tabla
 * @param {fn} setFilters: debe ser la función `handleFilters` de la clase SimpleFilter
 * @param {fn} updateOrder: debe ser la función `updateOrder` de la clase SimpleFilter
 */
export const TableSortHeader = ({
  attributes = [],
  currentFilters = {},
  order = {},
  setFilters = console.log,
  updateOrder = console.log,
}) => {
  const handleClick = (attribute) => {
    let direction, icon;
    switch (order.direction) {
      case 'DESC':
        direction = 'ASC';
        icon = 'chevron-down';
        break;
      default:
        direction = 'DESC';
        icon = 'chevron-up';
        break;
    }
    updateOrder({
      attribute,
      direction,
      icon,
    });
    setFilters({ ...currentFilters, order: `${attribute} ${direction}` });
  };

  return (
    <tr>
      {attributes.map((attr, i) => (
        <th key={`${attr}-${i}`} onClick={() => attr.sortable && handleClick(attr.code)}>
          {attr.label} {order.attribute === attr.code && <FontAwesome name={order.icon} />}
        </th>
      ))}
    </tr>
  );
};

export const ColumnSortMultiAttr = ({
  attributes = [],
  currentFilters = {},
  order = {},
  setFilters = console.log,
  updateOrder = console.log,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [selectedAttribute, setSelectedAttribute] = useState(
    attributes.filter((attr) => attr.code === currentFilters.order?.split(' ')[0])[0],
  );

  const handleClick = (attribute) => {
    let direction, icon;
    switch (order.direction) {
      case 'DESC':
        direction = 'ASC';
        icon = 'sort-alpha-down';
        break;
      default:
        direction = 'DESC';
        icon = 'sort-alpha-up';
        break;
    }
    updateOrder({
      attribute,
      direction,
      icon,
    });
    console.log(order, selectedAttribute);
    setFilters({ ...currentFilters, order: `${attribute} ${direction}` });
    // setSelectedAttribute(currentFilters.order?.split(' ')[0]);
  };

  return (
    <Row className="mt-3">
      <Col>
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle color="primary" className="w-100" outline caret>
            {selectedAttribute?.label || 'Selecciona'}
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem header></DropdownItem>
            {attributes.map((attr, i) => (
              <DropdownItem key={`${attr}-${i}`} onClick={() => setSelectedAttribute(attr)}>
                {attr.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </Col>
      <Col>
        <Button
          outline
          color="primary"
          onClick={() => handleClick(selectedAttribute.code)}
          disabled={!selectedAttribute}
        >
          {order?.icon ? <FontAwesome name={order.icon} /> : <FontAwesome name="sort" />}
        </Button>
      </Col>
    </Row>
  );
};

export default TableSortHeader;
