import React from 'react';
import { Media } from 'reactstrap';

import { getOrganization, getOrganizations } from '@core/admin/organizations';
import { RemoteSelector } from '@core/forms';
import { OrganizationCard } from '../index';

const OrganizationItem = ({ resource: organization }) => (
  <Media className="align-items-center">
    <Media left className="mr-2">
      <OrganizationCard height="2rem" width="3rem" url={organization.avatar.icon.url} alt={organization.name} />
    </Media>
    <Media body>
      <div>
        <strong>{organization.name}</strong>
      </div>
    </Media>
  </Media>
);

const OrganizationSelector = ({ filters, inputProps, ...otherProps }) => (
  <RemoteSelector
    {...otherProps}
    remoteLoader={getOrganization}
    remoteFinder={(query) => getOrganizations({ name: query, ...filters })}
    dropdownTemplate={OrganizationItem}
    inputProps={{
      ...inputProps,
      placeholder: 'Buscar por nombre',
    }}
  />
);

export default OrganizationSelector;
