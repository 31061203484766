import { createTag } from '@core/building/tags';
import { SubmissionError } from '@core/forms';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { TagForm } from './components/TagForm';

class NewTagPresentation extends Component {
  state = {
    created: false,
  };

  UNSAFE_componentWillMount() {
    // Parse target from URL
    const {
      location: { search },
    } = this.props;

    const query = new URLSearchParams(search);
    const target = query.get('for');

    this.setState({
      initialValues: {
        assetBarcode: target,
      },
    });
  }

  createTag = async (data) => {
    data.tagType = parseInt(data.tagType, 10);
    const { body, status } = await createTag(data);

    if (status === 201) {
      this.props.successCreationNotification();

      this.setState({ created: true });
    } else if (status === 422) {
      throw new SubmissionError(body.errors);
    }
  };

  render() {
    const { created, initialValues } = this.state;

    if (created) return <Redirect to={`/building/tags/`} />;

    return (
      <BuildingLayout>
        <SimpleContainerLayout title="Creación de Tag">
          <TagForm onSubmit={this.createTag} initialValues={initialValues} />
        </SimpleContainerLayout>
      </BuildingLayout>
    );
  }
}

export const NewTag = connect(null, (dispatch) => ({
  successCreationNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Tag creado',
        text: 'El Tag ha sido creado exitosamente.',
        color: 'success',
      }),
    ),
}))(NewTagPresentation);
