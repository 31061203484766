import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ containerProps, color, className, ...props }) => {
  const colorClass = color ? `text-${color}` : '';
  const additionalClass = className || '';
  const fontClassName = [colorClass, additionalClass].filter((className) => !!className).join(' ');

  const { name, ...rest } = props;

  return (
    <span
      style={{
        display: 'inline-flex',
        minWidth: '1.25em',
        justifyContent: 'center',
      }}
      className={fontClassName}
      {...containerProps}
    >
      <FontAwesomeIcon {...rest} icon={name} />
    </span>
  );
};
