import { FontAwesome } from '@shared';
import React from 'react';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardText, CardTitle } from 'reactstrap';
import noImage from './no-image.jpg';

const AreaDisplay = ({ area }) => (
  <Card>
    <CardImg
      top
      width="100%"
      src={(area.picture && area.picture.file.large.url) || noImage}
      alt={`Foto de ${area.name}`}
    />
    <CardBody>
      <CardTitle>{area.name}</CardTitle>
      <CardSubtitle>{area.location}</CardSubtitle>
      <CardText className="mb-4">{area.description}</CardText>
      <Button role="button" block disabled color="primary">
        <FontAwesome name="map" /> &nbsp; Ver ubicación
      </Button>
    </CardBody>
  </Card>
);

export default AreaDisplay;
