import { FontAwesome, ShowForRoles, simpleFilterQuery } from '@shared';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';

export const LocationRow = ({ location, index, onRemove }) => (
  <tr key={index}>
    <td className="align-middle text-left">
      <div>{location.name}</div>
      <div>
        <kbd>{location.code || location.id}</kbd>
      </div>
    </td>
    <td className="align-middle text-left">{location.geofenceType}</td>
    <td className="align-middle text-right">
      <ShowForRoles excluded={['guest']}>
        <Button
          role="button"
          size="sm"
          color="primary"
          outline
          tag={NavLink}
          to={`/building/assets?q=${simpleFilterQuery({
            'locations[{}]': [{ label: location.name, value: location.id }],
            page: 1,
          })}`}
        >
          <FontAwesome name="wrench" />
        </Button>
        <Button
          className="ml-1"
          role="button"
          size="sm"
          color="primary"
          tag={NavLink}
          to={`/organization/locations/${location.id}`}
        >
          <FontAwesome name="pencil-alt" />
        </Button>
        <Button className="ml-1" role="button" size="sm" color="danger" onClick={() => onRemove(location)}>
          <FontAwesome name="trash" />
        </Button>
      </ShowForRoles>
    </td>
  </tr>
);
