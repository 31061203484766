import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getMovementSet } from '@core/building/movement-sets';
import { UserDisplay } from '@shared/displays';
import { AttachmentDisplay, FontAwesome } from '@shared';
import { BuildingLayout, SimpleContainerLayout } from '@shared/layouts';
import { Col, Row, Table } from 'reactstrap';
import { fromIso8601 } from '@core/utils/dates';

export function PickingPage() {
  const [picking, setPicking] = useState({});
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      const { body } = await getMovementSet(id);
      console.log(body);
      setPicking(body);
    })();
  }, [id, setPicking]);

  return (
    <BuildingLayout>
      <SimpleContainerLayout
        title={
          <span>
            <FontAwesome name="truck" className="mr-2 text-primary" />
            Reporte de Picking
          </span>
        }
      >
        <Row className="mt-5">
          <Col className="offset-sm-2" sm={4}>
            <div className="mb-4 text-right">
              <div className="mb-1">
                <h5>{picking?.organization?.name}</h5>
              </div>
            </div>
          </Col>

          <Col sm={4}>
            <div className="mb-4 text-left">
              <div className="text-muted mb-1">
                <h5>Picking #{picking?.integrationInfo?.pickingId}</h5>
                {picking.requestNumber && <h5>Número Solicitud #{picking.requestNumber}</h5>}
              </div>
            </div>
          </Col>
        </Row>

        <Row className="">
          <Col className="offset-sm-2" sm={4}>
            <div className="mb-4 text-right">
              <div className="mb-1">
                <h5>{picking?.building?.name}</h5>
              </div>
            </div>
          </Col>

          <Col sm={4}>
            <div className="mb-4 text-left">
              <div className="text-muted mb-1">
                <h5>Fecha y hora: {fromIso8601(picking.date)}</h5>
              </div>
            </div>
          </Col>
        </Row>

        {picking.integratioInfo && (
          <Row>
            <Col sm={{ size: 6, offset: 3 }}>
              <div className="mb-4 text-center">
                <div className="text-muted mb-1">
                  <h5>Folio SAP: {picking?.integratioInfo?.folio}</h5>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Row className="mt-5">
          <Col className="offset-sm-2" sm={4}>
            <div className="text-muted mb-2">Cliente</div>
            {picking.buyer && <UserDisplay user={picking?.buyer} />}
          </Col>
          <Col sm={4}>
            <div className="text-muted mb-2">Ingresado por</div>
            {picking.user && <UserDisplay user={picking?.user} />}
          </Col>
        </Row>

        <Row>
          <Col className="offset-sm-2  mt-5" sm={12}>
            <div className="text-muted mb-2">Artículos</div>
          </Col>
        </Row>

        <Row>
          <Col sm={8} className="p-4 offset-sm-2">
            <Table>
              <thead>
                <th>Articulo</th>
                <th>Cantidad</th>
                <th>Unidad</th>
                <th>Precio/U</th>
                <th>Subtotal</th>
              </thead>
              <tbody>
                {picking.movements &&
                  picking?.movements.map((movement) => (
                    <tr>
                      <td>{movement.itemName}</td>
                      <td className="text-right">{movement.pickingUnit.quantity}</td>
                      <td>{movement.pickingUnit.unit}</td>
                      <td className="text-right">{movement.pickingUnit.price}</td>
                      <td className="text-right">
                        {movement.pickingUnit.quantity * parseInt(movement.pickingUnit.price)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>

          <Col sm={2} className="offset-8">
            <div className="mb-2 text-right">
              <h5>
                Total: $
                {picking.movements &&
                  picking.movements.reduce(
                    (prev, curr) => Number(prev) + Number(curr.pickingUnit.price) * Number(curr.pickingUnit.quantity),
                    0,
                  )}
              </h5>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={8} className="offset-sm-2">
            <div className="mb-4">
              <div className="text-muted mb-1">Adjuntos</div>
              <div>
                {picking?.attachments?.length === 0 && <div className="text-muted small">Sin adjuntos todavía</div>}

                {picking?.attachments && <AttachmentDisplay attachments={picking?.attachments} />}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="offset-7">
            <div className="mb-4">
              <div className="text-muted mb-1">Firma</div>
              <img width="350" src={picking?.signature} alt="" />
            </div>
          </div>
        </Row>
      </SimpleContainerLayout>
    </BuildingLayout>
  );
}
