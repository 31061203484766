import { getAssets } from '@core/building/assets';
import { getDevices } from '@core/building/devices';
import { getItems } from '@core/building/items';
import { getUsers } from '@core/building/users';
import { connect } from '@store';

import { DateSelector } from '@shared/forms';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';
import { get } from 'lodash';
import React from 'react';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import ChileanRUT from '@core/utils/chilean-rut';

export class MovementsFilter extends SimpleFilterModal {
  findItems = async (inputValue) => {
    const { body, status } = await getItems({
      search: inputValue,
      consumable: true,
      serializer: 'simple',
    });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findAssets = async (inputValue) => {
    const { body, status } = await getAssets({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ item: { name }, barcode1, id: value }) => ({
        label: `${name} - ${barcode1}`,
        value,
      }));
    }

    return [];
  };

  findDevices = async (inputValue) => {
    const { body, status } = await getDevices({ search: inputValue, serializer: 'simple' });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  findUsers = async (value) => {
    const { body, status } = await getUsers({ search: value });
    if (status === 200) {
      return body.map(({ firstName, lastName, identifier, id: value }) => ({
        label: `${firstName} ${lastName} - ${
          identifier.type === 'rut' ? ChileanRUT.PartialFormat(identifier.value) : identifier.value
        }`,
        value,
      }));
    }
    return [];
  };

  renderForm = () => {
    const { movementTypes, mroMovementTypes } = this.props;
    const consumable = get(this.state, 'consumable.value', undefined);
    let title;
    switch (consumable) {
      case true:
        title = 'Artículos';
        break;
      case false:
        title = 'Activos';
        break;
      default:
        title = 'No aplica';
    }

    return (
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label for="consumable">Involucrados</Label>
              <Select
                name="consumable"
                value={this.state['consumable']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Consumibles', value: true },
                  { label: 'No Consumibles', value: false },
                ]}
                onChange={(value, action) => {
                  if (!value || value.value !== consumable) {
                    this.onChange(null, { name: 'assets[{}]' });
                    this.onChange(null, { name: 'items[{}]' });
                  }
                  this.onChange(value, action);
                }}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>{title}</Label>
              <AsyncSelect
                name={consumable ? 'items[{}]' : 'assets[{}]'}
                value={get(this.state, consumable ? 'items[{}]' : 'assets[{}]')}
                isDisabled={consumable === undefined}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={consumable ? this.findItems : this.findAssets}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={(value, action) => {
                  this.onChange(value, action);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="generators[{}]">Ingresado Por (Dispositivo)</Label>
              <AsyncSelect
                name="generators[{}]"
                value={get(this.state, 'generator_type') === 'Core::Device' ? get(this.state, 'generators[{}]') : null}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findDevices}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={(value, action) => {
                  this.onChange(value.length > 0 ? 'Core::Device' : null, {
                    name: 'generator_type',
                  });
                  this.onChange(value, action);
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="generators[{}]">Ingresado Por (Usuario)</Label>
              <AsyncSelect
                name="generators[{}]"
                value={get(this.state, 'generator_type') === 'Core::User' ? get(this.state, 'generators[{}]') : null}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={(value, action) => {
                  this.onChange(value.length > 0 ? 'Core::User' : null, { name: 'generator_type' });
                  this.onChange(value, action);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="mro_types[{}]">Tipo de Movimiento MRO</Label>
              <Select
                name="mro_types[{}]"
                value={this.state['mro_types[{}]']}
                placeholder="Todos"
                isClearable
                isMulti
                options={mroMovementTypes}
                onChange={(value, action) => {
                  console.log(value, action, this.state['mro_types[{}]']);
                  this.onChange(value, action);
                  this.onChange(
                    this.state['types[{}]'] && value
                      ? value.concat(this.state['types[{}]'])
                      : !this.state['types[{}]'] && value
                      ? value
                      : this.state['types[{}]'],
                    {
                      ...action,
                      name: 'type[{}]',
                    },
                  );
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="types[{}]">Tipo de Movimiento</Label>
              <Select
                name="types[{}]"
                value={this.state['types[{}]']}
                placeholder="Todos"
                isClearable
                isMulti
                options={movementTypes}
                onChange={(value, action) => {
                  console.log(value, action, this.state['mro_types[{}]']);
                  this.onChange(value, action);
                  this.onChange(
                    this.state['mro_types[{}]'] && value
                      ? value.concat(this.state['mro_types[{}]'])
                      : !this.state['mro_types[{}]'] && value
                      ? value
                      : this.state['mro_types[{}]'],
                    {
                      ...action,
                      name: 'type[{}]',
                    },
                  );
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="clients[{}]">Cliente</Label>
              <AsyncSelect
                name="clients[{}]"
                value={get(this.state, 'clients[{}]')}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findUsers}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={(value, action) => {
                  this.onChange(value.length > 0 ? 'Core::User' : null, { name: 'client_type' });
                  this.onChange(value, action);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col>
            <FormGroup>
              <Label for="movement_period{}">Fecha Inicial</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'movement_period{}.started_at')}
                onChange={(date) =>
                  this.setState({
                    'movement_period{}': {
                      ...get(this.state, 'movement_period{}', {}),
                      started_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for="movement_period{}">Fecha Final</Label>
              <DateSelector
                isClearable={true}
                selected={get(this.state, 'movement_period{}.ended_at')}
                onChange={(date) =>
                  this.setState({
                    'movement_period{}': {
                      ...get(this.state, 'movement_period{}', {}),
                      ended_at: date,
                    },
                  })
                }
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  };
}

export const MovementsFilterModal = connect(({ appData }) => {
  const movements = {
    ...appData.movements,
  };
  const mroMovements = {
    ...appData.repair_movements,
    ...appData.evaluation_movements,
    ...appData.maintenance_movements,
    ...appData.preservation_movements,
    ...appData.certification_movements,
    ...appData.assurance_movements,
    ...appData.discard_movements,
  };
  const types = Object.keys(movements).map((key) => ({
    value: key,
    label: movements[key].name,
  }));
  const mroTypes = Object.keys(mroMovements).map((key) => ({
    value: key,
    label: mroMovements[key].name,
  }));
  return { movementTypes: types, mroMovementTypes: mroTypes };
})(MovementsFilter);
