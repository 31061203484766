import { fromIso8601 } from '@core/utils/dates';
import { FontAwesome } from '@shared';
import { isAfter, parseISO } from 'date-fns';
import React from 'react';
import { Card } from 'reactstrap';
import './Task.scss';

export const Task = ({ task, onClick, onStar, onComplete, className, selected = false }) => {
  const helpers = [];

  const { id, title, startDate, dueDate, completedAt, starred } = task;

  const isExpired = isAfter(new Date(), parseISO(dueDate));

  if (startDate) {
    helpers.push(`Desde: ${fromIso8601(startDate)}`);
  }

  if (dueDate) {
    helpers.push(`Hasta: ${fromIso8601(dueDate)}`);
  }

  const helperText = helpers.join(', ');

  const classes = ['task'];

  if (completedAt) {
    classes.push('completed');
  }

  if (isExpired) {
    classes.push('expired');
  }

  if (selected) {
    classes.push('selected');
  }

  if (className) {
    classes.push(className);
  }

  return (
    <Card className={classes.join(' ')} onClick={(e) => onClick(task, e)}>
      <div className="ctrl">
        <FontAwesome
          id={`t-c-${id}`}
          onClick={() => onComplete(task)}
          name={completedAt ? 'check-circle' : ['far', 'circle']}
        />
      </div>
      <div className="content">
        <div className={`content-title`}>{title}</div>
        <div className="content-footer">{helperText}</div>
      </div>
      <div className="ctrl">
        <FontAwesome id={`t-s-${id}`} onClick={() => onStar(task)} name={starred ? 'star' : ['far', 'star']} />
      </div>
    </Card>
  );
};
