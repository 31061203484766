import AsyncSelect from 'react-select/async';
import React from 'react';
import Select from 'react-select';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { compose } from 'recompose';
import { connect } from '@store';
import { getLocations } from '@core/organization/locations';
import { LOADING_MESSAGE, NO_OPTIONS, SimpleFilterModal } from '@shared/simple-filter/SimpleFilter';

class LocationFilterModalPresentation extends SimpleFilterModal {
  findLocations = async (inputValue) => {
    const { body, status } = await getLocations(this.props.currentOrganizationId, {
      search: inputValue,
    });

    if (status === 200) {
      return body.map(({ name: label, id: value }) => ({ label, value }));
    }

    return [];
  };

  renderForm() {
    return (
      <Form>
        <Row form>
          <Col md={8}>
            <FormGroup>
              <Label for="ids">Nombre</Label>
              <AsyncSelect
                name="ids[{}]"
                value={this.state['ids[{}]']}
                isClearable
                isSearchable
                isMulti
                placeholder="Buscar..."
                loadOptions={this.findLocations}
                noOptionsMessage={() => NO_OPTIONS}
                loadingMessage={() => LOADING_MESSAGE}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="active">¿Activa?</Label>
              <Select
                name="active"
                value={this.state['active']}
                placeholder="Todos"
                isClearable
                options={[
                  { label: 'Si', value: true },
                  { label: 'No', value: false },
                ]}
                onChange={this.onChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    );
  }
}

const enhance = compose(
  connect(({ session }) => {
    let organizationId = undefined;

    if (session.currentMembership && session.user) {
      const membership = session.user.memberships.find((membership) => membership.id === session.currentMembership);

      if (membership) organizationId = membership.building.organization.id;
    }

    return { currentOrganizationId: organizationId };
  }),
);

export const LocationFilterModal = enhance(LocationFilterModalPresentation);
