import { Field, Form } from '@core/forms';
import Validates, { presence } from '@core/forms/validations';
import { ProcessingButton } from '@shared';
import { MarkdownPreviewer } from '@shared/forms';
import React from 'react';
import { reduxForm } from 'redux-form';

const validate = Validates(presence({ of: 'title' }), presence({ of: 'content' }));

const PostFormPresentation = ({ handleSubmit, invalid, submitting, pristine, initialValues }) => (
  <Form noValidate onSubmit={handleSubmit}>
    <Field name="title" label="Título del post" help="Ingresar aquí el título completo del post" required />

    <Field
      name="content"
      label="Contenido"
      required
      help={
        <span>
          Puedes usar la{' '}
          <a href="https://guides.github.com/features/mastering-markdown/" rel="noopener noreferrer" target="_blank">
            especificación Markdown
          </a>{' '}
          para generar contenido formateado.
        </span>
      }
      component={MarkdownPreviewer}
    />

    <div className="text-center">
      <ProcessingButton
        role="button"
        type="submit"
        color="primary"
        outline
        width="10em"
        disabled={pristine || invalid}
        processing={submitting}
      >
        Guardar
      </ProcessingButton>
    </div>
  </Form>
);

export const PostForm = reduxForm({
  form: 'PostForm',
  validate,
})(PostFormPresentation);
