import { softDelete } from '@core/admin/users';
import { pluralize } from '@shared/helpers';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { UserRow } from './UserRow';

class UsersResultsPresentation extends Component {
  state = {
    displayConfirmBox: true,
    users: this.props.users,
  };

  openConfirmBox = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeConfirmBox = () => {
    this.setState({
      isOpen: false,
    });
  };

  softDelete = async (index) => {
    const user = this.state.users[index];
    const { users } = this.state;

    const { status } = await softDelete(user.id);

    if (status === 204) {
      this.props.deleteNotification();

      this.setState({
        users: [...users.slice(0, index), ...users.slice(index + 1)],
      });
    } else {
      this.props.errorNotification();
    }
  };

  toogleConfirmBox = () => {
    this.setState({
      displayConfirmBox: !this.state.displayConfirmBox,
    });
  };

  render() {
    const { users, displayConfirmBox } = this.state;
    const { pagination } = this.props;

    return (
      <div>
        <div className="small mb-4">
          <strong>{pagination.count}</strong> {pluralize(pagination.count, 'pagina encontrada', 'paginas encontradas')}.
          Mostrando <strong>{pagination.per}</strong> usuarios por página.
        </div>

        {users.length === 0 && (
          <div className="text-center mt-3 mb-3">
            <em>No se encontraron usuarios.</em>
          </div>
        )}
        <Table hover size="sm">
          <colgroup>
            <col style={{ width: 'auto' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '20%' }} />
            <col style={{ width: '60px' }} />
          </colgroup>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th className="text-center">Teléfono</th>
              <th className="text-center">Estado</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <UserRow
                key={index}
                user={user}
                softDelete={() => this.softDelete(index)}
                toogleConfirmBox={this.toogleConfirmBox}
                displayConfirmBox={displayConfirmBox}
              />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}

export const UsersResults = connect(null, (dispatch) => ({
  deleteNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Usuario eliminado',
        text: 'El usuario ha sido eliminado correctamente.',
        color: 'success',
      }),
    ),
  errorNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Error al eliminar.',
        text: 'Si el error persiste consulte con el administrador.',
        color: 'danger',
      }),
    ),
}))(UsersResultsPresentation);
