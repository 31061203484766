import { getAssetRepair, getAssetRepairs } from '@core/building/asset-repairs';
import { getAssetMaintenance, getAssetMaintenances } from '@core/building/asset-maintenances';
import { getAssetEvaluation, getAssetEvaluations } from '@core/building/asset-evaluations';
import { getAssetPreservation, getAssetPreservations } from '@core/building/asset-preservations';
import { getAssetCertification, getAssetCertifications } from '@core/building/asset-certifications';
import { getAssetAssurance, getAssetAssurances } from '@core/building/asset-assurances';
import { getAssetDiscard, getAssetDiscards } from '@core/building/asset-discards';
import { BpmIndex } from './BpmIndex';
import React from 'react';

export const RepairIndex = (props) => (
  <BpmIndex organizationRole="owner" getIndex={getAssetRepairs} getShow={getAssetRepair} bpmType="repair" {...props} />
);

export const MaintenanceIndex = (props) => (
  <BpmIndex
    organizationRole="owner"
    getIndex={getAssetMaintenances}
    getShow={getAssetMaintenance}
    bpmType="maintenance"
    {...props}
  />
);

export const EvaluationIndex = (props) => (
  <BpmIndex
    organizationRole="owner"
    getIndex={getAssetEvaluations}
    getShow={getAssetEvaluation}
    bpmType="evaluation"
    {...props}
  />
);

export const PreservationIndex = (props) => (
  <BpmIndex
    organizationRole="owner"
    getIndex={getAssetPreservations}
    getShow={getAssetPreservation}
    bpmType="preservation"
    {...props}
  />
);

export const CertificationIndex = (props) => (
  <BpmIndex
    organizationRole="owner"
    getIndex={getAssetCertifications}
    getShow={getAssetCertification}
    bpmType="certification"
    {...props}
  />
);

export const AssuranceIndex = (props) => (
  <BpmIndex
    organizationRole="owner"
    getIndex={getAssetAssurances}
    getShow={getAssetAssurance}
    bpmType="assurance"
    {...props}
  />
);

export const DiscardIndex = (props) => (
  <BpmIndex
    organizationRole="owner"
    getIndex={getAssetDiscards}
    getShow={getAssetDiscard}
    bpmType="discard"
    {...props}
  />
);

export const RepairIndexAsSupplier = (props) => (
  <BpmIndex
    organizationRole="supplier"
    getIndex={getAssetRepairs}
    getShow={getAssetRepair}
    bpmType="repair"
    {...props}
  />
);

export const MaintenanceIndexAsSupplier = (props) => (
  <BpmIndex
    organizationRole="supplier"
    getIndex={getAssetMaintenances}
    getShow={getAssetMaintenance}
    bpmType="maintenance"
    {...props}
  />
);

export const EvaluationIndexAsSupplier = (props) => (
  <BpmIndex
    organizationRole="supplier"
    getIndex={getAssetEvaluations}
    getShow={getAssetEvaluation}
    bpmType="evaluation"
    {...props}
  />
);

export const PreservationIndexAsSupplier = (props) => (
  <BpmIndex
    organizationRole="supplier"
    getIndex={getAssetPreservations}
    getShow={getAssetPreservation}
    bpmType="Preservation"
    {...props}
  />
);

export const CertificationIndexAsSupplier = (props) => (
  <BpmIndex
    organizationRole="supplier"
    getIndex={getAssetCertifications}
    getShow={getAssetCertification}
    bpmType="certification"
    {...props}
  />
);

export const AssuranceIndexAsSupplier = (props) => (
  <BpmIndex
    organizationRole="supplier"
    getIndex={getAssetAssurances}
    getShow={getAssetAssurance}
    bpmType="assurance"
    {...props}
  />
);

export const DiscardIndexAsSupplier = (props) => (
  <BpmIndex
    organizationRole="supplier"
    getIndex={getAssetDiscards}
    getShow={getAssetDiscard}
    bpmType="discard"
    {...props}
  />
);
