import React from 'react';
import { BuildingLayout } from '@shared/layouts';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { FontAwesome, Pagination, Spinner } from '@shared';
import { getOrganizationTasks } from '@core/building/tasks';
import { NavLink } from 'react-router-dom';
import { SimpleFilter } from '@shared/simple-filter/SimpleFilter';
import { TaskFilterModal } from './components/TaskFilterModal';
import { TasksResults } from './components/TasksResults';

export class TasksIndex extends SimpleFilter {
  searchFunction = getOrganizationTasks;

  updateTasks = (taskUpdated) => {
    console.log(taskUpdated);
    const { items } = this.state;
    if (taskUpdated.id) {
      // actualizar tarea
      this.setState({
        items: [...items.map((task) => (task.id === taskUpdated.id ? { ...taskUpdated } : { ...task }))],
      });
    } else {
      // eliminar tarea
      this.setState({
        items: [...items.slice(0, taskUpdated), ...items.slice(taskUpdated + 1)],
      });
    }
  };

  render() {
    const { items, filters, pagination, modalOpen, extras } = this.state;

    const paginationTemplate = pagination ? (
      <Pagination page={pagination.page} total={pagination.total} setPage={this.setPage} />
    ) : null;

    const summary = {
      notStared: extras?.notStared,
      onProgress: extras?.onProgress,
      overDue: extras?.overDue,
      finished: extras?.finished,
    };
    const currentFilters = this.state.filters;
    const currentOrder = this.state.order;
    return (
      <BuildingLayout>
        <Row>
          <Col>
            <div className="content-actions float-right">
              <Button size="sm" color="secondary" onClick={this.toggle}>
                <FontAwesome name="filter" /> Filtros
              </Button>
              <Button role="button" size="sm" outline color="success" tag={NavLink} to="/organization/tasks/new">
                <FontAwesome name="plus" /> Nueva Tarea
              </Button>
            </div>
            <h4 className="mb-4">
              <FontAwesome name="tasks" className="mr-2 text-primary" /> Gestión de Tareas
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="d-flex justify-content-center" style={{ fontSize: '1.5em' }}>
              <div className="m-4 d-inline">
                <FontAwesome name="stop-circle" className="mr-2 text-primary" />
                <h3 className="d-inline">{summary?.notStared}</h3> <h5 className="d-inline">No Iniciadas</h5>
              </div>
              <div className="m-4 d-inline">
                <FontAwesome name="spinner" className="mr-2 text-primary" />
                <h3 className="d-inline">{summary?.onProgress}</h3> <h5 className="d-inline">En Progreso</h5>
              </div>
              <div className="m-4 d-inline">
                <FontAwesome name="history" className="mr-2 text-primary" />
                <h3 className="d-inline">{summary?.overDue}</h3> <h5 className="d-inline">Retrasadas</h5>
              </div>
              <div className="m-4 d-inline">
                <FontAwesome name="flag-checkered" className="mr-2 text-primary" />
                <h3 className="d-inline">{summary?.finished}</h3> <h5 className="d-inline">Finalizadas</h5>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                {!items && (
                  <div className="text-center h1 text-info">
                    <Spinner />
                  </div>
                )}
                {items && (
                  <TasksResults
                    order={currentOrder}
                    updateOrder={this.updateOrder}
                    currentFilters={currentFilters}
                    handleOrder={this.handleFilter}
                    tasks={items}
                    pagination={pagination}
                    updateTasks={this.updateTasks}
                  />
                )}
                {(!items || items.length > 0) && paginationTemplate}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <TaskFilterModal
          isOpen={modalOpen}
          toggle={this.toggle}
          handleFilter={this.handleFilter}
          initialFilters={filters}
        />
      </BuildingLayout>
    );
  }
}
