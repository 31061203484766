import { localStorageSchemaVersion } from '@config';

const VersionKey = 'schema:version';

export const localStorageMigrate = () => {
  const currentVersion = window.localStorage.getItem(VersionKey);

  if (!currentVersion || localStorageSchemaVersion > currentVersion) {
    if (process.env.NODE_ENV !== 'production')
      console.log(
        `[DEBUG] New Local Storage version detected: ${localStorageSchemaVersion}. Migrating from: ${currentVersion}.`,
      );

    // For now, just clear the localStorage to start over again.
    // In the future, this won't be feasible. This means
    window.localStorage.clear();

    // Update to the new version
    window.localStorage.setItem(VersionKey, localStorageSchemaVersion);
  } else if (process.env.NODE_ENV !== 'production') {
    console.log('[DEBUG] Local Storage version up to date.');
  }
};
