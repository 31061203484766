import { borrowKit } from '@core/building/kits';
import { connect, notificationsGenerators } from '@store';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { KitBorrowForm } from './components/KitBorrowForm';
import { groupBy } from 'lodash';
import { getAssets } from '@core/building/assets';
import { Spinner } from '@shared';

const findAssets = async (item_barcodes) => {
  const filters = {
    items: item_barcodes,
    serializer: 'simple',
    active: true,
    states: 'stored',
    health_states: ['repaired', 'new', 'unknown'],
  };
  const { body, status } = await getAssets(filters);

  if (status === 200) {
    return body;
  }
  return [];
};

class BorrowKitPresentation extends Component {
  state = {
    redirectTo: undefined,
    loading: true,
    assets: [],
  };

  async componentDidMount() {
    const nonConsumables = this.props.kit.kitResources.filter((resource) => resource.item && !resource.item.consumable);
    const itemBarcodes = nonConsumables.map(({ item }) => item.barcode1);

    // solo disponibles para prestamo
    const assets = await findAssets(itemBarcodes);

    this.setState({
      assets: groupBy(assets, 'item.barcode1'),
      loading: false,
    });
  }

  borrowKit = async (payload) => {
    const { kit } = this.props;

    const { body, status } = await borrowKit(kit.id, payload);

    if (status === 200) {
      this.props.successNotification();
      const redirect = kit.kind === 'permanent' ? `/building/kits/${body.id}` : '/building/kits';
      this.setState({ redirectTo: redirect });
    } else {
      this.props.errorNotification();
    }
  };

  render() {
    const { kit } = this.props;
    const { loading, redirectTo, assets } = this.state;

    if (loading) {
      return (
        <div className="text-center h1 text-info">
          <Spinner />
        </div>
      );
    }

    if (redirectTo) return <Redirect to={redirectTo} />;

    return (
      <KitBorrowForm
        borrowKit={this.borrowKit}
        kitAvailable={kit.available}
        kitResources={kit.kitResources}
        assets={assets}
      />
    );
  }
}

export const BorrowKit = connect(null, (dispatch) => ({
  successNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'Kit entragado',
        text: `El kit ha sido Prestado exitosamente.`,
        color: 'success',
      }),
    ),
  errorNotification: () =>
    dispatch(
      notificationsGenerators.insert({
        title: 'No se pudo registrar la entrega del kit',
        text:
          'Revisa si todos los recursos del kit se encuentran disponibles. Por favor, contacta a un administrador si los problemas persisten.',
        color: 'danger',
        duration: 20000,
      }),
    ),
}))(BorrowKitPresentation);
