import { roleName } from '@core/account';
import ShowForRoles from '@shared/ShowForRoles';
import { currentMembership } from '@store/session';
import React from 'react';
import { DropdownItem, Media } from 'reactstrap';
import { OrganizationCard } from '../index';

const OrganizationHeader = ({ session, forceSetMembership, handleSubscriptions }) => {
  const membership = currentMembership(session);

  return (
    <div>
      {session.user.memberships.length === 0 && (
        <DropdownItem header>
          <em>Sin organizaciones</em>
        </DropdownItem>
      )}

      {session.currentMembership && (
        <div>
          <DropdownItem header>Conectado en</DropdownItem>
          <div>
            <Media className="mr-3 ml-3 mb-3 mt-1 align-items-center">
              <Media left className="mr-3">
                <OrganizationCard
                  url={membership?.building?.organization?.avatar?.icon?.url}
                  alt={membership?.building?.organization?.name}
                />
              </Media>
              <Media body style={{ width: '9em' }}>
                <div className="media-heading m-0 p-0 text-overflow" style={{ width: '5em' }}>
                  {membership?.building?.name}
                </div>
                <small className="text-muted m-0 p-0">{roleName(membership?.role)}</small>
              </Media>
            </Media>
          </div>
        </div>
      )}
      <ShowForRoles excluded={['guest']}>
        {session.user.memberships.length > 0 && session.currentMembership && (
          <DropdownItem role="button" onClick={handleSubscriptions}>
            Administrar Notificaciones
          </DropdownItem>
        )}
      </ShowForRoles>
      {((session.user.rootRole === 'user' && session.user.memberships.length > 1) ||
        (session.user.rootRole !== 'user' && session.user.memberships.length > 0)) && (
        <DropdownItem role="button" onClick={forceSetMembership}>
          {session.user.rootRole !== 'user' && !session.currentMembership
            ? 'Entrar como organización'
            : 'Cambiar organización'}
        </DropdownItem>
      )}
    </div>
  );
};

export default OrganizationHeader;
