import React, { useState } from 'react';
import { BlockPicker } from 'react-color';
import { getContrastingColor } from 'react-color/lib/helpers/color';

export const ColorPicker = ({ input: { value, onChange } }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const styles = {
    color: {
      height: '28px',
      borderRadius: '2px',
      background: `${value}`,
    },
    swatch: {
      width: '100%',
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
      textAlign: 'center',
    },
    label: {
      color: getContrastingColor(value),
    },
    popover: {
      position: 'absolute',
      zIndex: '999',
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    },
  };

  return (
    <div>
      <div style={styles.swatch} onClick={() => setDisplayColorPicker(true)}>
        <div style={styles.color}>
          <span style={styles.label}>{value}</span>
        </div>
      </div>

      {displayColorPicker ? (
        <div style={styles.popover}>
          <div style={styles.cover} onClick={() => setDisplayColorPicker(false)} />
          <BlockPicker className="mt-3" color={value} onChangeComplete={(color) => onChange(color.hex)} />
        </div>
      ) : null}
    </div>
  );
};
