import { pluralize } from '@shared/helpers';
import React from 'react';
import { Table } from 'reactstrap';
import { InventoryCheckerRow } from './InventoryCheckerRow';

export const InventoryCheckersResults = ({ inventoryCheckers, pagination, processing, states }) => {
  return (
    <div>
      <div className="small mb-4">
        <strong>{pagination.count}</strong> {pluralize(pagination.count, 'reporte encontrado', 'reportes encontrados')}.
        Mostrando <strong>{pagination.per}</strong> reportes por página.
      </div>

      <Table>
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
          <col style={{ width: 'auto' }} />
        </colgroup>
        <thead>
          <tr>
            <th className="text-left">Nº</th>
            <th className="text-left">Fecha</th>
            <th className="text-left">Última Actualización</th>
            <th className="text-left">Tipo</th>
            <th className="text-left">Estado</th>
            <th className="text-left">Origen</th>
            <th className="text-center">Reporte</th>
            <th className="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          {inventoryCheckers.map((inventoryChecker, index) => (
            <InventoryCheckerRow
              inventoryChecker={inventoryChecker}
              key={index}
              processing={processing === index}
              states={states}
            />
          ))}

          {inventoryCheckers.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center mt-3 mb-3">
                <em>No se encontraron reportes.</em>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
